import Cropper from 'cropperjs';
import i18next from 'i18next';
import { Platform, QuickReplyType } from '@globals';
import { uploadImg } from '../../api/network';
import 'cropperjs/dist/cropper.css';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { BACK_IN_STOCK_OTN_PURPOSE } from '../../../Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { DEFAULT_CONTENT_CARDS as FACEBOOK_ADS_DEFAULT_CARDS } from '../entry-points/EntryPointFacebookAds/utils/defaultContentCards';
import { Theme } from '../utility_classes';
import { getEntryPointCard } from '../entry-points/common/utils';
import { BLOCK_SUBTYPES } from '../../consts';
import { HEXColors } from '../../../../modern-ui/_common/colors';
import {
  COLOR_BY_BLOCK_SUBTYPE,
  ICON_BY_BLOCK_SUBTYPE,
  ICONS_BY_ENTRY_POINT_TYPE,
} from '../constants';
import { DEFAULT_CONTENT_CARDS as INSTAGRAM_ADS_DEFAULT_CARDS } from '../entry-points/EntryPointInstagramAds/utils/defaultContentCards';
import { DEFAULT_PLUGINS as INSTAGRAM_ADS_DEFAULT_PLUGINS } from '../entry-points/EntryPointInstagramAds/utils/defaultPlugins';
import { DEFAULT_PLUGINS as FACEBOOK_ADS_DEFAULT_PLUGINS } from '../entry-points/EntryPointFacebookAds/utils/defaultPlugins';
import {
  POST_PURCHASE_UPDATE,
  SHOPIFY_DISCOUNT,
} from '../../../BroadcastingTags/TagsData';
import { createDefaultPlugin } from './createDefaultPlugin';
import { getFlowPlatform } from '../../utils/getFlowPlatform';
import { entryPointsIconTextures } from '../../assets/textures';

export function findParent(view, type) {
  while (true) {
    if (typeof view === 'undefined' || view === null) {
      return undefined;
    }
    if (typeof view === type || view instanceof type) {
      return view;
    }
    view = view.parent;
  }
}

export function findParentByCallback(view, callback) {
  while (true) {
    if (typeof view === 'undefined' || view === null) {
      return undefined;
    }
    if (callback(view)) {
      return view;
    }
    view = view.parent;
  }
}

const CONTENT_BLOCKS = [
  BLOCK_SUBTYPES.send_message,
  BLOCK_SUBTYPES.import_content,
  BLOCK_SUBTYPES.calendly_reminder,
  BLOCK_SUBTYPES.calendly_confirmation,
];

export const createCustomPlugin = (
  sourcePlugin,
  createdCustomBlockSubtype,
  flow,
) => {
  if (
    sourcePlugin === PluginType.calendly &&
    CONTENT_BLOCKS.includes(createdCustomBlockSubtype)
  ) {
    const plugin = createDefaultPlugin(PluginType.comment, flow);

    plugin.config.text = i18next.t(
      'modernComponents.FlowBuilder.views.components.Calendly.privateNote',
    );

    return plugin;
  }

  return;
};

export const getMessageTagForContentBlock = (pluginType, blockSubtype) => {
  if (
    pluginType === PluginType.calendly &&
    CONTENT_BLOCKS.includes(blockSubtype)
  ) {
    return 'CONFIRMED_EVENT_UPDATE';
  }
  if (
    pluginType === PluginType.shopify_event_entry_point &&
    blockSubtype === BLOCK_SUBTYPES.receipt
  ) {
    return POST_PURCHASE_UPDATE;
  }
  if (
    pluginType === PluginType.shopify_event_entry_point &&
    blockSubtype === BLOCK_SUBTYPES.postPurchase
  ) {
    return POST_PURCHASE_UPDATE;
  }

  if (
    pluginType === PluginType.shopify_event_entry_point &&
    blockSubtype === BLOCK_SUBTYPES.shopify_discount
  ) {
    return SHOPIFY_DISCOUNT;
  }
  return undefined;
};

export const getOtnPurposeForContentBlock = (blockSubtype) => {
  if (blockSubtype === BLOCK_SUBTYPES.back_in_stock_otn) {
    return BACK_IN_STOCK_OTN_PURPOSE;
  }
  return undefined;
};

export const createPluginListByPluginId = (plugin_id) => {
  switch (plugin_id) {
    case PluginType.datepicker_shortcut:
      return [
        PluginType.datepicker_text_shortcut,
        PluginType.datepicker_quick_reply_shortcut,
      ];
    case PluginType.date_and_time_shortcut:
      return [
        PluginType.datepicker_text_shortcut,
        PluginType.date_and_time_shortcut,
      ];
    case PluginType.text_quick_replies_shortcut:
    case PluginType.instagram_text_quick_replies_shortcut:
      return [PluginType.text, PluginType.quick_reply];
    default:
      return [plugin_id];
  }
};

const cvs = document.createElement('canvas');
const ctx = cvs.getContext('2d');

function colorToRGBA(color) {
  cvs.height = 1;
  cvs.width = 1;
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);
  return ctx.getImageData(0, 0, 1, 1).data;
}

function byteToHex(num) {
  return ('0' + num.toString(16)).slice(-2);
}

const colorCache = new Map();

export function colorToHex(color) {
  let result = colorCache.get(color);
  if (typeof result !== 'undefined') {
    return result;
  }
  let rgba, hex;
  rgba = colorToRGBA(color);
  hex = [0, 1, 2]
    .map(function (idx) {
      return byteToHex(rgba[idx]);
    })
    .join('');
  result = Number.parseInt(hex, 16);
  colorCache.set(color, result);
  return result;
}

export function visitChildren(view, func) {
  if (typeof view === 'undefined') {
    return;
  }
  func(view);
  if (Array.isArray(view.children)) {
    view.children.forEach((child) => visitChildren(child, func));
  }
}

export function removeArrayElement(ar, el) {
  let number = ar.indexOf(el);
  if (number >= 0) {
    ar.splice(number, 1);
  }
}

export function moveArrayElement(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr; // for testing
}

export const swapImmutable = (array, oldIndex, newIndex) =>
  array.map((element, index) => {
    if (index === oldIndex) {
      return array[newIndex];
    } else if (index === newIndex) {
      return array[oldIndex];
    } else {
      return element;
    }
  });

export const FLOW_BUILDER_LINK_CLASS = 'flow-builder-link-wrapper';

export const wrapAllLinks = (htmlText) => {
  if (!htmlText) {
    return htmlText;
  }

  const url_pattern = /(https?:[/]{0,2}|[w]{3}[.])[^ "'>\n\t]{1,}/gi;

  return htmlText.replace(url_pattern, (url) => {
    return `<a class="${FLOW_BUILDER_LINK_CLASS}" href="${url}" style="text-decoration:underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};

export const getDefaultBlockCards = (plugin_id) =>
  ({
    [PluginType.ads_manager_ctm_json_entry_point]: FACEBOOK_ADS_DEFAULT_CARDS,
    [PluginType.ads_manager_ctm_entry_point]: FACEBOOK_ADS_DEFAULT_CARDS,
    [PluginType.ads_manager_sm_entry_point]: FACEBOOK_ADS_DEFAULT_CARDS,
    [PluginType.instagram_ads_manager_ctm_entry_point]:
      INSTAGRAM_ADS_DEFAULT_CARDS,
  }[plugin_id] || []);

export const isFacebookAds = (pluginId) =>
  [
    PluginType.ads_manager_ctm_entry_point,
    PluginType.ads_manager_sm_entry_point,
    PluginType.ads_manager_ctm_json_entry_point,
  ].includes(pluginId);

export const isFacebookAdsJson = (pluginId) =>
  [PluginType.ads_manager_ctm_json_entry_point].includes(pluginId);

export const isInstagramAds = (pluginId) =>
  pluginId === PluginType.instagram_ads_manager_ctm_entry_point;

export const getDefaultPlugins = (pluginId) => {
  if (isFacebookAds(pluginId)) {
    return FACEBOOK_ADS_DEFAULT_PLUGINS;
  }

  if (isInstagramAds(pluginId)) {
    return INSTAGRAM_ADS_DEFAULT_PLUGINS;
  }

  return [];
};
/**
 *
 * @param {Card} card
 * @param {any} propertyBag is mutable
 * @returns {number} added card index
 */
export const addGalleryCard = (card, lastGalleryCard) => {
  const index = card._cardsLayout.indexOf(lastGalleryCard) + 1;
  card.addCard(index);
  card._node.blockView.moveToTop();
  return index;
};

export const getToggleLabel = (value) =>
  value
    ? window.i18next.t('utils-string-1955-active')
    : window.i18next.t('utils-string-8930-inactive');

/**
 * Returns entry point theme by given type. Throws an error if type didn't match
 * any of cases.
 *
 * @param {String} type
 */
export const getEntryPointTheme = (type) => {
  switch (type) {
    case PluginType.fb_page_entry_point:
    case PluginType.send_to_messenger_entry_point:
    case PluginType.customer_chat_entry_point:
    case PluginType.comments_autoreply_entry_point:
    case PluginType.ad_comments_autoreply_entry_point:
    case PluginType.ads_manager_ctm_entry_point:
    case PluginType.ads_manager_ctm_json_entry_point:
    case PluginType.ads_manager_sm_entry_point:
    case PluginType.facebook_shops_entry_point:
    case PluginType.persistent_menu_entry_point:
      return new Theme({ backgroundColor: HEXColors.facebookColor });
    case PluginType.ref_link_entry_point:
    case PluginType.inbound_links_entry_point:
      return new Theme({ backgroundColor: HEXColors.azure });
    case PluginType.external_integration_entry_point:
      return new Theme({ backgroundColor: HEXColors.darkOrange });
    case PluginType.popup_entry_point:
      return new Theme({ backgroundColor: HEXColors.greenShopify });
    case PluginType.shopify_event_entry_point:
    case PluginType.shopify_customer_chat_entry_point:
    case PluginType.shopify_back_in_stock: // dont foret to change
      return new Theme({ backgroundColor: HEXColors.greenShopify });
    case PluginType.instagram_direct_entry_point:
    case PluginType.instagram_ads_manager_ctm_entry_point:
    case PluginType.instagram_comments_autoreply_entry_point:
    case PluginType.instagram_story_mention_entry_point:
    case PluginType.instagram_story_reply_entry_point:
    case PluginType.instagram_bot_link_entry_point:
    case PluginType.instagram_persistent_menu_entry_point:
      return new Theme({
        backgroundColor: HEXColors.fuchsia,
        backgroundStyle: 'linear-gradient(60deg, #E8864A 10%, #A039A0 75%)',
      });
    case PluginType.whatsapp_direct_entry_point:
    case PluginType.whatsapp_bot_link:
    case PluginType.whatsapp_widget_entry_point:
    case PluginType.whatsapp_popup_entry_point:
      return new Theme({
        backgroundColor: HEXColors.accent2Normal,
      });
    default:
      throw new Error(`There is no theme for ${type} entry point`);
  }
};

const getValueExn =
  (obj, objName = '') =>
  (key) => {
    const value = obj[key];

    if (!value) {
      console.error(`Key ${key} does not exist in object ${objName}`, obj);
    }

    return value;
  };

// Feel free to remove this functions when whole flowbuilder will rewritten in TS
export const iconsByEntryPointType = getValueExn(
  ICONS_BY_ENTRY_POINT_TYPE,
  'ICONS_BY_ENTRY_POINT_TYPE',
);
export const getColorByBlockSubtype = getValueExn(
  COLOR_BY_BLOCK_SUBTYPE,
  'COLOR_BY_BLOCK_SUBTYPE',
);

/**
 * Returns enabled or disabled icon by subtype
 *
 * @param {Block} block
 * @param {Boolean=true} isEnabledEntryPoint
 */
export const getIconByBlock = (block, isEnabledEntryPoint = true) => {
  const { subtype, cards } = block;
  const isEntryPoint = subtype === BLOCK_SUBTYPES.entrypoint;

  if (isEntryPoint) {
    const { plugin_id } = getEntryPointCard(cards);
    const { enabled, disabled } = iconsByEntryPointType(plugin_id);

    return isEnabledEntryPoint ? enabled : disabled;
  }

  /**
   * Blocks created via "Fill with data from Facebook Page" button doesn't have
   * subtype property, that's why we have to return "Send Message" EP icon by default
   */
  return (
    ICON_BY_BLOCK_SUBTYPE[subtype] ||
    ICON_BY_BLOCK_SUBTYPE[BLOCK_SUBTYPES.send_message]
  );
};
