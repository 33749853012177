import { HEXColors } from '@ui/_common/colors';
import { MainLayout } from '../../components/Elements/Layouts';
import { HTMLText } from '../../components/Elements/Shapes';
import { pluginWidth } from '../plugin_consts';

export class StaticCommentCard extends MainLayout {
  private readonly commentTextView: HTMLText;

  constructor() {
    super({
      background: {
        fill: HEXColors.yellow,
        opacity: 0.4,
        cornerRadius: 8,
      },
    });

    this.commentTextView = new HTMLText({
      text: '',
      fontSize: 15,
      width: pluginWidth - 32,
    });

    this.addToLayout(this.commentTextView, {
      margin: {
        top: 12,
        right: 16,
        bottom: 12,
        left: 16,
      },
    });
  }

  public updateText(text: string) {
    this.commentTextView.text(text);
  }
}
