import {
  HLayout,
  MainLayout,
  View,
} from '../../../../components/Elements/Layouts';
import {
  inactiveStrokeColor,
  mainStrokeColor,
  pluginWidth,
  textCardBackgroundColor,
} from '../../../plugin_consts';
import { HTMLText, Line } from '../../../../components/Elements/Shapes';
import { HEXColors } from '@ui/_common/colors';
import { SetAttributeView } from '../../../components/SetAttributeView';
import { ControlVisibility } from '../../../../FlowBuilderOverlay';
import { LineStartView } from '../../../components/Line/LineStartView';
import { createLineMenu } from '../../../Menu/createLineMenu';
import { splitTrafficPluginFragment_config_variants } from '../../../../../Plugins/SplitTrafficPlugin/@types/splitTrafficPluginFragment';
import { Node } from '../../../../Node';
import { VARIANT_TITLES } from '../consts';

interface VariantViewProps {
  variant: splitTrafficPluginFragment_config_variants;
  node: Node;
  onChange: (variant: splitTrafficPluginFragment_config_variants) => void;
  isEditing: () => boolean;
  isValid: () => boolean;
  index: number;
}

export class VariantView extends HLayout {
  TEST_NAME = 'VariantView';
  public readonly _node: Node;
  public readonly isEditing: () => boolean;
  public readonly index: number;
  private readonly lineStartView: LineStartView;
  private readonly line: Line;
  private readonly titleView: HTMLText;
  private readonly percentView: SetAttributeView;
  private readonly variant: splitTrafficPluginFragment_config_variants;
  private readonly isValid: () => boolean;

  constructor({
    variant,
    onChange,
    isEditing,
    isValid,
    index,
    node,
  }: VariantViewProps) {
    super({
      width: pluginWidth - 2,
      height: 35,
      background: {
        fill: textCardBackgroundColor,
      },
    });
    this.line = new Line({
      strokeWidth: 4,
      stroke: HEXColors.magenta,
      width: (variant.percent / 100) * 160,
    });
    const lines = new MainLayout({ width: 160, height: 35 })
      .layout(
        new Line({
          strokeWidth: 4,
          stroke: HEXColors.greyLight12,
          width: 160,
        }),
        { margin: { top: 18, left: 15 } },
      )
      .layout(this.line, { margin: { top: 18, left: 15 } });

    this.titleView = new HTMLText({
      fontSize: 15,
      verticalAlign: 'center',
      align: 'left',
      height: 35,
      width: 20,
    });

    this.variant = variant;
    this._node = node;
    this.index = index;
    this.isEditing = isEditing;
    this.isValid = isValid;

    this.percentView = new SetAttributeView(
      { isEditing: true },
      {
        width: 60,
        fill: HEXColors.black,
        background: {
          stroke: (v) =>
            v?._textAreaShown
              ? mainStrokeColor
              : isValid()
              ? inactiveStrokeColor
              : HEXColors.red,
        },
        text: variant.percent.toFixed(),
        numbersOnly: true,
        maxLength: 3,
        max: 100,
        min: 0,
        isEditing,
      },
      undefined,
      () => {
        let percent;
        try {
          const res = parseInt(this.percentView.text(), 10);
          percent = Number.isNaN(res) || res < 0 ? 0 : res > 100 ? 100 : res;
        } catch (e) {
          percent = 0;
        }
        if (percent !== this.variant.percent) {
          this.variant.percent = percent;
          onChange(this.variant);
        }
      },
      undefined,
      {
        emoji: ControlVisibility.hide,
        attributes: ControlVisibility.hide,
        symbolsLimit: ControlVisibility.hide,
      },
    );
    this.layout(lines)
      .layout(this.titleView, { margin: { left: 35 } })
      .layout(this.percentView, { margin: { left: 10 } });

    this.lineStartView = new LineStartView({
      from: this as unknown as View,
      node,
      mandatory: true,
      offset: 20,
      items: createLineMenu({ isShowConnectToExistingBlock: true }),
      onConnected: (blockView) => {
        this.variant.block_id = blockView.id();
        onChange(this.variant);
      },
      onRemoved: () => {
        this.variant.block_id = null;
        onChange(this.variant);
        node.updateLines();
      },
    });
    this.layout(this.lineStartView, {
      align: 'center',
      margin: { left: 8 },
    });
  }

  onBeforeRender() {
    this.titleView.text(VARIANT_TITLES[this.index]);
    this.line.width((this.variant.percent / 100) * 160);
    this.line.stroke(this.isValid() ? HEXColors.magenta : HEXColors.red);
    if (this.isEditing()) {
      this.percentView.text(this.variant.percent.toFixed());
    } else {
      this.percentView.text(`${this.variant.percent}%`);
    }
    if (this.variant.block_id) {
      this._node.addOutLink(
        this.variant.block_id,
        this.lineStartView._lineView,
      );
    }
  }
}
