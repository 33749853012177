import { Shape } from '../../../../../components/Elements/Shapes';

export class EventHandler {
  private views = new Map<any, Map<any, any>>(); // Map<View, Map<EventId, Event>>

  on(onView: any, eventId: string, eventStr: string, func: () => void): void {
    let view: any = onView;
    if (onView instanceof Shape) {
      view = onView.shape();
    }
    let events = this.views.get(view);
    if (typeof events === 'undefined') {
      events = new Map();
    }
    let event = events.get(eventId);
    let shouldSubscribe = false;
    if (typeof event === 'undefined') {
      event = { str: eventStr, func };
      shouldSubscribe = true;
    } else if (event.str !== eventStr || event.func !== func) {
      view.off(event.str, event.func);
      event.str = eventStr;
      event.func = func;
      shouldSubscribe = true;
    }
    if (shouldSubscribe) {
      view.on(eventStr, func);
    }
    events.set(eventId, event);
    this.views.set(view, events);
  }

  remove(viewToRemove: any): void {
    let view = viewToRemove;
    if (view instanceof Shape) {
      view = view.shape();
    }
    const existing = this.views.get(view);
    if (typeof existing !== 'undefined') {
      existing.forEach((_: any, k) => {
        this.off(view, k);
      });
    }
  }

  off(viewToRemove: any, eventId: string): void {
    let view = viewToRemove;
    if (view instanceof Shape) {
      view = view.shape();
    }
    const existing = this.views.get(view);
    if (typeof existing !== 'undefined') {
      const evt = existing.get(eventId);
      existing.delete(eventId);

      if (typeof evt !== 'undefined') {
        // TODO: refactor
        let shouldRemove = true;
        existing.forEach((value: any) => {
          if (value.str === evt.str && value.func === evt.func)
            shouldRemove = false;
        });
        if (shouldRemove) view.off(evt.str, evt.func);
      }
      if (existing.size === 0) {
        this.views.delete(view);
      }
    }
  }

  destroy() {
    this.views.clear();
  }
}
