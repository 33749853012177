import isFunction from 'lodash-es/isFunction';

export const resByFunc = <Response, Params = any>(
  func: Response | ((params?: Params) => Response),
  data?: Params,
): Response => {
  if (isFunction(func)) {
    return func(data);
  }
  return func;
};
