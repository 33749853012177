import { humanizeNumber } from '@utils/humanizeNumber';
import { createStatTooltip } from '../../components/BlockStatsView/components/StatTooltip';
import { StatFieldsIds } from '../../components/BlockStatsView';
import { FullCounterStats } from '../../../utils/Statistics/types';
import { tooltipScaled } from '../../helpers/TooltipHelpers';
import { MainLayout } from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { calcPercent } from './helpers';
import { HEXColors } from '@ui/_common/colors';

export const STAT_VIEW_WIDTH = 45;
export const STAT_VIEW_HEIGHT = 23;

interface ItemStatViewProps {
  stats: FullCounterStats;
  showPercentValue?: boolean;
  hideUniqStat?: boolean;
  unavailable?: boolean;
  showBackground?: boolean;
  fieldId?: StatFieldsIds;
}

export class ItemStatView extends MainLayout {
  private valueView: HTMLText;

  constructor({
    stats,
    showPercentValue,
    hideUniqStat,
    unavailable,
    showBackground,
    fieldId = StatFieldsIds.clicked,
  }: ItemStatViewProps) {
    super({
      width: STAT_VIEW_WIDTH,
      height: STAT_VIEW_HEIGHT,
    });

    const uniqValue = stats.counter.uniqStats?.[fieldId] || 0;
    const notUniqValue = stats.counter.notUniqStats?.[fieldId] || 0;

    if (unavailable) {
      tooltipScaled({
        view: this,
        text: window.i18next.t(
          'ItemStatView-string-2090-statistics-are-not-available-for-the-button-linking-to-the-messenger-extensions-url',
        ),
      });
    }

    if (!unavailable) {
      tooltipScaled({
        view: this,
        overTimeout: 500,
        children: createStatTooltip({
          fieldId,
          notUniqValue: showPercentValue ? notUniqValue : undefined,
          uniqValue: hideUniqStat ? undefined : uniqValue,
          blockId: stats.id,
        }),
      });
    }

    const blockStat = hideUniqStat ? stats.notUniqStats : stats.uniqStats;
    const value = hideUniqStat ? notUniqValue : uniqValue;
    const percentValue = showPercentValue
      ? calcPercent(blockStat?.[fieldId], value)
      : 0;

    const backgroundStyle = showBackground
      ? `background-color: ${HEXColors.white}; display: inline-block;padding:0 3px;border-radius:6px;line-height:19px;`
      : '';

    const text = unavailable
      ? '— &nbsp;'
      : `<div style="${backgroundStyle}">${humanizeNumber(
          showPercentValue ? percentValue : value,
        )}${showPercentValue ? '%' : ''}</div>`;

    this.valueView = new HTMLText({
      text,
      fontSize: 15,
      fill: HEXColors.black,
      fontStyle: 'semibold',
      align: 'right',
      singleLine: true,
      trustedHtml: true,
    });

    this.addToLayout(this.valueView, {
      align: 'end',
    });
  }

  get textWidth() {
    // TODO make this function independent from .width()
    return this.valueView.width() > 2 ? this.valueView.width() : 32;
  }
}
