import { clamp } from 'lodash-es';
import { LineView } from '../../views/components/Line/LineView';
import { Node } from '../../Node';
import { isRootBlock } from '../../consts';
import { createNodePosition, AllNodes, NodePosition } from './helpers';

interface PreparedBlocksPositions {
  newPositions: NodePosition[];
  oldPositions: NodePosition[];
}

interface Level {
  nodes: Node[];
}

function createDefaultLevel(): Level {
  return {
    nodes: [],
  };
}

function getNodeMetricY(node: Node, lineView: LineView): number {
  return node.blockView.toLocal(lineView.fromView.shape().getGlobalPosition())
    .y;
}

/**
 * Method implements DFS
 *
 * @param allNodes {AllNodes} is mutable
 * @param levels {Level[]} is mutable
 */
function processLevels(
  currentNode: Node,
  currentLevelIndex: number,
  allNodes: AllNodes,
  processedNodes: Map<string, boolean>,
  levels: Level[],
): void {
  if (processedNodes.has(currentNode.id) || !allNodes[currentNode.id]) {
    return;
  }

  if (!levels[currentLevelIndex]) {
    // eslint-disable-next-line no-param-reassign
    levels[currentLevelIndex] = createDefaultLevel();
  }
  const currentLevel = levels[currentLevelIndex];

  processedNodes.set(currentNode.id, true);
  currentLevel.nodes.push(currentNode);

  const outLinks: LineView[][] = Object.values(currentNode.outLinks);
  outLinks
    .flat(1)
    .sort(
      (l1, l2) =>
        getNodeMetricY(currentNode, l1) - getNodeMetricY(currentNode, l2),
    )
    .map((line: LineView) => line.toId())
    .forEach((outNodeId) => {
      if (outNodeId) {
        processLevels(
          allNodes[outNodeId],
          currentLevelIndex + 1,
          allNodes,
          processedNodes,
          levels,
        );
      }
    });
}

const VERTICAL_MARGIN_BETWEEN_ITEMS = 50;
const VERTICAL_MARGIN_BETWEEN_PARTS = 75;

const HORIZONTAL_ADDITIONAL_MARGIN_PER_NEXT_ITEM = 60;
const HORIZONTAL_ADDITIONAL_MARGIN_MIN = 160;
const HORIZONTAL_ADDITIONAL_MARGIN_MAX = 450;

function getHorizontalMargin(levels: Level[], index: number): number {
  const maxWidthForCurrentLevel = levels[index].nodes
    .map((node) =>
      Math.max(node.blockView.getCardsWidth(), node.blockView.width()),
    )
    .reduce((prevMax, current) => Math.max(prevMax, current), 0);
  const nextLevelNodesCount = levels[index + 1]?.nodes.length ?? 0;
  const additionalMarginConsideringNextLevel =
    nextLevelNodesCount * HORIZONTAL_ADDITIONAL_MARGIN_PER_NEXT_ITEM;
  return (
    maxWidthForCurrentLevel +
    clamp(
      additionalMarginConsideringNextLevel,
      HORIZONTAL_ADDITIONAL_MARGIN_MIN,
      HORIZONTAL_ADDITIONAL_MARGIN_MAX,
    )
  );
}

/**
 * @param oldPositions is mutable
 * @param newPositions is mutable
 * @returns {number} maxY for all newPositions
 */
function mapLevelsToPositions(
  levels: Level[],
  allNodes: AllNodes,
  startX: number,
  startY: number,
  oldPositions: NodePosition[],
  newPositions: NodePosition[],
): number {
  let maxYForAllPositions = 0;
  let newX = startX;
  for (let index = 0; index < levels.length; index++) {
    const level = levels[index];

    let newY = startY;
    // eslint-disable-next-line no-loop-func
    level.nodes.forEach((node) => {
      const currentNode = allNodes[node.id];
      if (isRootBlock(currentNode.block.subtype)) {
        const rootNode = Object.values(allNodes).find(
          (v) => currentNode.block.subtype === v.block.subtype,
        );
        if (rootNode) {
          oldPositions.push(
            createNodePosition(currentNode.id, currentNode.x, currentNode.y),
          );
          newPositions.push(
            createNodePosition(
              currentNode.id,
              rootNode.x - getHorizontalMargin(levels, index),
              rootNode.y,
            ),
          );
        }
        return; // continue
      }
      oldPositions.push(
        createNodePosition(currentNode.id, currentNode.x, currentNode.y),
      );
      newPositions.push(createNodePosition(currentNode.id, newX, newY));
      newY += currentNode.blockView.height() + VERTICAL_MARGIN_BETWEEN_ITEMS;
    });
    maxYForAllPositions = Math.max(maxYForAllPositions, newY);
    newX += getHorizontalMargin(levels, index);
  }
  return maxYForAllPositions;
}

function getAllLevels(startNodes: Node[], allNodes: AllNodes): Level[][] {
  const processedNodes = new Map();
  return startNodes
    .map((startNode) => {
      const levels: Level[] = [];
      processLevels(startNode, 0, allNodes, processedNodes, levels);
      return levels;
    })
    .filter((levels) => levels.length !== 0);
}

export function computePositions(
  startNodes: Node[],
  allNodes: AllNodes,
): PreparedBlocksPositions {
  const oldPositions: NodePosition[] = [];
  const newPositions: NodePosition[] = [];
  const startX = startNodes[0].x;
  let startY = startNodes[0].y;

  getAllLevels(startNodes, allNodes).forEach((levels) => {
    startY = mapLevelsToPositions(
      levels,
      allNodes,
      startX,
      startY,
      oldPositions,
      newPositions,
    );

    startY += VERTICAL_MARGIN_BETWEEN_PARTS;
  });

  if (
    Object.values(allNodes).length !== newPositions.length ||
    oldPositions.length !== newPositions.length
  ) {
    throw new Error('Could not compute position for some blocks');
  }

  return {
    newPositions,
    oldPositions,
  };
}
