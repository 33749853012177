import { HEXColors } from '@ui/_common/colors';
import { InteractionEvent } from 'pixi.js-legacy';
import { Line } from '../../components/Elements/Shapes/Line';
import {
  MainLayout,
  LayoutProps,
  defaultObj,
} from '../../components/Elements/Layouts';
import { DragHandler } from '../entry-points/common/utils/eventHandlers/DragHandler';
import { pluginWidth, textCardBackgroundColor } from '../plugin_consts';
import { getPixiFieldStrict } from '../../PixiFieldRepository';

export class Polzunok extends MainLayout {
  TEST_NAME = 'Polzunok';
  percent: number;
  activeLine: Line;
  controller: MainLayout;

  constructor(
    percent: number,
    newValueCallback: (p: number, s: 'dragged' | 'clicked') => void,
    doneCallback: () => void,
    props: LayoutProps = {},
  ) {
    super(
      defaultObj(
        {
          width: pluginWidth - 60,
          height: 16,
          background: {
            fill: textCardBackgroundColor,
          },
        },
        props,
      ),
    );
    this.percent = percent;
    this.activeLine = new Line({
      width: percent * this.width(),
      stroke: HEXColors.blue,
      strokeWidth: 4,
    });
    this.controller = new MainLayout({
      width: 16,
      height: 16,
      background: {
        fill: HEXColors.blue,
        cornerRadius: 8,
      },
    });
    this.layout(
      new Line({
        width: this.width(),
        stroke: HEXColors.greyLight12,
        strokeWidth: 4,
      }),
      {
        margin: { top: this.height() / 2 },
      },
    ).layout(this.activeLine, {
      margin: { top: this.height() / 2 },
    });
    this.layout(this.controller, {
      margin: () => ({ left: this.width() * this.percent - 8 }),
    });
    const dragHandler = new DragHandler(this.controller, getPixiFieldStrict());

    this.controller.on('dragstart', () => {
      const currentX = this.controller.x;
      dragHandler.dragFunc = (pos) => {
        return {
          x: Math.min(
            Math.max(-currentX - 8, pos.x),
            this.width() - currentX - 8,
          ),
          y: 0,
        };
      };
    });
    this.controller.on('dragmove', () => {
      const coord = this.controller.x;
      this.percent = (coord + 8) / this.width();
      this.activeLine.width(this.percent * this.width());
      newValueCallback(this.percent, 'dragged');
      this.renderNode();
    });
    this.controller.on('dragend', () => {
      doneCallback();
    });
    this.on('pointerdown', (e: InteractionEvent) => {
      e.stopPropagation();
    });
    this.on('click', (e: InteractionEvent) => {
      e.stopPropagation();
      // @ts-ignore
      const coord = e.data.getLocalPosition(this).x;
      this.percent = coord / this.width();
      this.activeLine.width(this.percent * this.width());
      this.renderNode();
      newValueCallback(this.percent, 'clicked');
      doneCallback();
    });
  }
}
