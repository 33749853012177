import { EntryPointPersistentMenu } from './EntryPointPersistentMenu';
import { EntryPointPersistentMenuCard } from './types';
import { Node } from '../../../Node';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';

export class EntryPointFacebookPersistentMenu extends EntryPointPersistentMenu {
  constructor(card: EntryPointPersistentMenuCard, node: Node) {
    super(card, node, {
      testName: 'EntryPointPersistentMenu',
      updateButtonTextKey:
        'EntryPointPersistentMenu-string--359-sync-changes-to-messenger',
    });
  }
  validationError() {
    return validateIsPageConnecting(this._node) || super.validationError();
  }
}
