import Maybe from 'graphql/tsutils/Maybe';
import { PermissionGroup } from '@utils/Permissions';
import { goToGetPermissions } from '@utils/FacebookPages/goToGetPermissions';
import { AggregatedFlowQuery_bot_verifiedPermissions as CommentsPermission } from '@utils/Data/Flow/Aggregated/@types/AggregatedFlowQuery';
import { FROM_PERMISSION_GRANT } from '@components/Plugins/FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';

export const validateCommentsPermissions = (
  commentsPermission: Maybe<CommentsPermission>,
  logger: PluginLogger,
  message: string,
) =>
  commentsPermission?.enabled
    ? undefined
    : {
        message,
        onClick: () => {
          if (!commentsPermission) {
            return undefined;
          }

          logger.log('Grant Permission tooltip click');

          return goToGetPermissions({
            permissionGroup:
              commentsPermission.permissions_group as PermissionGroup,
            interruptedHref: `${window.location.href}?${FROM_PERMISSION_GRANT}`,
          });
        },
        isBlockError: true,
      };
