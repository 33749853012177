import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { BLOCK_SUBTYPES } from '../../../consts';
import { getEntryPointCard } from './utils';
import { logFlowPluginEvent } from '../../../utils/Analytics';

export const disableEntryPointById = (entryPointId: string) => {
  getFlowControllerStrict()
    .allNodes()
    .forEach(({ blockView, block }) => {
      if (
        !block ||
        block.id !== entryPointId ||
        block.subtype !== BLOCK_SUBTYPES.entrypoint
      ) {
        return;
      }
      const entryPointCard = getEntryPointCard(block?.cards);

      if (!entryPointCard) {
        throw new Error(`Entry point card was not found`);
      }

      entryPointCard.enabled = false;
      blockView.blockTitleView.setHeaderActive(false);

      logFlowPluginEvent(entryPointCard?.plugin_id, 'entry point disabled', {
        blockId: entryPointId,
        cardId: entryPointCard.id,
        automatic: true,
      });
      if (blockView._error) {
        blockView._toggle?.setDisabled(true);
      }
      blockView.renderNode();
    });
};
