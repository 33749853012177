import { stripTags } from '@utils/stripTags';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import get from 'lodash-es/get';
import { clone, move, remove } from 'ramda';
import { instagramAdsEntryPointFragment_config_campaigns } from '@components/Plugins/InstagramAdsEntryPoint/@types/instagramAdsEntryPointFragment';
import { FacebookAdsEntryPointConfig } from '@components/Plugins/FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import { InstagramAdsEntryPointConfig } from '@components/Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { ISetPluginStateParams } from '@components/Plugins/common';
import { instagramAdsEntryPointConfigFragment_campaigns_ads } from '@components/Plugins/InstagramAdsEntryPoint/@types/instagramAdsEntryPointConfigFragment';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import { HTMLText } from '../../../../../components/Elements/Shapes';
import {
  facebookAdsEntryPointFragment_config_campaigns,
  facebookAdsEntryPointFragment_config_campaigns_ads,
} from '../../../../../../Plugins/FacebookAdsEntryPoint/@types/facebookAdsEntryPointFragment';
import {
  VLayout,
  LayoutProps,
  HLayout,
} from '../../../../../components/Elements/Layouts';
import { Node } from '../../../../../Node';
import { AdItemView } from '../AdItem';
import { buttonControl } from '../../../../helpers/ControlsHelpers';
import { getEntryPointCardView } from '../../utils';
import { StatefulPlugin } from '../../../../../StatefulPlugin';
import { EntryPointFacebookAds } from '../../../EntryPointFacebookAds';
import { HEXColors } from '@ui/_common/colors';

type CampaignParams = {
  index: number;
  updateParentLayout(): void;
};

interface CampaignItemProps extends LayoutProps {
  config:
    | facebookAdsEntryPointFragment_config_campaigns
    | instagramAdsEntryPointFragment_config_campaigns;
  state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;
  node: Node;
  campaignParams: CampaignParams;
}

export class CampaignItem extends VLayout {
  config:
    | facebookAdsEntryPointFragment_config_campaigns
    | instagramAdsEntryPointFragment_config_campaigns;
  campaignParams: CampaignParams;
  adsBox: VLayout;
  state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;
  node: Node;

  constructor({
    config,
    node,
    state,
    campaignParams,
    ...layoutProps
  }: CampaignItemProps) {
    super({
      ...layoutProps,
    });
    this.config = config;
    this.state = state;
    this.node = node;
    this.campaignParams = campaignParams;

    this.addToLayout(
      new HTMLText({
        text: `<span style="max-width: 224px; display: block; text-overflow: ellipsis; overflow: hidden;">${removeExtraSpaces(
          stripTags(
            config.name || window.i18next.t('CampaignItem-string--578-no-text'),
          ),
          ' ',
        )}</span>`,
        fontSize: 15,
        fontStyle: 'semibold',
        fill: HEXColors.black,
      }),
    );

    this.adsBox = new VLayout({});

    this.addToLayout(this.adsBox, {
      margin: {
        top: 8,
      },
    });

    this.createAdsList();
  }

  createAdsList() {
    this.adsBox._views.forEach(({ view }) => view.destroy());
    this.adsBox._views.length = 0;

    this.config.ads!.forEach(
      (
        ad:
          | facebookAdsEntryPointFragment_config_campaigns_ads
          | instagramAdsEntryPointConfigFragment_campaigns_ads,
        index: number,
      ) => {
        this.createAdView(ad, index);
      },
    );
  }

  createAdView(
    config:
      | facebookAdsEntryPointFragment_config_campaigns_ads
      | instagramAdsEntryPointConfigFragment_campaigns_ads,
    index: number,
  ) {
    const entryPointView = getEntryPointCardView(this.node.blockView) as
      | EntryPointFacebookAds
      | undefined;
    const ad = entryPointView?.syncResult[config.id] || null;
    const cardErrorMessage =
      this.state.data.validation_details?.fields?.find(
        ({ field }: { field: string }) =>
          field &&
          field.match('campaigns') &&
          get(this.state.data.config, `${field}.id`) === config.id,
      )?.error ?? '';
    let newAdsConfig = config;
    const adParams = {
      index,
      campaignIndex: this.campaignParams.index,
      error: ad?.errorMessage ?? cardErrorMessage ?? '',
    };

    if (ad && ad?.errorMessage?.length === 0 && !config.last_synced_date) {
      newAdsConfig = {
        ...config,
        last_synced_date: new Date().getTime(),
      };
      this.state.set(({ config }) => {
        // eslint-disable-next-line no-param-reassign
        config.campaigns[this.campaignParams.index].ads![index] = newAdsConfig;
        const newConfig = clone(config);
        return { newConfig } as
          | ISetPluginStateParams<InstagramAdsEntryPointConfig>
          | ISetPluginStateParams<FacebookAdsEntryPointConfig>;
      });
    }

    const adItem = new AdItemView({
      config: newAdsConfig,
      node: this.node,
      state: this.state,
      adParams,
    });
    this.addButtonControl(adItem);
    this.adsBox.addToLayout(adItem, { margin: { bottom: 16 } });
  }

  addButtonControl(newItemView: HLayout) {
    const itemsConfigs =
      this.state.data.config.campaigns[this.campaignParams.index]?.ads || [];

    buttonControl(
      newItemView,
      this.adsBox,
      () => this.node.updateLines(),
      () => {
        // for remove after save on blur
        setTimeout(() => {
          const indexEl = itemsConfigs.indexOf(
            (newItemView as any)._config || (newItemView as any).config,
          );
          const newConfig = remove(indexEl, 1, itemsConfigs as any);

          this.state.set(({ config }: any) => {
            // eslint-disable-next-line no-param-reassign
            config.campaigns[this.campaignParams.index].ads = newConfig;
            return { config };
          });
          this.adsBox.removeView(newItemView);
          newItemView.destroy();

          if (this.adsBox.views().length === 0) {
            this.state.set(({ config }: any) => {
              return {
                config: {
                  ...config,
                  campaigns: config.campaigns.filter(
                    (_: any, index: number) =>
                      this.campaignParams.index !== index,
                  ),
                },
              };
            });
            this.state.save();
            logFlowPluginEvent(this.state.data.plugin_id, 'remove ad', {
              blockId: this.node.id,
              cardId: this.state.data.id,
            });
            this.campaignParams.updateParentLayout();
            return;
          }
          logFlowPluginEvent(this.state.data?.plugin_id, 'remove ad', {
            blockId: this.node.id,
            cardId: this.state.data?.id,
          });
          this.state.save();
          this.node.updateLines();
          this.renderNode();
        });
      },
      (_, startIdx, endIdx) => {
        const newConfig = move(startIdx, endIdx, itemsConfigs as any);
        this.state.set(({ config }: any) => {
          // eslint-disable-next-line no-param-reassign
          config.campaigns[this.campaignParams.index].ads = newConfig;
          return { config };
        });
        logFlowPluginEvent(this.state.data?.plugin_id, 'drag ad', {
          start: startIdx,
          end: endIdx,
          blockId: this.node.id,
          cardId: this.state.data?.id,
        });
        this.renderElement();
        this.state.save();
      },
    );
  }
}
