import { useFlowEntryPointsData } from './Data/useFlowEntryPointsData';
import {
  getFlowController,
  getFlowControllerStrict,
} from '../PixiFieldRepository';
import { showEntryPointSelectorDialog } from '../../EntryPointSelectorDialog/showEntryPointSelectorDialog';
import { useCallback } from 'react';
import { getEntryPointCardView } from '../views/entry-points/common/utils';
import {
  getLockTooltipState,
  hideTooltip,
  setLockTooltipState,
  showTooltipForView,
} from '../views/helpers/TooltipHelpers';

export const useActivateEntryPoint = () => {
  const controller = getFlowController();
  const flowId = controller?.flow.id;
  const { entryPoints } = useFlowEntryPointsData(flowId);

  const activateSpecificEntryPoint = useCallback(
    async (entryPointId: string) => {
      const entryPointNode = getFlowControllerStrict().nodeById(entryPointId);
      const entryPointView =
        entryPointNode && getEntryPointCardView(entryPointNode.blockView);
      if (entryPointView && !entryPointView?.validationError()) {
        entryPointNode.blockView.blockTitleView.changeEntryPointEnableState(
          true,
        );
      } else {
        showTooltipForView(entryPointNode.blockView.header);
        setLockTooltipState(true);
      }
      await getFlowControllerStrict().focusOnBlock(entryPointId);
      entryPointNode.renderNode();
    },
    [],
  );

  const activateEntryPoint = useCallback(() => {
    const inactiveEntryPoints =
      entryPoints?.filter(({ enabled }) => !enabled) || [];
    if (!flowId || !inactiveEntryPoints?.[0]) {
      return;
    }
    if (inactiveEntryPoints.length > 1) {
      showEntryPointSelectorDialog({
        onSelectExisting: ({ entryPointId }) => {
          activateSpecificEntryPoint(entryPointId);
        },
        flowId,
      });
      return;
    }
    activateSpecificEntryPoint(inactiveEntryPoints[0].id);
  }, [activateSpecificEntryPoint, entryPoints, flowId]);

  return {
    activateEntryPoint,
  };
};

/**
 *  Unlock entry point error tooltip after show it by click on callouts button.
 */
export const unlockEntryPointErrorTooltip = () => {
  if (getLockTooltipState()) {
    setLockTooltipState(false);
    hideTooltip();
  }
};
