import React, { useCallback, useEffect, useRef, useState } from 'react';
import { lensPath, set, view } from 'ramda';
import { QueryResult } from 'react-apollo';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { checkTree, filterTreeBy, getNodesIds, isNode } from '@ui/Tree/utils';
import { TreeType } from '@ui/Tree/types';
import { NetworkStatus } from 'apollo-client';
import { sendEvent } from '@utils/Analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { extractGQLErrorData } from '@utils/GQL/utils';
import isEqual from 'lodash-es/isEqual';
import * as css from './SearchableTree.css';
import { TREE_QUERY } from '../queries';
import { QUERY_LIMIT } from '../constants';
import { ModalTree } from './ModalTree';
import {
  AdValidatorType,
  After,
  EntryPointRenderTree,
  FacebookAdsTree,
  FacebookCampaign,
  OnErrorSubmitType,
  RemoveAdFromEntryPointMutation,
  Tree,
  ValidatorResult,
} from './types';
import { adValidator, mergeAdsTrees } from './utils';
import { AdCampaignType, Platform } from '@globals';
import { SomethingWentWrong } from './SomethingWentWrong';
import { TreeQuery, TreeQueryVariables } from '../@types/TreeQuery';
import { PermissionsMissing } from './PermissionsMissing';

export const searchByIdOrName = (
  { id, name }: { id: string; name: string },
  searchValue: string,
) =>
  id === searchValue || name.toLowerCase().includes(searchValue.toLowerCase());

const ACCESS_ERROR = 'access_error';

const treeLens = lensPath(['bot', 'adsTree', 'tree']);
const afterLens = lensPath(['bot', 'adsTree', 'after']);
const getTree = view<TreeQuery | undefined, Tree>(treeLens);
const getAfter = view<TreeQuery | undefined, After>(afterLens);

interface SearchableTree {
  pageId: string;
  accountId: string;
  isMyAdAccount: boolean;
  pluginId: PluginType;
  isLoading: boolean;
  checkedAds: EntryPointRenderTree;
  checkedAdsIds: string[];
  onSubmit: (checkedItems: EntryPointRenderTree) => void;
  botId: string | undefined;
  adsTreeQuery: QueryResult<TreeQuery, TreeQueryVariables>;
  fetchAdsTree: () => void;
  onErrorSubmit?: OnErrorSubmitType;
  expanded: string[];
  setExpanded: (expanded: string[]) => void;
  checked: string[];
  setChecked: (checked: string[]) => void;
  removeAdFromEntryPoint: RemoveAdFromEntryPointMutation;
  adsType?: AdCampaignType;
  platform: Platform;
}

export const SearchableTree: React.FC<SearchableTree> = ({
  pageId,
  accountId,
  isMyAdAccount,
  pluginId,
  onSubmit,
  isLoading,
  botId,
  adsTreeQuery,
  fetchAdsTree,
  checkedAds,
  checkedAdsIds,
  onErrorSubmit,
  expanded,
  setExpanded,
  checked,
  setChecked,
  removeAdFromEntryPoint,
  adsType,
  platform,
}) => {
  const lastRequestCursor = useRef<string | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const { data, fetchMore, networkStatus } = adsTreeQuery;
  const cursor = getAfter(data);
  const [renderTree, setRenderTree] = useState<Tree | null>(null);
  const [tree, setTree] = useState<Tree | null>(null);

  const isEmptySearchResult = renderTree?.length === 0 && searchValue !== '';

  const loadMoreItems = useCallback(() => {
    if (botId && accountId && cursor && cursor !== lastRequestCursor.current) {
      lastRequestCursor.current = cursor;

      return fetchMore({
        query: TREE_QUERY,
        variables: {
          botId,
          accountId,
          limit: searchValue ? undefined : QUERY_LIMIT,
          after: cursor,
          type: adsType,
          platform,
        },
        updateQuery: (prev, curr) => {
          const oldTree = getTree(prev) || [];
          const newTree = getTree(curr.fetchMoreResult) || [];

          return set(
            treeLens,
            oldTree.concat(newTree),
            curr.fetchMoreResult as TreeQuery,
          );
        },
      }).then((result) => {
        const loadedItems = getTree(result.data);

        setTree([...(tree || []), ...loadedItems]);
      });
    }

    return Promise.resolve([]);
  }, [
    botId,
    accountId,
    cursor,
    fetchMore,
    tree,
    searchValue,
    adsType,
    platform,
  ]);

  const treeValidator: AdValidatorType = useCallback(
    (data: TreeType<FacebookAdsTree>) => {
      if (isNode(data)) {
        return { error: false } as ValidatorResult;
      }

      return adValidator(data, checkedAdsIds, pageId, null);
    },
    [checkedAdsIds, pageId],
  );

  const onSubmitHandler = useCallback(() => {
    if (!tree) {
      throw new Error('tree was not initialized');
    }

    const { result, addedCount, removedCount } = mergeAdsTrees({
      tree,
      checked,
      checkedAds,
      treeValidator,
    });

    onSubmit(result);

    sendEvent({
      category: 'flow',
      action: 'add ads click',
      label: pluginId,
      propertyBag: {
        addedCount,
        removedCount,
      },
    });
  }, [checked, onSubmit, tree, checkedAds, treeValidator, pluginId]);

  useEffect(() => {
    if (!tree || isLoading) {
      return;
    }

    if (searchValue === '') {
      setRenderTree(tree);
      return;
    }

    const newTree = filterTreeBy<FacebookCampaign>(tree, (node) =>
      searchByIdOrName(node, searchValue),
    ) as Tree;

    setRenderTree(newTree);
  }, [searchValue, tree, isLoading]);

  useEffect(() => {
    if (searchValue.trim() !== '' && cursor) {
      loadMoreItems();
    }
  }, [searchValue, cursor, loadMoreItems]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const initializationTree = getTree(data);

    setTree(initializationTree);
    setExpanded(getNodesIds<FacebookCampaign>(initializationTree));

    if (data && !tree) {
      setChecked(
        checkTree<FacebookCampaign>(initializationTree, checkedAdsIds),
      );
    }
  }, [data, tree, checkedAds, setChecked, setExpanded, checkedAdsIds]);

  if (adsTreeQuery.error) {
    const isAccessError =
      extractGQLErrorData(adsTreeQuery.error)?.message === ACCESS_ERROR;
    return isAccessError ? (
      <PermissionsMissing
        isMyAdAccount={isMyAdAccount}
        onRefresh={fetchAdsTree}
        pluginId={pluginId}
      />
    ) : (
      <SomethingWentWrong
        onRefresh={fetchAdsTree}
        style={{ height: 450 }}
        pluginId={pluginId}
      />
    );
  }

  return (
    <>
      <div className={css.input}>
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          renderIcon={() => <Icon icon="search" color="grey" />}
          placeholder="search by text or ID"
        />
      </div>
      <ModalTree
        onExpandedChange={setExpanded}
        expanded={expanded}
        onCheckedChange={setChecked}
        checked={checked}
        tree={renderTree || []}
        loading={isLoading && networkStatus !== NetworkStatus.fetchMore}
        treeLoading={adsTreeQuery.loading}
        isEmptySearchResult={isEmptySearchResult}
        loadMoreItems={loadMoreItems}
        onErrorSubmit={onErrorSubmit}
        checkedAdsIds={checkedAdsIds}
        removeAdFromEntryPoint={(...args) =>
          removeAdFromEntryPoint(...args).then((updatedAd) => {
            if (!tree) {
              throw new Error('tree was not initialized');
            }

            if (!updatedAd || !updatedAd.data) {
              return updatedAd;
            }

            const { adset, campaign_id } =
              updatedAd.data.removeAdFromEntryPoint;
            /**
             * We should remove adset id and campaign id from checked list when an ad
             * was successfully removed because removed ad is unchecked by default, but
             * if parent node is checked then removed would be checked as well.
             */
            const newCheckboxes = checked.filter(
              (id) => ![adset?.id, campaign_id].includes(id),
            );

            if (!isEqual(newCheckboxes, checked)) {
              setChecked(newCheckboxes);
            }

            return updatedAd;
          })
        }
        adValidator={treeValidator}
        pageId={pageId}
        pluginId={pluginId}
      />
      <Flex
        className={css.bottom}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          disabled={isLoading}
          onClick={onSubmitHandler}
          className={css.submitButton}
        >
          {window.i18next.t('SearchableTree-JSXText-1052-save')}
        </Button>
      </Flex>
    </>
  );
};
