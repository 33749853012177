import { showConnectShopifyAccountDialog } from '@utils/Integrations/Shopify/ConnectShopifyAccountDialog';
import { BotTabs, getTabLink } from '@utils/Routing';
import i18next from 'i18next';
import { Node } from '../../../../Node';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';

export const validateIsShopifyConnected = (
  isConnected: boolean | undefined,
  node: Node,
) => {
  const { botId, id: flowId } = getFlowControllerStrict().flow;
  const { id: blockId } = node.block;
  return isConnected
    ? undefined
    : {
        message: `${i18next.t(
          'shopify.connectYourShopifyAccount',
        )}<div style="margin-bottom: 8px;"></div><span style="text-decoration: underline;">${i18next.t(
          'shopify.connectNow',
        )}</span>`,
        onClick: () => {
          showConnectShopifyAccountDialog({
            interruptedHref: getTabLink(BotTabs.flows, botId, {
              flowId,
              blockId,
            }),
          });
        },
        isBlockError: true,
      };
};
