import React from 'react';
import { range } from 'ramda';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';

export const ZapierTemplatesLoader = React.memo(() => (
  <>
    {range(0, 10).map((index) => (
      <Flex
        key={index}
        alignItems="center"
        style={{
          height: 44,
        }}
      >
        <LoadingPlaceholder
          style={{
            height: 32,
            width: 72,
          }}
        />
        <Spacer factor={1} horizontalFactor={2} />
        <LoadingPlaceholder
          style={{
            height: 20,
            width: 200 + Math.round(320 * Math.random()),
          }}
        />
      </Flex>
    ))}
  </>
));
