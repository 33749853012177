import React, { useState } from 'react';
import OutsideClick from 'react-outsideclick';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { ReactComponent as PlaceIcon } from './place-icon.svg';
import { LocationPickerPlace } from './types';
import { Input } from '@ui/Input';
import { ButtonUnstyled } from '@ui/_deprecated/Button';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { logFlowPluginEvent } from '@components/FlowBuilder/utils/Analytics';
import { PluginType } from '@components/FlowBuilder/types';
import * as css from './ChoosenPlaceView.css';

export interface ChoosenPlaceViewProps {
  place: Partial<LocationPickerPlace>;
  setPlaceName: (value: string) => void;
}

export const MAX_PLACE_NAME_LENGTH = 100;

export const ChoosenPlaceView: React.FC<ChoosenPlaceViewProps> = ({
  place,
  setPlaceName,
}) => {
  const { t } = useSafeTranslation();
  const [isRenameMode, setIsRenameMode] = useState(false);
  const currentTextLimit = MAX_PLACE_NAME_LENGTH - (place.name?.length || 0);
  const addName = t(
    'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.addName',
  );

  return (
    <Flex alignItems="center">
      <PlaceIcon />

      <Flex flexDirection="column">
        {isRenameMode ? (
          <OutsideClick onClickOutside={() => setIsRenameMode(false)}>
            <TextOutsideControls
              shouldShowOutsideControls={{
                symbolsLimit: true,
              }}
              currentTextLimit={currentTextLimit}
              onInsertRequest={(text, el) => {
                insertText(text, el as InputLikeElement);
              }}
              tight
            >
              {({ ref }) => (
                <Input
                  autoFocus
                  ref={ref}
                  value={place.name || ''}
                  maxLength={MAX_PLACE_NAME_LENGTH}
                  onChange={(e) => setPlaceName(e.currentTarget.value)}
                  onBlur={() => {
                    logFlowPluginEvent(
                      PluginType.whatsapp_location,
                      'change place name',
                    );

                    setPlaceName(place.name?.trim() || '');
                  }}
                  className={css.input}
                  containerClassName={css.controls}
                  data-testid="location-name__input"
                />
              )}
            </TextOutsideControls>
          </OutsideClick>
        ) : (
          <ButtonUnstyled onClick={() => setIsRenameMode(true)}>
            <Flex>
              <Type
                size="15px"
                weight="medium"
                color="accent1Normal"
                align="left"
                data-testid="location-name__label"
              >
                {place.name || addName}
              </Type>
            </Flex>
          </ButtonUnstyled>
        )}

        <Type
          size="12px"
          color="baseSecondary"
          data-testid="location-address__label"
        >
          {place.address}
        </Type>
      </Flex>
    </Flex>
  );
};
