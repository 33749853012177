import noop from 'lodash-es/noop';
import { last } from 'ramda';
import {
  getRealPluginId,
  logFlowEvent,
  logFlowPluginEvent,
} from '../utils/Analytics';
import { tooltip } from './helpers/TooltipHelpers';
import { MainLayout, VLayout } from '../components/Elements/Layouts';
import { TextureShape } from '../components/Elements/Shapes';
import { updateControls } from './helpers/ControlsHelpers';
import { createBlockMenu } from './Menu/createBlockMenu';
import { isGalleryLimitOverflow } from './gallery_view';
import { removeArrayElement, addGalleryCard } from './utils';
import {
  cropSvgTexture,
  dragVerticalSvgTexture,
  plusSvgTexture,
  ratioSvgTexture,
  removeSvgTexture,
  addGalleryImageSvgTexture,
  instagramSvgTexture,
} from '../assets/textures';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../PixiFieldRepository';
import { removeEntity } from '../components';
import { getPluginName } from '../../../common/pluginNames';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { CreateMenuViewOverlay } from './menu_view_overlay';
import { flowBuilderEventEmitter, FlowBuilderEvent } from './events';
import { BLOCK_SUBTYPES } from '../consts';
import { isApolloBasedPlugin } from './plugins.configuration';
import { updatePluginData } from '../StatefulPlugin/ApolloState/utils';

export const controlBtnWidth = 28;
export const controlWidth = 35;
export const controlMargin = (controlWidth - controlBtnWidth) / 2;

const BLOCK_SUBTYPES_WITH_PLUS_BUTTON = [
  BLOCK_SUBTYPES.action,
  BLOCK_SUBTYPES.send_message,
  BLOCK_SUBTYPES.condition,
  BLOCK_SUBTYPES.delay,
  BLOCK_SUBTYPES.reminder,
  BLOCK_SUBTYPES.receipt,
  BLOCK_SUBTYPES.shopify_discount,
  BLOCK_SUBTYPES.postPurchase,
  BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
  BLOCK_SUBTYPES.back_in_stock_message,
  BLOCK_SUBTYPES.visitedProductsReminder,
];

export function controlLayout(img, cursor) {
  let controlLayout = new MainLayout({
    width: controlBtnWidth,
    height: controlBtnWidth,
    cursor: cursor,
    background: {
      cornerRadius: 4,
      fill: 'white',
      onhover: {
        fill: '#f3f2f2',
      },
    },
  });
  let inactiveLayout = new MainLayout({
    width: controlBtnWidth,
    height: controlBtnWidth,
    background: {
      fill: 'white',
      opacity: 0.7,
    },
  });
  inactiveLayout.on('pointerdown', (e) => e.stopPropagation());
  inactiveLayout.on('click', (e) => e.stopPropagation());
  return controlLayout.layout(controlImage(img)).layout(inactiveLayout, {
    gone: () => !controlLayout.inactive,
  });
}

function controlImage(texture) {
  return new TextureShape({
    texture: texture,
    width: controlBtnWidth,
    height: controlBtnWidth,
  });
}

export class CardControlView extends VLayout {
  TEST_NAME = 'CardControlView';

  constructor() {
    super({
      width: controlWidth,
      cursor: { in: 'pointer' },
      // draggable: true,
      background: {
        cornerRadius: 5,
        fill: 'white',
        stroke: '#f0f0f0',
        strokeWidth: 1,
      },
    });
    this._arrow = controlLayout(dragVerticalSvgTexture, { in: 'ns-resize' });
    this.removeBtn = controlLayout(removeSvgTexture);
    this._plus = controlLayout(plusSvgTexture);
    this._addImage = controlLayout(addGalleryImageSvgTexture);

    this._crop = controlLayout(cropSvgTexture);
    this._scale = controlLayout(ratioSvgTexture);
    this._instagram = controlLayout(instagramSvgTexture);

    let margin = { left: controlMargin, bottom: controlMargin };
    this.layout(new MainLayout({ height: controlMargin }))
      .layout(this._arrow, {
        margin,
        gone: () =>
          !this._block ||
          this._block._cardsLayout.size() < 2 ||
          (this._block._blockType === BLOCK_SUBTYPES.broadcast_flow_root &&
            this._card?._card?.plugin_id !== PluginType.comment),
      })
      .layout(this.removeBtn, {
        margin,
        gone: () =>
          this._card?._card?.plugin_id === PluginType.otn_request &&
          this._block._node.block.subtype ===
            BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
      })
      .layout(this._instagram, {
        margin,
        gone: () => {
          const card = this._card?._card;
          const permalink = this._card?.state?.data.config.permalink;
          const hasPostError =
            this._card?.state?.data.validation_details?.fields?.some(
              ({ field }) => field === 'media_id',
            );
          return card && !hasPostError
            ? !(card.plugin_id === PluginType.media_share && permalink)
            : true;
        },
      })
      .layout(this._crop, {
        margin,
        gone: (obj) => {
          return (
            obj._card?._card?.plugin_id !== 'image' ||
            !obj._card?._card?.config?.url
          );
        },
      })
      .layout(this._scale, {
        margin,
        gone: () => {
          const card = this._card?._card;
          return card ? !(card.plugin_id === 'gallery') : true;
        },
      })
      .layout(this._addImage, {
        margin,
        gone: () => {
          const card = this._card?._card;
          if (card?.plugin_id === 'gallery') {
            return isGalleryLimitOverflow(this._card);
          }
          return true;
        },
      })
      .layout(this._plus, {
        margin,
        gone: () => {
          return !BLOCK_SUBTYPES_WITH_PLUS_BUTTON.includes(
            this._block?._blockType,
          );
        },
      });
    this.renderNode();

    this._crop.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._crop.on('click', (e) => {
      e.stopPropagation();
      if (this._card && this._card.crop) this._card.crop();
      logFlowPluginEvent('image', 'crop', this.getPropertyBag());
    });
    this._instagram.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._instagram.on('click', (e) => {
      e.stopPropagation();
      window.open(
        this._card?.state?.data.config.permalink ?? '',
        '_blank',
        'noopener noreferrer',
      );
      let propertyBag = this.getPropertyBag();
      logFlowEvent('card', 'go to instagram post', propertyBag);
    });
    this._scale.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._scale.on('click', (e) => {
      e.stopPropagation();
      let propertyBag = this.getPropertyBag();
      this._card._card.config.square_ratio =
        !this._card._card.config.square_ratio;
      propertyBag.square_ratio = this._card._card.config.square_ratio;
      logFlowEvent('card', 'scale', propertyBag);
      this._block._node.updateCard(this._card._card);
      this._card.renderNode();
    });
    this._addImage.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._addImage.on('click', (e) => {
      const lastGalleryCard = last(this._card._cardsLayout.views());
      if (lastGalleryCard) {
        const index = addGalleryCard(this._card, lastGalleryCard);
        this.hide();
        const propertyBag = this.getPropertyBag();
        propertyBag.idx = index;
        propertyBag.cardControl = true;
        logFlowEvent(this._card._card?.plugin_id, 'add card', propertyBag);
      }
    });
    this.removeBtn.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.removeBtn.on('click', (e) => {
      e.stopPropagation();
      let hasChanges = true;
      try {
        hasChanges =
          this._card.hasSignificantChangesInConfig &&
          this._card.hasSignificantChangesInConfig();
      } catch (e) {
        console.error(e);
      }
      if (hasChanges) {
        let pluginName = getPluginName(this._card._card.plugin_id);
        if (pluginName) {
          pluginName = pluginName + ' ';
        } else pluginName = '';
        removeEntity({
          onSubmit: () => {
            this.removeCard();
          },
          renderActionText: () =>
            window.i18next.t('card_control_view-string-2043-delete'),
          renderHeading: () =>
            window.i18next.t('card_control_view-string--107-delete') +
            pluginName +
            window.i18next.t('card_control_view-string-2092-card'),
        });
      } else {
        this.removeCard();
      }
    });
    this._plus.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._plus.on('click', (e) => {
      e.stopPropagation();
      let blockType = this._block._blockType;
      let idx = this._block._cardsLayout.indexOf(this._card) + 1;
      if (blockType === 'condition') {
        this._block.addPlugin('go_to_block_plugin', idx);
      } else if (blockType === 'delay') {
        this._block.addPlugin('delay', idx);
      } else {
        flowBuilderEventEmitter.emit(FlowBuilderEvent.blockMenuOpened);

        new CreateMenuViewOverlay({
          onChoose: (item) => {
            let propertyBag = this.getPropertyBag();
            propertyBag.idx = idx;
            propertyBag.plugin = getRealPluginId(item.id);
            logFlowEvent('block', 'add card', propertyBag);
            this._block.addPlugin(item.id, idx);
          },
          items: createBlockMenu(blockType),
          onClose: () => {
            flowBuilderEventEmitter.emit(FlowBuilderEvent.blockMenuClosed);
          },
        }).showOn(e.data.global);
      }
    });

    tooltip(
      this._scale,
      window.i18next.t(
        'card_control_view-string--384-switch-between-landscape-square-photo-view',
      ),
    );
    tooltip(
      this._instagram,
      window.i18next.t('card_control_view-string-1671-open-in-instagram'),
    );
  }

  removeCard() {
    const block = this._block;
    const node = block._node;
    const card = this._card;
    block._cardsLayout.removeView(card);
    removeArrayElement(node.block.cards, card._card);
    node.block.cards.forEach((c, idx) => {
      if (
        isApolloBasedPlugin(c.plugin_id) &&
        !getPixiFieldStrict().isViewOnly()
      ) {
        updatePluginData(c.id, (data) => {
          data.card.position = idx;
        });
      } else {
        c.position = idx;
      }
    });
    node.removeCard(card._card);
    block.renderNode();
    this.hide();
    card.destroy();
    logFlowEvent('card', 'remove', this.getPropertyBag());
    flowBuilderEventEmitter.emit(FlowBuilderEvent.pluginRemoved);
  }

  getPropertyBag() {
    return {
      plugin: this._card._card.plugin_id,
      cardId: this._card._card.id,
      blockId: this._block._node.id,
      source: 'card menu',
    };
  }

  setBlockAndCard(block, card) {
    block._cardsLayout.controlDragging(this, card);
    getPixiFieldStrict()
      .eventHandler()
      .on(this, 'card_control', 'dragmove', () => block._node.updateLines());
    getPixiFieldStrict()
      .eventHandler()
      .on(this, 'card_control', 'dragend', noop);
    this._block = block;
    this._card = card;
    this.renderNode();
  }

  onElementPositionChanged(card, startIdx, idx) {
    updateControls();
    let propertyBag = this.getPropertyBag();
    propertyBag.start = startIdx;
    propertyBag.end = idx;
    logFlowEvent('card', 'drag', propertyBag);
  }

  show() {
    this.visible = this._views.some((item) => {
      if (typeof item.props.gone === 'function') {
        return !item.props.gone(this);
      }
      return false;
    });
    getPixiFieldStrict().render();
  }
}
