import { getFlowControllerStrict } from '../../../../PixiFieldRepository';
import { BLOCK_SUBTYPES } from '../../../../consts';
import { getEntryPointCard } from '../../../entry-points/common/utils';
import { isShopifyPlugin } from './isShopifyPlugin';

export const isFlowHasShopifyEntryPoint = () => {
  const { _nodesMap } = getFlowControllerStrict();

  return Object.values(_nodesMap).some(
    ({ block }) =>
      block.subtype === BLOCK_SUBTYPES.entrypoint &&
      isShopifyPlugin(getEntryPointCard(block.cards)?.plugin_id),
  );
};
