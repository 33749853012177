import React, { useState } from 'react';
import useVisibilityChange from 'use-visibility-change';
import { DefaultDialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import emptyShopsSrc from '../images/empty_shops.png';
import * as css from './ChooseFacebookShopProductsEmptyStateDialog.css';
import { DIALOG_STYLE } from '../consts';
import { GoToFacebookButton } from './GoToFacebookButton';

interface ChooseFacebookShopProductsEmptyStateDialogProps {
  onDismiss: () => void;
  onUpdateShopsRequest: () => void;
}

const MIN_TIME_TO_CREATE_SHOP_TIMEOUT = 10 * 1000;

export const ChooseFacebookShopProductsEmptyStateDialog: React.FC<ChooseFacebookShopProductsEmptyStateDialogProps> = ({
  onDismiss,
  onUpdateShopsRequest,
}) => {
  const [isLeaveToCreateShop, setIsLeaveToCreateShop] = useState<boolean>(
    false,
  );

  useVisibilityChange({
    onShow: ({ lastSeenDate }) => {
      if (!isLeaveToCreateShop) {
        return;
      }
      setIsLeaveToCreateShop(false);
      const creatingShopTime = Date.now() - lastSeenDate;
      if (creatingShopTime > MIN_TIME_TO_CREATE_SHOP_TIMEOUT) {
        onUpdateShopsRequest();
      }
    },
  });

  return (
    <DefaultDialog
      header={window.i18next.t(
        'ChooseFacebookShopProductsEmptyStateDialog-string-1273-set-up-your-facebook-shop',
      )}
      onDismiss={() => {
        onDismiss();
      }}
      dialogStyle={DIALOG_STYLE}
      footer={
        <GoToFacebookButton
          onClick={() => {
            setIsLeaveToCreateShop(true);
          }}
          eventTarget="empty state dialog"
        />
      }
    >
      <Flex
        alignItems="flex-end"
        justifyContent="center"
        className={css.imgBox}
      >
        <img src={emptyShopsSrc} alt="" className={css.img} />
      </Flex>
      <Spacer factor={4} />
      <Type size="18px" weight="semibold" as="div">
        {window.i18next.t(
          'ChooseFacebookShopProductsEmptyStateDialog-JSXText--565-you-dont-have-any-product-catalogs-in-facebook',
        )}
        <br />
        {window.i18next.t(
          'ChooseFacebookShopProductsEmptyStateDialog-JSXText--200-shops-yet',
        )}
      </Type>
      <Spacer factor={2} />
      <Type as="div" size="15px_DEPRECATED">
        {window.i18next.t(
          'ChooseFacebookShopProductsEmptyStateDialog-JSXText--165-to-display-products-here-for-this-entry-point-add-them-to-your-store-on-facebook-shops-first',
        )}
      </Type>
      <Spacer factor={15} />
    </DefaultDialog>
  );
};
