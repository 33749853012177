import { HLayout, VLayout } from '../components/Elements/Layouts';
import { Rect } from '../components/Elements/Shapes';
import { PluginType, Point } from '../types';
import {
  blockWidth,
  commentBackgroundColor,
  pluginWidth,
  textCardBackgroundColor,
} from './plugin_consts';
import { opacityAnimationFunc } from '../utils/opacityAnimationFunc';
import { HEXColors } from '@ui/_common/colors';
import { flowBuilderModule } from '../FlowBuilderModule';

export class BlockLoadingView extends VLayout {
  TEST_NAME = 'BlockLoadingView';

  constructor(location: Point) {
    super({
      x: location.x,
      y: location.y,
      width: blockWidth,
      cursor: { in: 'wait' },
      background: {
        fill: HEXColors.white,
        cornerRadius: 20,
      },
    });
    const hLayout = new HLayout();
    this.layout(hLayout, { margin: { top: 20 } });
    const rect = new Rect({
      width: 24,
      height: 24,
      fill: HEXColors.greyLight20,
      cornerRadius: 4,
    });
    const rect1 = new Rect({
      width: 210,
      height: 24,
      fill: HEXColors.greyLight20,
      cornerRadius: 4,
    });
    hLayout
      .layout(rect, { margin: { left: 20 } })
      .layout(rect1, { margin: { left: 12 } });
    const bottomRect = new Rect({
      width: pluginWidth,
      height: 50,
      fill: HEXColors.greyLight20,
      cornerRadius: 4,
    });
    this.layout(bottomRect, { margin: { left: 20, top: 30, bottom: 20 } });
    this.animationFunction = () =>
      opacityAnimationFunc(this, [rect, rect1, bottomRect]);
    this.startAnimation();
  }
}

export class PluginLoadingView extends VLayout {
  TEST_NAME = 'PluginLoadingView';

  static heightByPluginId: Record<string, number> = {
    [PluginType.text]: 120,
    [PluginType.gallery]: 403,
    [PluginType.image]: 52,
    [PluginType.video]: 52,
    [PluginType.audio]: 52,
    [PluginType.document]: 52,
    [PluginType.otn_request]: 204,
    [PluginType.save_user_email_shortcut]: 190,
    [PluginType.save_user_phone_shortcut]: 190,
    [PluginType.open_url]: 150,
    [PluginType.phone_call]: 106,
    [PluginType.add_tag]: 106,
    [PluginType.remove_tag]: 106,
    [PluginType.add_attribute]: 106,
    [PluginType.remove_attribute]: 106,
    [PluginType.facebook_analytics]: 106,
    [PluginType.go_to_block_plugin]: 45,
    [PluginType.split_traffic]: 194,
    [PluginType.json_plugin]: 112,
    [PluginType.delay]: 70,
    [PluginType.comment]: 104,
  };

  static getBackgroundColorByPluginType(pluginType: PluginType) {
    switch (pluginType) {
      case PluginType.comment:
        return commentBackgroundColor;
      default:
        return textCardBackgroundColor;
    }
  }

  static getDetailColorByPluginType(pluginType: PluginType) {
    switch (pluginType) {
      case PluginType.comment:
        return HEXColors._ece3ab;
      default:
        return HEXColors._e8e8e8;
    }
  }

  constructor(pluginId: PluginType) {
    super({
      width: pluginWidth,
      cursor: { in: 'wait' },
      background: {
        fill: PluginLoadingView.getBackgroundColorByPluginType(pluginId),
        cornerRadius: 10,
      },
    });
    let pluginHeight;

    if (flowBuilderModule.hasPlugin(pluginId)) {
      pluginHeight = flowBuilderModule.getPlugin(pluginId).loadingPreviewHeight;
    } else {
      pluginHeight = PluginLoadingView.heightByPluginId[pluginId];
    }

    if (!pluginHeight) {
      pluginHeight = 120;
    }
    const margin = 15;
    const minHeight = margin * 3 + 25 + 5;
    const hLayout = new HLayout({});
    const detailFillColor =
      PluginLoadingView.getDetailColorByPluginType(pluginId);
    const rect = new Rect({
      width: 25,
      height: 25,
      fill: detailFillColor,
      cornerRadius: 12,
    });
    const rect1 = new Rect({
      width: pluginWidth - 25 - 3 * margin,
      height: 25,
      fill: detailFillColor,
      cornerRadius: 4,
    });
    this.layout(hLayout, {
      margin: {
        top: pluginHeight > minHeight ? margin : (pluginHeight - 25) / 2,
        bottom: pluginHeight > minHeight ? 0 : (pluginHeight - 25) / 2,
      },
    });
    hLayout.layout(rect, { margin: { left: margin } });
    hLayout.layout(rect1, { margin: { left: margin } });

    let bottomRect: Rect;
    if (pluginHeight > minHeight) {
      bottomRect = new Rect({
        width: pluginWidth - 2 * margin,
        height: pluginHeight - minHeight + 5,
        fill:
          pluginId === PluginType.comment
            ? commentBackgroundColor
            : detailFillColor,
        cornerRadius: 4,
      });
      this.layout(bottomRect, {
        margin: { left: margin, top: margin, bottom: margin },
      });
    }
    this.animationFunction = () =>
      opacityAnimationFunc(this, [rect, rect1, bottomRect]);
    this.startAnimation();
  }
}
