import { PluginType } from '@components/Plugins/common/PluginTypes';
import { CheckboxTree } from '@ui/Tree/CheckboxTree';
import { FlatTreeItemTypes, TreeType } from '@ui/Tree/types';
import { isNode } from '@ui/Tree/utils';
import React, { useCallback } from 'react';
import { ItemLoadingPlaceholder } from '@components/dialogs/ChooseItemFromListDialog';
// TODO its better to move this component into another place
import { NoResultFound } from './NoResultFound';
import { TreeChildren } from './TreeChildren';
import {
  AdValidatorType,
  FacebookAdsTree,
  OnErrorSubmitType,
  RemoveAdFromEntryPointMutation,
} from './types';
import * as Utils from './utils';

interface ModalTreeProps {
  tree: FacebookAdsTree[];
  loading: boolean;
  treeLoading: boolean;
  isEmptySearchResult: boolean;
  expanded: string[];
  checked: string[];
  checkedAdsIds: string[];
  loadMoreItems: () => Promise<unknown>;
  onExpandedChange: (expanded: string[]) => void;
  onCheckedChange: (checked: string[]) => void;
  removeAdFromEntryPoint: RemoveAdFromEntryPointMutation;
  onErrorSubmit?: OnErrorSubmitType;
  adValidator: AdValidatorType;
  pageId: string;
  pluginId: PluginType;
}

export const ModalTree: React.FC<ModalTreeProps> = ({
  tree,
  loading,
  treeLoading,
  isEmptySearchResult,
  loadMoreItems,
  onExpandedChange,
  expanded,
  onCheckedChange,
  checked,
  onErrorSubmit,
  checkedAdsIds,
  adValidator,
  removeAdFromEntryPoint,
  pageId,
  pluginId,
}) => {
  const fitlerTree = useCallback(
    (data: TreeType<FacebookAdsTree>) => {
      if (isNode(data)) {
        return false;
      }

      return !Utils.adValidator(data, checkedAdsIds, pageId, null).error;
    },
    [checkedAdsIds, pageId],
  );

  if (loading) {
    return <ItemLoadingPlaceholder />;
  }

  if (isEmptySearchResult) {
    return <NoResultFound />;
  }

  return (
    <CheckboxTree
      height={400}
      treeData={tree}
      filterTree={fitlerTree}
      onExpandedChange={onExpandedChange}
      expanded={expanded}
      onCheckedChange={onCheckedChange}
      checked={checked}
      itemSize={(item) => {
        if (item.type === FlatTreeItemTypes.loader) {
          return 52 * 5;
        }

        if (item.type === FlatTreeItemTypes.spacer) {
          return 52;
        }

        return isNode(item.tree) ? 32 : 52;
      }}
      threshold={20}
      isItemLoaded={(index, treeLength) => index < treeLength}
      loadMoreItems={loadMoreItems}
      renderItem={(props) => (
        <TreeChildren
          {...props}
          onErrorSubmit={onErrorSubmit}
          checkedAdsIds={checkedAdsIds}
          removeAdFromEntryPoint={removeAdFromEntryPoint}
          adValidator={adValidator}
          pageId={pageId}
          pluginId={pluginId}
        />
      )}
      renderLoader={() => (treeLoading ? <ItemLoadingPlaceholder /> : null)}
    />
  );
};
