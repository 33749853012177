import i18next from 'i18next';
import { Level, log } from 'cf-common/src/logger';
import { HEXColors } from '@ui/_common/colors';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { toaster, ServiceMessageType } from '@services/MessageService';
import { MainLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { isEmptyString, nonEmptyArray } from '../../validation';
import { Node } from '../../../Node';
import { HTMLText } from '../../../components/Elements/Shapes';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { LineStartView } from '../../components/Line/LineStartView';
import {
  mainStrokeColor,
  onHoverStrokeWidth,
  pluginWidth,
  textCardBackgroundColor,
} from '../../plugin_consts';
import {
  goToBlockPluginFragment_config as GoToBlockPluginConfig,
  goToBlockPluginFragment_config_user_filter_parameters as UserFilterParameter,
} from '../../../../Plugins/GoToBlockPlugin/@types/goToBlockPluginFragment';
import {
  attributeOperationToString,
  blockOperationToString,
  segmentOperationToString,
  sequenceOperationToString,
} from '../../utils/segmentation';
import { UserAttributeType } from '@globals';
import { getLineItemsProps } from '../../utils/getLineItemsProps';

type FilterValue = string | null;

export class GoToBlockPluginView
  extends MainLayout
  implements StatefulPluginDelegate<GoToBlockPluginConfig>
{
  TEST_NAME = 'GoToBlockPluginView';

  public readonly state: StatefulPlugin<GoToBlockPluginConfig>;

  private _node: Node;

  private titleView: HTMLText;
  private lineStartView: LineStartView;

  constructor(state: StatefulPlugin<GoToBlockPluginConfig>, node: Node) {
    super({
      title: '',
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 10,
        stroke: mainStrokeColor,
        strokeWidth: onHoverStrokeWidth,
      },
    });

    this.state = state;
    this.state.setDelegate(this);

    this._node = node;

    this.titleView = new HTMLText({
      text: '',
      width: 240,
      fill: HEXColors.black,
      lineHeight: 1.4,
      fontSize: 15,
    });
    this.layout(this.titleView, { margin: { left: 16, top: 16, bottom: 16 } });

    this.on('click', () => {
      getPixiFieldStrict().fixBlockPosition(
        node.blockView,
        getPanelWidth(PluginType.go_to_block_plugin),
      );
      editPluginInEditorPanel(node.id, this.state.data);
    });

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        ...getLineItemsProps(node.block),
        onConnected: (blockView) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              action: {
                __typename: 'GoToBlockPluginAction',
                random: false,
                items: [
                  {
                    __typename: 'GoToBlockPluginActionItem',
                    text: null,
                    item_type: 'block',
                    blocks: [blockView.id()],
                  },
                ],
              },
            },
          }));

          this.state.save();
        },
        onRemoved: () => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              action: {
                __typename: 'GoToBlockPluginAction',
                random: false,
                items: [
                  {
                    __typename: 'GoToBlockPluginActionItem',
                    text: null,
                    item_type: 'block',
                    blocks: [],
                  },
                ],
              },
            },
          }));

          this.state.save();
        },
      },
      this.state.data.id,
    );
    this.layout(this.lineStartView, {
      align: 'end',
      verticalAlign: 'center',
      margin: { left: 8 },
    });
  }

  onBeforeRender() {
    const { config } = this.state.data;
    let str = 'If ';
    if (
      !config.user_filter?.parameters ||
      config.user_filter.parameters.length === 0
    ) {
      str = i18next.t(
        'modernComponents.FlowBuilder.views.components.GoToBlockPlugin.unconditionalRedirectPlaceholderText',
      );
    } else {
      const parameterStrings: string[] = [];
      config.user_filter.parameters.forEach((parameter) => {
        const originValues = parameter?.values;
        const values = !originValues?.length
          ? [window.i18next.t('GoToBlock-string-6708-empty')]
          : originValues.map((v) =>
              v === '' ? window.i18next.t('GoToBlock-string-6708-empty') : v,
            );

        if (
          parameter?.type === UserAttributeType.system ||
          parameter?.type === UserAttributeType.custom
        ) {
          parameterStrings.push(this.attributeToString(parameter, values));
        }
        if (parameter?.type === UserAttributeType.block) {
          parameterStrings.push(this.blockToString(parameter, values));
        }
        if (parameter?.type === UserAttributeType.segment) {
          parameterStrings.push(this.segmentToString(parameter, values));
        }
        if (parameter?.type === UserAttributeType.sequence) {
          parameterStrings.push(this.sequenceToString(parameter, values));
        }
      });
      str += parameterStrings.join(`\n\n${config.user_filter?.operation} `);
    }

    this._node.addOutLink(
      config.action?.items?.[0].blocks?.[0],
      this.lineStartView._lineView,
    );
    this.titleView.text(str);
  }

  sequenceToString(parameter: UserFilterParameter, values: FilterValue[]) {
    return `${window.i18next.t(
      'GoToBlock-Template-8902-sequence',
    )}${sequenceOperationToString(
      parameter.operation,
    )} <b>${this.sequencesIdsToTitles(values).join(',')}</b> `;
  }

  segmentToString(parameter: UserFilterParameter, values: FilterValue[]) {
    return `${window.i18next.t(
      'GoToBlock-Template-1120-segment',
    )}${segmentOperationToString(
      parameter.operation,
    )} <b>${this.segmentIdsToTitles(values).join(',')}</b> `;
  }

  blockToString(parameter: UserFilterParameter, values: FilterValue[]) {
    return `Block <b>${this.blockIdsToTitles(values).join(
      ', ',
    )}</b> ${blockOperationToString(parameter.operation)}`;
  }

  attributeToString(parameter: UserFilterParameter, values: FilterValue[]) {
    return `${
      isEmptyString(parameter.name)
        ? `<span style="color: ${HEXColors.grey};">{{attribute}}</span>`
        : `<b style="color: ${HEXColors.blue};">{{${parameter.name}}}</b>`
    } ${attributeOperationToString(
      parameter.operation,
      values.length > 1,
    )} <b>${values.join(', ')}</b>`;
  }

  segmentIdsToTitles(segmentIds: FilterValue[]) {
    return segmentIds.map((id) => {
      let segment;
      if (id) {
        segment = getFlowControllerStrict().segmentById(id);
      }
      return !segment || isEmptyString(segment.name)
        ? window.i18next.t('GoToBlock-string--194-unknown')
        : `'${segment.name}'`;
    });
  }

  sequencesIdsToTitles(sequenceIds: FilterValue[]) {
    return sequenceIds.map((id) => {
      let group;
      if (id) {
        group = getFlowControllerStrict().groupById(id);
      }
      return !group || isEmptyString(group.title)
        ? window.i18next.t('GoToBlock-string--194-unknown')
        : `'${group.title}'`;
    });
  }

  blockIdsToTitles(blockIds: FilterValue[]) {
    return blockIds.map((id) => {
      let block;
      if (id) {
        block = getFlowControllerStrict().groupById(id);
      }
      return !block || isEmptyString(block.title)
        ? window.i18next.t('GoToBlock-string--194-unknown')
        : `'${block.title}'`;
    });
  }

  pluginDidSet() {
    this.renderNode();
  }

  pluginDidSaveError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.GoToBlockPlugin.failedToSavePluginMessageText',
        ),
      },
    });
    log({
      msg: 'Error while saving Condition Plugin',
      level: Level.error,
      data: {
        error: error.toString(),
      },
    });
  }

  hasSignificantChangesInConfig() {
    const { user_filter, action } = this.state.data.config;
    if (!user_filter) {
      return false;
    }
    if (nonEmptyArray(user_filter.parameters)) {
      return true;
    }
    if (!action) {
      return false;
    }
    return (
      nonEmptyArray(action.items) && nonEmptyArray(action.items?.[0].blocks)
    );
  }

  validationError() {
    const { data } = this.state;
    if ('is_valid' in data && !data.is_valid) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.GoToBlockPlugin.validationText',
      );
    }
    return false;
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
