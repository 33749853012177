import { pipe } from 'ramda';
import { getFlowPlatform } from '../../utils/getFlowPlatform';
import { isWhatsappBroadcastFlow } from '../../utils/isWhatsappBroadcastFlow';
import { getMenuItem, menuNodes } from './menu_view';
import { resByFunc } from '../utils';
import { isFlowHasCalendlyBlock } from '../plugins/CalendlyIntegration/utils/isFlowHasCalendlyBlock';
import { getFlowControllerStrict } from '../../PixiFieldRepository';
import { PropFunc } from '../../types';
import {
  CreateLineMenuParams,
  GetMenuItemByConfig,
  IMenuItem,
  IMenuNode,
  MenuButtonId,
} from './types';
import { Platform } from '@globals';
import * as Groups from './sortedItemsGroups';
import { isFlowHasBackInStock } from '../entry-points/EntryPointShopifyBackInStock/utils';
import { isFlowHasShopifyEntryPoint } from '../components/ShopifyAccount/utils/isFlowHasShopifyEntryPoint';
import { LineStartSubtype } from '../components/Line/types';
import { createNode } from './utils';
import { calculateAutomateEnabled } from '@utils/Data/Admin/Automate/utils';
import { flowBuilderModule } from '@components/FlowBuilder/FlowBuilderModule';
import {
  CustomAiPlugins,
  getAiCustomTemplatesList,
} from '../plugins/AiCustomTemplates/getAiCustomTemplatesList';

const getContextItems = (config: CreateLineMenuParams) => {
  const { isShowCalendlySpecificItems } = config;

  if (isShowCalendlySpecificItems) {
    return Groups.calendlySpecificItems;
  }

  return [];
};

function buildMenu(
  config: CreateLineMenuParams,
): Record<string, IMenuNode<GetMenuItemByConfig>> {
  const nodes = menuNodes();

  const contextItems = getContextItems(config);
  const contextItemsNode = createNode(nodes.contextItems, contextItems);

  const entryPointsNode = createNode(
    nodes.entryPoints,
    [
      () => createNode(nodes.facebook, Groups.entryPointsFacebook),
      () => createNode(nodes.website, Groups.entryPointsWebsite),
      ...Groups.entryPointsInstagram,
      ...Groups.entryPointsWhatsapp,
    ].filter(Boolean),
  );

  const collectUserDataNode = createNode(
    nodes.collectUserData,
    Groups.collectUserData,
  );
  const contentBlocksNode = createNode(
    nodes.contentBlocks,
    flowBuilderModule.getContentBlocksMenuCreators(),
  );
  const integrationNode = createNode(nodes.integrations, Groups.integrations);
  const notificationsNode = createNode(
    nodes.notifications,
    Groups.notifications,
  );
  const shopifyEventsNode = createNode(
    nodes.shopifyEvents,
    Groups.shopifyEvents,
  );
  const buttonsNode = createNode(nodes.buttons, Groups.buttonsBlock);
  const actionsBlocksNode = createNode(nodes.actions, [
    ...Groups.utilityBlocks,
    ...flowBuilderModule.getActionItems(),
  ]);

  const customAiTemplatesGroup = config.customAiPlugins
    ? Groups.generateCustomAiPluginsGroup(config.customAiPlugins)
    : null;
  const customAiNode = customAiTemplatesGroup
    ? createNode(nodes.customAiPlugins, customAiTemplatesGroup)
    : null;

  return {
    contextItemsNode,
    entryPointsNode,
    ...(customAiNode ? { customAiNode } : {}),
    contentBlocksNode,
    notificationsNode,
    collectUserDataNode,
    integrationNode,
    buttonsNode,
    actionsBlocksNode,
    shopifyEventsNode,
  };
}

export const prepareNodeChildren = (
  children: GetMenuItemByConfig[] | undefined,
  platform: Platform,
  config: CreateLineMenuParams,
): IMenuNode<IMenuItem>[] =>
  children
    ?.map((child) => child(config))
    .filter(Boolean)
    .filter((item) => !item?.platform || item?.platform.eq(platform))
    .map((child) => ({
      ...child,
      children: prepareNodeChildren(
        (child as IMenuNode<GetMenuItemByConfig>).children,
        platform,
        config,
      ),
    })) as IMenuNode<IMenuItem>[];

const filterMenu = (
  platform: Platform,
  config: CreateLineMenuParams,
  menuNodeItems: ReturnType<typeof buildMenu>,
) => {
  const menu = [
    menuNodeItems.contextItemsNode.children?.length
      ? menuNodeItems.contextItemsNode
      : undefined,
    config.isShowConnectToExistingBlock ? menuNodeItems.buttonsNode : undefined,
    config.isShowEntryPoints ? menuNodeItems.entryPointsNode : undefined,
    menuNodeItems.customAiNode,
    menuNodeItems.contentBlocksNode,
    menuNodeItems.collectUserDataNode,
    menuNodeItems.notificationsNode,
    menuNodeItems.actionsBlocksNode,
    !config.hideIntegrations ? menuNodeItems.integrationNode : undefined,
  ]
    .filter(Boolean)
    .filter(
      (item) => !item?.platform || item?.platform.eq(platform),
    ) as IMenuNode<GetMenuItemByConfig>[];

  return menu
    .map((node) => ({
      ...node,
      children: prepareNodeChildren(node!.children, platform, config),
    }))
    .filter((node) => node.children.length) as IMenuNode<IMenuItem>[];
};

const prepareForRendering = (menu: IMenuNode<IMenuItem>[]) =>
  menu
    .filter(
      (item) => (item as IMenuItem).id !== MenuButtonId.connectToExistingBlock,
    )
    .flat() as IMenuItem[];

export const extendConfig = (
  configFn: PropFunc<CreateLineMenuParams>,
  customAiPlugins: CustomAiPlugins,
) => {
  const config = resByFunc(configFn) || {};
  const { user, bot, botFeatures } = getFlowControllerStrict().flow;

  const shouldShowCalendlyMenuItems = isFlowHasCalendlyBlock();
  const shouldShowShopifyBackInStockMenuItems = isFlowHasBackInStock();
  const shouldShowShopifyMenuItems = isFlowHasShopifyEntryPoint();

  const whatsappBroadcastFlow = isWhatsappBroadcastFlow();
  const isShowEntryPoints = whatsappBroadcastFlow
    ? false
    : config.isShowEntryPoints;
  const isAutomateEnabled = calculateAutomateEnabled({
    bot,
    adminFeatures: user.features,
  });
  const isAdCommentsAutoreplyEnabled = false; // botFeatures.enable_ad_comments_reply;
  const isShowKommoPlugin = botFeatures.kommo;

  const extendedConfig: CreateLineMenuParams = {
    ...config,
    shouldShowShopifyBackInStockMenuItems,
    shouldShowCalendlyMenuItems,
    shouldShowShopifyMenuItems,
    isAutomateEnabled,
    isAdCommentsAutoreplyEnabled,
    isShowKommoPlugin,
    isShowEntryPoints,
    customAiPlugins,
  };

  return extendedConfig;
};

export const createLineMenu =
  (config: PropFunc<CreateLineMenuParams>) => (): IMenuNode<IMenuItem>[] => {
    const platform = getFlowPlatform() as Platform;
    const customAiPlugins = getAiCustomTemplatesList();
    const extendedConfig = extendConfig(config, customAiPlugins);

    return pipe(
      buildMenu,
      (menu) => filterMenu(platform, extendedConfig, menu),
      prepareForRendering,
    )(extendedConfig);
  };

const createCommentsAutoReplyLineMenu =
  (subtype: LineStartSubtype) => (): IMenuNode<IMenuItem>[] => {
    const platform = getFlowPlatform() as Platform;

    const nodes = menuNodes();

    const contextItems = [];

    if (platform === Platform.instagram) {
      contextItems.push(getMenuItem.blockInstagramCommentsReply({}));
    } else {
      contextItems.push(getMenuItem.blockCommentsReply({}));
    }

    if (subtype !== LineStartSubtype.commentsAutoreplyAllUsers) {
      contextItems.push(getMenuItem.blockCommentsCondition({}));
    }
    contextItems.push(getMenuItem.openAiComments({}));

    const contextItemsNode = createNode(
      nodes.contextItems,
      contextItems,
    ) as IMenuNode<IMenuItem>;

    return [contextItemsNode];
  };

export const commentsAutoReplyLineMenu = createCommentsAutoReplyLineMenu(
  LineStartSubtype.commentsAutoreplyOldUsers,
);

export const commentsAutoReplyAllUsersLineMenu =
  createCommentsAutoReplyLineMenu(LineStartSubtype.commentsAutoreplyAllUsers);
