import React from 'react';
import { Type } from '@ui/Type';
import { stripTags } from '@utils/stripTags';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { ZapierTemplatesQuery_zapierTemplates_steps } from '../@types/ZapierTemplatesQuery';
import { ZapierItemSteps } from './ZapierItemSteps';

interface ZapierTemplateItemTooltipContentProps {
  template: {
    steps: ZapierTemplatesQuery_zapierTemplates_steps[];
    description?: string;
  };
}

export const ZapierTemplateItemTooltipContent: React.FC<ZapierTemplateItemTooltipContentProps> = ({
  template: { description, steps },
}) => (
  <>
    <Flex>
      <ZapierItemSteps steps={steps} />
    </Flex>
    {description && (
      <>
        <Spacer factor={2} />
        <Type size="12px" color="white" as="div">
          {stripTags(description)}
        </Type>
      </>
    )}
  </>
);

export const createZapierTemplateItemTooltipContent = (
  props: ZapierTemplateItemTooltipContentProps,
) => <ZapierTemplateItemTooltipContent {...props} />;
