export const DEFAULT_LANGUAGE = 'default';

export const DEFAULT_LANGUAGE_NAME = 'All languages';

export const LANGUAGES = () => [
  DEFAULT_LANGUAGE,
  window.i18next.t('languages-string-1356-afrikaans'),
  window.i18next.t('languages-string-1578-albanian'),
  window.i18next.t('languages-string-1969-arabic'),
  window.i18next.t('languages-string--136-armenian'),
  window.i18next.t('languages-string--312-assamese'),
  window.i18next.t('languages-string--436-azerbaijani'),
  window.i18next.t('languages-string-1982-basque'),
  window.i18next.t('languages-string-5595-belarusian'),
  window.i18next.t('languages-string-1441-bengali'),
  window.i18next.t('languages-string-1733-bosnian'),
  window.i18next.t('languages-string-1997-breton'),
  window.i18next.t('languages-string--117-bulgarian'),
  window.i18next.t('languages-string-1903-burmese'),
  window.i18next.t('languages-string--207-catalan'),
  window.i18next.t('languages-string--197-cebuano'),
  window.i18next.t('languages-string--388-corsican'),
  window.i18next.t('languages-string--212-croatian'),
  window.i18next.t('languages-string-6561-czech'),
  window.i18next.t('languages-string-2039-danish'),
  window.i18next.t('languages-string-6639-dutch'),
  window.i18next.t('languages-string-6089-english'),
  window.i18next.t('languages-string--188-estonian'),
  window.i18next.t('languages-string-5864-faroese'),
  window.i18next.t('languages-string--666-filipino'),
  window.i18next.t('languages-string-8117-finnish'),
  window.i18next.t('languages-string-2112-french'),
  window.i18next.t('languages-string-1064-frisian'),
  window.i18next.t('languages-string-6823-fulah'),
  window.i18next.t('languages-string--172-galician'),
  window.i18next.t('languages-string-1915-georgian'),
  window.i18next.t('languages-string-2129-german'),
  window.i18next.t('languages-string-6906-greek'),
  window.i18next.t('languages-string-2030-guarani'),
  window.i18next.t('languages-string--122-gujarati'),
  window.i18next.t('languages-string-6949-hausa'),
  window.i18next.t('languages-string--213-hebrew'),
  window.i18next.t('languages-string-6973-hindi'),
  window.i18next.t('languages-string--165-hungarian'),
  window.i18next.t('languages-string-2728-icelandic'),
  window.i18next.t('languages-string--155-indonesian'),
  window.i18next.t('languages-string-7091-irish'),
  window.i18next.t('languages-string--517-italian'),
  window.i18next.t('languages-string--688-japanese'),
  window.i18next.t('languages-string--516-javanese'),
  window.i18next.t('languages-string-7252-kannada'),
  window.i18next.t('languages-string--205-kazakh'),
  window.i18next.t('languages-string-7247-khmer'),
  window.i18next.t('languages-string-1953-kinyarwanda'),
  window.i18next.t('languages-string--204-korean'),
  window.i18next.t('languages-string-1301-kurdish'),
  window.i18next.t('languages-string-1618-latvian'),
  window.i18next.t('languages-string--129-lithuanian'),
  window.i18next.t('languages-string-1675-macedonian'),
  window.i18next.t('languages-string-1306-malagasy'),
  window.i18next.t('languages-string-7410-malay'),
  window.i18next.t('languages-string--228-malayalam'),
  window.i18next.t('languages-string--179-maltese'),
  window.i18next.t('languages-string--179-marathi'),
  window.i18next.t('languages-string-3391-mongolian'),
  window.i18next.t('languages-string--196-nepali'),
  window.i18next.t('languages-string--176-norwegian'),
  window.i18next.t('languages-string-7645-oriya'),
  window.i18next.t('languages-string--191-pashto'),
  window.i18next.t('languages-string-9862-persian'),
  window.i18next.t('languages-string--189-polish'),
  window.i18next.t('languages-string--146-portuguese'),
  window.i18next.t('languages-string-1440-punjabi'),
  window.i18next.t('languages-string--176-romanian'),
  window.i18next.t('languages-string--107-russian'),
  window.i18next.t('languages-string--138-sardinian'),
  window.i18next.t('languages-string--646-serbian'),
  window.i18next.t('languages-string-5117-silesian'),
  window.i18next.t('languages-string-1132-simplified-chinese'),
  window.i18next.t('languages-string--535-sinhala'),
  window.i18next.t('languages-string--181-slovak'),
  window.i18next.t('languages-string--154-slovenian'),
  window.i18next.t('languages-string--181-somali'),
  window.i18next.t('languages-string--189-sorani-kurdish'),
  window.i18next.t('languages-string--347-spanish'),
  window.i18next.t('languages-string--146-swahili'),
  window.i18next.t('languages-string--143-swedish'),
  window.i18next.t('languages-string-8057-tajik'),
  window.i18next.t('languages-string-8931-tamazight'),
  window.i18next.t('languages-string-8057-tamil'),
  window.i18next.t('languages-string--179-telugu'),
  window.i18next.t('languages-string-2605-thai'),
  window.i18next.t('languages-string--130-traditional-chinese'),
  window.i18next.t('languages-string-6990-turkish'),
  window.i18next.t('languages-string--539-ukrainian'),
  window.i18next.t('languages-string-2645-urdu'),
  window.i18next.t('languages-string-8223-uzbek'),
  window.i18next.t('languages-string--177-vietnamese'),
  window.i18next.t('languages-string-8346-welsh'),
];
