import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { facebookAdsEntryPointFragment_config as FacebookAdsEntryPointConfig } from '../../../../Plugins/FacebookAdsEntryPoint/@types/facebookAdsEntryPointFragment';
import { InstagramAdsEntryPointConfig } from '../../../../Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { Node } from '../../../Node';
import { pluginWidth } from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { Platform } from '@globals';
import { GreetingAdsTypeSelector } from '../common/components/GreetingAdsTypeSelector';
import { VLayout } from '../../../components/Elements/Layouts';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';

export class EntryPointFacebookAdsJson
  extends VLayout
  implements StatefulPluginDelegate<FacebookAdsEntryPointConfig>
{
  TEST_NAME = 'EntryPointFacebookAdsJson';
  private _node: Node;
  public state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;
  typeSelector: GreetingAdsTypeSelector;

  constructor(
    state: StatefulPlugin<
      FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
    >,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.typeSelector = new GreetingAdsTypeSelector(
      state,
      this._node,
      Platform.facebook,
    );
    this.addToLayout(this.typeSelector, {
      margin: { top: -16 },
    });
  }

  pluginDidSet() {}

  pluginWillSave() {}

  validationError() {
    return validateIsPageConnecting(this._node);
  }

  onDestroy() {
    this.state.destroy();
  }
}
