import { VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import {
  PLUGIN_TYPE,
  SplitTrafficPluginConfig,
} from '../../../../Plugins/SplitTrafficPlugin/SplitTrafficPluginConst';
import { Node } from '../../../Node';
import {
  buttonWidth,
  pluginWidth,
  textCardBackgroundColor,
} from '../../plugin_consts';
import { PlusButtonView } from '../../plus_button_view';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { Checkbox } from '../../kit/Checkbox';
import { tooltipScaled } from '../../helpers/TooltipHelpers';
import i18next from 'i18next';
import { DEFAULT_VARIANT } from './consts';
import { clone } from 'ramda';
import {
  buttonControl,
  removeButtonControl,
} from '../../helpers/ControlsHelpers';
import { splitTrafficPluginFragment_config_variants } from '../../../../Plugins/SplitTrafficPlugin/@types/splitTrafficPluginFragment';
import { VariantView } from './components/VariantView';
import { cardConfigIsValid } from './helpers/cardConfigIsValid';

export class SplitTrafficPlugin
  extends VLayout
  implements StatefulPluginDelegate<SplitTrafficPluginConfig>
{
  TEST_NAME = 'SplitTrafficPluginView';
  public readonly _node: Node;
  private readonly state: StatefulPlugin<SplitTrafficPluginConfig>;
  private readonly variantsLayout: VLayout;
  private readonly addButton: PlusButtonView;

  constructor(state: StatefulPlugin<SplitTrafficPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this.state = state;
    this.state.setDelegate(this);

    this._node = node;

    this.variantsLayout = new VLayout();
    this.addToLayout(this.variantsLayout);

    this.addButton = new PlusButtonView(
      i18next.t('split_traffic_plugin-string-9053-add-variant'),
      buttonWidth,
    );
    this.layout(this.addButton, {
      margin: { left: 15, bottom: 15 },
      gone: () =>
        !(
          this.state.isEditing &&
          (this.state.data.config.variants?.length || 0) < 10
        ),
    });
    this.addButton.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.addButton.on('click', (e) => {
      e.stopPropagation();
      const newVariant = clone(DEFAULT_VARIANT);
      const variants = [...(this.state.data.config.variants || []), newVariant];
      this.state.set(({ config }) => ({
        config: {
          ...config,
          variants,
        },
      }));
      this.state.save();
      this.addVariant(newVariant, variants.length - 1);
      this.renderNode();
      logFlowPluginEvent(PLUGIN_TYPE, 'add variant', {
        blockId: this._node.id,
        cardId: this.state.data.id,
      });
    });

    const randomCheckbox = new Checkbox({
      text: i18next.t(
        'split_traffic_plugin-string-3998-send-user-to-same-variant',
      ),
      value: !this.state.data.config.random,
      onChange: (newValue) => {
        this.state.set(({ config }) => ({
          config: {
            ...config,
            random: !newValue,
          },
        }));
        this.state.save();
        logFlowPluginEvent(PLUGIN_TYPE, 'change random', {
          value: !newValue,
          blockId: this._node.id,
          cardId: this.state.data.id,
        });
      },
    });

    this.layout(randomCheckbox, {
      gone: () => !this.state.isEditing,
      margin: { bottom: 15, left: 16 },
    });

    tooltipScaled({
      view: randomCheckbox,
      text: i18next.t(
        'modernComponents.FlowBuilder.views.splitTrafficPlugin.checkboxTooltip',
      ),
    });
    this.renderVariants();
  }

  addVariant(
    variant: splitTrafficPluginFragment_config_variants,
    index: number,
  ) {
    const variantView = new VariantView({
      variant,
      isValid: () => cardConfigIsValid(this.state.data.config),
      isEditing: () => this.state.isEditing,
      index,
      node: this._node,
      onChange: (variant) => {
        const variants = [...(this.state.data.config.variants || [])];
        variants.splice(index, 1, variant);
        this.state.set(({ config }) => ({
          config: {
            ...config,
            variants,
          },
        }));
        this.state.save();
        logFlowPluginEvent(PLUGIN_TYPE, 'update percent', {
          cardId: this.state.data.id,
          value: variant.percent,
        });
      },
    });
    this.variantsLayout.addToLayout(variantView, {
      margin: () => {
        const idx = this.variantsLayout.findViewIndex(variantView);
        return {
          top: idx === 0 ? 15 : 10,
          bottom: idx === this.variantsLayout.size() - 1 ? 15 : 0,
          left: 2,
        };
      },
    });
  }

  renderVariants() {
    this.variantsLayout.removeAllViews();
    this.state.data.config?.variants?.forEach((variant, index) => {
      this.addVariant(variant, index);
    });
  }

  addButtonControl(view: VariantView) {
    buttonControl(
      view,
      undefined,
      undefined,
      () => {
        const variants = [...(this.state.data.config.variants || [])];
        variants.splice(view.index, 1);
        this.state.set(({ config }) => ({
          config: {
            ...config,
            variants,
          },
        }));
        this.state.save();
        logFlowPluginEvent(PLUGIN_TYPE, 'remove variant', {
          blockId: this._node.id,
          cardId: this.state.data.id,
        });
        this.renderVariants();
        this.renderNode();
      },
      undefined,
    );
  }

  validationError() {
    return !cardConfigIsValid(this.state.data.config);
  }

  onBeforeRender() {
    const removeBtnAvailable = this.variantsLayout.size() > 2;
    this.variantsLayout.views().forEach((v) => {
      if (removeBtnAvailable) {
        this.addButtonControl(v as VariantView);
      } else {
        removeButtonControl(v);
      }
    });
    this._node.updateLines();
  }

  pluginDidSet() {
    this.renderNode();
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
