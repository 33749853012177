export enum ValidationError {
  permission,
}

export type ValidationResult = {
  type?: ValidationError.permission;
  message: string;
  onClick?: () => void;
  isBlockError: boolean;
};

export interface Validator<T> {
  validate(): T;
}
