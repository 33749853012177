import i18next from 'i18next';
import { mongoObjectId } from 'cf-common/src/utils/mongo/mongoObjectId';
import { HEXColors } from '@ui/_common/colors';
import { tooltipScaled } from '../../../../helpers/TooltipHelpers';
import { QRButtonView } from '../../../../qr_button_view';
import { ButtonView } from '../../../../button_view';
import { buttonControl } from '../../../../helpers/ControlsHelpers';
import { getPageInfoFromNode } from '../../../../utils';
import { VLayout } from '../../../../../components/Elements/Layouts';
import { Node } from '../../../../../Node';
import { ControlVisibility } from '../../../../../FlowBuilderOverlay';
import { Loader } from '../../../../loader';
import { QRPlusButtonView } from '../../../../qr_plus_button_view';
import {
  blockWidth,
  buttonHeight,
  pluginWidth,
  textCardBackgroundColor,
} from '../../../../plugin_consts';
import { IceBreaker, FAQProps, IcebreakableCard } from './types';
import { platformToString } from './utils';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType, OnboardingTourShape } from '@globals';

export class FAQView extends VLayout {
  TEST_NAME = 'FAQView';

  private _node: Node;

  // need for external usage
  _card: IcebreakableCard;

  private fillWithExternalSourceLoading = false;

  private fillWithExternalSourceLoader: Loader;

  private buttonsLayout: VLayout;

  private plusButtonView: QRPlusButtonView;

  private fillWithExternalSourceButton: ButtonView;

  private props: FAQProps;

  private getData: () => IcebreakableCard;

  constructor(
    card: IcebreakableCard,
    getData: () => IcebreakableCard,
    node: Node,
    props: FAQProps = {},
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this._card = card;
    this._node = node;
    this.props = props;
    this.getData = getData;

    this.buttonsLayout = new VLayout({});
    this.addToLayout(this.buttonsLayout, {
      bottom: 15,
      cornerRadius: 10,
      width: pluginWidth,
      margin: { left: 16, top: 15 },
      fill: textCardBackgroundColor,
    });

    this.plusButtonView = new QRPlusButtonView({
      title: window.i18next.t('FAQ-string--240-add-faq-option'),
    });
    this.plusButtonView.on('pointerdown', (e: MouseEvent) => {
      e.stopPropagation();
    });
    this.plusButtonView.on('click', (e: MouseEvent) => {
      e.stopPropagation();

      const iceBreaker: IceBreaker = {
        __typename: 'IceBreaker',
        title: '',
        block_id: null,
        counter_id: mongoObjectId(),
      };
      const buttonView = this.addButton(
        iceBreaker,
        this.getData().config.ice_breakers?.length ?? 0,
      );
      this.props.onIceBreakerAdd?.(iceBreaker);
      this.renderNode();
      buttonView.focus();
    });
    this.addToLayout(this.plusButtonView, {
      margin: () => ({
        left: blockWidth - 200 - 55,
        bottom: 15,
      }),
      gone: () =>
        (!this.isCardEditing() &&
          this.getData().config.ice_breakers?.some((ib) => ib.title)) ||
        this.isIceBreakersLimitReached(),
    });

    this.fillWithExternalSourceButton = new ButtonView({
      title: this.props.externalSourceButtonTitle ?? '',
      width: pluginWidth - 32,
      onClick: async (event) => {
        event.stopPropagation();

        this.fillWithExternalSourceLoading = true;
        this.fillWithExternalSourceLoader.start();
        this.renderNode();
        await this.props.onFillWithExternalSource?.(this.updateIceBreakers);
        this.fillWithExternalSourceLoader.stop();
        this.fillWithExternalSourceLoading = false;
        this.renderNode();
      },
    });

    const fillWithExternalSourceButtonMargin = () => ({
      bottom: this.getData().enabled && this.isCardEditing() ? 10 : 15,
      left: 16,
      right: 16,
    });
    this.addToLayout(this.fillWithExternalSourceButton, {
      margin: fillWithExternalSourceButtonMargin,
      gone: () => {
        const hasFilledIcebreaker = this.getData().config.ice_breakers?.some(
          (ib) => ib.title || ib.block_id,
        );
        return (
          !this.props.onFillWithExternalSource ||
          this.props.fillWithExternalSource?.() ||
          hasFilledIcebreaker ||
          this.fillWithExternalSourceLoading
        );
      },
    });

    if (this.props.externalSourceButtonTooltipText) {
      tooltipScaled({
        view: this.fillWithExternalSourceButton,
        text: this.props.externalSourceButtonTooltipText,
      });
    }

    this.fillWithExternalSourceLoader = this.createLoader(HEXColors.white);
    this.addToLayout(this.fillWithExternalSourceLoader, {
      margin: fillWithExternalSourceButtonMargin,
      gone: () => !this.fillWithExternalSourceLoading,
    });

    this.getData().config.ice_breakers?.forEach((ib, i) =>
      this.addButton(ib, i),
    );
  }

  removeButtons() {
    const views = this.buttonsLayout.views();
    views.forEach((v) => this.buttonsLayout.removeView(v));
    views.forEach((v) => v.destroy());
  }

  private updateIceBreakers = () => {
    this.removeButtons();
    this.getData().config.ice_breakers?.forEach((ib, i) =>
      this.addButton(ib, i),
    );
  };

  onBeforeRender() {
    this.fillWithExternalSourceButton.setDisabled(
      !getPageInfoFromNode(this._node),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createLoader(fill: HEXColors, texture?: any) {
    return new Loader(
      {
        width: pluginWidth - 32,
        height: buttonHeight,
        background: {
          fill,
          cornerRadius: 4,
        },
      },
      texture,
    );
  }

  addButton(iceBreaker: IceBreaker, i: number) {
    const qrButtonView = new QRButtonView({
      buttonShapeName: `${OnboardingTourShape.IceBreakerTitleButtonShape}${i}`,
      buttonConfig: iceBreaker,
      node: this._node,
      card: this.getData() as any,
      editable: true,
      lineLayout: { margin: { left: 277 } },
      maxLength: 80,
      caption: window.i18next.t('FAQ-string--852-add-question'),
      updateCardViaDeprecatedMethod: this.props.updateCardViaDeprecatedMethod,
      isLineMandatory: () => !this.isCardEditing(),
      /* eslint-disable no-param-reassign */
      onConnected: (blockView, index) => {
        this.props.onIceBreakerConnect?.(iceBreaker, index, blockView);
        this.props.onChangesSynced?.(false);
        this.renderNode();
      },
      onRemoved: (index) => {
        this.props.onIceBreakerDisconnect?.(iceBreaker, index);
        this.props.onChangesSynced?.(false);
        this.renderNode();
      },
      onTitleChangeProcess: (newValue) =>
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          element: `${OnboardingTourShape.IceBreakerTitleButtonShape}${i}`,
          value: newValue,
        }),
      /* eslint-enable no-param-reassign */
      onTitleChange: (newValue, index) => {
        this.props.onIceBreakerTitleChange?.(iceBreaker, index, newValue);
        this.props.onChangesSynced?.(false);
        this.renderNode();
      },
      attributesControlVisibility: ControlVisibility.hide,
    });
    this.buttonsLayout.addToLayout(qrButtonView, {
      margin: () => {
        return { bottom: this.buttonsLayout.isLast(qrButtonView) ? 15 : 10 };
      },
    });
    buttonControl(
      qrButtonView,
      this.buttonsLayout,
      () => {
        this._node.updateLines();
      },
      () => {
        this.props.onIceBreakerRemove?.(
          iceBreaker,
          qrButtonView.getQuickReplyIndex(),
        );
        this.buttonsLayout.removeView(qrButtonView);
        qrButtonView.destroy();
        this._node.updateLines();
        this.props.onChangesSynced?.(false);
        this.renderNode();
      },
      (_: any, startIdx: number, idx: number) => {
        this.props.onIceBreakerMove?.(iceBreaker, startIdx, idx);
        this.props.onChangesSynced?.(false);
        this.renderNode();
      },
    );
    return qrButtonView;
  }

  validationError() {
    if (this.isCardEditing() || !this.getData()) {
      return false;
    }
    if (!this.getData().config.ice_breakers?.every((q) => q.block_id)) {
      return true;
    }
    return this.validateIceBreakersLimit();
  }

  validateIceBreakersLimit() {
    if (this.isIceBreakersLimitExceeded()) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.iceBreakersLimitTooltip',
          {
            limit: this.props.maxIceBreakers,
            platform: platformToString(this.props.platform),
          },
        ),
        isBlockError: true,
      };
    }
    return undefined;
  }

  private isCardEditing() {
    return this.getData().isEditing ?? this.props.isEditing?.();
  }

  private isIceBreakersLimitReached() {
    return (
      typeof this.props.maxIceBreakers === 'number' &&
      (this.getData().config.ice_breakers?.length ?? 0) >=
        this.props.maxIceBreakers
    );
  }

  private isIceBreakersLimitExceeded() {
    return (
      typeof this.props.maxIceBreakers === 'number' &&
      (this.getData().config.ice_breakers?.length ?? 0) >
        this.props.maxIceBreakers
    );
  }
}
