import { HEXColors } from '@ui/_common/colors';
import { VLayout } from '../../components/Elements/Layouts';
import { pluginWidth } from '../plugin_consts';

export class GrayBoxView extends VLayout {
  constructor() {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
      cursor: {
        in: 'default',
      },
    });
  }
}
