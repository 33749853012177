import { Platform } from '@globals';
import { BlockView } from '../../../../block_view';
import { instagramDirectEntryPointFragment_config_ice_breakers as IceBreaker } from '../../../../../../Plugins/InstagramDirectEntryPoint/@types/instagramDirectEntryPointFragment';

export { IceBreaker };

export interface FAQProps {
  platform?: Platform;
  externalSourceButtonTitle?: string;
  externalSourceButtonTooltipText?: string;
  fillWithExternalSource?(): boolean;
  updateCardViaDeprecatedMethod?: boolean;
  onIceBreakerAdd?(iceBreaker: IceBreaker): void;
  onIceBreakerTitleChange?(
    iceBreaker: IceBreaker,
    index: number,
    newValue: string,
  ): void;
  onIceBreakerConnect?(
    iceBreaker: IceBreaker,
    index: number,
    blockView?: BlockView,
  ): void;
  onIceBreakerDisconnect?(iceBreaker: IceBreaker, index: number): void;
  onIceBreakerRemove?(iceBreaker: IceBreaker, index: number): void;
  onIceBreakerMove?(
    iceBreaker: IceBreaker,
    fromIndex: number,
    toIndex: number,
  ): void;
  onFillWithExternalSource?(updateIceBreakers: () => void): Promise<void>;
  onChangesSynced?(newState: boolean): void;
  isEditing?(): boolean;
  maxIceBreakers?: number;
}

export interface IcebreakableCard {
  isEditing?: boolean;
  enabled?: boolean | null;
  config: {
    ice_breakers?: IceBreaker[] | null;
  };
}
