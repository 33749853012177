import {
  makeContextTypeToSubtypeToBlockTitle,
  makeEntryPointIdToSubtitle,
  makeSubtypeToBlockTitle,
} from '../../consts';
import { getEntryPointCard } from '../entry-points/common/utils';
import { BlockContextType } from '@globals';

export interface WithPluginId {
  plugin_id: string;
}

interface BlockLike {
  subtype: string;
  cards: WithPluginId[] | undefined;
  context_type?: BlockContextType;
}

export const getBlockTitleByEntryPointId = (pluginId: string) =>
  makeEntryPointIdToSubtitle()[pluginId];

export const getBlockTitle = ({ subtype, cards, context_type }: BlockLike) =>
  (context_type &&
    makeContextTypeToSubtypeToBlockTitle()[context_type]?.[subtype]) ??
  makeSubtypeToBlockTitle()[subtype] ??
  (cards &&
    getBlockTitleByEntryPointId(getEntryPointCard(cards)?.plugin_id || ''));
