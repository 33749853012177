import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { defaultObj, MainLayout } from '../../components/Elements/Layouts';
import { resByFunc } from '../utils';
import { ControlVisibility } from '../../FlowBuilderOverlay';
import {
  TextEditView,
  TextEditViewProps,
  TextOutsideControlsVisibility,
} from '../text_edit_view';
import {
  inactiveStrokeColor,
  invalidStrokeColor,
  mainStrokeColor,
  textCardBackgroundColor,
} from '../plugin_consts';

interface EditableCard {
  isEditing?: boolean;
}

export class SetAttributeView extends TextEditView {
  TEST_NAME = 'SetAttributeView';

  private get editing(): boolean {
    return this._props.isEditing?.() ?? !!this._card.isEditing;
  }

  private _card: EditableCard;

  constructor(
    card: EditableCard,
    props: TextEditViewProps,
    onTextChanged?: (text: string) => void,
    onDoneEditing?: (text: string) => void,
    onStartEditing?: () => void,
    textOutsideControlsVisibility?: TextOutsideControlsVisibility,
    predefinedAttributes?: AttributesQuery_bot_variableSuggest[],
  ) {
    super(
      defaultObj(
        {
          fontSize: 15,
          height: 35,
          maxLines: 1,
          editable: () => this.editing,
          verticalAlign: 'center',
          fill: 'black',
          captionFill: inactiveStrokeColor,
          margin: { left: 10, right: 10 },
          handleOutsideClick: true,
          singleLine: true,
          background: {
            cornerRadius: 3,
            fill: () => (this.editing ? 'white' : textCardBackgroundColor),
            stroke: (v?: MainLayout) =>
              v?._textAreaShown
                ? mainStrokeColor
                : typeof props.isValid !== 'undefined' &&
                  !resByFunc(props.isValid)
                ? invalidStrokeColor
                : inactiveStrokeColor,
            strokeWidth: (v?: MainLayout) =>
              this.editing
                ? v?._textAreaShown ||
                  (typeof props.isValid !== 'undefined' &&
                    !resByFunc(props.isValid))
                  ? 2
                  : 1
                : 0,
          },
          attributeOnly: false,
          customAttributesOnly: false,
        },
        props,
      ),
      onStartEditing,
      onTextChanged,
      onDoneEditing,
      defaultObj(
        {
          emoji: ControlVisibility.show,
          attributes: ControlVisibility.show,
          discount: ControlVisibility.hide,
        },
        textOutsideControlsVisibility ?? {},
      ),
      predefinedAttributes,
      false,
    );

    this._card = card;
  }
}
