import i18next from 'i18next';
import { T, trim } from 'ramda';
import { escapeHtml } from 'xss';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import { ItemStatView, STAT_VIEW_WIDTH } from './ItemStatView/ItemStatView';
import { FullCounterStats } from '../../utils/Statistics/types';
import { OverlayFieldBase, OverlayFieldCommonProps } from './OverlayFieldBase';
import { OverlayType } from '../../FlowBuilderOverlay';
import { resByFunc } from '../utils';
import { PropFunc } from '../../types';
import {
  inactiveStrokeColor,
  invalidStrokeColor,
  mainStrokeColor,
} from '../plugin_consts';
import { HEXColors } from '@ui/_common/colors';

interface BubbleInputFieldConfig {
  value: string[];
}

interface BubbleInputFieldProps
  extends OverlayFieldCommonProps<BubbleInputFieldConfig> {
  errorLines?: PropFunc<string[]>;
  stats?: FullCounterStats;
}

const BUBBLE_STYLE = removeExtraSpaces(`
    display:inline-block;
    margin: 4px 0 0 4px;
    line-height: 16px;
    padding: 5px 12px 7px;
    background-color: ${HEXColors.greyLight10};
    border-radius: 14px;
    border: none;
    border-color: transparent;
    font-size: 15px;
    color: ${HEXColors.black};
    word-break: break-all;
  `);

export class BubbleInputField extends OverlayFieldBase<BubbleInputFieldConfig> {
  TEST_NAME = 'BubbleInputField';
  props: BubbleInputFieldProps;
  private readonly itemStatView: ItemStatView | undefined;

  constructor({ stats, ...props }: BubbleInputFieldProps) {
    super({
      ...props,
      background: {
        fill: HEXColors.white,
        stroke: (view: any) => {
          const hasErrors = !!resByFunc(props.errorLines)?.length;
          const hasValues = !!view.config?.value?.filter(Boolean).length;
          if (hasValues) {
            view.updateText();
          }
          return view.overlayShown
            ? mainStrokeColor
            : hasErrors || !hasValues
            ? invalidStrokeColor
            : inactiveStrokeColor;
        },
        strokeWidth: 1,
        cornerRadius: 4,
      },
      fontSize: 15,
      overlayType: OverlayType.bubbleInput,
    });

    if (stats) {
      this.itemStatView = new ItemStatView({
        stats,
        showPercentValue: true,
        showBackground: true,
      });
      this.layout(this.itemStatView, {
        gone: (v) => {
          const hasErrors = !!resByFunc(props.errorLines)?.length;
          const hasValues = !!(v as BubbleInputField).config?.value?.filter(
            Boolean,
          ).length;
          return this.overlayShown || hasErrors || !hasValues;
        },
        margin: {
          top: 7.5,
          left: -(STAT_VIEW_WIDTH - 34),
        },
        align: 'end',
      });
    }

    this.props = props;
    if (this.htmlTextNode.layoutProperties) {
      this.htmlTextNode.layoutProperties.margin = { top: 2, bottom: 1 };
    }
  }

  onBeforeRender() {
    super.onBeforeRender();
    this.setSingleLine(!resByFunc(this.props?.editable));
  }

  configToHtml() {
    const currentErrorLines = resByFunc(this.props?.errorLines) || [];
    const value = [...(this.config.value || []), ''];
    return `<div style="padding:0 4px 4px 0;min-height: 36px;max-height: 300px;overflow: hidden">${value
      .filter(this.isEditing() ? T : Boolean)
      .map(escapeHtml)
      .map(
        (itemString) =>
          `<span style="${BUBBLE_STYLE}${!itemString ? ';opacity:0;' : ''}${
            itemString && currentErrorLines.includes(itemString.toLowerCase())
              ? `;background-color:${HEXColors.red};color:${HEXColors.white};`
              : ''
          }">${itemString || '.'}</span>`,
      )
      .join('')}</div>`;
  }

  getOverlayOptions() {
    const { width, height } = this.props;
    return {
      width,
      height,
    };
  }

  onChangeHandler() {
    this.renderNode();
  }

  configPostProcessor({ value }: BubbleInputFieldConfig) {
    return {
      value: value.map(trim).filter(Boolean),
    };
  }

  prepareCaption() {
    return !this.config.value?.filter(Boolean).length
      ? {
          fill: HEXColors.red,
          text: `<div style="line-height: 36px;padding: 0 12px;">${i18next.t(
            'modernComponents.FlowBuilder.views.kit.BubbleInputField.addKeyword',
          )}</div>`,
        }
      : undefined;
  }
}
