import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { HEXColors } from '@ui/_common/colors';
import { __, prop, propEq } from 'ramda';
import { humanizeNumber } from '@utils/humanizeNumber';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { BLOCK_SUBTYPES } from '../../../consts';
import { getEntryPointCard } from '../../entry-points/common/utils';
import { Block } from '../../../types';
import { RevenueStatItem } from './components/RevenueStatItem';
import { createStatTooltip } from './components/StatTooltip';
import { tooltipScaled } from '../../helpers/TooltipHelpers';
import { getFullBlockStats } from './utils';
import { FullStats } from '../../../utils/Statistics/types';
import {
  HLayout,
  LayoutProps,
  MainLayout,
  VLayout,
} from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';

interface BlockStatProps extends LayoutProps {
  block: Block;
}

export enum StatFieldsIds {
  sent = 'sent',
  delivered = 'delivered',
  seen = 'seen',
  clicked = 'clicked',
}

export enum ItemWidth {
  withRevenue = 62,
  withOutRevenue = 75,
}

const SUBTYPE_BLOCK_WITH_REVENUE = [BLOCK_SUBTYPES.reminder];

const STAT_FIELDS = () => [
  {
    id: StatFieldsIds.sent,
    title: window.i18next.t('BlockStatsView-string-2573-sent'),
  },
  {
    id: StatFieldsIds.delivered,
    title: window.i18next.t('BlockStatsView-string-1761-delivered'),
  },
  {
    id: StatFieldsIds.seen,
    title: window.i18next.t('BlockStatsView-string-2572-seen'),
  },
  {
    id: StatFieldsIds.clicked,
    title: window.i18next.t('BlockStatsView-string--176-clicked'),
  },
];

const PLUGIN_STAT_FIELDS_ALIASES: () => Record<string, Record<string, string>> =
  memoize(() => ({
    [PluginType.comments_autoreply_entry_point]: {
      [StatFieldsIds.clicked]: window.i18next.t(
        'BlockStatsView-string-5089-commented',
      ),
    },
    [PluginType.ad_comments_autoreply_entry_point]: {
      [StatFieldsIds.clicked]: window.i18next.t(
        'BlockStatsView-string-5089-commented',
      ),
    },
    [PluginType.shopify_event_entry_point]: {
      [StatFieldsIds.sent]: window.i18next.t(
        'BlockStatsView-string-1355-triggered',
      ),
    },
    [PluginType.inbound_links_entry_point]: {
      [StatFieldsIds.clicked]: window.i18next.t(
        'BlockStatsView-string-1355-triggered',
      ),
    },
  }));

const BLOCK_STAT_FIELDS_ALIASES: () => Record<string, Record<string, string>> =
  memoize(() => ({
    [BLOCK_SUBTYPES.calendly]: {
      [StatFieldsIds.clicked]: window.i18next.t(
        'BlockStatsView-string-1355-triggered',
      ),
    },
  }));

const SHOW_AS_PERCENT = [
  StatFieldsIds.seen,
  StatFieldsIds.clicked,
  StatFieldsIds.delivered,
];

const isNeedShowRevenue = (block: Block) =>
  SUBTYPE_BLOCK_WITH_REVENUE.includes(block.subtype);

const createStatItem = (
  fieldId: StatFieldsIds,
  { uniqStats, notUniqStats }: FullStats,
  block: Block,
) => {
  const entryPointCard = getEntryPointCard(block.cards);
  const isEntryPointStat = !!entryPointCard;
  const hideUniqStat =
    entryPointCard &&
    [
      PluginType.persistent_menu_entry_point,
      PluginType.instagram_persistent_menu_entry_point,
    ].includes(entryPointCard.plugin_id);
  const showPeopleTabLinkForSeen =
    !entryPointCard ||
    ![
      PluginType.customer_chat_entry_point,
      PluginType.shopify_customer_chat_entry_point,
      PluginType.send_to_messenger_entry_point,
      PluginType.popup_entry_point,
      PluginType.whatsapp_widget_entry_point,
      PluginType.whatsapp_popup_entry_point,
    ].includes(entryPointCard.plugin_id);
  const title =
    (entryPointCard &&
      PLUGIN_STAT_FIELDS_ALIASES()[entryPointCard.plugin_id]?.[fieldId]) ||
    BLOCK_STAT_FIELDS_ALIASES()[block.subtype]?.[fieldId] ||
    STAT_FIELDS().find(propEq('id', fieldId))?.title ||
    '';

  const box = new MainLayout({
    width: isNeedShowRevenue(block)
      ? ItemWidth.withRevenue
      : ItemWidth.withOutRevenue,
    background: {
      fill: HEXColors.white,
      opacity: 0,
    },
  });

  const itemBox = new VLayout({
    background: {
      fill: HEXColors.white,
      opacity: 0,
    },
  });

  itemBox.addToLayout(
    new HTMLText({
      text: title,
      fontSize: 12,
      fill: HEXColors.greyDark,
    }),
  );

  const stat = (hideUniqStat ? notUniqStats : uniqStats) ?? {};

  const value = stat[fieldId];
  const percentBase = STAT_FIELDS()
    .map(prop('id'))
    .slice(0, STAT_FIELDS().findIndex(propEq('id', fieldId)))
    .map(prop(__, stat))
    .filter(Boolean)[0];

  const isPercentValue =
    SHOW_AS_PERCENT.includes(fieldId) && typeof percentBase === 'number';
  const hasValue = typeof value === 'number';
  const showValue = hasValue
    ? `${humanizeNumber(
        isPercentValue
          ? percentBase! > 0
            ? Math.round((value! / percentBase!) * 100)
            : 0
          : value!,
      )}${isPercentValue ? '%' : ''}`
    : '—';

  itemBox.addToLayout(
    new HTMLText({
      text: showValue,
      fontSize: 15,
      fill: HEXColors.black,
    }),
  );

  if (!hasValue) {
    tooltipScaled({
      view: itemBox,
      text: `${i18next.t(
        'modernComponents.FlowBuilder.views.components.BlockStatsView.dataCantCollected',
      )} ${
        isEntryPointStat
          ? window.i18next.t('BlockStatsView-string--934-entry-point')
          : window.i18next.t('BlockStatsView-string-9383-block')
      }`,
    });
  }

  if (hasValue && (isPercentValue || !hideUniqStat)) {
    tooltipScaled({
      view: itemBox,
      overTimeout: 500,
      children: createStatTooltip({
        uniqValue: !hideUniqStat ? value || 0 : undefined,
        notUniqValue: (notUniqStats ?? {})[fieldId] ?? undefined,
        fieldId,
        blockId: block.id,
        blockTitle: block.title,
        showPeopleTabLink:
          !hideUniqStat &&
          (showPeopleTabLinkForSeen || fieldId !== StatFieldsIds.seen),
      }),
    });
  }

  box.addToLayout(itemBox);

  return box;
};

export class BlockStatsView extends MainLayout {
  TEST_NAME = 'BlockStatsView';

  constructor({ block, ...props }: BlockStatProps) {
    super({
      ...props,
    });

    const stats = getFullBlockStats(block.id);

    if (!stats) {
      return;
    }

    const box = new HLayout({});

    STAT_FIELDS().forEach(({ id }) => {
      box.addToLayout(createStatItem(id, stats, block));
    });

    if (isNeedShowRevenue(block)) {
      box.addToLayout(
        new RevenueStatItem({
          blockId: block.id,
          blockSubtype: block.subtype,
        }),
      );
    }

    this.addToLayout(box, {
      margin: {
        left: 20,
        bottom: 16,
      },
    });
  }
}
