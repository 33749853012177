import React from 'react';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Type } from '@ui/Type';
import { goToGetMarketingPermissions } from '@utils/FacebookPages/goToGetPermissions';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { sendEvent } from '@utils/Analytics';
import * as css from './PermissionModal.css';

export interface PermissionModalProps {
  onDismiss: () => void;
  pluginId: PluginType;
}

export const PermissionModal: React.FC<PermissionModalProps> = ({
  onDismiss,
  pluginId,
}) => (
  <DefaultDialog
    header={window.i18next.t('PermissionModal-string--279-choose-ads')}
    onDismiss={onDismiss}
    dialogStyle={{ width: 600 }}
  >
    <div className={css.info}>
      <Type size="15px_DEPRECATED">
        {window.i18next.t(
          'PermissionModal-JSXText-1822-chatfuel-needs-permissions-to-your-ads-manager-to-connect-your-ads-to-this-bot',
        )}
      </Type>
    </div>
    <div className={css.submitButton}>
      <Button
        onClick={() => {
          goToGetMarketingPermissions();

          sendEvent({
            category: 'flows',
            action: 'go get marketing permission',
            label: pluginId,
          });
        }}
      >
        {window.i18next.t(
          'PermissionModal-JSXText-1399-give-permissions-to-ads-manager',
        )}
      </Button>
    </div>
  </DefaultDialog>
);
