import { VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { VideoPluginConfig } from '../../../../Plugins/VideoPlugin/VideoPluginConst';
import { Node } from '../../../Node';
import { pluginWidth } from '../../plugin_consts';
import {
  FileAttachmentView,
} from '../../components/FileAttachmentView';
import { HEXColors } from '@ui/_common/colors';
import { pollForCardUploadStatus } from '@common/plugins/cardMediaUpload';
import i18next from 'i18next';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import {
  SUPPORTED_VIDEO_MIME_TYPES,
  VIDEO_FILE_SIZE_LIMIT_MB,
} from '@utils/PlatformSupportedFiles/videoSupportedTypes';
import { FileAttachmentType } from '@utils/UploadService/types';

export class VideoPluginView
  extends VLayout
  implements StatefulPluginDelegate<VideoPluginConfig>
{
  _node: Node;
  public readonly state: StatefulPlugin<VideoPluginConfig>;
  public readonly TEST_NAME = 'VideoPluginView';
  private readonly fileAttachmentView: FileAttachmentView;
  private cancelCardPolling: Function | undefined;

  constructor(state: StatefulPlugin<VideoPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        opacity: 0,
        fill: HEXColors.white,
      },
    });

    this.state = state;
    this.state.setDelegate(this);

    this._node = node;

    this.fileAttachmentView = new FileAttachmentView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.components.VideoPlugin.addMedia',
        { size: VIDEO_FILE_SIZE_LIMIT_MB[getFlowPlatform()!] },
      ),
      type: FileAttachmentType.video,
      fileSizeLimitMb: VIDEO_FILE_SIZE_LIMIT_MB[getFlowPlatform()!],
      fileData: () => this.state.data.config,
      accept: SUPPORTED_VIDEO_MIME_TYPES[getFlowPlatform()!],
      onFileUploaded: ({ url, filename }) => {
        this.state
          .setAndSave(({ config }) => ({
            config: {
              ...config,
              url,
              filename,
            },
            is_valid: true,
          }))
          .then(() => {
            const { promise, cancel } = pollForCardUploadStatus(
              this.state.data.id,
            );
            this.cancelCardPolling = cancel;
            return promise;
          })
          .catch(() => this.configInvalidate())
          .finally(() => {
            this.fileAttachmentView.stopLoading();
            this.renderNode();
          });
      },
      isEditing: () => this.state.isEditing,
      isShowFileFormatError: () =>
        !!this.state.data.config.url && !this.state.data.is_valid,
    });
    this.addToLayout(this.fileAttachmentView);
  }

  pluginDidSet() {}

  private configInvalidate() {
    this.state.setAndSave(({ config }) => ({
      config,
      is_valid: false,
    }));
  }

  public hasSignificantChangesInConfig() {
    return !!this.state.data.config.url;
  }

  destroy() {
    this.cancelCardPolling?.();
    this.state.destroy();
    super.destroy();
  }
}
