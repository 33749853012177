import { LayoutProps, VLayout } from '../components/Elements/Layouts';
import { TextureShape } from '../components/Elements/Shapes';
import { loaderSvgTexture } from '../assets/textures';
import { pluginWidth } from './plugin_consts';
import { HEXColors } from '@ui/_common/colors';

export interface LoaderProps extends LayoutProps {
  loaderSize?: number;
  cornerRadius?: number;
}

export class Loader extends VLayout {
  TEST_NAME = 'Loader';
  private readonly loaderImg: TextureShape;
  private loading: boolean = false;

  constructor(
    { loaderSize = 22, cornerRadius = 10, background, ...props }: LoaderProps,
    texture = loaderSvgTexture,
  ) {
    super({
      width: pluginWidth - 2,
      height: 50,
      background: {
        fill: HEXColors.greyLight11,
        cornerRadius,
        ...background,
      },
      ...props,
    });
    this.loaderImg = new TextureShape({
      texture,
      width: loaderSize,
      height: loaderSize,
    });
    this.layout(this.loaderImg, {
      margin: () => ({
        left: this._layoutProps.width! / 2,
        top: this._layoutProps.height! / 2,
      }),
    });
    this.loaderImg._shape.pivot.x = 22;
    this.loaderImg._shape.pivot.y = 22;

    this.animationFunction = () => {
      this.loaderImg._shape.rotation += 0.1;
    };
  }

  public start() {
    this.startAnimation();
    this.loading = true;
  }

  public stop() {
    this.stopAnimation();
    this.loading = false;
  }

  public get isLoading() {
    return this.loading;
  }
}
