import i18n from 'i18next';
import { CardButtonType, ShopifyDiscountsCodeType } from '@globals';
import { defaultObj, MainLayout } from '../components/Elements/Layouts';
import { resByFunc } from './utils';
import debounce from 'lodash-es/debounce';
import { MAX_SCALE } from '../PixiField';
import {
  getFlowControllerStrict,
  getPixiField,
  getPixiFieldStrict,
} from '../PixiFieldRepository';
import {
  overlayEventEmitter,
  OverlayComponentEvent,
  FlowBuilderOverlayEvent,
  OverlayType,
  ControlVisibility,
} from '../FlowBuilderOverlay';
import {
  FONT_STYLE_TO_WEIGHT,
  HTMLText,
  TextureShape,
} from '../components/Elements/Shapes';
import { fontFamily } from '../consts';
import { hideControls } from './helpers/ControlsHelpers';
import {
  openUrlTexture,
  callPhoneTexture,
  openUrlRedTexture,
  errorInfoTriangleSvgTexture,
} from '../assets/textures';
import { prepareControlsVisibilityFlags } from './text_edit_view';
import { notEmptyString } from './validation';
import { isValidUrl } from '../../../utils/UrlUtils';
import { getFullAttributesBoundaries } from '../../../modern-ui/TextWithAttributesEditor/attributesBoundariesUtils';
import { ItemStatView } from './kit/ItemStatView/ItemStatView';
import {
  getFullCounterStats,
  isWebExtensionButton,
} from './components/BlockStatsView/utils';
import { isFlowHasCalendlyBlock } from './plugins/CalendlyIntegration/utils/isFlowHasCalendlyBlock';
import { FlowBuilderEvent, flowBuilderEventEmitter } from './events';
import {
  isCalendlyButtonType,
  isPaymentButtonType,
  isShopifyButtonType,
} from '../FlowBuilderOverlay/overlays/ButtonPopupOverlay/utils';
import { isFlowHasShopifyEventEntryPoint } from './entry-points/EntryPointShopifyEvent/utils';
import { getFlowPlatform } from '../utils/getFlowPlatform';
import { HEXColors } from '../../../modern-ui/_common/colors';
import { PANEL_WIDTH } from '../EditorPanel/components/editors/EditorPanelPaymentButton/consts';
import { getPaymentButtonTitle } from '../EditorPanel/components/editors/EditorPanelPaymentButton/helpers/getPaymentButtonTitle';
import { getButtonNamePostfix } from '../EditorPanel/components/editors/EditorPanelPaymentButton/helpers/getButtonNamePostfix';
import { MAX_BUTTON_LENGTH } from './constants';
import {
  hideEditorPanelPaymentButton,
  paymentButtonEditorEmitter,
  PaymentButtonEditorEvent,
  showEditorPanelPaymentButton,
} from '../EditorPanel/components/editors/EditorPanelPaymentButton/events';
import { equals } from 'ramda';
import {
  calcStripeMinimalAmount,
  getCurrenciesObservable,
} from '../../../utils/Data/Currency';
import { getCurrencyByCode } from '../../../utils/Data/Currency/getCurrencyByCode';
import { calcTotalAmount } from '../EditorPanel/components/editors/EditorPanelPaymentButton/helpers/calcTotalAmount';
import { getStripeAccountObservable } from '../../../utils/Stripe/getStripeAccountObservable';

const getActionText = (config) => {
  if (isCalendlyButtonType(config.type)) {
    const hasCalendlyPlugin = isFlowHasCalendlyBlock();

    if (!hasCalendlyPlugin) {
      return i18n.t(
        'modernComponents.FlowBuilder.views.components.Calendly.validation.noCalendlyPlugin',
      );
    }

    switch (config.type) {
      case CardButtonType.calendly_cancel:
        return i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.cancel',
        );
      case CardButtonType.calendly_join_meeting:
        return i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.join',
        );
      case CardButtonType.calendly_reschedule:
        return i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.reschedule',
        );
      case CardButtonType.calendly_schedule:
        return i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.schedule',
        );
      default:
        throw new Error(`Cannot find key for type=${config.type}`);
    }
  }

  if (isShopifyButtonType(config.type)) {
    switch (config.type) {
      case CardButtonType.shopify_order_list:
        if (!isFlowHasShopifyEventEntryPoint()) {
          return i18n.t('shopify.orderList.validation.noShopifyEntryPoint');
        }
        return i18n.t('shopify.orderList.buttonAction');
      case CardButtonType.shopify_page:
        const discount = config.shopify_page?.discount;
        const discountInfo = !discount?.code
          ? i18n.t('shopify.openShopifyStore.example')
          : `${
              discount?.discount_type === ShopifyDiscountsCodeType.unique
                ? i18n.t('shopify.openShopifyStore.unique')
                : i18n.t('shopify.openShopifyStore.common')
            }: ${discount?.code}`;
        return discountInfo.length > 30
          ? `${discountInfo.substr(0, 30)}...`
          : discountInfo;
      default:
        throw new Error(`Cannot find key for type=${config.type}`);
    }
  }

  return config.url || config.phone_number || config.description;
};

const clearButtonConfig = ({
  title,
  phone_number,
  url,
  webview_height_ratio,
  type,
  shopify_page,
  payment_blocks,
  variables,
  payment_summary,
  description,
}) => ({
  type: type || CardButtonType.regular,
  title: (title || '').trim(),
  ...((url || '').trim()
    ? {
        url,
        webview_height_ratio,
      }
    : {}),
  ...((phone_number || '').trim()
    ? {
        phone_number,
      }
    : {}),
  ...(isCalendlyButtonType(type) || isShopifyButtonType(type)
    ? {
        webview_height_ratio,
      }
    : {}),
  ...(isShopifyButtonType(type)
    ? {
        shopify_page: {
          ...shopify_page,
          path: (shopify_page?.path || '').trim(),
        },
      }
    : {}),
  ...(isPaymentButtonType(type)
    ? {
        payment_summary,
        payment_blocks,
        variables,
      }
    : {}),
  ...(type === CardButtonType.listButton ? { description } : {}),
});

const ADDITIONAL_TOP_MARGIN = 10;

export class ButtonEditView extends MainLayout {
  TEST_NAME = 'ButtonEditView';
  _props;
  _textNode;
  _onStartEditing;
  _onConfigChanged;
  _onDoneEditing;
  _overlayShown = false;
  _captionSet = false;
  _zoomed = false;
  _config;
  _actionTextNode;
  _openUrlIcon;
  _errorIcon;
  _callPhoneIcon;
  shouldShowAttributes;
  _predefinedAttributes;
  itemStatView;
  _flowEventsSubscriptions;
  _currenciesSubscription;
  _stripeAccountSubscription;
  _symbol = Symbol();
  _currencies;
  _stripeAccountStatus;

  constructor(
    props,
    onStartEditing,
    onConfigChanged,
    onDoneEditing,
    predefinedAttributes,
  ) {
    props = defaultObj(
      {
        editable: true,
        fill: () => (props.error?.() ? HEXColors.red : HEXColors.black),
        caption: '',
        captionFill: props.fill,
        cursor: (v) => {
          const isEditable = v._props && resByFunc(v._props.editable, v);
          return isEditable ? { in: 'text' } : undefined;
        },
        resolution: window.devicePixelRatio,
        hideCallPhoneOption: false,
        hidePaymentOption: false,
        disablePaymentOption: false,
        hideCalendlyOptions: false,
        hideContinueToFlowOption: false,
        hideButtonPopupIfTargetBlockSelected: false,
        hidePopup: false,
        hideIcon: false,
        hideStats: false,
        hideActionText: false,
        textOutsideControlsVisibility: {
          attributes: ControlVisibility.auto,
          emoji: ControlVisibility.auto,
          symbolsLimit: ControlVisibility.auto,
          discount: ControlVisibility.auto,
        },
      },
      props,
    );
    super(props);

    props.textOutsideControlsVisibility.attributes =
      !predefinedAttributes || predefinedAttributes.length > 0
        ? props.textOutsideControlsVisibility.attributes
        : ControlVisibility.hide;

    this._predefinedAttributes = predefinedAttributes;
    this.shouldShowAttributes = [
      ControlVisibility.show,
      ControlVisibility.auto,
    ].includes(props.textOutsideControlsVisibility.attributes);

    this._props = props;
    this._onStartEditing = onStartEditing;
    this._onConfigChanged = onConfigChanged;
    this._onDoneEditing = onDoneEditing;
    this._config = props.config;
    this._textNode = new HTMLText({
      fontFamily,
      fontSize: 15,
      fontStyle: 'semibold',
      height: 20,
      fill: 'black',
      align: 'center',
      verticalAlign: 'center',
      width: this.calcFieldWidth(),
      text: this.getTitle(),
      singleLine: true,
      attributeOnly: false,
      shouldShowAttributes: this.shouldShowAttributes,
      predefinedAttributes,
    });
    this._textNode.interactive = true;
    const actionText = getActionText(props.config);

    this._actionTextNode = new HTMLText({
      fontFamily,
      fontSize: 10,
      fontStyle: 'semibold',
      height: 16,
      fill: '#747474',
      align: 'center',
      verticalAlign: 'center',
      width: this.calcFieldWidth(),
      text: actionText,
      singleLine: true,
      attributeOnly: false,
    });
    this._openUrlIcon = new TextureShape({
      texture: openUrlTexture,
      width: 24,
      height: 24,
    });
    this._errorIcon = new TextureShape({
      texture: errorInfoTriangleSvgTexture,
      width: 24,
      height: 24,
    });
    this._callPhoneIcon = new TextureShape({
      texture: callPhoneTexture,
      width: 24,
      height: 24,
    });
    this.setCaption();
    this.layout(this._textNode, {
      margin: () => {
        const statWidth = this.itemStatView?.textWidth ?? 0;
        const currentMargin =
          30 + (!this._overlayShown && statWidth ? statWidth + 4 : 0);
        return {
          top: this.needAdditionalMargin() ? 5 : ADDITIONAL_TOP_MARGIN,
          left: currentMargin,
          right: currentMargin,
        };
      },
      width: 0,
    });
    this.layout(this._actionTextNode, {
      margin: () => ({
        top: this._config?.description ? 32 : 22,
        left: 30,
        right: 30,
      }),
      width: 0,
      visible: !!actionText,
      gone: () => resByFunc(props.hideActionText),
    });
    this.layout(this._openUrlIcon, {
      margin: {
        top: 8,
        left: 8,
      },
      gone: () =>
        resByFunc(props.hideIcon) ||
        !(
          this._config.url ||
          isCalendlyButtonType(this._config?.type) ||
          isShopifyButtonType(this._config?.type)
        ),
    });
    this.layout(this._errorIcon, {
      margin: {
        top: 8,
        left: 8,
      },
      gone: () => this.getButtonErrorGone(),
    });
    this.layout(this._callPhoneIcon, {
      margin: {
        top: 8,
        left: 8,
      },
      gone: () => resByFunc(props.hideIcon) || !this._config.phone_number,
    });

    const counterId = props.config.counter_id || props.config.counterId;

    const stats = getFullCounterStats(counterId, props.blockId);

    if (stats && !resByFunc(props.hideStats)) {
      this.itemStatView = new ItemStatView({
        stats,
        showPercentValue: true,
        hideUniqStat: props.hideUniqStat,
        unavailable: isWebExtensionButton(props.config),
      });
      this.layout(this.itemStatView, {
        gone: () =>
          this._overlayShown || this._config.hasOwnProperty('phone_number'),
        margin: () => {
          return {
            top:
              getActionText(this._config)?.length > 28
                ? 5
                : ADDITIONAL_TOP_MARGIN,
            left: 192,
          };
        },
      });
    }

    this.on('pointerdown', (e) => {
      if (resByFunc(this._props.editable)) {
        e.stopPropagation();
      }
    });
    this.on('click', (e) => {
      if (resByFunc(this._props.editable)) {
        e.stopPropagation();
        this.startEditing();
      }
    });

    const updateButtonErrorState = () => {
      if (
        isCalendlyButtonType(this._config?.type) ||
        isShopifyButtonType(this._config?.type)
      ) {
        this.setActionTextVisibility(true);
        this.setActionErrorState();
        this.renderElement();
      }
    };

    this._flowEventsSubscriptions = [
      flowBuilderEventEmitter.on(
        FlowBuilderEvent.pluginAdded,
        updateButtonErrorState,
      ),
      flowBuilderEventEmitter.on(
        FlowBuilderEvent.pluginRemoved,
        updateButtonErrorState,
      ),
      flowBuilderEventEmitter.on(
        FlowBuilderEvent.blockAdded,
        updateButtonErrorState,
      ),
      flowBuilderEventEmitter.on(
        FlowBuilderEvent.blockRemoved,
        updateButtonErrorState,
      ),
      paymentButtonEditorEmitter.on(
        PaymentButtonEditorEvent.updateConfig,
        ({ config, symbol }) => {
          if (
            isPaymentButtonType(config.type) &&
            this._symbol === symbol &&
            !equals(config, this._config)
          ) {
            this.updateConfig(config, true);
          }
        },
      ),
    ];

    if (!getPixiFieldStrict().isViewOnly()) {
      this._currenciesSubscription = getCurrenciesObservable().subscribe(
        (currencies) => {
          this._currencies = currencies;
          this.renderElement();
        },
      );
    }

    if (!getPixiFieldStrict().isViewOnly()) {
      this._stripeAccountSubscription = getStripeAccountObservable(
        getFlowControllerStrict().flow.botId,
      ).subscribe((status) => {
        this._stripeAccountStatus = !!status;
        this.renderElement();
      });
    }

    setTimeout(() => {
      this.setActionTextVisibility(true); // when all nodes ready
    });
  }

  onBeforeRender() {
    this.setActionErrorState();
    this.setActionTextVisibility(true);
    this.setCaption();
  }

  hasPaymentError() {
    return (
      isPaymentButtonType(this._config?.type) &&
      (this.getTitle().length > 20 ||
        this._config.payment_summary?.price_list?.some(
          ({ label }) => label.trim().length === 0,
        ) ||
        calcStripeMinimalAmount(
          getCurrencyByCode(
            this._config.payment_summary?.currency,
            this._currencies,
          ),
        ) > calcTotalAmount(this._config.payment_summary) ||
        this._stripeAccountStatus === false)
    );
  }

  hasError() {
    const hasCalendlyError =
      isCalendlyButtonType(this._config?.type) && !isFlowHasCalendlyBlock();

    const hasShopifyOrderListError =
      this._config?.type === CardButtonType.shopify_order_list &&
      !isFlowHasShopifyEventEntryPoint();

    return (
      this._props.error?.() ||
      this.hasPaymentError() ||
      hasCalendlyError ||
      hasShopifyOrderListError ||
      (notEmptyString(this._config?.url) &&
        !isValidUrl(this._config?.url || '') &&
        (!this.shouldShowAttributes ||
          !getFullAttributesBoundaries(this._config?.url || '').length))
    );
  }

  setActionErrorState() {
    const hasError = this.hasError();
    this._actionTextNode.fill(hasError ? '#de614d' : '#747474');
    this._openUrlIcon.texture(hasError ? openUrlRedTexture : openUrlTexture);
  }

  needAdditionalMargin = () => {
    if (resByFunc(this._props.hideActionText)) {
      return false;
    }

    const { url, phone_number: phoneNumber, type } = this._config;
    return (
      (url ||
        phoneNumber ||
        isCalendlyButtonType(type) ||
        isShopifyButtonType(type)) &&
      !this._overlayShown
    );
  };

  setCaption() {
    const title = this.getTitle();
    if (!this._overlayShown) {
      if (title.length > (this._props.maxLength || MAX_BUTTON_LENGTH)) {
        this._textNode.fill(this._props.captionFill);
      } else if (title === '') {
        this._textNode.text(this._props.caption);
        this._textNode.fill(this._props.captionFill);
        this._captionSet = true;
      } else {
        this._textNode.fill(resByFunc(this._props.fill));
        this._captionSet = false;
      }
    } else {
      this._textNode.fill(resByFunc(this._props.fill));
      if (this._captionSet) {
        this._textNode.text('');
      }
      this._captionSet = false;
    }
  }

  config(config) {
    if (config) {
      this._config = config;
      this._textNode.text(this.getTitle());
      this.setActionTextVisibility(true);
      this.setCaption();
    }
    return this._config;
  }

  setActionTextVisibility(show) {
    if (show && !this._overlayShown) {
      const actionText = this._props.error?.() || getActionText(this._config);
      if (this._actionTextNode.layoutProperties) {
        this._actionTextNode.layoutProperties.visible = !!actionText;
      }
      this._actionTextNode.text(actionText);
    } else if (this._actionTextNode.layoutProperties) {
      this._actionTextNode.layoutProperties.visible = false;
    }
  }

  setTextColor(color) {
    this._textNode.fill(color);
  }

  start() {
    this.on('pointerdown', (e) => {
      this.startEditing(e);
    });
    return this;
  }

  startEditing() {
    setTimeout(() => {
      this.showOverlay();
      this.showPanelIfNeed();
    });
  }

  async showPanelIfNeed() {
    if (isPaymentButtonType(this._config.type)) {
      showEditorPanelPaymentButton(this._config, this._symbol);
      await getPixiField()?.fixBlockPosition(this, PANEL_WIDTH);
      this.setPosition();
    } else {
      hideEditorPanelPaymentButton();
    }
  }

  showOverlay() {
    if (this._overlayShown) {
      return;
    }
    const group = this;
    const { _textNode, _actionTextNode } = this;
    this._overlayShown = true;
    const onStartEditing = this._onStartEditing;
    const viewport = getPixiFieldStrict().viewport;
    const { platform } = getFlowControllerStrict().flow;

    hideControls();
    if (typeof onStartEditing !== 'undefined') {
      onStartEditing();
    }
    this.setCaption(true);

    const shouldShowOutsideControls = prepareControlsVisibilityFlags(
      this._props.textOutsideControlsVisibility,
      this._props,
      OverlayType.buttonPopup,
    );

    overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
      overlayType: OverlayType.buttonPopup,
      getOverlayOptions: () => ({
        config: this._config,
        style: {
          fontFamily,
          textAlign: 'center',
          color: 'black',
          fontWeight: FONT_STYLE_TO_WEIGHT['semibold'],
          fontSize: 15,
          width: this.calcFieldWidth(),
        },
        hideCalendlyOptions: this._props.hideCalendlyOptions,
        hideCallPhoneOption: resByFunc(this._props.hideCallPhoneOption),
        hidePaymentOption: resByFunc(this._props.hidePaymentOption),
        disablePaymentOption: resByFunc(this._props.disablePaymentOption),
        hideContinueToFlowOption: resByFunc(
          this._props.hideContinueToFlowOption,
        ),
        hidePopup: resByFunc(this._props.hidePopup),
        shouldShowOutsideControls,
        maxLength: this._props.maxLength,
        predefinedAttributes: this._predefinedAttributes,
        platform: getFlowPlatform(),
      }),
    });

    this.overlayOnChangeUnsubscribe = overlayEventEmitter.on(
      OverlayComponentEvent.change,
      ({ value } = {}) => {
        this.updateConfig(value);
        this.showPanelIfNeed();
      },
    );

    this.overlayOnKeyDownUnsubscribe = overlayEventEmitter.on(
      OverlayComponentEvent.keydown,
      (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          _textNode.text(this.getTitle());
          this.handleConfigChange(this._config);
          this.removeOverlay();
          return;
        }
        if (event.key === 'Escape') {
          this._config = this._props.config;
          this.removeOverlay();
        }
      },
    );

    viewport.on('zoomed', this.zoomedHandler);
    viewport.on('zoomed-end', this.zoomEndHandler);

    setTimeout(() => {
      this.setPosition();
      _textNode.layoutProperties.visible = false;
      this.setActionTextVisibility(false);
      group.renderNode({ shouldRunOnBeforeRender: false });
      this.addClickHandler();
    });
  }

  updateConfig(updatedConfig, force) {
    const isNeedUpdate =
      updatedConfig.hasOwnProperty('url') !==
        this._config.hasOwnProperty('url') ||
      updatedConfig.hasOwnProperty('phone_number') !==
        this._config.hasOwnProperty('phone_number') ||
      updatedConfig.hasOwnProperty('type') !==
        this._config.hasOwnProperty('type');
    this.config(updatedConfig);
    this._textNode.text(this.getTitle());
    this._onConfigChanged?.(this._config);
    if (isNeedUpdate || force) {
      this._onDoneEditing?.(this._config);
    }
    this.renderElement();
  }

  addClickHandler() {
    window.addEventListener('mousedown', this.handleOutsideClick);
  }

  handleConfigChange(newConfig) {
    const { _onConfigChanged } = this;
    if (_onConfigChanged) {
      _onConfigChanged(newConfig);
    }
  }

  handleOutsideClick = (e) => {
    this._textNode.text(this.getTitle());
    this.handleConfigChange(this._config);
    this.removeOverlay();
  };

  setPosition = () => {
    const { scale } = getPixiFieldStrict().viewport;
    const { x, y } = this.globalPosition();
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
      position: {
        x,
        y:
          y +
          (this._props.type === CardButtonType.listButton
            ? 0
            : ADDITIONAL_TOP_MARGIN * scale.x),
        width: this.width(),
        height: this.height(),
        scale: scale.x,
      },
    });
  };

  zoomEndHandler = debounce(() => {
    if (this._zoomed && this._overlayShown) {
      this.setPosition();
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.show);
      this._textNode.layoutProperties.visible = false;
      this.setActionTextVisibility(false);
      this.renderNode({ shouldRunOnBeforeRender: false });
      this._zoomed = false;
    }
  }, 350);

  zoomedHandler = () => {
    const { scale } = getPixiFieldStrict().viewport;
    if (!this._zoomed && this._overlayShown && scale.x < MAX_SCALE) {
      const { _textNode, _actionTextNode } = this;
      const title = this.getTitle();
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.hide);
      if (_textNode.text() !== title) {
        _textNode.text(title);
        this.setCaption(true);
        _textNode.renderElement();
      }
      _textNode.layoutProperties.visible = true;
      this.setActionTextVisibility(true);
      this.renderNode({ shouldRunOnBeforeRender: false });
      this._zoomed = true;
      this.zoomEndHandler();
    }
  };

  removeOverlay() {
    const { _textNode, _actionTextNode, _onDoneEditing, _config } = this;
    this.config(clearButtonConfig(_config));
    const viewport = getPixiFieldStrict().viewport;
    this.overlayOnChangeUnsubscribe();
    this.overlayOnKeyDownUnsubscribe();
    this.zoomEndHandler.cancel();
    this._zoomed = false;
    window.removeEventListener('mousedown', this.handleOutsideClick);
    viewport.off('zoomed', this.zoomedHandler);
    viewport.off('zoomed-end', this.zoomEndHandler);
    this._overlayShown = false;
    this.setCaption(true);
    _textNode.layoutProperties.visible = true;
    this.setActionTextVisibility(true);
    this.renderNode({ shouldRunOnBeforeRender: false });
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
    if (_onDoneEditing) {
      _onDoneEditing(this._config);
    }
  }

  calcFieldWidth = () => this._props.width - 60;

  isEditing() {
    return this._overlayShown;
  }

  getTitle() {
    const { title, type } = this._config;
    return isPaymentButtonType(type)
      ? getPaymentButtonTitle(this._config, getButtonNamePostfix(this._config))
      : title;
  }

  getButtonErrorGone() {
    return (
      resByFunc(this._props.hideIcon) ||
      !(isPaymentButtonType(this._config?.type) && this.hasError())
    );
  }

  destroy() {
    this._currenciesSubscription?.unsubscribe();
    this._flowEventsSubscriptions.forEach((fn) => {
      fn();
    });
    return super.destroy();
  }
}
