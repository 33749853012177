import { LSKey, useLocalStorageForId } from '@utils/LocalStorage';
import Maybe from 'graphql/tsutils/Maybe';
import { useEffect } from 'react';

export interface UseSaveAccountIdToServerStorageProps {
  pageId: Maybe<string>;
  selectedAdAccountId: string;
  setSelectedAdAccountId: (value: string) => void;
  adsItems: Array<{ id: string }>;
  accountId: Maybe<string>;
}

export const useSaveAccountIdToServerStorage = ({
  pageId,
  selectedAdAccountId,
  setSelectedAdAccountId,
  adsItems,
  accountId,
}: UseSaveAccountIdToServerStorageProps) => {
  const [savedAdAccountId, setSavedAdAccountId] = useLocalStorageForId<string>(
    LSKey.fbAdAccountId,
    pageId || undefined,
  );

  useEffect(() => {
    /**
     * Case when user opens modal for the first time. If he doesn't select any ad account
     * and he doesn't have saved ad account in local storage then we are trying to set
     * checkedAdsIds ad account and if it is also empty we just taking first id from
     * ads account array.
     */
    if (!selectedAdAccountId && !savedAdAccountId && adsItems.length) {
      setSelectedAdAccountId(accountId ? `act_${accountId}` : adsItems[0].id);
    }

    // Case when user opens modal and he has saved ad account in local storage
    if (!selectedAdAccountId && savedAdAccountId) {
      setSelectedAdAccountId(savedAdAccountId);
    }
  }, [
    savedAdAccountId,
    adsItems,
    selectedAdAccountId,
    setSelectedAdAccountId,
    accountId,
  ]);

  return [savedAdAccountId, setSavedAdAccountId] as const;
};
