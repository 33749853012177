import i18next from 'i18next';
import { propEq, difference } from 'ramda';
import { FlowTypes } from '@utils/Data/Flow/Aggregated';
import { prepareFlowBlocks } from '@utils/Data/Flow/Aggregated/helpers';
import { HEXColors } from '@ui/_common/colors';
import { log } from 'cf-common/src/logger';
import { organizeBlocks } from '../../../utils/organizeBlocks/organizeBlocks';
import {
  logFlowPluginEvent,
  sendFlowBuilderEvent,
} from '../../../utils/Analytics';
import { FAQView } from '../common/components/FAQ';
import { getBlocks } from '../../../api/network';
import { GetStartedButtonView } from './GetStartedButton';
import {
  EntryPointFacebookPageCard,
  TypeIds,
  FacebookPageConfig,
} from './types';
import { ButtonView } from '../../button_view';
import {
  infoSvgTexture,
  loaderWhiteSvgTexture,
} from '../../../assets/textures';
import entryPointFacebookPageFaqSvg from '../../../assets/entry_point/faq.svg';
import entryPointFacebookPageGetStarted from '../../../assets/entry_point/facebook_page_get_started.png';
import {
  VLayout,
  HLayout,
  MainLayout,
} from '../../../components/Elements/Layouts';
import { TextureShape } from '../../../components/Elements/Shapes';
import { Node } from '../../../Node';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { validateEntryPointLeadToBlockOrUrl } from '../common/utils/validateEntryPointLeadToBlockOrUrl';
import { addValidationProps } from '../common/utils/validationProps';
import { Dropdown, Item } from '../../kit/Dropdown';
import {
  ServiceMessageType,
  toaster,
} from '../../../../../services/MessageService';
import { buttonHeight, pluginWidth } from '../../plugin_consts';
import { tooltipScaled, removeTooltip } from '../../helpers/TooltipHelpers';
import { Loader } from '../../loader';
import fillWithFacebookPageDataSvg from '../../../assets/entry_point/fill_with_facebook_page_data.svg';
import { moveArrayElement, removeArrayElement } from '../../utils';
import { Platform } from '@globals';

const APPLY_TYPES: () => Item[] = () => [
  {
    id: TypeIds.ice_breakers,
    title: i18next.t('FacebookPage-string--119-frequently-asked-questions'),
  },
  {
    id: TypeIds.get_started,
    title: i18next.t('FacebookPage-string-6664-get-started-button'),
  },
];

const FACEBOOK_MAX_ICE_BREAKERS = 6;

const TOOLTIP_DATA = () => ({
  [TypeIds.ice_breakers]: `<img src="${entryPointFacebookPageFaqSvg}" width="216" /><br/><br/>${i18next.t(
    'modernComponents.FlowBuilder.views.entryPoints.FacebookPage.iceBreakersInfo',
    { maxIceBreakers: FACEBOOK_MAX_ICE_BREAKERS },
  )}`,
  [TypeIds.get_started]: `<img src="${entryPointFacebookPageGetStarted}" width="214" height="101"/><br/><br/>${i18next.t(
    'modernComponents.FlowBuilder.views.entryPoints.FacebookPage.welcomeMessageInfo',
    { helpDocUrl: i18next.t('common.helpDocLinks.entry_points') },
  )}`,
});
const getTypeByConfig = (config: FacebookPageConfig) => {
  if (config.type) {
    return config.type;
  }

  if (config.ice_breakers?.length) {
    return TypeIds.ice_breakers;
  }

  if (config.block_id) {
    return TypeIds.get_started;
  }

  return TypeIds.ice_breakers;
};
export class EntryPointFacebookPage extends VLayout {
  TEST_NAME = 'EntryPointFacebookPage';
  private _card: EntryPointFacebookPageCard;

  private _node: Node;

  private infoIconContainer: HLayout;

  private dropdownTitleView: HLayout;

  private faqView: FAQView;

  private getStartedButtonView: GetStartedButtonView;
  textureShape: HLayout;

  private syncWithMessengerLoading = false;

  private syncWithMessengerLoader: Loader;

  private syncWithMessengerButton: ButtonView;

  private changesSynced = true;

  constructor(card: EntryPointFacebookPageCard, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });
    this._card = card;
    this._node = node;

    this.dropdownTitleView = new HLayout({});
    this.infoIconContainer = new HLayout({});

    if (!card.config.type) {
      // eslint-disable-next-line no-param-reassign
      card.config.type = getTypeByConfig(card.config);
      node.updateCard(card);
    }

    this.dropdownTitleView.addToLayout(
      new Dropdown({
        defaultSelectedItem:
          APPLY_TYPES().find(propEq('id', getTypeByConfig(card.config))) ||
          APPLY_TYPES()[0],
        items: APPLY_TYPES(),
        onChange: (selectedItem) => {
          // eslint-disable-next-line no-param-reassign
          card.config.type = selectedItem.id as TypeIds;
          this.changesSynced = false;
          node.updateCard(card);
          this.updateLines();
          this.updateView();

          logFlowPluginEvent(card.plugin_id, 'Facebook Page type changed', {
            type: selectedItem.id,
          });
        },
        isEditing: () => !!card.isEditing,
        fontStyle: 'semibold',
        background: {
          fill: HEXColors.white,
        },
      }),
    );
    this.textureShape = new MainLayout({}).addToLayout(
      new TextureShape({
        texture: infoSvgTexture,
        width: 16,
        height: 16,
      }),
    );

    this.infoIconContainer.addToLayout(this.textureShape, {
      margin: { top: -28, left: 285 },
    });

    this.addToLayout(this.dropdownTitleView, { margin: { bottom: 10 } });
    this.addToLayout(this.infoIconContainer);
    this.faqView = new FAQView(
      card,
      () => ({
        ...card,
        config: {
          ice_breakers: card.config.ice_breakers,
          fill_ib_with_fb_data: card.config.fill_ib_with_fb_data,
        },
      }),
      node,
      {
        platform: Platform.facebook,
        maxIceBreakers: FACEBOOK_MAX_ICE_BREAKERS,
        updateCardViaDeprecatedMethod: true,
        externalSourceButtonTitle: i18next.t(
          'FacebookPage-string-1097-fill-with-data-from-facebook-page',
        ),
        externalSourceButtonTooltipText: `<img src="${fillWithFacebookPageDataSvg}" /><br/><br/>${i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.FacebookPage.fillWithData',
        )}`,
        fillWithExternalSource: () => !!this._card.config.fill_ib_with_fb_data,
        onFillWithExternalSource: (updateIceBreakers) => {
          sendFlowBuilderEvent({
            category: 'entry point',
            action: 'fill data from fb',
            label: 'facebook page',
          });
          return this.fillFaqWithFacebookData(updateIceBreakers, {
            card,
            node,
          });
        },
        onIceBreakerAdd: (iceBreaker) => {
          sendFlowBuilderEvent({
            category: 'entry point',
            action: 'add faq option',
            label: 'facebook page',
          });
          if (this._card.config.ice_breakers) {
            this._card.config.ice_breakers.push(iceBreaker);
          } else {
            this._card.config.ice_breakers = [iceBreaker];
          }
          node.updateCard(card);
        },
        onIceBreakerRemove: (iceBreaker) => {
          removeArrayElement(this._card.config.ice_breakers, iceBreaker);
          this._node.updateCard(this._card);
        },
        onIceBreakerMove: (_, fromIndex, toIndex) => {
          moveArrayElement(this._card.config.ice_breakers, fromIndex, toIndex);
          this._node.updateCard(this._card);
        },
        onIceBreakerConnect: (iceBreaker, _, blockView) => {
          // eslint-disable-next-line no-param-reassign
          iceBreaker.block_id = blockView?._node.id ?? null;
        },
        onIceBreakerDisconnect: (iceBreaker) => {
          // eslint-disable-next-line no-param-reassign
          iceBreaker.block_id = null;
        },
        onChangesSynced: (newState) => {
          this.changesSynced = newState;
        },
      },
    );
    addValidationProps(this.faqView);
    this.addToLayout(this.faqView, {
      margin: { bottom: 15 },
      gone: () => {
        this.updateLines();

        return getTypeByConfig(card.config) !== TypeIds.ice_breakers;
      },
    });

    this.getStartedButtonView = new GetStartedButtonView(card, node);
    addValidationProps(this.getStartedButtonView);
    this.addToLayout(this.getStartedButtonView, {
      gone: () => {
        this.updateLines();

        return getTypeByConfig(card.config) !== TypeIds.get_started;
      },
    });

    this.syncWithMessengerButton = new ButtonView({
      title: i18next.t('FacebookPage-string--359-sync-changes-to-messenger'),
      width: pluginWidth,
      bgColor: HEXColors.blue,
      textColor: HEXColors.white,
      onClick: (event) => {
        sendFlowBuilderEvent({
          category: 'entry point',
          action: 'sync changes',
          label: 'facebook page',
        });
        event.stopPropagation();
        // eslint-disable-next-line no-param-reassign
        card.refresh = true;
        this.syncWithMessengerLoading = true;
        this.syncWithMessengerLoader.start();
        this.renderNode();
        node.updateCard(
          card,
          () => {
            // eslint-disable-next-line no-param-reassign
            card.refresh = false;
            this.changesSynced = true;
            this.syncWithMessengerLoading = false;
            this.syncWithMessengerLoader.stop();
            this.renderNode();
          },
          (error) => {
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: i18next.t(
                  'FacebookPage-string-3438-couldnt-sync-changes-to-messenger-try-again-in-a-minute',
                ),
              },
            });

            log.error({
              error,
              msg: 'Error while sync icebreakers data with facebook',
              data: { label: 'flow_fb_page_entry_point' },
            });

            // eslint-disable-next-line no-param-reassign
            card.refresh = false;
            this.syncWithMessengerLoading = false;
            this.syncWithMessengerLoader.stop();
            this.renderNode();
          },
        );
      },
    });

    this.addToLayout(this.syncWithMessengerButton, {
      margin: () => {
        return {
          top:
            getTypeByConfig(this._card.config) === TypeIds.get_started ? 15 : 0,
        };
      },
      gone: () => {
        const { enabled, isEditing } = this._card;
        return (
          !enabled ||
          !isEditing ||
          this.changesSynced ||
          this.syncWithMessengerLoading
        );
      },
    });

    tooltipScaled({
      view: this.syncWithMessengerButton,
      text: 'Changes not yet live in Messenger;<br/>click to publish them.<br/>Note that you can’t sync changes<br/>more than ten times in ten minutes,<br/>per Facebook’s restrictions.',
    });

    this.syncWithMessengerLoader = this.createLoader(
      HEXColors.blue,
      loaderWhiteSvgTexture,
    );

    this.addToLayout(this.syncWithMessengerLoader, {
      margin: () => {
        return {
          top:
            getTypeByConfig(this._card.config) === TypeIds.get_started ? 15 : 0,
        };
      },
      gone: () => !this.syncWithMessengerLoading,
    });

    this.updateLines();
    this.updateView();
  }

  // eslint-disable-next-line class-methods-use-this
  createLoader(fill: HEXColors, texture?: any) {
    return new Loader(
      {
        width: pluginWidth,
        height: buttonHeight,
        background: {
          fill,
          cornerRadius: 4,
        },
      },
      texture,
    );
  }

  onBeforeRender() {
    this.syncWithMessengerButton.setDisabled(
      this.changesSynced || !!this.validationError(),
    );
  }

  updateLines() {
    const lines = Object.values(this._node.outLinks).flat(1);

    const iceBreakersLines = lines.filter(
      ({ fromView: lineFromView }) =>
        lineFromView.fromView !== this.getStartedButtonView,
    );
    const getStartedLine = lines.filter(
      ({ fromView: lineFromView }) =>
        lineFromView.fromView === this.getStartedButtonView,
    )[0];
    iceBreakersLines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      line.gone = getTypeByConfig(this._card.config) !== TypeIds.ice_breakers;
    });

    if (getStartedLine) {
      // eslint-disable-next-line no-param-reassign
      getStartedLine.gone =
        getTypeByConfig(this._card.config) !== TypeIds.get_started;
    }
  }

  updateView() {
    removeTooltip(this.textureShape);
    tooltipScaled({
      view: this.textureShape,
      overTimeout: 200,
      text: TOOLTIP_DATA()[this._card.config.type],
    });

    this.renderNode();
  }

  hasSignificantChangesInConfig() {
    const { block_id, ice_breakers = [] } = this._card.config;
    return Boolean(block_id || ice_breakers.length);
  }

  validationError() {
    const configType = getTypeByConfig(this._card.config);
    const isButtonConnected = Boolean(this._card.config?.block_id);
    const isIceBreakersValid =
      this._card.config.ice_breakers?.length &&
      this._card.config.ice_breakers?.every(
        (iceBreaker) => iceBreaker.block_id,
      );

    return (
      validateIsPageConnecting(this._node) ||
      validateEntryPointLeadToBlockOrUrl(() =>
        configType === TypeIds.get_started
          ? isButtonConnected
          : Boolean(isIceBreakersValid),
      ) ||
      (configType === TypeIds.ice_breakers
        ? this.faqView.validateIceBreakersLimit()
        : undefined)
    );
  }

  private fillFaqWithFacebookData(
    updateIceBreakers: () => void,
    { card, node }: { card: EntryPointFacebookPageCard; node: Node },
  ) {
    if (card.enabled) {
      // eslint-disable-next-line no-param-reassign
      card.refresh = true;
      this.changesSynced = true;
    } else {
      this.changesSynced = false;
    }
    const prevIceBreakers = (this._card.config.ice_breakers ?? []).slice();

    return new Promise<void>((resolve) => {
      node.updateCard(
        { ...card, config: { ...card.config, fill_ib_with_fb_data: true } },
        async (data: any) => {
          if (card.enabled) {
            // eslint-disable-next-line no-param-reassign
            card.refresh = false;
          } else {
            this.changesSynced = true;
          }
          this._card.config = data.config;
          const newIceBreakers = this._card.config.ice_breakers ?? [];
          try {
            const blocks = await getBlocks(this._node.controller.flow.id);
            await prepareFlowBlocks(
              this._node.controller.flow,
              blocks as unknown as FlowTypes.AggregatedFlowQuery_bot_flowBlocks[],
            );
          } catch (error) {
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: i18next.t(
                  'FacebookPage-string--232-something-went-wrong-please-try-to-reload-the-page',
                ),
              },
            });

            log.error({
              error,
              msg: 'Error while refetching blocks in fb entry point',
              data: { label: 'flow_fb_page_entry_point' },
            });
          }
          updateIceBreakers();

          const iceBreakersToHide = difference(prevIceBreakers, newIceBreakers);
          const iceBreakersToRender = difference(
            newIceBreakers,
            prevIceBreakers,
          );
          const allNodes = this._node.controller.allNodes();
          iceBreakersToHide.forEach(({ block_id }) => {
            const node = allNodes.find((node) => node.block.id === block_id);
            if (node) {
              this._node.controller.removeNode(node);
            }
          });
          const allBlocks = this._node.controller.flow.blocks;
          const organizableNodes: Record<string, Node> = {
            [this._node.id]: this._node,
          };
          iceBreakersToRender.forEach(({ block_id }) => {
            const block = allBlocks.find((b) => b.id === block_id);
            if (block) {
              const node = new Node(block, this._node.controller);
              this._node.controller.addNode(node);
              organizableNodes[node.id] = node;
            }
          });
          this.renderNode();
          await organizeBlocks(
            this._node.controller.flow.id,
            [this._node],
            organizableNodes,
          );
          resolve();
        },
        (error: any) => {
          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: i18next.t(
                'FacebookPage-string--232-something-went-wrong-please-try-to-reload-the-page',
              ),
            },
          });

          log.error({
            error,
            msg: 'Error while filling icebreakers with facebook page data',
            data: { label: 'flow_fb_page_entry_point' },
          });

          if (card.enabled) {
            // eslint-disable-next-line no-param-reassign
            card.refresh = false;
          }
          resolve();
        },
      );
    });
  }
}
