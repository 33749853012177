import i18next from 'i18next';
import Maybe from 'graphql/tsutils/Maybe';
import { Node } from '@components/FlowBuilder/Node';
import { goToHomeTabAndConnectWhatsapp } from '../../utils/goToHomeTabAndConnectWhatsapp';
import { ConnectWhatsappPageError, IFlowBuilderError } from './errors';
import { Validator } from './utils/types';

export interface WhatsappPlugin {
  _node: Node;
  isWhatsappConnected: Maybe<boolean>;
}

export class WhatsappValidator
  implements Validator<IFlowBuilderError | undefined>
{
  constructor(private readonly plugin: WhatsappPlugin) {}

  private validateIsMustLeadToBlocks() {
    const { next_block_ids } = this.plugin._node.block;
    return next_block_ids?.length
      ? undefined
      : {
          message: i18next.t('Whatsapp.entryPointMustLeadToBlock'),
          isBlockError: true,
        };
  }

  public validate() {
    return (
      this.validateIsMustLeadToBlocks() ||
      (!this.plugin.isWhatsappConnected
        ? new ConnectWhatsappPageError(goToHomeTabAndConnectWhatsapp, {
            type: i18next.t(
              'modernComponents.FlowBuilder.views.entryPoints.entryPoint',
            ),
          })
        : undefined)
    );
  }
}
