import i18next from 'i18next';
import { clone } from 'ramda';
import { abandonedCartPluginFragment_config_gallery_plugin as GalleryReminderConfig } from '../../../../../Plugins/AbandonedCartPlugin/@types/abandonedCartPluginFragment';
import { MainLayout } from '../../../../components/Elements/Layouts';
import { PluginType, PropFunc } from '../../../../types';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import { FullCounterStats } from '../../../../utils/Statistics/types';
import {
  EMPTY_PRODUCT_CARD_VIEW_CONFIG,
  ProductCardView,
  ProductCardViewProps,
} from '../../../components/ProductCardView/ProductCardView';
import { TextCardSimpleButtonWithStat } from '../../../kit/TextCard/TextCardSimpleButtonWithStat';
import productImg from './images/product.svg';

interface GalleryReminderViewProps {
  config: GalleryReminderConfig;
  onChange: (config: GalleryReminderConfig) => void;
  isEditing: PropFunc<boolean>;
  stats: FullCounterStats | undefined;
}

export class GalleryReminderView extends MainLayout {
  config: GalleryReminderConfig;

  constructor({
    config,
    onChange,
    isEditing,
    stats,
  }: GalleryReminderViewProps) {
    super();
    this.config = clone(config);

    const cardProps: ProductCardViewProps = {
      config: {
        ...EMPTY_PRODUCT_CARD_VIEW_CONFIG,
        title: i18next.t('shopify.productTitle'),
        image_url: productImg,
        description: i18next.t('shopify.productPrice'),
      },
      height: 340,
      interactive: false,
    };

    this.addToLayout(new ProductCardView(cardProps), {
      margin: {
        left: 10,
      },
    });

    this.addToLayout(new ProductCardView(cardProps), {
      margin: {
        top: 10,
      },
    });

    const button = new TextCardSimpleButtonWithStat({
      isEditing,
      initConfig: { title: config.button_title || '' },
      onChange: (config) => {
        this.config.button_title = config.title;
        logFlowPluginEvent(PluginType.abandoned_cart, 'update button text');
        onChange(this.config);
      },
      stats,
    });

    this.addToLayout(button, {
      margin: {
        top: 288,
        left: 15,
      },
    });

    this.on('click', () => {
      button.startEditing();
    });
  }

  validationError() {
    return !this.config.button_title?.trim();
  }
}
