import i18next from 'i18next';
import isEqual from 'lodash-es/isEqual';
import { HEXColors } from '@ui/_common/colors';
import { commentsLikePluginConfigFragment_config as CommentsLikePlugin } from '../../../../Plugins/CommentsLikePlugin/@types/commentsLikePluginConfigFragment';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../Plugins/CalendlyIntegration/CalendlyIntegrationConst';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { IPluginView } from '../../../types';
import { pluginWidth } from '../../plugin_consts';
import { Toggle } from '../../kit/toggle';
import { HTMLText } from '@components/FlowBuilder/components/Elements/Shapes';
import { Margin } from '../../utility_classes';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType, OnboardingTourShape } from '@globals';

export class CommentsLikePluginView
  extends VLayout
  implements StatefulPluginDelegate<CommentsLikePlugin>, IPluginView
{
  public readonly TEST_NAME = 'CommentsLikePluginView';

  constructor(private readonly state: StatefulPlugin<CommentsLikePlugin>) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
    });

    this.state = state;
    this.state.setDelegate(this);

    const wrapper = new HLayout({
      name: OnboardingTourShape.LikeCommentsToggleContainer,
    });

    const label = new HTMLText({
      text: `${i18next.t(
        'modernComponents.FlowBuilder.views.plugins.PublicReplyPlugin.like',
      )}&nbsp;`,
      fontSize: 15,
      trustedHtml: true,
    });

    wrapper.addToLayout(label, { margin: { right: 8 } });

    const toggle = new Toggle(
      this.state.data.config.likeComment ?? false,
      (_, likeComment) => {
        this.state.set(({ config }) => ({
          config: { ...config, likeComment },
        }));
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          element: OnboardingTourShape.LikeCommentsToggle,
          value: likeComment,
        });
      },
    );
    toggle.shape().name = OnboardingTourShape.LikeCommentsToggle;

    wrapper.addToLayout(toggle, { margin: { top: 3 } });

    this.addToLayout(wrapper, {
      margin: new Margin({ x: 16, y: 16 }),
    });
  }

  hasSignificantChangesInConfig() {
    return !isEqual(PLUGIN_DEFAULT_CONFIG, this.state.data.config);
  }

  validationError() {
    return undefined;
  }

  pluginDidSet() {
    this.state.save();
    this.renderNode();
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
