import i18next from 'i18next';
import { Frequency } from '@globals';

export function getNTimeNotificationPluginTexts(frequency?: Frequency | null) {
  switch (frequency) {
    case Frequency.DAILY:
      return {
        description: i18next.t('NTimeNotificationPlugin.Card.dailyDescription'),
        frequency: i18next.t('NTimeNotificationPlugin.Daily'),
        pluginTitle: i18next.t('NTimeNotificationPlugin.Card.dailyTitle'),
      };
    case Frequency.WEEKLY:
      return {
        description: i18next.t(
          'NTimeNotificationPlugin.Card.weeklyDescription',
        ),
        frequency: i18next.t('NTimeNotificationPlugin.Weekly'),
        pluginTitle: i18next.t('NTimeNotificationPlugin.Card.weeklyTitle'),
      };
    case Frequency.MONTHLY:
    default:
      return {
        description: i18next.t(
          'NTimeNotificationPlugin.Card.monthlyDescription',
        ),
        frequency: i18next.t('NTimeNotificationPlugin.Monthly'),
        pluginTitle: i18next.t('NTimeNotificationPlugin.Card.monthlyTitle'),
      };
  }
}
