import i18next from 'i18next';
import { log } from 'cf-common/src/logger';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { PermissionGroup } from '@utils/Permissions';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { Node } from '../../../../Node';
import { PostsSelector } from '../components/PostsSelector';
import {
  Plugin,
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../../StatefulPlugin';
import { instagramCommentsAutoreplyEntryPointFragment_config as InstagramCommentsAutoreplyEntryPointConfig } from '../../../../../Plugins/InstagramCommentsAutoreplyEntryPoint/@types/instagramCommentsAutoreplyEntryPointFragment';
import { getI18nCommentsAutoreplyKey } from '../utils/getI18nCommentsAutoreplyKey';
import {
  commentsAutoReplyAllUsersLineMenu,
  commentsAutoReplyLineMenu,
} from '../../../Menu/createLineMenu';
import { LineStartSubtype } from '../../../components/Line/types';
import { validateIsInstagramPageConnected } from '../../common/utils/validateIsInstagramPageConnected';
import { CommentsAutoreplyCommon } from '../components/CommentsAutoreplyCommon';
import { InstagramCommentsAutoreplyPostsSelector } from './InstagramCommentsAutoreplyPostsSelector';
import { SetPluginResult } from '@components/FlowBuilder/StatefulPlugin/types';

export class EntryPointInstagramCommentsAutoreply
  extends CommentsAutoreplyCommon<InstagramCommentsAutoreplyEntryPointConfig>
  implements StatefulPluginDelegate<InstagramCommentsAutoreplyEntryPointConfig>
{
  getPostSelectorView(): PostsSelector<{ id: string }> {
    return new InstagramCommentsAutoreplyPostsSelector({
      plugin: this,
      logger: this.logger,
    });
  }

  TEST_NAME = 'EntryPointInstagramCommentsAutoreply';

  constructor(
    state: StatefulPlugin<InstagramCommentsAutoreplyEntryPointConfig>,
    node: Node,
  ) {
    super(state, node, {
      validatorOptions: {
        postSelectorErrorMessage: i18next.t(
          getI18nCommentsAutoreplyKey('Instagram.postSelectorValidationText'),
        ),
        commentsPermissionsErrorMessage: i18next.t(
          getI18nCommentsAutoreplyKey('Instagram.permissionsValidationText'),
        ),
        permissionGroup: PermissionGroup.instagram_comments,
      },
      replyToOptions: {
        targetPlugin: PluginType.instagram_comments_autoreply_entry_point,
        lineParams: {
          items: commentsAutoReplyAllUsersLineMenu,
          searchable: false,
          menuStyle: { minWidth: 0 },
          subtype: LineStartSubtype.commentsAutoreplyAllUsers,
        },
      },
      replyToForCurrentSubscribersOptions: {
        targetPlugin: PluginType.instagram_comments_autoreply_entry_point,
        lineParams: {
          items: commentsAutoReplyLineMenu,
          searchable: false,
          menuStyle: { minWidth: 0 },
          subtype: LineStartSubtype.commentsAutoreplyOldUsers,
        },
      },
    });
  }

  onBeforeRender() {
    const { config } = this.state.data;
    this.replyToForCurrentSubscribers.keywordGroupsBox
      .views()
      // @ts-ignore // TODO check View props
      .forEach(({ lineStartView }) => {
        // eslint-disable-next-line no-param-reassign
        lineStartView._lineView.gone = !config.already_subscribed_setup_enabled;
      });

    this.postSelector.createPostsList();

    this.replyToForCurrentSubscribers.lineStartView._lineView.gone =
      !config.already_subscribed_setup_enabled;
  }

  validationError() {
    return (
      validateIsInstagramPageConnected(this._node) || super.validationError()
    );
  }

  pluginDidSet(
    prevState: Plugin<InstagramCommentsAutoreplyEntryPointConfig>,
    result: SetPluginResult<InstagramCommentsAutoreplyEntryPointConfig>,
  ) {
    if (
      this.state.data.config.apply_for_all_posts !==
      prevState.config.apply_for_all_posts
    ) {
      // handling this directly in onApplyRuleTypeChange
      return;
    }

    super.pluginDidSet(prevState, result);
  }

  pluginDidSaveError(error: any) {
    const isConflictError = extractGQLErrorData(error)?.status === 409;
    if (isConflictError) return;

    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          getI18nCommentsAutoreplyKey(
            'Instagram.failedToSaveEntryPointMessageText',
          ),
        ),
      },
    });
    log.error({
      error,
      msg: 'Error while saving Instagram Comments Autoreply Entry Point',
      data: { label: 'instagram_comments_autoreply' },
    });
  }

  destroy() {
    super.destroy();
  }
}
