import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { InstagramPostType } from '@globals';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { instagramMediaSharePluginFragment_config as InstagramMediaSharePluginConfig } from '../../../Plugins/InstagramMediaSharePlugin/@types/instagramMediaSharePluginFragment';
import { instagramPostFragment as InstagramPost } from '../../../dialogs/ChooseInstagramPostDialog/@types/instagramPostFragment';
import choosePostSvg from '../../assets/controller/choose_post.svg';
import {
  choosePostTexture,
  instagramGalleryPostTexture,
  instagramVideoPostTexture,
  instagramAccountPreviewTexture,
} from '../../assets/textures';
import { showInstagramChoosePostDialog } from '../../components';
import { HLayout, VLayout } from '../../components/Elements/Layouts';
import { Layout } from '../../components/Elements/Layouts/Layout';
import { getFlowController } from '../../PixiFieldRepository';
import { StatefulPlugin, StatefulPluginDelegate } from '../../StatefulPlugin';
import { Node } from '../../Node';
import {
  HTMLText,
  Image,
  TextureShape,
} from '../../components/Elements/Shapes';
import { logFlowPluginEvent } from '../../utils/Analytics';
import { getInstagramAccount } from '../../utils/Data/getInstagramAccount';
import { AddMedia } from '../add_media';
import { ConnectButton } from '../entry-points/common/components/ConnectButton';
import { showConnectPageDialog } from '../entry-points/common/utils/showConnectPageDialog';
import { validateIsInstagramPageConnected } from '../entry-points/common/utils/validateIsInstagramPageConnected';
import {
  buttonWidth,
  pluginWidth,
  textCardBackgroundColor,
} from '../plugin_consts';
import { Loader } from '../loader';

export class MediaSharePluginView
  extends VLayout
  implements StatefulPluginDelegate<InstagramMediaSharePluginConfig>
{
  TEST_NAME = 'FeedContentPluginView';

  private _node: Node;

  private instagramNameView: HTMLText;
  private instagramAvatarView: Image;

  private contentPlaceholder: AddMedia;
  private contentImg: Image;

  private instagramAccountSubscription: ZenObservable.Subscription | undefined;

  public readonly state: StatefulPlugin<InstagramMediaSharePluginConfig>;

  private hasInstagramAccount = false;
  // for share flow mode
  private showDefaultInstagramAccount = false;
  private instagramAccountLoading = true;

  private loader: Loader;

  private getFbPageId = () => {
    return getFlowController()?.flow.bot.status?.page_info?.id;
  };

  private getBotId = () => {
    return getFlowController()?.flow.bot.id;
  };

  private needConnectInstagramAccount = () => {
    return (
      !this.showDefaultInstagramAccount &&
      (!this.getFbPageId() || !this.hasInstagramAccount)
    );
  };

  private subscribeOnInstagramAccountQuery = () => {
    const botId = this.getBotId();
    if (botId) {
      this.instagramAccountLoading = true;
      this.loader.start();
      this.instagramAccountSubscription = getInstagramAccount(botId).subscribe({
        next: (data) => {
          this.instagramAccountLoading = false;
          this.loader.stop();
          this.hasInstagramAccount =
            !!data.data.bot?.instagram?.business_account;
          this.instagramAvatarView.url(
            data.data.bot?.instagram?.business_account?.profile_picture_url ??
              undefined,
          );
          this.instagramNameView.text(
            data.data.bot?.instagram?.business_account?.name ?? undefined,
          );
          this.renderNode();
        },
        error: () => {
          this.instagramAccountLoading = false;
          this.showDefaultInstagramAccount = true;
          this.loader.stop();
          this.renderNode();
        },
      });
    } else {
      this.instagramAccountLoading = false;
    }
  };

  constructor(
    state: StatefulPlugin<InstagramMediaSharePluginConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 10,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const pluginTopView = new HLayout({
      height: 48,
      width: pluginWidth,
    });

    const accountPreviewTexture = new TextureShape({
      texture: instagramAccountPreviewTexture,
      width: 24,
      height: 24,
    });
    this.instagramAvatarView = new Image({
      width: 24,
      height: 24,
      cornerRadius: 12,
      background: { fill: HEXColors.white },
    });
    this.instagramNameView = new HTMLText({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.InstagramMediaShare.yourAccount',
      ),
      fontSize: 15,
      fontStyle: 'semibold',
    });

    this.loader = new Loader({
      width: buttonWidth,
      height: 36,
      loaderSize: 24,
    });
    const loaderView = new Layout({
      width: buttonWidth,
      height: 36,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 4,
      },
    }).addToLayout(this.loader, {
      align: 'center',
      verticalAlign: 'center',
    });

    const disconnectedAccountView = new ConnectButton({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.InstagramMediaShare.connectInstagramAccount',
      ),
      texture: instagramAccountPreviewTexture,
    });

    pluginTopView
      .addToLayout(accountPreviewTexture, {
        margin: { left: 16 },
        align: 'center',
        gone: () => !!this.instagramAvatarView.url(),
      })
      .addToLayout(this.instagramAvatarView, {
        margin: { left: 16 },
        align: 'center',
        gone: () => !this.instagramAvatarView.url(),
      })
      .addToLayout(this.instagramNameView, {
        margin: { left: 10 },
        align: 'center',
      });

    const pluginContentView = new Layout({
      width: pluginWidth - 2,
      height: pluginWidth - 1,
      background: {
        corners: { bottomLeft: 10, bottomRight: 10 },
      },
    });

    this.contentPlaceholder = new AddMedia({
      label: i18next.t(
        'modernComponents.FlowBuilder.views.components.InstagramMediaShare.choosePost',
      ),
      fullLabel: true,
      url: choosePostSvg,
      height: pluginWidth - 2,
      corners: { bottomLeft: 10, bottomRight: 10 },
    });

    this.contentImg = new Image({
      width: pluginWidth - 2,
      height: pluginWidth - 2,
      corners: { bottomLeft: 10, bottomRight: 10 },
      url: this.state.data.config.media_url ?? undefined,
    });

    this.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });

    this.on('click', () => {
      if (this.instagramAccountLoading) {
        return;
      }
      if (!this.getFbPageId()) {
        logFlowPluginEvent(PluginType.media_share, 'Connect FB page click', {
          blockId: node.id,
          cardId: this.state.data?.id,
        });
        showConnectPageDialog(this._node, this.state.data);
        return;
      }
      if (this.needConnectInstagramAccount()) {
        logFlowPluginEvent(
          PluginType.media_share,
          'Connect Instagram account click',
          {
            blockId: node.id,
            cardId: this.state.data?.id,
          },
        );
        validateIsInstagramPageConnected(this._node)?.onClick();
        return;
      }
      logFlowPluginEvent(PluginType.media_share, 'Choose new post click', {
        blockId: node.id,
        cardId: this.state.data?.id,
      });
      showInstagramChoosePostDialog({
        selectedPosts: [
          { id: this.state.data.config.media_id ?? '' } as InstagramPost,
        ],
        onSubmit: (posts) => {
          const post = posts?.[0];
          if (post) {
            this.state.set(({ config }) => ({
              config: {
                ...config,
                media_id: post.id,
                media_url: post.media_url,
                media_type: post.media_type,
                permalink: post.permalink,
              },
            }));
            this.state.save();
          }
        },
      });
    });

    const postGalleryIcon = new TextureShape({
      texture: instagramGalleryPostTexture,
      width: 16,
      height: 16,
    });

    const postVideoIcon = new TextureShape({
      texture: instagramVideoPostTexture,
      width: 16,
      height: 16,
    });

    const postErrorView = new VLayout()
      .addToLayout(
        new TextureShape({
          texture: choosePostTexture,
          width: 22,
          height: 22,
        }),
        {
          align: 'center',
          margin: { bottom: 10 },
        },
      )
      .addToLayout(
        new HTMLText({
          text: i18next.t(
            'modernComponents.FlowBuilder.views.components.InstagramMediaShare.postViewText',
          ),
          fontSize: 15,
          fontStyle: 'semibold',
        }),
      );

    pluginContentView
      .addToLayout(this.contentPlaceholder, {
        margin: { left: 2 },
        gone: () => !!this.state.data.config.media_url,
      })
      .addToLayout(postErrorView, {
        align: 'center',
        verticalAlign: 'center',
        gone: () => !this.postError,
      })
      .addToLayout(this.contentImg, {
        margin: { left: 1 },
        gone: () => !this.state.data.config.media_url || this.postError,
      })
      .addToLayout(postGalleryIcon, {
        margin: { top: 16, left: pluginWidth - 32 },
        gone: () =>
          this.state.data.config.media_type !==
          InstagramPostType.CAROUSEL_ALBUM,
      })
      .addToLayout(postVideoIcon, {
        margin: { top: 16, left: pluginWidth - 32 },
        gone: () =>
          this.state.data.config.media_type !== InstagramPostType.VIDEO,
      });

    this.addToLayout(loaderView, {
      gone: () => !this.instagramAccountLoading,
      margin: {
        left: 16,
        top: 20,
        bottom: 20,
      },
    });
    this.addToLayout(disconnectedAccountView, {
      gone: () =>
        this.instagramAccountLoading || !this.needConnectInstagramAccount(),
      margin: {
        left: 16,
        top: 20,
        bottom: 20,
      },
    });

    this.addToLayout(pluginTopView, {
      gone: () =>
        this.instagramAccountLoading || this.needConnectInstagramAccount(),
    });

    this.addToLayout(pluginContentView, {
      gone: () =>
        this.instagramAccountLoading || this.needConnectInstagramAccount(),
    });

    this.subscribeOnInstagramAccountQuery();

    // Force invalidate validation_details
    this.state.save();
  }

  get postError() {
    return this.state.data.validation_details?.fields?.some(
      ({ field }: any) => field === 'media_id',
    );
  }

  onBeforeRender() {
    if (!this.instagramAccountSubscription) {
      this.subscribeOnInstagramAccountQuery();
    }
  }

  pluginDidSet() {
    this.contentImg.url(this.state.data.config.media_url ?? undefined);
    this.renderNode();
  }

  validationError() {
    if (this.postError) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.InstagramMediaShare.postValidationErrorText',
      );
    }
    return false;
  }

  destroy() {
    this.instagramAccountSubscription?.unsubscribe();
    super.destroy();
  }
}
