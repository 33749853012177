import { head } from 'ramda';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { ZapierTemplatesQuery_zapierTemplates as ZapierTemplate } from '../ZapierZapsDialog/@types/ZapierTemplatesQuery';

export type ZapierTemplatesFilter = (
  zapierTemplates: ZapierTemplate,
) => Boolean;

const CHATFUEL_SLUG = 'chatfuel';

export const createZapierTemplatesFilter = (
  pluginId: string,
): ZapierTemplatesFilter => {
  switch (pluginId) {
    case PluginType.event_trigger:
      return ({ steps }) => head(steps).slug === CHATFUEL_SLUG;
    case PluginType.external_integration_entry_point:
    case PluginType.integration_zapier_plugin:
      return ({ steps }) => head(steps).slug !== CHATFUEL_SLUG;
    default:
      return () => true;
  }
};
