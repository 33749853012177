import { equals, head as first, last } from 'ramda';
import { Node } from '../../../Node';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { MainLayout } from '../../../components/Elements/Layouts';
import { Line } from '../../../components/Elements/Shapes';
import { BlockView } from '../../block_view';
import { PersistentMenuItemConfig } from './types';
import { createLineMenu } from '../../Menu/createLineMenu';
import { LocaleItem, MAX_ITEM_LIMIT } from './LocaleItem';
import { ButtonEditView } from '../../button_edit_view';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import { updatePersistentMenuCard } from './EntryPointPersistentMenu';
import { notEmptyString } from '../../validation';
import { mainStrokeColor, pluginWidth } from '../../plugin_consts';
import { LineStartView } from '../../components/Line/LineStartView';
import { buttonBlockId } from '../../button_block_id';
import { HEXColors } from '@ui/_common/colors';

export class ButtonItem extends MainLayout {
  _itemEditView: ButtonEditView;

  _config: PersistentMenuItemConfig;

  _localeItemView: LocaleItem;

  _lineStartView: LineStartView;

  _node: Node;

  constructor(config: PersistentMenuItemConfig, localeItemView: LocaleItem) {
    const itemWidth = pluginWidth - 32;
    const isLast = () =>
      last<PersistentMenuItemConfig>(localeItemView._config.items) === config;
    const isFirst = () =>
      first<PersistentMenuItemConfig>(localeItemView._config.items) === config;
    super({
      width: itemWidth,
      height: 48,
      cursor: {
        in: 'pointer',
      },
      background: {
        fill: HEXColors.white,
        corners: () => ({
          bottomLeft: 1,
          bottomRight: 1,
          topLeft: 1,
          topRight: 1,
          ...((!localeItemView._card.isEditing ||
            localeItemView._config.items.length === MAX_ITEM_LIMIT) &&
          isLast()
            ? {
                bottomLeft: 8,
                bottomRight: 8,
              }
            : undefined),
          ...(!localeItemView._card.isEditing &&
          localeItemView._config.user_input_disabled &&
          isFirst()
            ? {
                topLeft: 8,
                topRight: 8,
              }
            : undefined),
        }),
        strokeWidth: (v: any) => (v._itemEditView?.isEditing() ? 2 : 0),
        stroke: mainStrokeColor,
        onhover: {
          strokeWidth: 2,
        },
      },
    });

    this.TEST_NAME = 'ButtonItem';
    this._node = localeItemView._node;
    this._config = config;
    this._localeItemView = localeItemView;

    this._itemEditView = new ButtonEditView(
      {
        width: itemWidth,
        height: 47,
        verticalAlign: 'center',
        singleLine: true,
        maxLength: 30,
        fontSize: 15,
        caption: window.i18next.t('ButtonItem-string--106-add-button-name'),
        captionFill: HEXColors.red,
        fill: HEXColors.black,
        editable: () => localeItemView._card.isEditing,
        config: this._config,
        hideCallPhoneOption: true,
        hideContinueToFlowOption: true,
        hideCalendlyOptions: true,
        hidePaymentOption: true,
        hidePopup: () => !!this._config.block_ids?.length,
        textOutsideControlsVisibility: {
          attributes: ControlVisibility.hide,
        },
        hideUniqStat: true,
        blockId: localeItemView._node.block.id,
      },
      undefined,
      (newConfig: PersistentMenuItemConfig) => {
        /* eslint-disable no-param-reassign */
        newConfig.title = (newConfig.title || '').trim();
        if (newConfig.url) {
          newConfig.url = newConfig.url.trim();
        }
        /* eslint-enable no-param-reassign */
        if (!equals(this._config, newConfig)) {
          delete this._config.url;
          delete this._config.webview_height_ratio;
          delete this._config.webview_height_ratio;
          Object.assign(this._config, newConfig);
          setTimeout(() => {
            this._itemEditView.setPosition();
            this._localeItemView.renderNode();
            this._lineStartView.renderLine();
          }, 10);
        }
      },
      () => {
        updatePersistentMenuCard(localeItemView._node, localeItemView._card);
        logFlowPluginEvent(
          localeItemView._card.plugin_id,
          'update button config',
          {
            blockId: this._localeItemView._node.id,
            cardId: this._localeItemView._card?.id,
            config: this._config,
            language: this._localeItemView._card?.config.language,
          },
        );
      },
    );

    this._itemEditView.on('pointerdown', (e: MouseEvent) => {
      if (localeItemView._card.isEditing) {
        e.stopPropagation();
      }
    });

    this._itemEditView.on('click', (e: MouseEvent) => {
      if (localeItemView._card.isEditing) {
        e.stopPropagation();
        this._itemEditView.startEditing();
      }
    });

    this.addToLayout(this._itemEditView, {
      margin: { top: 4 },
    });

    this.addToLayout(
      new Line({
        width: itemWidth,
        strokeWidth: 1,
        stroke: HEXColors.greyLight20,
      }),
      {
        margin: { top: 49.5 },
        gone: () => isLast() && !localeItemView._card.isEditing,
      },
    );

    this._lineStartView = new LineStartView(
      {
        from: this,
        node: localeItemView._node,
        offset: 40,
        mandatory: true,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        /* eslint-disable no-param-reassign */
        onConnected: (blockView: BlockView) => {
          config.block_ids = [blockView.id()];
          updatePersistentMenuCard(localeItemView._node, localeItemView._card);
          localeItemView.renderNode();
        },
        onRemoved: () => {
          config.block_ids = [];
          updatePersistentMenuCard(localeItemView._node, localeItemView._card);
          localeItemView.renderNode();
        },
        /* eslint-enable no-param-reassign */
      },
      localeItemView._card.id,
    );

    this.addToLayout(this._lineStartView, {
      margin: { left: pluginWidth - 32 - 21 },
      verticalAlign: 'center',
      gone: () => notEmptyString(this._config.url),
    });
  }

  onBeforeRender() {
    const blockId = buttonBlockId(this._config);
    if (blockId) {
      this._localeItemView._node.addOutLink(
        blockId,
        this._lineStartView._lineView,
      );
    }
  }

  focus() {
    this._itemEditView.startEditing();
  }
}
