import {
  ConnectInstagramVariant,
  getInstagramConnectionVariant,
} from '@components/dialogs/ConnectInstagramDialog';
import { showInstagramChoosePostDialog } from '@components/FlowBuilder/components';
import { getFlowController } from '@components/FlowBuilder/PixiFieldRepository';
import { PluginType } from '@components/FlowBuilder/types';
import { connectInstagram } from '../../common/utils/connectInstagram';
import {
  CommentsAutoreplyPostsSelector,
  CommentsAutoreplyPostsSelectorPluginConfig,
} from '../components/CommentsAutoreplyPostsSelector';
import {
  instagramCommentsAutoreplyEntryPointFragment_config as InstagramCommentsAutoreplyEntryPointConfig,
  instagramCommentsAutoreplyEntryPointFragment_config_posts as InstagramCommentsAutoreplyPost,
} from '../../../../../Plugins/InstagramCommentsAutoreplyEntryPoint/@types/instagramCommentsAutoreplyEntryPointFragment';
import { PostViewModel } from '../components/PostItem';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';

interface InstagramCommentsAutoreplyPostsSelectorProps {
  plugin: CommentsAutoreplyPostsSelectorPluginConfig<InstagramCommentsAutoreplyEntryPointConfig>;
  logger: PluginLogger;
}

export class InstagramCommentsAutoreplyPostsSelector extends CommentsAutoreplyPostsSelector<
  InstagramCommentsAutoreplyPost,
  InstagramCommentsAutoreplyEntryPointConfig
> {
  getPostData(post: InstagramCommentsAutoreplyPost): PostViewModel {
    return {
      __typename: post.__typename,
      id: post.id,
      link: post.permalink,
      text: post.caption,
      picture: post.media_url,
    };
  }
  onShowChoosePostsDialog(updatePostList: () => void): void {
    const handleShowChosePostDialog = () =>
      showInstagramChoosePostDialog({
        selectedPosts:
          this.params.plugin.state.data.config.posts ?? ([] as any),
        disableAnotherFlowPost: true,
        multiple: true,
        onSubmit: (posts) => {
          this.params.logger.log('Add posts click', {
            postIds: posts.map(({ id }) => id),
          });
          this.params.plugin.state.set(
            ({ config }) =>
              ({
                config: {
                  ...config,
                  posts: posts ?? [],
                },
              } as any),
          );
          this.removePostsFromOtherPlugin(
            posts.map((v) => v.id),
            false,
          );
          updatePostList();
        },
        onPostSelect: (post, selected) => {
          this.params.logger.log('Post select change', {
            postId: post.id,
            selected,
          });
        },
        onPostRemove: (post) => {
          this.params.logger.log('Remove post click (tooltip)', {
            postId: post.id,
            flowId: post.flow_id,
          });
          this.removePostsFromOtherPlugin([post.id], true);
        },
      });
    const { bot, user } = this.params.plugin._node.controller.flow;
    const {
      connected: instagramConnected,
      business_account: instagramBusinessAccount,
    } = bot?.instagram ?? {};
    const connectVariant = getInstagramConnectionVariant(
      !!instagramConnected,
      !!instagramBusinessAccount,
      Boolean(user.fbPermissions.hasInstagramMessagingPermissions),
    );

    if (
      [
        ConnectInstagramVariant.refresh,
        ConnectInstagramVariant.full,
        ConnectInstagramVariant.known,
      ].includes(connectVariant)
    ) {
      connectInstagram(
        connectVariant,
        this.params.plugin._node.block.id,
        async () => {
          await getFlowController()?.fetchFlow();
          this.params.plugin._node.renderNode();
          handleShowChosePostDialog();
        },
      );
    } else {
      handleShowChosePostDialog();
    }
  }
  private readonly params: InstagramCommentsAutoreplyPostsSelectorProps;

  constructor(params: InstagramCommentsAutoreplyPostsSelectorProps) {
    super(params.plugin, params.logger, {
      updateCardViaDeprecatedMethod: false,
      pluginType: PluginType.instagram_comments_autoreply_entry_point,
    });

    this.params = params;
  }
}
