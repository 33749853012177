import memoize from 'lodash-es/memoize';
import i18next from 'i18next';
import {
  clone,
  lensPath,
  mergeDeepRight,
  path,
  pipe,
  set,
  when,
  view,
} from 'ramda';
import { getAbandonedCartPluginGalleryDefaultConfig } from '@components/Plugins/AbandonedCartPlugin/AbandonedCartPluginConst';
import { Item } from '../../kit/Dropdown';
import {
  ProductListType,
  ProductListVisitedProductsRecommendationType,
} from '@globals';
import { DEFAULT_CONFIG_BY_TYPE, NONE_ID, PATH_BY_TYPE } from './consts';
import {
  productListPluginFragment_config as ProductListPluginConfig,
  productListPluginFragment_config_visited_products_buttons as ProductButton,
} from '../../../../Plugins/ProductListPlugin/@types/productListPluginFragment';
import { GalleryConfig } from './components/GalleryView';

export const getButtonPathByType = (type: ProductListType) => [
  ...PATH_BY_TYPE[type],
  'buttons',
  0,
];

export const setFirstButtonTitle = (title: string, type: ProductListType) =>
  when<ProductListPluginConfig, ProductListPluginConfig>(
    view(lensPath([...getButtonPathByType(type)])),
    set(lensPath([...getButtonPathByType(type), 'title']), title),
  );

export const setFisrtButtonBlockId = (
  blockId: string | null,
  type: ProductListType,
) => set(lensPath([...getButtonPathByType(type), 'block_id']), blockId);

export const setDescription = (description: string, type: ProductListType) =>
  when(
    () => type === ProductListType.back_in_stock,
    set(lensPath([...PATH_BY_TYPE[type], 'description']), description),
  );

export const setDefaultFields = (type: ProductListType) =>
  mergeDeepRight(DEFAULT_CONFIG_BY_TYPE[type]);

export const updateConfigWithReminderConfig = (
  config: ProductListPluginConfig,
  updatedReminderConfig: GalleryConfig,
) =>
  pipe<
    ProductListPluginConfig,
    ProductListPluginConfig,
    ProductListPluginConfig,
    ProductListPluginConfig,
    ProductListPluginConfig
  >(
    clone,
    setDefaultFields(config.list_type),
    setFirstButtonTitle(
      updatedReminderConfig.button.title || '',
      config.list_type,
    ),
    setDescription(updatedReminderConfig.description || '', config.list_type),
  )(config);

export const getReminderDefaultConfig = (
  config: ProductListPluginConfig,
): GalleryConfig => {
  const button = path<ProductButton>(
    getButtonPathByType(config.list_type),
    config,
  );

  switch (config.list_type) {
    case ProductListType.back_in_stock: {
      return {
        button: {
          title: button?.title || '',
        },
        description: config.back_in_stock?.description || '',
      };
    }
    case ProductListType.order_content:
    case ProductListType.static:
    case ProductListType.visited_products:
      return {
        description: '',
        button: {
          title: getAbandonedCartPluginGalleryDefaultConfig(button?.title || '')
            .button_title,
        },
      };
    default:
      throw new Error(`Unexpected list type ${config.list_type}`);
  }
};

export const getRelatedItems = memoize(
  () =>
    [
      {
        id: NONE_ID,
        title: i18next.t('shopify.visitedProducts.related.none'),
      },
      {
        id: ProductListVisitedProductsRecommendationType.recommended,
        title: i18next.t('shopify.visitedProducts.related.recommended'),
      },
      {
        id: ProductListVisitedProductsRecommendationType.similar,
        title: i18next.t('shopify.visitedProducts.related.similarTags'),
      },
    ] as Item[],
);
