import { CardButtonType, Platform } from '@globals';
import { propEq, propSatisfies, when, omit } from 'ramda';
import { logFlowPluginEvent } from '../utils/Analytics';
import { equals } from 'ramda';
import { createLineMenu } from './Menu/createLineMenu';
import { MainLayout } from '../components/Elements/Layouts';
import { ButtonEditView } from './button_edit_view';
import { notEmptyString } from './validation';
import { getPredefinedAttributes } from './entry-points/common/utils/getPredefinedAttributes';
import { resByFunc } from './utils';
import { buttonHeight, buttonWidth, mainStrokeColor } from './plugin_consts';
import { LineStartView } from './components/Line/LineStartView';
import { buttonBlockId } from './button_block_id';
import { PluginType } from '../../Plugins/common/PluginTypes';
import { BLOCK_SUBTYPES } from '../consts';
import { getFlowControllerStrict } from '../PixiFieldRepository';
import {
  lineStartViewEventEmitter,
  LineStartViewEvents,
} from './components/Line/lineStartViewEventEmitter';
import {
  BlockShortcutId,
  getBlockShortcut,
} from '../../FlowBuilder/views/helpers/blocksShortcuts';
import { isFacebookAds } from '../views/utils/utils';
import {
  isCalendlyUrlType,
  isPaymentButtonType,
  isShopifyButtonType,
} from '../FlowBuilderOverlay/overlays/ButtonPopupOverlay/utils';
import { getFlowPlatform } from '../utils/getFlowPlatform';

const shouldHideCalendlyOptions = ({ block }) => {
  const hasOTNCard = block.cards.some(
    propEq('plugin_id', PluginType.otn_request),
  );
  const hasFacebookAdsCard = block.cards.some(
    propSatisfies(isFacebookAds, 'plugin_id'),
  );
  const isPersistentMenuEP = [
    PluginType.persistent_menu_entry_point,
    PluginType.instagram_persistent_menu_entry_point,
  ].includes(block.cards[0]?.plugin_id);

  return hasOTNCard || hasFacebookAdsCard || isPersistentMenuEP;
};

const getConfigType = (createdBlockType, oldConfig) => {
  if (createdBlockType === BLOCK_SUBTYPES.calendly) {
    return CardButtonType.calendly_schedule;
  }

  if (
    createdBlockType !== BLOCK_SUBTYPES.calendly &&
    oldConfig.type === CardButtonType.calendly_schedule
  ) {
    return CardButtonType.regular;
  }

  return oldConfig.type;
};

const BLOCKS_ALLOWED_TO_HAVE_LINE_TO_CALENDLY = [
  BLOCK_SUBTYPES.send_message,
  BLOCK_SUBTYPES.comments_autoreply_content,
  BLOCK_SUBTYPES.shopify_discount,
];

const createDefaultTargetBlockData = (config) => {
  if (config?.type === CardButtonType.calendly_schedule) {
    return getBlockShortcut(BlockShortcutId.calendlyScheduleEvent);
  }
};

const shouldRemoveOutcomingLine = (config) => {
  const flow = getFlowControllerStrict();
  const isLeadsToCalendlyScheduleEvent =
    flow._nodesMap[config.block_id?.[0]]?.block.subtype ===
    BLOCK_SUBTYPES.calendly;

  const isValidConfigType =
    config?.type !== CardButtonType.calendly_schedule &&
    config?.type !== CardButtonType.regular;

  return isValidConfigType && isLeadsToCalendlyScheduleEvent;
};

export class TextCardButtonView extends MainLayout {
  TEST_NAME = 'TextCardButtonView';
  _buttonEditView;
  _lineView;
  _config;
  _node;
  _card;
  _lineStartView;
  _onDoneEditing;
  _onConnected;
  _onDisconnected;
  _flags;
  hideButtonPopupIfTargetBlockSelected;

  constructor({
    config,
    node,
    card,
    isEditing,
    hideCallPhoneOption,
    hidePaymentOption,
    hideButtonPopupIfTargetBlockSelected,
    buttonIndex,
    galleryIndex,
    onDoneEditing,
    onConfigChanged,
    onConnected,
    onDisconnected,
    flags,
    disablePaymentOption,
    containerName,
    buttonEditName,
    corners = () => ({
      bottomLeft: 5,
      bottomRight: 5,
      topLeft: 5,
      topRight: 5,
    }),
    height = () => buttonHeight,
    placeholder = '',
    maxLength = 20,
  }) {
    super({
      name: containerName,
      title: 'Text card button view: ' + config.title,
      width: buttonWidth,
      height,
      background: {
        corners,
        fill: 'white',
        stroke: mainStrokeColor,
        strokeWidth: (v) =>
          v._buttonEditView ? (v._buttonEditView._textAreaShown ? 2 : 0) : 0,
      },
    });
    Object.keys(config).forEach((key) => {
      if (config[key] === null) {
        delete config[key];
      }
    });
    this.buttonIndex = buttonIndex;
    this.galleryIndex = galleryIndex;
    this._config = config;
    this._node = node;
    this._card = card;
    this.hideButtonPopupIfTargetBlockSelected =
      hideButtonPopupIfTargetBlockSelected;
    this._onDoneEditing = onDoneEditing;
    this._onConnected = onConnected;
    this._onDisconnected = onDisconnected;
    this._flags = flags;

    this._buttonEditView = new ButtonEditView(
      {
        name: buttonEditName,
        config,
        editable: () => resByFunc(isEditing),
        height,
        width: buttonWidth,
        caption:
          placeholder ||
          i18next.t('text_card_button_view-string--106-add-button-name'),
        captionFill: '#de614d',
        maxLength,
        blockId: node.block.id,
        hideCallPhoneOption,
        hidePaymentOption,
        disablePaymentOption,
        hideCalendlyOptions: shouldHideCalendlyOptions(this._node),
        hideActionText: () => this._flags.hideActionText,
        hideStats: () => this._flags.hideStats,
        hideIcon: () => this._flags.hideIcon,
        hidePopup: () =>
          this._flags.hidePopup ||
          (!!(config.block_id || config.block_ids)?.length &&
            resByFunc(hideButtonPopupIfTargetBlockSelected)),
        error: () =>
          getFlowPlatform() === Platform.whatsapp &&
          this._card.config.buttons?.filter(
            ({ title }) => this._config.title === title,
          ).length > 1
            ? i18next.t('Whatsapp.nonUniqButtonError')
            : undefined,
      },
      undefined,
      onConfigChanged,
      () => {
        const newConfig = this._buttonEditView.config();
        newConfig.title = newConfig.title.trim();
        if (newConfig.url) {
          newConfig.url = newConfig.url.trim();
        }
        if (!equals(this._config, newConfig)) {
          delete this._config.phone_number;
          delete this._config.url;
          delete this._config.webview_height_ratio;
          delete this._config.type;
          delete this._config.payment_blocks;
          delete this._config.payment_summary;
          delete this._config.variables;

          const flow = getFlowControllerStrict();
          const node = flow._nodesMap[this._config?.block_id?.[0]];

          this.handleTypeChange(newConfig, node);

          Object.assign(this._config, newConfig);

          this._onDoneEditing(this._card, this._config);
          if (this._config.title !== newConfig.title) {
            logFlowPluginEvent(card.plugin_id, 'update button title', {
              blockId: node.id,
              title: newConfig.title,
            });
          }
        }
        this._lineStartView.renderLine();
        this.renderNode();
      },
      getPredefinedAttributes(node.block),
    );
    this.on('pointerdown', (e) => {
      if (resByFunc(isEditing)) {
        e.stopPropagation();
      }
    });
    this.on('click', (e) => {
      if (resByFunc(isEditing)) {
        e.stopPropagation();
        this._buttonEditView.startEditing(e);
      }
    });
    this.layout(this._buttonEditView);

    this._lineStartView = new LineStartView(
      {
        from: this,
        node: node,
        offset: 40,
        mandatory: () =>
          !(
            notEmptyString(this._config.url) ||
            isCalendlyUrlType(this._config?.type) ||
            isShopifyButtonType(this._config?.type)
          ),
        onDragFinished: ({ showAddBlockMenu, createBlock }) => {
          if (this._config?.type === CardButtonType.calendly_schedule) {
            createBlock();
          } else {
            showAddBlockMenu();
          }
        },
        items: createLineMenu(() => {
          const isShowScheduleCalendlyEvent = !isFacebookAds(
            node.blockView?._card?.plugin_id,
          );
          return {
            isShowConnectToExistingBlock: true,
            isShowScheduleCalendlyEvent,
          };
        }),
        defaultTargetBlockData: () =>
          createDefaultTargetBlockData(this._config),
        onConnected: (blockView) => {
          this._config.block_id = [blockView.id()];
          if (isPaymentButtonType(this._config.type)) {
            this._config.payment_blocks = [blockView.id()];
          }
          this._onConnected(this._card, this._config);
          this.renderNode();
        },
        onRemoved: () => {
          this._config.block_id = [];
          if (isPaymentButtonType(this._config.type)) {
            this._config.payment_blocks = [];
          }
          this._onDisconnected(this._card, this._config);
          this.renderNode();
        },
      },
      card.id,
    );

    this.layout(this._lineStartView, {
      margin: { left: buttonWidth - 18 },
      verticalAlign: 'center',
      gone: () =>
        this._flags.hideLineStartView ||
        this._config.hasOwnProperty('phone_number') ||
        (notEmptyString(this._config.url) &&
          resByFunc(hideButtonPopupIfTargetBlockSelected)),
    });

    this.eventEmitterSubscription = lineStartViewEventEmitter.on(
      LineStartViewEvents.lineConnected,
      this.handleLineConnectedEvent.bind(this),
    );
  }

  handleTypeChange(newConfig, node) {
    if (
      (newConfig.type === CardButtonType.calendly_schedule &&
        node?.block.subtype !== BLOCK_SUBTYPES.calendly) ||
      (newConfig.type !== CardButtonType.calendly_schedule &&
        node?.block.subtype === BLOCK_SUBTYPES.calendly)
    ) {
      this._node.removeOutLink(
        buttonBlockId(this._config),
        this._lineStartView._lineView,
      );
      delete this._config.block_id;
    }
  }

  handleLineConnectedEvent({ view, sourceBlockType, createdBlockType }) {
    if (
      view !== this ||
      !BLOCKS_ALLOWED_TO_HAVE_LINE_TO_CALENDLY.includes(sourceBlockType)
    ) {
      return;
    }

    const type = getConfigType(createdBlockType, this._config);
    const shouldUpdateConfig = type !== this._config.type;

    if (!shouldUpdateConfig) {
      return;
    }

    const newConfig =
      type === CardButtonType.calendly_schedule
        ? { ...omit(['url'], this._config), type }
        : { ...this._config, type };

    this._config = newConfig;

    if (this._card.plugin_id === 'text') {
      this._card.config.buttons[this.buttonIndex] = newConfig;
    }
    if (this._card.plugin_id === 'gallery') {
      this._card.config.gallery_cards[this.galleryIndex].buttons[
        this.buttonIndex
      ] = newConfig;
    }
    this._buttonEditView.config(newConfig);
    this._buttonEditView.renderNode();
  }

  onBeforeRender() {
    const config = this._config || { title: '' };
    if (
      (this._config.block_id && shouldRemoveOutcomingLine(this._config)) ||
      notEmptyString(this._config.phone_number) ||
      (notEmptyString(this._config.url) &&
        resByFunc(this.hideButtonPopupIfTargetBlockSelected))
    ) {
      this._node.removeOutLink(
        buttonBlockId(config),
        this._lineStartView._lineView,
      );
    } else {
      this._node.addOutLink(
        buttonBlockId(config),
        this._lineStartView._lineView,
      );
    }
  }

  focus() {
    this._buttonEditView.showOverlay();
  }

  destroy() {
    this.eventEmitterSubscription();
    super.destroy();
  }
}

export class TextCardButtonViewLegacy extends TextCardButtonView {
  constructor({
    config,
    node,
    card,
    hideCallPhoneOption,
    hidePaymentOption,
    hideButtonPopupIfTargetBlockSelected,
    buttonIndex,
    galleryIndex,
    disablePaymentOption,
    containerName,
    buttonEditName,
    onConfigChanged,
  }) {
    const flags = {
      hideLineStartView: false,
      hidePopup: false,
      hideIcon: false,
      hideStats: false,
      hideActionText: false,
    };

    super({
      config,
      node,
      card,
      isEditing: () => card.isEditing,
      hideCallPhoneOption,
      hidePaymentOption,
      hideButtonPopupIfTargetBlockSelected,
      buttonIndex,
      galleryIndex,
      onConfigChanged,
      onDoneEditing: (card) => node.updateCard(card),
      onConnected: (card) => node.updateCard(card),
      onDisconnected: (card) => node.updateCard(card),
      flags,
      disablePaymentOption,
      containerName,
      buttonEditName,
    });
  }
}
