import i18next from 'i18next';

export interface IClickableEntryPointError {
  onClick: () => void;
}

export interface IFlowBuilderError {
  message: string;
  isBlockError?: boolean;
}

interface IFlowBuilderErrorProps {
  message?: string;
  messageKey?: string;
  messageOptions?: Object;
  isBlockError?: boolean;
}

export class FlowBuilderError implements IFlowBuilderError {
  message: string;
  isBlockError: boolean;
  constructor({
    message,
    messageKey = '',
    messageOptions,
    isBlockError = false,
  }: IFlowBuilderErrorProps) {
    this.message = message || i18next.t(messageKey, messageOptions);
    this.isBlockError = isBlockError;
  }
}

export class LeadToBlockOrUrlError extends FlowBuilderError {
  constructor() {
    super({
      messageKey:
        'modernComponents.FlowBuilder.views.entryPoints.common.errors.leadToBlockOrUrlError',
      isBlockError: true,
    });
  }
}

export class LeadToBlockError extends FlowBuilderError {
  constructor() {
    super({
      messageKey:
        'modernComponents.FlowBuilder.views.entryPoints.common.errors.leadToBlockError',
      isBlockError: true,
    });
  }
}

export class ConnectFacebookPageError
  extends FlowBuilderError
  implements IClickableEntryPointError
{
  onClick: () => void;

  constructor(onClick: () => void) {
    super({
      messageKey:
        'modernComponents.FlowBuilder.views.entryPoints.common.errors.connectFacebookPageError',
      isBlockError: true,
    });

    this.onClick = onClick;
  }
}

export class ConnectWhatsappPageError
  extends FlowBuilderError
  implements IClickableEntryPointError
{
  onClick: () => void;
  constructor(onClick: () => void, messageOptions: { type: string }) {
    super({
      messageKey:
        'modernComponents.FlowBuilder.views.entryPoints.common.errors.connectWhatsappPageError',
      messageOptions,
      isBlockError: true,
    });
    this.onClick = onClick;
  }
}
