import gql from 'graphql-tag';

export const DISCONNECT_SHOPIFY_ACCOUNT_MUTATION = gql`
  mutation DisconnectShopifyAccountMutation($botId: String!) {
    unbindShopifyAccount(botId: $botId) {
      id
      connectedShop {
        id
        name
        myshopify_domain
        domain
        currency
        subscription {
          status
          confirmationUrl
          trialDays
          price
          currency
          trialEndsOn
        }
      }
    }
  }
`;
