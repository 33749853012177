import i18next from 'i18next';
import { showIntercom } from '@utils/Intercom';

export function validateIsAdAccountConnected(validationDetails: any) {
  const adAccountError = validationDetails?.fields?.find(
    ({ field }: any) => field === 'adAccount',
  );

  return adAccountError
    ? {
        message: `${i18next.t(
          'modernComponents.FlowBuilder.views.components.AdCommentsAutoreplyEntryPoint.somethingWentWrong',
        )}<br/><br/> <span style="text-decoration: underline;">${i18next.t(
          'modernComponents.FlowBuilder.views.components.AdCommentsAutoreplyEntryPoint.contactSupport',
        )}</span>`,
        isBlockError: true,
        onClick: () => {
          showIntercom();
        },
      }
    : undefined;
}
