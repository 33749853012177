import { getFlowPlatform } from '../../utils/getFlowPlatform';
import { BLOCK_SUBTYPES } from '../../consts';
import { blockMenuNodes } from './menu_view';
import {
  CreateLineMenuParams,
  GetMenuItemByConfig,
  IMenuItem,
  IMenuNode,
} from './types';
import { Platform } from '@globals';
import { additionalActionItems, collectUserData } from './sortedItemsGroups';
import { extendConfig, prepareNodeChildren } from './createLineMenu';
import { createNode } from './utils';
import { flowBuilderModule } from '@components/FlowBuilder/FlowBuilderModule';
import { getAiCustomTemplatesList } from '../plugins/AiCustomTemplates/getAiCustomTemplatesList';

export function buildMenu(
  blockSubtype: string,
): Array<IMenuNode<GetMenuItemByConfig>> {
  const menuNodes = blockMenuNodes();

  const contentBlocksNode = createNode(
    menuNodes.contentBlocks,
    flowBuilderModule.getContentPluginsCreators(),
  );
  const collectUserDataNode = createNode(
    menuNodes.collectUserData,
    collectUserData,
  );

  if (BLOCK_SUBTYPES.broadcast_flow_root === blockSubtype) {
    return [collectUserDataNode];
  }

  if (BLOCK_SUBTYPES.send_data === blockSubtype) {
    const actionItemsNode = createNode(menuNodes.actionItems, [
      ...flowBuilderModule.getActionItems(),
      ...additionalActionItems,
    ]);

    return [actionItemsNode];
  }

  if ([BLOCK_SUBTYPES.action, BLOCK_SUBTYPES.calendly].includes(blockSubtype)) {
    const actionItemsNode = createNode(
      menuNodes.actionItems,
      flowBuilderModule.getActionItems(),
    );

    return [actionItemsNode];
  }

  return [contentBlocksNode, collectUserDataNode];
}

const filterMenu = (
  menu: ReturnType<typeof buildMenu>,
  config: CreateLineMenuParams,
  platform: Platform,
) => {
  return menu
    .map((node) => ({
      ...node,
      children: prepareNodeChildren(node.children, platform, config),
    }))
    .filter((node) => node.children.length) as IMenuNode<IMenuItem>[];
};

export function createBlockMenu(
  blockSubtype: string,
  config: CreateLineMenuParams = {},
): IMenuNode<IMenuItem>[] {
  const platform = getFlowPlatform() as Platform;
  const menu = buildMenu(blockSubtype);
  const customAiPlugins = getAiCustomTemplatesList();
  const extendedConfig = extendConfig(config, customAiPlugins);

  return filterMenu(menu, extendedConfig, platform);
}
