import { PluginType } from '../../../../../Plugins/common/PluginTypes';

const SHOPIFY_ENTRY_POINTS_IDS = [
  PluginType.popup_entry_point,
  PluginType.shopify_event_entry_point,
  PluginType.shopify_customer_chat_entry_point,
];

export const isShopifyPlugin = (pluginType: PluginType | undefined) =>
  pluginType &&
  (SHOPIFY_ENTRY_POINTS_IDS.includes(pluginType) ||
    pluginType.includes('shopify'));
