import { Texture, SCALE_MODES } from 'pixi.js-legacy';

interface LoadOptions {
  resolution?: number;
  width?: number;
  height?: number;
  scaleMode?: SCALE_MODES;
}

export class FlowBuilderImageLoader {
  private callbacks: (() => void)[] = [];

  private activeLoaders = 0;

  private onFinally() {
    this.activeLoaders -= 1;
    if (this.activeLoaders === 0) {
      this.callbacks.forEach((callback) => callback());
    }
  }

  load(url: string, options?: LoadOptions): Promise<{ texture: Texture }> {
    this.activeLoaders += 1;
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.crossOrigin = 'anonymous';
      img.addEventListener('load', () => {
        resolve({ texture: Texture.from(img, options || {}) });
        this.onFinally();
      });
      img.addEventListener('error', () => {
        reject();
        this.onFinally();
      });
      img.src = url;
    });
  }

  onCompleted(callback: () => void) {
    this.callbacks.push(callback);
    return () => {
      const callbackIndex = this.callbacks.findIndex(
        (item) => item === callback,
      );
      if (callbackIndex !== -1) {
        this.callbacks.splice(callbackIndex, 1);
      }
    };
  }

  isEmpty() {
    return this.activeLoaders === 0;
  }
}
