import i18next from 'i18next';
import { clone } from 'ramda';
import { Margin } from '../../../utility_classes';
import { MainLayout, VLayout } from '../../../../components/Elements/Layouts';
import { PropFunc } from '../../../../types';
import {
  CARD_WIDTH,
  ProductCardViewConfig,
  ProductCardView,
  ProductCardViewProps,
} from '../../../components/ProductCardView/ProductCardView';
import productImg from './images/product.svg';
import { resByFunc } from '../../../utils';
import { TextCardSimpleButtonWithStat } from '../../../kit/TextCard/TextCardSimpleButtonWithStat';
import { TextCardSimpleButtonConfig } from '../../../kit/TextCard/TextCardSimpleButton';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import { FullCounterStats } from '../../../../utils/Statistics/types';

export interface GalleryConfig {
  description: string | null;
  button: TextCardSimpleButtonConfig;
}

interface GalleryViewProps {
  config: GalleryConfig;
  isEditing: PropFunc<boolean>;
  stats: FullCounterStats | undefined;
  expandable?: boolean;
  singleProductPlaceholder: boolean;
  subtitleEditable: boolean;
  hideButton?: boolean;
  onChange: (config: GalleryConfig) => void;
}

export class GalleryView extends VLayout {
  private readonly config: GalleryConfig;

  constructor({
    config,
    onChange,
    isEditing,
    stats,
    expandable,
    singleProductPlaceholder,
    subtitleEditable,
    hideButton,
  }: GalleryViewProps) {
    super();
    this.config = clone(config);

    const cardProps: ProductCardViewProps = {
      config: {
        id: '1',
        title: i18next.t('shopify.productTitle'),
        image_url: productImg,
        description: config.description || i18next.t('shopify.productPrice'),
      } as ProductCardViewConfig,
      isEditing,
      interactive: false,
      subtitleEditable,
    };

    const cardsStack = new MainLayout();

    const productCard = new ProductCardView({
      ...cardProps,
      config: {
        ...cardProps.config,
      },
      onChange: ({ description }: ProductCardViewConfig) => {
        this.config.description = description;
        logFlowPluginEvent(PluginType.product_list, 'update description text');
        onChange(this.config);
      },
    });

    cardsStack.addToLayout(productCard, {
      margin: () => {
        if (expandable && resByFunc(isEditing)) {
          return {
            top: 10,
            left: singleProductPlaceholder ? 0 : CARD_WIDTH + 16,
          };
        }
        return {
          top: 0,
          left: singleProductPlaceholder ? 0 : 10,
        };
      },
    });

    const button = new TextCardSimpleButtonWithStat({
      isEditing,
      initConfig: { title: config.button.title || '' },
      onChange: (config) => {
        this.config.button.title = config.title;
        logFlowPluginEvent(PluginType.product_list, 'update button text');
        onChange(this.config);
      },
      stats,
    });

    productCard.addToLayout(button, {
      margin: new Margin({ x: 16, bottom: 20 }),
      gone: () => hideButton,
    });

    if (!singleProductPlaceholder) {
      const secondCard = new ProductCardView(cardProps);
      secondCard.addToLayout(button, {
        margin: new Margin({ x: 16, bottom: 20 }),
        gone: () => hideButton,
      });
      cardsStack.addToLayout(secondCard, {
        margin: {
          top: 10,
        },
      });
    }

    this.addToLayout(cardsStack);
  }

  validationError() {
    return !this.config.button.title?.trim();
  }
}
