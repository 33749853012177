import { logFlowPluginEvent } from '../../../utils/Analytics';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import {
  EntryPointPersistentMenuCard,
  PersistentMenuItemConfig,
  PersistentMenuLocaleItem,
} from './types';
import { Node } from '../../../Node';
import {
  HTMLText,
  Line,
  Rect,
  TextureShape,
} from '../../../components/Elements/Shapes';
import { DEFAULT_LANGUAGE, DEFAULT_LANGUAGE_NAME } from './languages';
import { Toggle } from '../../kit/toggle';
import {
  instagramPersistentMenuUserInputTexture,
  persistentMenuUserInputTexture,
} from '../../../assets/textures';
import { ButtonItem } from './ButtonItem';
import { addButtonControl } from '../../helpers/ControlsHelpers';
import { updatePersistentMenuCard } from './EntryPointPersistentMenu';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { PlusButtonView } from '../../plus_button_view';
import { HEXColors } from '@ui/_common/colors';
import { PluginType } from '../../../../Plugins/common/PluginTypes';

export const MAX_ITEM_LIMIT = 20;

const isNeedEnabledUserInput = (config: PersistentMenuLocaleItem) =>
  !(config.items?.length > 1 || config.items?.[0]?.title);

export class LocaleItem extends VLayout {
  TEST_NAME = 'LocaleItem';
  _config: PersistentMenuLocaleItem;

  _node: Node;

  _card: EntryPointPersistentMenuCard;

  _userInputToggle: Toggle | undefined;

  _itemsBox: VLayout;

  constructor(
    config: PersistentMenuLocaleItem,
    node: Node,
    card: EntryPointPersistentMenuCard,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
      cursor: {
        in: 'default',
      },
    });

    this._config = config;
    this._node = node;
    this._card = card;

    this.addToLayout(
      new HTMLText({
        fontStyle: 'semibold',
        fill: HEXColors.black,
        fontSize: 15,
        height: 21,
        text:
          config.language === DEFAULT_LANGUAGE
            ? DEFAULT_LANGUAGE_NAME
            : config.language,
      }),
      { margin: { top: 16, left: 16, bottom: 16, right: 16 } },
    );

    if (card.plugin_id === PluginType.persistent_menu_entry_point) {
      this._userInputToggle = new Toggle(
        false,
        (_, value) => {
          // eslint-disable-next-line no-param-reassign
          config.user_input_disabled = !value;
          updatePersistentMenuCard(node, card);
          this.renderNode();

          logFlowPluginEvent(this._card.plugin_id, 'change user input status', {
            language: config.language,
            user_input_disabled: config.user_input_disabled,
            blockId: this._node.id,
            cardId: this._card?.id,
          });
        },
        window.i18next.t('LocaleItem-string-4429-allow-user-input'),
      );

      this.addToLayout(this._userInputToggle, {
        gone: () => !card.isEditing,
        margin: { left: 16, bottom: 16 },
      });
    }

    const whiteBox = new VLayout({
      width: pluginWidth - 32,
      background: {
        fill: HEXColors.white,
        cornerRadius: 8,
      },
    });

    whiteBox.on('pointerdown', (e: MouseEvent) => {
      if (card.isEditing) {
        e.stopPropagation();
      }
    });

    whiteBox.on('click', (e: MouseEvent) => {
      if (card.isEditing) {
        e.stopPropagation();
      }
    });

    this._itemsBox = new VLayout({
      width: pluginWidth - 32,
    });

    const addItemButton = new PlusButtonView(
      window.i18next.t('LocaleItem-string-5145-＋-add-menu-item'),
      pluginWidth - 32 - 32,
    );

    whiteBox.addToLayout(
      new VLayout({
        width: pluginWidth - 32,
      })
        .addToLayout(
          new TextureShape(
            card.plugin_id === PluginType.instagram_persistent_menu_entry_point
              ? {
                  width: 250,
                  height: 36,
                  texture: instagramPersistentMenuUserInputTexture,
                }
              : {
                  width: 236,
                  height: 24,
                  texture: persistentMenuUserInputTexture,
                },
          ),
          {
            margin: {
              top: 8,
              left:
                card.plugin_id ===
                PluginType.instagram_persistent_menu_entry_point
                  ? 8
                  : 16,
              bottom: 8,
            },
            gone: () => {
              return (
                config.user_input_disabled && !isNeedEnabledUserInput(config)
              );
            },
          },
        )
        .addToLayout(
          new Line({
            width: pluginWidth - 32,
            strokeWidth: 1,
            stroke: HEXColors.greyLight20,
          }),
        )
        .addToLayout(
          new Rect({
            width: 24,
            height: 4,
            cornerRadius: 2,
            fill: HEXColors.grey,
          }),
          {
            gone: () => !card.isEditing,
            margin: {
              top: 4,
              bottom: 4,
              left: (pluginWidth - 32 - 12) * 0.5,
            },
          },
        )
        .addToLayout(this._itemsBox)
        .addToLayout(addItemButton, {
          gone: () =>
            !card.isEditing || config.items.length > MAX_ITEM_LIMIT - 1,
          margin: {
            left: 16,
            bottom: 16,
            top: 20,
          },
        }),
    );

    this.addToLayout(whiteBox, {
      margin: { left: 16, bottom: 16, right: 16 },
    });

    addItemButton.on('pointerdown', (e: MouseEvent) => {
      e.stopPropagation();
    });

    addItemButton.on('click', (e: MouseEvent) => {
      e.stopPropagation();
      const newItem = { title: '' };
      config.items.push(newItem);
      updatePersistentMenuCard(node, card);
      const newItemView = new ButtonItem(newItem, this);
      this.addButtonControl(newItemView);
      this._itemsBox.addToLayout(newItemView, { margin: { bottom: 1.8 } });
      this.renderNode();
      newItemView.focus();
      logFlowPluginEvent(this._card.plugin_id, 'add button', {
        blockId: this._node.id,
        cardId: this._card?.id,
        language: this._config.language,
      });
    });

    this.createMenuItems();
  }

  updateToggleState() {
    const userInputToggleDisabled = isNeedEnabledUserInput(this._config);
    if (userInputToggleDisabled) {
      this._config.user_input_disabled = false;
    }
    this._userInputToggle?.setValue(!this._config.user_input_disabled);
    this._userInputToggle?.setDisabled(userInputToggleDisabled, true);
  }

  onBeforeRender() {
    this.updateToggleState();
  }

  createMenuItems() {
    this._itemsBox._views = [];
    this._config.items?.forEach((item: PersistentMenuItemConfig) => {
      const newItemView = new ButtonItem(item, this);
      this.addButtonControl(newItemView);
      this._itemsBox.addToLayout(newItemView, {
        margin: (view?: HLayout) => ({
          bottom: view?.isLast(newItemView) ? 1 : 2,
        }),
      });
    });
  }

  addButtonControl(newItemView: ButtonItem) {
    addButtonControl(
      newItemView,
      this._itemsBox,
      this._config.items,
      this._node,
      this._card,
      undefined,
      undefined,
      undefined,
      () => {
        this.setPersistentMenuUnsynced();
        this.renderNode();
        logFlowPluginEvent(this._card.plugin_id, 'remove button', {
          blockId: this._node.id,
          cardId: this._card?.id,
          language: this._config.language,
        });
      },
      (_, start, end) => {
        this.setPersistentMenuUnsynced();
        logFlowPluginEvent(this._card.plugin_id, 'drag button', {
          start,
          end,
          blockId: this._node.id,
          cardId: this._card?.id,
          language: this._config.language,
        });
      },
    );
  }

  setPersistentMenuUnsynced() {
    delete this._card.synced;
    this.renderNode();
  }
}
