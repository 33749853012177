import { HEXColors } from '@ui/_common/colors';
import { ButtonView } from '../../button_view';
import { createLineMenu } from '../../Menu/createLineMenu';
import { HLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { BlockView } from '../../block_view';
import { pluginWidth } from '../../plugin_consts';
import { LineStartView } from '../../components/Line/LineStartView';
import { WhatsappTemplateButtonType } from '@globals';
import {
  callPhoneTexture,
  openUrlTexture,
} from '@components/FlowBuilder/assets/textures';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import {
  ItemStatView,
  STAT_VIEW_WIDTH,
} from '../../kit/ItemStatView/ItemStatView';

const getTextureByType = (type: WhatsappTemplateButtonType) => {
  if (type === WhatsappTemplateButtonType.PHONE_NUMBER) {
    return callPhoneTexture;
  }

  if (type === WhatsappTemplateButtonType.URL) {
    return openUrlTexture;
  }

  return undefined;
};

interface WhatsappTemplateButtonProps {
  node: Node;
  title: string;
  blockId?: string | null;
  counterId?: string | null;
  type?: WhatsappTemplateButtonType;
  hideStats?: boolean;
  onConnected?(blockId: string): void;
  onDisconnected?(): void;
}

export class WhatsappTemplateButtonView extends HLayout {
  TEST_NAME = 'WhatsappTemplateButtonView';

  private readonly _node: Node;

  private readonly title: string;
  private blockId?: string | null;

  private readonly lineStartView?: LineStartView;
  private readonly buttonView: ButtonView;

  private onConnected?(blockId: string): void;
  private onDisconnected?(): void;

  constructor({
    node,
    title,
    blockId,
    counterId,
    type = WhatsappTemplateButtonType.QUICK_REPLY,
    hideStats,
    onConnected,
    onDisconnected,
  }: WhatsappTemplateButtonProps) {
    super({});

    this._node = node;
    this.title = title;
    this.blockId = blockId;
    this.onConnected = onConnected;
    this.onDisconnected = onDisconnected;

    this.buttonView = new ButtonView({
      img: getTextureByType(type),
      title: this.title,
      width: pluginWidth - 24,
      bgColor: HEXColors.white,
      cornerRadius: 5,
    });

    this.buttonView.on('pointerdown', (e: MouseEvent) => {
      e.stopPropagation();
    });

    this.addToLayout(this.buttonView, {
      margin: { left: 12 },
    });

    if (type === WhatsappTemplateButtonType.QUICK_REPLY) {
      const reminderStats = getFullCounterStats(
        counterId || '',
        this._node.block.id,
      );

      if (reminderStats && !hideStats) {
        this.addToLayout(
          new ItemStatView({
            stats: reminderStats,
            showPercentValue: true,
          }),
          { align: 'center', margin: { left: -75 } },
        );
      } else {
        this.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }), {
          align: 'center',
          margin: { left: -75 },
        });
      }

      this.lineStartView = this.drawLineStartView();
    }
  }

  onBeforeRender() {
    if (this.blockId && this.lineStartView) {
      this._node.addOutLink(this.blockId, this.lineStartView._lineView);
    }
  }

  private drawLineStartView() {
    const lineStartView = new LineStartView({
      node: this._node,
      from: this,
      items: createLineMenu({
        isShowConnectToExistingBlock: true,
      }),
      mandatory: true,
      onConnected: (blockView: BlockView) => {
        this.blockId = blockView.id();
        this.onConnected?.(this.blockId);
        this.renderNode();
      },
      onRemoved: () => {
        this.blockId = undefined;
        this.onDisconnected?.();
        this.renderNode();
      },
    });

    this.addToLayout(lineStartView, {
      margin: { left: 15 },
      align: 'center',
    });

    return lineStartView;
  }
}
