import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Icon } from '@ui/Icon';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { Tooltip2 } from '@ui/Tooltip2';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { log } from 'cf-common/src/logger';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { ApolloError, NetworkStatus } from 'apollo-client';
import { removeTypename } from '@utils/GQL/utils';
import { Flex } from '@ui/Flex';
import { Platform } from '@globals';
import { SearchableTree, NoCampaignsView } from './components';
import { REMOVE_AD_FROM_ENTRY_POINT_MUTATION, TREE_QUERY } from './queries';
import { QUERY_LIMIT } from './constants';
import {
  EntryPointRenderTree,
  FacebookAd,
  OnErrorSubmitType,
  RemoveAdFromEntryPointMutation,
} from './components/types';
import { getAdsIdsFromTree } from './utils';
import { pluginIdToAdType } from './components/utils';
import { TreeQuery, TreeQueryVariables } from './@types/TreeQuery';
import {
  RemoveAdFromEntryPoint,
  RemoveAdFromEntryPointVariables,
} from './@types/RemoveAdFromEntryPoint';
import { AdAccountsDropdown } from './components/AdAccountsDropdown';

export const getAccountId = (checkedAds: EntryPointRenderTree) =>
  checkedAds?.[0]?.ads?.[0]?.account_id;
const removeActPrefix = (str: string) => str.replace('act_', '');

const onRemoveAdError = (error: ApolloError) => {
  log.warn({ error, msg: 'Remove post from other Entry Point error' });
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: Messages.somethingWentWrong,
    },
  });
};

export const onFetchError = (msg: string) => (error: ApolloError) => {
  log.warn({ error, msg });
};

export interface ChooseAdsPopupProps {
  onDismiss: () => void;
  onSubmit: (checkedItems: EntryPointRenderTree) => void;
  onErrorSubmit?: OnErrorSubmitType;
  checkedAds?: EntryPointRenderTree;
  /**
   * There are two types of ads: ctm and sm. Specify this prop if you want to see
   * ads of concrete type.
   */
  pluginId: PluginType;
  platform: Platform.instagram | Platform.facebook;
}

const EMPTY_ARRAY = [] as EntryPointRenderTree;

export const ChooseAdsPopup: React.FC<ChooseAdsPopupProps> = ({
  onDismiss,
  onSubmit,
  onErrorSubmit,
  pluginId,
  checkedAds = EMPTY_ARRAY,
  platform,
}) => {
  const botId = useCurrentBotId();
  const { pageId, loading: pageLoading } = usePageConnected(botId);
  const [fetchAdsTree, adsTreeQuery] = useLazyQuery<
    TreeQuery,
    TreeQueryVariables
  >(TREE_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
    onError: onFetchError(
      window.i18next.t('ChooseAdsPopup-string-1029-fetch-ads-tree-error'),
    ),
  });
  const [removeAdFromEntryPointMutation] = useMutation<
    RemoveAdFromEntryPoint,
    RemoveAdFromEntryPointVariables
  >(REMOVE_AD_FROM_ENTRY_POINT_MUTATION, {
    onError: onRemoveAdError,
  });
  const [selectedAdAccountId, setSelectedAdAccountId] = useState<string>('');
  const [expanded, setExpanded] = useState<string[]>([]);
  const [checked, setChecked] = useState<string[]>([]);

  const checkedAdsIds = useMemo(() => {
    if (getAccountId(checkedAds) === removeActPrefix(selectedAdAccountId)) {
      return getAdsIdsFromTree(checkedAds);
    }

    return [];
  }, [checkedAds, selectedAdAccountId]);

  const adsType = pluginId ? pluginIdToAdType(pluginId) : undefined;

  const fetchAdsTreeHelper = useCallback(() => {
    if (!botId) {
      throw new Error(`Cannot fetch ads tree with botId = ${botId}`);
    }

    if (!selectedAdAccountId) {
      // Do nothing if selected ad account id have not initialized yet
      return;
    }

    fetchAdsTree({
      variables: {
        botId,
        accountId: selectedAdAccountId,
        limit: QUERY_LIMIT,
        type: adsType,
        platform,
      },
    });
  }, [botId, fetchAdsTree, selectedAdAccountId, adsType, platform]);

  useEffect(() => {
    fetchAdsTreeHelper();
  }, [fetchAdsTreeHelper]);

  useEffect(() => {
    setChecked(checkedAdsIds);
  }, [setChecked, checkedAdsIds]);

  const onDropdownChange = useCallback(
    (item) => {
      sendEvent({
        category: 'flows',
        action: 'dropdown change',
        label: pluginId,
      });
      setSelectedAdAccountId(item.id);
    },
    [setSelectedAdAccountId, pluginId],
  );

  const removeAdFromEntryPoint: RemoveAdFromEntryPointMutation = useCallback(
    // Use entryPointBotId if you want to remove an ad form different bot
    (ad: FacebookAd, entryPointBotId?: string | null) => {
      if (!botId) {
        throw new Error(`Cannot remove ad from entry point. botId = ${botId}`);
      }

      return removeAdFromEntryPointMutation({
        variables: {
          botId: entryPointBotId || botId,
          adId: ad.id,
          ad: removeTypename(ad),
        },
      });
    },
    [botId, removeAdFromEntryPointMutation],
  );

  const hasMessageCampaigns =
    adsTreeQuery.networkStatus !== NetworkStatus.ready || !adsTreeQuery.called
      ? true
      : Boolean(adsTreeQuery.data?.bot?.adsTree?.tree?.length);

  const shouldShowTooltip = !checked.length || Boolean(adsTreeQuery.error);

  return (
    <DefaultDialog
      header={
        <Flex alignItems="center">
          {window.i18next.t('ChooseAdsPopup-JSXText-5045-choose-ads')}
          <Tooltip2
            boundariesElement="viewport"
            type="small"
            content={
              <>
                {window.i18next.t(
                  'ChooseAdsPopup-JSXText--188-you-can-only-use-an-ad-either-in-a-messenger-or-in-an-instagram-flow-if-you-need-the-same-ad-to-work-in-both-duplicate-it-in-ads-manager',
                )}
              </>
            }
          >
            {(ref, bind) => (
              <div
                ref={ref}
                {...bind}
                style={{ marginTop: '4px', marginLeft: '4px' }}
              >
                <Icon icon="info" color="grey" />
              </div>
            )}
          </Tooltip2>
        </Flex>
      }
      onDismiss={onDismiss}
      dialogStyle={{ width: 700, height: 638 }}
    >
      <AdAccountsDropdown
        pluginId={pluginId}
        selectedAdAccountId={selectedAdAccountId}
        pageId={pageId}
        setSelectedAdAccountId={setSelectedAdAccountId}
        accountId={getAccountId(checkedAds)}
        onChange={onDropdownChange}
        isTooltipDisabled={shouldShowTooltip}
        tooltipContent={window.i18next.t(
          'ChooseAdsPopup-JSXText--748-all-the-ads-you-select-here-must-be-from-the-same-ad-account-if-you-wish-to-choose-ads-from-a-different-account-deselect-them-all-and-the-option-to-switch-accounts-will-appear',
        )}
        isDropdownDisabled={Boolean(
          adsTreeQuery.error ? checkedAds.length : checked.length,
        )}
      >
        {({ adsQuery, selectedItem, setSavedAdAccountId }) => {
          const isLoading =
            adsTreeQuery.loading || pageLoading || adsQuery.loading;

          return (
            <>
              {hasMessageCampaigns || isLoading || adsTreeQuery.error ? (
                <SearchableTree
                  pageId={pageId || ''}
                  accountId={selectedAdAccountId}
                  isMyAdAccount={Boolean(selectedItem)}
                  pluginId={pluginId}
                  checkedAds={checkedAds}
                  checkedAdsIds={checkedAdsIds}
                  isLoading={isLoading}
                  adsTreeQuery={adsTreeQuery}
                  fetchAdsTree={fetchAdsTreeHelper}
                  botId={botId}
                  onErrorSubmit={onErrorSubmit}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  checked={checked}
                  setChecked={setChecked}
                  removeAdFromEntryPoint={removeAdFromEntryPoint}
                  adsType={adsType}
                  platform={platform}
                  onSubmit={(item) => {
                    setSavedAdAccountId(selectedAdAccountId);
                    // setSavedAccountId will not set value to localstorage if we remove
                    // this setTimeout below. Probably that happens because react batches
                    // actions and when react understands that modal is about to be closed
                    // it thinks that there is no reason for calling useState. That's why
                    // setSavedAccountId will not set anything to localstorage.
                    setTimeout(() => {
                      onSubmit(item);
                    });
                  }}
                />
              ) : (
                <NoCampaignsView
                  adsType={adsType}
                  onRefresh={fetchAdsTreeHelper}
                  pluginId={pluginId}
                  platform={platform}
                />
              )}
            </>
          );
        }}
      </AdAccountsDropdown>
    </DefaultDialog>
  );
};
