import type { InteractionEvent } from 'pixi.js-legacy';
import { Point } from '../../../../../types';
import type { PixiField } from '../../../../../PixiField';

interface IDragFunc {
  (pos: Point): Point;
}

export interface DragmoveEvent extends Point {
  event: InteractionEvent;
}

export class DragHandler {
  view: any;
  moved: boolean = false;
  dragDistance: number = 0;
  dragging: boolean = false;
  dragFunc: IDragFunc | null = null;

  private pixiField: Readonly<PixiField>;
  private oldPosition: Point | null = null;
  private startPosition: Point | null = null;
  private data: any;

  constructor(view: any, pixiField: Readonly<PixiField>) {
    this.pixiField = pixiField;
    this.view = view;
    this.view
      .on('mousedown', this.onDragStart)
      .on('mouseup', this.onDragEnd)
      .on('mouseupoutside', this.onDragEnd)
      .on('mousemove', this.onDragMove);
  }

  get hoverHandler() {
    return this.pixiField.hoverHandler();
  }

  private onDragStart = (event: any) => {
    event.stopPropagation();

    this.hoverHandler.skip = true;
    this.data = event.data;
    this.dragging = true;
    this.moved = false;
    this.dragDistance = 0;
    this.startPosition = { x: this.view.position.x, y: this.view.position.y };
    this.oldPosition = this.data.getLocalPosition(this.view.parent);
    this.view.emit('dragstart');
  };

  private onDragEnd = (event: any) => {
    if (this.dragging) {
      event.stopPropagation();
      this.hoverHandler.skip = false;
      this.dragging = false;
      this.data = null;
      this.view.emit('dragend');
    }
  };

  private onDragMove = (event: any) => {
    if (this.dragging) {
      event.stopPropagation();
      const newPosition = this.data.getLocalPosition(this.view.parent);
      const oldPosition = this.oldPosition!;
      let deltaX = newPosition.x - oldPosition.x;
      let deltaY = newPosition.y - oldPosition.y;
      if (this.dragFunc) {
        const dragRes = this.dragFunc({ x: deltaX, y: deltaY });
        deltaX = dragRes.x;
        deltaY = dragRes.y;
      }
      this.dragDistance = Math.sqrt(deltaY * deltaY + deltaX * deltaX);
      this.moved = this.dragDistance > 3;
      this.view.x = this.startPosition!.x + deltaX;
      this.view.y = this.startPosition!.y + deltaY;
      this.view.emit('dragmove', { x: deltaX, y: deltaY, event });
    }
  };

  stop(): void {
    this.view
      .off('mousedown', this.onDragStart)
      .off('mouseup', this.onDragEnd)
      .off('mouseupoutside', this.onDragEnd)
      .off('mousemove', this.onDragMove);
  }
}
