import { VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Margin } from '../../utility_classes';
import { WhatsappListMessageView } from './WhatsappListMessageView';
import { HEXColors } from '@ui/_common/colors';
import { WhatsappListSectionsListView } from './WhatsappListSectionsListView';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { DividerView } from './DividerView';
import { whatsappListPluginFragment_config } from '../../../../Plugins/WhatsappListPlugin/@types/whatsappListPluginFragment';

export const cardMargin = new Margin({ x: 16, y: 16 });

export class WhatsappListPluginView
  extends VLayout
  implements StatefulPluginDelegate<whatsappListPluginFragment_config>
{
  public readonly TEST_NAME = 'WhatsappListPluginView';

  private readonly node: Node;
  private readonly state: StatefulPlugin<whatsappListPluginFragment_config>;
  private readonly logger: PluginLogger;

  constructor(
    state: StatefulPlugin<whatsappListPluginFragment_config>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 10,
      },
    });

    this.node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.logger = new PluginLogger(
      this.state.data.plugin_id,
      this.node.id,
      this.state.data.id,
    );

    this.addToLayout(this.renderView(), { margin: cardMargin });
  }

  private renderView() {
    const wrapper = new VLayout();

    wrapper
      .addToLayout(
        new WhatsappListMessageView(this.state, this.logger, {
          width: this.width() - cardMargin.x,
        }),
        { margin: new Margin({ bottom: 16 }) },
      )
      .addToLayout(
        new DividerView({
          width: this.width() - cardMargin.x,
          fill: HEXColors.grey,
        }),
        { margin: new Margin({ bottom: 16 }) },
      )
      .addToLayout(
        new WhatsappListSectionsListView(
          this.state,
          this.logger,
          { width: this.width() - cardMargin.x },
          this.node,
        ),
      );

    return wrapper;
  }

  pluginDidSet() {
    this.renderNode();
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
