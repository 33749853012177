import i18next from 'i18next';
import { toaster, ServiceMessageType } from '@services/MessageService';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { GoogleSpreadsheets_bot_googleSpreadsheets as GoogleSheet } from '@utils/Data/Google/@types/GoogleSpreadsheets';
import { GoogleUsersQuery_googleUsers as GoogleUser } from '@utils/Data/Google/@types/GoogleUsersQuery';
import {
  getGoogleSheetsObservable,
  getGoogleUsersObservable,
} from '@utils/Data/Google/helpers';
import { log } from 'cf-common/src/logger';
import { Subscription } from '@utils/Data/types';
import { BLOCK_SUBTYPES } from '../../../consts';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { Node } from '../../../Node';
import {
  pluginWidth,
  textCardBackgroundColor,
  buttonHeight,
  blockWidth,
} from '../../plugin_consts';
import { Loader } from '../../loader';
import { QRPlusButtonView } from '../../qr_plus_button_view';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { tooltips } from '../../Menu/menu_view';
import {
  pluginGoogleSheetSvgTexture,
  loaderSvgTexture,
  externalLinkIconSvgTexture,
} from '../../../assets/textures';
import { googleSheetPluginFragment_config as GoogleSheetPluginConfig } from '../../../../Plugins/GoogleSheetPlugin/@types/googleSheetPluginFragment';
import { cropHTMLText } from '../../utils';
import {
  TextureShape,
  Image,
  HTMLText,
} from '../../../components/Elements/Shapes';
import {
  Plugin,
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';

const GENERAL_PLUGIN_ERROR_FIELD = 'error:general';
const NON_EXISTENT_SPREADSHEET_ERROR_FIELD = 'error:nonExistentSpreadsheet';
const DISPLAYABLE_ERROR_FIELDS = [
  GENERAL_PLUGIN_ERROR_FIELD,
  NON_EXISTENT_SPREADSHEET_ERROR_FIELD,
];

export class GoogleSheetPluginView
  extends VLayout
  implements StatefulPluginDelegate<GoogleSheetPluginConfig>
{
  private _node: Node;
  public readonly state: StatefulPlugin<GoogleSheetPluginConfig>;
  public readonly TEST_NAME = 'GoogleSheetPluginView';

  private titleLayout: PluginTitleLayout;

  private accountInfoLoader: Loader;
  private accountInfoLoading = false;

  private profileLayout: HLayout;
  private profileEmailTextView: HTMLText;
  private profileImageView: Image;

  private chooseGoogleSheetLoader: Loader;
  private chooseGoogleSheetLoading = false;

  private spreadsheetTitleView: HTMLText;

  private attributesCountBadge: QRPlusButtonView;

  private spreadsheetContentLoader: Loader;
  private spreadsheetContentLoading = false;

  private googleUsersSubscription: Subscription | null = null;
  private googleSheetsSubscription: Subscription | null = null;

  private googleUsers: GoogleUser[] = [];
  private googleSheets: GoogleSheet[] = [];

  constructor(state: StatefulPlugin<GoogleSheetPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 10,
      },
    });

    this._node = node;

    this.state = state;
    this.state.setDelegate(this);

    this.titleLayout = new PluginTitleLayout(
      false,
      node.block.subtype === BLOCK_SUBTYPES.send_data
        ? i18next.t('GoogleSheet-string-2366-google-sheets')
        : i18next.t('GoogleSheet-string-7047-send-data-to-google-sheets'),
      pluginGoogleSheetSvgTexture,
      this.state.data,
      tooltips().googleSheet,
    );
    this.addToLayout(this.titleLayout, {
      margin: { top: 15, bottom: 15, left: 10 },
    });

    const googleSheetsLayoutMargin = {
      bottom: 15,
      left: 16,
      right: 16,
    };

    this.accountInfoLoader = GoogleSheetPluginView.createLoader(22);
    this.addToLayout(this.accountInfoLoader, {
      margin: googleSheetsLayoutMargin,
      gone: () => !this.accountInfoLoading,
    });

    this.profileLayout = new HLayout({});
    this.profileLayout.TEST_NAME = 'GoogleSheetPluginProfileLayout';

    this.profileEmailTextView = new HTMLText({
      fontSize: 15,
      verticalAlign: 'center',
      singleLine: true,
      width: pluginWidth - 30,
    });

    this.profileImageView = new Image({
      width: 22,
      height: 22,
      cornerRadius: 11,
    });

    this.profileLayout.addToLayout(this.profileImageView, {
      margin: { right: 8 },
    });
    this.profileLayout.addToLayout(this.profileEmailTextView);

    this.addToLayout(this.profileLayout, {
      margin: googleSheetsLayoutMargin,
      gone: () =>
        this.isAuthenticationError ||
        !this.state.data.config.owner_id ||
        this.accountInfoLoading ||
        getPixiFieldStrict().isViewOnly(),
    });

    this.chooseGoogleSheetLoader = GoogleSheetPluginView.createLoader(35);
    this.addToLayout(this.chooseGoogleSheetLoader, {
      margin: googleSheetsLayoutMargin,
      gone: () => this.accountInfoLoading || !this.chooseGoogleSheetLoading,
    });

    const titleFontSize = 15;
    const spreadsheetTitleLayout = new HLayout({});
    spreadsheetTitleLayout.TEST_NAME =
      'GoogleSheetPluginSpreadsheetTitleLayout';
    this.spreadsheetTitleView = new HTMLText({
      fontSize: titleFontSize,
      textDecoration: 'underline',
      singleLine: true,
    });

    const spreadsheetTitleExternalLinkIcon = new TextureShape({
      width: 8,
      height: 8,
      texture: externalLinkIconSvgTexture,
    });

    spreadsheetTitleLayout.addToLayout(this.spreadsheetTitleView);
    spreadsheetTitleLayout.addToLayout(spreadsheetTitleExternalLinkIcon, {
      margin: { top: 8, left: 4 },
    });

    this.addToLayout(spreadsheetTitleLayout, {
      margin: googleSheetsLayoutMargin,
      gone: () =>
        this.isAuthenticationError || !this.state.data.config.spreadsheet_id,
    });

    (this.spreadsheetTitleView as any).on('click', (e: any) => {
      e.stopPropagation();

      const selectedSheet = this.googleSheets.find(
        (v) => v.id === this.state.data.config.spreadsheet_id,
      );
      if (selectedSheet?.url) {
        window.open(selectedSheet?.url, '_blank');
      }
    });

    const qrButtonLeftMargin = blockWidth - 255;

    this.attributesCountBadge = new QRPlusButtonView({
      title: this.userAttributesBadgeTitle,
      disabled: true,
    });
    this.addToLayout(this.attributesCountBadge, {
      margin: () => ({
        left: qrButtonLeftMargin,
        bottom: 15,
      }),
    });

    this.spreadsheetContentLoader = GoogleSheetPluginView.createLoader();
    this.addToLayout(this.spreadsheetContentLoader, {
      margin: googleSheetsLayoutMargin,
      gone: () => !this.spreadsheetContentLoading,
    });

    if (!getPixiFieldStrict().isViewOnly()) {
      this.loadGoogleUsers();
      if (this.state.data.config.owner_id) {
        this.loadGoogleSpreadsheets();
      }
    }

    this.on('click', () => {
      this.openLeftPanel();
    });
  }

  onBeforeRender() {
    if (this.selectedGoogleAccount) {
      const { email, picture } = this.selectedGoogleAccount;
      this.profileEmailTextView.text(cropHTMLText(email, 240));
      if (picture) {
        this.profileImageView.url(picture);
      }
    } else {
      this.profileEmailTextView.text('');
      this.profileImageView.url('');
    }

    this.updateSpreadsheetTitle();

    this.attributesCountBadge.update({
      title: this.userAttributesBadgeTitle,
    });
  }

  pluginDidSet(prevState: Plugin<GoogleSheetPluginConfig>) {
    if (prevState.config.owner_id !== this.state.data.config.owner_id) {
      this.loadGoogleSpreadsheets();
    }

    this.renderNode();
  }

  pluginDidSave() {
    this.renderNode();
  }

  validationError() {
    const { validationErrorMessage } = this;

    if (validationErrorMessage) {
      return {
        message: validationErrorMessage,
        onClick: () => this.openLeftPanel(),
      };
    }

    return false;
  }

  destroy() {
    if (this.accountInfoLoading) {
      this.accountInfoLoading = false;
      this.accountInfoLoader.stop();
    }
    if (this.chooseGoogleSheetLoading) {
      this.chooseGoogleSheetLoading = false;
      this.chooseGoogleSheetLoader.stop();
    }
    this.googleUsersSubscription?.unsubscribe();
    this.googleSheetsSubscription?.unsubscribe();
    this.state.destroy();
    super.destroy();
  }

  private openLeftPanel() {
    const node = this._node;
    getPixiFieldStrict().fixBlockPosition(
      node.blockView,
      getPanelWidth(PluginType.google_sheet),
    );
    editPluginInEditorPanel(node.id, this.state.data);
  }

  private loadGoogleUsers() {
    const { botId } = this._node.controller.flow;

    this.googleUsersSubscription = getGoogleUsersObservable(botId).subscribe(
      ({ data, errors, loading }) => {
        if (loading) {
          this.accountInfoLoading = true;
          this.accountInfoLoader.start();
        } else if (this.accountInfoLoading) {
          this.accountInfoLoading = false;
          this.accountInfoLoader.stop();
        }

        if (errors) {
          log.error({
            error: errors[0],
            msg: 'Error while fetching Google users',
            data: {
              label: 'flow_google_sheet',
            },
          });
        }

        this.googleUsers = data?.googleUsers;
        this.renderNode();
      },
    );
  }

  private loadGoogleSpreadsheets() {
    const { botId } = this._node.controller.flow;
    const ownerId = this.state.data.config.owner_id!;

    this.googleSheetsSubscription?.unsubscribe();

    this.googleSheetsSubscription = getGoogleSheetsObservable(
      botId,
      ownerId,
    ).subscribe(({ data, errors, loading }) => {
      if (loading) {
        this.chooseGoogleSheetLoading = true;
        this.chooseGoogleSheetLoader.start();
      } else if (this.chooseGoogleSheetLoading) {
        this.chooseGoogleSheetLoading = false;
        this.chooseGoogleSheetLoader.stop();
      }

      if (errors) {
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: i18next.t(
              'GoogleSheet-string--165-could-not-load-google-sheets-please-try-again-later',
            ),
          },
        });
        log.error({
          error: errors[0],
          msg: 'Error while fetching Google Sheets',
          data: {
            label: 'flow_google_sheet',
          },
        });
      }

      this.googleSheets = data?.bot.googleSpreadsheets;
      this.updateSpreadsheetTitle();
      this.renderNode();
    });
  }

  private static createLoader(height = buttonHeight) {
    return new Loader(
      {
        width: pluginWidth - 32,
        height,
        background: {
          cornerRadius: 4,
        },
      },
      loaderSvgTexture,
    );
  }

  private get spreadsheetTitleText() {
    const { spreadsheet_id, spreadsheet_title } = this.state.data.config;
    return (
      spreadsheet_id &&
      (this.googleSheets?.find((v) => v.id === spreadsheet_id)?.title ??
        spreadsheet_title)
    );
  }

  private get selectedGoogleAccount() {
    return this.googleUsers?.find(
      (v) => v.id === this.state.data.config.owner_id,
    );
  }

  private updateSpreadsheetTitle() {
    const spreadsheetTitle = this.spreadsheetTitleText;

    if (spreadsheetTitle) {
      this.spreadsheetTitleView.text(cropHTMLText(spreadsheetTitle, 260));
    } else {
      this.spreadsheetTitleView.text('');
    }
  }

  private get isAuthenticationError(): boolean {
    const { config, validation_details } = this.state.data;
    return Boolean(
      config.owner_id &&
        validation_details?.fields?.some(
          ({ field }: any) => field === 'owner_id',
        ),
    );
  }

  private get userAttributesBadgeTitle(): string {
    const { use_all_attributes, user_attributes } = this.state.data.config;
    return use_all_attributes
      ? i18next.t(
          'modernComponents.FlowBuilder.plugins.GoogleSheetPlugin.allAttributesButton',
        )
      : i18next.t('GoogleSheet-Template--126-attributes', {
          count: user_attributes?.length ?? 0,
        });
  }

  private get validationErrorMessage(): string | undefined {
    const { config, validation_details } = this.state.data;
    const modalPopupWarning = `${i18next.t(
      'modernComponents.FlowBuilder.views.plugins.GoogleSheet.modalPopupWarning',
    )}<br/><br/>${i18next.t(
      'modernComponents.FlowBuilder.views.plugins.GoogleSheet.connectAccountAction',
    )}`;

    if (!config.owner_id) {
      return `${i18next.t(
        'GoogleSheet-Template-1415-connect-your-google-account',
      )}${modalPopupWarning}`;
    }

    if (!config.spreadsheet_id) {
      return `${i18next.t(
        'modernComponents.FlowBuilder.views.plugins.GoogleSheet.chooseSpreadsheetWarning',
      )}<br/><br/>${i18next.t(
        'modernComponents.FlowBuilder.views.plugins.GoogleSheet.chooseSpreadsheetAction',
      )}`;
    }

    if (!config.user_attributes?.length && !config.use_all_attributes) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.plugins.GoogleSheet.noAttributesWarning',
      );
    }

    const getNotExistentSpreadSheetText = () =>
      `${i18next.t('GoogleSheet-error-non-existent-spreadsheet', {
        title: this.spreadsheetTitleText,
      })}<br/><br/>${i18next.t(
        'modernComponents.FlowBuilder.views.plugins.GoogleSheet.chooseSpreadsheetAction',
      )}`;

    const spreadsheetWasDeleted = this.googleSheets?.find(
      ({ id }) => id === config.spreadsheet_id,
    )?.deletedFromGoogle;

    if (spreadsheetWasDeleted) {
      return getNotExistentSpreadSheetText();
    }

    if (validation_details) {
      if (this.isAuthenticationError && this.selectedGoogleAccount) {
        const { email } = this.selectedGoogleAccount;
        return `${i18next.t(
          'modernComponents.FlowBuilder.views.plugins.GoogleSheet.reconnect',
        )}<b>${email}</b>.${modalPopupWarning}`;
      }

      const errField = validation_details?.fields?.find(({ field }: any) =>
        DISPLAYABLE_ERROR_FIELDS.includes(field),
      );

      switch (errField?.field) {
        case NON_EXISTENT_SPREADSHEET_ERROR_FIELD:
          return getNotExistentSpreadSheetText();
        case GENERAL_PLUGIN_ERROR_FIELD:
          return i18next.t('GoogleSheet-error-something-went-wrong');
        default: // here handle other important errors (google sheets rate limits and etc.)
      }
    }

    return undefined;
  }
}
