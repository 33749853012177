import Maybe from 'graphql/tsutils/Maybe';
import { equals } from 'ramda';
import { isValidUrl } from '../../../utils/UrlUtils';
import { removeTypename } from '../../../utils/GQL/utils';
import { getFullAttributesBoundaries } from '../../../modern-ui/TextWithAttributesEditor/attributesBoundariesUtils';
import {
  CALENDLY_BUTTON_TYPES,
  SHOPIFY_BUTTON_TYPES,
} from '../FlowBuilderOverlay/overlays/ButtonPopupOverlay/constants';

export function isEmptyString(s: Maybe<string>): s is Maybe<''> {
  return typeof s === 'undefined' || s === null || s === '';
}

export function notEmptyString(s: Maybe<string>): s is string {
  return !isEmptyString(s);
}

export function isQRButtonValid(b: { title: Maybe<string> }) {
  return notEmptyString(b.title);
}

export function nonEmptyArray<T>(ar: Maybe<Array<T>>) {
  return Array.isArray(ar) && ar.length > 0;
}

export function emptyArray<T>(ar: Maybe<Array<T>>): ar is Maybe<[]> {
  return !ar || ar.length === 0;
}

export interface ButtonType {
  title?: Maybe<string>;
  block_id?: Maybe<Array<unknown>>;
  block_ids?: Maybe<Array<unknown>>;
}

export function hasNonEmptyButtons<T extends ButtonType>(
  buttons: Maybe<Array<T>>,
) {
  if (emptyArray(buttons)) return false;
  return buttons.some(
    (b) =>
      notEmptyString(b.title) ||
      nonEmptyArray(b.block_id) ||
      nonEmptyArray(b.block_ids),
  );
}

export interface Button extends ButtonType {
  url?: Maybe<string>;
  type?: Maybe<string>;
  phone_number?: Maybe<string>;
}

export function isButtonValid(b: Button) {
  const isUrlValid =
    notEmptyString(b.url) &&
    (isValidUrl(b.url) || getFullAttributesBoundaries(b.url).length > 0);
  const isPhoneNumberValid = notEmptyString(b.phone_number);
  const isCardConnected = nonEmptyArray(b.block_id);
  const isTitleValid = notEmptyString(b.title);
  const isTypeValid =
    (<string[]>CALENDLY_BUTTON_TYPES).includes(b.type as string) ||
    (<string[]>SHOPIFY_BUTTON_TYPES).includes(b.type as string);

  return (
    isTitleValid &&
    (isCardConnected || isUrlValid || isPhoneNumberValid || isTypeValid)
  );
}

export interface GalleryConfig {
  title: Maybe<string>;
  subtitle: Maybe<string>;
  image_url: Maybe<string>;
  buttons: Array<Button>;
}

export interface GalleryProps {
  buttonsGone?: () => void;
}

export function isGalleryCardValid(
  card: GalleryConfig,
  props: GalleryProps = {},
) {
  if (
    isEmptyString(card.title) ||
    (isEmptyString(card.subtitle) &&
      isEmptyString(card.image_url) &&
      card.buttons.length === 0)
  ) {
    return false;
  }

  if (!props?.buttonsGone?.() && card.buttons.some((b) => !isButtonValid(b))) {
    return false;
  }
  return true;
}

export const compareFromDefaultConfig = <T, U>(config: T, defaultConfig: U) => {
  const cleanDefaultConfig = removeTypename(defaultConfig);
  return Object.entries(removeTypename(config)).every(
    ([prop, value]) => !value || equals(value, cleanDefaultConfig[prop]),
  );
};
