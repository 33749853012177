import { DisplayObject, InteractionEvent } from 'pixi.js-legacy';
import { MainLayout } from '../../../components/Elements/Layouts';
import { Point } from '../../../components/Elements/Shapes';
import { Node } from '../../../Node';
import {
  getFlowController,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { BlockView } from '../../block_view';

interface DragMoveEvent {
  x: number;
  y: number;
  event: InteractionEvent;
}

interface OutsideViewItemConfig {
  createView: (node: Node) => MainLayout;
  gone: (node: Node) => boolean;
  positionFunction: (node: Node) => Point;
}

const addOutsideViewItemToLayout = (item: MainLayout) => {
  getPixiFieldStrict().viewport.addChild(item as unknown as DisplayObject);
};

const removeOutsideViewItemFromLayout = (item: MainLayout) => {
  getPixiFieldStrict().viewport.removeChild(item as unknown as DisplayObject);
};

class OutsideBlockFixedViewsService {
  private blocks = new Map<BlockView, Map<OutsideViewItemConfig, MainLayout>>();
  private outsideViewItemConfigs = new Set<OutsideViewItemConfig>();
  private reRenderindEnabled: boolean = false;
  private deferredReRenderind: boolean = false;

  private handlerDragMove = ({ event: { currentTarget } }: DragMoveEvent) => {
    const blockView = currentTarget as unknown as BlockView;
    this.blocks.get(blockView)?.forEach((_, config) => {
      this.updatePositionViewItem(config, blockView);
    });
  };

  private createOutsideViewItem(
    config: OutsideViewItemConfig,
    blockView: BlockView,
  ) {
    const view = config.createView(blockView._node);
    this.blocks.get(blockView)?.set(config, view);
    addOutsideViewItemToLayout(view);
  }

  private removeOutsideViewItem(
    config: OutsideViewItemConfig,
    blockView: BlockView,
  ) {
    const view = this.blocks.get(blockView)?.get(config);
    this.blocks.get(blockView)?.delete(config);
    if (view) {
      removeOutsideViewItemFromLayout(view);
      view.destroy();
    }
  }

  private updatePositionViewItem(
    config: OutsideViewItemConfig,
    blockView: BlockView,
  ) {
    const view = this.blocks.get(blockView)?.get(config);
    if (!view) {
      return;
    }
    const { x, y } = config.positionFunction(blockView._node);
    view.x = x;
    view.y = y;

    view.zOrder(blockView.zIndex + 1);
  }

  public attachBlock(view: BlockView) {
    if (this.blocks.get(view)) {
      return;
    }
    view.on('dragmove', this.handlerDragMove);
    this.blocks.set(view, new Map<OutsideViewItemConfig, MainLayout>());
  }

  public unattachBlock(view: BlockView) {
    view.off('dragmove', this.handlerDragMove);
    this.blocks.get(view)?.forEach(removeOutsideViewItemFromLayout);
    this.blocks.delete(view);
  }

  public addOutsideView(config: OutsideViewItemConfig) {
    this.outsideViewItemConfigs.add(config);
    this.updateAllOutsideViews();
    return () => this.removeOutsideView(config);
  }

  public removeOutsideView(config: OutsideViewItemConfig) {
    this.outsideViewItemConfigs.delete(config);
    this.updateAllOutsideViews();
  }

  public updateOutsideViews(blockView: BlockView) {
    const currentOutsideViews = this.blocks.get(blockView);

    currentOutsideViews?.forEach((_, config) => {
      if (!this.outsideViewItemConfigs.has(config)) {
        this.removeOutsideViewItem(config, blockView);
      }
    });

    this.outsideViewItemConfigs.forEach((config) => {
      const isItemShowed = currentOutsideViews?.get(config);
      const needShow = !config.gone(blockView._node);
      if (!isItemShowed && needShow) {
        this.createOutsideViewItem(config, blockView);
      }
      if (isItemShowed && !needShow) {
        this.removeOutsideViewItem(config, blockView);
      }
      if (needShow) {
        this.updatePositionViewItem(config, blockView);
      }
    });

    currentOutsideViews?.forEach((view) => {
      view.renderElement();
    });
  }

  public updateAllOutsideViews() {
    if (!this.reRenderindEnabled) {
      this.deferredReRenderind = true;
      return;
    }
    getFlowController()
      ?.allNodes()
      .forEach(({ blockView }) => {
        this.updateOutsideViews(blockView);
      });
  }

  public pauseReRendering() {
    this.reRenderindEnabled = false;
  }

  public startReRendering() {
    this.reRenderindEnabled = true;
    if (this.deferredReRenderind) {
      this.deferredReRenderind = false;
      this.updateAllOutsideViews();
    }
  }
}

export const outsideBlockFixedViewsService =
  new OutsideBlockFixedViewsService();
