import React, { HTMLProps } from 'react';
import { head, last } from 'ramda';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { ZapierTemplatesQuery_zapierTemplates_steps } from '../@types/ZapierTemplatesQuery';
import * as css from './ZapierTemplateSteps.css';

interface ZapierTemplateStepsProps extends HTMLProps<HTMLDivElement> {
  steps: ZapierTemplatesQuery_zapierTemplates_steps[];
  shortMode?: boolean;
}

const STEPS_SHOW_LIMIT = 2;

export const ZapierTemplateSteps = React.forwardRef<
  HTMLDivElement,
  ZapierTemplateStepsProps
>(({ steps, shortMode, ...props }, ref) => {
  const boxProps = {
    className: css.box,
    alignItems: 'center' as const,
    ...props,
    ref,
  };

  const firstStep = head(steps);
  const lastStep = last(steps);
  return (
    <Flex {...boxProps}>
      <img
        className={css.pic}
        src={firstStep.image}
        alt={firstStep.description || ''}
      />
      {steps.length === STEPS_SHOW_LIMIT ? (
        <Icon icon="triangle" color="grey" className={css.triangle} />
      ) : (
        <Type size="15px_DEPRECATED">...</Type>
      )}
      <img
        className={css.pic}
        src={lastStep.image}
        alt={lastStep.description || ''}
      />
    </Flex>
  );
});
