import { ObservableQuery } from 'apollo-client';
import memoize from 'lodash-es/memoize';
import {
  INSTAGRAM_ACCOUNT_QUERY,
  InstagramAccountQuery,
  InstagramAccountQueryVariables,
} from '@utils/Data/Instagram/BotAccount';

import client from '../../../../common/services/ApolloService';

export const getInstagramAccount = memoize(
  (botId: string) =>
    client.watchQuery<InstagramAccountQuery, InstagramAccountQueryVariables>({
      query: INSTAGRAM_ACCOUNT_QUERY,
      variables: {
        botId: botId ?? '',
      },
    }) as ObservableQuery<
      InstagramAccountQuery,
      InstagramAccountQueryVariables
    >,
);
