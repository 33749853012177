import {
  removeNullFields,
  removeNullFieldsDeep,
  removeTypename,
} from '@utils/GQL/utils';
import { Card } from '../../../Plugins/common';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { PLUGIN_DEFAULT_CONFIG as EMAIL_PLUGIN_DEFAULT_CONFIG } from '../../../Plugins/EmailPlugin/EmailPluginConst';
import { PLUGIN_DEFAULT_CONFIG as QUICK_REPLY_DEFAULT_CONFIG } from '../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { PLUGIN_DEFAULT_CONFIG as SEND_TO_MESSENGER_ENTRY_POINT_DEFAULT_CONFIG } from '../../../Plugins/SendToMessengerEntryPoint/SendToMessengerEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as ADD_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG } from '../../../Plugins/AddAttributePlugin/AddAttributePluginConst';
import { PLUGIN_DEFAULT_CONFIG as REMOVE_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG } from '../../../Plugins/RemoveAttributePlugin/RemoveAttributePluginConst';
import { PLUGIN_DEFAULT_CONFIG as JSON_PLUGIN_DEFAULT_CONFIG } from '../../../Plugins/JsonPlugin/JsonPluginConst';
import { PLUGIN_DEFAULT_CONFIG as CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG } from '../../../Plugins/CustomerChatEntryPoint/CustomerChatEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG } from '../../../Plugins/ShopifyCustomerChatEntryPoint/ShopifyCustomerChatEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as PERSISTENT_MENU_ENTRY_POINT_DEFAULT_CONFIG } from '../../../Plugins/PersistentMenuEntryPoint/PersistentMenuEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as COMMENTS_AUTOREPLY_DEFAULT_CONFIG } from '../../../Plugins/CommentsAutoreplyEntryPoint/CommentsAutoreplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as OPEN_AI_DEFAULT_CONFIG } from '../../../Plugins/OpenAiPlugin/OpenAiPluginConst';
import { PLUGIN_DEFAULT_CONFIG as AD_COMMENTS_AUTOREPLY_DEFAULT_CONFIG } from '../../../Plugins/AdCommentsAutoreplyEntryPoint/AdCommenntsAutoreplyEntryPointConst';
import {
  PLUGIN_DEFAULT_CTM_CONFIG as FACEBOOK_ADS_DEFAULT_CTM_CONFIG,
  PLUGIN_DEFAULT_CTM_JSON_CONFIG as FACEBOOK_ADS_DEFAULT_CTM_JSON_CONFIG,
  PLUGIN_DEFAULT_SM_CONFIG as FACEBOOK_ADS_DEFAULT_SM_CONFIG,
} from '../../../Plugins/FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_ADS_DEFAULT_CONFIG } from '../../../Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as FACEBOOK_SHOPS_DEFAULT_CONFIG } from '../../../Plugins/FacebookShopsEntryPoint/FacebookShopsEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as FACEBOOK_SHOPS_PRODUCT_LIST_DEFAULT_CONFIG } from '../../../Plugins/FacebookShopsProductListPlugin/FacebookShopsProductListPluginConst';
import { PLUGIN_DEFAULT_CONFIG as PRIVATE_REPLY_DEFAULT_CONFIG } from '../../../Plugins/PrivateReplyEntryPoint/PrivateReplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as PUBLIC_REPLY_DEFAULT_CONFIG } from '../../../Plugins/PublicReplyEntryPoint/PublicReplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as EXTERNAL_INTEGRATION_DEFAULT_CONFIG } from '../../../Plugins/ExternalIntegrationEntryPoint/ExternalIntegrationEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as ZAPIER_IMPORT_CONTENT_DEFAULT_CONFIG } from '../../../Plugins/ZapierImportContentPlugin/ZapierImportContentPluginConst';
import { PLUGIN_DEFAULT_CONFIG as ZAPIER_EVENT_TRIGGER_DEFAULT_CONFIG } from '../../../Plugins/ZapierEventTriggerPlugin/ZapierEventTriggerPluginConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_DIRECT_DEFAULT_CONFIG } from '../../../Plugins/InstagramDirectEntryPoint/InstagramDirectEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as POPUP_ENTRY_POINT_DEFAULT_CONFIG } from '../../../Plugins/PopupEntryPoint/PopupEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_DEFAULT_CONFIG } from '../../../Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as SHOPIFY_EVENT_POINT_DEFAULT_CONFIG } from '../../../Plugins/ShopifyEventEntryPoint/ShopifyEventEntryPointConst';
import {
  AbandonedCartType,
  PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG as ABANDONED_CART_POINT_DEFAULT_CONFIG,
} from '../../../Plugins/AbandonedCartPlugin/AbandonedCartPluginConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_COMMENTS_AUTOREPLY_DEFAULT_CONFIG } from '../../../Plugins/InstagramCommentsAutoreplyEntryPoint/InstagramCommentsAutoreplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as COMMENTS_LIKE_DEFAULT_CONFIG } from '../../../Plugins/CommentsLikePlugin/CommentsLikePluginConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_STORY_MENTION_DEFAULT_CONFIG } from '../../../Plugins/InstagramStoryMentionEntryPoint/InstagramStoryMentionEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_STORY_REPLY_DEFAULT_CONFIG } from '../../../Plugins/InstagramStoryReplyEntryPoint/InstagramStoryReplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as ORDER_RECEIPT_DEFAULT_CONFIG } from '../../../Plugins/OrderReceiptPlugin/OrderReceiptPluginConst';
import { PLUGIN_DEFAULT_CONFIG as CALENDLY_DEFAULT_CONFIG } from '../../../Plugins/CalendlyIntegration/CalendlyIntegrationConst';
import { PLUGIN_DEFAULT_CONFIG as BOT_LINK_DEFAULT_CONFIG } from '../../../Plugins/BotLinkEntryPoint/BotLinkEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as INSTAGRAM_LINK_DEFAULT_CONFIG } from '../../../Plugins/InstagramLinkEntryPoint/InstagramLinkEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_DIRECT_DEFAULT_CONFIG } from '../../../Plugins/WhatsappDirectEntryPoint/WhatsappDirectEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_BOT_LINK_DEFAULT_CONFIG } from '../../../Plugins/WhatsappBotLinkEntryPoint/WhatsappBotLinkEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_POPUP_DEFAULT_CONFIG } from '../../../Plugins/WhatsappPopupEntryPoint/WhatsappPopupEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as SPLIT_TRAFFIC_DEFAULT_CONFIG } from '../../../Plugins/SplitTrafficPlugin/SplitTrafficPluginConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_LIST_DEFAULT_CONFIG } from '../../../Plugins/WhatsappListPlugin/WhatsappListPluginConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_WIDGET_ENTRY_POINT_DEFAULT_CONFIG } from '../../../Plugins/WhatsappWidgetEntryPoint/WhatsappWidgetEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as DOCUMENT_PLUGIN_DEFAULT_CONFIG } from '../../../Plugins/DocumentPlugin/DocumentPluginConst';
import { PLUGIN_DEFAULT_CONFIG as VIDEO_PLUGIN_DEFAULT_CONFIG } from '../../../Plugins/VideoPlugin/VideoPluginConst';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_LOCATION_DEFAULT_CONFIG } from '../../../Plugins/WhatsappLocationPlugin/WhatsappLocationPluginConst';
import { getShortcutPluginDefaultData } from '../../../Plugins/common/getShortcutPluginDefaultData';
import { flowBuilderModule } from '@components/FlowBuilder';

export function createDefaultPlugin<T>(
  plugin_id: PluginType,
  flow?: { title: string }, // Probably required but is not used in Node.ts
): Card<T> | undefined {
  const config: any = {};
  const result: any = {
    plugin_id,
    config,
  };

  if (flowBuilderModule.hasPlugin(plugin_id)) {
    const config = flowBuilderModule.getPlugin(plugin_id).getDefaultConfig();
    result.config = removeNullFields(removeTypename(config));
  }

  if (plugin_id === PluginType.text) {
    config.text = '';
    config.buttons = [];
  }
  if (plugin_id === PluginType.datepicker_text_shortcut) {
    result.plugin_id = 'text';
    config.text = '';
    config.buttons = [];
  }
  if (plugin_id === PluginType.quick_reply) {
    result.config = removeNullFields(
      removeTypename(QUICK_REPLY_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.datepicker_quick_reply_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.split_traffic) {
    result.config = removeTypename(
      removeNullFields(SPLIT_TRAFFIC_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.gallery) {
    config.gallery_cards = [{}];
  }
  if (plugin_id === PluginType.comment) {
    config.text = '';
  }
  if (plugin_id === PluginType.email) {
    result.config = removeTypename(EMAIL_PLUGIN_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.send_to_messenger_entry_point) {
    result.config = removeTypename(
      SEND_TO_MESSENGER_ENTRY_POINT_DEFAULT_CONFIG,
    );
  }
  if (plugin_id === PluginType.add_attribute) {
    result.config = removeTypename(ADD_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.remove_attribute) {
    result.config = removeTypename(REMOVE_ATTRIBUTE_PLUGIN_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.json_plugin) {
    result.config = removeTypename(JSON_PLUGIN_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.customer_chat_entry_point) {
    result.config = removeTypename(CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.shopify_customer_chat_entry_point) {
    result.config = removeTypename(
      SHOPIFY_CUSTOMER_CHAT_ENTRY_POINT_DEFAULT_CONFIG,
    );
  }
  if (plugin_id === PluginType.ref_link_entry_point) {
    result.config.referral = flow?.title;
  }
  if (plugin_id === PluginType.instagram_bot_link_entry_point) {
    result.config.referral = flow?.title;
  }
  if (plugin_id === PluginType.persistent_menu_entry_point) {
    result.config = removeTypename(PERSISTENT_MENU_ENTRY_POINT_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.instagram_persistent_menu_entry_point) {
    result.config = removeTypename(PERSISTENT_MENU_ENTRY_POINT_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.comments_autoreply_entry_point) {
    result.config = removeTypename(COMMENTS_AUTOREPLY_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.ad_comments_autoreply_entry_point) {
    result.config = removeTypename(AD_COMMENTS_AUTOREPLY_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.ads_manager_ctm_json_entry_point) {
    result.config = removeTypename(FACEBOOK_ADS_DEFAULT_CTM_JSON_CONFIG);
  }
  if (plugin_id === PluginType.ads_manager_ctm_entry_point) {
    result.config = removeTypename(FACEBOOK_ADS_DEFAULT_CTM_CONFIG);
  }
  if (plugin_id === PluginType.instagram_ads_manager_ctm_entry_point) {
    result.config = removeTypename(INSTAGRAM_ADS_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.ads_manager_sm_entry_point) {
    result.config = removeTypename(FACEBOOK_ADS_DEFAULT_SM_CONFIG);
  }
  if (plugin_id === PluginType.facebook_shops_entry_point) {
    result.config = removeTypename(FACEBOOK_SHOPS_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.facebook_shops_product_list) {
    result.config = removeTypename(FACEBOOK_SHOPS_PRODUCT_LIST_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.private_reply_entry_point) {
    result.config = removeTypename(PRIVATE_REPLY_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.public_reply_entry_point) {
    result.config = removeTypename(PUBLIC_REPLY_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.external_integration_entry_point) {
    result.config = removeTypename(
      removeNullFields(EXTERNAL_INTEGRATION_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.integration_zapier_plugin) {
    result.config = removeTypename(
      removeNullFields(ZAPIER_IMPORT_CONTENT_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.event_trigger) {
    result.config = removeTypename(
      removeNullFields(ZAPIER_EVENT_TRIGGER_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.instagram_direct_entry_point) {
    result.config = removeTypename(
      removeNullFields(INSTAGRAM_DIRECT_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.popup_entry_point) {
    result.config = removeTypename(POPUP_ENTRY_POINT_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.shopify_back_in_stock) {
    result.config = removeTypename(
      SHOPIFY_BACK_IN_STOCK_ENTRY_POINT_DEFAULT_CONFIG,
    );
  }
  if (plugin_id === PluginType.shopify_event_entry_point) {
    result.config = removeTypename(
      removeNullFieldsDeep(SHOPIFY_EVENT_POINT_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.abandoned_cart) {
    result.config = removeTypename({
      ...ABANDONED_CART_POINT_DEFAULT_CONFIG,
      reminder_type: AbandonedCartType.gallery,
    });
  }
  if (plugin_id === PluginType.instagram_comments_autoreply_entry_point) {
    result.config = removeTypename(
      removeNullFields(INSTAGRAM_COMMENTS_AUTOREPLY_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.comments_like) {
    result.config = removeTypename(
      removeNullFields(COMMENTS_LIKE_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.instagram_story_mention_entry_point) {
    result.config = removeTypename(
      removeNullFields(INSTAGRAM_STORY_MENTION_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.instagram_story_reply_entry_point) {
    result.config = removeTypename(
      removeNullFields(INSTAGRAM_STORY_REPLY_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.order_receipt) {
    result.config = removeTypename(ORDER_RECEIPT_DEFAULT_CONFIG);
  }
  if (plugin_id === PluginType.calendly) {
    result.config = removeTypename(removeNullFields(CALENDLY_DEFAULT_CONFIG));
  }
  if (plugin_id === PluginType.text_quick_replies_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.instagram_text_quick_replies_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.collect_numbers_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.date_and_time_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.collect_answers_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.collect_phones_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.collect_emails_shortcut) {
    return undefined;
  }

  if (plugin_id === PluginType.datepicker_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.comments_reply_shortcut) {
    return undefined;
  }
  if (plugin_id === PluginType.instagram_comments_reply_shortcut) {
    return undefined;
  }

  if (plugin_id === PluginType.ref_link_entry_point) {
    result.config = removeTypename(removeNullFields(BOT_LINK_DEFAULT_CONFIG));
  }

  if (plugin_id === PluginType.open_ai) {
    result.config = removeTypename(OPEN_AI_DEFAULT_CONFIG);
  }

  if (plugin_id === PluginType.custom_ai) {
    return undefined;
  }

  if (plugin_id === PluginType.instagram_bot_link_entry_point) {
    result.config = removeTypename(
      removeNullFields(INSTAGRAM_LINK_DEFAULT_CONFIG),
    );
  }

  if (plugin_id === PluginType.whatsapp_direct_entry_point) {
    result.config = removeTypename(
      removeNullFields(WHATSAPP_DIRECT_DEFAULT_CONFIG),
    );
  }

  if (plugin_id === PluginType.whatsapp_bot_link) {
    result.config = removeTypename(
      removeNullFields(WHATSAPP_BOT_LINK_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.whatsapp_popup_entry_point) {
    result.config = removeTypename(
      removeNullFields(WHATSAPP_POPUP_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.whatsapp_list) {
    result.config = removeTypename(
      removeNullFieldsDeep(WHATSAPP_LIST_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.whatsapp_widget_entry_point) {
    result.config = removeTypename(
      removeNullFieldsDeep(WHATSAPP_WIDGET_ENTRY_POINT_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.document) {
    result.config = removeTypename(
      removeNullFieldsDeep(DOCUMENT_PLUGIN_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.video) {
    result.config = removeTypename(
      removeNullFieldsDeep(VIDEO_PLUGIN_DEFAULT_CONFIG),
    );
  }
  if (plugin_id === PluginType.whatsapp_location) {
    result.config = removeTypename(
      removeNullFieldsDeep(WHATSAPP_LOCATION_DEFAULT_CONFIG),
    );
  }

  if (
    [
      PluginType.text_reminder_shortcut,
      PluginType.gallery_reminder_shortcut,
      PluginType.order_confirmation_shortcut,
      PluginType.order_status_update_shortcut,
      PluginType.product_list_shopify_order_shortcut,
      PluginType.product_list_opt_in_confirmation_shortcut,
      PluginType.product_list_back_in_stock_message_shortcut,
      PluginType.product_visit_shortcut,
      PluginType.product_visit_shortcut,
      PluginType.product_list_product_visit_shortcut,
      PluginType.nTNDaily,
      PluginType.nTNMonthly,
      PluginType.nTNWeekly,
      PluginType.kommo_plugin,
    ].includes(plugin_id)
  ) {
    const { config, pluginType } = getShortcutPluginDefaultData(plugin_id);
    result.plugin_id = pluginType;
    result.config = removeTypename(config);
  }
  return result;
}
