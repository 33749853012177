import { differenceWith, clone } from 'ramda';
import { log } from 'cf-common/src/logger';
import { organizeBlocksAndBlocksWithZeroCoordinates } from '../../../utils/organizeBlocks';
import {
  getPixiFieldStrict,
  getFlowControllerStrict,
} from '../../../PixiFieldRepository';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { toaster } from '@services/MessageService';
import { Node } from '../../../Node';
import { BLOCK_SUBTYPES } from '../../../consts';
import { FlowTypes } from '../../../types';
import { getEntryPointCard } from '../common/utils';

type FlowBlock = FlowTypes.AggregatedFlowQuery_bot_flowBlocks;

export const showToasterAfterCreatePersistentMenuFromConfigureTab = () =>
  toaster.show({
    type: ServiceMessageType.default,
    payload: {
      message: window.i18next.t(
        'utils-string--165-we-moved-your-persistent-menu-settings-to-flows',
      ),
    },
  });

// TODO: remove this code after deprecate persistent menu from configure tab
export const preparePersistentMenuFromConfigureTab = (
  persistentMenuBlock: FlowBlock,
  updatedBlocks: FlowBlock[],
  // eslint-disable-next-line consistent-return
) => {
  try {
    const controller = getFlowControllerStrict();
    const oldBlocks = clone(controller.flow.blocks) as FlowBlock[];
    const needRenderBlocks = differenceWith(
      (lBlock, rBlock) => lBlock.id === rBlock.id,
      updatedBlocks.filter(
        (block) => block.subtype !== BLOCK_SUBTYPES.flow_root,
      ),
      oldBlocks,
    );
    controller.flow.blocks.push(...needRenderBlocks);
    needRenderBlocks.forEach((block) => {
      // eslint-disable-next-line no-param-reassign
      block.position_in_flow = {
        ...persistentMenuBlock.position_in_flow!,
      };
      const node = new Node(block, controller);
      controller.addNode(node);
    });
    const node = controller._nodesMap[persistentMenuBlock.id];
    if (needRenderBlocks.length) {
      setTimeout(async () => {
        const entryPointCard = getEntryPointCard(node.block.cards);
        if (entryPointCard) {
          entryPointCard.isEditing = true;
          node.blockView.renderNode();
        }
        await organizeBlocksAndBlocksWithZeroCoordinates(
          controller.flow.id,
          [node],
          controller._nodesMap,
        );
        getPixiFieldStrict().fixBlockPosition(node.blockView, 0);
        showToasterAfterCreatePersistentMenuFromConfigureTab();
      });
    }

    return node;
  } catch (error) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: window.i18next.t(
          'utils-string--232-something-went-wrong-please-try-to-reload-the-page',
        ),
      },
    });

    log.error({
      error,
      msg: 'Error while refetching blocks in fb entry point',
      data: { label: 'flow_persistent_menu' },
    });
  }
};
