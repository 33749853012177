import { HEXColors } from '@ui/_common/colors';
import { HLayout } from '../../../components/Elements/Layouts';
import { pluginWidth } from '../../plugin_consts';
import { WhatsappTemplateComponentFormat } from '@globals';
import { HTMLText, TextureShape } from '../../../components/Elements/Shapes';
import {
  whatsappTemplateDocumentSvgTexture,
  whatsappTemplateImageSvgTexture,
  whatsappTemplateVideoSvgTexture,
  whatsappLocationSvgTexture,
  whatsappLinkSvgTexture,
} from '../../../assets/textures';

const mediaHeaderHeight = 40;
const iconSize = 24;

const getTextureByType = (type: WhatsappTemplateComponentFormat) => {
  switch (type) {
    case WhatsappTemplateComponentFormat.DOCUMENT:
      return whatsappTemplateDocumentSvgTexture;
    case WhatsappTemplateComponentFormat.IMAGE:
      return whatsappTemplateImageSvgTexture;
    case WhatsappTemplateComponentFormat.VIDEO:
      return whatsappTemplateVideoSvgTexture;
    case WhatsappTemplateComponentFormat.LOCATION:
      return whatsappLocationSvgTexture;
    case WhatsappTemplateComponentFormat.TEXT:
      return undefined;
    default:
      return undefined;
  }
};

export class MediaHeaderStub extends HLayout {
  TEST_NAME = 'MediaHeaderStub';

  constructor(
    format: WhatsappTemplateComponentFormat,
    filename?: string,
    link?: string,
  ) {
    super({
      width: pluginWidth - 24,
      height: mediaHeaderHeight,
      background: { fill: HEXColors.baseLight },
    });

    const texture = getTextureByType(format);

    if (texture) {
      const iconView = new TextureShape({
        texture,
        width: iconSize,
        height: iconSize,
      });
      iconView.TEST_NAME = `${iconView.TEST_NAME}_${format}`;

      this.addToLayout(iconView, {
        margin: { top: (mediaHeaderHeight - iconSize) * 0.5, left: 8 },
      });
    }

    if (filename) {
      this.addToLayout(
        new HTMLText({
          text: filename,
          fontSize: 15,
          width: 190,
          singleLine: true,
        }),
        {
          margin: {
            top: 9,
            left: 8,
          },
        },
      );
    }

    if (link) {
      const linkView = new TextureShape({
        texture: whatsappLinkSvgTexture,
        width: 32,
        height: 30,
      });
      this.addToLayout(linkView, {
        margin: {
          top: 5,
          left: 8,
        },
      });

      linkView.on('click', (event) => {
        event.stopPropagation();
        window.open(link, '_blank');
      });
    }
  }
}
