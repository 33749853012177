import { Platform } from '@globals';
import i18next from 'i18next';

export interface CreateHTMLMediaTooltipParams {
  message: string;
  maxSize: string;
  docsLink: string;
}

export const createHTMLMediaTooltip = ({
  message,
  maxSize,
  docsLink,
}: CreateHTMLMediaTooltipParams): string => {
  return `
    ${message}
    <br />
    ${maxSize}
    <br />
    <br />
    <a href="${docsLink}" target="_blank" style="color: var(--white)"><span style="text-decoration: underline;">${i18next.t(
    'MediaTypes.learnMore',
  )}</span> ↗</a>
	`;
};

export enum MediaTypes {
  image = 'image',
  video = 'video',
  audio = 'audio',
}

export interface MediaType {
  pluralName: string;
  formats: string;
  maxSizeMB: number;
}

type MediaTypeMap = Record<MediaTypes, MediaType>;

export const getWhatsappMediaTypes = (): MediaTypeMap => ({
  [MediaTypes.image]: {
    pluralName: i18next.t('MediaTypes.image'),
    formats: 'JPG, PNG',
    maxSizeMB: 4,
  },
  [MediaTypes.audio]: {
    pluralName: i18next.t('MediaTypes.audio'),
    formats: 'AAC, MP3, OGG',
    maxSizeMB: 15,
  },
  [MediaTypes.video]: {
    pluralName: i18next.t('MediaTypes.video'),
    formats: 'MP4, 3GP',
    maxSizeMB: 14,
  },
});

export const WhatsappDocumentType = (): MediaType => ({
  pluralName: i18next.t('MediaTypes.documents'),
  formats: 'TXT, PDF, DOC, DOCX, XLS, XML, RTF, PPT.',
  maxSizeMB: 90,
});

export const getMessengerMediaTypes = (): MediaTypeMap => ({
  [MediaTypes.image]: {
    pluralName: i18next.t('MediaTypes.image'),
    formats: 'JPG, PNG',
    maxSizeMB: 8,
  },
  [MediaTypes.audio]: {
    pluralName: i18next.t('MediaTypes.audio'),
    formats: 'AAC, MP3, OGG',
    maxSizeMB: 22,
  },
  [MediaTypes.video]: {
    pluralName: i18next.t('MediaTypes.video'),
    formats: 'MP4',
    maxSizeMB: 22,
  },
});

export const getIgMediaTypes = (): MediaTypeMap => ({
  [MediaTypes.image]: {
    pluralName: i18next.t('MediaTypes.image'),
    formats: 'JPG, PNG, GIF',
    maxSizeMB: 8,
  },
  [MediaTypes.audio]: {
    pluralName: i18next.t('MediaTypes.audio'),
    formats: 'AAC, MP3, OGG',
    maxSizeMB: 22,
  },
  [MediaTypes.video]: {
    pluralName: i18next.t('MediaTypes.video'),
    formats: 'MP4',
    maxSizeMB: 22,
  },
});

export const makeMessageFromMediaType = (media: MediaType) =>
  i18next.t(`MediaTypes.message`, {
    mediaType: media.pluralName,
    formats: media.formats,
  });

export const getPlatformMediaTypes = (): Record<Platform, MediaTypeMap> => ({
  [Platform.facebook]: getMessengerMediaTypes(),
  [Platform.instagram]: getIgMediaTypes(),
  [Platform.whatsapp]: getWhatsappMediaTypes(),
});

export const createHTMLMediaTooltipByPlatform = (
  platform: Platform,
  mediaType: MediaTypes,
  customMaxSizeMB?: number,
) => {
  const media = getPlatformMediaTypes()[platform][mediaType];
  const mb = customMaxSizeMB || media.maxSizeMB;
  const messengerTooltip = createHTMLMediaTooltip({
    message: makeMessageFromMediaType(media),
    maxSize: i18next.t(`MediaTypes.maxSizeMB`, { mb }),
    docsLink: i18next.t(`MediaTypes.Messenger.docsLink`),
  });

  return {
    [Platform.whatsapp]: createHTMLMediaTooltip({
      message: makeMessageFromMediaType(media),
      maxSize: i18next.t(`MediaTypes.maxSizeMB`, { mb }),
      docsLink: i18next.t(`MediaTypes.Whatsapp.docsLink`),
    }),
    [Platform.facebook]: messengerTooltip,
    [Platform.instagram]: messengerTooltip,
  }[platform];
};
