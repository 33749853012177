import React from 'react';
import { CheckBox } from '@ui/CheckBox';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import { ListItemWithPicture } from '@ui/ListItemWithPicture';
import { isNode } from '@ui/Tree/utils';

import { Spacer } from '@ui/Spacer';
import { plurals } from '@utils/Plurals';
import { Flex } from '@ui/Flex';
import { CheckboxTreeRenderItemProps } from '@ui/Tree/CheckboxTree';
import capitalize from 'lodash-es/capitalize';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  ProductTreeItem,
  RemoveProductFromEntryPointsProps,
} from '../ChooseFacebookShopProductsDialog';
import { PREPEND_ENTRY_POINT_PRODUCT_ID } from '../../../../../plugins/FacebookShopsProductListPlugin/consts';
import emptyPicSrc from '../images/empty_pic.svg';
import preProductPicSrc from '../images/pre_product_pic.svg';
import * as css from './FacebookShopProductItem.css';

const LEFT_GAP = 20;

interface FacebookShopProductItemProps
  extends CheckboxTreeRenderItemProps<ProductTreeItem> {
  onRemoveProductRequest: (props: RemoveProductFromEntryPointsProps) => void;
  disableForCheck?: boolean;
  blockId?: string;
}

export const FacebookShopProductItem: React.FC<FacebookShopProductItemProps> = ({
  data,
  state,
  handlers,
  level,
  disableForCheck,
  onRemoveProductRequest,
  blockId,
}) => {
  const isProductInOtherEntryPoint =
    blockId && data.entry_point_id && data.entry_point_id !== blockId;

  const itemName = isNode(data) ? 'group' : 'product';

  if (data.id === PREPEND_ENTRY_POINT_PRODUCT_ID) {
    return (
      <Flex alignItems="center">
        <CheckBox
          onChange={() => {
            handlers.onCheckedChange();
            sendEvent({
              category: 'flows',
              action: 'Product select change',
              label: PluginType.facebook_shops_entry_point,
              propertyBag: {
                id: data.id,
                selected: !state.checked,
              },
            });
          }}
          checked={state.checked}
          disabled={disableForCheck && !state.checked}
        />
        <Spacer factor={1} horizontalFactor={3} />
        <ListItemWithPicture
          message={
            <div style={{ lineHeight: '20px' }}>
              {window.i18next.t(
                'FacebookShopProductItem-JSXText-1260-send-a-card-that-shows-an-image-of-the-last-product',
              )}
              <br />
              {window.i18next.t(
                'FacebookShopProductItem-JSXText--359-the-user-sent-a-message-about',
              )}
            </div>
          }
          messageStyles={{ maxWidth: 385 }}
          picture={preProductPicSrc}
        />
      </Flex>
    );
  }

  return (
    <Flex
      alignItems="center"
      style={{
        paddingLeft: level * LEFT_GAP,
      }}
    >
      {isProductInOtherEntryPoint ? (
        <Tooltip2
          content={
            <>
              {window.i18next.t('FacebookShopProductItem-JSXText-1282-this')}
              {itemName}
              {window.i18next.t(
                'FacebookShopProductItem-JSXText-1293-is-already-being-used-for-an-entry-point-in-the',
              )}
              {data.flow_title}
              {window.i18next.t('FacebookShopProductItem-JSXText-2140-flow')}
              <br />
              <br />
              <Anchor
                intent="tooltip"
                onClick={() => {
                  onRemoveProductRequest({
                    product: !isNode(data) ? data : undefined,
                    productGroupId: isNode(data)
                      ? data.retailer_product_group_id || undefined
                      : undefined,
                  });
                  sendEvent({
                    category: 'flows',
                    action: `Remove ${itemName} from other EP click (tooltip)`,
                    label: PluginType.facebook_shops_entry_point,
                    propertyBag: {
                      fbShopProductId: data.id,
                      flowId: data.flow_id,
                    },
                  });
                }}
              >
                {window.i18next.t(
                  'FacebookShopProductItem-JSXText-1797-remove',
                )}
                {itemName}
                {window.i18next.t(
                  'FacebookShopProductItem-JSXText--970-from-the',
                )}
                {data.flow_title}
                {window.i18next.t('FacebookShopProductItem-JSXText-4846-flow')}
              </Anchor>
            </>
          }
          placement="right"
          hoverable
          type="small"
        >
          {(ref, bind) => (
            <div className={css.checkBox} ref={ref} {...bind}>
              <Icon
                icon="warning"
                size="28px"
                color="red"
                style={{
                  marginLeft: -5,
                  marginTop: -4,
                }}
              />
            </div>
          )}
        </Tooltip2>
      ) : (
        <CheckBox
          onChange={() => {
            handlers.onCheckedChange();
            sendEvent({
              category: 'flows',
              action: 'Product select change',
              label: PluginType.facebook_shops_entry_point,
              propertyBag: {
                postId: data.id,
                selected: !state.checked,
              },
            });
          }}
          checked={state.checked}
          disabled={disableForCheck && !state.checked}
        />
      )}
      {isNode(data) ? (
        <ButtonUnstyled
          onClick={() => {
            handlers.onExpandedChange();
            sendEvent({
              category: 'flows',
              action: 'Expand tree node',
              label: PluginType.facebook_shops_entry_point,
              propertyBag: {
                postId: data.id,
                flowId: data.flow_id,
              },
            });
          }}
          style={{
            transform: state.expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
            outline: 'none',
          }}
        >
          <Icon icon="triangle" />
        </ButtonUnstyled>
      ) : (
        <Spacer horizontalFactor={3} factor={1} />
      )}
      <Tooltip2
        boundariesElement="viewport"
        type="small"
        placement="right"
        content={
          <div className={css.tooltipBox}>
            {data.image_url && (
              <Flex justifyContent="center" className={css.tooltipPicBox}>
                <img
                  className={css.tooltipPic}
                  alt={data.name}
                  src={data.image_url}
                />
              </Flex>
            )}
            {data.description && data.image_url && <Spacer factor={2} />}
            {data.description && (
              <Type color="white" as="div" size="15px_DEPRECATED">
                {data.description}
              </Type>
            )}
          </div>
        }
      >
        {(ref, bind) => (
          <ListItemWithPicture
            message={capitalize(data.name)}
            messageStyles={{ maxWidth: 385 }}
            picture={data.image_url || emptyPicSrc}
            {...bind}
            ref={ref}
          >
            <Flex>
              {data.price && (
                <>
                  <Type size="12px">{data.price}</Type>
                  <Spacer horizontalFactor={2} factor={1} />
                </>
              )}
              {data.children?.length && (
                <Flex alignItems="center">
                  <Icon
                    icon="bag"
                    size="24px"
                    color="greyDark"
                    style={{ margin: '-5px' }}
                  />
                  <Spacer horizontalFactor={1} factor={1} />
                  <Type size="12px" color="greyDark">
                    {plurals(
                      data.children.length,
                      window.i18next.t(
                        'FacebookShopProductItem-string-2367-variant',
                      ),
                      window.i18next.t(
                        'FacebookShopProductItem-string--124-variants',
                      ),
                    )}
                  </Type>
                </Flex>
              )}
            </Flex>
          </ListItemWithPicture>
        )}
      </Tooltip2>
    </Flex>
  );
};
