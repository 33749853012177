import { HEXColors } from '@ui/_common/colors';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { MainLayout } from '../../../components/Elements/Layouts';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import { PropFunc } from '../../../types';
import { pluginWidth } from '../../plugin_consts';
import { TextEditView, TextEditViewProps } from '../../text_edit_view';
import { resByFunc } from '../../utils';

interface TextCardCommonViewProps {
  initValue: string;
  onChange: (value: string) => void;
  isEditing: PropFunc<boolean>;
  isEmptyStringAvailable?: boolean;
  predefinedAttributes?: AttributesQuery_bot_variableSuggest[];
}

export class TextCardInputView extends MainLayout {
  private readonly textView: TextEditView;
  private value: string;

  constructor({
    initValue,
    onChange,
    isEditing,
    isEmptyStringAvailable,
    predefinedAttributes,
  }: TextCardCommonViewProps) {
    super();

    this.value = initValue;

    const textIsValid = () => {
      return (
        !resByFunc(isEditing) ||
        this.textView._textAreaShown ||
        (isEmptyStringAvailable ? true : !!this.value)
      );
    };

    const textProps: TextEditViewProps = {
      maxLength: 640,
      fontSize: 15,
      fill: HEXColors.black,
      text: this.value || '',
      caption: window.i18next.t('TextCardInputView-string-1116-enter-text'),
      captionFill: HEXColors.greyDark,
      enterForNewLine: false,
      align: 'left',
      editable: () => resByFunc(isEditing),
      width: pluginWidth - 30,
      margin: { left: 8, top: 8, bottom: 8, right: 8 },
      background: {
        fill: () =>
          resByFunc(isEditing) ? HEXColors.white : HEXColors.greyLight30,
        cornerRadius: 4,
        strokeWidth: (view) => {
          return !resByFunc(isEditing) ? 0 : view?.isEditing() ? 2 : 1;
        },
        stroke: (view) =>
          (view as TextEditView)._textAreaShown
            ? HEXColors.blueLight
            : textIsValid()
            ? HEXColors.grey
            : HEXColors.red,
      },
    };

    this.textView = new TextEditView(
      textProps,
      undefined,
      undefined,
      () => {
        const newText = this.textView.text().trim();
        if (this.value !== newText) {
          this.value = newText;
          onChange(this.value);
        }
        this.renderNode();
      },
      {
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
        discount: ControlVisibility.show,
      },
      predefinedAttributes,
    );

    this.addToLayout(this.textView);
  }

  public startEditing() {
    this.textView.startEditing();
  }
}
