import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { stripTags } from '@utils/stripTags';
import { FacebookShopsProductListPluginCard } from '../../plugins/FacebookShopsProductListPlugin';
import { Image, HTMLText } from '../../../components/Elements/Shapes';
import { Node } from '../../../Node';
import { MainLayout, VLayout } from '../../../components/Elements/Layouts';
import {
  PREPEND_ENTRY_POINT_PRODUCT,
  PREPEND_ENTRY_POINT_PRODUCT_ID,
} from '../../plugins/FacebookShopsProductListPlugin/consts';
import { mainStrokeColor } from '../../plugin_consts';
import { TextEditView } from '../../text_edit_view';
import { Margin } from '../../utility_classes';
import { PropFunc } from '../../../types';
import { resByFunc } from '../../utils';
import { ControlVisibility } from '../../../FlowBuilderOverlay';

export interface ProductCardViewConfig {
  id?: string;
  title: string;
  description: string | null;
  image_url: string | null;
}

export interface ProductCardViewProps {
  config: ProductCardViewConfig;
  onImageClick?: (event: Event) => void;
  node?: Node;
  card?: FacebookShopsProductListPluginCard;
  height?: number;
  interactive?: boolean;
  onChange?: (config: ProductCardViewConfig) => void;
  isEditing?: PropFunc<boolean>;
  subtitleEditable?: boolean;
}

export const CARD_WIDTH = 300;

export const EMPTY_PRODUCT_CARD_VIEW_CONFIG: ProductCardViewConfig = {
  id: '_',
  title: '',
  description: null,
  image_url: null,
};

export class ProductCardView extends VLayout {
  TEST_NAME = 'ProductCardView';

  public _config: ProductCardViewConfig;
  public _node: Node | undefined;
  public _card: FacebookShopsProductListPluginCard | undefined;

  constructor({
    isEditing,
    config,
    card,
    node,
    onImageClick,
    height,
    interactive = true,
    onChange,
    subtitleEditable,
  }: ProductCardViewProps) {
    super({
      width: CARD_WIDTH,
      height,
      background: {
        fill: HEXColors.greyLight20,
        stroke: HEXColors.grey,
        strokeWidth: 1,
        cornerRadius: 12,
        onhover: interactive
          ? {
              stroke: mainStrokeColor,
              strokeWidth: 2,
            }
          : undefined,
      },
    });

    this._config = config;
    this._card = card;
    this._node = node;

    const isPrependEntryPointProduct =
      config.id === PREPEND_ENTRY_POINT_PRODUCT_ID;
    const viewConfig: ProductCardViewConfig = isPrependEntryPointProduct
      ? PREPEND_ENTRY_POINT_PRODUCT
      : config;

    const imageBox = new MainLayout({});

    imageBox.addToLayout(
      new Image({
        url: viewConfig.image_url || undefined,
        width: CARD_WIDTH,
        height: 200,
        corners: {
          topLeft: 12,
          topRight: 12,
        },
        background: {
          fill: HEXColors._ff0000,
        },
      }),
    );

    imageBox.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });
    imageBox.on('click', (event: Event) => {
      onImageClick?.(event);
    });

    this.addToLayout(imageBox, {
      margin: { bottom: 12 },
    });

    this.addToLayout(
      new HTMLText({
        text: isPrependEntryPointProduct
          ? viewConfig.title
          : stripTags(viewConfig.title),
        fontSize: 15,
        fontStyle: 'semibold',
        width: CARD_WIDTH - 48,
        singleLine: true,
        trustedHtml: true,
      }),
      {
        margin: {
          left: 22,
          bottom: 8,
        },
      },
    );

    const textViewMargin = new Margin({ left: 16, bottom: 20 });
    const textView = new TextEditView(
      {
        caption: i18next.t('shopify.subtitle'),
        captionFill: HEXColors.grey,
        fill: HEXColors.black,
        isEditing: () => Boolean(subtitleEditable && resByFunc(isEditing)),
        text: viewConfig.description || '',
        fontColor: HEXColors.greyDark,
        fontSize: 15,
        width: CARD_WIDTH - 36,
        singleLine: false,
        align: 'left',
        margin: new Margin({ x: 6, y: 6 }),
        editable: subtitleEditable,
        maxLength: 80,
        verticalAlign: 'center',
        background: {
          stroke: mainStrokeColor,
          fill: (view) =>
            view?._textAreaShown ? HEXColors.white : HEXColors.greyLight20,
          cornerRadius: 4,
          strokeWidth: (view) => (view?._textAreaShown ? 2 : 0),
        },
      },
      undefined,
      (text) => {
        this._config.description = text.trim();
      },
      () => {
        onChange?.(this._config);
      },
      {
        attributes: ControlVisibility.auto,
        emoji: ControlVisibility.auto,
        symbolsLimit: ControlVisibility.auto,
      },
    );

    this.addToLayout(textView, { margin: textViewMargin });
  }
}
