import { updatePluginData } from '@components/FlowBuilder/StatefulPlugin/ApolloState/utils';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { getAllPluginIdsByType } from '@components/FlowBuilder/utils/getAllPluginIdsByType';
import { ApolloError } from 'apollo-client';
import { handleConflictError, Plugin } from '../../common/handleConflictError';
import {
  ApplyRuleType,
  EntryPointCommentsAutoreplyConfigCommon,
  PostsSelector,
  PostsSelectorProps,
} from './PostsSelector';

export interface CommentsAutoreplyPostsSelectorPluginConfig<T extends {}>
  extends Plugin<T> {}

export abstract class CommentsAutoreplyPostsSelector<
  T extends { id: string },
  C extends EntryPointCommentsAutoreplyConfigCommon<T>,
> extends PostsSelector<T> {
  constructor(
    private readonly plugin: CommentsAutoreplyPostsSelectorPluginConfig<C>,
    private readonly logger: PluginLogger,
    options: Omit<PostsSelectorProps, 'isEditing'>,
  ) {
    super(() => plugin.state.data, plugin._node, {
      ...options,
      updateCardViaDeprecatedMethod: false,
      isEditing: () => plugin.state.isEditing,
    });
  }

  onChange(config: C) {
    this.plugin.state.set({
      config,
    });
  }

  async onApplyRuleTypeChange(type: ApplyRuleType) {
    const {
      config: { apply_for_all_posts },
    } = this.plugin.state.data;

    this.logger.log('Apply rule to change', {
      apply_for_all_posts,
      apply_rule_to: type,
    });

    try {
      await this.plugin.state.save();
    } catch (error) {
      handleConflictError(error as ApolloError, this.plugin);
    }
  }

  protected removePostsFromOtherPlugin(
    postIds: string[],
    removeFromCurrent: boolean,
  ) {
    const entryPointsIds = getAllPluginIdsByType(
      this.plugin.state.data.plugin_id,
      this.plugin._node.controller.flow,
    );
    entryPointsIds.forEach((id) => {
      if (id === this.plugin.state.data.id && !removeFromCurrent) {
        return;
      }
      updatePluginData<C>(id, (data) => {
        // eslint-disable-next-line no-param-reassign
        data.card.config.posts =
          data.card.config.posts?.filter(
            (v) => !postIds.includes(this.getPostData(v).id),
          ) ?? [];
      });
    });
  }
}
