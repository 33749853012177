import gql from 'graphql-tag';
import client from '../../../../../../common/services/ApolloService';
import { sequentialPolling } from '../../../../../../common/plugins/cardMediaUpload';
import { facebookAdsCardUploadStatus_facebookAdEntryPointCardUploadStatus } from './@types/facebookAdsCardUploadStatus';

const FACEBOOK_ADS_CARD_UPLOAD_STATUS_QUERY = gql`
  query facebookAdsCardUploadStatus($id: String!) {
    facebookAdEntryPointCardUploadStatus(id: $id) {
      status
      ads {
        id
        status
        errorMessage
      }
    }
  }
`;

export function pollForAdsCardUploadStatus(cardId: string) {
  let cancel: () => void;
  return {
    cancel: () => cancel(),
    promise:
      new Promise<facebookAdsCardUploadStatus_facebookAdEntryPointCardUploadStatus>(
        (resolve, reject) => {
          cancel = sequentialPolling({
            interval: 1000,
            async taskFn() {
              return client
                .query({
                  query: FACEBOOK_ADS_CARD_UPLOAD_STATUS_QUERY,
                  variables: { id: cardId },
                  fetchPolicy: 'network-only',
                })
                .then(({ data }) => {
                  return data.facebookAdEntryPointCardUploadStatus;
                })
                .then(({ status, ads }) => {
                  if (status !== 'InProgress') {
                    cancel();
                    resolve({
                      status,
                      ads,
                    } as facebookAdsCardUploadStatus_facebookAdEntryPointCardUploadStatus);
                  }
                })
                .catch((error) => {
                  cancel();
                  const { graphQLErrors } = error;
                  if (graphQLErrors && graphQLErrors.length) {
                    const errorMessage = graphQLErrors[0].extensions.message;
                    reject(new Error(errorMessage));
                  } else {
                    reject(error);
                  }
                });
            },
          });
        },
      ),
  };
}
