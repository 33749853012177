import { HEXColors } from '@ui/_common/colors';
import { HLayout, MainLayout } from '../../components/Elements/Layouts';
import { Circle, HTMLText } from '../../components/Elements/Shapes';
import { inactiveStrokeColor, pluginWidth } from '../plugin_consts';

export const RADIO_SIZE = 16;

export interface RadioProps {
  width?: number;
}

export class Radio extends HLayout {
  TEST_NAME = 'Radio';
  _value: boolean;

  constructor(
    label: string,
    value: boolean,
    onChange: (value: boolean) => void,
    props: RadioProps = {
      width: pluginWidth,
    },
  ) {
    super({
      cursor: { in: 'pointer' },
      ...props,
      background: {
        cornerRadius: 4,
        fill: HEXColors.grey,
        opacity: 0,
        onhover: {
          opacity: 0.3,
        },
      },
    });
    this._value = value || false;
    this.layout(
      new MainLayout({
        width: RADIO_SIZE,
        height: RADIO_SIZE,
        background: {
          fill: HEXColors.white,
          cornerRadius: RADIO_SIZE * 0.5,
          strokeWidth: 1,
          stroke: inactiveStrokeColor,
        },
      }).layout(
        new Circle({
          radius: 5,
          fill: HEXColors.blue,
        }),
        {
          gone: () => !this._value,
          margin: {
            top: RADIO_SIZE * 0.5,
            left: RADIO_SIZE * 0.5,
          },
        },
      ),
      {
        margin: {
          top: 6,
          bottom: 6,
          left: 6,
        },
      },
    );

    this.layout(
      new HTMLText({
        text: label,
        height: RADIO_SIZE,
        fontSize: 15,
        verticalAlign: 'center',
      }),
      { margin: { top: 6, left: 10 } },
    );

    this.on('pointerdown', (event: MouseEvent) => {
      event.stopPropagation();
      this._value = true;
      this.renderNode();
      if (onChange) {
        onChange(this._value);
      }
    });
    this.on('click', (event: MouseEvent) => {
      event.stopPropagation();
    });
  }

  setValue(value: boolean) {
    this._value = value;
    this.renderNode();
  }
}
