import { removeNullFieldsDeep, removeTypename } from '@utils/GQL/utils';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { productListPluginFragment_config } from '@components/Plugins/ProductListPlugin/@types/productListPluginFragment';
import { PLUGIN_DEFAULT_CONFIG as CALENDLY_DEFAULT_PLUGIN } from '../../../Plugins/CalendlyIntegration/CalendlyIntegrationConst';
import {
  CardButtonType,
  ExternalIntegrationEntryPointType,
  ProductListShopifyOrderButtonType,
  ProductListType,
  QuickReplyType,
} from '@globals';
import {
  AbandonedCartType,
  PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG,
} from '../../../Plugins/AbandonedCartPlugin/AbandonedCartPluginConst';
import { getShortcutPluginDefaultData } from '../../../Plugins/common/getShortcutPluginDefaultData';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { PLUGIN_DEFAULT_CONFIG as ORDER_RECEIPT_PLUGIN_DEFAULT_CONFIG } from '../../../Plugins/OrderReceiptPlugin/OrderReceiptPluginConst';
import { PLUGIN_DEFAULT_CONFIG as ASSIGNEE_ADMIN_PLUGIN_DEFAULT_CONFIG } from '../../modules/AssignAdminPluginModule/AssignAdminPluginConst';
import {
  getQuickReplyShortcut,
  getQuickReplyConfig,
} from '../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { getDatePickerConfig } from '../../../Plugins/DatePickerPlugin/DatePickerPluginConst';
import { BLOCK_SUBTYPES } from '../../consts';
import { CalendlyAttributes } from '../plugins/CalendlyIntegration/consts';
import { BACK_IN_STOCK_OTN_PURPOSE } from '../../../Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { DEFAULT_CONTENT_CARDS as instagramCommentsReplyCards } from '../entry-points/EntryPointCommentsAutoreply/Instagram/constants';
import { DEFAULT_CONTENT_CARDS as commentsReplyCards } from '../entry-points/EntryPointCommentsAutoreply/Messenger/constants';
import { PREVENT_INTERPOLATE } from '@utils/I18next/consts';
import { PLUGIN_DEFAULT_CONFIG as WHATSAPP_DIRECT_DEFAULT_CONFIG } from '@components/Plugins/WhatsappDirectEntryPoint/WhatsappDirectEntryPointConst';

export enum BlockShortcutId {
  liveChat = 'liveChat',
  textReminder = 'textReminder',
  galleryReminder = 'galleryReminder',
  calendlyEventConfirmation = 'calendlyEventConfirmation',
  calendlyEventReminder = 'calendlyEventReminder',
  calendlyScheduleEvent = 'calendlyScheduleEvent',
  shopifyDiscount = 'shopifyDiscount',
  receipt = 'receipt',
  postPurchase = 'postPurchase',
  datepicker = 'datepicker',
  dateAndTime = 'dateAndTime',
  productVisitReminder = 'productVisitReminder',
  backInStockOptInConfirmation = 'backInStockOptInConfirmation',
  backInStockOTN = 'backInStockOTN',
  backInStockMessage = 'backInStockMessage',
  nTNDaily = 'nTNDaily',
  nTNWeekly = 'nTNWeekly',
  nTNMonthly = 'nTNMonthly',
  collectAnswers = 'collectAnswers',
  collectEmails = 'collectEmails',
  collectPhones = 'collectPhones',
  collectNumbers = 'collectNumbers',
  commentsReply = 'commentsReply',
  instagramCommentsReply = 'instagramCommentsReply',
  whatsappDirect = 'whatsappDirect',
}

const prepareBlockShortcuts = memoize(() => ({
  [BlockShortcutId.liveChat]: {
    subtype: BLOCK_SUBTYPES.action,
    cards: [
      {
        plugin_id: PluginType.conversation_assign_admin,
        config: ASSIGNEE_ADMIN_PLUGIN_DEFAULT_CONFIG,
      },
      {
        plugin_id: PluginType.flowbuilder_livechat,
        // Когфиг должен быть пустым объектом, сам конфиг создается и приходит с бека
        config: {},
      },
    ],
  },
  [BlockShortcutId.nTNDaily]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: getShortcutPluginDefaultData(PluginType.nTNDaily).pluginType,
        config: getShortcutPluginDefaultData(PluginType.nTNDaily).config,
      },
    ],
  },
  [BlockShortcutId.nTNWeekly]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: getShortcutPluginDefaultData(PluginType.nTNWeekly)
          .pluginType,
        config: getShortcutPluginDefaultData(PluginType.nTNWeekly).config,
      },
    ],
  },
  [BlockShortcutId.nTNMonthly]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: getShortcutPluginDefaultData(PluginType.nTNMonthly)
          .pluginType,
        config: getShortcutPluginDefaultData(PluginType.nTNMonthly).config,
      },
    ],
  },
  [BlockShortcutId.datepicker]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          buttons: [],
        },
      },
      {
        plugin_id: PluginType.date_picker,
        config: getDatePickerConfig({
          date_button: {
            title: i18next.t(
              'modernComponents.FlowBuilder.views.components.CollectUserData.datePicker.shortcut.buttonTitle',
            ),
          },
          skip_button: {
            title: i18next.t('QuickReplyPlugin-string-2578-skip'),
          },
          property: 'date',
        }),
      },
    ],
  },
  [BlockShortcutId.dateAndTime]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          buttons: [],
        },
      },
      {
        plugin_id: PluginType.date_picker,
        config: getDatePickerConfig({
          date_button: {
            title: i18next.t(
              'modernComponents.FlowBuilder.views.components.CollectUserData.dateAndTime.shortcut.buttonTitle',
            ),
          },
          skip_button: {
            title: i18next.t('QuickReplyPlugin-string-2578-skip'),
          },
          property: 'datetime',
          type: QuickReplyType.datetime,
        }),
      },
    ],
  },
  [BlockShortcutId.textReminder]: {
    subtype: BLOCK_SUBTYPES.reminder,
    cards: [
      {
        plugin_id: PluginType.abandoned_cart,
        config: {
          ...PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG,
          reminder_type: AbandonedCartType.text,
        },
      },
    ],
  },
  [BlockShortcutId.galleryReminder]: {
    subtype: BLOCK_SUBTYPES.reminder,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          text: i18next.t(
            'shopify.galleryReminderDefaultText',
            PREVENT_INTERPOLATE,
          ),
        },
      },
      {
        plugin_id: PluginType.abandoned_cart,
        config: {
          ...PLUGIN_DEFAULT_FLOW_BUILDER_CONFIG,
          reminder_type: AbandonedCartType.gallery,
        },
      },
    ],
  },
  [BlockShortcutId.calendlyEventConfirmation]: {
    subtype: BLOCK_SUBTYPES.calendly_confirmation,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          text: `${i18next.t(
            'blocksShortcuts-Template--356-hi-first-name-you-scheduled-an-event-here-are-the-details',
            { skipInterpolation: true },
          )}${CalendlyAttributes.eventTime.toString()}
${CalendlyAttributes.eventDate.toString()}${i18next.t(
            'blocksShortcuts-Template--194-if-you-need-to-make-changes-to-it-choose-an-option-below',
          )}`,
          buttons: [
            {
              counter_id: null,
              title: i18next.t('blocksShortcuts-string--179-reschedule'),
              type: CardButtonType.calendly_reschedule,
              __typename: 'CardButton',
            },
            {
              counter_id: null,
              title: i18next.t('blocksShortcuts-string-2011-cancel'),
              type: CardButtonType.calendly_cancel,
              __typename: 'CardButton',
            },
          ],
        },
      },
    ],
  },
  [BlockShortcutId.calendlyEventReminder]: {
    subtype: BLOCK_SUBTYPES.calendly_confirmation,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          text: `${i18next.t(
            'blocksShortcuts-Template--356-hi-first-name-you-scheduled-an-event-here-are-the-details',
            { skipInterpolation: true },
          )}${CalendlyAttributes.eventTime.toString()}
${CalendlyAttributes.eventDate.toString()}`,
          buttons: [
            {
              counter_id: null,
              title: i18next.t('blocksShortcuts-string-2314-join'),
              type: CardButtonType.calendly_join_meeting,
              __typename: 'CardButton',
            },
            {
              counter_id: null,
              title: i18next.t('blocksShortcuts-string--179-reschedule'),
              type: CardButtonType.calendly_reschedule,
              __typename: 'CardButton',
            },
            {
              counter_id: null,
              title: i18next.t('blocksShortcuts-string-2011-cancel'),
              type: CardButtonType.calendly_cancel,
              __typename: 'CardButton',
            },
          ],
        },
      },
    ],
  },
  [BlockShortcutId.calendlyScheduleEvent]: {
    subtype: BLOCK_SUBTYPES.calendly,
    cards: [
      {
        plugin_id: PluginType.calendly,
        config: removeTypename(CALENDLY_DEFAULT_PLUGIN),
      },
    ],
  },
  [BlockShortcutId.shopifyDiscount]: {
    subtype: BLOCK_SUBTYPES.shopify_discount,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          text: i18next.t(
            'shopify.openShopifyStore.blockShortcut.text',
            PREVENT_INTERPOLATE,
          ),
          buttons: [
            {
              counter_id: null,
              title: i18next.t('shopify.openShopifyStore.blockShortcut.button'),
              type: CardButtonType.shopify_page,
              __typename: 'CardButton',
            },
          ],
        },
      },
    ],
  },
  [BlockShortcutId.receipt]: {
    subtype: BLOCK_SUBTYPES.receipt,
    cards: [
      {
        plugin_id: PluginType.comment,
        config: {
          text: i18next.t(
            'shopify.shopifyReceiptPrivateNote1',
            PREVENT_INTERPOLATE,
          ),
        },
      },
      {
        plugin_id: PluginType.text,
        config: {
          text: i18next.t('shopify.ThankYouForYourOrder', PREVENT_INTERPOLATE),
        },
      },
      {
        plugin_id: PluginType.order_receipt,
        config: removeTypename(ORDER_RECEIPT_PLUGIN_DEFAULT_CONFIG),
      },
    ],
  },
  [BlockShortcutId.postPurchase]: {
    subtype: BLOCK_SUBTYPES.postPurchase,
    cards: [
      {
        plugin_id: PluginType.comment,
        config: {
          text: i18next.t(
            'shopify.postPurchase.shortcut.comment1',
            PREVENT_INTERPOLATE,
          ),
        },
      },
      {
        plugin_id: getShortcutPluginDefaultData(
          PluginType.product_list_shopify_order_shortcut,
        ).pluginType as PluginType,
        config: removeNullFieldsDeep(
          getShortcutPluginDefaultData(
            PluginType.product_list_shopify_order_shortcut,
          ).config,
        ),
      },
      {
        plugin_id: PluginType.text,
        config: {
          text: i18next.t(
            'shopify.postPurchase.shortcut.text',
            PREVENT_INTERPOLATE,
          ),
          buttons: [
            {
              title: i18next.t('shopify.postPurchase.shortcut.buttonTitle2'),
              type: CardButtonType.shopify_order_list,
            },
          ],
        },
      },
      {
        plugin_id: PluginType.quick_reply,
        config: getQuickReplyConfig({
          buttons: [
            {
              title: i18next.t('shopify.postPurchase.shortcut.qr1'),
            },
            {
              title: i18next.t('shopify.postPurchase.shortcut.qr2'),
            },
          ],
          process_text_by_ai: false,
          skip_button: {
            title: i18next.t('shopify.postPurchase.shortcut.skip'),
          },
          property: '',
        }),
      },
    ],
  },
  [BlockShortcutId.productVisitReminder]: {
    subtype: BLOCK_SUBTYPES.visitedProductsReminder,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          text: i18next.t(
            'shopify.productVisitReminder.text',
            PREVENT_INTERPOLATE,
          ),
        },
      },
      {
        plugin_id: getShortcutPluginDefaultData(
          PluginType.product_list_product_visit_shortcut,
        ).pluginType as PluginType,
        config: removeNullFieldsDeep(
          getShortcutPluginDefaultData(
            PluginType.product_list_product_visit_shortcut,
          ).config,
        ),
      },
    ],
  },
  [BlockShortcutId.backInStockOptInConfirmation]: {
    subtype: BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
    cards: [
      {
        plugin_id: PluginType.product_list,
        config: {
          __typename: 'ProductListPluginConfig',
          list_type: ProductListType.back_in_stock,
          products: null,
          visited_products: null,
          order: null,
          back_in_stock: {
            __typename: 'ProductListBackInStockConfig',
            integration_type: ExternalIntegrationEntryPointType.shopify,
            description: i18next.t(
              'shopify.backInStock.optInConfirmation.productList.description',
            ),
            buttons: [],
          },
        } as productListPluginFragment_config,
      },
      {
        plugin_id: PluginType.otn_request,
        config: {
          title: i18next.t('shopify.backInStock.optInConfirmation.otn.title'),
          purpose: BACK_IN_STOCK_OTN_PURPOSE,
          purpose_hidden: true,
        },
      },
    ],
  },
  [BlockShortcutId.backInStockMessage]: {
    subtype: BLOCK_SUBTYPES.back_in_stock_message,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          text: i18next.t(
            'shopify.backInStock.messageBlock.text',
            PREVENT_INTERPOLATE,
          ),
          buttons: [],
        },
      },
      {
        plugin_id: PluginType.product_list,
        config: {
          __typename: 'ProductListPluginConfig',
          list_type: ProductListType.back_in_stock,
          products: null,
          visited_products: null,
          order: null,
          back_in_stock: {
            __typename: 'ProductListBackInStockConfig',
            integration_type: ExternalIntegrationEntryPointType.shopify,
            description: '',
            buttons: [
              {
                __typename: 'ProductListShopifyOrderButtonConfig',
                type: ProductListShopifyOrderButtonType.product_page,
                title: i18next.t(
                  'shopify.backInStock.messageBlock.productList',
                ),
              },
            ],
          },
        } as productListPluginFragment_config,
      },
      // {
      //   plugin_id: PluginType.quick_reply,
      //   config: {
      //     buttons: [
      //       {
      //         title: i18next.t('shopify.backInStock.messageBlock.quickReply'),
      //       },
      //     ],
      //     type: 'text',
      //   },
      // },
    ],
  },
  [BlockShortcutId.backInStockOTN]: {
    subtype: BLOCK_SUBTYPES.back_in_stock_otn,
    cards: [
      {
        plugin_id: PluginType.product_list,
        config: {
          __typename: 'ProductListPluginConfig',
          list_type: ProductListType.back_in_stock,
          products: null,
          visited_products: null,
          order: null,
          back_in_stock: {
            __typename: 'ProductListBackInStockConfig',
            integration_type: ExternalIntegrationEntryPointType.shopify,
            description: i18next.t(
              'shopify.backInStock.otnBlock.productList.description',
              PREVENT_INTERPOLATE,
            ),
            buttons: [
              {
                __typename: 'ProductListShopifyOrderButtonConfig',
                type: ProductListShopifyOrderButtonType.product_page,
                title: i18next.t(
                  'shopify.backInStock.otnBlock.productList.buttonTitle',
                ),
              },
            ],
          },
        } as productListPluginFragment_config,
      },
      // {
      //   plugin_id: PluginType.quick_reply,
      //   config: {
      //     buttons: [
      //       {
      //         title: i18next.t(
      //           'shopify.backInStock.otnBlock.quickReply.buttonTitle',
      //         ),
      //       },
      //     ],
      //     type: 'text',
      //   },
      // },
    ],
  },
  [BlockShortcutId.collectAnswers]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          buttons: [],
        },
      },
      getQuickReplyShortcut({
        type: QuickReplyType.text,
        error_messages: {
          text: i18next.t(
            'modernComponents.FlowBuilder.views.components.CollectUserData.answers.shortcut.errorMessage',
          ),
        },
      }),
    ],
  },
  [BlockShortcutId.collectEmails]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          buttons: [],
        },
      },
      getQuickReplyShortcut({
        type: QuickReplyType.email,
        property: 'email',
        error_messages: {
          email: i18next.t(
            'modernComponents.FlowBuilder.views.components.CollectUserData.emails.shortcut.errorMessage',
          ),
        },
      }),
    ],
  },
  [BlockShortcutId.collectPhones]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          buttons: [],
        },
      },
      getQuickReplyShortcut({
        type: QuickReplyType.phone,
        property: 'phone',
        error_messages: {
          phone: i18next.t(
            'modernComponents.FlowBuilder.views.components.CollectUserData.phones.shortcut.errorMessage',
          ),
        },
      }),
    ],
  },
  [BlockShortcutId.collectNumbers]: {
    subtype: BLOCK_SUBTYPES.send_message,
    cards: [
      {
        plugin_id: PluginType.text,
        config: {
          buttons: [],
        },
      },
      getQuickReplyShortcut({
        type: QuickReplyType.number,
        error_messages: {
          number: i18next.t(
            'modernComponents.FlowBuilder.views.components.CollectUserData.numbers.shortcut.errorMessage',
          ),
        },
      }),
    ],
  },
  [BlockShortcutId.commentsReply]: {
    subtype: BLOCK_SUBTYPES.comments_autoreply_content,
    cards: commentsReplyCards,
  },
  [BlockShortcutId.instagramCommentsReply]: {
    subtype: BLOCK_SUBTYPES.instagram_comments_autoreply_content,
    cards: instagramCommentsReplyCards,
  },
  [BlockShortcutId.whatsappDirect]: {
    subtype: BLOCK_SUBTYPES.entrypoint,
    cards: [
      {
        plugin_id: PluginType.comment,
        config: {
          text: i18next.t(
            'modernComponents.FlowBuilder.views.entryPoints.WhatsappDirect.shortcut.newUsers',
          ),
        },
      },
      {
        plugin_id: PluginType.comment,
        config: {
          text: i18next.t(
            'modernComponents.FlowBuilder.views.entryPoints.WhatsappDirect.shortcut.flow',
          ),
        },
      },
      {
        plugin_id: PluginType.whatsapp_direct_entry_point,
        config: WHATSAPP_DIRECT_DEFAULT_CONFIG,
      },
    ],
  },
}));

export const getBlockShortcut = (shortcutId: BlockShortcutId) =>
  prepareBlockShortcuts()[shortcutId];
