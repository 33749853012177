/* eslint-disable import/no-webpack-loader-syntax */
import i18next from 'i18next';
import { customerChatEntryPointFragment_config as CustomerChatEntryPointConfig } from '@components/Plugins/CustomerChatEntryPoint/@types/customerChatEntryPointFragment';
import { log } from 'cf-common/src/logger';
import { ServiceMessageType, toaster } from '@services/MessageService';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Node } from '../../../Node';
import { MainLayout } from '../../../components/Elements/Layouts';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../Plugins/CustomerChatEntryPoint/CustomerChatEntryPointConst';
import { simpleMultiDomainsValidate } from '../../../../DomainsWhitelisting';
import { prepareCustomerChatStats } from '../common/components/CustomerChatView/utils';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { compareFromDefaultConfig } from '../../validation';
import { validateBlockLeadInEntryPoint } from '../common/utils/validateBlockLeadInEntryPoint';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { logFlowEvent } from '../../../utils/Analytics';
import { getI18nCustomerChatEntryPointKey } from './utils/getI18nCustomerChatEntryPointKey';
import { CustomerChatView } from '../common/components/CustomerChatView';

export class EntryPointCustomerChat
  extends MainLayout
  implements StatefulPluginDelegate<CustomerChatEntryPointConfig>
{
  TEST_NAME = 'EntryPointCustomerChat';

  private readonly _node: Node;
  private readonly customerChatView: CustomerChatView;

  public readonly state: StatefulPlugin<CustomerChatEntryPointConfig>;

  constructor(state: StatefulPlugin<CustomerChatEntryPointConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 12,
        strokeWidth: 0,
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.on('click', () => {
      logFlowEvent('entry_point', 'open customer chat settings');
      getPixiFieldStrict().fixBlockPosition(node.blockView, getPanelWidth());
      editPluginInEditorPanel(node.id, this.state.data, state);
    });

    this.customerChatView = new CustomerChatView({
      node,
      getData: () => ({
        config: this.state.data.config,
        stats: prepareCustomerChatStats(
          this.state.data.id,
          this._node.block.id,
        ),
      }),
      onChange: (updatedConfig) => {
        this.state.set(({ config }) => ({
          config: {
            ...config,
            ...updatedConfig,
          },
        }));
        this.state.save();
      },
    });

    this.addToLayout(this.customerChatView, {
      margin: { left: 1, top: 16, bottom: 16 },
    });
  }

  pluginDidSaveError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          getI18nCustomerChatEntryPointKey('failedToSaveEntryPointMessageText'),
        ),
      },
    });
    log.error({
      error,
      msg: 'Error while saving Customer Chat Entry Point',
      data: { label: 'flow_customer_chat' },
    });
  }

  pluginDidSet() {
    this.renderNode();
  }

  hasSignificantChangesInConfig() {
    return !compareFromDefaultConfig(
      this.state.data.config,
      PLUGIN_DEFAULT_CONFIG,
    );
  }

  validationError() {
    return (
      validateIsPageConnecting(this._node) ||
      validateBlockLeadInEntryPoint(this.state.data.config) ||
      (simpleMultiDomainsValidate(this.state.data.config.domains)
        ? undefined
        : {
            message:
              'To enable this Entry Point, specify the website domain where you’ll be using it.<br/><br/><span style="text-decoration: underline;">Specify domain</span>',
            onClick: (e: Event) => {
              this.emit('click', e);
            },
          })
    );
  }

  destroy() {
    super.destroy();
    this.state.destroy();
  }
}
