import { HEXColors } from '@ui/_common/colors';
import { logFlowPluginEvent } from '../../utils/Analytics';
import { VLayout } from '../../components/Elements/Layouts';
import { Node } from '../../Node';
import { Card } from '../../types';
import { TextEditView } from '../text_edit_view';
import { ControlVisibility } from '../../FlowBuilderOverlay';
import {
  commentBackgroundColor,
  mainStrokeColor,
  pluginWidth,
} from '../plugin_consts';
import { PluginTitleLayout } from '../components/PluginTitleLayout';
import { pluginCommentTexture } from '../../assets/textures';
import { tooltips } from '../Menu/menu_view';

export class CommentPluginView extends VLayout {
  TEST_NAME = 'CommentPluginView';

  _node?: Node;

  _card?: Card;

  textEditView?: TextEditView;

  constructor(card: Card, node: Node) {
    super({
      title: `${window.i18next.t(
        'CommentPlugin-Template--145-comment-plugin-view',
      )}${card.config.text}`,
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: commentBackgroundColor,
      },
    });

    this._node = node;
    this._card = card;

    const titleLayout = new PluginTitleLayout(
      false,
      window.i18next.t('CommentPlugin-string-1106-private-note'),
      pluginCommentTexture,
      card,
      { text: tooltips().comment.html },
      { background: { fill: commentBackgroundColor, cornerRadius: 4 } },
    );
    this.layout(titleLayout, {
      margin: { top: 15, bottom: 5, left: 10 },
    });

    this.textEditView = new TextEditView(
      {
        maxLength: 640,
        fontSize: 15,
        fill: HEXColors.black,
        text: card.config.text,
        caption: window.i18next.t(
          'CommentPlugin-string--159-add-a-private-comment-or-note',
        ),
        captionFill: HEXColors.greyDark,
        enterForNewLine: false,
        align: 'left',
        editable: () => card.isEditing,
        width: pluginWidth - 30,
        margin: { left: 8, top: 8, bottom: 8, right: 8 },
        background: {
          fill: (v) =>
            this._card?.isEditing && v?.isEditing()
              ? HEXColors.white
              : commentBackgroundColor,
          cornerRadius: 4,
          strokeWidth: (v) => (card.isEditing && v?.isEditing() ? 2 : 0),
          stroke: (v) => (v?._textAreaShown ? mainStrokeColor : HEXColors.grey),
        },
      },
      undefined,
      undefined,
      () => {
        const text = this.textEditView?.text().trim();
        if (this._card && this._card?.config.text !== text) {
          this._card.config.text = text;
          node.updateCard(card);
          logFlowPluginEvent('comment', 'update text', this.propertyBag());
        }
        this.renderNode();
      },
      {
        emoji: ControlVisibility.show,
        links: ControlVisibility.show,
      },
    );

    this.addToLayout(this.textEditView, {
      margin: { left: 15, bottom: 15 },
    });
  }

  propertyBag() {
    return { blockId: this._node?.id, cardId: this._card?.id };
  }

  onBeforeRender() {
    const text = this._card?.config.text ?? '';
    this.textEditView?.text(text);
  }

  focus() {
    this.textEditView?.showTextArea();
  }

  onEditStart(e: any) {
    this.textEditView?.detectLinkClick(e);
  }
}
