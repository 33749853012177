import { Layout } from '../../components/Elements/Layouts/Layout';
import { View } from '../../components/Elements/Layouts/types';
import { BlockView } from '../block_view';
import { PublicReplyCard } from '../plugins/PublicReplyPlugin/PublicReplyPlugin';
import { EntryPointFacebookAdsCard } from '../entry-points/EntryPointFacebookAds/EntryPointFacebookAds';
import { tooltip } from './TooltipHelpers';
import { SingleView, ViewPosition } from '../SingleView';
import { getPixiFieldStrict, getPixiField } from '../../PixiFieldRepository';
import { moveArrayElement, removeArrayElement } from '../utils';
import { Node } from '../../Node';
import { Card, PropFunc } from '../../types';
import { EntryPointInstagramAds } from '../entry-points/EntryPointInstagramAds';

export function cardControl(card?: View, blockView?: BlockView): SingleView {
  if (card && blockView && !getPixiFieldStrict().isViewOnly()) {
    getPixiFieldStrict().cardControllerView.subscribe({
      view: card,
      onShow: () => {
        getPixiFieldStrict().cardControlView.setBlockAndCard(blockView, card);
        return getPixiFieldStrict().cardControlView;
      },
      positionFunction: () => {
        return { x: -40, y: 0 };
      },
    });
  }
  return getPixiFieldStrict().cardControllerView;
}

export function galleryCardControl(
  galleryCard?: View,
  card?: View,
): SingleView {
  if (galleryCard && !getPixiFieldStrict().isViewOnly()) {
    getPixiFieldStrict().galleryCardControllerView.subscribe({
      view: galleryCard,
      onShow: () => {
        getPixiFieldStrict().galleryCardControlView.setGalleryCard(
          galleryCard,
          card,
        );
        return getPixiFieldStrict().galleryCardControlView;
      },
      positionFunction: () => ({
        x:
          (galleryCard.width() / 2) * getPixiFieldStrict().viewport.scale.x -
          getPixiFieldStrict().galleryCardControlView.width() / 2,
        y: -40,
      }),
    });
  }
  return getPixiFieldStrict().galleryCardControllerView;
}

export function buttonControl(
  button?: Layout,
  layout?: Layout,
  onMove?: () => void,
  onRemove?: () => void,
  onPositionChanged?: (button: View, startIdx: number, idx: number) => void,
  positionFunction?: (button: View) => ViewPosition,
  hideRemoveButton?: PropFunc<boolean>,
  notRemoveOneItem?: boolean,
) {
  if (button && !getPixiFieldStrict().isViewOnly()) {
    getPixiFieldStrict().cardControllerView.subscribe({
      view: button,
      onShow: () => {
        if (notRemoveOneItem && layout && layout.size() < 2) {
          return undefined;
        }
        getPixiFieldStrict().buttonControlView.setButton(
          button,
          layout,
          onMove,
          onRemove,
          onPositionChanged,
          hideRemoveButton,
        );
        return getPixiFieldStrict().buttonControlView;
      },
      positionFunction: () => {
        return positionFunction
          ? positionFunction(button)
          : {
              x: -40,
              y:
                (button.height() * getPixiFieldStrict().viewport.scale.y -
                  getPixiFieldStrict().buttonControlView.height()) *
                0.5,
            };
      },
    });
  }
  return getPixiFieldStrict().cardControllerView;
}

export function removeButtonControl(button: View) {
  getPixiFieldStrict().cardControllerView.unsubscribe(button);
}

export const addButtonControl = (
  itemView: Layout,
  itemsBoxView: Layout,
  itemsConfigs: unknown[],
  node: Node,
  card:
    | Card
    | EntryPointFacebookAdsCard
    | EntryPointInstagramAds
    | PublicReplyCard,
  positionFunction?: (button: View) => ViewPosition,
  hideRemoveButton?: boolean,
  notRemoveOneItem?: boolean,
  onRemove?: (configToRemove: any) => void,
  onPositionChanged?: (button: View, startIdx: number, idx: number) => void,
  updateCardViaDeprecatedMethod = true,
): void => {
  buttonControl(
    itemView,
    itemsBoxView,
    () => node.updateLines(),
    () => {
      // for remove after save on blur
      setTimeout(() => {
        const configToRemove =
          (itemView as any)._config || (itemView as any).config;
        if (updateCardViaDeprecatedMethod) {
          removeArrayElement(itemsConfigs, configToRemove);
          itemsBoxView.removeView(itemView);
          itemView.destroy();
          onRemove?.(configToRemove);
          node.updateCard(card);
        } else {
          onRemove?.(itemView);
          itemsBoxView.removeView(itemView);
          itemView.destroy();
        }
        node.updateLines();
      });
    },
    (button, startIdx, idx) => {
      if (updateCardViaDeprecatedMethod) {
        moveArrayElement(itemsConfigs, startIdx, idx);
        onPositionChanged?.(button, startIdx, idx);
        node.updateCard(card);
      } else {
        onPositionChanged?.(button, startIdx, idx);
      }
    },
    positionFunction,
    hideRemoveButton,
    notRemoveOneItem,
  );
};

export function blockControl(block?: View): SingleView {
  if (block && !getPixiFieldStrict().isViewOnly()) {
    getPixiFieldStrict().cardControllerView.subscribe({
      view: block,
      onShow: () => {
        const pixiFiel = getPixiField();
        if (!pixiFiel) {
          return undefined;
        }
        pixiFiel.blockControlView.setView(block);
        return pixiFiel.blockControlView;
      },
      onHide: () => {
        getPixiFieldStrict().blockControlView.onHide(block);
      },
      positionFunction: () => {
        return {
          x:
            (block.width() * getPixiFieldStrict().viewport.scale.x -
              getPixiFieldStrict().blockControlView.width()) /
            2,
          y: -getPixiFieldStrict().blockControlView.height() - 5,
        };
      },
    });
  }
  return getPixiFieldStrict().cardControllerView;
}

export function updateControls(): void {
  try {
    cardControl().updatePosition();
    galleryCardControl().updatePosition();
    blockControl().updatePosition();
    buttonControl().updatePosition();
    tooltip()?.updatePosition();
  } catch (e) {
    console.error(e);
  }
}

export function moveControlsToTop() {
  try {
    cardControl().moveToTop();
    galleryCardControl().moveToTop();
    blockControl().moveToTop();
    buttonControl().moveToTop();
  } catch (e) {
    console.error(e);
  }
}

export function hideControls(): void {
  try {
    cardControl().hide();
    galleryCardControl().hide();
    blockControl().hide();
    buttonControl().hide();
    getPixiFieldStrict().tooltipView.hide();
  } catch (e) {
    console.error(e);
  }
}
