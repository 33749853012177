import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { clone } from 'ramda';
import { stripTags } from '@utils/stripTags';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import { AdCampaignType } from '@globals';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { facebookAdsEntryPointFragment_config_campaigns_ads } from '@components/Plugins/FacebookAdsEntryPoint/@types/facebookAdsEntryPointFragment';
import { FacebookAdsEntryPointConfig } from '@components/Plugins/FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import { InstagramAdsEntryPointConfig } from '@components/Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { instagramAdsEntryPointConfigFragment_campaigns_ads } from '@components/Plugins/InstagramAdsEntryPoint/@types/instagramAdsEntryPointConfigFragment';
import { ISetPluginStateParams } from '@components/Plugins/common';
import { StatefulPlugin } from '../../../../../StatefulPlugin';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import {
  removeTooltip,
  tooltipScaled,
} from '../../../../helpers/TooltipHelpers';
import {
  HLayout,
  LayoutProps,
  MainLayout,
} from '../../../../../components/Elements/Layouts';
import {
  HTMLText,
  Image,
  TextureShape,
} from '../../../../../components/Elements/Shapes';
import { Node } from '../../../../../Node';
import { EntryPointFacebookAds } from '../../../EntryPointFacebookAds';
import emptyPictureUrl from '../../images/empty-picture.svg';
import {
  loaderSvgTexture,
  restartSvgTexture,
} from '../../../../../assets/textures';
import { getEntryPointCardView } from '../../utils';
import { Loader } from '../../../../loader';
import { pluginWidth } from '../../../../plugin_consts';
import { pollForAdsCardUploadStatus } from '../../../EntryPointFacebookAds/utils/adsCardUploadStatus';
import { EntryPointInstagramAds } from '../../../EntryPointInstagramAds';
import { HEXColors } from '@ui/_common/colors';

interface AdItemProps extends LayoutProps {
  config:
    | facebookAdsEntryPointFragment_config_campaigns_ads
    | instagramAdsEntryPointConfigFragment_campaigns_ads;
}
interface AdItemViewProps extends AdItemProps, LayoutProps {
  state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;
  node: Node;
  adParams: {
    index: number;
    campaignIndex: number;
    error: string;
  };
}

export class AdItemView extends HLayout {
  config:
    | facebookAdsEntryPointFragment_config_campaigns_ads
    | instagramAdsEntryPointConfigFragment_campaigns_ads;
  node: Node;
  state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;
  stringRow: MainLayout;
  imageFrame: MainLayout;
  error: string;
  _loader: Loader;
  refreshButton: MainLayout;
  refreshAdClick: () => void;
  syncing: boolean;

  constructor({
    config,
    node,
    state,
    adParams,
    ...layoutProps
  }: AdItemViewProps) {
    super({
      height: 32,
      background: {
        fill: HEXColors.greyLight20,
      },
      ...layoutProps,
    });
    this.node = node;
    this.state = state;
    this.config =
      state.data.config.campaigns[adParams.campaignIndex as number]?.ads?.[
        adParams.index as number
      ]!;
    this.error = adParams.error || '';
    this.imageFrame = new MainLayout({});
    this.imageFrame.alpha = !config.last_synced_date || this.error ? 0.5 : 1;
    this.syncing = false;
    this._loader = new Loader(
      {
        width: 16,
        height: 16,
        background: {
          fill: HEXColors.greyLight20,
        },
        loaderSize: 16,
      },
      loaderSvgTexture,
    );

    this.refreshAdClick = () => {
      const cardView = getEntryPointCardView(this.node.blockView) as
        | EntryPointFacebookAds
        | EntryPointInstagramAds;
      this.state.set(({ config }) => {
        // eslint-disable-next-line no-param-reassign
        config.campaigns[adParams.campaignIndex as number].ads![
          adParams.index as number
        ]!.sync = true;
        const newConfig = clone(config);

        return { newConfig } as
          | ISetPluginStateParams<InstagramAdsEntryPointConfig>
          | ISetPluginStateParams<FacebookAdsEntryPointConfig>;
      });
      this.syncing = true;
      removeTooltip(this.stringRow);
      this._loader.start();

      logFlowPluginEvent(this.state.data.plugin_id, 'Click on refresh ad', {
        blockId: this.node.id,
        cardId: this.state.data?.id,
      });

      this.state
        .save()
        .then(() => {
          pollForAdsCardUploadStatus(this.state.data.id)
            .promise.then((res) => {
              cardView.syncResult =
                res.ads?.reduce((acc, ad) => {
                  acc[ad.id] = ad;
                  return acc;
                }, {} as any) ?? {};
            })
            .catch((error) => {
              toaster.show({
                type: ServiceMessageType.error,
                payload: {
                  message: window.i18next.t(
                    'AdItem-string--828-something-went-wrong-please-try-again',
                  ),
                },
              });
              log.error({
                error,
                msg: 'Error with FB ads',
                data: {
                  label: 'flow_fb_ads',
                  requestId: getRequestIdFromApolloError(error),
                },
              });
            })
            .finally(() => {
              this.syncing = true;
              this._loader.stop();
              cardView?.adsSelector.createCampaignList();
              cardView?.renderNode();
            });

          cardView?.renderNode();
        })
        .catch(() => {
          this.syncing = true;
          this._loader.stop();
          cardView?.renderNode();
        });
      this.renderNode();
    };

    const ad = new HLayout({
      height: 32,
      cursor: {
        in: 'default',
      },
    });
    ad.alpha = !config.last_synced_date || this.error ? 0.5 : 1;

    this.imageFrame.addToLayout(
      new Image({
        url: config.preview_image_small || emptyPictureUrl,
        cornerRadius: 4,
        width: 32,
        height: 32,
      }),
    );

    if (config.type === AdCampaignType.click_to_messenger) {
      this.imageFrame.addToLayout(
        new MainLayout({
          height: 13,
          width: 29,
          background: {
            fill: HEXColors.blue,
            cornerRadius: 4,
          },
        }).addToLayout(
          new HTMLText({
            text: 'CTM',
            fontSize: 10,
            fontStyle: 'semibold',
            fill: HEXColors.white,
          }),
          {
            margin: {
              left: 3,
              right: 3,
              top: -4,
            },
          },
        ),
        {
          margin: {
            left: 7,
            top: 25,
            right: 0,
          },
        },
      );
    } else if (config.type === AdCampaignType.sponsored_message) {
      this.imageFrame.addToLayout(
        new MainLayout({
          height: 13,
          width: 22,
          background: {
            fill: HEXColors.green,
            cornerRadius: 4,
          },
        }).addToLayout(
          new HTMLText({
            text: 'SM',
            fontSize: 10,
            fontStyle: 'semibold',
            fill: HEXColors.white,
          }),
          {
            margin: {
              left: 3,
              right: 3,
              top: -4,
            },
          },
        ),
        {
          margin: {
            left: 14,
            top: 25,
            right: 0,
          },
        },
      );
    }

    ad.addToLayout(
      new HTMLText({
        text: `<span style="max-width: 200px; width: 200px; display: block; text-overflow: ellipsis; overflow: hidden;">${removeExtraSpaces(
          stripTags(
            config.name || window.i18next.t('AdItem-string--578-no-text'),
          ),
          ' ',
        )}</span>`,
        fontSize: 15,
        fill: HEXColors.black,
      }),
      {
        margin: { top: 5, left: 8 },
      },
    );

    this.stringRow = new MainLayout({});
    this.stringRow.addToLayout(this.imageFrame);
    this.stringRow.addToLayout(ad, {
      margin: {
        left: 34,
      },
    });

    this.refreshButton = new MainLayout({}).addToLayout(
      new TextureShape({
        texture: restartSvgTexture,
        width: 24,
        height: 24,
      }),
    );
    this.refreshButton.on('click', this.refreshAdClick);

    this.stringRow.addToLayout(this.refreshButton, {
      margin: {
        top: 3,
        left: pluginWidth - 56,
      },
      gone: () => this.syncing || !this.error,
    });
    this.stringRow.addToLayout(this._loader, {
      margin: {
        top: 7,
        left: pluginWidth - 52,
      },
      gone: () => !this.syncing,
    });
    this.addToLayout(this.stringRow);
  }

  onBeforeRender() {
    if (this.error) {
      tooltipScaled({
        view: this.stringRow,
        text: this.error,
        overTimeout: 200,
      });
    } else {
      removeTooltip(this.stringRow);
    }
  }

  destroy() {
    this._loader.stop();
    this.refreshButton.off('click', this.refreshAdClick);
    removeTooltip(this.stringRow);
    super.destroy();
  }
}
