import { getPixiFieldStrict } from '../../PixiFieldRepository';
import { Node } from '../../Node';
import { blockWidth } from '../../views/plugin_consts';
import { FIT_ANIMATION_TIME } from '../../consts';

// the return value indicates successful finish of fit animation
function fitRectInScreen(
  minX: number,
  minY: number,
  maxX: number,
  maxY: number,
  animationDuration: number = FIT_ANIMATION_TIME,
) {
  const {
    screen,
    viewport,
    app: { ticker },
  } = getPixiFieldStrict();
  const newWidth = maxX - minX + 200;
  const newHeight = maxY - minY + 200;

  const newScale = Math.min(
    1,
    screen.width / newWidth,
    screen.height / newHeight,
  );

  const newX = (maxX + minX) / 2;
  const newY = (maxY + minY) / 2;
  const newCenter = { x: newX, y: newY };

  return new Promise<boolean>((resolve) => {
    viewport.emit('moved');
    viewport.animate({
      position: newCenter,
      scale: newScale,
      time: animationDuration,
      width: newWidth,
      removeOnInterrupt: true,
      ease: 'easeOutQuad',
      callbackOnComplete: () => {
        viewport.emit('moved-end');
        viewport.emit('zoomed');
        viewport.emit('zoomed-end');
        resolve(true);
      },
    });
    ticker.update();

    // resolve promise for conflicted animations case
    // in this case callbackOnComplete is not called
    setTimeout(() => {
      viewport.emit('moved-end');
      viewport.emit('zoomed');
      viewport.emit('zoomed-end');
      resolve(false);
    }, FIT_ANIMATION_TIME * 2);
  });
}

interface Bounds {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

function getBounds(
  nodeList: Node[],
  xGetter = (n: Node) => n.x,
  yGetter = (n: Node) => n.y,
): Bounds {
  let minX: number = Infinity;
  let minY: number = Infinity;
  let maxX: number = -Infinity;
  let maxY: number = -Infinity;

  nodeList
    .filter((n) => n.blockView.visible)
    .forEach((n) => {
      const x = xGetter(n);
      const y = yGetter(n);

      minX = Math.min(x, minX);
      minY = Math.min(y, minY);
      maxX = Math.max(x + n.blockView.getLocalBounds().width, maxX);
      maxY = Math.max(y + n.blockView.getLocalBounds().height, maxY);
    });

  return { minX, minY, maxX, maxY };
}

interface FitScreenOptions {
  xGetter?: (n: Node) => number;
  yGetter?: (n: Node) => number;
  duration?: number;
}

const DEFAULT_FIT_SCREEN_OPTIONS: Required<FitScreenOptions> = {
  xGetter: (n: Node) => n.x,
  yGetter: (n: Node) => n.y,
  duration: FIT_ANIMATION_TIME,
};

export function fitScreen(
  nodeList: Node[],
  options: FitScreenOptions = {},
): Promise<boolean> {
  if (nodeList.length === 0) {
    return Promise.resolve(true);
  }

  const { xGetter, yGetter, duration } = {
    ...DEFAULT_FIT_SCREEN_OPTIONS,
    ...options,
  };
  const { minX, minY, maxX, maxY } = getBounds(nodeList, xGetter, yGetter);
  return fitRectInScreen(minX, minY, maxX, maxY, duration);
}

const IN_BLOCK_ELEMENT_MARGIN = 240;

export function fitBlockPartInScreen(
  blockNode: Node,
  topOffset: number = 0,
  height: number = 0,
) {
  return fitRectInScreen(
    blockNode.x,
    blockNode.y + topOffset,
    blockNode.x + blockWidth,
    blockNode.y +
      (height
        ? topOffset + height + IN_BLOCK_ELEMENT_MARGIN
        : blockNode.blockView.height()),
  );
}
