import { useEffect } from 'react';
import * as css from './useFixBodyOverscroll.css';

export const useFixBodyOverscroll = () => {
  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (!bodyClassList.contains(css.fixOverscroll)) {
      bodyClassList.add(css.fixOverscroll);
    }
    return () => {
      bodyClassList.remove(css.fixOverscroll);
    };
  });
};
