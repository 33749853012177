import copyToClipboard from 'clipboard-copy';
import i18next from 'i18next';
import { Subscription } from 'apollo-client/util/Observable';

import { toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';

import { extractGQLErrorData } from '@utils/GQL/utils';

import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { instagramLinkEntryPointFragment_config as InstagramLinkEntryPointConfig } from '../../../../Plugins/InstagramLinkEntryPoint/@types/instagramLinkEntryPointFragment';

import { logFlowPluginEvent } from '../../../utils/Analytics';
import { MainLayout, VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Node } from '../../../Node';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import { resolveEntryPointConflict } from '../common/resolveEntryPointConflict';

import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { createLineMenu } from '../../Menu/createLineMenu';
import { ButtonView } from '../../button_view';
import { isEmptyString } from '../../validation';
import { removeTooltip } from '../../helpers/TooltipHelpers';
import { SetAttributeView } from '../../components/SetAttributeView';
import { LineStartView } from '../../components/Line/LineStartView';

import { validateBlockLeadInEntryPoint } from '../common/utils/validateBlockLeadInEntryPoint';
import { getPixiField } from '../../../PixiFieldRepository';
import { HEXColors } from '@ui/_common/colors';
import { validateIsInstagramPageConnected } from '../common/utils/validateIsInstagramPageConnected';
import { getConnectedInstagramObservable } from '@utils/Data/Instagram/BotAccount';
import { BotLink } from '../common/BotLink';

class InstagramBotLink extends BotLink {
  constructor() {
    super({ url: 'https://ig.me/m/', parameter: 'ref' });
  }
}

export class EntryPointInstagramLink
  extends MainLayout
  implements StatefulPluginDelegate<InstagramLinkEntryPointConfig>
{
  TEST_NAME = 'EntryPointInstagramLink';

  public readonly state: StatefulPlugin<InstagramLinkEntryPointConfig>;

  private readonly _node: Node;

  private readonly refView: SetAttributeView;

  private readonly lineStartView: LineStartView;

  private readonly buttonView: ButtonView;

  private readonly buttonBox: VLayout;

  private instagramHandle: string = '';

  private readonly botLink: InstagramBotLink;

  private connectedInstagramObservable: Subscription | undefined;

  constructor(
    state: StatefulPlugin<InstagramLinkEntryPointConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const { title } = node.controller.flow;

    if (!this.state.data?.config?.referral) {
      this.state.set(({ config }) => ({
        config: { ...config, referral: title },
      }));
    }

    this.botLink = new InstagramBotLink();

    this.refView = new SetAttributeView(
      this.state.data,
      {
        caption: i18next.t(
          'modernComponents.FlowBuilder.views.components.EntryPointBotLink.setBotLinkUrl',
        ),
        width: pluginWidth - 30,
        height: 0,
        textDecoration: 'underline',
        singleLine: false,
        maxLength: 256,
        maskPrefixLength: this.getBaseUrl().toString().length,
        margin: {
          left: 10,
          top: 8,
          bottom: 8,
          right: 10,
        },
        text: this.getRefUrlString(this.getBotUrl()),
        isValid: () => !!this.state.data?.config?.referral,
        isEditing: () => this.state.isEditing,
      },
      undefined,
      async (newLink) => {
        this.refView.text(newLink);
        const newReferral = new URL(newLink).searchParams.get('ref') ?? '';
        this.updateReferral(newReferral, () => {
          logFlowPluginEvent(
            PluginType.instagram_bot_link_entry_point,
            'update referral',
            {
              blockId: this._node.id,
              cardId: this.state.data?.id,
              referral: newLink,
            },
          );
          this.renderNode();
        });

        try {
          await this.state.save();
        } catch (error) {
          const extractedGQLErrorData = extractGQLErrorData(error as any);
          const isConflictError = extractedGQLErrorData?.status === 409;
          if (isConflictError && extractedGQLErrorData?.conflictingData) {
            const toggleView = node.blockView._toggle;
            if (!toggleView) {
              return;
            }
            this.state.set({ enabled: false });
            toggleView.setValue(false);
            toggleView.renderNode();

            resolveEntryPointConflict(
              this._node,
              extractedGQLErrorData?.conflictingData,
              this.state.data,
            );
          }
        }
      },
      undefined,
      {
        emoji: ControlVisibility.hide,
        attributes: ControlVisibility.hide,
      },
      undefined,
    );

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: true,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: (blockView) => {
          this.state.set(({ config }) => ({
            config: { ...config, block_id: blockView.id() },
          }));
        },
        onRemoved: () => {
          this.state.set(({ config }) => ({
            config: { ...config, block_id: null },
          }));
        },
      },
      this.state.data?.id,
    );

    const configLayout = new VLayout();
    this.layout(configLayout);
    configLayout.layout(this.refView, {
      margin: { left: 15, top: 15, bottom: 0 },
    });

    this.buttonView = new ButtonView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.components.EntryPointBotLink.copyUrl',
      ),
      bgColor: HEXColors.white,
      onClick: (e: any) => {
        e.stopPropagation();
        copyToClipboard(this.getRefUrlString(this.getBotUrl(true)));
        toaster.show({
          type: ServiceMessageType.default,
          timeout: 1000,
          payload: {
            message: i18next.t(
              'modernComponents.FlowBuilder.views.components.EntryPointBotLink.urlCopied',
            ),
          },
        });
        logFlowPluginEvent(
          PluginType.instagram_bot_link_entry_point,
          'copy link',
          {
            blockId: this._node.id,
            cardId: this.state.data?.id,
            referral: this.state.data?.config?.referral,
          },
        );
      },
    });
    this.buttonBox = new VLayout({
      width: pluginWidth - 16,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });
    this.buttonBox.layout(this.buttonView, {
      margin: { left: 15, top: 10, bottom: 15 },
    });

    if (!getPixiField()?.isViewOnly()) {
      this.connectedInstagramObservable = getConnectedInstagramObservable(
        this._node.controller.flow.botId,
      ).subscribe(({ isConnected, instagramHandle }) => {
        this.instagramHandle = instagramHandle ?? '';
        this.handleInstagramConnected(isConnected, instagramHandle);
      });
    }

    configLayout.layout(this.buttonBox);
    this.layout(this.lineStartView, {
      margin: { left: -9, top: -25 },
      align: 'end',
      verticalAlign: 'center',
    });
  }

  updateReferral(newReferral: string, onChange?: () => void) {
    if (this.state.data?.config?.referral !== newReferral) {
      this.state.set(({ config }) => ({
        config: {
          ...config,
          referral: newReferral,
        },
      }));
      onChange?.();
    }
  }

  handleInstagramConnected(
    isConnected: boolean,
    instagramHandle?: string | null,
  ) {
    if (!isConnected) return;
    const newUrl = this.getBotUrl(false, instagramHandle);
    this.updateReferral(newUrl.searchParams.get('ref') ?? '');
    this.refView.text(this.getRefUrlString(newUrl));

    const baseUrl = this.getBaseUrl(instagramHandle);
    this.refView._props.maskPrefixLength = baseUrl.toString().length;

    this.buttonView.setDisabled(false);
    removeTooltip(this.buttonBox);

    this.renderNode();
  }

  getBaseUrl(instagramHandle?: string | null) {
    return this.botLink.getBaseUrl(instagramHandle ?? this.instagramHandle);
  }

  getBotUrl(encode = false, instagramHandle?: string | null) {
    const referral =
      this.state.data?.config?.referral ?? this._node.controller.flow.title;

    return this.botLink.getBotUrl(
      referral,
      instagramHandle ?? this.instagramHandle,
      { encode },
    );
  }

  getRefUrlString(url: URL) {
    return this.botLink.getUrlString(url);
  }

  onBeforeRender() {
    if (this.state.data?.config?.block_id) {
      this._node.addOutLink(
        this.state.data?.config?.block_id,
        this.lineStartView._lineView,
      );
    }
    this.refView._textNode.textDecoration(
      this.state.isEditing || !this.state.data?.config?.referral
        ? 'none'
        : 'underline',
    );
  }

  hasSignificantChangesInConfig() {
    const { title } = this._node.controller.flow;
    return (
      !!this.state.data?.config?.referral.trim() &&
      this.state.data?.config?.referral.trim() !== title
    );
  }

  validationError() {
    return (
      validateIsInstagramPageConnected(this._node) ||
      validateBlockLeadInEntryPoint({
        block_id: this.state.data?.config?.block_id ?? undefined,
      }) ||
      (isEmptyString(this.state.data?.config?.referral)
        ? {
            message: i18next.t(
              'modernComponents.FlowBuilder.views.components.EntryPointBotLink.addBotLink',
            ),
          }
        : undefined)
    );
  }

  pluginDidSet() {
    if (this.state.data.isEditing) {
      return;
    }
    this.state.save();
  }

  pluginDidSave() {
    this.renderNode();
  }

  destroy() {
    this.connectedInstagramObservable?.unsubscribe();
    this.state.destroy();
    super.destroy();
  }
}
