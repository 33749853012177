import { ExternalAccountsData } from '@utils/Integrations/common/ExternalAccount';
import {
  FlowBuilderOverlayEvent,
  overlayEventEmitter,
  OverlayType,
} from '../../../../FlowBuilderOverlay';
import { Point } from '../../../../types';

interface ShowExternalAccountOverlayProps {
  externalAccountsData: ExternalAccountsData;
  point: Point;
  onDisconnectRequest: () => void;
}

export const showExternalAccountOverlay = ({
  externalAccountsData,
  point: { x, y },
  onDisconnectRequest,
}: ShowExternalAccountOverlayProps) => {
  overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
    overlayType: OverlayType.accountSelector,
    getOverlayOptions: () => ({
      externalAccountsData,
      onDisconnectRequest,
    }),
  });
  overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
    position: {
      x: Math.round(x + 1), // prevent mouseup event on overlay
      y: Math.round(y),
    },
  });
  overlayEventEmitter.emit(FlowBuilderOverlayEvent.show);

  const handleOutsideClick = () => {
    window.removeEventListener('mousedown', handleOutsideClick);
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
  };

  setTimeout(() => {
    window.addEventListener('mousedown', handleOutsideClick);
  });
};
