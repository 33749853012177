import i18next from 'i18next';
import {
  CardButtonType,
  OnboardingTourEventType,
  OnboardingTourShape,
  Platform,
} from '@globals';
import noop from 'lodash-es/noop';
import { logFlowPluginEvent } from '../utils/Analytics';
import { TextCardButtonViewLegacy } from './text_card_button_view';
import {
  defaultObj,
  HLayout,
  MainLayout,
  VLayout,
} from '../components/Elements/Layouts';
import { Image } from '../components/Elements/Shapes';
import addImageSvg from '../assets/controller/gallery_card_add_image.svg';
import {
  moveArrayElement,
  removeArrayElement,
  resByFunc,
  isFacebookAds,
} from './utils';
import { buttonControl, galleryCardControl } from './helpers/ControlsHelpers';
import { SetAttributeView } from './components/SetAttributeView';
import { isValidUrl } from '../../../utils/UrlUtils';
import { cropImage } from '../components';
import { uploadFile } from '../api/network';
import {
  emptyArray,
  hasNonEmptyButtons,
  isButtonValid,
  isEmptyString,
  isGalleryCardValid,
  notEmptyString,
} from './validation';
import { plurals } from '../../../utils/Plurals';
import { addValidationProps } from './entry-points/common/utils/validationProps';
import { AspectRatioValue } from '../../Plugins/ImagePlugin/ratio';
import { getPredefinedAttributes } from './entry-points/common/utils/getPredefinedAttributes';
import { PluginType } from '../../Plugins/common/PluginTypes';
import { getEntryPointCard } from './entry-points/common/utils';
import { Loader } from './loader';
import { AddMedia } from './add_media';
import {
  buttonHeight,
  buttonWidth,
  pluginWidth,
  textCardBackgroundColor,
} from './plugin_consts';
import { PlusButtonView } from './plus_button_view';
import { Banner } from './kit/Banner';
import { getFlowPlatform } from '../utils/getFlowPlatform';
import { ControlVisibility } from '../FlowBuilderOverlay';
import { isPaymentButtonType } from '../FlowBuilderOverlay/overlays/ButtonPopupOverlay/utils';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { uploadFileService } from '@utils/UploadService';
import {
  MediaTypes,
  createHTMLMediaTooltipByPlatform,
} from './utils/createHTMLMediaTooltip';
import { getSupportedImageTypes } from '../../../utils/PlatformSupportedFiles/getSupportedTypes';
import { FileAttachmentType } from '../../../utils/UploadService/types';

const DEFAULT_GALLERY_ITEMS_QTY_LIMIT = 9;

export const getGalleryItems = (view) =>
  view?._cards || view?._config?.products;

export const isGalleryLimitOverflow = (view) => {
  const cardsCount = getGalleryItems(view)?.length;
  const cardsLimit =
    view.props?.maxImageItems || DEFAULT_GALLERY_ITEMS_QTY_LIMIT;
  return cardsCount >= cardsLimit;
};

const isInstagramPlatform = () => getFlowPlatform() === Platform.instagram;

export class GalleryPluginView extends HLayout {
  TEST_NAME = 'GalleryPluginView';
  _node;
  _card;
  _cardsLayout;
  props;

  constructor(card, node) {
    super({
      title: window.i18next.t('gallery_view-string--208-gallery-view'),
      width: pluginWidth,
    });
    this._card = card;
    this._config = card.config;
    this._node = node;
    this.props = {
      maxImageItems: 10,
      isImagesNeedToBeUploaded: false,
      isAtLeastOneButtonIsRequired: () => false,
      hideCardControls: () => false,
      buttonsGone: () => false,
      isButtonsAvailable: () => true,
      hideCallPhoneOption: isInstagramPlatform(),
      hidePaymentOption: isInstagramPlatform(),
    };
    card.config.gallery_cards =
      card.config.gallery_cards && card.config.gallery_cards.length > 0
        ? card.config.gallery_cards
        : [{}];
    this._cards = card.config.gallery_cards;

    this._cardsLayout = new HLayout({
      itemsOffset: 20,
    });
    this._cardsLayout.sortableChildren = true;

    this.layout(this._cardsLayout);

    this._cards.forEach((galleryCard, index) => {
      this.layoutCard(galleryCard, index);
    });
  }

  addCard(idx) {
    const galleryCard = {};
    this._cards.push(galleryCard);
    this.layoutCard(galleryCard, idx);
    this._node.updateCard(this._card);
    this.renderNode();
  }

  layoutCard(galleryCard, idx) {
    const indexed = typeof idx !== 'undefined' && idx >= 0;
    let galleryCardView = new GalleryCardView(
      galleryCard,
      idx,
      this._card,
      this._node,
      this.props,
    );
    addValidationProps(galleryCardView);
    this._cardsLayout.layout(galleryCardView, {}, indexed ? idx : undefined);
  }
  removeCard(galleryCard) {
    removeArrayElement(this._cards, galleryCard._galleryCard);
    this._cardsLayout.removeView(galleryCard);
    galleryCard.destroy();
    this._node.updateCard(this._card);
    this._node.updateLines();
    this.renderNode();
  }

  moveCard(galleryCard, startIdx, idx) {
    moveArrayElement(this._cards, startIdx, idx);
    this._node.updateCard(this._card);
  }

  onBeforeRender() {
    const cardsViews = this._cardsLayout.views();
    cardsViews.forEach((v) => {
      if (!resByFunc(this.props.hideCardControls)) {
        galleryCardControl(v, this);
      }
    });
  }

  focus() {
    try {
      this._cardsLayout.views()[0]._editTitleView.startEditing();
    } catch (e) {
      console.log(e);
    }
  }

  hasSignificantChangesInConfig() {
    let galleryCards = this._config.gallery_cards;
    if (emptyArray(galleryCards)) return false;
    if (galleryCards.length > 1) return true;

    let firstCard = galleryCards[0];
    return (
      notEmptyString(firstCard.title) ||
      notEmptyString(firstCard.subtitle) ||
      notEmptyString(firstCard.image_url) ||
      notEmptyString(firstCard.item_url) ||
      hasNonEmptyButtons(firstCard.buttons)
    );
  }

  validationError() {
    return this._cardsLayout
      .views()
      .find((cardView) => cardView.validationError?.());
  }

  getOccupiedWidth() {
    return this._cardsLayout.width();
  }

  destroy() {
    this._cardsLayout.views().forEach((v) => {
      galleryCardControl().unsubscribe(v);
    });
    super.destroy();
  }
}

const MAX_BUTTONS_LENGTH = 3;
const getButtonMarginByIndex = ({ buttons, buttonIndex, view }) => {
  const isLastButtonItem = buttons.length - 1 === buttonIndex;
  const isViewInEditingMode = view._card.isEditing;
  const isButtonsLengthMaximum = buttons.length === MAX_BUTTONS_LENGTH;

  return {
    bottom:
      isLastButtonItem && (!isViewInEditingMode || isButtonsLengthMaximum)
        ? 15
        : 10,
    left: 15,
  };
};

const IMAGE_SIZE = 10;

export function createButtonsLayout(
  view,
  config,
  node,
  card,
  buttonsGone = noop,
  hideCallPhoneOption = noop,
  hidePaymentOption = noop,
  hideButtonPopupIfTargetBlockSelected = noop,
  galleryIndex,
) {
  if (!config.buttons) {
    config.buttons = [];
  }

  let buttons = config.buttons;
  view._buttonsLayout = new VLayout({
    width: pluginWidth - 30,
  });
  view.layout(view._buttonsLayout, { gone: buttonsGone });

  buttons.forEach((button, buttonIndex) => {
    let buttonView = new TextCardButtonViewLegacy({
      containerName: `${OnboardingTourShape.ButtonEditViewContainer}${buttonIndex}`,
      buttonEditName: `${OnboardingTourShape.ButtonEditView}${buttonIndex}`,
      onConfigChanged: () => {
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          element: `${OnboardingTourShape.ButtonEditView}${buttonIndex}`,
          value: null,
        });
      },
      config: button,
      node,
      card,
      hideCallPhoneOption,
      hidePaymentOption,
      hideButtonPopupIfTargetBlockSelected,
      buttonIndex,
      galleryIndex,
      disablePaymentOption: () =>
        buttons.some(({ type }) => isPaymentButtonType(type)) &&
        !isPaymentButtonType(button.type),
    });
    view._buttonsLayout.layout(buttonView, {
      margin: () => getButtonMarginByIndex({ buttons, buttonIndex, view }),
    });
    view.addControl(buttonView);
  });
  view._plusButtonView = new PlusButtonView(
    window.i18next.t('gallery_view-string--213-add-button'),
    buttonWidth,
    buttonHeight,
    '#000000',
    undefined,
  );
  view._plusButtonView.on('pointerdown', (e) => {
    e.stopPropagation();
  });
  view._plusButtonView.on('click', (e) => {
    e.stopPropagation();
    const button = {
      __typename: 'CardButton',
      type: CardButtonType.regular,
      title: '',
    };
    const buttonIndex = buttons.length;
    buttons.push(button);
    const buttonView = new TextCardButtonViewLegacy({
      config: button,
      node,
      card,
      hideCallPhoneOption,
      hidePaymentOption,
      hideButtonPopupIfTargetBlockSelected,
      buttonIndex,
      galleryIndex,
      disablePaymentOption: () =>
        buttons.some(({ type }) => isPaymentButtonType(type)),
    });

    view._buttonsLayout.layout(buttonView, {
      margin: () => getButtonMarginByIndex({ buttons, buttonIndex, view }),
    });
    node.updateCard(view._card);
    view.renderNode();
    buttonView.focus();
    view.addControl(buttonView);
    logFlowPluginEvent(
      card.plugin_id,
      'add button',
      view.propertyBag ? view.propertyBag() : undefined,
    );
  });
  view.layout(view._plusButtonView, {
    margin: { bottom: 15, left: 15 },
    gone: () =>
      !(buttons.length < MAX_BUTTONS_LENGTH && view._card.isEditing) ||
      buttonsGone(),
  });
}

export class GalleryCardView extends VLayout {
  TEST_NAME = 'GalleryCardView';
  _img;
  _editTitleView;
  _editSubtitleView;
  _node;
  _card;
  _galleryCard;
  _uploadedAndValid = true;
  _loading = false;

  constructor(galleryCard, idx, card, node, props) {
    let config = galleryCard;
    super({
      title: 'Gallery card view ' + galleryCard.title,
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._node = node;
    this._card = card;
    this._galleryCard = galleryCard;
    this._galleryCardIndex = idx;
    this.props = props;

    const mainViewProps = {
      margin: { left: 2, top: 2, bottom: card.config.square_ratio ? 0 : 1 },
    };
    const corners = { topLeft: 10, topRight: 10 };
    const cardHeight = card.config.square_ratio ? pluginWidth : 154;

    const imageBox = new MainLayout();
    this.layout(imageBox);

    this._img = new Image({
      url: galleryCard.image_url,
      width: pluginWidth - 2,
      height: cardHeight,
      corners,
      background: { fill: textCardBackgroundColor, cornerRadius: 10 },
      onReady: () => {
        this._loading = false;
        this._loader.stop();
        this.renderNode();
      },
    });

    imageBox.layout(this._img, {
      ...mainViewProps,
      gone: () => !galleryCard.image_url || !isValidUrl(galleryCard.image_url),
    });

    this._loader = new Loader({ height: cardHeight, background: { corners } });
    imageBox.layout(this._loader, {
      ...mainViewProps,
      gone: () => !this._loading,
    });

    this._media = new AddMedia({
      label: `<b>Image</b> (max <b>${IMAGE_SIZE}MB</b>)`,
      url: addImageSvg,
      height: cardHeight,
      background: {
        cornerRadius: 0,
      },
      corners,
    });
    this.layout(this._media, {
      ...mainViewProps,
      gone: () =>
        this._loading ||
        (galleryCard.image_url && isValidUrl(galleryCard.image_url)),
    });

    this._img.on('pointerdown', (e) => {
      if (this._card.isEditing) {
        e.stopPropagation();
      }
    });

    const handleChoosePicture = (e) => {
      if (this._card.isEditing) {
        e.stopPropagation();
        this.uploadImgData();
        logFlowPluginEvent('gallery', 'update image', { blockId: node.id });
      }
    };

    this._img.on('click', handleChoosePicture);
    this._media.on('click', handleChoosePicture);

    this._editTitleView = new SetAttributeView(
      card,
      {
        width: pluginWidth - 30,
        maxLines: 3,
        maxLength: 80,
        height: 0,
        caption: window.i18next.t('gallery_view-string-8081-title'),
        singleLine: false,
        margin: { left: 10, right: 10, top: 10, bottom: 10 },
      },
      undefined,
      () => {
        let newText = this._editTitleView.text().trim();
        if (galleryCard.title !== newText) {
          galleryCard.title = newText;
          node.updateCard(card);
          this._uploadedAndValid = true;
          logFlowPluginEvent('gallery', 'update title', this.propertyBag());
        }
        this._editTitleView.text(newText);
      },
      undefined,
      {
        discount: ControlVisibility.show,
      },
      getPredefinedAttributes(node.block, PluginType.gallery),
    );
    this.layout(this._editTitleView, {
      margin: { top: 15, left: 15, bottom: 4 },
    });

    this._editSubtitleView = new SetAttributeView(
      card,
      {
        width: pluginWidth - 30,
        height: 0,
        maxLines: 3,
        maxLength: 80,
        singleLine: false,
        margin: { left: 10, right: 10, top: 10, bottom: 10 },
        caption: window.i18next.t('gallery_view-string--199-subtitle'),
      },
      undefined,
      () => {
        let newText = this._editSubtitleView.text().trim();
        if (galleryCard.subtitle !== newText) {
          galleryCard.subtitle = newText;
          node.updateCard(card);
          this._uploadedAndValid = true;
          logFlowPluginEvent('gallery', 'update subtitle', this.propertyBag());
        }
        this._editSubtitleView.text(newText);
      },
      undefined,
      {
        discount: ControlVisibility.show,
      },
      getPredefinedAttributes(node.block, PluginType.gallery),
    );
    this.layout(this._editSubtitleView, {
      margin: () =>
        this.props.buttonsGone()
          ? { left: 15, bottom: 15 }
          : { left: 15, bottom: 4 },
    });

    this._editUrlView = new SetAttributeView(
      card,
      {
        width: pluginWidth - 30,
        isValid: () =>
          isEmptyString(galleryCard.item_url) ||
          isValidUrl(galleryCard.item_url),
        caption: 'URL',
      },
      undefined,
      () => {
        let newText = this._editUrlView.text().trim();
        if (galleryCard.item_url !== newText) {
          galleryCard.item_url = newText;
          node.updateCard(card);
          this._uploadedAndValid = true;
          logFlowPluginEvent('gallery', 'update url', this.propertyBag());
        }
        this._editUrlView.text(newText);
      },
      undefined,
      {
        discount: ControlVisibility.show,
      },
      getPredefinedAttributes(node.block, PluginType.gallery),
    );

    this.layout(this._editUrlView, {
      margin: { left: 15, bottom: 5 },
      gone: () => {
        const entryPointCard = getEntryPointCard(node.block.cards);

        return !card.isEditing || isFacebookAds(entryPointCard?.plugin_id);
      },
    });

    createButtonsLayout(
      this,
      config,
      node,
      card,
      () => this.props.buttonsGone(),
      () => this.props.hideCallPhoneOption,
      () => this.props.hidePaymentOption,
      () => this.props.hideButtonPopupIfTargetBlockSelected,
      this._galleryCardIndex,
    );

    const infoBannerView = new Banner({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.Gallery.instagramBannerText',
      ),
    });
    this.addToLayout(infoBannerView, {
      margin: { left: 16, bottom: 15 },
      gone: () =>
        this._galleryCardIndex !== 0 ||
        !isInstagramPlatform() ||
        !this._card.isEditing,
    });
  }

  propertyBag() {
    return { blockId: this._node.id, cardId: this._card.id };
  }

  startLoading = () => {
    this._loading = true;
    this._loader.start();
    this.renderNode();
  };

  uploadImgData() {
    uploadFileService
      .chooseFile({
        beforeUpload: this.startLoading,
        accept: getSupportedImageTypes(getFlowPlatform()),
        dataTestId: 'image__upload-file',
        platform: getFlowPlatform(),
        filetype: FileAttachmentType.image,
      })
      .then(this.onSuccessfulUpload)
      .catch(this.onFailedUpload);
  }

  addControl(buttonView) {
    buttonControl(
      buttonView,
      this._buttonsLayout,
      () => this._node.updateLines(),
      () => {
        removeArrayElement(this._galleryCard.buttons, buttonView._config);
        this._buttonsLayout.removeView(buttonView);
        buttonView.destroy();
        this._node.updateCard(this._card);
        this._node.updateLines();
        this.renderNode();
        logFlowPluginEvent('gallery', 'remove button', this.propertyBag());
      },
      (button, startIdx, idx) => {
        moveArrayElement(this._galleryCard.buttons, startIdx, idx);
        this._node.updateCard(this._card);
        let propertyBag = this.propertyBag();
        propertyBag.start = startIdx;
        propertyBag.end = idx;
        this.renderNode();
        logFlowPluginEvent('gallery', 'drag button', propertyBag);
      },
    );
  }

  crop() {
    if (this._galleryCard.image_url) {
      cropImage({
        image: this._galleryCard.image_url,
        ratio: this._card.config.square_ratio
          ? AspectRatioValue.square
          : AspectRatioValue.horizontalFacebookGallery,
        onSave: (blob) => {
          const formData = new FormData();
          let file = blob;
          formData.append('file', file, file.name);
          this._loading = true;
          this._uploadedAndValid = true;
          this.renderNode();
          this._loader.start();
          uploadFile(
            formData,
            (url) => {
              this.onSuccessfulUpload({
                url,
                filename: this._card.config.filename,
              });
            },
            this.onFailedUpload,
            {
              filetype: FileAttachmentType.image,
              platform: getFlowPlatform(),
              filename: this._card.config.filename,
            },
          );
        },
      });
    }
  }

  onSuccessfulUpload = ({ url }) => {
    this._galleryCard.image_url = url;
    this._node.updateCard(this._card);
    this._uploadedAndValid = true;
    this.renderNode();
  };

  onFailedUpload = (error) => {
    this._loader.stop();
    this._uploadedAndValid = false;
    this._loading = false;
    this.renderNode();
  };

  validationError() {
    const invalidCardsLength = this._card.config.gallery_cards.filter(
      (c) => !isGalleryCardValid(c, { buttonsGone: this.props.buttonsGone }),
    ).length;

    if (
      this._card.config.gallery_cards.length > 1 &&
      invalidCardsLength > 0 &&
      !this._card.isEditing &&
      this._galleryCardIndex === 0
    ) {
      return `${plurals(
        invalidCardsLength,
        window.i18next.t('gallery_view-string-6723-error'),
        window.i18next.t('gallery_view-string-2084-errors'),
      )}${window.i18next.t('gallery_view-Template-1057-in')}${plurals(
        invalidCardsLength,
        'card',
        'cards',
      )}`;
    }

    if (!this._uploadedAndValid) {
      return createHTMLMediaTooltipByPlatform(
        getFlowPlatform(),
        MediaTypes.image,
        IMAGE_SIZE,
      );
    }

    const card = this._galleryCard;
    if (card) {
      const { title, subtitle, image_url, buttons } = card;
      const isTitleEmpty = isEmptyString(title);
      const isSubtitleEmpty = isEmptyString(subtitle);
      const isImageUrlInvalid =
        isEmptyString(image_url) || !isValidUrl(image_url);
      const buttonsNotDefined = buttons.length === 0;
      let errorMessage = '';

      if (isTitleEmpty) {
        errorMessage += window.i18next.t(
          'gallery_view-string-1853-add-a-title',
        );
      }

      if (
        (isSubtitleEmpty && isImageUrlInvalid && buttonsNotDefined) ||
        (isImageUrlInvalid && this.props.isImagesNeedToBeUploaded)
      ) {
        errorMessage += `${
          errorMessage.length ? '<br/>' : ''
        }${window.i18next.t(
          'gallery_view-Template-1954-add-an-image-a-subtitle-or-a-button-name',
        )}`;
      }

      if (this.props.isAtLeastOneButtonIsRequired?.()) {
        const invalidCards = this._card.config.gallery_cards.filter(
          (c) => c.buttons.length === 0,
        );

        if (invalidCards.length > 0) {
          return window.i18next.t(
            'gallery_view-string-1827-at-least-one-button-is-required',
          );
        }
      }

      if (errorMessage) {
        return errorMessage;
      }

      if (
        this.props.isButtonsAvailable() &&
        buttons?.some((b) => !isButtonValid(b))
      ) {
        return true;
      }
    }

    return null;
  }

  onBeforeRender() {
    const config = this._galleryCard;
    this._img.url(config.image_url);
    let number = this._card.config.square_ratio ? pluginWidth : 200;
    this._img.height(number);
    this._media._layoutProps.height = number;
    this._loader._layoutProps.height = number;

    let textToSet = this._galleryCard.title;
    if (isEmptyString(textToSet)) {
      textToSet = '';
    }
    this._editTitleView.text(textToSet);

    textToSet = this._galleryCard.subtitle;
    if (isEmptyString(textToSet)) {
      textToSet = '';
    }
    this._editSubtitleView.text(textToSet);
    if (this._galleryCard.subtitle && !this._card.isEditing) {
      this._editSubtitleView.setTextColor('#747474');
    }

    textToSet = this._galleryCard.item_url;
    if (isEmptyString(textToSet)) {
      textToSet = '';
    }
    this._editUrlView.text(textToSet);
  }
}

export class ImgView extends MainLayout {
  TEST_NAME = 'ImgView';
  _image;

  constructor(props, background, layoutProps) {
    super(defaultObj(props, { height: 0 }));
    this._image = new Image(props);
    if (background) {
      this.layout(background, layoutProps);
    }
    this.layout(this._image, {
      gone: () => isEmptyString(this._image.url),
    });
  }

  url(url) {
    this._image.url(url);
    this.renderNode();
  }
}
