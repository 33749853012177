import i18next from 'i18next';
import { clone } from 'ramda';
import { ShopifyEventType } from '@globals';
import { shopifyEventEntryPointFragment_config_events_product_visit_actions as DelayConfig } from '../../../../../../Plugins/ShopifyEventEntryPoint/@types/shopifyEventEntryPointFragment';
import { VLayout } from '../../../../../components/Elements/Layouts';
import { PluginType, ValidationError } from '../../../../../types';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import { ButtonView } from '../../../../button_view';
import { buttonControl } from '../../../../helpers/ControlsHelpers';
import { pluginWidth } from '../../../../plugin_consts';
import { resByFunc, swapImmutable } from '../../../../utils';
import { LeadToBlockError } from '../../../common/errors';
import { EmptyDelayListError } from '../../errors';
import { ShopifyEventViewBase } from './common/ShopifyEventViewBase';
import { CartReminderEventViewProps } from './common/types';
import { ShopifyEventDelayView } from './components/ShopifyEventDelayView';

const DEFAULT_DELAY_CONFIG: DelayConfig = {
  __typename: 'ProductVisitAction',
  delay_in_minutes: 5,
  next_block_id: null,
  counter_id: null,
};

export class ProductVisitEventView extends ShopifyEventViewBase {
  private delayItemsBox: VLayout;
  private readonly props: CartReminderEventViewProps;

  constructor(props: CartReminderEventViewProps) {
    super({
      type: props.config.type,
    });
    this.props = {
      ...props,
      config: clone(props.config),
    };
    this.delayItemsBox = new VLayout();
    this.addToLayout(this.delayItemsBox, {
      margin: {
        bottom: 8,
      },
    });

    this.addToLayout(
      new ButtonView({
        width: pluginWidth - 40,
        height: 40,
        title: i18next.t('shopify.addReminder'),
        onClick: (event) => {
          event.stopPropagation();
          this.addDelayItem();
          logFlowPluginEvent(
            PluginType.shopify_event_entry_point,
            'Add reminder click',
            {
              delayInMinutes: DEFAULT_DELAY_CONFIG.delay_in_minutes,
              shopifyEventType: ShopifyEventType.product_visit,
            },
          );
        },
      }),
      {
        margin: {
          bottom: 20,
          left: 20,
        },
        gone: () =>
          (!resByFunc(this.props.isEditing) &&
            !!this.props.config.product_visit?.actions?.length) ||
          (this.props.config.product_visit?.actions?.length || 0) > 9,
      },
    );

    this.props.config.product_visit?.actions.forEach((action) => {
      this.createDelayItemView(action);
    });
  }

  private addDelayItem() {
    const { config, onChange } = this.props;
    const newDelayConfig = clone(DEFAULT_DELAY_CONFIG);
    config.product_visit?.actions.push(newDelayConfig);
    this.createDelayItemView(newDelayConfig);
    onChange(config);
  }

  private createDelayItemView(delayConfig: DelayConfig) {
    const view = new ShopifyEventDelayView<DelayConfig>({
      node: this.props.node,
      config: delayConfig,
      isEditing: this.props.isEditing,
      onChange: (updatedDelayConfig) => {
        const { config, onChange } = this.props;
        const index = this.delayItemsBox.indexOf(view);
        config.product_visit!.actions[index] = updatedDelayConfig;
        onChange(config);
      },
      lineMenuProps: {
        isShowProductVisitReminders: true,
      },
      min: 1,
      eventType: ShopifyEventType.product_visit,
      title: i18next.t('shopify.remindIn'),
      titleImmediately: i18next.t('shopify.remind'),
    });

    this.delayItemsBox.addToLayout(view, {
      margin: {
        bottom: 8,
        left: 20,
      },
    });

    buttonControl(
      view,
      this.delayItemsBox,
      undefined,
      () => {
        const { config, onChange } = this.props;
        const index = this.delayItemsBox.indexOf(view);
        config.product_visit!.actions.splice(index, 1);
        onChange(config);
        this.delayItemsBox.removeView(view);
        view.destroy();
        this.renderNode();
        logFlowPluginEvent(
          PluginType.shopify_event_entry_point,
          'reminder remove',
          {
            shopifyEventType: ShopifyEventType.product_visit,
          },
        );
      },
      async (_: any, oldIndex: number, newIndex: number) => {
        const { config, onChange } = this.props;
        config.product_visit!.actions = swapImmutable(
          config.product_visit!.actions,
          oldIndex,
          newIndex,
        );
        onChange(config);
        logFlowPluginEvent(
          PluginType.shopify_event_entry_point,
          'reminder change position',
          {
            oldIndex,
            newIndex,
            shopifyEventType: ShopifyEventType.product_visit,
          },
        );
        this.renderNode();
      },
      undefined,
      undefined,
      true,
    );
  }

  public validationError(): ValidationError | undefined {
    const actions = this.props.config.product_visit?.actions;
    return !actions?.length
      ? new EmptyDelayListError(() => {
          this.addDelayItem();
          logFlowPluginEvent(
            PluginType.shopify_event_entry_point,
            'Add reminder in tooltip click',
            {
              delayInMinutes: DEFAULT_DELAY_CONFIG.delay_in_minutes,
              shopifyEventType: ShopifyEventType.product_visit,
            },
          );
        })
      : actions.some(({ next_block_id }) => !next_block_id)
      ? new LeadToBlockError()
      : undefined;
  }
}
