import {
  FLOW_BUILDER_ROOT_ID,
  TEMPLATES_MODAL_ROOT_ID,
} from '../../../../../consts';
import { Cursor } from '../../../../../components/Elements/Layouts/types';
import type { PixiField } from '../../../../../PixiField';

interface CursorPayload {
  over: () => void;
  out: () => void;
  type: Cursor | undefined;
  outType: Cursor | undefined;
}

export class CursorHandler<T = any> {
  private views = new Map<T, CursorPayload>();

  private pixiField: PixiField;
  constructor(pixiField: PixiField) {
    this.pixiField = pixiField;
  }

  createCursor(view: T, type?: Cursor, outType?: Cursor) {
    const existing = this.views.get(view);
    const rootEl =
      document.getElementById(FLOW_BUILDER_ROOT_ID) ||
      document.getElementById(TEMPLATES_MODAL_ROOT_ID);
    if (!rootEl) {
      return false;
    }
    if (typeof existing !== 'undefined') {
      if (existing.type === type && existing.outType === outType) {
        return false;
      }
    }
    const onOver = () => {
      if (type) rootEl.style.cursor = type;
    };
    const onOut = () => {
      if (outType) rootEl.style.cursor = outType;
    };
    this.pixiField.hoverHandler().subscribe({
      view,
      eventId: 'cursor_handler',
      propagate: false,
      over: onOver,
      out: onOut,
    });
    this.views.set(view, {
      type,
      outType,
      over: onOver,
      out: onOut,
    });
    return true;
  }

  removeCursor(view: T) {
    this.pixiField.hoverHandler().unsubscribe(view, 'cursor_handler');
    this.views.delete(view);
  }

  destroy() {
    this.views.clear();
  }
}
