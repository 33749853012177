import React, { HTMLProps } from 'react';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { ZapierTemplatesQuery_zapierTemplates_steps } from '../@types/ZapierTemplatesQuery';
import * as css from './ZapierItemSteps.css';

interface ZapierTemplateStepsProps extends HTMLProps<HTMLDivElement> {
  steps: ZapierTemplatesQuery_zapierTemplates_steps[];
}

export const ZapierItemSteps = React.forwardRef<
  HTMLDivElement,
  ZapierTemplateStepsProps
>(({ steps, ...props }, ref) => (
  <Flex alignItems="center" className={css.box} {...props} ref={ref}>
    {steps.map(({ image, description }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        <Flex
          className={css.picBox}
          justifyContent="center"
          alignItems="center"
        >
          <img className={css.pic} src={image} alt={description || ''} />
        </Flex>

        {index + 1 < steps.length && (
          <Icon
            icon="triangle"
            color="grey"
            className={css.triangle}
            size="16px"
          />
        )}
      </React.Fragment>
    ))}
  </Flex>
));
