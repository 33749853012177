export const emptyFacebookShopProductGroupConflict = {
  flow_id: null,
  flow_title: null,
  entry_point_id: null,
};

export const DIALOG_STYLE = {
  width: 562,
  height: 570,
};

export const CONTENT_HEIGHT = 360;
