import { PluginType } from '@components/Plugins/common/PluginTypes';
import { NestedMenuNodeDisplayMode } from '@ui/NestedMenu/types';
import { PlatformHolder } from './PlatformHolder';
import { BLOCK_SUBTYPES } from '../../consts';
import { getBlockShortcut } from '../helpers/blocksShortcuts';
import { PropFunc } from '../../types';
import { BlockContextType } from '@globals';
import { CustomAiPlugins } from '../plugins/AiCustomTemplates/getAiCustomTemplatesList';

export enum MenuItemType {
  entrypoint,
  plugin,
  button,
  block,
  node,
}

interface Item {
  id: string;
  type: MenuItemType;
  newLabel?: boolean;
  tooltip?: PropFunc<Tooltip>;
  defaultPluginId?: PluginType;
}

type ValueOf<T> = T[keyof T];

interface MenuItem {
  platform: PlatformHolder;
  subtype: ValueOf<typeof BLOCK_SUBTYPES>;
  contextType?: BlockContextType;
  shortcut?: ReturnType<typeof getBlockShortcut>;
}

interface MenuItemUI {
  title: string;
  img: React.FC;
  imageUrl?: string;
}

interface MenuNode<T> {
  children?: T[];
  platform?: PlatformHolder;
}

interface MenuNodeUI {
  displayMode?: NestedMenuNodeDisplayMode;
  title?: string;
  img?: React.FC;
  imageUrl?: string;
}

export interface IMenuItem extends Item, MenuItem, MenuItemUI {}
export interface IMenuNode<T> extends Item, MenuNode<T>, MenuNodeUI {}

export interface Tooltip {
  html?: string;
  text?: string;
  url?: string;
}

export type GetMenuItemByConfig = (
  config: CreateLineMenuParams,
) => IMenuItem | IMenuNode<GetMenuItemByConfig> | undefined;

export type MenuItemsMap = Record<string, GetMenuItemByConfig>;

export interface CreateLineMenuParams {
  isShowEntryPoints?: boolean;
  hideIntegrations?: boolean;
  isShowContentBlocks?: boolean;
  isShowActions?: boolean;

  isShowCommentsAutoreplyEntryPoint?: boolean;
  isShowConnectToExistingBlock?: boolean;
  isShowReminders?: boolean;
  isShowProductVisitReminders?: boolean;
  isShowCalendlySpecificItems?: boolean;
  isShowScheduleCalendlyEvent?: boolean;
  isShowReceipt?: boolean;
  isShowPostPurchase?: boolean;
  isShowOptInConfirmation?: boolean;
  isShowBackInStockMessage?: boolean;
  isShowShopifyDiscount?: boolean;
  isWhatsappEnabled?: boolean;

  shouldShowCalendlyMenuItems?: boolean;
  shouldShowShopifyBackInStockMenuItems?: boolean;
  isAutomateEnabled?: boolean;
  isAdCommentsAutoreplyEnabled?: boolean;

  shouldShowShopifyMenuItems?: boolean;

  isShowKommoPlugin?: boolean;

  customAiPlugins?: CustomAiPlugins;
}

export enum BlockMenuId {
  customAiPlugins = 'customAiPlugins',
  contentBlocks = 'contentBlocks',
  collectUserData = 'collectUserData',
  actionItems = 'actionItems',
}

export enum MenuNodeId {
  actions = 'acitons',
  contentBlocks = 'contentBlocks',
  integrations = 'integrations',
  entryPoints = 'entryPoints',
  buttons = 'buttons',
  contextItems = 'contextItems',
  facebook = 'facebook',
  website = 'website',
  collectUserData = 'collectUserData',
  customAiPlugins = 'customAiPlugins',
}

export enum MenuButtonId {
  connectToExistingBlock = 'connect_to_existing_block',
}
