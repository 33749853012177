import { flowBuilderModule } from '../FlowBuilderModule';
import { PluginType } from '../types';

const APOLLO_BASED_PLUGINS = [
  PluginType.inbound_links_entry_point,
  PluginType.add_attribute,
  PluginType.remove_attribute,
  PluginType.go_to_block_plugin,
  PluginType.google_sheet,
  PluginType.external_integration_entry_point,
  PluginType.instagram_direct_entry_point,
  PluginType.instagram_comments_autoreply_entry_point,
  PluginType.instagram_story_mention_entry_point,
  PluginType.instagram_story_reply_entry_point,
  PluginType.customer_chat_entry_point,
  PluginType.shopify_customer_chat_entry_point,
  PluginType.event_trigger,
  PluginType.integration_zapier_plugin,
  PluginType.shopify_event_entry_point,
  PluginType.abandoned_cart,
  PluginType.text_reminder_shortcut,
  PluginType.gallery_reminder_shortcut,
  PluginType.media_share,
  PluginType.ads_manager_ctm_entry_point,
  PluginType.ads_manager_ctm_json_entry_point,
  PluginType.ads_manager_sm_entry_point,
  PluginType.instagram_ads_manager_ctm_entry_point,
  PluginType.popup_entry_point,
  PluginType.calendly,
  PluginType.customer_chat_entry_point,
  PluginType.order_receipt,
  PluginType.ref_link_entry_point,
  PluginType.product_list,
  PluginType.product_list_shopify_order_shortcut,
  PluginType.product_list_back_in_stock_message_shortcut,
  PluginType.product_list_opt_in_confirmation_shortcut,
  PluginType.shopify_back_in_stock,
  PluginType.audio,
  PluginType.quick_reply,
  PluginType.product_list_product_visit_shortcut,
  PluginType.notification_messages_request,
  PluginType.nTNDaily,
  PluginType.nTNWeekly,
  PluginType.nTNMonthly,
  PluginType.collect_answers_shortcut,
  PluginType.collect_emails_shortcut,
  PluginType.collect_phones_shortcut,
  PluginType.collect_numbers_shortcut,
  PluginType.datepicker_quick_reply_shortcut,
  PluginType.date_and_time_shortcut,
  PluginType.date_picker,
  PluginType.flowbuilder_livechat,
  PluginType.json_plugin,
  PluginType.comments_reply_shortcut,
  PluginType.instagram_comments_reply_shortcut,
  PluginType.instagram_bot_link_entry_point,
  PluginType.whatsapp_direct_entry_point,
  PluginType.whatsapp_bot_link,
  PluginType.comments_autoreply_entry_point,
  PluginType.ad_comments_autoreply_entry_point,
  PluginType.comments_like,
  PluginType.open_ai,
  PluginType.custom_ai,
  PluginType.whatsapp_template,
  PluginType.email,
  PluginType.split_traffic,
  PluginType.whatsapp_list,
  PluginType.whatsapp_widget_entry_point,
  PluginType.whatsapp_popup_entry_point,
  PluginType.document,
  PluginType.video,
  PluginType.whatsapp_location,
  PluginType.kommo_plugin,
];

export const isApolloBasedPlugin = (type: PluginType) => {
  if (flowBuilderModule.hasPlugin(type)) {
    return flowBuilderModule.getPlugin(type).isApolloBased;
  }
  return APOLLO_BASED_PLUGINS.includes(type);
};
