import { resByFunc } from './utils';
import { HLayout, MainLayout } from '../components/Elements/Layouts';
import { HTMLText, TextureShape } from '../components/Elements/Shapes';
import { FullCounterStats } from '../utils/Statistics/types';
import { mainStrokeColor, pluginWidth } from './plugin_consts';
import { ItemStatView } from './kit/ItemStatView/ItemStatView';
import { tooltipScaled } from './helpers/TooltipHelpers';
import { errorInfoSvgTexture } from '../assets/textures';
import { HEXColors } from '@ui/_common/colors';

const prepareTitle = (title: string) => (title ? resByFunc(title) : '+');

export class PlusButtonView extends MainLayout {
  TEST_NAME = 'PlusButtonView';
  _title: string;
  textView: HTMLText;

  _disable = false;
  opacity = 1;

  // @ts-ignore super is not the first line in constructor
  constructor(
    title: string,
    width?: number,
    height?: number,
    fontColor?: HEXColors,
    stats?: FullCounterStats,
    alertText?: string,
  ) {
    const width1 = width || pluginWidth;
    const height1 = height || 35;
    const mainLayout = new MainLayout({
      width: width1,
      height: height1,
      background: {
        fill: HEXColors.white,
        opacity: 0.5,
      },
    });
    super({
      width: width1,
      cursor: {
        in: 'pointer',
      },
      background: {
        cornerRadius: 5,
        fill: HEXColors.white,
        strokeWidth: 1,
        stroke: HEXColors._ECECEC,
        onhover: {
          strokeWidth: 2,
          stroke: mainStrokeColor,
        },
      },
    });
    this._title = title;
    this.textView = new HTMLText({
      text: prepareTitle(title),
      fontSize: 15,
      fontStyle: 'semibold',
      height: height1,
      fill: fontColor || HEXColors.black,
      align: 'center',
      verticalAlign: 'center',
      width: width1,
    });

    if (stats) {
      this.layout(
        new ItemStatView({
          stats,
          showPercentValue: true,
        }),
        {
          margin: {
            top: 9.5,
            left: 196,
          },
        },
      );
    }

    this.layout(this.textView);

    if (alertText) {
      const alertLayout = new HLayout({});

      alertLayout.addToLayout(
        new TextureShape({
          texture: errorInfoSvgTexture,
          width: 14,
          height: 14,
        }),
      );

      tooltipScaled({
        view: alertLayout,
        text: alertText,
      });
      this.addToLayout(alertLayout, { margin: { top: 13, left: width1 - 20 } });
    }

    // TODO: property inactive doesn't exist in type Layout
    this.layout(mainLayout, { gone: (v: any) => !v?.inactive });
    mainLayout.on('pointerdown', (e: Event) => e.stopPropagation());
    mainLayout.on('click', (e: Event) => e.stopPropagation());
  }

  setDisabled(disabled: boolean) {
    this._disable = disabled;
    this.alpha = disabled ? 0.6 : 1;
    this.interactiveChildren = !disabled;
    this.interactive = !disabled;
  }

  onBeforeRender() {
    this.textView.text(prepareTitle(this._title));
  }
}
