const HOLD_DELAY = 120;

export interface ClickHoldCoordinatorDelegate {
  on(event: string, callback: (event: any) => void): void;
  off(event: string, callback?: (() => void) | undefined): void;
  shouldPreventClick?(): boolean;
  shouldPreventHold?(): boolean;
}

interface ClickHoldCoordinatorConfig {
  onClick?(event: any): void;
  onHold?(event: any): void;
}

export class ClickHoldCoordinator {
  private holdingLineTimeout: number | undefined;
  private preventClickAction = false;

  constructor(
    private delegate: ClickHoldCoordinatorDelegate,
    private config: ClickHoldCoordinatorConfig,
  ) {
    this.delegate.on('pointerdown', (event: any) => {
      event.stopPropagation();
      this.clearHoldTimeout();

      if (this.delegate.shouldPreventHold?.()) {
        return;
      }

      this.holdingLineTimeout = window.setTimeout(() => {
        if (!this.holdingLineTimeout) {
          return;
        }
        this.preventClickAction = true;
        this.config.onHold?.(event);
      }, HOLD_DELAY);
    });

    this.delegate.on('click', (event: any) => {
      event.stopPropagation();
      if (this.preventClickAction || this.delegate.shouldPreventClick?.()) {
        return;
      }
      this.clearHoldTimeout();
      this.config.onClick?.(event);
    });
  }

  private clearHoldTimeout() {
    if (this.holdingLineTimeout) {
      clearTimeout(this.holdingLineTimeout);
    }
  }

  public flush() {
    this.clearHoldTimeout();
    this.preventClickAction = false;
  }

  public destroy() {
    // @ts-ignore
    this.delegate.off('click', this.config.onClick);
    // @ts-ignore
    this.delegate.off('pointerdown', this.config.onHold);
  }
}
