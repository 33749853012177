import React, { useEffect } from 'react';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { Flex } from '@ui/Flex';
import { sendEvent } from '@utils/Analytics';
import { FacebookAdCampaignsListEmpty } from '../../../../../../../../pages/GrowPage/ClickToMessenger/components/FacebookAdCampaignsListEmpty';

interface NoAccountsViewProps {
  onRefresh: () => void;
  pluginId: PluginType;
}

export const NoAccountsView: React.FC<NoAccountsViewProps> = ({
  onRefresh,
  pluginId,
}) => {
  useEffect(() => {
    sendEvent({
      category: 'flows',
      label: pluginId,
      action: 'No accounts view popup shows up',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex justifyContent="center" alignItems="center" style={{ height: 510 }}>
      <FacebookAdCampaignsListEmpty
        refetchRequest={onRefresh}
        title={window.i18next.t(
          'NoAccountsView-string-6313-you-dont-have-any-facebook-ad-accounts',
        )}
        message={window.i18next.t(
          'NoAccountsView-string--539-create-an-account-for-your-facebook-page',
        )}
        refreshButtonLabel={window.i18next.t(
          'NoAccountsView-string--917-refresh-accounts-list',
        )}
      />
    </Flex>
  );
};
