import { HEXColors } from '@ui/_common/colors';
import { HLayout } from '../../components/Elements/Layouts';
import { bannerInfoIconSvgTexture } from '../../assets/textures';
import { HTMLText, TextureShape } from '../../components/Elements/Shapes';
import { tooltipScaled } from '../helpers/TooltipHelpers';
import { pluginWidth } from '../plugin_consts';

interface BannerProps {
  text: string;
  tooltipText?: string;
}

export class Banner extends HLayout {
  constructor({ text, tooltipText }: BannerProps) {
    const width = pluginWidth - 32;
    super({
      width,
      background: { fill: HEXColors.antiqueWhite, cornerRadius: 4 },
    });

    const infoIconContainer = new HLayout({});
    const iconView = new TextureShape({
      texture: bannerInfoIconSvgTexture,
      width: 16,
      height: 16,
    });
    this.addToLayout(infoIconContainer.addToLayout(iconView), {
      margin: { top: 14, left: 12, right: 8 },
    });

    if (tooltipText) {
      tooltipScaled({
        view: infoIconContainer,
        text: tooltipText,
      });
    }

    this.addToLayout(
      new HTMLText({
        text,
        width: width - 42,
        fontSize: 15,
        fill: HEXColors.alloyOrange,
        trustedHtml: true,
        verticalAlign: 'center',
      }),
      { margin: { top: 11, bottom: 14 } },
    );
  }
}
