import { head, last } from 'ramda';
import { HEXColors } from '@ui/_common/colors';
import { pluginTriangleSvgTexture } from '../../../../../../../assets/textures';
import { HLayout } from '../../../../../../../components/Elements/Layouts';
import { ZapierZap } from '../../../../query';
import {
  HTMLText,
  Image,
  TextureShape,
} from '../../../../../../../components/Elements/Shapes';
import { zapFragment_steps } from '../../../../@types/zapFragment';

const STEPS_LIMIT = 2;

export class ZapSteps extends HLayout {
  constructor(zap: ZapierZap) {
    super({
      height: 32,
      width: 71,
      background: {
        fill: HEXColors.white,
        cornerRadius: 4,
      },
    });

    const { steps } = zap;

    this.createStep(head(steps));

    if (steps.length > STEPS_LIMIT) {
      this.addToLayout(
        new HTMLText({
          text: '...',
          fontSize: 12,
          trustedHtml: true,
          fill: HEXColors.grey,
        }),
        {
          margin: {
            top: 6,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      );
    } else {
      this.addToLayout(
        new TextureShape({
          texture: pluginTriangleSvgTexture,
          width: 6,
          height: 8,
        }),
        {
          margin: {
            top: 12,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      );
    }

    this.createStep(last(steps));
  }

  private createStep(step: zapFragment_steps) {
    this.addToLayout(
      new Image({
        url: step.app?.image,
        width: 16,
        height: 16,
      }),
      {
        margin: {
          top: 8,
          right: 8,
          bottom: 8,
          left: 8,
        },
      },
    );
  }

  destroy() {
    super.destroy();
  }
}
