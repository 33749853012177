import i18next from 'i18next';
import { logFlowPluginEvent } from '../utils/Analytics';
import {
  OnboardingTourEventType,
  OnboardingTourShape,
  Platform,
} from '@globals';
import { TextEditView, TextEditViewProps } from './text_edit_view';
import { VLayout } from '../components/Elements/Layouts';
import { moveArrayElement, removeArrayElement } from './utils';
import { buttonControl } from './helpers/ControlsHelpers';
import { createButtonsLayout } from './gallery_view';
import { ControlVisibility } from '../FlowBuilderOverlay';
import {
  hasNonEmptyButtons,
  isButtonValid,
  isEmptyString,
  notEmptyString,
} from './validation';
import {
  getPredefinedAttributes,
  shouldJoinAttributes,
} from './entry-points/common/utils/getPredefinedAttributes';
import { flowBuilderEventEmitter, FlowBuilderEvent } from './events';
import { Card, PluginType } from '../types';
import {
  inactiveStrokeColor,
  mainStrokeColor,
  pluginWidth,
  textCardBackgroundColor,
} from './plugin_consts';
import { getFlowPlatform } from '@components/FlowBuilder/utils/getFlowPlatform';
import { Node } from '../Node';
import { HEXColors } from '@ui/_common/colors';
import { TextCardButtonView } from './text_card_button_view';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

// TODO кажется нет смысла загоняться с типизацией, тк плагин все равно
// переписывать на стейтфул и там будут сгенеренные типы.
interface ButtonConfig {
  type?: string;
  title?: string;
  block_id?: Array<string>;
  counter_id?: string;
}

interface TextPluginConfig {
  text: string;
  buttons: Array<ButtonConfig>;
}

interface TextPluginViewProps {
  isAtLeastOneButtonIsRequired: () => boolean;
  isButtonsAvailable: () => boolean;
  buttonsProps: {};
  buttonsGone: () => boolean;
  textMaxLength: number;
  hideOpenShopifyStoryOptions?: boolean;
  hideCallPhoneOption?: boolean;
  hidePaymentOption?: boolean;
  hideButtonPopupIfTargetBlockSelected?: boolean;
  isEmptyStringAvailable?: boolean;
}

export class TextPluginView extends VLayout {
  TEST_NAME = 'TextPluginView';

  public props: TextPluginViewProps;
  public _card: Card<TextPluginConfig>;
  private _node: Node;
  private _editTextView: TextEditView;
  private _config: TextPluginConfig;
  // @ts-expect-error инициализируется мутацией в FlowBuilder/views/gallery_view.js:223
  private _buttonsLayout: VLayout;
  private textProps: TextEditViewProps;

  constructor(card: Card<TextPluginConfig>, node: Node) {
    super({
      // TODO probably not needed
      title: `Text plugin view ${card.text}`,
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._node = node;
    this._card = card;
    this._config = card.config;
    this.props = {
      isAtLeastOneButtonIsRequired: () => false,
      isButtonsAvailable: () => true,
      buttonsProps: {},
      buttonsGone: () =>
        ({
          [Platform.instagram]: true,
          [Platform.facebook]: false,
          [Platform.whatsapp]: false,
        }[getFlowPlatform()!]),
      textMaxLength: 640,
    };

    this.textProps = {
      name: OnboardingTourShape.TextPluginTextArea,
      maxLength: this.props.textMaxLength,
      fontSize: 15,
      fill: HEXColors.black,
      text: card.config.text || '',
      caption: i18next.t('text_plugin-string--122-add-text'),
      captionFill: HEXColors.grey,
      enterForNewLine: false,
      align: 'left',
      editable: () => card.isEditing,
      width: pluginWidth - 30,
      margin: { left: 8, top: 8, bottom: 8, right: 8 },
      background: {
        fill: () =>
          card.isEditing ? HEXColors.white : textCardBackgroundColor,
        cornerRadius: 4,
        strokeWidth: () => (!card.isEditing ? 0 : 1),
        stroke: (v) =>
          v?._textAreaShown
            ? mainStrokeColor
            : this.textIsValid()
            ? inactiveStrokeColor
            : HEXColors._EBB8B0,
      },
    };
    this._editTextView = new TextEditView(
      this.textProps,
      undefined,
      (newText) => {
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          element: OnboardingTourShape.TextPluginTextArea,
          value: newText,
        });
      },
      () => {
        const newText = this._editTextView.text().trim();

        if (this._config.text !== newText) {
          this._config.text = newText;
          const afterUpdate = () => {
            flowBuilderEventEmitter.emit(FlowBuilderEvent.textPluginUpdated);
          };
          node.updateCard(card, afterUpdate, afterUpdate);
          logFlowPluginEvent('text', 'update text', this.propertyBag());
        }
        this.renderNode();
      },
      {
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
        discount: ControlVisibility.show,
      },
      getPredefinedAttributes(node.block, PluginType.text),
      shouldJoinAttributes(node.block.subtype),
    );

    this.layout(this._editTextView, {
      margin: { left: 15, top: 15, bottom: 15 },
    });

    createButtonsLayout(
      this,
      card.config,
      node,
      card,
      () => this.buttonsGone(),
      () => this.props.hideCallPhoneOption,
      () => this.props.hidePaymentOption,
      () => this.props.hideButtonPopupIfTargetBlockSelected,
    );
  }

  public onBeforeRender() {
    this._editTextView.updateProps({ maxLength: this.props.textMaxLength });
  }

  public startEditing() {
    this._card.isEditing = true;
  }

  public stopEditing() {
    this._card.isEditing = false;
  }

  private buttonsGone() {
    return this.props.buttonsGone?.();
  }

  private propertyBag() {
    return { blockId: this._node.id, cardId: this._card.id };
  }

  public addControl(buttonView: TextCardButtonView) {
    buttonControl(
      buttonView,
      this._buttonsLayout,
      () => {
        this._node.updateLines();
        this.renderNode();
      },
      () => {
        removeArrayElement(this._config.buttons, buttonView._config);
        this._buttonsLayout.removeView(buttonView);
        buttonView.destroy();
        this._node.updateCard(this._card);
        this._node.updateLines();
        this.renderNode();
        logFlowPluginEvent('text', 'remove button', this.propertyBag());
      },
      (_, startIdx, idx) => {
        moveArrayElement(this._config.buttons, startIdx, idx);
        this._node.updateCard(this._card);
        this.renderNode();
        const propertyBag = this.propertyBag();
        logFlowPluginEvent('text', 'drag button', {
          ...propertyBag,
          start: startIdx,
          end: idx,
        });
      },
    );
  }

  private textIsValid() {
    return (
      !this._node.isEditing ||
      (this._editTextView && this._editTextView._textAreaShown) ||
      (this.props.isEmptyStringAvailable ? true : this._config.text)
    );
  }

  public focus() {
    this._editTextView.showTextArea();
  }

  public validationError() {
    if (
      this.props.isAtLeastOneButtonIsRequired?.() &&
      this._config.buttons.length === 0
    ) {
      return true;
    }

    return (
      (this.props.isEmptyStringAvailable
        ? false
        : isEmptyString(this._config.text)) ||
      (this.props.isButtonsAvailable() &&
        this._config.buttons?.some((b) => !isButtonValid(b)))
    );
  }

  public hasSignificantChangesInConfig() {
    return (
      notEmptyString(this._config.text) ||
      hasNonEmptyButtons(this._config.buttons)
    );
  }
}
