import { Card } from '@components/Plugins/common';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  CustomerActionType,
  Platform,
  WelcomeMessageMediaType,
} from '@globals';
import {
  FacebookMessageTypeIds,
  InstagramMessageTypeIds,
  MESSAGE_TYPES_FACEBOOK_CTM,
  MESSAGE_TYPES_FACEBOOK_SM,
  MESSAGE_TYPES_INSTAGRAM_CTM,
} from './consts';

export const getFacebookMessageTypeId = (card: Card) => {
  const type: WelcomeMessageMediaType =
    card.config.welcomeMessageType || WelcomeMessageMediaType.text;
  const customerActionType: CustomerActionType =
    card.config.customer_action_type || CustomerActionType.quick_replies;
  const plugin_id: PluginType =
    (card.plugin_id as PluginType) || PluginType.ads_manager_ctm_entry_point;

  if (
    type === WelcomeMessageMediaType.text &&
    customerActionType === CustomerActionType.buttons
  ) {
    return FacebookMessageTypeIds.TEXT_AND_BUTTONS;
  }
  if (
    type === WelcomeMessageMediaType.text &&
    customerActionType === CustomerActionType.quick_replies
  ) {
    return FacebookMessageTypeIds.TEXT_AND_QR;
  }
  if (type === WelcomeMessageMediaType.image) {
    if (
      [
        PluginType.ads_manager_ctm_entry_point,
        PluginType.ads_manager_ctm_json_entry_point,
      ].includes(plugin_id)
    ) {
      return FacebookMessageTypeIds.TEXT_AND_IMAGE;
    }

    if (customerActionType === CustomerActionType.buttons) {
      return FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_BUTTONS;
    }

    return FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_QR;
  }
  if (type === WelcomeMessageMediaType.video) {
    return FacebookMessageTypeIds.TEXT_AND_VIDEO;
  }

  return FacebookMessageTypeIds.TEXT_AND_BUTTONS;
};

export const getInstagramMessageTypeId = (card: Card) => {
  const type: WelcomeMessageMediaType =
    card.config.welcomeMessageType || WelcomeMessageMediaType.text;
  const customerActionType: CustomerActionType =
    card.config.customer_action_type || CustomerActionType.ice_breakers;

  if (
    type === WelcomeMessageMediaType.text &&
    customerActionType === CustomerActionType.none
  ) {
    return InstagramMessageTypeIds.TEXT;
  }
  if (
    type === WelcomeMessageMediaType.text &&
    customerActionType === CustomerActionType.ice_breakers
  ) {
    return InstagramMessageTypeIds.TEXT_AND_ICE_BREAKERS;
  }

  return InstagramMessageTypeIds.TEXT;
};

export const getMessageTypesByPlatform = (
  platform: Platform,
  plugin_id: PluginType,
) => {
  if (platform === Platform.facebook) {
    return [
      PluginType.ads_manager_ctm_entry_point,
      PluginType.ads_manager_ctm_json_entry_point,
    ].includes(plugin_id)
      ? MESSAGE_TYPES_FACEBOOK_CTM()
      : MESSAGE_TYPES_FACEBOOK_SM();
  }

  return MESSAGE_TYPES_INSTAGRAM_CTM();
};

export const getMessageTypeIdsByPlatform = (platform: Platform, data: Card) => {
  if (platform === Platform.facebook) {
    return getFacebookMessageTypeId(data);
  }

  return getInstagramMessageTypeId(data);
};
