import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
} from '@components/ConnectPageDialog';
import { BotStatus } from '@utils/Bot';
import { getFlowController } from '../../../../PixiFieldRepository';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import { Node } from '../../../../Node';
import { Card } from '../../../../types';

export const showConnectPageDialog = (
  node: Node,
  entryPointCard: Card,
  onPageConnected?: (params: {
    status: BotStatus;
    pageId: string | null | undefined;
  }) => void,
  connectPageOrigin?: ConnectPageOrigin,
) => {
  logFlowPluginEvent(entryPointCard?.plugin_id, 'click to Connect now (page)', {
    blockId: node.id,
    cardId: entryPointCard?.id,
  });
  const controller = getFlowController();
  if (!controller) {
    return;
  }
  connectPage({
    botId: node.controller.flow.botId,
    urlParams: {
      [ConnectPageField.origin]:
        connectPageOrigin ?? ConnectPageOrigin.entryPoint,
      [ConnectPageField.botId]: node.controller.flow.botId,
      [ConnectPageField.blockId]: node.id,
      [ConnectPageField.cardId]: entryPointCard.id,
      [ConnectPageField.flowId]: node.controller.flow.id,
      [ConnectPageField.name]: entryPointCard.plugin_id,
    },
    onPageConnected: ({ status, pageId, verifiedPermissions, close }) => {
      controller.updateFlowBuilderPage(status, pageId, verifiedPermissions);
      onPageConnected?.({ status, pageId });
      close?.();
    },
  });
};
