import { ParameterFilterValueOperator } from '@globals';

export const blockOperationToString = (op: ParameterFilterValueOperator) => {
  if (op === 'not_seen') {
    return 'is not seen';
  }
  return 'is seen';
};

export const segmentOperationToString = (op: ParameterFilterValueOperator) => {
  if (op === 'is_not') {
    return 'is not';
  }
  return 'is';
};

export const attributeOperationToString = (
  op: ParameterFilterValueOperator,
  moreThanOneParam: boolean,
) => {
  switch (op) {
    case 'is':
      if (moreThanOneParam) {
        return 'in';
      }
      return 'is';
    case 'gt':
      return '>';
    case 'lt':
      return '<';
    case 'starts_with':
      return 'starts with';
    case 'is_not':
      return 'is not';
    case 'contains':
      return 'contains';
    default:
      return 'is';
  }
};

export const sequenceOperationToString = (op: ParameterFilterValueOperator) => {
  switch (op) {
    case 'was_not':
      return window.i18next.t('segmentation-string-1123-was-not');
    case 'was':
      return 'was';
    case 'is_not':
      return 'is not';
    default:
      return 'is';
  }
};
