import { HEXColors } from '@ui/_common/colors';
import { ShopifyEventType } from '@globals';
import { infoSvgTexture } from '../../../../../../assets/textures';
import {
  HLayout,
  MainLayout,
  VLayout,
} from '../../../../../../components/Elements/Layouts';
import {
  HTMLText,
  TextureShape,
} from '../../../../../../components/Elements/Shapes';
import { ValidationError } from '../../../../../../types';
import {
  removeTooltip,
  tooltipScaled,
} from '../../../../../helpers/TooltipHelpers';
import { pluginWidth } from '../../../../../plugin_consts';
import { getShopifyEventsWording } from './constants';
import { CircleIcon } from '../../../../../components/CircleIcon';

export interface ShopifyEventViewBaseProps {
  type: ShopifyEventType;
}

export abstract class ShopifyEventViewBase extends VLayout {
  private readonly infoIcon: MainLayout;

  protected constructor({ type }: ShopifyEventViewBaseProps) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 12,
        fill: HEXColors.greyLight30,
      },
    });

    const header = new HLayout();
    this.addToLayout(header, {
      margin: {
        top: 20,
        left: 20,
      },
    });

    const { title, description, icon } = getShopifyEventsWording(type);

    header.addToLayout(
      new MainLayout().addToLayout(
        new CircleIcon({
          icon,
        }),
        {
          margin: {
            top: -1,
            right: 8,
          },
        },
      ),
    );

    header.addToLayout(
      new HTMLText({
        text: title,
        fontSize: 15,
        trustedHtml: true,
      }),
      {
        margin: {
          right: 8,
          bottom: 16,
        },
      },
    );

    this.infoIcon = new MainLayout().addToLayout(
      new TextureShape({
        texture: infoSvgTexture,
        width: 16,
        height: 16,
      }),
    );

    tooltipScaled({
      view: this.infoIcon,
      text: description,
      overTimeout: 200,
    });

    header.addToLayout(this.infoIcon, {
      margin: {
        top: 3,
      },
    });
  }

  public abstract validationError(): ValidationError | undefined;

  destroy() {
    removeTooltip(this.infoIcon);
    super.destroy();
  }
}
