import React from 'react';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { ZapierTemplatesQuery_zapierTemplates } from '../@types/ZapierTemplatesQuery';
import { ZapierTemplateItemTooltipContent } from './ZapierTemplateItemTooltipContent';
import * as css from './ZapierTemplateItem.css';
import { ZapierTemplateSteps } from './ZapierTemplateSteps';

interface ZapierTemplateItemProps {
  template: ZapierTemplatesQuery_zapierTemplates;
  onTemplateClick: (template: ZapierTemplatesQuery_zapierTemplates) => void;
}

export const ZapierTemplateItem: React.FC<ZapierTemplateItemProps> = ({
  template,
  onTemplateClick,
}) => (
  <Tooltip2
    content={<ZapierTemplateItemTooltipContent template={template} />}
    placement="top"
    boundariesElement="viewport"
    type="small"
  >
    {(ref, bind) => (
      <ButtonUnstyled
        onClick={() => onTemplateClick(template)}
        className={css.item}
      >
        <Flex className={css.box} alignItems="center">
          <ZapierTemplateSteps
            steps={template.steps}
            ref={ref}
            shortMode
            {...bind}
          />
          <Spacer factor={1} horizontalFactor={2} />
          <div>
            <Type size="15px_DEPRECATED">{template.title}</Type>
          </div>
        </Flex>
      </ButtonUnstyled>
    )}
  </Tooltip2>
);
