import { Platform, QuickReplyType } from '@globals';

export const PHONE_PLACEHOLDER = '+1234567890';
export const EMAIL_PLACEHOLDER = 'name@email.com';
export const MAX_NUMBER_OF_QUICK_REPLIES = 13;

export const QUICK_REPLY_TYPES_WITH_ERROR_MESSAGES = [
  QuickReplyType.email,
  QuickReplyType.phone,
  QuickReplyType.number,
];

export const QUICK_REPLY_TYPE_SUPPORTED_PLATFORMS: Record<
  QuickReplyType,
  Platform[]
> = {
  [QuickReplyType.text]: [Platform.facebook, Platform.instagram],
  [QuickReplyType.number]: [Platform.facebook, Platform.instagram],
  [QuickReplyType.email]: [Platform.facebook, Platform.instagram],
  [QuickReplyType.phone]: [Platform.facebook, Platform.instagram],
  [QuickReplyType.date]: [Platform.facebook],
  [QuickReplyType.datetime]: [Platform.facebook],
};
