import i18next from 'i18next';
import {
  HLayout,
  VLayout,
} from '@components/FlowBuilder/components/Elements/Layouts';
import {
  HTMLText,
  TextureShape,
} from '@components/FlowBuilder/components/Elements/Shapes';
import { Node } from '@components/FlowBuilder/Node';
import { StatefulPlugin, Plugin } from '@components/FlowBuilder/StatefulPlugin';
import { IEntryPointView, PluginType } from '@components/FlowBuilder/types';
import { pluginWidth } from '@components/FlowBuilder/views/plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { PermissionGroup } from '@utils/Permissions';
import Maybe from 'graphql/tsutils/Maybe';
import {
  CommentsAutoreplyValidatorDelegate,
  PluginConfig as ValidatorPluginConfig,
} from '../CommentsAutoreplyValidator';
import { PostsSelector } from './PostsSelector';
import { getI18nCommentsAutoreplyKey } from '../utils/getI18nCommentsAutoreplyKey';
import { ReplyToByKeywordsGroups } from '../../common/components/ReplyToByKeywordGroups/ReplyToByKeywordsGroups';
import { LineParams } from '../../common/components/ReplyToByKeywordGroups/types';
import { Checkbox } from '@components/FlowBuilder/views/kit/Checkbox';
import { infoSvgTexture } from '@components/FlowBuilder/assets/textures';
import {
  removeTooltip,
  tooltipScaled,
} from '@components/FlowBuilder/views/helpers/TooltipHelpers';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { SetPluginResult } from '@components/FlowBuilder/StatefulPlugin/types';

interface CommentsAutoreplyCommonPlugin extends ValidatorPluginConfig {
  posts: Maybe<Array<unknown>>;
}

interface CommentsAutoreplyCommonOptions {
  validatorOptions: {
    postSelectorErrorMessage: string;
    commentsPermissionsErrorMessage: string;
    permissionGroup: PermissionGroup;
  };
  replyToOptions: { targetPlugin: PluginType; lineParams: LineParams };
  replyToForCurrentSubscribersOptions: {
    targetPlugin: PluginType;
    lineParams: LineParams;
  };
}

export abstract class CommentsAutoreplyCommon<
    T extends CommentsAutoreplyCommonPlugin,
  >
  extends VLayout
  implements IEntryPointView
{
  protected readonly validator: CommentsAutoreplyValidatorDelegate<T>;
  public readonly logger: PluginLogger;
  public readonly postSelector: PostsSelector<{ id: string }>;
  public readonly replyTo: ReplyToByKeywordsGroups;
  public readonly replyToForCurrentSubscribers: ReplyToByKeywordsGroups;
  private icon!: VLayout;

  constructor(
    public readonly state: StatefulPlugin<T>,
    public readonly _node: Node,
    private readonly options: CommentsAutoreplyCommonOptions,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });

    this.state = state;
    this.state.setDelegate(this);
    this.validator = new CommentsAutoreplyValidatorDelegate(
      this.options.validatorOptions.commentsPermissionsErrorMessage,
      this.options.validatorOptions.postSelectorErrorMessage,
      this.options.validatorOptions.permissionGroup,
    );
    this.validator.setDelegate(this);

    this.logger = new PluginLogger(
      this.state.data.plugin_id,
      this._node.id,
      this.state.data.id,
    );

    this.postSelector = this.getPostSelectorView();
    this.addToLayout(this.postSelector, {
      margin: { bottom: 16 },
    });

    this.addToLayout(
      new HTMLText({
        text: i18next.t(getI18nCommentsAutoreplyKey('reactToTitle')),
        fontSize: 15,
        fontStyle: 'semibold',
        trustedHtml: true,
      }),
      {
        margin: { bottom: 8 },
      },
    );

    this.replyTo = new ReplyToByKeywordsGroups(
      () => {
        const { data } = this.state;
        return {
          ...data,
          config: {
            keywords_setup: data.config.keywords_setup,
          },
        };
      },
      this._node,
      {
        defaultAnswerLabel: i18next.t(
          'EntryPointInstagramCommentsAutoreply-string-9431-all-comments',
        ),
        keywordsGroupLabel: i18next.t(
          'EntryPointInstagramCommentsAutoreply-string-1185-comments-with-keywords',
        ),
        statKey: `${this.state.data.id}_default_intent_new_users`,
        targetPlugin: this.options.replyToOptions.targetPlugin,
        lineParams: this.options.replyToOptions.lineParams,
        isEditing: () => !!this.state.isEditing,
        onChange: (keywordsSetup) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              keywords_setup: keywordsSetup,
            },
          }));
        },
        showFilterTypeSelector: true,
      },
    );
    this.addToLayout(this.replyTo);

    const separateReplies = new HLayout({
      cursor: {
        in: 'default',
      },
    });
    separateReplies.addToLayout(
      new Checkbox({
        text: i18next.t(
          getI18nCommentsAutoreplyKey('oldUsersFlowCheckboxLabel'),
        ),
        value: this.state.data.config.already_subscribed_setup_enabled,
        onChange: (value: boolean) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              already_subscribed_setup_enabled: value,
            },
          }));

          this.logger.log('Another reply for current subscribers change', {
            already_subscribed_setup_enabled: value,
          });
        },
        textFalse: undefined,
        textTrue: undefined,
        width: 280,
        multiline: true,
      }),
      {},
    );

    this.icon = new VLayout({}).addToLayout(
      new TextureShape({
        texture: infoSvgTexture,
        width: 16,
        height: 16,
      }),
    );

    tooltipScaled({
      view: this.icon,
      text: i18next.t(
        getI18nCommentsAutoreplyKey('oldUsersFlowCheckboxTooltip'),
      ),
    });

    separateReplies.addToLayout(this.icon, { margin: { left: 4 } });

    this.addToLayout(separateReplies, { margin: { top: 16 } });

    this.replyToForCurrentSubscribers = new ReplyToByKeywordsGroups(
      () => {
        const { data } = this.state;
        return {
          ...data,
          config: {
            keywords_setup: data.config.already_subscribed_keywords_setup,
          },
        };
      },
      this._node,
      {
        defaultAnswerLabel: i18next.t(
          'EntryPointCommentsAutoreply-string-9431-all-comments',
        ),
        keywordsGroupLabel: i18next.t(
          'EntryPointCommentsAutoreply-string-1185-comments-with-keywords',
        ),
        statKey: `${this.state.data.id}_default_intent_existing_users`,
        targetPlugin:
          this.options.replyToForCurrentSubscribersOptions.targetPlugin,
        lineParams: this.options.replyToForCurrentSubscribersOptions.lineParams,
        isEditing: () => !!this.state.isEditing,
        onChange: (keywordsSetup) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              already_subscribed_keywords_setup: keywordsSetup,
            },
          }));
        },
        showFilterTypeSelector: true,
      },
    );

    this.addToLayout(this.replyToForCurrentSubscribers, {
      margin: { top: 16 },
      gone: () => !this.state.data.config.already_subscribed_setup_enabled,
    });
  }

  hasSignificantChangesInConfig() {
    const { already_subscribed_keywords_setup, keywords_setup, posts } =
      this.state.data.config;

    return Boolean(
      already_subscribed_keywords_setup !== null ||
        keywords_setup?.intents?.length ||
        posts?.length,
    );
  }

  validationError() {
    return this.validator.validate();
  }

  pluginDidSave() {
    this.renderNode();
  }

  pluginDidSet(prevState: Plugin<T>, result: SetPluginResult<T>) {
    this.state.save();
    this.renderNode();

    if (prevState.config.posts?.length !== result.data.config.posts?.length) {
      this.postSelector.updatePostsList();
    }
  }

  destroy() {
    this.state.destroy();
    removeTooltip(this.icon);
    super.destroy();
  }

  abstract getPostSelectorView(): PostsSelector<{ id: string }>;
}
