import { ServiceMessageType, toaster } from '@services/MessageService';
import clipboardCopy from 'clipboard-copy';
import i18next from 'i18next';
import {
  zapierImportContentPluginFragment,
  zapierImportContentPluginFragment_config as ZapierImportContentPluginConfig,
} from '../../../../Plugins/ZapierImportContentPlugin/@types/zapierImportContentPluginFragment';
import { VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { ZapierIntegrationView } from '../../components/Zapier';
import { getI18ZapierKey } from '../../components/Zapier/utils/getI18ZapierKey';
import { ZapierCommentCardView } from '../../components/Zapier/ZapierCommentCardView/ZapierCommentCardView';
import { ZapierImportContentPreviewView } from './components/ZapierImportContentPreview/ZapierImportContentPreviewView';

export interface ZapierImportContentPluginCard
  extends zapierImportContentPluginFragment {
  isEditing?: boolean;
}

export class ZapierImportContentPlugin
  extends VLayout
  implements StatefulPluginDelegate<ZapierImportContentPluginConfig>
{
  public _node: Node;
  private state: StatefulPlugin<ZapierImportContentPluginConfig>;
  private readonly integrationView: ZapierIntegrationView;
  private readonly previewView: ZapierImportContentPreviewView;
  private readonly comment: ZapierCommentCardView;

  constructor(
    state: StatefulPlugin<ZapierImportContentPluginConfig>,
    node: Node,
  ) {
    super({});
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.integrationView = new ZapierIntegrationView({
      node,
      pluginId: state.data.plugin_id,
      cardId: state.data.id,
    });

    this.integrationView.updateConfigData(this.state.data.config);

    this.addToLayout(this.integrationView);

    this.previewView = new ZapierImportContentPreviewView({
      isEditing: () => this.state.isEditing,
    });

    this.updatePreviewData();

    this.addToLayout(this.previewView, {
      gone: () => !this.hasGalleryCards(),
    });

    this.comment = new ZapierCommentCardView();

    this.comment.on('click', (event: Event) => {
      if (!this.hasConnectedZaps() && !this.hasGalleryCards()) {
        event.stopPropagation();
        clipboardCopy(state.data.id);
        toaster.show({
          type: ServiceMessageType.default,
          payload: {
            message: i18next.t(getI18ZapierKey('cardIdCopied')),
          },
        });
      }
    });

    this.updateComment();

    this.addToLayout(this.comment, {
      gone: () => !this.hasGalleryCards() && this.hasConnectedZaps(),
    });
  }

  private updateComment() {
    let commentTextShortKey = '';
    if (!this.hasConnectedZaps()) {
      commentTextShortKey = 'importContentCardInstructions';
    }
    if (this.hasGalleryCards()) {
      commentTextShortKey = 'importContentGalleryComment';
    }
    this.comment.updateText(
      i18next.t(getI18ZapierKey(commentTextShortKey), {
        cardId: this.state.data.id,
      }),
    );
  }

  private updatePreviewData() {
    this.previewView.updateCards(
      this.state.data.config.preview?.gallery_cards || [],
    );
  }

  private hasGalleryCards() {
    return !!this.state.data.config.preview?.gallery_cards?.length;
  }

  private hasConnectedZaps() {
    return !!this.state.data.config.connected_zaps?.length;
  }

  pluginDidSet() {
    this.integrationView.updateConfigData(this.state.data.config);
    this.updatePreviewData();
    this.updateComment();
    this.renderNode();
  }
}
