import { logFlowPluginEvent } from '../utils/Analytics';
import { TextEditView } from './text_edit_view';
import { MainLayout } from '../components/Elements/Layouts';
import { createLineMenu } from './Menu/createLineMenu';
import { ControlVisibility } from '../FlowBuilderOverlay';
import { ItemStatView } from './kit/ItemStatView/ItemStatView';
import { getPredefinedAttributes } from './entry-points/common/utils/getPredefinedAttributes';
import { getFullCounterStats } from '../views/components/BlockStatsView/utils';
import { isEmptyString } from './validation';
import { PluginType } from '../../Plugins/common/PluginTypes';
import {
  mainStrokeColor,
  pluginWidth,
  qrBackgroundColor,
} from './plugin_consts';
import { LineStartView } from './components/Line/LineStartView';
import { buttonBlockId } from './button_block_id';

const COUNTER_ID_POSTFIX = {
  email: '_email',
  phone: '_phone',
  default: '_qr',
};

export const getCounterId = ({ id, config }) => {
  if (config.type === 'email' && config.email?.facebook_suggest) {
    return `${id}${COUNTER_ID_POSTFIX.email}`;
  }

  if (config.type === 'phone' && config.phone?.facebook_suggest) {
    return `${id}${COUNTER_ID_POSTFIX.phone}`;
  }

  return `${id}${COUNTER_ID_POSTFIX.default}`;
};

/**
 * TODO: to refactor as mentioned in the comment
 * https://github.com/chatfuel-lab/frontend/pull/526#discussion_r559482917
 */
export class QRButtonView extends MainLayout {
  TEST_NAME = 'QRButtonView';
  _config;
  _node;
  _textEditView;
  _lineView;
  _lineStartView;
  _withStats;
  itemStatView;

  gotoBlockId;
  updateCardViaDeprecatedMethod;

  constructor({
    name,
    buttonShapeName,
    buttonConfig,
    node,
    card,
    caption,
    editable,
    lineLayout,
    maxLength,
    isLineMandatory,
    onConnected,
    onRemoved,
    onTitleChange,
    onTitleChangeProcess,
    attributesControlVisibility = ControlVisibility.show,
    withStats = true,
    updateCardViaDeprecatedMethod = true,
    attributeOnly = false,
    hideLineStartView,
    isEditing,
  }) {
    let title = buttonConfig.title;
    if (isEmptyString(title)) {
      title = '';
    }

    super({
      name,
      backgroundName: buttonShapeName,
      title: 'QR Button view ' + buttonConfig.title,
      clipOutside: true,
      width: withStats ? pluginWidth - 32 : 200,
      height: 35,
      background: {
        cornerRadius: 17,
        fill: qrBackgroundColor,
        strokeWidth: 0,
        onhover: {
          stroke: mainStrokeColor,
          strokeWidth: 2,
        },
      },
    });
    this._config = buttonConfig;
    this._node = node;
    this._card = card;
    this._withStats = withStats;
    this.gotoBlockId = buttonBlockId(this._config);
    this.updateCardViaDeprecatedMethod = updateCardViaDeprecatedMethod;

    const props = {
      text: title,
      fontSize: 15,
      height: 35,
      maxLength: maxLength ?? 20,
      editable: () =>
        (card.isEditing ?? isEditing?.()) &&
        (typeof editable === 'undefined' || editable),
      attributeOnly,
      singleLine: true,
      fill: 'black',
      align: 'center',
      verticalAlign: 'center',
      caption:
        caption ?? window.i18next.t('qr_button_view-string-1103-set-title'),
      captionFill: '#de614d',
      ...(withStats
        ? {
            overlayWidth: pluginWidth - 32 - 48,
            overlayOffset: () => ({
              x: -(this.getStatsWidth() + 2),
              y: 0,
            }),
          }
        : { width: 160 }),
    };

    this._textEditView = new TextEditView(
      props,
      undefined,
      (newText) => {
        onTitleChangeProcess?.(newText, this.getQuickReplyIndex());
      },
      () => {
        let newText = this._textEditView.text().trim();
        if (buttonConfig.title !== newText) {
          onTitleChange?.(newText, this.getQuickReplyIndex());
          buttonConfig.title = newText;

          this.renderNode();
          if (updateCardViaDeprecatedMethod) {
            node.updateCard(card);
          }
          logFlowPluginEvent(card.plugin_id, 'update button title', {
            blockId: this._node.id,
            cardId: this._card.id,
            value: newText,
          });
        }
      },
      {
        emoji: ControlVisibility.show,
        attributes: attributesControlVisibility,
        discount: ControlVisibility.show,
      },
      getPredefinedAttributes(node.block, PluginType.quick_reply),
    );

    this.layout(this._textEditView, { margin: { left: 20 } });

    if (withStats) {
      const stats = getFullCounterStats(
        buttonConfig.counter_id || getCounterId(card),
        node.block.id,
      );

      if (stats) {
        this.itemStatView = new ItemStatView({
          stats,
          showPercentValue: true,
        });
        this.layout(this.itemStatView, {
          gone: () => this._textEditView._textAreaShown,
          margin: {
            top: 7,
            left: lineLayout ? 200 : 190,
          },
        });
      }
    }

    this._lineStartView = new LineStartView(
      {
        from: this,
        node: node,
        items: createLineMenu({ isShowConnectToExistingBlock: true }),
        mandatory: isLineMandatory,
        onConnected: (blockView) => {
          this.gotoBlockId = blockView.id();
          if (onConnected) {
            onConnected(blockView, this.getQuickReplyIndex());
          } else {
            this._config.block_ids = [blockView._node.id];
          }
          if (updateCardViaDeprecatedMethod) {
            node.updateCard(card);
          }
        },
        onRemoved: () => {
          this.gotoBlockId = null;
          if (onRemoved) {
            onRemoved(this.getQuickReplyIndex());
          } else {
            this._config.block_ids = [];
          }
          if (updateCardViaDeprecatedMethod) {
            node.updateCard(card);
          }
        },
      },
      card.id,
    );

    const leftMargin = withStats
      ? lineLayout?.margin?.left ?? pluginWidth - 32 - 18
      : lineLayout?.margin?.left ?? 200 - 20;

    this.layout(this._lineStartView, {
      verticalAlign: 'center',
      margin: { left: leftMargin },
      gone: () => hideLineStartView,
    });

    this.on('click', (e) => {
      e.stopPropagation();
      if (editable !== false) {
        this._textEditView.startEditing();
      }
    });
  }

  getQuickReplyIndex() {
    return this.parent?.getChildIndex(this) - 1;
  }

  setButtonShapeName(name) {
    super.setBackgroundName(name);
  }

  setTextAreaShapeName(name) {
    this._textEditView._textNode.shape().name = name;
  }

  onBeforeRender() {
    let title = this._config.title;
    if (isEmptyString(title)) {
      title = '';
    }

    this._node.addOutLink(
      this.updateCardViaDeprecatedMethod
        ? buttonBlockId(this._config)
        : this.gotoBlockId,
      this._lineStartView._lineView,
    );

    if (this._withStats) {
      const statViewWidth = this.getStatsWidth();

      this._textEditView._textNode.width(pluginWidth - 84 - statViewWidth * 2);
      if (!this._textEditView._textNode.layoutProperties.margin) {
        this._textEditView._textNode.layoutProperties.margin = {
          left: statViewWidth,
        };
      } else {
        this._textEditView._textNode.layoutProperties.margin.left =
          statViewWidth;
      }
    }

    this._textEditView.text(title);
  }

  focus() {
    this._textEditView.showTextArea();
  }

  getStatsWidth() {
    return this._textEditView.text() ? this.itemStatView?.textWidth ?? 0 : 0;
  }
}
