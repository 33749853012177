import { HEXColors } from '@ui/_common/colors';

interface ThemeProps {
  backgroundColor: HEXColors;
  backgroundStyle?: string;
  strokeColor?: HEXColors;
}

export class Theme {
  backgroundColor: HEXColors;
  backgroundStyle?: string;
  strokeColor: HEXColors;

  constructor({ backgroundColor, backgroundStyle, strokeColor }: ThemeProps) {
    this.backgroundColor = backgroundColor;
    this.strokeColor = strokeColor || backgroundColor;
    this.backgroundStyle = backgroundStyle;
  }
}

interface MarginProps {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  x?: number;
  y?: number;
}

export class Margin {
  top: number;
  left: number;
  right: number;
  bottom: number;

  constructor({ top, left, right, bottom, x, y }: MarginProps) {
    this.top = top || y || 0;
    this.bottom = bottom || y || 0;
    this.left = left || x || 0;
    this.right = right || x || 0;
  }

  get x() {
    return this.left + this.right;
  }

  get y() {
    return this.top + this.bottom;
  }
}
