import mergeWith from 'lodash-es/mergeWith';

interface ObjWithCounter {
  counterId?: string;
  counter_id?: string;
}

const assignCountersId = (
  obj: ObjWithCounter,
  srcObj: ObjWithCounter | undefined,
) => {
  if (!obj || !srcObj || obj.counter_id || obj.counterId) {
    return;
  }
  const { counter_id, counterId } = srcObj;
  if (counter_id) {
    // eslint-disable-next-line no-param-reassign
    obj.counter_id = counter_id;
  }
  if (counterId) {
    // eslint-disable-next-line no-param-reassign
    obj.counterId = counterId;
  }
};

export const mergeCounterIdData = (
  obj: ObjWithCounter,
  srcObj: ObjWithCounter,
) => {
  if (typeof obj !== 'object') {
    return srcObj;
  }
  return mergeWith(obj, srcObj, (subObj, subSrcObj) => {
    if (typeof subObj === 'string') {
      return subSrcObj;
    }
    if (typeof subObj !== 'object') {
      return undefined;
    }
    if (Array.isArray(subObj)) {
      subObj.forEach((objItem, i) => {
        assignCountersId(objItem, subSrcObj[i]);
        mergeCounterIdData(objItem, subSrcObj[i]);
      });
    } else {
      assignCountersId(subObj, subSrcObj);
      mergeCounterIdData(subObj, subSrcObj);
    }
    return subObj;
  });
};
