import httpStatus from 'http-status';
import { ApolloError } from 'apollo-client';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { StatefulPlugin } from '@components/FlowBuilder/StatefulPlugin';
import { Node } from '@components/FlowBuilder/Node';
import { resolveEntryPointConflict } from './resolveEntryPointConflict';

export interface Plugin<T extends {}> {
  _node: Node;
  state: StatefulPlugin<T>;
}

export const handleConflictError = <T extends {}>(
  error: ApolloError,
  plugin: Plugin<T>,
) => {
  const extractedGQLErrorData = extractGQLErrorData(error);
  const isConflictError = extractedGQLErrorData?.status === httpStatus.CONFLICT;
  if (isConflictError && extractedGQLErrorData?.conflictingData) {
    const toggleView = plugin._node.blockView._toggle;
    if (!toggleView) {
      return;
    }
    plugin.state.set({ enabled: false });
    toggleView.setValue(false);
    toggleView.renderNode();

    resolveEntryPointConflict(
      plugin._node,
      extractedGQLErrorData?.conflictingData,
      plugin.state.data,
    );
  }
};
