import i18next from 'i18next';
import { logFlowEvent } from '../utils/Analytics';
import {
  removeTooltip,
  tooltip,
  tooltipScaled,
  hideTooltip,
} from './helpers/TooltipHelpers';
import { HLayout } from '../components/Elements/Layouts';
import {
  controlImage,
  controlLayout,
  controlMargin,
  controlWidth,
} from './card_control_view';
import {
  duplicateSvgTexture,
  menuSvgTexture,
  noteSvgTexture,
  removeSvgTexture,
  testSvgTexture,
  testInstagramTexture,
  testWhatsappTexture,
} from '../assets/textures';
import { CreateMenuViewOverlay } from './menu_view_overlay';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../PixiFieldRepository';
import { removeEntity, showUpdateMessageTagDialog } from '../components';
import { BLOCK_SUBTYPES, isRootBlock } from '../consts';
import { updateMessageTag } from './components/MessageTag';
import { showTestOverlay } from './kit/showTestOverlay';
import { startTestEntryPoint } from '@components/TestEntryPoints/dialogs/TestThisFlowDialog';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { toaster, ServiceMessageType } from '@services/MessageService';
import {
  blockControlViewEmitter,
  BlockControlViewEmitterEvent,
} from './block_control_view_emitter';
import { tooltips } from './Menu/menu_view';
import { getEntryPointCard } from './entry-points/common/utils';
import { showChooseFlowDialog } from '../components';
import { cloneFlowBlocks } from '../api/cloneFlowBlocks';
import { blockWidth } from './plugin_consts';
import {
  BotTabs,
  getCurrentBroadcastId,
  getTabLink,
  globalHistory,
} from '@utils/Routing';
import { getFlowPlatform } from '@components/FlowBuilder/utils/getFlowPlatform';
import { isWhatsappBroadcastFlow } from '@components/FlowBuilder/utils/isWhatsappBroadcastFlow';
import { BlockContextType, Platform } from '@globals';
import { PluginType } from '../../Plugins/common/PluginTypes';
import { WITH_MESSAGE_TAG_BLOCKS } from './block_title_view';

const getCopyPasteHotKeysLabel = () => {
  const copyPasteKey = window.navigator.appVersion.includes('Mac')
    ? 'Cmd'
    : 'Ctrl';

  return `${copyPasteKey}+C / ${copyPasteKey}+V`;
};

const DISABLE_BLOCK_DELETION_TOOLTIP = () =>
  i18next.t(
    'modernComponents.FlowBuilder.views.blockControlView.deletionTooltip',
  );

const NOT_TESTABLE_BLOCKS = [BLOCK_SUBTYPES.calendly];
const NOT_TESTABLE_ENTRY_POINTS = [
  PluginType.instagram_ads_manager_ctm_entry_point,
  PluginType.instagram_comments_autoreply_entry_point,
  PluginType.instagram_direct_entry_point,
  PluginType.instagram_story_mention_entry_point,
  PluginType.instagram_story_reply_entry_point,
  PluginType.instagram_bot_link_entry_point,
  PluginType.shopify_event_entry_point,
  PluginType.shopify_customer_chat_entry_point,
  PluginType.shopify_back_in_stock,
  PluginType.inbound_links_entry_point,
  PluginType.whatsapp_bot_link,
  PluginType.whatsapp_direct_entry_point,
  PluginType.whatsapp_widget_entry_point,
  PluginType.whatsapp_popup_entry_point,
];

export class BlockControlView extends HLayout {
  TEST_NAME = 'BlockControlView';
  _clone;
  _remove;
  _test;
  _menu;
  _lastShownBlock;
  _privateNoteCrating = false;
  _blockDuplicateInProgress = false;

  constructor() {
    super({
      height: controlWidth,
      cursor: { in: 'pointer' },
      itemsOffset: controlMargin,
      background: {
        cornerRadius: 5,
        fill: 'white',
        stroke: '#f0f0f0',
        strokeWidth: 1,
      },
    });
    this.visible = !getPixiFieldStrict().isViewOnly();
    this._remove = controlLayout(removeSvgTexture);
    this._menu = controlLayout(menuSvgTexture);
    this._note = controlLayout(noteSvgTexture);
    this._duplicate = controlLayout(duplicateSvgTexture);

    const platformTexture = {
      [Platform.facebook]: testSvgTexture,
      [Platform.instagram]: testInstagramTexture,
      [Platform.whatsapp]: testWhatsappTexture,
    }[getFlowPlatform()];

    tooltipScaled({
      view: this._duplicate,
      text: i18next.t(
        'modernComponents.FlowBuilder.views.blockControlView.duplicate',
        { copyPasteShortcut: getCopyPasteHotKeysLabel() },
      ),
      position: 'top-start',
    });

    this.layout(this._note, {
      margin: { left: controlMargin, top: controlMargin },
    })
      .layout(this._duplicate, {
        margin: { left: controlMargin, top: controlMargin },
        gone: () => this.isRootBlock,
      })
      .layout(this._remove, {
        margin: { left: controlMargin, top: controlMargin },
      });

    if (!isWhiteLabelDomain() && !getCurrentBroadcastId()) {
      this._test = controlLayout(platformTexture);
      this.layout(this._test, {
        margin: { left: controlMargin, top: controlMargin },
        gone: () =>
          NOT_TESTABLE_BLOCKS.includes(this._view?._node.block.subtype) ||
          NOT_TESTABLE_ENTRY_POINTS.includes(
            getEntryPointCard(this._view?._node.block.cards)?.plugin_id,
          ),
      });
      tooltipScaled({
        view: this._test,
        text: i18next.t(
          'block_control_view-string-2032-test-your-bot-starting-with-this-block',
        ),
        position: 'top-start',
      });
      this.renderNode();
      this._test.on('pointerdown', (e) => {
        e.stopPropagation();
      });
      this._test.on('click', (e) => {
        e.stopPropagation();
        hideTooltip();
        const blockId = this._view._node.id;
        logFlowEvent(this.isEntryPoint() ? 'entry_point' : 'block', 'test', {
          blockId,
        });

        if (startTestEntryPoint(this._view._node)) {
          return;
        }
        showTestOverlay(blockId, e.data.global, () => {
          this.hide();
        });
      });
    }

    this.layout(this._menu, {
      margin: { top: controlMargin, right: controlMargin },
    });

    this._remove.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._remove.on('click', (e) => {
      e.stopPropagation();
      this.deleteBlock(this._view);
    });

    this._note.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._note.on('click', (e) => {
      e.stopPropagation();
      this.createPrivateNote();
    });

    this._duplicate.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._duplicate.on('click', (e) => {
      e.stopPropagation();
      logFlowEvent(
        'block control menu',
        'duplicate block',
        this.getCurrentBotFlowBlockInfo(),
      );
      this.handleBlockDuplicate();
    });

    this._menu.on('pointerdown', (e) => {
      e.stopPropagation();
    });

    this._menu.on('click', (e) => {
      const { subtype, context_type } = this._view._node.block;
      e.stopPropagation();
      let items = [];
      if (
        !this._remove.inactive &&
        ![
          BLOCK_SUBTYPES.entrypoint,
          BLOCK_SUBTYPES.comments_autoreply_content,
          BLOCK_SUBTYPES.instagram_comments_autoreply_content,
        ].includes(subtype) &&
        !isRootBlock(subtype) &&
        !isWhatsappBroadcastFlow() &&
        context_type !== BlockContextType.comments
      ) {
        items.push({
          title: i18next.t(
            'block_control_view-string--135-mark-as-starting-step',
          ),
          id: 'starting_step',
        });
      }
      const messageTagPlatform = {
        [Platform.facebook]: true,
        [Platform.instagram]: false,
        // TODO
        [Platform.whatsapp]: false,
      }[getFlowPlatform()];

      if (
        messageTagPlatform &&
        WITH_MESSAGE_TAG_BLOCKS.includes(this._view._node.block.subtype)
      ) {
        items.push({
          title: i18next.t('block_control_view-string-1308-choose-message-tag'),
          id: 'message_tag',
          tooltip: {
            html: i18next.t(
              'modernComponents.FlowBuilder.views.blockControlView.chooseTagTooltip',
              {
                helpDocUrl: i18next.t('common.helpDocLinks.message_tags'),
              },
            ),
          },
        });
      }
      items.push({
        title: i18next.t('block_control_view-string--182-edit-title'),
        id: 'edit_title',
      });
      items.push({
        title: i18next.t('block_control_view-string--156-add-private-note'),
        id: 'add_note',
        tooltip: tooltips().comment,
      });

      if (!this.isRootBlock) {
        items.push({
          title: i18next.t('block_control_view-string--108-duplicate'),
          id: 'duplicate',
        });
        items.push({
          title: `${i18next.t(
            'block_control_view-Template-1000-copy-to',
          )}${getCopyPasteHotKeysLabel()})`,
          id: 'copy_to',
        });
      }
      if (!this._view._node.startingPoint && !this.isRootBlock) {
        items.push({
          title: i18next.t('block_control_view-string-2043-delete'),
          id: 'delete',
          inactive: this._view._node.startingPoint,
          tooltip: this._view._node.startingPoint
            ? { html: DISABLE_BLOCK_DELETION_TOOLTIP() }
            : undefined,
        });
      }

      new CreateMenuViewOverlay({
        onChoose: async (item) => {
          switch (item.id) {
            case 'edit_title':
              logFlowEvent(
                this.isEntryPoint() ? 'entry_point' : 'block',
                'edit title',
                {
                  source: 'menu',
                  blockId: this._view._node.id,
                },
              );
              this._view._titleView.startEditing();
              break;
            case 'starting_step':
              logFlowEvent(
                this.isEntryPoint() ? 'entry_point' : 'block',
                'mark starting',
                {
                  blockId: this._view._node.id,
                },
              );
              getFlowControllerStrict().markStartingPoint(this._view._node);
              break;
            case 'add_note':
              this.createPrivateNote();
              break;
            case 'message_tag':
              showUpdateMessageTagDialog({
                defaultValue:
                  this._view._node.block.notification_topic_id ||
                  this._view._node.block.otn_purpose ||
                  this._view._node.block.message_tag,
                onChange: (item) => {
                  updateMessageTag(this._view._node, item.id, item.tagType);
                  this._view.renderNode();
                  this.renderNode();
                },
              });
              break;
            case 'duplicate':
              logFlowEvent(
                'block context menu',
                'duplicate block',
                this.getCurrentBotFlowBlockInfo(),
              );
              this.handleBlockDuplicate();
              break;
            case 'copy_to':
              logFlowEvent(
                'block context menu',
                'open paste block modal dialog',
                this.getCurrentBotFlowBlockInfo(),
              );
              showChooseFlowDialog({
                botId: getFlowControllerStrict().flow.botId ?? '',
                platform: getFlowPlatform(),
                flowFilter: (flow) =>
                  flow.platform === getFlowControllerStrict().flow.platform,
                onSelectExisting: (toFlowId, { botId: toBotId, title }) => {
                  const { id: flowId, botId } = getFlowControllerStrict().flow;
                  const sameFlow = flowId === toFlowId && botId === toBotId;
                  if (sameFlow) {
                    logFlowEvent(
                      'paste block modal dialog',
                      'duplicate block',
                      this.getCurrentBotFlowBlockInfo(),
                    );
                    this.handleBlockDuplicate();
                  } else {
                    logFlowEvent('paste block modal dialog', 'copy block to', {
                      ...this.getCurrentBotFlowBlockInfo(),
                      toBotId,
                      toFlowId,
                    });
                    this.cloneBlockAndShowRedirectToaster(
                      toBotId,
                      toFlowId,
                      title,
                    );
                  }
                },
                onCreateNewFlow: (newFlowId, { title, botId: toBotId }) => {
                  logFlowEvent('modal dialog', 'copy block to new flow', {
                    ...this.getCurrentBotFlowBlockInfo(),
                    newFlowId,
                    toBotId,
                  });
                  this.cloneBlockAndShowRedirectToaster(
                    toBotId,
                    newFlowId,
                    title,
                  );
                },
              });
              break;
            case 'delete':
              this.deleteBlock(this._view);
              break;
            default:
              break;
          }

          this.renderNode();
        },
        items,
      }).showOn(e.data.global);
    });
  }

  get isRootBlock() {
    return isRootBlock(this._view?._node.block.subtype);
  }

  deleteBlock(view) {
    this.hide();
    const isEntryPoint = view._node.block.subtype === BLOCK_SUBTYPES.entrypoint;
    logFlowEvent(isEntryPoint ? 'entry_point' : 'block', 'remove', {
      blockId: view._node.id,
    });
    let hasChanges = true;
    try {
      hasChanges =
        view.hasSignificantChangesInConfig &&
        view.hasSignificantChangesInConfig();
    } catch (e) {
      console.error(e);
    }
    if (hasChanges) {
      removeEntity({
        onSubmit: () => {
          getFlowControllerStrict().removeNode(view._node);
        },
        renderActionText: () =>
          i18next.t('block_control_view-string-2043-delete'),
        renderHeading: () =>
          `${i18next.t(
            'block_control_view-Template-8843-delete',
          )}${view._titleView.text()}" ${
            isEntryPoint
              ? i18next.t('block_control_view-string--964-entry-point')
              : 'Block'
          }`,
      });
    } else {
      getFlowControllerStrict().removeNode(view._node);
    }
  }

  setView(blockView) {
    this._view = blockView;
    let isStartingPoint = blockView._node.startingPoint;
    this._remove.inactive = isStartingPoint || this.isRootBlock;
    if (isStartingPoint) {
      tooltip(this._remove, DISABLE_BLOCK_DELETION_TOOLTIP());
    } else {
      removeTooltip(this._remove);
    }
    tooltipScaled({
      view: this._note,
      text: tooltips().comment.html,
      position: 'top-start',
    });
    this._remove.renderNode();
    this.onShow();
  }

  onShow() {
    if (this._lastShownBlock) {
      this.onHide(this._lastShownBlock);
    }
    this._lastShownBlock = this._view;
    blockControlViewEmitter.emit(BlockControlViewEmitterEvent.show, {
      blockId: this._view._node.block.id,
    });
  }

  onHide(blockView) {
    blockControlViewEmitter.emit(BlockControlViewEmitterEvent.hide, {
      blockId: blockView._node.block.id,
    });
  }

  isEntryPoint() {
    return this._view._node.block.subtype === BLOCK_SUBTYPES.entrypoint;
  }

  async handleBlockDuplicate() {
    if (this._blockDuplicateInProgress) {
      return;
    }
    this._blockDuplicateInProgress = true;
    this._duplicate.alpha = 0.3;
    const { x, y } = this._view._node;
    const controller = getFlowControllerStrict();
    const margin = 30;
    const nearBlockOffset = margin + blockWidth;
    const nearPosition = { x: x + nearBlockOffset, y };
    const loadingView = controller.addLoadingView(nearPosition);
    this.renderNode();
    const block = await this.handleCloneBlock(controller.flow.id, nearPosition);
    await controller.onBlockAdded(block);
    controller.removeLoadingView(loadingView);
    this._blockDuplicateInProgress = false;
    this._duplicate.alpha = 1;
    this.renderNode();
  }

  async cloneBlockAndShowRedirectToaster(toBotId, toFlowId, title) {
    const block = await this.handleCloneBlock(toFlowId);
    toaster.show({
      type: ServiceMessageType.default,
      timeout: 5000,
      payload: {
        message: `${i18next.t(
          'block_control_view-Template--151-copied-to-the',
        )}${title}${i18next.t('block_control_view-Template-1013-flow')}`,
        buttonLabel: i18next.t('block_control_view-string--354-go-to-block'),
        onButtonClick: () => {
          logFlowEvent('toaster', 'go to copied block', {
            ...this.getCurrentBotFlowBlockInfo(),
            toBotId,
            toFlowId,
          });
          globalHistory.push(
            `${getTabLink(BotTabs.flows, toBotId, {
              flowId: toFlowId,
            })}?blockId=${block.id}`,
          );
        },
      },
    });
  }

  async handleCloneBlock(toFlowId, position) {
    const { id } = this._view._node;
    const info = this.getCurrentBotFlowBlockInfo();
    try {
      const { blocks, errors } = await cloneFlowBlocks(
        info.flowId,
        toFlowId,
        [{ id, position }],
        info,
      );
      if (errors?.length || !blocks.length) {
        throw new Error('Cloning block failed!');
      }
      const isSameFlow = getFlowControllerStrict().flow.id === toFlowId;
      const oneOfBlocksIsEntryPoint = blocks.some(
        ({ subtype }) => subtype === BLOCK_SUBTYPES.entrypoint,
      );
      return blocks[0];
    } catch {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: i18next.t(
            'block_control_view-string-3030-couldnt-copy-the-block-try-again-later',
          ),
        },
      });
    }
  }

  getCurrentBotFlowBlockInfo() {
    return {
      blockId: this._view._node.id,
      botId: getFlowControllerStrict().flow.botId,
      flowId: getFlowControllerStrict().flow.id,
    };
  }

  createPrivateNote() {
    if (this._privateNoteCrating) {
      return;
    }
    this._privateNoteCrating = true;
    logFlowEvent(this.isEntryPoint() ? 'entry_point' : 'block', 'add note', {
      blockId: this._view._node.id,
    });
    this._note.alpha = 0.3;
    this.renderNode();
    this._view.addPlugin('comment', 0, () => {
      this._privateNoteCrating = false;
      this._note.alpha = 1;
      this.renderNode();
    });
  }
}
