import noop from 'lodash-es/noop';
import { TextEditView } from './text_edit_view';
import { DownArrow } from '../components/Elements/Shapes';
import {
  inactiveStrokeColor,
  invalidStrokeColor,
  textCardBackgroundColor,
} from './plugin_consts';
import { Card } from '../types';
import Maybe from 'graphql/tsutils/Maybe';
import { HEXColors } from '@ui/_common/colors';

interface DropdownFieldViewParams {
  width: number;
  caption: string;
  isValid?: () => boolean;
  singleLine?: boolean;
  card?: Maybe<Card>;
  isDisabled?: () => boolean | void;
  isEditing?: Maybe<() => boolean>;
}

export class DropdownFieldView extends TextEditView {
  TEST_NAME = 'DropdownFieldView';

  constructor({
    width,
    caption,
    isValid,
    singleLine = false,
    card = null,
    isDisabled = noop,
    isEditing: isEditingFunc = null,
  }: DropdownFieldViewParams) {
    const isEditing = () => card?.isEditing ?? isEditingFunc?.();

    super({
      width,
      height: 35,
      fontSize: 15,
      editable: false,
      fill: HEXColors.black,
      singleLine,
      verticalAlign: 'center',
      margin: { left: 10, right: 10 },
      caption,
      captionFill: inactiveStrokeColor,
      background: {
        fill: () =>
          isEditing() && !isDisabled()
            ? HEXColors.white
            : textCardBackgroundColor,
        cornerRadius: 5,
        stroke: () => (isValid?.() ? inactiveStrokeColor : invalidStrokeColor),
        strokeWidth: () =>
          isEditing() && !isDisabled() ? (isValid?.() ? 1 : 2) : 0,
      },
    });

    this.layout(
      new DownArrow({
        width: 10,
        height: 8,
      }),
      {
        margin: { left: width - 23, top: 13.5 },
        gone: () => Boolean(!isEditing() || isDisabled()),
      },
    );
  }
}
