import {
  getFullCounterStats,
  isFlowStatsEnabled,
} from '../../../../components/BlockStatsView/utils';
import { CustomerChatStats } from './types';

export const prepareCustomerChatStats = (cardId: string, blockId: string) =>
  isFlowStatsEnabled()
    ? ({
        statGuest: getFullCounterStats(`${cardId}_guest`, blockId),
        statNonGuest: getFullCounterStats(`${cardId}_non_guest`, blockId),
        statUpgrade: getFullCounterStats(`${cardId}_upgrade`, blockId),
      } as CustomerChatStats)
    : undefined;
