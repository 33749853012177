import memoize from 'lodash-es/memoize';
import i18next from 'i18next';
import { Item } from '../../kit/Dropdown';
import {
  ProductListType,
  ProductListVisitedProductsRecommendationType,
} from '@globals';
import { BACK_IN_STOCK_DEFAULT_CONFIG } from '../../../../Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { PLUGIN_SHOPIFY_PRODUCT_LIST_DEFAULT_CONFIG } from '../../../../Plugins/ProductListPlugin/ProductListPluginConst';

export const NONE_ID = 'none';

export const DEFAULT_CONFIG_BY_TYPE: Record<ProductListType, object> = {
  [ProductListType.order_content]: PLUGIN_SHOPIFY_PRODUCT_LIST_DEFAULT_CONFIG,
  [ProductListType.static]: PLUGIN_SHOPIFY_PRODUCT_LIST_DEFAULT_CONFIG,
  [ProductListType.visited_products]:
    PLUGIN_SHOPIFY_PRODUCT_LIST_DEFAULT_CONFIG,
  [ProductListType.back_in_stock]: BACK_IN_STOCK_DEFAULT_CONFIG,
};

export const PATH_BY_TYPE: Record<ProductListType, string[]> = {
  [ProductListType.order_content]: ['order', 'shopify_order'],
  [ProductListType.static]: ['products'],
  [ProductListType.visited_products]: ['visited_products'],
  [ProductListType.back_in_stock]: ['back_in_stock'],
};

export const getRelatedItems = memoize(
  () =>
    [
      {
        id: NONE_ID,
        title: i18next.t('shopify.visitedProducts.related.none'),
      },
      {
        id: ProductListVisitedProductsRecommendationType.recommended,
        title: i18next.t('shopify.visitedProducts.related.recommended'),
      },
      {
        id: ProductListVisitedProductsRecommendationType.similar,
        title: i18next.t('shopify.visitedProducts.related.similarTags'),
      },
    ] as Item[],
);
