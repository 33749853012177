import { MainLayout } from '../../../../components/Elements/Layouts';
import { TextureShape } from '../../../../components/Elements/Shapes';
import type { Texture } from 'pixi.js-legacy';

interface IconButtonViewProps {
  texture: Texture;
  size?: number;
}

const DEFAULT_ICON_BUTTON_SIZE = 16;

export class IconButtonView extends MainLayout {
  TEST_NAME = 'IconButton';

  constructor({ size, texture }: IconButtonViewProps) {
    super({
      cursor: {
        in: 'pointer',
      },
    });

    const width = size || DEFAULT_ICON_BUTTON_SIZE;
    const height = size || DEFAULT_ICON_BUTTON_SIZE;

    this.layout(
      new TextureShape({
        texture,
        width,
        height,
      }),
    );
  }
}
