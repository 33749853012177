import {
  Alignment,
  View,
  VLayout,
} from '@components/FlowBuilder/components/Elements/Layouts';
import { TextEditView } from '../../text_edit_view';
import { fontFamily } from '@components/FlowBuilder/consts';
import { HEXColors } from '@ui/_common/colors';
import { Margin } from '../../utility_classes';
import { WhatsappListLayoutProps } from './types';
import { Node } from '@components/FlowBuilder/Node';
import { TextCardButtonView } from '../../text_card_button_view';
import { buttonControl } from '../../helpers/ControlsHelpers';
import { PlusButtonView } from '../../plus_button_view';
import { InteractionEvent } from 'pixi.js-legacy';
import { StatefulPlugin } from '@components/FlowBuilder/StatefulPlugin';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { clone } from 'ramda';
import { resByFunc, swapImmutable } from '../../utils';
import { DividerView } from './DividerView';
import {
  whatsappListPluginFragment_config,
  whatsappListPluginFragment_config_listSections as ListSections,
  whatsappListPluginFragment_config_listSections_rows,
} from '../../../../Plugins/WhatsappListPlugin/@types/whatsappListPluginFragment';
import { DEFAULT_ROW } from '../../../../Plugins/WhatsappListPlugin/WhatsappListPluginConst';
import { buttonHeight } from '../../plugin_consts';
import { PropFunc } from '../../../types';
import i18next from 'i18next';
import { getButtonsAmount } from './utils';
import { BUTTONS_LIMIT } from './consts';
import { ControlVisibility } from '@components/FlowBuilder/FlowBuilderOverlay';

export interface WhatsappListSectionParams extends WhatsappListLayoutProps {
  index: PropFunc<number>;
}

export class WhatsappListSectionItemView extends VLayout {
  private titleText: string = '';
  private readonly state: StatefulPlugin<whatsappListPluginFragment_config>;
  private readonly logger: PluginLogger;
  private readonly props: WhatsappListSectionParams;
  private readonly node: Node;
  private _buttonsLayout!: VLayout;
  private touched: boolean = false;

  constructor(
    state: StatefulPlugin<whatsappListPluginFragment_config>,
    logger: PluginLogger,
    props: WhatsappListSectionParams,
    node: Node,
  ) {
    super(props);

    this.state = state;
    this.logger = logger;
    this.props = props;
    this.node = node;

    this.addToLayout(this.renderView());
  }

  private isLastSection() {
    return (this.state.data.config.listSections?.length ?? 0) < 2;
  }

  private renderView() {
    const sectionBlock = new VLayout();

    sectionBlock
      .addToLayout(this.renderTitleInput(), {
        margin: { bottom: 16 },
        gone: () => {
          if (this.isLastSection()) {
            if (this.sectionConfig?.title?.length) {
              return false;
            }

            return !this.touched;
          }

          return false;
        },
      })
      .addToLayout(this.renderButtons())
      .addToLayout(this.renderPlusButton(), {
        margin: { bottom: 16 },
        gone: () =>
          getButtonsAmount(this.state.data.config.listSections) >=
            BUTTONS_LIMIT || !this.state.isEditing,
      })
      .addToLayout(
        new DividerView({ width: this.props.width, fill: HEXColors.white }),
        {
          margin: { bottom: 16 },
          gone: () => {
            const index = resByFunc(this.props.index, this);
            return (
              index ===
                (this.state.data.config.listSections?.length ?? 0) - 1 &&
              !this.state.isEditing
            );
          },
        },
      );

    return sectionBlock;
  }

  private get sectionConfig(): ListSections | undefined {
    const index = resByFunc(this.props.index, this);
    return this.state.data.config.listSections?.[index];
  }

  private renderTitleInput() {
    return new TextEditView(
      {
        text: this.sectionConfig?.title || '',
        fontFamily,
        fontSize: 15,
        height: 36,
        fill: HEXColors.black,
        maxLength: 24,
        verticalAlign: 'center',
        width: this.props.width,
        caption: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.sectionTitle',
        ),
        captionFill: () =>
          this.isLastSection()
            ? HEXColors.cobaltTertiary
            : HEXColors.accent4Normal,
        singleLine: true,
        margin: new Margin({ x: 12 }),
        attributeOnly: false,
        background: {
          fill: HEXColors.white,
          stroke: () =>
            this.isLastSection()
              ? HEXColors.cobaltTertiary
              : HEXColors.accent4Secondary,
          strokeWidth: () =>
            this.isLastSection() ? 0 : this.sectionConfig?.title ? 0 : 2,
          cornerRadius: 4,
          strokeAlignment: Alignment.outter,
        },
        editable: () => this.state.isEditing,
      },
      undefined,
      (title) => {
        this.titleText = title.trim();
        this.touched = true;
      },
      () => {
        this.state.setAndSave(({ config }) => {
          const listSections = clone(config.listSections || []);
          listSections[resByFunc(this.props.index, this)] = {
            ...this.sectionConfig!,
            title: this.titleText.length ? this.titleText : null,
          };
          return { config: { ...config, listSections } };
        });
        this.logger.log('update section title text', {
          value: this.sectionConfig?.title,
        });
      },
      {
        symbolsLimit: ControlVisibility.show,
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
      },
    );
  }

  private addControl(buttonView: TextCardButtonView) {
    buttonControl(
      buttonView,
      this._buttonsLayout,
      () => {
        this.node.updateLines();
        this.renderNode();
      },
      () => {
        this.state.setAndSave(({ config }) => {
          const index = this._buttonsLayout.indexOf(buttonView);
          const listSections = clone(config.listSections || []);
          listSections[resByFunc(this.props.index, this)].rows?.splice(
            index,
            1,
          );
          return { config: { ...config, listSections } };
        });
        this._buttonsLayout.removeView(buttonView);
        buttonView.destroy();
        this.renderNode();

        this.logger.log('remove button from section');
      },
      (_: View, oldIndex, newIndex) => {
        this.state.setAndSave(({ config }) => {
          const listSections = clone(config.listSections || []);
          const section = listSections[resByFunc(this.props.index, this)];
          section.rows = swapImmutable(section.rows || [], oldIndex, newIndex);
          return { config: { ...config, listSections } };
        });
      },
      undefined,
      undefined,
      true,
    );
  }

  private createTextCardButtonView(
    config: whatsappListPluginFragment_config_listSections_rows,
    index: number,
  ) {
    return new TextCardButtonView({
      config,
      node: this.node,
      isEditing: () => this.state.isEditing,
      card: { config: this.sectionConfig },
      hideCallPhoneOption: true,
      hidePaymentOption: true,
      hideButtonPopupIfTargetBlockSelected: false,
      buttonIndex: index,
      galleryIndex: 0,
      maxLength: 24,
      onConfigChanged: ({
        title,
        description,
      }: whatsappListPluginFragment_config_listSections_rows) => {
        this.state.set(({ config }) => {
          const listSections = clone(config.listSections || []);
          const { rows } = listSections[resByFunc(this.props.index, this)];
          const buttonConfig = rows?.[index]!;
          rows![index] = {
            ...clone(DEFAULT_ROW),
            ...buttonConfig,
            title: title.trim(),
            description: description?.trim().length ? description : null,
          };

          return { config: { ...config, listSections } };
        });
      },
      onDoneEditing: () => {
        this.state.save();
        this.logger.log('section button update');
      },
      onConnected: (
        _: unknown,
        { block_id }: whatsappListPluginFragment_config_listSections_rows,
      ) => {
        this.state.setAndSave(({ config }) => {
          const listSections = clone(config.listSections || []);
          const { rows } = listSections[resByFunc(this.props.index, this)];
          const row = rows?.[index]!;
          rows![index] = {
            ...row,
            block_id,
          };
          return { config: { ...config, listSections } };
        });
      },
      onDisconnected: () => {
        this.state.setAndSave(({ config }) => {
          const listSections = clone(config.listSections || []);
          const { rows } = listSections[resByFunc(this.props.index, this)];
          const row = rows?.[index]!;
          rows![index] = {
            ...row,
            block_id: [],
          };

          return { config: { ...config, listSections } };
        });
      },
      disablePaymentOption: true,
      containerName: '',
      buttonEditName: '',
      flags: {},
      corners: () => {
        const radius = config.description ? 28 : 18;
        return {
          bottomLeft: radius,
          bottomRight: radius,
          topLeft: radius,
          topRight: radius,
        };
      },
      height: () => (config.description ? 52 : buttonHeight),
      placeholder: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.sectionButtonTitle',
      ),
    });
  }

  private renderButtons() {
    this._buttonsLayout = new VLayout();

    this.sectionConfig?.rows?.forEach((button, index) => {
      const buttonView = this.createTextCardButtonView(button, index);
      this._buttonsLayout.layout(buttonView, {
        margin: { bottom: 16 },
      });
      this.addControl(buttonView);
    });

    return this._buttonsLayout;
  }

  private renderPlusButton() {
    const button = new PlusButtonView(
      i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.addButton',
      ),
      this.props.width,
      36,
    );

    button.on('pointerdown', (e: MouseEvent) => {
      e.stopPropagation();
    });

    button.on('click', (e: InteractionEvent) => {
      e.stopPropagation();
      const buttonConfig: whatsappListPluginFragment_config_listSections_rows =
        clone(DEFAULT_ROW);
      this.state.setAndSave(({ config }) => {
        const listSections = clone(this.state.data.config.listSections || []);
        listSections[resByFunc(this.props.index, this)].rows?.push(
          buttonConfig,
        );
        return { config: { ...config, listSections } };
      });
      const index = this._buttonsLayout.children.length - 1 ?? 1;
      const buttonView = this.createTextCardButtonView(buttonConfig, index);
      this._buttonsLayout.addToLayout(buttonView, {
        margin: { bottom: 16 },
      });
      this.addControl(buttonView);
      buttonView.focus();
      this.renderNode();
    });

    return button;
  }
}
