import { VLayout } from '../../../../components/Elements/Layouts';
import { PluginTitleLayout } from '../../../components/PluginTitleLayout';
import { HTMLText } from '../../../../components/Elements/Shapes';
import { ButtonView } from '../../../button_view';
import { StatefulPlugin } from '../../../../StatefulPlugin';
import {
  buttonHeight,
  pluginWidth,
  textCardBackgroundColor,
} from '../../../plugin_consts';
import i18next from 'i18next';
import { pluginCollectNumbersSvgTexture } from '../../../../assets/textures';
import { getPixiFieldStrict } from '../../../../PixiFieldRepository';
import { Node } from '@components/FlowBuilder/Node';
import { customAiPluginFragment_config } from '../../../../../Plugins/CustomAiPlugin/@types/customAiPluginFragment';
import { HEXColors } from '../../../../../../modern-ui/_common/colors';

export class CustomAiPromptLayout extends VLayout {
  TEST_NAME = 'CustomAiPromptLayout';
  titleLayout: PluginTitleLayout;
  textView: HTMLText;
  buttonView: ButtonView;
  _node: Node;

  private tokens = 0;
  private onEdit;

  state: StatefulPlugin<customAiPluginFragment_config>;
  constructor(
    state: StatefulPlugin<customAiPluginFragment_config>,
    node: Node,
    onEdit: () => void,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
        strokeOpacity: 0,
        strokeWidth: 2,
      },
    });
    this._node = node;
    this.state = state;
    this.onEdit = onEdit;
    this.titleLayout = new PluginTitleLayout(
      false,
      i18next.t(
        'modernComponents.FlowBuilder.views.components.CustomAiPlugin.prompt',
      ),
      pluginCollectNumbersSvgTexture,
      state.data,
      undefined,
      {
        background: {
          fill: textCardBackgroundColor,
        },
      },
    );

    this.on('click', () => {
      if (getPixiFieldStrict().isViewOnly()) {
        return;
      }
      this.onEdit();
    });

    this.addToLayout(this.titleLayout, {
      margin: { top: 15, right: 10, left: 10 },
    });

    this.textView = new HTMLText({
      fontSize: 15,
      align: 'left',
      text: this.promptText(),
      width: pluginWidth - 32,
    });
    this.addToLayout(this.textView, {
      margin: {
        left: 16,
        right: 16,
      },
    });

    this.buttonView = new ButtonView({
      width: pluginWidth - 32,
      height: buttonHeight,
      title: i18next.t(
        'modernComponents.FlowBuilder.views.components.CustomAiPlugin.setUp',
      ),
    });

    this.addToLayout(this.buttonView, {
      margin: {
        top: 16,
        left: 16,
        bottom: 16,
      },
    });

    this.textView.text(this.promptText());
  }

  promptText(): string {
    const count = this.tokens;
    return `${count}/${this.state.data.config.userInputMaxTokens} ${i18next.t(
      'modernComponents.FlowBuilder.views.components.CustomAiPlugin.tokens',
      { count },
    )}`;
  }

  updateTokens(tokens: number) {
    this.tokens = tokens;
    this.textView.text(this.promptText());
    this.textView.fill(this.tokens > this.state.data.config.userInputMaxTokens ? HEXColors.red : HEXColors.black)
  }
}
