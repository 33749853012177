import React from 'react';
import { Input } from '@ui/Input';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { LocationPickerPlace } from './types';
import { Spacer } from '@ui/Spacer';

export interface PlacePickerMockProps {
  place: LocationPickerPlace | null;
  setPlace(place: LocationPickerPlace): void;
}

export const PlacePickerMock: React.FC<PlacePickerMockProps> = ({
  place,
  setPlace,
}) => {
  return (
    <div>
      <PluginControlLabel label="latitude">
        {() => (
          <Input
            data-testid="place-picker-mock__latitude"
            type="number"
            value={place?.latitude || ''}
            onChange={(event) => {
              setPlace({
                ...place!,
                latitude: event.target.value,
              });
            }}
            onBlur={() => {
              const latitude = place?.latitude ? place?.latitude.trim() : '';
              setPlace({ ...place!, latitude });
            }}
          />
        )}
      </PluginControlLabel>

      <Spacer factor={4} />

      <PluginControlLabel label="longitude">
        {() => (
          <Input
            data-testid="place-picker-mock__longitude"
            type="number"
            value={place?.longitude || ''}
            onChange={(event) => {
              setPlace({
                ...place!,
                longitude: event.target.value,
              });
            }}
            onBlur={() => {
              const longitude = place?.longitude ? place?.longitude.trim() : '';
              setPlace({ ...place!, longitude });
            }}
          />
        )}
      </PluginControlLabel>

      <Spacer factor={4} />

      <PluginControlLabel label="address">
        {() => (
          <Input
            data-testid="place-picker-mock__address"
            type="text"
            value={place?.address || ''}
            onChange={(event) => {
              setPlace({
                ...place!,
                address: event.target.value,
              });
            }}
            onBlur={() => {
              const address = place?.address ? place?.address.trim() : '';
              setPlace({ ...place!, address });
            }}
          />
        )}
      </PluginControlLabel>
    </div>
  );
};
