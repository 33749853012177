import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { Level, log } from 'cf-common/src/logger';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { pluginWidth } from '../../plugin_consts';
import { Line } from '../../../components/Elements/Shapes';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';

import { OpenAiPluginConfig } from './types';
import { OpenAiSaveAttributeCardView } from './OpenAiSaveAttributeCardView';
import { notEmptyString } from '../../validation';
import { OpenAiPromptLayout } from './OpenAiPromptLayout';
import { OpenAiFooterStartArrow } from './OpenAiFooterStartArrow';
import { logFlowPluginEvent } from '@components/FlowBuilder/utils/Analytics';
import {
  getFullCounterStats,
  sumCounterStats,
} from '@components/FlowBuilder/views/components/BlockStatsView/utils';
import { OnboardingTourShape } from '@globals';

export class OpenAiPluginView
  extends VLayout
  implements StatefulPluginDelegate<OpenAiPluginConfig>
{
  TEST_NAME = 'OpenAiPluginView';

  public readonly state: StatefulPlugin<OpenAiPluginConfig>;
  public readonly _node: Node;

  constructor(state: StatefulPlugin<OpenAiPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        strokeOpacity: 0,
        opacity: 0,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const goodStats = getFullCounterStats(
      `${state.data.id}_good`,
      node.block.id,
    );
    const poorStats = getFullCounterStats(
      `${state.data.id}_poor`,
      node.block.id,
    );
    const liveChatStats = getFullCounterStats(
      `${state.data.id}_livechat`,
      node.block.id,
    );

    this.addToLayout(new OpenAiPromptLayout(state, node));

    const attributeCardView = new OpenAiSaveAttributeCardView(state, node);
    this.addToLayout(attributeCardView, { margin: { top: 10, bottom: 15 } });

    const arrowsContainer = new VLayout({
      name: OnboardingTourShape.OpenAiPluginArrowsContainer,
    });
    this.addToLayout(arrowsContainer);

    arrowsContainer.addToLayout(
      new OpenAiFooterStartArrow(
        {
          tooltipText: i18next.t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.connectToBlockTooltip',
          ),
          stats: !state.data.config.poorAnswerNextBlock
            ? sumCounterStats(goodStats, poorStats)
            : goodStats,
          connectedBlock: state.data.config.goodAnswerNextBlock,
          text: i18next.t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.promptBaseReplies',
          ),
          onChangeBlock: (blockId) => {
            this.state.setAndSave({
              config: {
                ...state.data.config,
                goodAnswerNextBlock: blockId,
              },
            });
          },
        },
        node,
      ),
      {
        margin: { top: 4 },
      },
    );

    arrowsContainer.addToLayout(
      new Line({
        strokeWidth: 1,
        stroke: HEXColors.greyLight10,
        width: pluginWidth,
      }),
      {
        margin: {
          top: 4,
          bottom: 4,
        },
      },
    );

    arrowsContainer.addToLayout(
      new OpenAiFooterStartArrow(
        {
          tooltipText: i18next.t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.connectToBlockTooltip',
          ),
          stats: poorStats,
          connectedBlock: state.data.config.poorAnswerNextBlock,
          text: i18next.t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.defaultAiReplies',
          ),
          onChangeBlock: (blockId) => {
            this.state.setAndSave({
              config: {
                ...state.data.config,
                poorAnswerNextBlock: blockId,
              },
            });
          },
        },
        node,
      ),
      {
        gone: () => !state.data.config.poorAnswerNextBlock,
      },
    );

    arrowsContainer.addToLayout(
      new Line({
        strokeWidth: 1,
        stroke: HEXColors.greyLight10,
        width: pluginWidth,
      }),
      {
        margin: {
          top: 4,
          bottom: 4,
        },
        gone: () => !state.data.config.poorAnswerNextBlock,
      },
    );

    arrowsContainer.addToLayout(
      new OpenAiFooterStartArrow(
        {
          mandatory: false,
          tooltipText: i18next.t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.connectToBlockTooltip',
          ),
          stats: liveChatStats,
          connectedBlock: state.data.config.liveChatAnswerNextBlock,
          text: i18next.t(
            'modernComponents.FlowBuilder.views.components.OpenAiPlugin.liveChatReplies',
          ),
          onChangeBlock: (blockId) => {
            this.state.setAndSave({
              config: {
                ...state.data.config,
                liveChatAnswerNextBlock: blockId,
              },
            });
          },
        },
        node,
      ),
      {
        margin: { bottom: 8 },
      },
    );
  }

  pluginDidSet() {
    this.renderNode();
  }

  pluginDidSave() {
    this.renderNode();
    logFlowPluginEvent('open_ai', 'update', {
      blockId: this._node.id,
      cardId: this.state.data.id,
      value: this.state.data.config,
    });
  }

  pluginDidSetError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.OpenAiPlugin.failedToSavePluginMessageText',
        ),
      },
    });
    log({
      msg: 'Error while saving OpenAi Plugin',
      level: Level.error,
      data: {
        error: error.toString(),
      },
    });
  }

  hasSignificantChangesInConfig() {
    const { saveToVariable, prompt } = this.state.data.config;
    return notEmptyString(saveToVariable) || notEmptyString(prompt);
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
