import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
} from '@components/ConnectPageDialog';
import { getFlowController } from '../../../../PixiFieldRepository';

export function getHandleConnectClick(botId: string, blockId: string) {
  return () => {
    connectPage({
      botId,
      urlParams: {
        [ConnectPageField.origin]: ConnectPageOrigin.ntn,
        [ConnectPageField.blockId]: blockId,
      },
      onPageConnected: ({ status, pageId, verifiedPermissions }) => {
        const controller = getFlowController();
        return controller?.updateFlowBuilderPage(
          status,
          pageId,
          verifiedPermissions,
        );
      },
    });
  };
}
