import { HEXColors } from '@ui/_common/colors';
import { Texture } from 'pixi.js-legacy';
import {
  HLayout,
  LayoutProps,
  MainLayout,
} from '../../components/Elements/Layouts';
import { HTMLText, TextureShape } from '../../components/Elements/Shapes';
import { buttonHeight, buttonWidth } from '../plugin_consts';
import { MarginProps } from '@components/FlowBuilder/components/Elements/Layouts/types';

interface ButtonViewProps extends LayoutProps {
  onClick: (event: Event) => void;
  title?: string;
  textColor?: HEXColors;
  iconWidth?: number;
  iconHeight?: number;
  icon?: Texture;
  iconMargin?: MarginProps;
}

const DEFAULT_PROPS: LayoutProps = {
  width: buttonWidth,
  height: buttonHeight,
  background: {
    fill: HEXColors.white,
    cornerRadius: 4,
    strokeWidth: 1,
    stroke: HEXColors.greyLight20,
    onhover: {
      strokeWidth: 2,
      stroke: HEXColors.blueLight,
    },
  },
};

export class ButtonView extends MainLayout {
  TEST_NAME = 'ButtonView';
  private readonly onClick: (e: Event) => void;
  constructor({
    iconWidth = 24,
    iconHeight = 24,
    textColor = HEXColors.black,
    icon,
    title,
    onClick,
    iconMargin,
    ...layoutProps
  }: ButtonViewProps) {
    super({
      ...DEFAULT_PROPS,
      ...layoutProps,
      background: {
        ...DEFAULT_PROPS.background,
        ...layoutProps.background,
      },
    });

    this.onClick = onClick;
    const box = new HLayout();
    this.addToLayout(box, {
      align: 'center',
      verticalAlign: 'center',
    });

    if (icon) {
      box.addToLayout(
        new TextureShape({
          texture: icon,
          width: iconWidth,
          height: iconHeight,
        }),
        {
          verticalAlign: 'center',
          margin: iconMargin || { right: 8 },
        },
      );
    }

    if (title) {
      box.addToLayout(
        new HTMLText({
          text: title,
          fill: textColor,
          fontSize: 15,
        }),
        {
          margin: {
            top: 1,
          },
        },
      );
    }

    this.on('click', this.onClick);
  }

  destroy() {
    this.off('click', this.onClick);
    super.destroy();
  }
}
