import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { getEntryPointCard } from '../utils';
import { BLOCK_SUBTYPES } from '../../../../consts';
import { Block } from '../../../../types';
import { CALENDLY_PREDEFINED_ATTRIBUTES } from '../../../plugins/CalendlyIntegration/consts';
import { instagramAdsPredefinedAttributes } from '../../EntryPointInstagramAds/utils/instagramAdsPredefinedAttributes';
import { facebookAdsPredefinedAttributes } from '../../EntryPointFacebookAds/utils/facebookAdsPredefinedAttributes';

const PREDEFINED_ATTRIBUTES_BY_BLOCK_SUBTYPE: Record<string, any> = {
  [BLOCK_SUBTYPES.calendly_confirmation]: CALENDLY_PREDEFINED_ATTRIBUTES,
  [BLOCK_SUBTYPES.calendly_reminder]: CALENDLY_PREDEFINED_ATTRIBUTES,
};

const PREDEFINED_ATTRIBUTES_BY_ENTRY_POINT: Record<
  string,
  Record<string, AttributesQuery_bot_variableSuggest[]>
> = {
  [PluginType.ads_manager_ctm_entry_point]: facebookAdsPredefinedAttributes,
  [PluginType.ads_manager_ctm_json_entry_point]:
    facebookAdsPredefinedAttributes,
  [PluginType.ads_manager_sm_entry_point]: facebookAdsPredefinedAttributes,
  [PluginType.instagram_ads_manager_ctm_entry_point]:
    instagramAdsPredefinedAttributes,
};

const DEFAULT = 'default';

const BLOCK_TYPES_WITH_PREDEFINED_AND_DEFAULT_ATTRIBUTES = [
  BLOCK_SUBTYPES.calendly_confirmation,
  BLOCK_SUBTYPES.calendly_reminder,
];

export const shouldJoinAttributes = (subtype: string) => {
  if (BLOCK_TYPES_WITH_PREDEFINED_AND_DEFAULT_ATTRIBUTES.includes(subtype)) {
    return true;
  }

  return false;
};

export const getPredefinedAttributes = (
  { subtype, cards }: Block = {} as Block,
  pluginId?: PluginType,
) => {
  const predefinedAttributes =
    PREDEFINED_ATTRIBUTES_BY_BLOCK_SUBTYPE[subtype] ||
    PREDEFINED_ATTRIBUTES_BY_ENTRY_POINT[
      getEntryPointCard(cards)?.plugin_id || ''
    ];

  return (
    predefinedAttributes?.[pluginId || DEFAULT] ||
    predefinedAttributes?.[DEFAULT]
  );
};
