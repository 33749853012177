import { PropFunc } from '../types';
import { CornerRadiusAdv, VLayout } from '../components/Elements/Layouts';
import { HTMLText, Image } from '../components/Elements/Shapes';
import { pluginWidth } from './plugin_consts';
import { HEXColors } from '@ui/_common/colors';

export interface AddMediaProps {
  width?: number;
  height?: number;
  corners?: PropFunc<CornerRadiusAdv>;
  url?: string;
  label?: string;
  fullLabel?: boolean;
  textHeight?: number;
}

export class AddMedia extends VLayout {
  TEST_NAME = 'AddMedia';

  constructor(props: AddMediaProps = {}) {
    super({
      width: props?.width ?? pluginWidth - 3,
      height: props?.height ?? 198,
      background: {
        fill: HEXColors.greyLight11,
        cornerRadius: 10,
        corners: props?.corners,
      },
    });

    if (props.url) {
      this.layout(
        new Image({
          width: 22,
          height: 22,
          url: props.url,
        }),
        {
          margin: () => ({
            left: (this._layoutProps.width! - 22) / 2,
            top: (this._layoutProps.height! - 44) / 2,
            bottom: 10,
          }),
        },
      );
    }
    this.layout(
      new HTMLText({
        text: props.fullLabel ? props.label : `<b>+ Add </b>${props.label}`,
        fontSize: 15,
        width: props?.width ?? pluginWidth,
        align: 'center',
        verticalAlign: 'center',
        height: props.textHeight,
      }),
    );
  }
}
