import i18next from 'i18next';
import {
  facebookShopsEntryPointFragment_config,
  facebookShopsEntryPointFragment_config_already_subscribed_keywords_setup,
} from '@components/Plugins/FacebookShopsEntryPoint/@types/facebookShopsEntryPointFragment';
import { PLUGIN_DEFAULT_CONFIG } from '@components/Plugins/FacebookShopsProductListPlugin/FacebookShopsProductListPluginConst';
import { PLUGIN_DEFAULT_CONFIG as ENTRY_POINT_DEFAULT_CONFIG } from '@components/Plugins/FacebookShopsEntryPoint/FacebookShopsEntryPointConst';
import { HEXColors } from '@ui/_common/colors';
import { removeTypename } from '@utils/GQL/utils';
import { PermissionGroup } from '@utils/Permissions';
import { goToGetPermissions } from '@utils/FacebookPages/goToGetPermissions';
import { FROM_PERMISSION_GRANT } from '@components/Plugins/FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { Checkbox } from '../../kit/Checkbox';
import { BLOCK_SUBTYPES } from '../../../consts';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { Card } from '../../../types';
import { KeywordGroup } from '../common/components/ReplyToByKeywordGroups/KeywordGroup';
import { ProductsSelector } from './components/ProductsSelector';
import { ReplyToByKeywordsGroups } from '../common/components/ReplyToByKeywordGroups/ReplyToByKeywordsGroups';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { HTMLText } from '../../../components/Elements/Shapes';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { pluginWidth } from '../../plugin_consts';
import { LeadToBlockError } from '../common/errors';

export type EntryPointFacebookShopsCard =
  Card<facebookShopsEntryPointFragment_config>;

export class EntryPointFacebookShops extends VLayout {
  TEST_NAME = 'EntryPointFacebookShops';
  private _card: EntryPointFacebookShopsCard;
  private _node: Node;
  private replyTo: ReplyToByKeywordsGroups;
  private replyToForCurrentSubscribers: ReplyToByKeywordsGroups;
  productsSelector: ProductsSelector;

  constructor(card: EntryPointFacebookShopsCard, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._card = card;
    this._node = node;

    this.productsSelector = new ProductsSelector(card, node);

    this.addToLayout(this.productsSelector, {
      margin: { bottom: 16 },
    });

    this.addToLayout(
      new HTMLText({
        text: window.i18next.t('EntryPointFacebookShops-string--365-reply-to'),
        fontSize: 15,
        fontStyle: 'semibold',
      }),
      {
        margin: { bottom: 8 },
      },
    );

    this.replyTo = new ReplyToByKeywordsGroups(
      () => ({
        ...this._card,
        config: {
          keywords_setup: this._card.config.keywords_setup,
        },
      }),
      this._node,
      {
        defaultAnswerLabel: window.i18next.t(
          'EntryPointFacebookShops-string-1083-all-messages',
        ),
        keywordsGroupLabel: window.i18next.t(
          'EntryPointFacebookShops-string--885-messages-with-keywords',
        ),
        targetPlugin: PluginType.facebook_shops_entry_point,
        statKey: `${card.id}_default_intent_new_users`,
        lineParams: {
          defaultTargetBlockData: {
            subtype: BLOCK_SUBTYPES.send_message,
            cards: [
              {
                plugin_id: PluginType.facebook_shops_product_list,
                config: PLUGIN_DEFAULT_CONFIG,
              },
            ],
          },
        },
        isEditing: () => !!this._card.isEditing,
        onChange: (keywordsSetup) => {
          this._card.config.keywords_setup = keywordsSetup;
          this._node.updateCard(this._card);
        },
        showFilterTypeSelector: true,
      },
    );

    this.addToLayout(this.replyTo);

    // eslint-disable-next-line no-param-reassign
    card.config.already_subscribed_keywords_setup =
      card.config.already_subscribed_keywords_setup ||
      (removeTypename(
        ENTRY_POINT_DEFAULT_CONFIG.already_subscribed_keywords_setup!,
      ) as facebookShopsEntryPointFragment_config_already_subscribed_keywords_setup);

    this.replyToForCurrentSubscribers = new ReplyToByKeywordsGroups(
      () => ({
        ...this._card,
        config: {
          keywords_setup: this._card.config.already_subscribed_keywords_setup,
        },
      }),
      this._node,
      {
        defaultAnswerLabel: window.i18next.t(
          'EntryPointFacebookShops-string-1083-all-messages',
        ),
        keywordsGroupLabel: window.i18next.t(
          'EntryPointFacebookShops-string--885-messages-with-keywords',
        ),
        targetPlugin: PluginType.facebook_shops_entry_point,
        statKey: `${card.id}_default_intent_existing_users`,
        lineParams: {
          defaultTargetBlockData: {
            subtype: BLOCK_SUBTYPES.send_message,
            cards: [
              {
                plugin_id: PluginType.facebook_shops_product_list,
                config: PLUGIN_DEFAULT_CONFIG,
              },
            ],
          },
        },
        isEditing: () => !!this._card.isEditing,
        onChange: (keywordsSetup) => {
          this._card.config.already_subscribed_keywords_setup = keywordsSetup;
          this._node.updateCard(this._card);
        },
        showFilterTypeSelector: true,
      },
    );

    this.addToLayout(
      new Checkbox({
        text: i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.FacebookShops.flowForNewUsersTitle',
        ),
        value: !!card.config.already_subscribed_setup_enabled,
        onChange: (value: boolean) => {
          // eslint-disable-next-line no-param-reassign
          card.config.already_subscribed_setup_enabled = value;
          node.updateCard(card);
          this.renderNode();
          logFlowPluginEvent(
            PluginType.facebook_shops_entry_point,
            'Another reply for current subscribers change',
            {
              already_subscribed_setup_enabled: value,
              blockId: this._node.id,
              cardId: this._card?.id,
            },
          );
        },
        textFalse: undefined,
        textTrue: undefined,
        width: undefined,
      }),
      { margin: { top: 16 } },
    );

    this.addToLayout(this.replyToForCurrentSubscribers, {
      margin: { top: 16 },
      gone: () => !card.config.already_subscribed_setup_enabled,
    });
  }

  onBeforeRender() {
    this.replyToForCurrentSubscribers.keywordGroupsBox
      .views()
      .forEach((view) => {
        // eslint-disable-next-line no-param-reassign
        (view as KeywordGroup).lineStartView._lineView.gone =
          !this._card.config.already_subscribed_setup_enabled;
      });
    this.replyToForCurrentSubscribers.lineStartView._lineView.gone =
      !this._card.config.already_subscribed_setup_enabled;
  }

  hasSignificantChangesInConfig() {
    return false;
  }

  validationError() {
    const facebookShopsPermission =
      this._node.controller.flow.verifiedPermissions?.find(
        ({ id }) => id === PermissionGroup.shops,
      );

    return (
      validateIsPageConnecting(this._node) ||
      (facebookShopsPermission?.enabled
        ? undefined
        : {
            message:
              'Grant Chatfuel permission for <br/>your Facebook Shops.<br/><span style="text-decoration: underline;">Grant Permission</span>',
            onClick: () => {
              if (!facebookShopsPermission) {
                return undefined;
              }
              logFlowPluginEvent(
                PluginType.facebook_shops_entry_point,
                'Grant Permission tooltip click',
                {
                  blockId: this._node.id,
                  cardId: this._card?.id,
                },
              );
              return goToGetPermissions({
                permissionGroup:
                  facebookShopsPermission.permissions_group as PermissionGroup,
                interruptedHref: `${window.location.href}?${FROM_PERMISSION_GRANT}`,
              });
            },
            isBlockError: true,
          }) ||
      (this._card.config.keywords_setup?.intents?.every(
        ({ goto_block }) => !!goto_block?.[0],
      ) &&
      (this._card.config.keywords_setup?.default_block_id ||
        !!this._card.config.keywords_setup?.intents?.length)
        ? undefined
        : new LeadToBlockError()) ||
      (this.productsSelector.isValid()
        ? undefined
        : {
            message:
              'You must add at least one Facebook product.<br/><br/><u>Add product</u>',
            isBlockError: true,
            onClick: () => {
              this.productsSelector.showChooseProductsDialog();
              logFlowPluginEvent(
                PluginType.facebook_shops_entry_point,
                'Add product tooltip click',
                {
                  blockId: this._node.id,
                  cardId: this._card?.id,
                },
              );
            },
          }) ||
      (this.replyTo.isValid()
        ? undefined
        : {
            message:
              "Keywords must be unique. You've<br/>already entered this keyword.",
            isBlockError: true,
          })
    );
  }
}
