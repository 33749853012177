import { abandonedCartPluginFragment_config as CardReminderPluginConfig } from '../../../../Plugins/AbandonedCartPlugin/@types/abandonedCartPluginFragment';
import { AbandonedCartType } from '../../../../Plugins/AbandonedCartPlugin/AbandonedCartPluginConst';
import { VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import { pluginWidth } from '../../plugin_consts';
import { GalleryReminderView } from './components/GalleryReminderView';
import { TextCardReminderView } from './components/TextCardReminderView';

export class CardReminderPlugin
  extends VLayout
  implements StatefulPluginDelegate<CardReminderPluginConfig>
{
  public _node: Node;
  private state: StatefulPlugin<CardReminderPluginConfig>;
  private readonly reminderView:
    | TextCardReminderView
    | GalleryReminderView
    | undefined;

  constructor(state: StatefulPlugin<CardReminderPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const { config } = this.state.data;

    if (config.reminder_type === AbandonedCartType.text) {
      this.reminderView = new TextCardReminderView({
        config: config.text_plugin,
        onChange: (updatedReminderConfig) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              text_plugin: updatedReminderConfig,
            },
          }));
          this.state.save();
        },
        isEditing: () => this.state.isEditing,
        stats: getFullCounterStats(`${this.state.data.id}_text`, node.block.id),
      });
      this.addToLayout(this.reminderView);
    }

    if (config.reminder_type === AbandonedCartType.gallery) {
      this.reminderView = new GalleryReminderView({
        config: config.gallery_plugin,
        onChange: (updatedReminderConfig) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              gallery_plugin: updatedReminderConfig,
            },
          }));
          this.state.save();
        },
        isEditing: () => this.state.isEditing,
        stats: getFullCounterStats(
          `${this.state.data.id}_gallery`,
          node.block.id,
        ),
      });
      this.addToLayout(this.reminderView);
    }
  }

  pluginDidSet() {}

  validationError() {
    return this.reminderView?.validationError();
  }
}
