import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { CenteredLoader } from '@ui/Loader';
import { DIALOG_STYLE } from '../consts';

interface ChooseFacebookShopProductsLoadingStateDialogProps {
  onDismiss: () => void;
}

export const ChooseFacebookShopProductsLoadingStateDialog: React.FC<ChooseFacebookShopProductsLoadingStateDialogProps> = ({
  onDismiss,
}) => (
  <DefaultDialog
    header=""
    onDismiss={() => {
      onDismiss();
    }}
    dialogStyle={DIALOG_STYLE}
  >
    <CenteredLoader
      style={{
        height: 480,
      }}
    />
  </DefaultDialog>
);
