import { updateFlowBlockPositions as netUpdateFlowBlockPositions } from '../../api/network';
import { Node } from '../../Node';
import { fitScreen } from './fitScreen';
import { computePositions } from './computePositions';
import { createNodePosition, NodePosition, AllNodes } from './helpers';
import {
  updateNodesPositionWithAnimation,
  setNewPositionsToNodes,
} from './updateNodesPosition';

export async function organizeBlocksToTargetPositions(
  flowId: string,
  allNodes: AllNodes,
  targetPositions: NodePosition[],
) {
  const nodesToUpdate = setNewPositionsToNodes(targetPositions, allNodes);
  await Promise.all([
    updateNodesPositionWithAnimation(nodesToUpdate),
    fitScreen(Object.values(allNodes), {
      xGetter: (n) => n.newX ?? n.x,
      yGetter: (n) => n.newY ?? n.y,
      duration: 500,
    }),
  ]);
  netUpdateFlowBlockPositions(
    flowId,
    nodesToUpdate.map((n) => createNodePosition(n.id, n.x, n.y)),
  );
}

/**
 * @returns old positions
 */
export async function organizeBlocks(
  flowId: string,
  startNodes: Node[],
  allNodes: AllNodes,
): Promise<{ oldPositions: NodePosition[]; newPositions: NodePosition[] }> {
  if (startNodes.length === 0) {
    throw new Error('startNodes should not be empty');
  }
  const computedPositions = computePositions(startNodes, allNodes);
  await organizeBlocksToTargetPositions(
    flowId,
    allNodes,
    computedPositions.newPositions,
  );
  return {
    oldPositions: computedPositions.oldPositions,
    newPositions: computedPositions.newPositions,
  };
}

/**
 * @returns old positions
 */
export async function organizeBlocksAndBlocksWithZeroCoordinates(
  flowId: string,
  startNodes: Node[],
  allNodes: AllNodes,
): Promise<NodePosition[]> {
  const blocksWithZeroCoordinates = Object.values(allNodes).filter(
    ({ x, y }) => x === 0 && y === 0,
  );
  const { oldPositions } = await organizeBlocks(
    flowId,
    [...startNodes, ...blocksWithZeroCoordinates],
    allNodes,
  );
  return oldPositions;
}
