import { ShopifyEventType } from '@globals';
import { CartReminderEventView } from './CartReminderEventView';
import { OrderConfirmationEventView } from './OrderConfirmationEventView';
import { ProductVisitEventView } from './ProductVisitEventView';
import { OrderStatusUpdateEventView } from './OrderStatusUpdateEventView';

export const SHOPIFY_EVENTS_COMPONENTS = {
  [ShopifyEventType.abandoned_cart]: CartReminderEventView,
  [ShopifyEventType.order_confirmation]: OrderConfirmationEventView,
  [ShopifyEventType.order_status_update]: OrderStatusUpdateEventView,
  [ShopifyEventType.product_visit]: ProductVisitEventView,
};
