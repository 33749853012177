import Maybe from 'graphql/tsutils/Maybe';
import i18next from 'i18next';
import { getI18nCommentsAutoreplyKey } from '../../EntryPointCommentsAutoreply/utils/getI18nCommentsAutoreplyKey';

interface Keywords {
  __typename: 'KeywordsSetup';
  intents: Maybe<Array<{ goto_block: Maybe<Array<string>> }>>;
  default_block_id: Maybe<string>;
}

export interface CommentsConfig {
  keywords_setup: Maybe<Keywords>;
  already_subscribed_keywords_setup: Maybe<Keywords>;
  already_subscribed_setup_enabled: boolean;
}

export const validateCommentsBlockConnection = (config: CommentsConfig) =>
  config.keywords_setup?.intents?.every(
    ({ goto_block }) => !!goto_block?.[0],
  ) &&
  (config.keywords_setup?.default_block_id ||
    !!config.keywords_setup?.intents?.length) &&
  (!config.already_subscribed_setup_enabled ||
    (config.already_subscribed_keywords_setup?.intents?.every(
      ({ goto_block }) => !!goto_block?.[0],
    ) &&
      (config.already_subscribed_keywords_setup?.default_block_id ||
        !!config.already_subscribed_keywords_setup?.intents?.length)))
    ? undefined
    : {
        message: i18next.t(
          getI18nCommentsAutoreplyKey('connectBlockValidationText'),
        ),
        isBlockError: true,
      };
