import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { getLinkByVideoHash } from '@utils/Youtube/getLinkByVideoHash';

import { NestedMenuNodeDisplayMode } from '@ui/NestedMenu/types';
import * as textures from '../../assets/textures';

import entryPointFacebookPageSvg from '../../assets/entry_point/facebook_page.svg';
import removeUserAttributePreview from '../../assets/plugin/remove-user-attribute-preview.svg';
import sendEventToFbPreview from '../../assets/plugin/send-event-to-fb-preview.svg';
import jsonPluginPreview from '../../assets/plugin/json-plugin-preview.svg';
import notifyAdminViaEmailPreview from '../../assets/plugin/notify-admin-via-email-preview.svg';
import liveChatPreviewSvg from '../../assets/plugin/live-chat-preview.svg';
import googleSheetPreview from '../../assets/plugin/google-sheet-preview.png';
import scheduleCalendlyEvent from '../../assets/plugin/schedule-calendly-event.png';
import calendlyEventReminder from '../../assets/plugin/calendly-event-reminder.png';
import calendlyEventConfirmation from '../../assets/plugin/calendly-event-confirmation.png';
import cartRemindersPreview from '../../assets/plugin/cart-reminders-preview.png';
import datePickerPreviewSvg from '../../assets/plugin/datepicker_preview.svg';
import otnPreviewSvg from '../../assets/plugin/otn-preview.svg';
import whatsappTemplatePng from '../../assets/plugin/whatsapp_tempalte_plugin.png';
import orderConfirmation from '../../assets/plugin/order-confirmation.png';
import productVisit from '../../assets/plugin/product-visit.png';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { BLOCK_SUBTYPES } from '../../consts';
import commentTooltipPlaceholder from '../../assets/plugin/comment-preview.svg';
// import customerChatImgSrc from '../../assets/entry_point/menu-images/customer-chat.png';
import sendToMessengerImgSrc from '../../assets/entry_point/menu-images/send-to-messenger.png';
import persistentMenuImgSrc from '../../assets/entry_point/menu-images/persistent-menu.png';
import instagramPersistentMenuImgSrc from '../../assets/entry_point/menu-images/instagram-persistent-menu.png';
import refLinkImgSrc from '../../assets/entry_point/menu-images/ref-link.png';
import commentsAutoreplySrc from '../../assets/entry_point/menu-images/comments-autoreply.png';
import adCommentsAutoreplySrc from '../../assets/entry_point/menu-images/ad_comments_autoreply_entry_point.png';
import facebookAdsCtmSrc from '../../assets/entry_point/menu-images/facebook-ads-ctm.png';
import facebookAdsCtmJsonSrc from '../../assets/entry_point/menu-images/facebook-ads-ctm-json.png';
import instagramAdsCtmSrc from '../../assets/entry_point/menu-images/instagram_ctm.png';
// import facebookAdsSmSrc from '../../assets/entry_point/menu-images/facebook-ads-sm.png';
import facebookShopsSrc from '../../assets/entry_point/menu-images/facebook-shops.png';
import instagramDirectSrc from '../../assets/entry_point/menu-images/instagram-direct.png';
import instagramCommentsAutoreplySrc from '../../assets/entry_point/menu-images/instagram-comments-autoreply.png';
import instagramStoryMentionSrc from '../../assets/entry_point/menu-images/instagram-story-mention.png';
import instagramStoryReplySrc from '../../assets/entry_point/menu-images/instagram-story-reply.png';
import instagramBotLinkSrc from '../../assets/entry_point/menu-images/instagram-bot-link.png';
import whatsappDirectSrc from '../../assets/entry_point/menu-images/whatsapp_direct_preview.png';
import whatsappBotLinkSrc from '../../assets/entry_point/menu-images/whatsapp_bot_link.png';
import whatsappWidgetSrc from '../../assets/entry_point/menu-images/whatsapp_widget.png';
import whatsappPopupSrc from '../../assets/entry_point/menu-images/whatsapp_popup.png';
import { BlockShortcutId, getBlockShortcut } from '../helpers/blocksShortcuts';
import {
  EntryPointTutorialYoutube,
  PluginTutorialYoutube,
} from '../../../../constants/externalLinks';
import zapierSrc from '../../assets/entry_point/menu-images/zapier.png';
import {
  BlockMenuId,
  GetMenuItemByConfig,
  IMenuNode,
  MenuButtonId,
  MenuItemsMap,
  MenuItemType,
  MenuNodeId,
  Tooltip,
} from './types';
import { PlatformHolder } from './PlatformHolder';
import { BlockContextType, Platform } from '@globals';
import { flowBuilderModule } from '@components/FlowBuilder/FlowBuilderModule';

const oneRowMargin = '<div style="margin-bottom: 8px;"> </div>';
const learnMoreLink = (href: string) =>
  `<a style="color: white;" target="_blank" rel="noreferrer noopener" href="${href}">${i18next.t(
    'common.LearnMore',
  )} ↗</a>`;
const troubleshootingLink = (href: string) =>
  `<a style="color: white;" target="_blank" rel="noreferrer noopener" href="${href}">${i18next.t(
    'common.Troubleshooting',
  )} ↗</a>`;
const CALENDLY_DOCS_LINK =
  'http://docs.chatfuel.com/en/articles/5349954-schedule-calendly-event-plugin-let-your-users-schedule-appointments-or-events-via-bot';

export const tooltips: () => Record<string, Tooltip> = memoize(() => ({
  ...flowBuilderModule
    .getPlugins()
    .reduce((prev, p) => ({ ...prev, [p.id]: p.tooltip() }), {}),
  whatsappTemplate: {
    html: `<img src="${whatsappTemplatePng}" style="display:block;width:172px;height:224px;margin-bottom:12px;" />
		<div style="width:172px;">${i18next.t(
      'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.tooltip',
    )}</div>
		${oneRowMargin}
		<a style="color: white;" target="_blank" rel="noreferrer noopener" href="https://docs.chatfuel.com/en/articles/6987395-how-to-create-a-whatsapp-message-template">${i18next.t(
      'common.LearnMore',
    )} ↗</a>`,
  },
  locationPlugin: {
    text: i18next.t(
      'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.tooltip',
    ),
  },
  listPlugin: {
    text: i18next.t(
      'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.tooltip',
    ),
  },
  nTNDaily: {
    text: i18next.t('nestedMenu.NTN.dailyTooltip'),
  },
  nTNWeekly: {
    text: i18next.t('nestedMenu.NTN.weeklyTooltip'),
  },
  nTNMonthly: {
    text: i18next.t('nestedMenu.NTN.monthlyTooltip'),
  },
  send_msg: {
    text: i18next.t(
      'menu_view-string--622-send-images-videos-audios-and-other-types-of-content-to-your-users',
    ),
  },
  action: {
    text: i18next.t(
      'menu_view-string--841-have-your-bot-perform-a-background-action-like-saving-changing-or-sending-data',
    ),
  },
  split_traffic: {
    text: i18next.t(
      'menu_view-string--885-split-traffic-for-a-b-testing-or-randomize-which-users-are-sent-to-the-next-step',
    ),
  },
  condition: {
    text: i18next.t(
      'menu_view-string--103-redirect-users-based-on-whether-they-fit-conditional-criteria-that-you-set',
    ),
  },
  open_url: {
    text: i18next.t(
      'menu_view-string-1903-bot-will-open-url-when-user-presses-the-button',
    ),
  },
  delay: {
    text: i18next.t(
      'menu_view-string-7008-set-up-your-chatbot-to-send-a-message-after-a-certain-pause',
    ),
  },
  phone_call: {
    text: i18next.t(
      'menu_view-string-4845-use-it-if-you-want-the-user-to-call-you',
    ),
  },
  add_tag: {
    html: i18next.t(
      'menu_view-string--158-tag-your-users-to-arrange-them-in-groups-in-the-people-tab',
    ),
  },
  [PluginType.add_attribute]: {
    html: `${i18next.t(
      'menu_view-Template-8356-save-a-value-for-a-custom-user-attribute-based-on-the-users-action-in-your-bot',
    )}${oneRowMargin}<u>${i18next.t('common.LearnMore')} ↗</u>`,
    url: i18next.t('common.helpDocLinks.attributes'),
  },
  [PluginType.remove_attribute]: {
    html: `<img src="${removeUserAttributePreview}" style="display:block;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.remove_attribute',
    )}${oneRowMargin}<u>${i18next.t('common.LearnMore')} ↗</u>`,
    url: i18next.t('common.helpDocLinks.attributes'),
  },
  remove_tag: {
    html: i18next.t(
      'menu_view-string--160-untag-users-to-remove-them-from-specific-groups',
    ),
  },
  fb_analytics: {
    html: `<img src="${sendEventToFbPreview}" style="display:block;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.fb_analytics',
    )}${oneRowMargin}<u>${i18next.t('common.LearnMore')} ↗</u>`,
    url: 'https://docs.chatfuel.com/en/articles/3742284-send-analytics-events-to-facebook-beta',
  },
  json: {
    html: `<img src="${jsonPluginPreview}" style="display:block;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.json',
    )}${oneRowMargin}<u>${i18next.t('common.LearnMore')} ↗</u>`,
    url: 'https://docs.chatfuel.com/en/articles/735122-json-api',
  },
  text: {
    text: i18next.t(
      'menu_view-string--228-a-text-card-with-attributes-and-emoji',
    ),
  },
  textAndButtons: {
    text: i18next.t(
      'menu_view-string--172-a-text-card-with-attributes-emoji-and-buttons',
    ),
  },
  handover: {
    text: i18next.t(
      'menu_view-string-1666-pass-control-of-the-conversation-to-other-facebook-apps-such-as-social-media-management-or-customer-support-platforms',
    ),
    url: 'https://docs.chatfuel.com/plugins/connect-users-to-a-human/conversation-handover',
  },
  otn: {
    html: `<img src="${otnPreviewSvg}" style="display:block;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.otn',
    )}${oneRowMargin}<u>${i18next.t(
      'common.WatchTutorial',
    )} ↗</u>${oneRowMargin}<a style="color: white;" target="_blank" rel="noreferrer noopener" href="https://docs.chatfuel.com/en/articles/3705639-one-time-notification-api-beta">${i18next.t(
      'common.LearnMore',
    )} ↗</a>`,
    url: getLinkByVideoHash(PluginTutorialYoutube.oneTimeNotification),
  },
  save_phone: {
    text: i18next.t(
      'menu_view-string--174-collect-users-phone-number-and-access-it-later-in-the-people-tab',
    ),
  },
  save_email: {
    text: i18next.t(
      'menu_view-string-1708-collect-users-email-and-access-it-later-in-the-people-tab',
    ),
  },
  email: {
    html: `<img src="${notifyAdminViaEmailPreview}" style="display:block;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.email',
    )}`,
  },
  redirect: {
    text: i18next.t(
      'menu_view-string-1029-send-users-to-the-next-flow-or-block',
    ),
    url: 'https://docs.chatfuel.com',
  },
  redirectFlow: {
    text: i18next.t('menu_view-string--519-send-users-to-the-next-flow'),
    url: 'https://docs.chatfuel.com',
  },
  flowRedirect: {
    text: i18next.t(
      'menu_view-string-5563-send-users-to-the-next-flow-of-same-channel-based-on-their-attribute-values',
    ),
  },
  comment: {
    html: `<img src="${commentTooltipPlaceholder}" style="display:block;width:214px;height:117px;margin-bottom:12px;" alt=""  />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.comment',
    )}`,
  },
  liveChat: {
    html: `<img src="${liveChatPreviewSvg}" style="display:block;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.liveChat',
    )}${oneRowMargin}<u>${i18next.t('common.LearnMore')} ↗</u>`,
    url: 'https://docs.chatfuel.com/en/articles/849315-live-chat',
  },
  [PluginType.send_to_messenger_entry_point]: {
    html: `${i18next.t(
      'menu_view-Template--541-add-a-button-to-your-website-that-lets-users-get-in-touch-with-your-bot',
    )}${oneRowMargin}<u>${i18next.t('common.LearnMore')}</u> ↗`,
    url: i18next.t('common.helpDocLinks.entry_points'),
  },
  [PluginType.customer_chat_entry_point]: {
    text: `${i18next.t(
      'menu_view-Template-9533-place-a-messenger-chat-window-right-on-your-website',
    )}${oneRowMargin}<u>${i18next.t('common.LearnMore')}</u> ↗`,
    url: i18next.t('common.helpDocLinks.entry_points'),
  },
  [PluginType.fb_page_entry_point]: {
    html: `<img src="${entryPointFacebookPageSvg}" style="display:block;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.fb_page_entry_point',
    )}${oneRowMargin}<u>${i18next.t(
      'common.WatchTutorial',
    )} ↗</u>${oneRowMargin}<a style="color: white;" target="_blank" rel="noreferrer noopener" href="${i18next.t(
      'common.helpDocLinks.entry_points',
    )}">${i18next.t('common.LearnMore')} ↗</a>`,
    url: getLinkByVideoHash(EntryPointTutorialYoutube.facebookPage),
  },
  googleSheet: {
    html: `<img src="${googleSheetPreview}" style="display:block;margin-bottom:12px;width:214px;height:164px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.googleSheet',
    )}${oneRowMargin}<u>${i18next.t(
      'common.WatchTutorial',
    )} ↗</u>${oneRowMargin}<a style="color: white;" target="_blank" rel="noreferrer noopener" href="https://docs.chatfuel.com/en/articles/2708726-save-to-google-sheets">${i18next.t(
      'common.LearnMore',
    )} ↗</a>`,
    url: getLinkByVideoHash(PluginTutorialYoutube.googleSheet),
  },
  [PluginType.event_trigger]: {
    html: i18next.t(
      'menu_view-string-7253-send-information-you-collected-in-your-chatbot-to-another-app-via-zapier',
    ),
  },
  [PluginType.instagram_direct_entry_point]: {
    html: `<img src="${instagramDirectSrc}" style="display:block;margin-bottom:12px;width:214px;height:139px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.instagram_direct_entry_point',
    )}${oneRowMargin}<a style="color: white;" target="_blank" rel="noreferrer noopener" href="${i18next.t(
      'common.helpDocLinks.entry_points',
    )}">${i18next.t('common.LearnMore')} ↗</a>`,
    // url: getLinkByVideoHash(EntryPointTutorialYoutube.instagramDirect),
  },
  [PluginType.instagram_comments_autoreply_entry_point]: {
    html: `<img src="${instagramCommentsAutoreplySrc}" alt="" style="display:block;width:214px;height:140px;margin-bottom: 12px;"/>
  <div style="width:214px;">${i18next.t(
    'modernComponents.FlowBuilder.views.menuView.instagram_comments_autoreply_entry_point',
  )}</div>${oneRowMargin}<u>${i18next.t(
      'common.WatchTutorial',
    )} ↗</u>${oneRowMargin}<a style="color: white;" target="_blank" rel="noreferrer noopener" href="https://docs.chatfuel.com/en/articles/5213600-comments-autoreply-entry-point-for-instagram-flows-automatically-respond-to-instagram-post-comments">${i18next.t(
      'common.LearnMore',
    )} ↗</a>`,
    url: getLinkByVideoHash(
      EntryPointTutorialYoutube.instagramCommentsAutoreply,
    ),
  },
  [PluginType.instagram_story_mention_entry_point]: {
    html: `<img src="${instagramStoryMentionSrc}" style="display:block;margin-bottom:12px;width:214px;height:154.5px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.instagram_story_mention_entry_point',
    )}${oneRowMargin}<u>${i18next.t(
      'common.WatchTutorial',
    )} ↗</u>${oneRowMargin}<a style="color: white;" target="_blank" rel="noreferrer noopener" href="https://docs.chatfuel.com/en/articles/6725238-instagram-entry-points#h_0cd4984f0a">${i18next.t(
      'common.LearnMore',
    )} ↗</a>`,
    url: getLinkByVideoHash(EntryPointTutorialYoutube.instagramStoryMention),
  },
  [PluginType.instagram_story_reply_entry_point]: {
    html: `<img src="${instagramStoryReplySrc}" style="display:block;margin-bottom:12px;width:214px;height:154.5px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.instagram_story_reply_entry_point',
    )}${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u>`,
    url: getLinkByVideoHash(EntryPointTutorialYoutube.instagramStoryReply),
  },
  [PluginType.instagram_bot_link_entry_point]: {
    html: `<img src="${instagramBotLinkSrc}" alt="" style="display:block;width:214px;height:84px;margin-bottom:12px;"/>
       <div style="width:214px;">${i18next.t(
         'modernComponents.FlowBuilder.views.menuView.instagramLink',
       )}</div>`,
  },
  cartReminders: {
    html: `<img src="${cartRemindersPreview}" style="display:block;width:214px;height:180px;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.cartReminders',
    )}${oneRowMargin}<a style="color: white;" target="_blank" rel="noreferrer noopener" href="${i18next.t(
      'common.helpDocLinks.entry_points',
    )}">${i18next.t('common.LearnMore')} ↗</a>`,
    //  url: getLinkByVideoHash(EntryPointTutorialYoutube.cartReminders),
  },
  orderConfirmation: {
    html: `<img src="${orderConfirmation}" style="display:block;width:212px;height:241px;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.orderConfirmation',
    )}`,
    // url: getLinkByVideoHash(EntryPointTutorialYoutube.cartReminders),
  },
  textReminder: {
    html: i18next.t(
      'menu_view-string--110-remind-your-users-of-the-item-in-their-cart-with-a-text-message',
    ),
  },
  productVisitReminder: {
    html: i18next.t(
      'modernComponents.FlowBuilder.views.menuView.productVisitReminder',
    ),
  },
  galleryReminder: {
    html: i18next.t(
      'menu_view-string-1992-remind-your-users-of-the-item-in-the-cart-with-its-image-gallery-and-description',
    ),
  },
  scheduleCalendlyEvent: {
    html: `<img src="${scheduleCalendlyEvent}" style="display:block;margin-bottom:12px;width:214px;height:96px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.scheduleCalendlyEvent',
    )}${oneRowMargin}${learnMoreLink(CALENDLY_DOCS_LINK)}`,
  },
  calendlyEventConfirmation: {
    html: `<img src="${calendlyEventConfirmation}" style="display:block;margin-bottom:12px;width:214px;height:167px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.calendlyEventConfirmation',
    )}${oneRowMargin}${learnMoreLink(CALENDLY_DOCS_LINK)}`,
  },
  calendlyEventReminder: {
    html: `<img src="${calendlyEventReminder}" style="display:block;margin-bottom:12px;width:214px;height:167px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.calendlyEventReminder',
    )}${oneRowMargin}${learnMoreLink(CALENDLY_DOCS_LINK)}`,
  },
  receiptBlock: {
    html: i18next.t(
      'menu_view-string--189-a-preset-block-that-contains-order-details',
    ),
  },
  receiptPlugin: {
    html: i18next.t(
      'menu_view-string--929-order-receipt-is-a-card-with-order-details',
    ),
  },
  productVisit: {
    html: `<img src="${productVisit}" style="display:block;width:214px;height:180px;margin-bottom:12px;" />${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.productVisit',
    )}`,
    // url: getLinkByVideoHash(EntryPointTutorialYoutube.cartReminders),
  },
  datePicker: {
    html: `<img src="${datePickerPreviewSvg}" alt="" style="display:block;width:214px;height:205px;margin-bottom:12px;"/>
       <div style="width:214px;">${i18next.t(
         'modernComponents.FlowBuilder.views.menuView.datepicker_shortcut',
       )}</div>${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u>`,
    url: getLinkByVideoHash(PluginTutorialYoutube.datePicker),
  },
  [PluginType.whatsapp_direct_entry_point]: {
    html: `<img src="${whatsappDirectSrc}" alt="" style="display:block;width:214px;height:158px;margin-bottom:12px;"/>
       <div style="width:214px;">${i18next.t(
         'modernComponents.FlowBuilder.views.menuView.whatsappDirect',
       )}</div>`,
  },
  [PluginType.whatsapp_bot_link]: {
    html: `<img src="${whatsappBotLinkSrc}" alt="" style="display:block;width:214px;height:140px;margin-bottom:12px;"/>
       <div style="width:214px;">${i18next.t(
         'modernComponents.FlowBuilder.views.menuView.whatsappBotLink',
       )}</div>`,
  },
  [PluginType.whatsapp_widget_entry_point]: {
    html: `<img src="${whatsappWidgetSrc}" alt="" style="display:block;width:214px;height:120px;margin-bottom:12px;"/>
        <div style="width:214px;">${i18next.t(
          'modernComponents.FlowBuilder.views.menuView.whatsappWidget',
        )}</div>`,
  },
  [PluginType.whatsapp_popup_entry_point]: {
    html: `<img src="${whatsappPopupSrc}" alt="" style="display:block;width:214px;height:158px;margin-bottom:12px;"/>
    <div style="width:214px;">${i18next.t(
      'modernComponents.FlowBuilder.views.menuView.whatsappPopup',
    )}</div>`,
  },
}));

export const getMenuItem: MenuItemsMap = {
  blockMsg: () => ({
    id: 'send_message',
    img: textures.BlockSendMsgIcon,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    subtype: BLOCK_SUBTYPES.send_message,
    title: i18next.t('menu_view-string--121-send-message'),
    type: MenuItemType.block,
    tooltip: tooltips().send_msg,
  }),
  blockAction: () => ({
    id: 'action',
    img: textures.BlockActionIcon,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    subtype: BLOCK_SUBTYPES.action,
    title: i18next.t('menu_view-string-1744-bot-action'),
    type: MenuItemType.block,
    tooltip: tooltips().action,
  }),
  blockSplit: () => ({
    defaultPluginId: PluginType.split_traffic,
    id: PluginType.split_traffic,
    img: textures.BlockSplitActionsIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    subtype: BLOCK_SUBTYPES.split_traffic,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.menuView.splitTraffic',
    ),
    type: MenuItemType.block,
    tooltip: tooltips().split_traffic,
  }),
  blockCondition: () => ({
    defaultPluginId: PluginType.go_to_block_plugin,
    id: 'condition',
    img: textures.BlockConditionActionsIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    subtype: BLOCK_SUBTYPES.condition,
    title: i18next.t('modernComponents.FlowBuilder.views.menuView.condition'),
    type: MenuItemType.block,
    tooltip: tooltips().condition,
  }),
  blockCommentsCondition: () => ({
    defaultPluginId: PluginType.go_to_block_plugin,
    id: 'comments_autoreply_condition',
    img: textures.BlockConditionActionsIcon,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    subtype: BLOCK_SUBTYPES.condition,
    contextType: BlockContextType.comments,
    title: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.autoreply_condition',
    ),
    type: MenuItemType.block,
    tooltip: tooltips().condition,
  }),
  blockCommentsReply: () => ({
    defaultPluginId: PluginType.comments_reply_shortcut,
    id: PluginType.comments_reply_shortcut,
    img: textures.CommentsAutoreplyIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.comments_autoreply_content,
    title: i18next.t('modernComponents.FlowBuilder.views.menuView.reply'),
    type: MenuItemType.block,
    shortcut: getBlockShortcut(BlockShortcutId.commentsReply),
  }),
  blockInstagramCommentsReply: () => ({
    defaultPluginId: PluginType.instagram_comments_reply_shortcut,
    id: PluginType.instagram_comments_reply_shortcut,
    img: textures.InstagramCommentsAutoreplyIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.instagram_comments_autoreply_content,
    title: i18next.t('modernComponents.FlowBuilder.views.menuView.reply'),
    type: MenuItemType.block,
    shortcut: getBlockShortcut(BlockShortcutId.instagramCommentsReply),
  }),
  blockDelay: () => ({
    subtype: BLOCK_SUBTYPES.delay,
    defaultPluginId: PluginType.delay,
    id: 'delay',
    img: textures.BlockDelayActionsIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    title: i18next.t('modernComponents.FlowBuilder.views.menuView.delay'),
    type: MenuItemType.block,
    tooltip: tooltips().delay,
  }),
  blockRedirect: ({ isAutomateEnabled }) =>
    isAutomateEnabled
      ? {
          subtype: BLOCK_SUBTYPES.redirect,
          id: 'redirect',
          img: textures.BlockRedirectActionsIcon,
          platform: new PlatformHolder(Platform.facebook),
          title: i18next.t('menu_view-string--206-redirect-to-flow-or-block'),
          type: MenuItemType.block,
          tooltip: tooltips().redirect,
        }
      : undefined,
  flowRedirect: ({ isAutomateEnabled }) => ({
    subtype: BLOCK_SUBTYPES.redirect,
    id: 'redirect',
    img: textures.BlockRedirectActionsIcon,
    platform: !isAutomateEnabled
      ? new PlatformHolder(
          Platform.facebook,
          Platform.instagram,
          Platform.whatsapp,
        )
      : new PlatformHolder(Platform.instagram, Platform.whatsapp),
    title: i18next.t('menu_view-string-1227-redirect-to-flow'),
    type: MenuItemType.block,
    tooltip: tooltips().flowRedirect,
  }),
  facebookPage: () => ({
    id: PluginType.fb_page_entry_point,
    img: textures.FacebookPageIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.entrypoint,
    title: i18next.t('menu_view-string--193-facebook-page'),
    type: MenuItemType.entrypoint,
    tooltip: tooltips()[PluginType.fb_page_entry_point],
  }),
  refLink: () => ({
    id: PluginType.ref_link_entry_point,
    img: textures.BotLinkIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.entrypoint,
    title: i18next.t('menu_view-string-2142-bot-link'),
    type: MenuItemType.entrypoint,
    tooltip: {
      html: `<img src="${refLinkImgSrc}" alt="" style="display:block;width:214px;height:75px;margin-bottom:12px;"/>
       <div style="width:214px;">${i18next.t(
         'modernComponents.FlowBuilder.views.menuView.refLink',
       )}</div>${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u>`,
      url: getLinkByVideoHash(EntryPointTutorialYoutube.botLink),
    },
  }),
  persistentMenu: () => ({
    id: PluginType.persistent_menu_entry_point,
    img: textures.PersistentMenuIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.entrypoint,
    title: i18next.t('menu_view-string-1487-persistent-menu'),
    type: MenuItemType.entrypoint,
    tooltip: {
      html: `<img src="${persistentMenuImgSrc}" alt="" style="display:block;width:214px;height:124px;margin-bottom:12px;"/>
       <div style="width:214px;">${i18next.t(
         'modernComponents.FlowBuilder.views.menuView.persistent_menu_entry_point',
       )}</div>${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u>`,
      url: getLinkByVideoHash(EntryPointTutorialYoutube.persistentMenu),
    },
  }),
  openAi: () => ({
    defaultPluginId: PluginType.open_ai,
    id: PluginType.open_ai,
    img: textures.PluginOpenAiIcon,
    platform: new PlatformHolder(
      Platform.facebook,
      Platform.instagram,
      Platform.whatsapp,
    ),
    subtype: BLOCK_SUBTYPES.open_ai,
    title: i18next.t('modernComponents.FlowBuilder.views.menuView.openAiTitle'),
    type: MenuItemType.plugin,
    tooltip: {
      html: `<div style="width:214px;">${i18next.t(
        'modernComponents.FlowBuilder.views.menuView.openAiTooltip',
      )}</div>`,
    },
  }),
  openAiComments: () => ({
    defaultPluginId: PluginType.open_ai,
    id: PluginType.open_ai,
    img: textures.PluginOpenAiIcon,
    contextType: BlockContextType.comments,
    platform: new PlatformHolder(Platform.facebook, Platform.instagram),
    subtype: BLOCK_SUBTYPES.open_ai,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.menuView.openAiCommentsTitle',
    ),
    type: MenuItemType.plugin,
    tooltip: {
      html: `<div style="width:214px;">${i18next.t(
        'modernComponents.FlowBuilder.views.menuView.openAiTooltip',
      )}</div>`,
    },
  }),
  kommo: ({ isShowKommoPlugin }) =>
    isShowKommoPlugin
      ? {
          id: PluginType.kommo_plugin,
          img: textures.KommoMenuIcon,
          platform: new PlatformHolder(Platform.whatsapp),
          subtype: BLOCK_SUBTYPES.send_data,
          title: i18next.t(
            'modernComponents.FlowBuilder.views.menuView.sendToKommo',
          ),
          type: MenuItemType.plugin,
        }
      : undefined,
  instagramPersistentMenu: () => ({
    id: PluginType.instagram_persistent_menu_entry_point,
    img: textures.InstagramPersistentMenuIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.entrypoint,
    title: i18next.t('menu_view-string-1487-persistent-menu'),
    type: MenuItemType.entrypoint,
    tooltip: {
      html: `<img src="${instagramPersistentMenuImgSrc}" alt="" style="display:block;width:214px;height:124px;margin-bottom:12px;"/>
       <div style="width:214px;">${i18next.t(
         'modernComponents.FlowBuilder.views.menuView.instagram_persistent_menu_entry_point',
       )}</div>`,
    },
  }),
  sendToMessenger: () => ({
    id: PluginType.send_to_messenger_entry_point,
    img: textures.SendToMessengerIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.entrypoint,
    title: i18next.t('menu_view-string--208-send-to-messenger'),
    type: MenuItemType.entrypoint,
    tooltip: {
      html: `<img src="${sendToMessengerImgSrc}" alt="" style="display:block;width:214px;height:124px;margin-bottom:12px;"/>
<div style="width:214px;">${i18next.t(
        'modernComponents.FlowBuilder.views.menuView.send_to_messenger_entry_point',
      )}</div>${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u>`,
      url: getLinkByVideoHash(EntryPointTutorialYoutube.sendToMessenger),
    },
  }),
  customerChat: () => undefined,
  //   customerChat: () => ({
  // ({
  //     id: PluginType.customer_chat_entry_point,
  //     img: textures.CustomerChatIcon,
  //     platform: new PlatformHolder(Platform.facebook),
  //     subtype: BLOCK_SUBTYPES.entrypoint,
  //     type: MenuItemType.entrypoint,
  //     title: i18next.t('menu_view-string--140-customer-chat'),
  //     tooltip: {
  //       html: `<img src="${customerChatImgSrc}" alt="" style="display:block;width:214px;height:160px;margin-bottom:12px;"/>
  // <div style="width:214px;">${i18next.t(
  //         'modernComponents.FlowBuilder.views.menuView.customer_chat_entry_point',
  //       )}</div>${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u>`,
  //       url: getLinkByVideoHash(EntryPointTutorialYoutube.customerChat),
  //     },
  //   }),
  commentsAutoreply: ({ isShowCommentsAutoreplyEntryPoint }) =>
    isShowCommentsAutoreplyEntryPoint
      ? {
          id: PluginType.comments_autoreply_entry_point,
          img: textures.CommentsAutoreplyIcon,
          platform: new PlatformHolder(Platform.facebook),
          subtype: BLOCK_SUBTYPES.entrypoint,
          type: MenuItemType.entrypoint,
          title: i18next.t(
            'modernComponents.EntryPointsModal.entryPoints.CommentsAutoreply.title',
          ),
          tooltip: {
            html: `<img src="${commentsAutoreplySrc}" alt="" style="display:block;width:214px;height:124px;margin-bottom: 12px;"/>
<div style="width:214px;">${i18next.t(
              'modernComponents.EntryPointsModal.entryPoints.CommentsAutoreply.description',
            )}</div>${oneRowMargin}<u>${i18next.t(
              'common.WatchTutorial',
            )} ↗</u>`,
            url: getLinkByVideoHash(
              EntryPointTutorialYoutube.commentsAutoreply,
            ),
          },
        }
      : undefined,
  adCommentsAutoreply: ({ isAdCommentsAutoreplyEnabled }) =>
    isAdCommentsAutoreplyEnabled
      ? {
          id: PluginType.ad_comments_autoreply_entry_point,
          img: textures.AdCommentsAutoreplyIcon,
          platform: new PlatformHolder(Platform.facebook),
          subtype: BLOCK_SUBTYPES.entrypoint,
          type: MenuItemType.entrypoint,
          title: i18next.t(
            'modernComponents.EntryPointsModal.entryPoints.AdCommentsAutoreply.title',
          ),
          tooltip: {
            html: `<img src="${adCommentsAutoreplySrc}" alt="" style="display:block;width:214px;height:160px;margin-bottom: 12px;"/>
    <div style="width:214px;">${i18next.t(
      'modernComponents.EntryPointsModal.entryPoints.AdCommentsAutoreply.description',
    )}</div>`,
          },
        }
      : undefined,
  facebookAdsSm: () => undefined,
  // facebookAdsSm: () => ({
  //   id: PluginType.ads_manager_sm_entry_point,
  //   img: textures.FacebookAdsSmIcon,
  //   platform: new PlatformHolder(Platform.facebook),
  //   subtype: BLOCK_SUBTYPES.entrypoint,
  //   type: MenuItemType.entrypoint,
  //   title: i18next.t('menu_view-string-1517-sponsored-messages'),
  //   tooltip: {
  //     html: `
  //      <img src="${facebookAdsSmSrc}" alt="" style="display:block;width:214px;height:124px;margin-bottom:16px;"/>
  //      <div>${i18next.t(
  //        'modernComponents.FlowBuilder.views.menuView.ads_manager_sm_entry_point',
  //      )}${oneRowMargin}<u>${i18next.t('common.WatchTutorial')}↗</u> </div>
  // `,
  //     url: getLinkByVideoHash(EntryPointTutorialYoutube.sponsoredMessages),
  //   },
  // }),
  facebookAdsCtm: () => ({
    id: PluginType.ads_manager_ctm_entry_point,
    img: textures.FacebookAdsCtmIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t('menu_view-string--903-click-to-messenger-ads'),
    tooltip: {
      html: `
      <img src="${facebookAdsCtmSrc}" alt="" style="display:block;width:214px;height:124px;margin-bottom:16px;"/>
      <div>${i18next.t(
        'modernComponents.FlowBuilder.views.menuView.ads_manager_ctm_entry_point',
      )}${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u> </div>
`,
      url: getLinkByVideoHash(EntryPointTutorialYoutube.clickToMessenger),
    },
  }),
  facebookAdsCtmJson: () => ({
    id: PluginType.ads_manager_ctm_json_entry_point,
    img: textures.FacebookAdsCtmJsonIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t('menu_view-string--903-click-to-messenger-ads-json'),
    tooltip: {
      html: `
      <img src="${facebookAdsCtmJsonSrc}" alt="" style="display:block;width:214px;height:124px;margin-bottom:16px;"/>
      <div>${i18next.t(
        'modernComponents.FlowBuilder.views.menuView.ads_manager_ctm_json_entry_point',
      )}${oneRowMargin}<u>${i18next.t('common.LearnMore')} ↗</u> </div>`,
      url: i18next.t('common.helpDocLinks.how_to_create_an_ad'),
    },
  }),
  instagramAdsCtm: () => ({
    id: PluginType.instagram_ads_manager_ctm_entry_point,
    img: textures.InstagramCTM,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t('menu_view-string-4252-instagram-ads'),
    tooltip: {
      html: `
      <img src="${instagramAdsCtmSrc}" alt="" style="display:block;width:214px;height:160px;margin-bottom:16px;"/>
      <div>${i18next.t(
        'modernComponents.FlowBuilder.views.menuView.instagram_ads_manager_ctm_entry_point',
      )}</div>${oneRowMargin}${learnMoreLink(
        i18next.t('common.helpDocLinks.entry_points'),
      )}${oneRowMargin}${troubleshootingLink(
        i18next.t('common.helpDocLinks.problems_with_ads'),
      )}
`,
    },
  }),
  facebookShops: () => ({
    id: PluginType.facebook_shops_entry_point,
    img: textures.FacebookShopsIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t('menu_view-string-1717-facebook-shops'),
    tooltip: {
      html: `
      <img src="${facebookShopsSrc}" alt="" style="display:block;width:214px;height:215px;margin-bottom:16px;"/>
      <div>${i18next.t(
        'modernComponents.FlowBuilder.views.menuView.facebook_shops_entry_point',
      )}${oneRowMargin}<u>${i18next.t('common.WatchTutorial')} ↗</u> </div>
`,
      url: getLinkByVideoHash(EntryPointTutorialYoutube.facebookShops),
    },
  }),
  zapierEp: ({ isShowEntryPoints }) =>
    isShowEntryPoints
      ? {
          id: PluginType.external_integration_entry_point,
          img: textures.ZapierIcon,
          platform: new PlatformHolder(Platform.facebook),
          type: MenuItemType.entrypoint,
          subtype: BLOCK_SUBTYPES.entrypoint,
          title: i18next.t('menu_view-string-1174-zapier-entry-point'),
          tooltip: {
            // TODO add link to video
            html: `<img src="${zapierSrc}" alt="" style="display:block;width:214px;height:75px;margin-bottom:12px;"/>
                         <div style="width:214px;">${i18next.t(
                           'modernComponents.FlowBuilder.views.menuView.external_integration_entry_point',
                         )}</div>`,
          },
        }
      : undefined,
  zapierEventTrigger: () => ({
    id: PluginType.event_trigger,
    img: textures.ZapierEventTriggerIcon,
    platform: new PlatformHolder(Platform.facebook, Platform.whatsapp),
    subtype: BLOCK_SUBTYPES.send_data,
    type: MenuItemType.block,
    title: i18next.t('menu_view-string--212-send-data-to-zapier'),
    tooltip: tooltips()[PluginType.event_trigger],
  }),
  zapierImportContent: () => ({
    id: PluginType.integration_zapier_plugin,
    img: textures.ZapierImportContentIcon,
    platform: new PlatformHolder(Platform.facebook),
    subtype: BLOCK_SUBTYPES.import_content,
    title: i18next.t('menu_view-string--757-import-content-via-zapier'),
    type: MenuItemType.block,
    tooltip: {
      html: i18next.t(
        'menu_view-string--174-import-content-from-another-app-to-your-chatbot-via-zapier',
      ),
    },
  }),
  googleSheet: () => ({
    id: PluginType.google_sheet,
    img: textures.pluginGoogleSheetIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    subtype: BLOCK_SUBTYPES.send_data,
    title: i18next.t('menu_view-string-7047-send-data-to-google-sheets'),
    type: MenuItemType.block,
    tooltip: tooltips().googleSheet,
  }),
  instagramDirect: () => ({
    id: PluginType.instagram_direct_entry_point,
    img: textures.InstagramDirectIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t('menu_view-string--182-instagram-direct'),
    tooltip: tooltips()[PluginType.instagram_direct_entry_point],
  }),
  instagramCommentsAutoreply: () => ({
    id: PluginType.instagram_comments_autoreply_entry_point,
    img: textures.InstagramCommentsAutoreplyIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t(
      'modernComponents.EntryPointsModal.entryPoints.InstagramCommentsAutoreply.title',
    ),
    tooltip: tooltips()[PluginType.instagram_comments_autoreply_entry_point],
  }),
  instagramStoryMention: () => ({
    id: PluginType.instagram_story_mention_entry_point,
    img: textures.InstagramStoryMentionIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t('menu_view-string--718-story-mention'),
    tooltip: tooltips()[PluginType.instagram_story_mention_entry_point],
  }),
  instagramStoryReply: () => ({
    id: PluginType.instagram_story_reply_entry_point,
    img: textures.InstagramStoryReplyIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t('menu_view-string--108-story-reply'),
    tooltip: tooltips()[PluginType.instagram_story_reply_entry_point],
  }),
  instagramLink: () => ({
    id: PluginType.instagram_bot_link_entry_point,
    img: textures.InstagramBotLinkIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t(
      'modernComponents.EntryPointsModal.entryPoints.InstagramLink.title',
    ),
    tooltip: tooltips()[PluginType.instagram_bot_link_entry_point],
  }),
  calendly: (config) =>
    config.isShowScheduleCalendlyEvent
      ? {
          defaultPluginId: PluginType.calendly,
          id: PluginType.calendly,
          img: textures.ScheduleCalendlyEventIcon,
          platform: new PlatformHolder(Platform.instagram, Platform.facebook),
          subtype: BLOCK_SUBTYPES.calendly,
          type: MenuItemType.block,
          title: i18next.t(
            'modernComponents.FlowBuilder.views.menuView.schedule_calendly_event',
          ),
          tooltip: tooltips().scheduleCalendlyEvent,
        }
      : undefined,
  calendlyEventConfirmation: (config) =>
    config.isShowCalendlySpecificItems || config.shouldShowCalendlyMenuItems
      ? {
          id: 'calendlyEventConfirmation',
          img: textures.CalendlyEventConfirmationIcon,
          platform: new PlatformHolder(Platform.instagram, Platform.facebook),
          shortcut: getBlockShortcut(BlockShortcutId.calendlyEventConfirmation),
          subtype: BLOCK_SUBTYPES.calendly_confirmation,
          type: MenuItemType.block,
          title: i18next.t('menu_view-string-1202-calendly-event-confirmation'),
          tooltip: tooltips().calendlyEventConfirmation,
        }
      : undefined,
  calendlyEventReminder: (config) =>
    config.isShowCalendlySpecificItems || config.shouldShowCalendlyMenuItems
      ? {
          id: 'calendlyEventReminder',
          img: textures.CalendlyEventReminderIcon,
          platform: new PlatformHolder(Platform.instagram, Platform.facebook),
          shortcut: getBlockShortcut(BlockShortcutId.calendlyEventReminder),
          subtype: BLOCK_SUBTYPES.calendly_reminder,
          type: MenuItemType.block,
          title: i18next.t('menu_view-string-3282-calendly-event-reminder'),
          tooltip: tooltips().calendlyEventReminder,
        }
      : undefined,
  orderReceipt: ({ isShowReceipt }) =>
    isShowReceipt
      ? {
          platform: new PlatformHolder(Platform.instagram, Platform.facebook),
          title: i18next.t('menu_view-string-9429-order-receipt'),
          img: textures.BlockOrderReceiptIcon,
          id: PluginType.order_receipt,
          subtype: BLOCK_SUBTYPES.receipt,
          type: MenuItemType.block,
          shortcut: getBlockShortcut(BlockShortcutId.receipt),
          tooltip: tooltips().receiptBlock,
        }
      : undefined,
  [PluginType.whatsapp_direct_entry_point]: () => ({
    id: PluginType.whatsapp_direct_entry_point,
    img: textures.WhatsappDirectIcon,
    platform: new PlatformHolder(Platform.whatsapp),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_direct',
    ),
    shortcut: getBlockShortcut(BlockShortcutId.whatsappDirect),
    tooltip: tooltips()[PluginType.whatsapp_direct_entry_point],
  }),
  [PluginType.whatsapp_bot_link]: () => ({
    id: PluginType.whatsapp_bot_link,
    img: textures.WhatsappBotLinkIcon,
    platform: new PlatformHolder(Platform.whatsapp),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_bot_link',
    ),
    tooltip: tooltips()[PluginType.whatsapp_bot_link],
  }),
  [PluginType.whatsapp_widget_entry_point]: () => ({
    id: PluginType.whatsapp_widget_entry_point,
    img: textures.WhatsappWitgetIcon,
    platform: new PlatformHolder(Platform.whatsapp),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_widget_entry_point',
    ),
    tooltip: tooltips()[PluginType.whatsapp_widget_entry_point],
  }),
  [PluginType.whatsapp_popup_entry_point]: () => ({
    id: PluginType.whatsapp_popup_entry_point,
    img: textures.WhatsupSignupFormIcon,
    platform: new PlatformHolder(Platform.whatsapp),
    subtype: BLOCK_SUBTYPES.entrypoint,
    type: MenuItemType.entrypoint,
    title: i18next.t(
      'modernComponents.FlowBuilder.blockTitles.whatsapp_popup_entry_point',
    ),
    tooltip: tooltips()[PluginType.whatsapp_popup_entry_point],
  }),
};

export const getPluginMenuItem: MenuItemsMap = {
  goToBlock: () => ({
    subtype: BLOCK_SUBTYPES.condition,
    id: PluginType.go_to_block_plugin,
    img: textures.ConditionIcon,
    type: MenuItemType.plugin,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    title: i18next.t('menu_view-string-5863-redirect-with-condition'),
  }),
  addAttribute: () => ({
    subtype: BLOCK_SUBTYPES.action,
    id: PluginType.add_attribute,
    img: textures.PluginAddAttributeIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    type: MenuItemType.plugin,
    title: i18next.t('menu_view-string-2074-set-user-attribute'),
    tooltip: tooltips().add_attribute,
  }),
  removeAttribute: () => ({
    subtype: BLOCK_SUBTYPES.action,
    id: PluginType.remove_attribute,
    img: textures.PluginRemoveAttributeIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    title: i18next.t('menu_view-string-4823-remove-user-attribute'),
    type: MenuItemType.plugin,
    tooltip: tooltips().remove_attribute,
  }),
  fbAnalytics: () => ({
    subtype: BLOCK_SUBTYPES.action,
    id: PluginType.facebook_analytics,
    img: textures.PluginFbAnalyticsIcon,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    title: i18next.t('menu_view-string--865-send-analytics-events-to-fb'),
    type: MenuItemType.plugin,
    tooltip: tooltips().fb_analytics,
  }),
  json: () => ({
    subtype: BLOCK_SUBTYPES.action,
    id: PluginType.json_plugin,
    img: textures.PluginJsonIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    title: i18next.t('menu_view-string-2090-send-json-request'),
    type: MenuItemType.plugin,
    tooltip: tooltips().json,
  }),
  text: () => ({
    id: PluginType.text,
    img: textures.PluginTextIcon,
    platform: new PlatformHolder(Platform.instagram),
    subtype: BLOCK_SUBTYPES.send_message,
    title: i18next.t('menu_view-string-2603-text'),
    type: MenuItemType.plugin,
    tooltip: tooltips().text,
  }),
  textAndButtons: () => ({
    id: PluginType.text,
    img: textures.PluginTextIcon,
    platform: new PlatformHolder(Platform.facebook, Platform.whatsapp),
    subtype: BLOCK_SUBTYPES.send_message,
    title: i18next.t('menu_view-string-6676-text-buttons'),
    type: MenuItemType.plugin,
    tooltip: tooltips().textAndButtons,
  }),
  image: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.image,
    img: textures.PluginImageIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    title: i18next.t('menu_view-string-7076-image'),
    type: MenuItemType.plugin,
  }),
  gallery: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.gallery,
    img: textures.PluginGalleryIcon,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    title: i18next.t('menu_view-string-1468-gallery'),
    type: MenuItemType.plugin,
  }),
  video: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.video,
    img: textures.PluginVideoIcon,
    platform: new PlatformHolder(
      Platform.facebook,
      Platform.whatsapp,
      Platform.instagram,
    ),
    title: i18next.t('menu_view-string-8265-video'),
    type: MenuItemType.plugin,
  }),
  audio: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.audio,
    img: textures.PluginAudioIcon,
    platform: new PlatformHolder(
      Platform.facebook,
      Platform.instagram,
      Platform.whatsapp,
    ),
    title: i18next.t('menu_view-string-6361-audio'),
    type: MenuItemType.plugin,
  }),
  document: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.document,
    img: textures.PluginDocumentIcon,
    platform: new PlatformHolder(Platform.whatsapp),
    title: i18next.t('menu_view-string-6361-document'),
    type: MenuItemType.plugin,
  }),
  otn: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.otn_request,
    img: textures.PluginOtnIcon,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('menu_view-string-1975-one-time-notification'),
    type: MenuItemType.plugin,
    tooltip: tooltips().otn,
  }),
  nTNDaily: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.nTNDaily,
    img: textures.PluginNTNIcon,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('nestedMenu.NTN.dailyTitle'),
    type: MenuItemType.plugin,
    shortcut: getBlockShortcut(BlockShortcutId.nTNDaily),
    tooltip: tooltips().nTNDaily,
  }),
  nTNWeekly: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.nTNWeekly,
    img: textures.PluginNTNIcon,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('nestedMenu.NTN.weeklyTitle'),
    type: MenuItemType.plugin,
    shortcut: getBlockShortcut(BlockShortcutId.nTNWeekly),
    tooltip: tooltips().nTNWeekly,
  }),
  nTNMonthly: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.nTNMonthly,
    img: textures.PluginNTNIcon,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('nestedMenu.NTN.monthlyTitle'),
    type: MenuItemType.plugin,
    shortcut: getBlockShortcut(BlockShortcutId.nTNMonthly),
    tooltip: tooltips().nTNMonthly,
  }),
  datePicker: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.datepicker_shortcut,
    defaultPluginId: PluginType.datepicker_shortcut,
    shortcut: getBlockShortcut(BlockShortcutId.datepicker),
    img: textures.PluginDatepickerIcon,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('nestedMenu.CollectUserData.datePicker'),
    type: MenuItemType.plugin,
    tooltip: tooltips().datePicker,
  }),
  dateAndTime: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.date_and_time_shortcut,
    defaultPluginId: PluginType.datepicker_shortcut,
    shortcut: getBlockShortcut(BlockShortcutId.dateAndTime),
    img: textures.PluginDatepickerIcon,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('nestedMenu.CollectUserData.dateAndTime'),
    type: MenuItemType.plugin,
    tooltip: tooltips().datePicker,
  }),
  email: () => ({
    subtype: BLOCK_SUBTYPES.action,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    title: i18next.t('menu_view-string--451-notify-admin-via-email'),
    type: MenuItemType.plugin,
    img: textures.PluginEmailIcon,
    id: PluginType.email,
    tooltip: tooltips().email,
  }),
  liveChat: () => ({
    subtype: BLOCK_SUBTYPES.action,
    id: PluginType.flowbuilder_livechat,
    img: textures.pluginLiveChatIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    title: i18next.t('menu_view-string--134-live-chat'),
    type: MenuItemType.plugin,
    shortcut: getBlockShortcut(BlockShortcutId.liveChat),
    tooltip: tooltips().liveChat,
  }),
  facebookShopProducts: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.facebook_shops_product_list,
    img: textures.pluginFacebookShopProductsIcon,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('menu_view-string-4888-product-gallery'),
    type: MenuItemType.plugin,
  }),
  googleSheet: () => ({
    id: PluginType.google_sheet,
    img: textures.pluginGoogleSheetIcon,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    subtype: BLOCK_SUBTYPES.action,
    title: i18next.t('menu_view-string-7047-send-data-to-google-sheets'),
    type: MenuItemType.plugin,
    tooltip: tooltips().googleSheet,
  }),
  zapierEventTrigger: () => ({
    id: PluginType.event_trigger,
    img: textures.pluginZapierIcon,
    platform: new PlatformHolder(Platform.facebook, Platform.whatsapp),
    subtype: BLOCK_SUBTYPES.action,
    title: i18next.t('menu_view-string--212-send-data-to-zapier'),
    type: MenuItemType.plugin,
    tooltip: tooltips()[PluginType.event_trigger],
  }),
  mediaShare: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    id: PluginType.media_share,
    img: textures.MediaShareIcon,
    platform: new PlatformHolder(Platform.instagram),
    title: i18next.t(
      'modernComponents.FlowBuilder.views.menu.plugins.mediaShare.title',
    ),
    type: MenuItemType.plugin,
    tooltip: {
      text: i18next.t(
        'modernComponents.FlowBuilder.views.menu.plugins.mediaShare.tooltip',
      ),
    },
  }),
  [PluginType.order_receipt]: () => ({
    subtype: BLOCK_SUBTYPES.receipt,
    platform: new PlatformHolder(Platform.facebook),
    title: i18next.t('menu_view-string--154-receipt'),
    type: MenuItemType.plugin,
    id: PluginType.order_receipt,
    img: textures.PluginOrderReceiptIcon,
    tooltip: tooltips().receiptPlugin,
  }),
  [PluginType.product_list_opt_in_confirmation_shortcut]: () => ({
    subtype: BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
    platform: new PlatformHolder(Platform.facebook),
    title: 'Opt-in Confirmation',
    type: MenuItemType.plugin,
    id: PluginType.product_list_opt_in_confirmation_shortcut,
    img: textures.PluginProductListIcon,
  }),
  [PluginType.product_list_back_in_stock_message_shortcut]: () => ({
    subtype: BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
    platform: new PlatformHolder(Platform.facebook),
    title: 'Back in Stock Message',
    type: MenuItemType.plugin,
    id: PluginType.product_list_back_in_stock_message_shortcut,
    img: textures.PluginProductListIcon,
  }),
  [PluginType.collect_answers_shortcut]: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    platform: new PlatformHolder(
      Platform.instagram,
      Platform.facebook,
      Platform.whatsapp,
    ),
    title: i18next.t('nestedMenu.CollectUserData.answers'),
    type: MenuItemType.plugin,
    id: PluginType.collect_answers_shortcut,
    defaultPluginId: PluginType.collect_answers_shortcut,
    img: textures.PluginCollectAnswersIcon,
    shortcut: getBlockShortcut(BlockShortcutId.collectAnswers),
  }),
  [PluginType.collect_emails_shortcut]: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    title: i18next.t('nestedMenu.CollectUserData.emails'),
    type: MenuItemType.plugin,
    id: PluginType.collect_emails_shortcut,
    defaultPluginId: PluginType.collect_emails_shortcut,
    img: textures.PluginCollectEmailsIcon,
    shortcut: getBlockShortcut(BlockShortcutId.collectEmails),
  }),
  [PluginType.collect_phones_shortcut]: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    title: i18next.t('nestedMenu.CollectUserData.phones'),
    type: MenuItemType.plugin,
    id: PluginType.collect_phones_shortcut,
    defaultPluginId: PluginType.collect_phones_shortcut,
    img: textures.PluginCollectPhonesIcon,
    shortcut: getBlockShortcut(BlockShortcutId.collectPhones),
  }),
  [PluginType.collect_numbers_shortcut]: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    platform: new PlatformHolder(Platform.instagram, Platform.facebook),
    title: i18next.t('nestedMenu.CollectUserData.numbers'),
    type: MenuItemType.plugin,
    id: PluginType.collect_numbers_shortcut,
    defaultPluginId: PluginType.collect_numbers_shortcut,
    img: textures.PluginCollectNumbersIcon,
    shortcut: getBlockShortcut(BlockShortcutId.collectNumbers),
  }),
  [PluginType.whatsapp_list]: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    platform: new PlatformHolder(Platform.whatsapp),
    title: i18next.t(
      'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.title',
    ),
    type: MenuItemType.plugin,
    id: PluginType.whatsapp_list,
    img: textures.PluginWhatsappListIcon,
    tooltip: tooltips().listPlugin,
  }),
  [PluginType.whatsapp_template]: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    platform: new PlatformHolder(Platform.whatsapp),
    title: i18next.t(
      'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.title',
    ),
    type: MenuItemType.plugin,
    id: PluginType.whatsapp_template,
    img: textures.PluginWhatsappTemplateIcon,
    tooltip: tooltips().whatsappTemplate,
  }),
  [PluginType.whatsapp_location]: () => ({
    subtype: BLOCK_SUBTYPES.send_message,
    platform: new PlatformHolder(Platform.whatsapp),
    title: i18next.t(
      'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.title',
    ),
    type: MenuItemType.plugin,
    id: PluginType.whatsapp_location,
    img: textures.PluginWhatsappLocationIcon,
    tooltip: tooltips().locationPlugin,
  }),
};

export const menuButtons: MenuItemsMap = {
  connectToExistingBlock: () => ({
    type: MenuItemType.button,
    id: MenuButtonId.connectToExistingBlock,
    title: i18next.t('menu_view-string-2610-connect-to-existing-block'),
    img: textures.ConnectBlockIcon,
  }),
};

type MenuNodesType = () => Record<string, IMenuNode<GetMenuItemByConfig>>;

export const blockMenuNodes: MenuNodesType = memoize(() => ({
  contentBlocks: {
    id: BlockMenuId.contentBlocks,
    type: MenuItemType.block,
    displayMode: NestedMenuNodeDisplayMode.structure,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.menu.nodes.contentBlocks',
    ),
  },
  customAiPlugins: {
    newLabel: true,
    id: BlockMenuId.customAiPlugins,
    type: MenuItemType.block,
    displayMode: NestedMenuNodeDisplayMode.structure,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.menu.nodes.customAiPlugins',
    ),
  },
  collectUserData: {
    id: BlockMenuId.collectUserData,
    type: MenuItemType.block,
    displayMode: NestedMenuNodeDisplayMode.structure,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.menu.nodes.collectUserData',
    ),
  },
  actionItems: {
    id: BlockMenuId.actionItems,
    type: MenuItemType.block,
    displayMode: NestedMenuNodeDisplayMode.noTitlteStructure,
  },
}));

export const menuNodes: MenuNodesType = memoize(() => ({
  collectUserData: {
    type: MenuItemType.node,
    id: MenuNodeId.collectUserData,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.menu.nodes.collectUserData',
    ),
    img: textures.CollectUserDataIcon,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  contextItems: {
    type: MenuItemType.node,
    id: MenuNodeId.contextItems,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  buttons: {
    type: MenuItemType.node,
    id: MenuNodeId.buttons,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  entryPoints: {
    type: MenuItemType.node,
    id: MenuNodeId.entryPoints,
    title: i18next.t('menu_view-string-1082-entry-points'),
    img: textures.EntryPointsIcon,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  integrations: {
    type: MenuItemType.node,
    id: MenuNodeId.integrations,
    title: i18next.t('menu_view-string--147-integration'),
    img: textures.IntegrationsIcon,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  customAiPlugins: {
    newLabel: true,
    type: MenuItemType.node,
    id: MenuNodeId.customAiPlugins,
    title: i18next.t('nestedMenu.CustomAiPlugins'),
    img: textures.CustomAiPluginIcon,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  notifications: {
    type: MenuItemType.node,
    id: MenuNodeId.contentBlocks,
    title: i18next.t('nestedMenu.Notifications'),
    img: textures.NotificationsIcon,
    displayMode: NestedMenuNodeDisplayMode.nested,
    platform: new PlatformHolder(Platform.facebook),
  },
  contentBlocks: {
    type: MenuItemType.node,
    id: MenuNodeId.contentBlocks,
    title: i18next.t('menu_view-string--106-content-blocks'),
    img: textures.ContentBlocksIcon,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  actions: {
    type: MenuItemType.node,
    id: MenuNodeId.actions,
    title: i18next.t('menu_view-string-5028-actions'),
    img: textures.ActionsIcon,
    displayMode: NestedMenuNodeDisplayMode.nested,
  },
  facebook: {
    type: MenuItemType.node,
    id: MenuNodeId.facebook,
    title: i18next.t('modernComponents.FlowBuilder.views.menu.groups.facebook'),
    displayMode: NestedMenuNodeDisplayMode.structure,
    platform: new PlatformHolder(Platform.facebook),
  },
  website: {
    type: MenuItemType.node,
    id: MenuNodeId.website,
    title: i18next.t('modernComponents.FlowBuilder.views.menu.groups.website'),
    displayMode: NestedMenuNodeDisplayMode.structure,
    platform: new PlatformHolder(Platform.facebook),
  },
}));
