import i18next from 'i18next';
import {
  getQuantId,
  QuantIds,
  QUANT_MULTIPLEXER,
  getRegularValue,
  getQuantValue,
  QuantItem,
} from '@utils/DateTime/quant';
import { propEq, identity } from 'ramda';
import { HLayout } from '../../components/Elements/Layouts';
import { HTMLText } from '../../components/Elements/Shapes';
import { FontWeightKey } from '../../components/Elements/Shapes/types';
import { PropFunc } from '../../types';
import { Dropdown } from './Dropdown';
import { TextEditView } from '../text_edit_view';
import { ControlVisibility } from '../../FlowBuilderOverlay';
import { resByFunc } from '../utils';
import { inactiveStrokeColor, mainStrokeColor } from '../plugin_consts';
import { HEXColors } from '@ui/_common/colors';

export enum LabelPosition {
  start = 'start',
  end = 'end',
}

interface DelayInputProps {
  quantItems: QuantItem[];
  initialValue: number | null;
  onChange: (value: number) => void;
  getPlaceholderTitle?: (value: string, quantId: QuantIds) => string;
  fontStyle?: FontWeightKey;
  editable?: PropFunc<boolean>;
  label?: string;
  max?: number;
  min?: number;
  allowFraction?: boolean;
  labelPosition?: LabelPosition;
}

const MAX_VALUE_BY_QUANT = 99;
const MIN_VALUE_BY_QUANT = 0;

const getMaxForQuant = (max: number | undefined, quantId: QuantIds) =>
  max
    ? Math.min(MAX_VALUE_BY_QUANT, max / (QUANT_MULTIPLEXER[quantId] || 0))
    : MAX_VALUE_BY_QUANT;

const getMinForQuant = (min: number | undefined, quantId: QuantIds) =>
  min
    ? Math.max(MIN_VALUE_BY_QUANT, min / (QUANT_MULTIPLEXER[quantId] || 0))
    : MIN_VALUE_BY_QUANT;

const FRACTION_REGEXP = /.0$/;

const toStringSafe = (value: number) =>
  value.toFixed(1).replace(FRACTION_REGEXP, '');

export class DelayInput extends HLayout {
  TEST_NAME = 'DelayInput';

  static QUANT_IDS = QuantIds;

  private readonly quantSelectorView: Dropdown<QuantItem>;
  private readonly props: DelayInputProps;
  private readonly valueFieldView: TextEditView;
  private readonly valuePlaceholderFieldView: HTMLText;
  private titlePlaceholderView: HTMLText;

  constructor(props: DelayInputProps) {
    super({
      height: 36,
    });
    this.props = props;
    const {
      initialValue,
      onChange,
      editable = true,
      label,
      max,
      min,
      fontStyle,
      allowFraction = true,
      labelPosition = LabelPosition.start,
    } = props;

    const safeInitialValue = initialValue || 0;

    const quantId = getQuantId(safeInitialValue);

    const background = {
      fill: HEXColors.white,
      opacity: 1,
      cornerRadius: 4,
      strokeWidth: 1,
    };

    const handleChange = () => {
      const reqularValue = parseFloat(this.valueFieldView.text());
      const value = getQuantValue(
        reqularValue,
        this.quantSelectorView.selectedItem.id as QuantIds,
      );
      onChange(Number.isNaN(value) ? 0 : value);
    };

    this.titlePlaceholderView = new HTMLText({
      text: label || window.i18next.t('DelayInput-string-2043-delay'),
      fontSize: 15,
      trustedHtml: true,
      height: 36,
      verticalAlign: 'center',
    });

    if (labelPosition === LabelPosition.start) {
      this.addToLayout(this.titlePlaceholderView, { margin: { right: 5 } });
    }

    this.valueFieldView = new TextEditView(
      {
        text:
          quantId === QuantIds.instantly
            ? '0'
            : toStringSafe(getRegularValue(safeInitialValue, quantId)),
        fontSize: 15,
        fontStyle,
        trustedHtml: true,
        height: 36,
        width: 47,
        align: 'center',
        verticalAlign: 'center',
        numbersOnly: true,
        numbersFractionAllowed: allowFraction,
        min: (allowFraction ? identity : Math.ceil)(
          getMinForQuant(min, quantId),
        ),
        max: getMaxForQuant(max, quantId),
        maxLength: 3,
        singleLine: true,
        background: {
          ...background,
          stroke: (v: any) =>
            v._textAreaShown ? mainStrokeColor : inactiveStrokeColor,
        },
      },
      undefined,
      undefined,
      () => {
        const clearValue = parseFloat(
          this.valueFieldView.text().replace(',', '.'),
        );
        this.valueFieldView.text(
          Number.isNaN(clearValue) ? '0' : toStringSafe(clearValue),
        );
        if (clearValue === 0 || Number.isNaN(clearValue)) {
          this.quantSelectorView.selectedItem = this.props.quantItems.find(
            propEq('id', QuantIds.instantly),
          )!;
        }
        handleChange();
        this.renderNode();
      },
      {
        symbolsLimit: ControlVisibility.hide,
      },
    );

    this.addToLayout(this.valueFieldView, {
      margin: { right: 8 },
      gone: () =>
        this.quantSelectorView?.selectedItem.id === QuantIds.instantly ||
        !resByFunc(editable),
    });

    const { quantItems } = this.props;
    this.quantSelectorView = new Dropdown<QuantItem>({
      items: quantItems.slice(
        min ? quantItems.findIndex(propEq('id', getQuantId(min))) : 0,
        max
          ? quantItems.findIndex(propEq('id', getQuantId(max))) + 1
          : quantItems.length,
      ),
      defaultSelectedItem:
        quantItems.find(propEq('id', quantId)) ?? quantItems[0],
      onChange: ({ id }) => {
        if (id !== QuantIds.instantly) {
          if (max) {
            this.valueFieldView._props.max = getMaxForQuant(
              max,
              id as QuantIds,
            );
          }
          if (min) {
            this.valueFieldView._props.min = getMinForQuant(
              min,
              id as QuantIds,
            );
            if (!allowFraction) {
              this.valueFieldView._props.min = Math.ceil(
                this.valueFieldView._props.min,
              );
            }
          }
          this.valueFieldView.startEditing();
        }
        this.renderNode();
        handleChange();
      },
      height: 36,
      verticalAlign: 'center',
      padding: { left: 12, right: 12 },
      background: {
        ...background,
        stroke: (v: any) =>
          v.overlayShown ? mainStrokeColor : inactiveStrokeColor,
      },
    });

    this.addToLayout(this.quantSelectorView, {
      gone: () => !resByFunc(editable),
    });

    this.valuePlaceholderFieldView = new HTMLText({
      fontSize: 15,
      trustedHtml: true,
      height: 36,
      verticalAlign: 'center',
    });

    this.addToLayout(this.valuePlaceholderFieldView, {
      gone: () => resByFunc(editable),
    });

    if (labelPosition === LabelPosition.end) {
      this.addToLayout(this.titlePlaceholderView, { margin: { right: 5 } });
    }
  }

  updatePlaceholder() {
    const quantItem = this.quantSelectorView.selectedItem;
    const value = this.valueFieldView.text();
    const isInstantly = quantItem.id === QuantIds.instantly;
    this.valuePlaceholderFieldView.text(
      `${isInstantly ? '' : `${value} `}${i18next.t(quantItem.titleKey, {
        count: parseFloat(value),
      })}`,
    );
    if (this.props.getPlaceholderTitle) {
      this.titlePlaceholderView.text(
        this.props.getPlaceholderTitle(
          this.titlePlaceholderView.text(),
          quantItem.id as QuantIds,
        ),
      );
    }
  }

  onBeforeRender() {
    this.quantSelectorView.setWidth(
      this.quantSelectorView.selectedItem.id === QuantIds.instantly ? 155 : 100,
    );
    this.updatePlaceholder();
  }
}
