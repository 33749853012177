import { EntryPointPersistentMenu } from './EntryPointPersistentMenu';
import { EntryPointPersistentMenuCard } from './types';
import { Node } from '../../../Node';
import { validateIsInstagramPageConnected } from '../common/utils/validateIsInstagramPageConnected';

export class EntryPointInstagramPersistentMenu extends EntryPointPersistentMenu {
  constructor(card: EntryPointPersistentMenuCard, node: Node) {
    super(card, node, {
      testName: 'EntryPointInstagramPersistentMenu',
      updateButtonTextKey:
        'EntryPointPersistentMenu-string--359-sync-changes-to-instagram',
    });
  }
  validationError() {
    return (
      validateIsInstagramPageConnected(this._node) || super.validationError()
    );
  }
}
