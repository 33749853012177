import i18next from 'i18next';
import { PermissionGroup } from '@utils/Permissions';
import { adCommentsAutoreplyEntryPointFragment_config as EntryPointAdCommentsAutoreplyConfig } from '../../../../../Plugins/AdCommentsAutoreplyEntryPoint/@types/adCommentsAutoreplyEntryPointFragment';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { Node } from '../../../../Node';
import { validateIsPageConnecting } from '../../common/utils/validateIsPageConnecting';
import { PostsSelector } from '../components/PostsSelector';
import { getI18nCommentsAutoreplyKey } from '../utils/getI18nCommentsAutoreplyKey';
import { LinkedLayout } from '../../../../components/Elements/Layouts/types';
import {
  commentsAutoReplyAllUsersLineMenu,
  commentsAutoReplyLineMenu,
} from '../../../Menu/createLineMenu';
import { LineStartSubtype } from '../../../components/Line/types';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '@components/FlowBuilder/StatefulPlugin';
import { CommentsAutoreplyCommon } from '../components/CommentsAutoreplyCommon';
import { MessengerAdCommentsAutoreplyPostsSelector } from './MessengerAdCommentsAutoreplyPostsSelector';
import { validateIsGivenPermissions } from '../../common/utils/validateIsGivenPermission';
import { validateIsAdAccountConnected } from '../../common/utils/validateIsAdAccountConnected';

export class EntryPointAdCommentsAutoreply
  extends CommentsAutoreplyCommon<EntryPointAdCommentsAutoreplyConfig>
  implements StatefulPluginDelegate<EntryPointAdCommentsAutoreplyConfig>
{
  // TODO fix any
  getPostSelectorView(): PostsSelector<any> {
    return new MessengerAdCommentsAutoreplyPostsSelector({
      plugin: this,
      logger: this.logger,
    });
  }

  TEST_NAME = 'EntryPointAdCommentsAutoreply';

  constructor(
    state: StatefulPlugin<EntryPointAdCommentsAutoreplyConfig>,
    node: Node,
  ) {
    super(state, node, {
      validatorOptions: {
        postSelectorErrorMessage: i18next.t(
          getI18nCommentsAutoreplyKey(
            'BoostedPosts.postSelectorValidationText',
          ),
        ),
        commentsPermissionsErrorMessage: i18next.t(
          getI18nCommentsAutoreplyKey('BoostedPosts.permissionsValidationText'),
        ),
        permissionGroup: PermissionGroup.comments,
      },
      replyToOptions: {
        targetPlugin: PluginType.ad_comments_autoreply_entry_point,
        lineParams: {
          items: commentsAutoReplyAllUsersLineMenu,
          searchable: false,
          menuStyle: { minWidth: 0 },
          subtype: LineStartSubtype.commentsAutoreplyAllUsers,
        },
      },
      replyToForCurrentSubscribersOptions: {
        targetPlugin: PluginType.ad_comments_autoreply_entry_point,
        lineParams: {
          items: commentsAutoReplyLineMenu,
          searchable: false,
          menuStyle: { minWidth: 0 },
          subtype: LineStartSubtype.commentsAutoreplyOldUsers,
        },
      },
    });
  }

  onBeforeRender() {
    const { config } = this.state.data;

    (
      this.replyToForCurrentSubscribers.keywordGroupsBox.views() as LinkedLayout[]
    ).forEach(({ lineStartView }) => {
      // eslint-disable-next-line no-param-reassign
      lineStartView._lineView.gone = !config.already_subscribed_setup_enabled;
    });

    this.replyToForCurrentSubscribers.lineStartView._lineView.gone =
      !config.already_subscribed_setup_enabled;
  }

  validationError() {
    return (
      validateIsPageConnecting(this._node) ||
      validateIsGivenPermissions(
        PermissionGroup.ads,
        i18next.t(
          'EntryPointFacebookAds-string--156-grant-chatfuel-permission-to-manage-your-facebook-ads',
        ),
        () => this.logger.log('Grant Permission tooltip click'),
      ) ||
      validateIsAdAccountConnected(this.state.data.validation_details) ||
      super.validationError()
    );
  }

  destroy() {
    super.destroy();
  }
}
