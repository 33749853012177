import { HEXColors } from '@ui/_common/colors';
import { Texture } from 'pixi.js-legacy';
import { ExternalAccountsData } from '@utils/Integrations/common/ExternalAccount';
import {
  LayoutProps,
  MainLayout,
} from '../../../../components/Elements/Layouts';
import { DownArrow } from '../../../../components/Elements/Shapes';
import { getPixiFieldStrict } from '../../../../PixiFieldRepository';
import { logFlowEvent } from '../../../../utils/Analytics';
import { ButtonView } from '../../ButtonView';
import { showExternalAccountOverlay } from './showExternalAccountOverlay';

interface ExternalAccountSelectorProps extends LayoutProps {
  getData: () => ExternalAccountsData;
  onDisconnectRequest: () => void;
}

export class ExternalAccountSelector extends MainLayout {
  private button: ButtonView | undefined;
  private readonly onDisconnectRequest: () => void;
  private readonly getData: () => ExternalAccountsData;
  private currentAccountId: string | null | undefined;
  private props: LayoutProps;

  constructor({
    onDisconnectRequest,
    getData,
    ...props
  }: ExternalAccountSelectorProps) {
    super(props);
    this.props = props;
    this.onDisconnectRequest = onDisconnectRequest;
    this.getData = getData;
  }

  onBeforeRender() {
    this.updateView(this.getData());
  }

  private updateView(config: ExternalAccountsData) {
    if (this.currentAccountId === config.currentAccountId) {
      return;
    }

    this.currentAccountId = config.currentAccountId;

    if (this.button) {
      this.removeView(this.button);
      this.button.destroy();
    }

    if (!config?.currentAccountId) {
      return;
    }

    const { currentAccountId, accounts } = config;

    const currentAccount =
      accounts.find(({ id }) => id === currentAccountId) || accounts[0];

    this.button = new ButtonView({
      title: `<div style="display:inline-block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:215px;">${currentAccount.name}</div>`,
      icon: Texture.from(currentAccount.iconSrc),
      onClick: (event: any) => {
        event.stopPropagation();
        const { x, y } = this.globalPosition();
        const { scale } = getPixiFieldStrict().viewport;
        if (config) {
          showExternalAccountOverlay({
            externalAccountsData: config,
            point: {
              x,
              y: y + this.height() * scale.y,
            },
            onDisconnectRequest: this.onDisconnectRequest,
          });
        }
        logFlowEvent('external account manager', 'current account click');
      },
      background: {
        fill: HEXColors.white,
        stroke: HEXColors.greyLight20,
        strokeWidth: 1,
      },
      width: this.props.width,
    });

    this.button.addToLayout(
      new DownArrow({ width: 9, height: 7, fill: HEXColors.black }),
      {
        align: 'end',
        verticalAlign: 'center',
        margin: {
          left: -16,
        },
      },
    );

    this.addToLayout(this.button);
  }
}
