import { HEXColors } from '@ui/_common/colors';
import { customerChatEntryPointFragment_config as CustomerChatEntryPointConfig } from '@components/Plugins/CustomerChatEntryPoint/@types/customerChatEntryPointFragment';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { Node } from '../../../Node';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { logFlowEvent } from '../../../utils/Analytics';
import { ShopifyAccount } from '../../components/ShopifyAccount/ShopifyAccount';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { prepareCustomerChatStats } from '../common/components/CustomerChatView/utils';
import { showConnectPageDialog } from '../common/utils/showConnectPageDialog';
import { validateBlockLeadInEntryPoint } from '../common/utils/validateBlockLeadInEntryPoint';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { validateIsShopifyConnected } from '../common/utils/validateIsShopifyConnected';
import { MainLayout } from '../../../components/Elements/Layouts';
import { CustomerChatView } from '../common/components/CustomerChatView';

export class EntryPointShopifyCustomerChat
  extends MainLayout
  implements StatefulPluginDelegate<CustomerChatEntryPointConfig>
{
  TEST_NAME = 'EntryPointShopifyCustomerChat';

  public _node: Node;
  private state: StatefulPlugin<CustomerChatEntryPointConfig>;
  private connectedToShopify: boolean | undefined;
  private readonly customerChatView: MainLayout;

  constructor(state: StatefulPlugin<CustomerChatEntryPointConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
        strokeWidth: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.addToLayout(
      new ShopifyAccount({
        eventPropertyBag: { pluginId: PluginType.customer_chat_entry_point },
        onConnectedAccountStatusChange: (account) => {
          this.connectedToShopify = !!account;
          this.renderNode();
        },
        blockId: node.block.id,
        onPageConnectRequest: () => {
          showConnectPageDialog(node, this.state.data);
        },
      }),
      {
        margin: {
          bottom: 16,
        },
      },
    );

    this.on('click', () => {
      logFlowEvent('entry_point', 'open customer chat settings');
      getPixiFieldStrict().fixBlockPosition(node.blockView, getPanelWidth());
      editPluginInEditorPanel(node.id, this.state.data, state);
    });

    this.customerChatView = new MainLayout({
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 12,
      },
    }).addToLayout(
      new CustomerChatView({
        node,
        getData: () => ({
          config: this.state.data.config,
          stats: prepareCustomerChatStats(
            this.state.data.id,
            this._node.block.id,
          ),
        }),
        onChange: (updatedConfig) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              ...updatedConfig,
            },
          }));
          this.state.save();
        },
      }),
      {
        margin: { top: 16, bottom: 16 },
      },
    );

    this.addToLayout(this.customerChatView, {
      margin: { left: 1, top: 56 },
    });
  }

  pluginDidSet() {
    this.renderNode();
  }

  validationError() {
    return (
      validateIsPageConnecting(this._node) ||
      validateIsShopifyConnected(this.connectedToShopify, this._node) ||
      validateBlockLeadInEntryPoint(this.state.data.config)
    );
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
