import React from 'react';
import { Type } from '@ui/Type';
import * as css from './NoResultFound.css';

export const NoResultFound = () => (
  <div className={css.wrapper}>
    <div className={css.messageBlock}>
      <div className={css.header}>
        <Type as="h4" weight="medium" size="18px">
          {window.i18next.t('NoResultFound-JSXText-2047-no-result-found')}
        </Type>
      </div>
      <Type size="15px_DEPRECATED">
        {window.i18next.t(
          'NoResultFound-JSXText-1777-try-broadening-your-search',
        )}
      </Type>
    </div>
  </div>
);
