import React, { useEffect } from 'react';
import cn from 'classnames';
import { log } from 'cf-common/src/logger';
import { CenteredLoader } from '@ui/Loader';
import { Input } from '@ui/Input';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { GoogleMapsPlacePickerParams, useGoogleMapsPlacePicker } from './hooks';
import { LocationPickerPlace } from './types';
import { Icon } from '@ui/Icon';
import * as css from './GoogleMapsPlacePicker.css';

export interface GoogleMapsPlacePickerProps
  extends Omit<GoogleMapsPlacePickerParams, 'onError'> {
  location?: LocationPickerPlace;
}

export const GoogleMapsPlacePicker: React.FC<GoogleMapsPlacePickerProps> = ({
  location,
  setPlace,
}) => {
  const { t } = useSafeTranslation();

  const { mapId, inputId, containerInputId, loadMaps } =
    useGoogleMapsPlacePicker({
      location,
      setPlace,
      onError: (error, data) =>
        log.error({
          msg: 'Google maps location picker error',
          error,
          data,
        }),
    });

  useEffect(loadMaps, []);

  return (
    <>
      <div
        id={containerInputId}
        className={cn(css.inputContainer, css.inputPositionWorkaround)}
      >
        <Input
          id={inputId}
          renderIcon={() => <Icon icon="search" color="baseTertiary" />}
          type="text"
          placeholder={t(
            'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.placeholder',
          )}
          className={css.input}
        />
      </div>
      <div id={mapId} className={css.mapContainer}>
        <CenteredLoader />
      </div>
    </>
  );
};
