import i18next from 'i18next';
import debounce from 'lodash-es/debounce';
import { clone } from 'ramda';
import { HEXColors } from '../../../modern-ui/_common/colors';
import { Toaster } from '@services/index';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import type { BlockView } from './block_view';
import { getFlowPlatform } from '../utils/getFlowPlatform';
import { isApolloBasedPlugin } from './plugins.configuration';
import {
  getPluginData,
  updatePluginData,
} from '../StatefulPlugin/ApolloState/utils';
import { logFlowEvent, logFlowPluginEvent } from '../utils/Analytics';
import { tooltipScaled } from './helpers/TooltipHelpers';
import {
  getIconByBlock,
  getToggleLabel,
  isFacebookAds,
  isInstagramAds,
} from './utils';
import {
  Alignment,
  BackgroundProps,
  HLayout,
} from '../components/Elements/Layouts';
import { HTMLText, TextureShape } from '../components/Elements/Shapes';
import { BLOCK_SUBTYPES, disabledTheme, isRootBlock } from '../consts';
import { TextEditView } from './text_edit_view';
import { PluginType } from '../../Plugins/common/PluginTypes';
import { FB_LIMIT_TT_TEXT } from './entry-points/EntryPointPersistentMenu';
import { Toggle } from './kit/toggle';
import { MessageTagView, updateMessageTag } from './components/MessageTag';
import {
  EntryPointEventTrigger,
  entryPointsEmitter,
  EntryPointsEvents,
} from './entry-points/events';
import { getEntryPointCard } from './entry-points/common/utils';
import { resolveEntryPointConflict } from './entry-points/common/resolveEntryPointConflict';
import { emitOnbordingEntryPointToggle } from './entry-points/common/emitOnbordingEntryPointToggle';
import {
  ACTIVENESS_LABEL_WIDTH,
  ACTIVENESS_MARGIN,
  ALERT_VIEW_MARGIN,
  ALERT_VIEW_SIZE,
  ENTRY_POINT_HEADER_MARGIN,
  ENTRY_POINT_ICON_MARGIN,
  ENTRY_POINT_TEXT_VIEW_LAYOUT_MARGIN,
  ENTRY_POINT_TEXT_VIEW_MARGIN,
  HEADER_MARGIN,
  ICON_MARGIN,
  ICON_SIZE,
  TEXT_VIEW_LAYOUT_MARGIN,
  TEXT_VIEW_MARGIN,
  TOGGLE_MARGIN,
  TOGGLE_WIDTH,
} from './constants';
import { blockWidth, mainStrokeColor } from './plugin_consts';
import { refetchFlowBlockAndUpdateInFlowBlocksCache } from '../api/refetchFlowBlock';
import { removeEntity } from '../components/Modals';
import { OnboardingTourShape, Platform } from '@globals';
import type { Node } from '../Node';
import type { Card } from '../types';

export const WITH_MESSAGE_TAG_BLOCKS = [
  BLOCK_SUBTYPES.send_message,
  BLOCK_SUBTYPES.import_content,
  BLOCK_SUBTYPES.reminder,
  BLOCK_SUBTYPES.visitedProductsReminder,
  BLOCK_SUBTYPES.receipt,
  BLOCK_SUBTYPES.calendly_confirmation,
  BLOCK_SUBTYPES.calendly_reminder,
  BLOCK_SUBTYPES.postPurchase,
  BLOCK_SUBTYPES.shopify_discount,
  BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
  BLOCK_SUBTYPES.back_in_stock_message,
];

export class BlockTitleView extends HLayout {
  TEST_NAME = 'BlockTitleView';

  subtype: string;
  isEntryPoint: boolean | undefined | null;
  isRootBlock: boolean;
  title: string;
  entryPointCard: Card;
  _node: Node;
  _messageTagView: any;
  titleView: TextEditView | undefined;
  blockView;
  toggle: Toggle | undefined;
  label: HTMLText | undefined;
  isHeaderDisabled: boolean | undefined;
  icon: TextureShape | undefined;

  constructor(subtype: string, node: Node, blockView: BlockView) {
    super({
      height: 36,
      background: {
        cornerRadius: 4,
      },
    });

    this.subtype = subtype;
    this.isEntryPoint = subtype === BLOCK_SUBTYPES.entrypoint;
    this.isRootBlock = isRootBlock(subtype);
    this.blockView = blockView;
    this.title = '';
    this._node = node;
    this.entryPointCard = getEntryPointCard(this._node.block.cards)!;

    this.addIconToLayout();
    this.addTitleViewToLayout();

    if (WITH_MESSAGE_TAG_BLOCKS.includes(subtype)) {
      this.addMessageTagToLayout();
    }

    if (this.isEntryPoint) {
      if (
        !(
          isFacebookAds(this.entryPointCard.plugin_id) ||
          isInstagramAds(this.entryPointCard.plugin_id)
        )
      ) {
        this.addLabeledToggleToLayout();
      }

      if (
        [
          PluginType.persistent_menu_entry_point,
          PluginType.instagram_persistent_menu_entry_point,
        ].includes(this.entryPointCard?.plugin_id)
      ) {
        tooltipScaled({
          view: this.toggle,
          text: FB_LIMIT_TT_TEXT(),
        });
      }
    }
  }

  addMessageTagToLayout() {
    const messageTagView = new MessageTagView({
      block: this._node.block as any,
      isEditing: () => this._node.isEditing,
      onChange: ({ tag, tagType }) => {
        updateMessageTag(this._node, tag, tagType);
        this.blockView.renderNode();
      },
    });
    this._messageTagView = messageTagView;
    this.layout(messageTagView, {
      gone: () => getFlowPlatform() !== Platform.facebook,
    });
  }

  addTitleViewToLayout() {
    const width = this.calculateTextViewWidth();

    const entryPointTitleStyles: BackgroundProps = {
      // @ts-expect-error Stroke doesnt work if fill wasn't passed
      fill: 'transparent',
      opacity: 0,
      stroke: HEXColors.white,
      strokeOpacity: 0.4,
    };

    const defaultStyles = {
      fill: HEXColors.white,
      stroke: mainStrokeColor,
    };

    this.titleView = new TextEditView(
      {
        align: 'left',
        fontSize: 18,
        fontStyle:
          this.isEntryPoint || this.isRootBlock ? 'normal' : 'semibold',
        fill:
          this.isEntryPoint || this.isRootBlock
            ? HEXColors.white
            : HEXColors.black,
        width,
        height: 36,
        editable: false,
        singleLine: true,
        maxLength: 60,
        margin: this.isEntryPoint
          ? ENTRY_POINT_TEXT_VIEW_MARGIN
          : TEXT_VIEW_MARGIN,
        verticalAlign: 'center',
        background: {
          ...(this.isEntryPoint || this.isRootBlock
            ? entryPointTitleStyles
            : defaultStyles),
          cornerRadius: 4,
          strokeAlignment: Alignment.inner,
          strokeWidth: (view) => (view?._textAreaShown ? 2 : 0),
        },
      },
      undefined,
      (text) => {
        this.title = text.trim();
      },
      () => {
        if (this._node.block.title !== this.title) {
          logFlowEvent('block', 'edit title', {
            source: 'click',
            blockId: this._node.id,
          });
          this._node.block.title = this.title;
          this._node.updateBlock(() => {
            entryPointsEmitter.emit(EntryPointEventTrigger.flowBuilder, {
              data: {
                event: EntryPointsEvents.rename,
                flowId: this._node.controller.flow.id,
              },
            });
            if (this._node.controller) {
              refetchFlowBlockAndUpdateInFlowBlocksCache(
                this._node.id,
                this._node.controller.flow,
              );
            }
          });
        }
        this.titleView!.text(this.title);
      },
    );

    this.layout(this.titleView, {
      margin: this.isEntryPoint
        ? ENTRY_POINT_TEXT_VIEW_LAYOUT_MARGIN
        : TEXT_VIEW_LAYOUT_MARGIN,
    });
  }

  addIconToLayout() {
    const iconWrapper = new HLayout({
      background: {
        fill: HEXColors.white,
        stroke: HEXColors.greyLight20,
        strokeWidth: 1,
        cornerRadius: 4,
      },
      width: ICON_SIZE,
      height: ICON_SIZE,
    });

    this.icon = new TextureShape({
      texture: getIconByBlock(
        this._node.block,
        this.isEntryPoint ? !!this.entryPointCard.enabled : true,
      ),
      width: ICON_SIZE,
      height: ICON_SIZE,
    });

    iconWrapper.addToLayout(this.icon);

    this.layout(iconWrapper, {
      margin: this.isEntryPoint ? ENTRY_POINT_ICON_MARGIN : ICON_MARGIN,
    });
  }

  addLabeledToggleToLayout() {
    const container = new HLayout({
      name: OnboardingTourShape.EntryPointToggleContainer,
    });
    this.label = new HTMLText({
      fontSize: 12,
      height: 54,
      width: ACTIVENESS_LABEL_WIDTH,
      fill: HEXColors.white,
      singleLine: true,
      text: getToggleLabel(this.entryPointCard.enabled),
      verticalAlign: 'center',
      align: 'right',
    });

    container.layout(this.label, {
      width: ACTIVENESS_LABEL_WIDTH,
      height: 16,
      margin: ACTIVENESS_MARGIN,
    });

    this.toggle = new Toggle(
      !!this.entryPointCard?.enabled,
      (e, value) => {
        e.preventDefault();

        if (!value) {
          removeEntity({
            onSubmit: () => this.changeEntryPointEnableState(value),
            renderHeading: () =>
              i18next.t(
                'modernComponents.FlowBuilder.views.blockTitleView.confirmationModal.heading',
              ),
            renderActionText: () =>
              i18next.t(
                'modernComponents.FlowBuilder.views.blockTitleView.confirmationModal.action',
              ),
            renderNoteText: () => '',
          });
          return;
        }

        this.changeEntryPointEnableState(value);
        if (!value && this.blockView._error) {
          this.toggle?.setDisabled(true);
        }
      },
      undefined,
      {
        styles: {
          background: {
            fill: HEXColors.black,
            // @ts-ignore
            opacity: ({ parent: toggleView }) => {
              if (toggleView?._props.disabled) {
                return 0.2;
              }

              return toggleView?._value ? 0.4 : 0.35;
            },
          },
        },
      },
    );
    this.toggle.shape().name = OnboardingTourShape.EntryPointToggle;

    container.layout(this.toggle, {
      margin: TOGGLE_MARGIN,
      gone: () => this.subtype === BLOCK_SUBTYPES.broadcast_flow_root,
    });

    this.layout(container);
  }

  onBeforeRender() {
    const textViewWidth = this.calculateTextViewWidth();

    if (this.titleView && textViewWidth !== this.titleView.width()) {
      this.titleView.width(textViewWidth);
      this.titleView._layoutProps.width = textViewWidth;
    }
  }

  /**
   * Synchronously changes toggle, label and icon type of view.
   * This method is only for changing entry point header styles
   *
   * @param {Boolean} value
   */
  setHeaderActive(value: boolean): void {
    if (this.isHeaderDisabled === value) {
      return;
    }

    this.isHeaderDisabled = value;
    const padding = this._node.blockView.header;
    const { backgroundColor, backgroundStyle } = value
      ? this._node.blockView._theme!
      : disabledTheme;

    this.toggle?.setValue(value);
    this.label?.text(getToggleLabel(value));
    this.label?.renderElement();

    this.icon?.texture(getIconByBlock(this._node.block, value));
    this.icon?.renderElement();
    this.blockView.renderElement();
    padding._layoutProps.background!.fill = backgroundColor;
    padding._layoutProps.background!.backgroundStyle = backgroundStyle;
    // @ts-ignore
    padding._updateBackground();

    if (!value && this.blockView._error) {
      this.toggle?.setDisabled(true);
    }
  }

  /**
   * Changes header styles and updates Entry point state
   *
   * @param {Boolean} value
   */
  changeEntryPointEnableState(value: boolean) {
    this.setHeaderActive(value);
    this.updateEntryPointEnableStateDebounced(value);
  }

  calculateTextViewWidth() {
    let controlWidth = 0;
    const colorfulTitle =
      this.isEntryPoint || this.subtype === BLOCK_SUBTYPES.broadcast_flow_root;

    const headerMargin = colorfulTitle
      ? ENTRY_POINT_HEADER_MARGIN
      : HEADER_MARGIN;
    const iconMargin = colorfulTitle ? ENTRY_POINT_ICON_MARGIN : ICON_MARGIN;
    const textViewLayoutMargin = colorfulTitle
      ? ENTRY_POINT_TEXT_VIEW_LAYOUT_MARGIN
      : TEXT_VIEW_LAYOUT_MARGIN;

    if (
      this.isEntryPoint &&
      !(
        isFacebookAds(this.entryPointCard?.plugin_id) ||
        isInstagramAds(this.entryPointCard?.plugin_id) ||
        this.subtype === BLOCK_SUBTYPES.broadcast_flow_root
      )
    ) {
      controlWidth =
        ACTIVENESS_LABEL_WIDTH + ACTIVENESS_MARGIN.x + TOGGLE_WIDTH;
    }

    if (colorfulTitle && this._node.blockView?._error) {
      controlWidth += ALERT_VIEW_SIZE + ALERT_VIEW_MARGIN.left;
    }

    if (WITH_MESSAGE_TAG_BLOCKS.includes(this.subtype)) {
      controlWidth = 36;
    }

    const TEXT_EDIT_VIEW_WIDTH =
      headerMargin.x +
      ICON_SIZE +
      iconMargin.x +
      textViewLayoutMargin.x +
      controlWidth;

    return blockWidth - TEXT_EDIT_VIEW_WIDTH;
  }

  destroy() {
    super.destroy();
  }

  updateEntryPointEnableState(value: boolean) {
    const node = this._node;
    let card = getEntryPointCard(node.block.cards)!;
    if (isApolloBasedPlugin(card.plugin_id)) {
      const data = getPluginData(card.id);
      card = {
        ...card,
        ...clone(data?.card ?? {}),
      };
    }
    if (card) {
      logFlowPluginEvent(card.plugin_id, 'change status', {
        enable: value,
        blockId: node.id,
        cardId: card.id,
      });
      if (isApolloBasedPlugin(card.plugin_id)) {
        updatePluginData(card.id, (data) => {
          // eslint-disable-next-line no-param-reassign
          data.card.enabled = value;
        });
      }
      card.enabled = value;
      if (
        [
          PluginType.persistent_menu_entry_point,
          PluginType.instagram_persistent_menu_entry_point,
        ].includes(card.plugin_id)
      ) {
        card.synced = true;
      }
      card.config = card.config || {};

      node.updateCard(
        card,
        () => {
          logFlowPluginEvent(
            card.plugin_id,
            value ? 'entry point enabled' : 'entry point disabled',
            {
              blockId: node.id,
              cardId: card.id,
            },
          );
          entryPointsEmitter.emit(EntryPointEventTrigger.flowBuilder, {
            data: {
              event: EntryPointsEvents.updateEnabled,
            },
          });
          emitOnbordingEntryPointToggle(value);

          if (Array.isArray(this._node.block?.cards)) {
            this._node.block.cards.forEach((blockCard) => {
              if (card.id === blockCard.id) {
                // eslint-disable-next-line no-param-reassign
                blockCard.enabled = value;
              }
            });
          }

          if (
            [
              PluginType.persistent_menu_entry_point,
              PluginType.instagram_persistent_menu_entry_point,
            ].includes(card.plugin_id)
          ) {
            card.synced = true;
            this.renderNode();
          }
        },
        (error) => {
          const setDisabled = () => {
            if (isApolloBasedPlugin(card.plugin_id)) {
              updatePluginData(card.id, (data) => {
                // eslint-disable-next-line no-param-reassign
                data.card.enabled = false;
              });
            }
            card.enabled = false;
            this.toggle?.setValue(false);
          };
          if (error.status === 409) {
            error.json().then(({ conflicting_data: conflictingData }) => {
              if (!conflictingData) {
                return;
              }
              resolveEntryPointConflict(node, conflictingData, card);
            });
          } else if (error.status === 429) {
            setDisabled();
            Toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: window.i18next.t(
                  'block_title_view-string--581-too-many-requests-facebook-exception-try-later',
                ),
              },
            });
            this.setHeaderActive(false);
          } else {
            setDisabled();
            this.setHeaderActive(false);
          }
          this.renderNode();
        },
      );
      this.renderNode();
    }
  }

  updateEntryPointEnableStateDebounced = debounce(
    this.updateEntryPointEnableState,
    500,
  );
}
