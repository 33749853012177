import { clone, lensPath, path, pipe, propEq, set, prop, objOf } from 'ramda';
import i18next from 'i18next';
import { ISetPluginStateParams } from '@components/Plugins/common';
import {
  productListPluginFragment_config as ProductListPluginConfig,
  productListPluginFragment_config_visited_products_buttons as ProductButton,
} from '../../../../Plugins/ProductListPlugin/@types/productListPluginFragment';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { BLOCK_SUBTYPES } from '../../../consts';
import {
  Plugin,
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import { pluginWidth } from '../../plugin_consts';
import { GalleryConfig, GalleryView } from './components/GalleryView';
import { TextCardReminderView } from './components/TextCardReminderView';
import {
  ProductListType,
  ProductListVisitedProductsRecommendationType,
} from '@globals';
import { HTMLText } from '../../../components/Elements/Shapes';
import { CARD_WIDTH } from '../../components/ProductCardView/ProductCardView';
import { Dropdown, Item } from '../../kit/Dropdown';
import { NONE_ID } from './consts';
import {
  getButtonPathByType,
  getReminderDefaultConfig,
  getRelatedItems,
  updateConfigWithReminderConfig,
} from './utils';

export class ProductListPlugin
  extends VLayout
  implements StatefulPluginDelegate<ProductListPluginConfig>
{
  public _node: Node;
  private state: StatefulPlugin<ProductListPluginConfig>;
  private readonly reminderView: TextCardReminderView | GalleryView | undefined;

  constructor(state: StatefulPlugin<ProductListPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const { data } = this.state;
    const { config } = data;

    const isBackInStock = config.list_type === ProductListType.back_in_stock;
    const isVisitedProducts = false;

    // todo uncomment for v2
    // const isVisitedProducts =
    //   config.list_type === ProductListType.visited_products;

    const button = path<ProductButton>(
      getButtonPathByType(config.list_type),
      config,
    );

    this.reminderView = new GalleryView({
      hideButton:
        this._node.block.subtype ===
        BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
      config: getReminderDefaultConfig(config),
      onChange: this.onGalleryConfigUpdated.bind(this),
      isEditing: () => this.state.isEditing,
      stats: getFullCounterStats(button?.counter_id ?? '', node.block.id),
      expandable: isVisitedProducts,
      subtitleEditable: ![
        BLOCK_SUBTYPES.back_in_stock_message,
        BLOCK_SUBTYPES.visitedProductsReminder,
        BLOCK_SUBTYPES.postPurchase,
      ].includes(this._node.block.subtype),
      singleProductPlaceholder: isBackInStock,
    });

    this.addToLayout(this.reminderView);

    if (isVisitedProducts) {
      const hBox = new HLayout();
      hBox.addToLayout(
        new HTMLText({
          text: i18next.t('shopify.visitedProducts.relatedProducts'),
          trustedHtml: true,
          fontSize: 15,
        }),
      );

      const relatedItems = getRelatedItems();
      const defaultSelectedItem =
        relatedItems.find(
          propEq('id', config.visited_products?.recommendation_type || NONE_ID),
        ) || relatedItems[0];

      hBox.addToLayout(
        new Dropdown({
          isEditing: () => this.state.isEditing,
          items: getRelatedItems(),
          defaultSelectedItem,
          onChange: this.onDropdownConfigUpdated,
        }),
        {
          margin: {
            left: 8,
          },
        },
      );

      hBox.on('pointerdown', (event: Event) => {
        event.stopPropagation();
      });

      this.reminderView.addToLayout(hBox, {
        gone: () => !this.state.isEditing,
        margin: {
          left: CARD_WIDTH + 16 + 20,
          top: 222,
        },
      });
    }
  }

  private onGalleryConfigUpdated(updatedReminderConfig: GalleryConfig) {
    this.state.set(({ config }) => ({
      config: updateConfigWithReminderConfig(config, updatedReminderConfig),
    }));
    this.state.save();
  }

  private onDropdownConfigUpdated(selectedItem: Item) {
    this.state.set(
      pipe<
        Plugin<ProductListPluginConfig>,
        ProductListPluginConfig,
        ProductListPluginConfig,
        ProductListPluginConfig,
        ProductListPluginConfig,
        ISetPluginStateParams<ProductListPluginConfig>
      >(
        prop('config'),
        clone,
        set(
          lensPath(['visited_products', 'recommendation_type']),
          selectedItem.id === NONE_ID
            ? null
            : (selectedItem.id as ProductListVisitedProductsRecommendationType),
        ),
        set(lensPath(['visited_products', 'buttons']), []),
        objOf('config'),
      ),
    );
    this.state.save();
  }

  pluginDidSet() {}

  validationError() {
    return this.reminderView?.validationError();
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
