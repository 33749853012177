import { HEXColors } from '@ui/_common/colors';
import { Texture } from 'pixi.js-legacy';
import { ExternalAccountsData } from '@utils/Integrations/common/ExternalAccount';
import { MainLayout } from '../../../components/Elements/Layouts';
import { logFlowEvent } from '../../../utils/Analytics';
import { ButtonView } from '../ButtonView';
import { ExternalAccountSelector } from './components/ExternalAccountSelector';

interface ExternalAccountButtonProps {
  title: string;
  icon: Texture;
}

interface ExternalAccountProps {
  eventPropertyBag?: object;
  width: number;
  button: ExternalAccountButtonProps;
  onConnectRequest: () => void;
  onDisconnectRequest: () => void;
  getData: () => ExternalAccountsData;
  // onSelectRequest: () => void; // todo
}

export class ExternalAccount extends MainLayout {
  public readonly TEST_NAME = 'ExternalAccount';
  private readonly props: ExternalAccountProps;
  private isAccountConnected: boolean | undefined;
  constructor(props: ExternalAccountProps) {
    super();
    this.props = props;

    const {
      width,
      button: { icon, title },
      onConnectRequest,
      onDisconnectRequest,
    } = this.props;

    const externalAccountSelector = new ExternalAccountSelector({
      width,
      getData: this.props.getData,
      onDisconnectRequest: () => {
        onDisconnectRequest();
        logFlowEvent('external account manager', 'disconnect click', {
          title: props.button.title,
        });
      },
    });

    this.addToLayout(externalAccountSelector, {
      gone: () => !this.isAccountConnected,
    });

    this.addToLayout(
      new ButtonView({
        title,
        icon,
        width,
        height: 36,
        textColor: HEXColors.white,
        iconWidth: 20,
        iconHeight: 20,
        onClick: (event: Event) => {
          event.stopPropagation();
          onConnectRequest();
          logFlowEvent('external account manager', 'connect click', {
            title: props.button.title,
            ...this.props.eventPropertyBag,
          });
        },
        background: {
          fill: HEXColors.blue,
        },
      }),
      {
        gone: () => !!this.isAccountConnected,
      },
    );
  }

  onBeforeRender() {
    this.isAccountConnected = !!this.props.getData().currentAccountId;
  }
}
