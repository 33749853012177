import i18next from 'i18next';
import { FlowBuilderError, IClickableEntryPointError } from '../common/errors';

export class DontHavePopupEntryPointError extends FlowBuilderError
  implements IClickableEntryPointError {
  onClick: () => void;
  constructor(onClick: () => void) {
    super({
      message: `${i18next.t(
        'shopify.requestAddShopifyEntryPoint',
      )}<br/><br/><span style="text-decoration: underline;">${i18next.t(
        'shopify.AddShopifyPopUpEntryPoint',
      )}</span>`,
      isBlockError: true,
    });
    this.onClick = onClick;
  }
}

export class DontHaveEnabledPopupEntryPointError extends FlowBuilderError
  implements IClickableEntryPointError {
  onClick: () => void;
  constructor(onClick: () => void) {
    super({
      message: `${i18next.t(
        'shopify.requestSetupPopUpEntryPoint',
      )}<br/><br/><span style="text-decoration: underline;">${i18next.t(
        'shopify.GoToShopifyPopUpSettings',
      )}</span>`,
      isBlockError: true,
    });
    this.onClick = onClick;
  }
}

export class EmptyEventsListError extends FlowBuilderError {
  constructor() {
    super({
      message: `${i18next.t(
        'shopify.addEventToEnableThisEntryPoint',
      )}<br/><br/><span style="text-decoration: underline;">${i18next.t(
        'shopify.addEventNow',
      )}</span>`,
      isBlockError: true,
    });
  }
}

export class EmptyDelayListError extends FlowBuilderError
  implements IClickableEntryPointError {
  onClick: () => void;
  constructor(onClick: () => void) {
    super({
      message: `${i18next.t(
        'shopify.addATimerToEnableThisEntryPoint',
      )}<br/><br/><span style="text-decoration: underline;">${i18next.t(
        'shopify.addTimer',
      )}</span>`,
      isBlockError: true,
    });
    this.onClick = onClick;
  }
}

export class DontHaveShopifyPermissionsError extends FlowBuilderError
  implements IClickableEntryPointError {
  onClick: () => void;
  constructor(onClick: () => void) {
    super({
      message: i18next.t(
        'shopify.orderStatusUpdate.validation.permissionsValidationText',
      ),
      isBlockError: true,
    });
    this.onClick = onClick;
  }
}
