import i18next from 'i18next';
import { VLayout } from '../components/Elements/Layouts';
import { SetAttributeView } from './components/SetAttributeView';
import { PluginTitleLayout } from './components/PluginTitleLayout';
import { pluginPhoneCallSvgTexture } from '../assets/textures';
import { tooltips } from './Menu/menu_view';
import { pluginWidth, textCardBackgroundColor } from './plugin_consts';
import { Node } from '../Node';
import { Card } from '../types';

interface PhoneCallConfig {
  phone_number: string;
}

export class PhoneCallPluginView extends VLayout {
  TEST_NAME = 'PhoneCallPluginView';

  // TODO поля могут быть использованны где угодно, сделать приватными когда в фб не будет мутаций
  public _node;
  public _card;
  public _config;

  private _phoneView;
  private _titleLayout: PluginTitleLayout;

  constructor(card: Card<PhoneCallConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._card = card;
    this._node = node;
    const { config } = card;
    this._config = config;
    this._titleLayout = new PluginTitleLayout(
      false,
      i18next.t('phone_call-string-1098-phone-call'),
      pluginPhoneCallSvgTexture,
      card,
      tooltips().phone_call,
    );
    this.layout(this._titleLayout, { margin: { top: 15, left: 10 } });

    this._phoneView = new SetAttributeView(
      card,
      {
        width: pluginWidth - 30,
        caption: window.i18next.t('phone_call-string-7709-phone'),
      },
      (text) => {
        config.phone_number = text;
      },
      () => {
        node.updateCard(card);
      },
    );
    this._phoneView.text(config.phone_number);
    this.layout(this._titleLayout, { margin: { top: 15, bottom: 10 } });
    this.layout(this._phoneView, { margin: { left: 15, bottom: 15 } });
  }
}
