import { PluginType } from '@components/Plugins/common/PluginTypes';
import { FlowData } from '../types';

export const getAllPluginIdsByType = (type: PluginType, flow: FlowData) =>
  flow.blocks
    .flatMap(({ cards }) => cards)
    .reduce((acc, val) => {
      if (val?.plugin_id === type) {
        acc.push(val.id);
      }
      return acc;
    }, [] as string[]);
