import { PluginType } from '@components/Plugins/common/PluginTypes';
import { filterTreeBy, isNode } from '@ui/Tree/utils';
import {
  append,
  concat,
  differenceWith,
  equals,
  pick,
  prop,
  uniqBy,
} from 'ramda';
import { AdCampaignType } from '@globals';
import { getAdsIdsFromTree } from '../utils';
import {
  AdValidatorType,
  EntryPointFacebookCampaign,
  EntryPointRenderTree,
  FacebookAd,
  FacebookCampaign,
  Tree,
  ValidatorResult,
} from './types';

const diffByEquality = differenceWith(equals);

export interface ValidatorAd {
  id: string;
  page_id: string | null;
  flow_id: string | null;
  entry_point_bot_id: string | null;
}

/**
 * Returns object which has the validation result and the reason why validation wasn't passed.
 * If result is true that means that validation was successfuly passed
 */
export const adValidator = (
  ad: ValidatorAd,
  checkedAdsIds: string[],
  currentPageId: string,
  entryPointBotId: string | null,
): ValidatorResult => {
  const isAdBelongsToCurrentPageId = ad.page_id === currentPageId;
  const isAdFromCurrentEP =
    entryPointBotId &&
    ad.entry_point_bot_id &&
    entryPointBotId === ad.entry_point_bot_id;
  const shouldShowEPError = checkedAdsIds.includes(ad.id) || isAdFromCurrentEP;
  const isFlowIdExists = Boolean(ad.flow_id);

  const error = Boolean(
    (isFlowIdExists && !shouldShowEPError) || !isAdBelongsToCurrentPageId,
  );

  return {
    error,
    reason: {
      adBelongsToAnotherPage: !isAdBelongsToCurrentPageId,
      adFromAnotherEP: !shouldShowEPError,
      flowIdDoesNotExists: !isFlowIdExists,
    },
  };
};

type MergeAdsTreesProps = {
  tree: Tree;
  checked: string[];
  checkedAds: EntryPointRenderTree;
  treeValidator: AdValidatorType;
};

export const mergeAdsTrees = ({
  tree,
  checked,
  checkedAds,
  treeValidator,
}: MergeAdsTreesProps) => {
  const checkedAdsIds = getAdsIdsFromTree(checkedAds);
  const addedIds = diffByEquality(checked, checkedAdsIds);
  const removedIds = diffByEquality(checkedAdsIds, checked);
  const addedAds = filterTreeBy<FacebookCampaign>(
    tree || [],
    (data) =>
      !isNode(data) && addedIds.includes(data.id) && !treeValidator(data).error,
  ) as Tree;
  const updatedTree = checkedAds.map(({ ads, ...rest }) => ({
    ...rest,
    ads: ads.filter(({ id }) => !removedIds.includes(id)),
  }));

  /**
   * Modal uses tree with this structure: campaigns -> adset -> ads
   * but entry point requires the following structure: campaigns -> ads
   * so we have to drop adsets out of the tree to make it an "entry point tree"
   */
  const t = addedAds.map(({ children, ...campaign }) => {
    const requiredCampaign = pick(['id', 'name'], campaign);
    const ads = children.reduce(
      (acc, { children }) => acc.concat(children),
      [] as FacebookAd[],
    );

    return {
      ...requiredCampaign,
      ads,
    };
  });

  /**
   * Here we have to merge our tree with the given tree. mergeDeepWith wouldn't help here
   * because trees contains arrays, and we would have to make an recursive algorithm which
   * iterates through our arrays and merges them into one object.
   *
   * So in a few lines below we are making to arrays of unique campaigns and unique ads
   */
  const arr = concat(t, updatedTree);
  const [campaings, ads] = arr.reduce(
    ([c, a], { ads, ...campaign }) => [append(campaign, c), concat(ads, a)],
    [[], []] as [EntryPointFacebookCampaign[], FacebookAd[]],
  );

  const uniqCampaigns = uniqBy(prop('id'), campaings);
  const uniqAds = uniqBy(prop('id'), ads);

  /**
   * Finally we are appending unique ads to their campaings
   */
  const result = uniqCampaigns.reduce((acc: EntryPointRenderTree, campaign) => {
    const ads = uniqAds.filter(
      ({ campaign_id }) => campaign_id === campaign.id,
    );

    if (ads.length) {
      return append({ ...campaign, ads }, acc);
    }

    return acc;
  }, []);

  return {
    result,
    // addedIds also contains ids of nodes, that why we should take ids from ads array
    addedCount: ads.filter(({ id }) => addedIds.includes(id)).map(prop('id'))
      .length,
    removedCount: removedIds.length,
  };
};

export const pluginIdToAdType = (pluginId: PluginType): AdCampaignType => {
  switch (pluginId) {
    case PluginType.ads_manager_ctm_entry_point:
    case PluginType.instagram_ads_manager_ctm_entry_point:
      return AdCampaignType.click_to_messenger;
    case PluginType.ads_manager_sm_entry_point:
      return AdCampaignType.sponsored_message;
    default:
      throw new Error(`Unsupported plugin id ${pluginId}`);
  }
};
