import { Texture } from 'pixi.js-legacy';
import { HEXColors } from '@ui/_common/colors';
import { Circle, TextureShape } from '../../components/Elements/Shapes';
import { HLayout } from '../../components/Elements/Layouts';

interface CircleIconProps {
  icon: Texture;
}

export class CircleIcon extends HLayout {
  static ICON_SIZE = 24;

  private readonly props: CircleIconProps;

  constructor(props: CircleIconProps) {
    super({});
    this.props = props;

    const circle = new Circle({
      fill: HEXColors.white,
      stroke: HEXColors.greyLight20,
      strokeWidth: 1,
      radius: CircleIcon.ICON_SIZE / 2,
    });

    this.addToLayout(circle);

    this.addToLayout(
      new TextureShape({
        texture: this.props.icon,
        width: CircleIcon.ICON_SIZE,
        height: CircleIcon.ICON_SIZE,
      }),
      {
        margin: {
          left: -CircleIcon.ICON_SIZE,
        },
      },
    );
  }
}
