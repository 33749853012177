import { stripTags } from '@utils/stripTags';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import { getFullCounterStats } from '../../../components/BlockStatsView/utils';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import {
  HLayout,
  LayoutProps,
  MainLayout,
} from '../../../../components/Elements/Layouts';
import { Image, HTMLText } from '../../../../components/Elements/Shapes';
import { Node } from '../../../../Node';
import emptyPictureUrl from '../../common/images/empty-picture.svg';
import {
  ItemStatView,
  STAT_VIEW_WIDTH,
} from '../../../kit/ItemStatView/ItemStatView';
import { pluginWidth } from '../../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';

interface PostItemProps extends LayoutProps {
  node: Node;
  cardId: string;
  post: PostViewModel;
}

export interface PostViewModel {
  __typename: string;
  id: string;
  text: string | null;
  link: string | null;
  picture: string | null;
}

export class PostItem extends HLayout {
  TEST_NAME = 'PostItem';

  postId: PostViewModel['id'];

  constructor({ post, node, cardId, ...layoutProps }: PostItemProps) {
    super({
      height: 32,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      ...layoutProps,
    });
    this.postId = post.id;
    this.loggingFlag = true;

    const link = new HLayout({
      height: 32,
      cursor: {
        in: 'pointer',
      },
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    link.on('click', () => {
      if (post.link) {
        window.open(post.link, '_blank');
        logFlowPluginEvent(
          PluginType.comments_autoreply_entry_point,
          'Post click (open on FB)',
          {
            cardId,
            blockId: node.id,
            postId: post.id,
          },
        );
      }
    });

    link.addToLayout(
      new Image({
        url: post.picture ?? emptyPictureUrl,
        cornerRadius: 4,
        width: 32,
        height: 32,
      }),
      {
        margin: { right: 12 },
      },
    );

    link.addToLayout(
      new HTMLText({
        text: `<u style="max-width: 190px; display: block; text-overflow: ellipsis; overflow: hidden;text-decoration-color:${
          HEXColors.grey
        };">${removeExtraSpaces(
          stripTags(
            post.text ?? window.i18next.t('PostItem-string--578-no-text'),
          ),
          ' ',
        )}</u>`,
        singleLine: true,
        fontSize: 15,
        fill: post.text ? HEXColors.black : HEXColors.greyDark,
      }),
      {
        margin: { top: 3 },
      },
    );

    const stats = getFullCounterStats(`${cardId}_${post.id}`, node.block.id);
    const linkBox = new MainLayout({
      width: pluginWidth - 34 - (stats ? STAT_VIEW_WIDTH : 0),
    });
    linkBox.addToLayout(link);
    this.addToLayout(linkBox);

    if (stats) {
      this.addToLayout(
        new ItemStatView({
          stats,
          showPercentValue: true,
        }),
        { margin: { top: 4.5 } },
      );
    }
  }
}
