import i18next, { TFunction } from 'i18next';
import { removeTooltip, tooltipScaled } from '../helpers/TooltipHelpers';
import { HLayout } from '../../components/Elements/Layouts';
import { TextureShape } from '../../components/Elements/Shapes';
import { messageTagTexture } from '../../assets/textures';
import {
  STANDARD_MESSAGING_ID,
  TAG_ID_TO_TAG_MAP,
} from '../../../BroadcastingTags/TagsData';
import { showUpdateMessageTagDialog } from '../../components/Modals';
import { textCardBackgroundColor } from '../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { Subscription } from 'apollo-client/util/Observable';
import { NotificationMessagesTopicsQuery_page_notificationMessagesTopics } from '@utils/Data/NotificationMessages/@types/NotificationMessagesTopicsQuery';
import { getNotificationMessagesTopicsObservable } from '@utils/Data/NotificationMessages/getNotificationMessagesTopicsObservable';
import { getFlowControllerStrict } from '../../PixiFieldRepository';

interface MessageTagProps {
  isEditing: () => boolean;
  block: {
    message_tag?: string | null;
    otn_purpose?: string | null;
  };
  onChange: (params: { tag: string; tagType?: string }) => void;
}

export enum MessageTagType {
  tag = 'tag',
  otn = 'otn',
  ntn = 'ntn',
}

export const updateMessageTag = (node: any, tag: string, tagType?: string) => {
  /* eslint-disable no-param-reassign */
  node.block.message_tag = null;
  node.block.otn_purpose = null;
  node.block.notification_topic_id = null;
  switch (tagType) {
    case MessageTagType.otn:
      node.block.otn_purpose = tag;
      break;
    case MessageTagType.ntn:
      node.block.notification_topic_id = tag;
      break;
    case MessageTagType.tag:
    default:
      node.block.message_tag = tag;
      break;
  }
  /* eslint-enable no-param-reassign */
  node.updateBlock();
};

export const getMessageTag = (t: TFunction, tagId?: string | null) =>
  TAG_ID_TO_TAG_MAP(t).get(tagId || STANDARD_MESSAGING_ID)?.leftPartTitle ||
  tagId ||
  '';

export class MessageTagView extends HLayout {
  TEST_NAME = 'MessageTagView';
  block: {
    message_tag?: string | null;
    otn_purpose?: string | null;
    notification_topic_id?: string | null;
  };
  private notificationMessagesTopicsSubscription: Subscription | undefined;
  private notificationMessagesTopics: NotificationMessagesTopicsQuery_page_notificationMessagesTopics[] =
    [];

  constructor(props: MessageTagProps) {
    super({
      height: 36,
      width: 36,
      cursor: {
        in: 'pointer',
      },
      background: {
        fill: HEXColors.white,
        cornerRadius: 4,
        onhover: {
          fill: () => textCardBackgroundColor,
        },
      },
    });

    this.block = props.block;

    const pageId = getFlowControllerStrict().flow.bot.status?.page;
    if (pageId) {
      this.notificationMessagesTopicsSubscription =
        getNotificationMessagesTopicsObservable(pageId).subscribe(
          (notificationMessagesTopics) => {
            this.notificationMessagesTopics = notificationMessagesTopics || [];
            this.setTooltip();
          },
        );
    }

    this.addToLayout(
      new TextureShape({
        texture: messageTagTexture,
        width: 18,
        height: 18,
      }),
      {
        margin: { top: 9, left: 9 },
      },
    );

    this.on('pointerdown', (e: any) => {
      e.stopPropagation();
    });

    this.on('click', (e: any) => {
      e.stopPropagation();
      removeTooltip(this);
      setTimeout(() => removeTooltip(this), 1200);
      showUpdateMessageTagDialog({
        defaultValue:
          this.block.notification_topic_id ||
          this.block.otn_purpose ||
          this.block.message_tag,
        onChange: (item) => {
          props.onChange({ tag: item.id, tagType: item.tagType });
          this.setTooltip();
        },
        onDismiss: () => {
          this.setTooltip();
        },
      });
    });
  }

  getNtnTopicName() {
    return this.block.notification_topic_id
      ? this.notificationMessagesTopics.find(
          ({ id }) => id === this.block.notification_topic_id,
        )?.name
      : undefined;
  }

  setTooltip() {
    removeTooltip(this);
    tooltipScaled({
      view: this,
      text:
        this.getNtnTopicName() ||
        getMessageTag(
          i18next.t.bind(i18next),
          this.block.otn_purpose || this.block.message_tag,
        ),
    });
  }

  destroy() {
    this.notificationMessagesTopicsSubscription?.unsubscribe();
    removeTooltip(this);
  }
}
