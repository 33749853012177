import { HEXColors } from '@ui/_common/colors';
import { Texture } from 'pixi.js-legacy';
import { HLayout } from '../../components/Elements/Layouts';
import { HTMLText, TextureShape } from '../../components/Elements/Shapes';
import { tooltipScaled } from '../helpers/TooltipHelpers';

interface LabelProps {
  text: string;
  width?: number;
  icon?: Texture;
  tooltipText?: string;
  tooltipSvgIcon?: Texture;
  multiline?: boolean;
}

export class Label extends HLayout {
  constructor({
    icon,
    text,
    tooltipSvgIcon,
    tooltipText,
    width,
    multiline = false,
  }: LabelProps) {
    super();
    const infoIconContainer = new HLayout({
      cursor: {
        in: 'default',
      },
    });

    if (icon) {
      this.addToLayout(
        infoIconContainer.addToLayout(
          new TextureShape({
            texture: icon,
            width: 14,
            height: 14,
          }),
        ),
        {
          margin: { top: 3, right: 8 },
        },
      );
    }

    this.addToLayout(
      new HTMLText({
        text,
        fontSize: 15,
        fontStyle: 'semibold',
        fill: HEXColors.black,
        height: multiline ? undefined : 20,
        width,
        singleLine: !multiline,
        verticalAlign: 'center',
      }),
    );
    if (tooltipText && tooltipSvgIcon) {
      this.addToLayout(
        infoIconContainer.addToLayout(
          new TextureShape({
            texture: tooltipSvgIcon,
            width: 14,
            height: 14,
          }),
        ),
        {
          margin: { top: 3, left: 8 },
        },
      );

      tooltipScaled({
        view: infoIconContainer,
        text: tooltipText,
      });
    }
  }
}
