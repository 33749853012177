import React, { useEffect } from 'react';
import YouTube from 'react-youtube';
import { Anchor } from '@ui/Links';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { Platform } from '@globals';
import * as css from './NoCampaignsView.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

const ADS_MANAGER_SETUP_VIDEO_ID = {
  [Platform.facebook]: 'PaUQ_tJoU6E',
  [Platform.instagram]: 'GvV5akci2TI',
};

const PLAYER_OPTIONS = {
  height: '285px',
  width: '100%',
};

const sendVideoEventWithLabel = (label: PluginType) => (state: string) =>
  sendEvent({
    category: 'flows',
    action: `ads video (${state})`,
    label,
  });

export interface NoAdsViewProps {
  title: string;
  message1?: string;
  message2?: string;
  buttonTitle?: string;
  onRefresh: () => void;
  pluginId: PluginType;
  platform: Platform.facebook | Platform.instagram;
  hideVideo?: boolean;
  className?: string;
}

export const NoAdsView: React.FC<NoAdsViewProps> = ({
  title,
  buttonTitle,
  onRefresh,
  pluginId,
  platform,
  children,
  hideVideo,
  className,
}) => {
  const sendVideoEvent = sendVideoEventWithLabel(pluginId);

  useEffect(() => {
    sendEvent({
      category: 'flows',
      label: pluginId,
      action: 'No campaigns view popup shows up',
      propertyBag: {
        reason: title.replace('You', 'user').replace(' yet', ''),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      className={className}
    >
      {!hideVideo ? (
        <div className={css.video}>
          <YouTube
            videoId={ADS_MANAGER_SETUP_VIDEO_ID[platform]}
            onPlay={() => {
              sendVideoEvent('play');
            }}
            onPause={() => {
              sendVideoEvent('pause');
            }}
            onEnd={() => {
              sendVideoEvent('end');
            }}
            onError={() => {
              sendVideoEvent('error');
            }}
            opts={PLAYER_OPTIONS}
          />
        </div>
      ) : (
        <div />
      )}

      <Flex flexDirection="column">
        <Type as="h4" size="18px" weight="semibold">
          {title}
        </Type>
        <Spacer factor={4} />
        {children}
        <Spacer factor={4} />
      </Flex>

      <Flex justifyContent="flex-end">
        <Button
          intent="secondary"
          onClick={() => {
            sendEvent({
              category: 'flows',
              action: 'refresh campaign list',
              label: pluginId,
              propertyBag: { platform },
            });

            onRefresh();
          }}
        >
          {buttonTitle}
        </Button>
      </Flex>
    </Flex>
  );
};

export const AdsManagerAnchor = () => {
  const { t } = useSafeTranslation();

  return (
    <Anchor
      intent="external"
      target="_blank"
      rel="noopener noreferrer"
      href="https://facebook.com/adsmanager"
      hideArrow
    >
      {t(
        'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.adsManager',
      )}
    </Anchor>
  );
};

export const AdsLearnMoreAnchor = () => {
  const { t } = useSafeTranslation();

  return (
    <Anchor
      intent="external"
      target="_blank"
      rel="noopener noreferrer"
      href={t(
        'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.learnMoreLinkHref',
      )}
    >
      {t(
        'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.learnMoreLinkText',
      )}
    </Anchor>
  );
};

export const MetaBusinessSuiteAnchor = () => {
  const { t } = useSafeTranslation();

  return (
    <Anchor
      intent="external"
      target="_blank"
      rel="noopener noreferrer"
      href="https://business.facebook.com/"
      hideArrow
    >
      {t(
        'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.metaBusinessSuite',
      )}
    </Anchor>
  );
};

export const PagePostsAnchor = () => {
  const { t } = useSafeTranslation();

  return (
    <Anchor
      intent="external"
      target="_blank"
      rel="noopener noreferrer"
      href="https://business.facebook.com/content_management/SCHEDULED_POSTS"
      hideArrow
    >
      {t(
        'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.pagePosts',
      )}
    </Anchor>
  );
};

type SpecifiedProps = 'title' | 'buttonTitle';

export interface NoBoostedPostsViewProps
  extends Omit<NoAdsViewProps, SpecifiedProps> {}

export const NoBoostedPostsView: React.FC<NoBoostedPostsViewProps> = (
  props,
) => {
  const { t } = useSafeTranslation();

  return (
    <NoAdsView
      {...props}
      title={t(
        'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.NoBoostedPostsView.title',
      )}
      buttonTitle={t(
        'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.NoBoostedPostsView.buttonTitle',
      )}
    >
      <Type as="p" size="15px">
        {t(
          'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.NoBoostedPostsView.description1',
        )}
        , <MetaBusinessSuiteAnchor />
        , <PagePostsAnchor />,{' '}
        {t(
          'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.NoBoostedPostsView.description2',
        )}{' '}
        <AdsManagerAnchor />.{' '}
        {t(
          'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.NoBoostedPostsView.description3',
        )}{' '}
        <AdsLearnMoreAnchor />
      </Type>
    </NoAdsView>
  );
};
