import { EventEmitter } from '@utils/EventEmitter';

export enum EntryPointsEvents {
  create = 'flow:entryPoint:create',
  delete = 'flow:entryPoint:delete',
  rename = 'flow:entryPoint:rename',
  updateEnabled = 'flow:entryPoint:updateEnable',
}

export enum EntryPointEventTrigger {
  flowBuilder = 'flow:entryPoint:event:flowBuilder',
  flowList = 'flow:entryPoint:event:flowList',
}

export const entryPointsEmitter = new EventEmitter();
