import React, { useCallback } from 'react';
import { ButtonUnstyled } from '@ui/Button';
import { CheckBox } from '@ui/CheckBox';
import { Icon } from '@ui/Icon';
import { filterTreeBy, getLeafIds, isNode } from '@ui/Tree/utils';
import { Type } from '@ui/Type';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { CheckboxTreeRenderItemProps } from '@ui/Tree/CheckboxTree';
import * as css from './TreeChildren.css';
import { TREE_GAP } from '../constants';
import {
  AdValidatorType,
  FacebookAd,
  FacebookAdsTree,
  OnErrorSubmitType,
  RemoveAdFromEntryPointMutation,
} from './types';
import { adValidator } from './utils';
import { TreeItem } from './TreeItem';

type Props = CheckboxTreeRenderItemProps<FacebookAdsTree> & {
  onErrorSubmit?: OnErrorSubmitType;
  checkedAdsIds: string[];
  removeAdFromEntryPoint: RemoveAdFromEntryPointMutation;
  adValidator: AdValidatorType;
  pageId: string;
  pluginId: PluginType;
};

export const TreeChildren: React.FC<Props> = ({
  data,
  handlers,
  level,
  state,
  onErrorSubmit,
  checkedAdsIds,
  removeAdFromEntryPoint,
  pageId,
  pluginId,
}) => {
  const { checked, expanded } = state;
  const { onCheckedChange, onExpandedChange } = handlers;
  const isError = useCallback(
    (ad) => adValidator(ad as FacebookAd, checkedAdsIds, pageId, null),
    [checkedAdsIds, pageId],
  );

  if (isNode(data)) {
    const adsAmount = getLeafIds(data.children);
    const filteredTree = filterTreeBy(
      data.children,
      (node) => !isNode(node) && isError(node).error,
    );
    const errorsAmount = getLeafIds(filteredTree || []);
    const shouldDisableCheckbox = adsAmount.length === errorsAmount.length;

    return (
      <div
        className={css.wrapper}
        style={{ paddingLeft: level * TREE_GAP }}
        data-testid={`tree_item__level_${level}`}
      >
        <CheckBox
          onChange={onCheckedChange}
          checked={checked}
          disabled={shouldDisableCheckbox}
        />
        <ButtonUnstyled
          onClick={onExpandedChange}
          style={{
            transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
          }}
        >
          <Icon icon="triangle" />
        </ButtonUnstyled>
        <Type as="p" noWrap size="15px_DEPRECATED">
          {data.name}
        </Type>
      </div>
    );
  }

  const { error: validationError, reason } = isError(data);

  return (
    <div
      className={css.wrapper}
      style={{ paddingLeft: level * TREE_GAP }}
      data-testid={`tree_item__level_${level}`}
    >
      <TreeItem
        data={data}
        reason={reason}
        pluginId={pluginId}
        validationError={validationError}
        onCheckedChange={onCheckedChange}
        checked={checked}
        onResolveConflictClick={(entryPointBotId) => {
          removeAdFromEntryPoint(data, entryPointBotId).then(() => {
            onErrorSubmit?.(data);
          });
        }}
      />
    </div>
  );
};
