import i18next from 'i18next';
import { externalIntegrationEntryPointFragment_config_connected_zaps_owner } from '../../../../../../../Plugins/ExternalIntegrationEntryPoint/@types/externalIntegrationEntryPointFragment';
import { HLayout } from '../../../../../../components/Elements/Layouts';
import { HTMLText, Image } from '../../../../../../components/Elements/Shapes';
import { getPixiFieldStrict } from '../../../../../../PixiFieldRepository';
import { pluginWidth } from '../../../../../plugin_consts';
import { getI18ZapierKey } from '../../../utils/getI18ZapierKey';

class ZapListAdminViewProps {
  user!: externalIntegrationEntryPointFragment_config_connected_zaps_owner | null;
}

export class ZapAdminView extends HLayout {
  constructor({ user }: ZapListAdminViewProps) {
    super({});
    if (user?.picture && !getPixiFieldStrict().isViewOnly()) {
      this.addToLayout(
        new Image({
          width: 24,
          height: 24,
          url: user.picture || '',
          cornerRadius: 12,
        }),
        {
          margin: {
            left: 0,
            top: 0,
            right: 8,
            bottom: 0,
          },
        },
      );
    }

    this.addToLayout(
      new HTMLText({
        text: getPixiFieldStrict().isViewOnly()
          ? i18next.t(getI18ZapierKey('zaps'))
          : user
          ? `${i18next.t(getI18ZapierKey('zapsSetUpBy'))} ${filterXSS(
              user.name || '',
            )}`
          : i18next.t(getI18ZapierKey('removedAdminsZaps')),
        singleLine: true,
        trustedHtml: true,
        width: pluginWidth - 64,
        fontSize: 15,
      }),
      {
        margin: {
          left: 0,
          top: 2,
          right: 0,
          bottom: 0,
        },
      },
    );
  }
}
