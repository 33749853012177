import { globalHistory } from '@utils/Routing';
import { HLayout } from '../../../../components/Elements/Layouts';
import { pluginWidth } from '../../../plugin_consts';
import { HTMLText } from '../../../../components/Elements/Shapes';
import { ItemStatView } from '../../../kit/ItemStatView/ItemStatView';
import { FullCounterStats } from '../../../../utils/Statistics/types';

interface InboundLinkProps {
  title: string;
  href: string;
  onOpenHref(): void;
  stats?: FullCounterStats;
}

export class InboundLinkView extends HLayout {
  private props: InboundLinkProps;

  constructor(props: InboundLinkProps) {
    super({
      cursor: {
        in: 'pointer',
      },
    });

    this.props = props;
    const { title, stats } = this.props;

    this.addToLayout(
      new HTMLText({
        text: title,
        fontSize: 15,
        singleLine: true,
        width: pluginWidth - 100,
        textDecoration: 'underline',
      }),
    );

    if (stats) {
      this.addToLayout(new ItemStatView({ stats, showPercentValue: true }), {
        margin: { left: 15 },
        align: 'end',
      });
    }

    this.on('click', this.openHref);
  }

  private openHref = () => {
    const { href, onOpenHref } = this.props;
    onOpenHref();
    globalHistory.push(href);
  };
}
