import { HEXColors } from '@ui/_common/colors';
import { MainLayout, VLayout } from '../../../../components/Elements/Layouts';
import { CARD_WIDTH } from '../../../components/ProductCardView/ProductCardView';
import { HTMLText, TextureShape } from '../../../../components/Elements/Shapes';
import { productSvgTexture } from '../../../../assets/textures';
import { invalidStrokeColor, mainStrokeColor } from '../../../plugin_consts';

interface FacebookShopsProductAddButtonProps {
  onClick: () => void;
}

export class FacebookShopsProductAddButton extends MainLayout {
  constructor({ onClick }: FacebookShopsProductAddButtonProps) {
    super({
      width: CARD_WIDTH,
      height: 200,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
        stroke: invalidStrokeColor,
        strokeWidth: 2,
        onhover: {
          stroke: mainStrokeColor,
          strokeWidth: 2,
        },
      },
    });

    this.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });

    this.on('click', (event: Event) => {
      event.stopPropagation();
      onClick();
    });

    this.addToLayout(
      new VLayout({})
        .addToLayout(
          new TextureShape({
            texture: productSvgTexture,
            width: 20,
            height: 22,
          }),
          {
            margin: {
              bottom: 8,
              left: 45,
            },
          },
        )
        .addToLayout(
          new HTMLText({
            text: window.i18next.t(
              'FacebookShopsProductAddButton-string-2023-add-product',
            ),
            trustedHtml: true,
            fontStyle: 'semibold',
            fill: HEXColors.black,
            fontSize: 15,
          }),
        ),
      {
        margin: {
          top: 78,
          left: 98,
        },
      },
    );
  }
}
