import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Type } from '@ui/Type';
import { sendEvent } from 'cf-common/src/analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { Flex } from '@ui/Flex';
import { useFacebookLogin } from '@pages/MultiSystemAuth/hooks/useFacebookLogin';
import css from './PermissionsMissing.css';

interface SomethingWentWrongProps {
  onRefresh: () => void;
  pluginId: PluginType;
  isMyAdAccount: boolean;
}

export const PermissionsMissing: React.FC<SomethingWentWrongProps> = ({
  onRefresh,
  pluginId,
  isMyAdAccount,
}) => {
  const { t } = useSafeTranslation();

  const location = useLocation();

  const { facebookLogin } = useFacebookLogin({
    interruptedHref: window.location.href,
    location,
    reRequestPermissions: true,
  });

  return (
    <Flex
      className={css.root}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Type as="h4" weight="semibold" size="18px">
        {t(
          'modernComponents.FlowBuilder.modals.ChooseAdsPopup.PermissionsMissing.title',
        )}
      </Type>
      {isMyAdAccount ? (
        <>
          <Type size="15px" className={css.description} align="center">
            {t(
              'modernComponents.FlowBuilder.modals.ChooseAdsPopup.PermissionsMissing.description',
            )}
          </Type>
          <Button
            intent="primary"
            onClick={async () => {
              sendEvent({
                category: 'flows',
                action: 'refetch ads (error)',
                label: pluginId,
              });
              await facebookLogin();
              onRefresh();
            }}
          >
            {t(
              'modernComponents.FlowBuilder.modals.ChooseAdsPopup.PermissionsMissing.allowAccess',
            )}
          </Button>
        </>
      ) : (
        <Type size="15px" className={css.askTeammateDescription} align="center">
          {t(
            'modernComponents.FlowBuilder.modals.ChooseAdsPopup.PermissionsMissing.askTeammate',
          )}{' '}
          <Type size="15px" weight="semibold">
            {t(
              'modernComponents.FlowBuilder.modals.ChooseAdsPopup.PermissionsMissing.growTabPath',
            )}
          </Type>{' '}
          {t(
            'modernComponents.FlowBuilder.modals.ChooseAdsPopup.PermissionsMissing.toFacebookorInstagram',
          )}
        </Type>
      )}
    </Flex>
  );
};
