import { differenceWith, prop } from 'ramda';
import {
  facebookShopsProductListPluginFragment_config as FacebookShopsProductListPluginConfig,
  facebookShopsProductListPluginFragment_config,
  facebookShopsProductListPluginFragment_config_products as FacebookShopsProduct,
} from '@components/Plugins/FacebookShopsProductListPlugin/@types/facebookShopsProductListPluginFragment';
import { removeTypename } from '@utils/GQL/utils';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  ProductCardView,
  ProductCardViewConfig,
} from '../../components/ProductCardView/ProductCardView';
import { FacebookShopsProductAddButton } from './components/FacebookShopsProductAddButton';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { galleryCardControl } from '../../helpers/ControlsHelpers';
import { HLayout, MainLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { Card } from '../../../types';
import { removeArrayElement, moveArrayElement } from '../../utils';
import { showChooseFacebookShopProductsDialog } from '../../entry-points/EntryPointFacebookShops/components/ChooseFacebookShopProductsDialog';
import { pluginWidth } from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';

export { FacebookShopsProductListPluginConfig };

export type FacebookShopsProductListPluginCard =
  Card<facebookShopsProductListPluginFragment_config>;

const MAX_NO_EDITING_QTY = 3;
const PRODUCT_VIEW_PADDING = 8;
const PRODUCTS_QTY_LIMIT = 10;

const compareById = (a: FacebookShopsProduct, b: FacebookShopsProduct) =>
  a.id === b.id;

export class FacebookShopsProductListPlugin extends MainLayout {
  TEST_NAME = 'FacebookShopsProductListPlugin';
  public _node: Node;
  public _card: FacebookShopsProductListPluginCard;
  public _config: FacebookShopsProductListPluginConfig;
  private readonly productCardsLayout: HLayout;

  constructor(card: FacebookShopsProductListPluginCard, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors._ff0000,
        opacity: 0,
      },
    });

    // eslint-disable-next-line no-param-reassign
    card.config.products = card.config.products || [];

    this._card = card;
    this._node = node;
    this._config = card.config;

    this.productCardsLayout = new HLayout({
      itemsOffset: () =>
        card.isEditing ? 10 : -(pluginWidth - PRODUCT_VIEW_PADDING),
      zIndexFunc: (zIndex) => 1000 - zIndex + 1,
    });

    this.productCardsLayout.sortableChildren = true;
    this.addToLayout(this.productCardsLayout, {
      margin: () => ({
        top:
          Math.min(MAX_NO_EDITING_QTY, card.config.products?.length) *
          PRODUCT_VIEW_PADDING,
      }),
      gone: () => !card.config.products?.length,
    });

    this.addToLayout(
      new FacebookShopsProductAddButton({
        onClick: () => {
          logFlowPluginEvent(
            PluginType.facebook_shops_product_list,
            'Add Product big button click',
            {
              blockId: node.id,
              cardId: card?.id,
            },
          );
          this.addCard();
        },
      }),
      {
        gone: () => !!card.config.products?.length,
      },
    );

    this.updateProductsCards();
  }

  addCard(index?: number) {
    logFlowPluginEvent(
      PluginType.facebook_shops_product_list,
      'Choose product dialog show',
      {
        blockId: this._node.id,
        cardId: this._card?.id,
      },
    );
    showChooseFacebookShopProductsDialog({
      target: PluginType.facebook_shops_product_list,
      checkedProductsIds: this._config.products.map(({ id }) => id),
      productsOnly: true,
      limit: PRODUCTS_QTY_LIMIT,
      showPrependEntryPointProduct: true,
      onSubmit: (checked, notLoadedCheckedProductsIds) => {
        const maxIndex = this._config.products.length;
        const currentIndex =
          typeof index === 'number' && index < maxIndex ? index : maxIndex;
        const checkedProducts = checked
          .map(removeTypename)
          .map(prop('preview'))
          .filter(Boolean) as FacebookShopsProduct[];
        checkedProducts.push(
          ...(this._config.products?.filter(({ id }) =>
            notLoadedCheckedProductsIds.includes(id),
          ) || []),
        );
        const checkedProductsSafe = checkedProducts.slice(
          0,
          PRODUCTS_QTY_LIMIT,
        );
        const newProducts = differenceWith(
          compareById,
          checkedProductsSafe,
          this._config.products,
        );
        const oldProducts = differenceWith(
          compareById,
          checkedProductsSafe,
          newProducts,
        );
        this._config.products.length = 0;
        this._config.products.push(...oldProducts);
        this._config.products.splice(currentIndex, 0, ...newProducts);
        this.updateProductsCards();
        this._node.updateCard(this._card);
        this.renderNode();
      },
    });
  }

  updateProductsCards() {
    this.productCardsLayout.views().forEach((productCardView) => {
      productCardView.destroy();
      this.productCardsLayout.removeView(productCardView);
    });

    this._config.products.forEach((product, index) => {
      this.layoutProductCard(product, index);
    });
  }

  layoutProductCard(product: FacebookShopsProduct, index?: number) {
    const config: ProductCardViewConfig = {
      id: product.id,
      title: product.name,
      description: product.price,
      image_url: product.image_url,
    };

    const productCardView = new ProductCardView({
      card: this._card,
      node: this._node,
      config,
      onImageClick: (event) => {
        if (this._card.isEditing) {
          event.stopPropagation();
          this.addCard(index !== undefined ? index + 1 : undefined);
        }
      },
    });
    this.productCardsLayout.addToLayout(
      productCardView,
      {
        margin: () => {
          const index = this.productCardsLayout.findViewIndex(productCardView);
          const top = this._card.isEditing ? 0 : -PRODUCT_VIEW_PADDING * index;
          return { top };
        },
        gone: () => {
          const index = this.productCardsLayout.findViewIndex(productCardView);
          return !this._card.isEditing && index + 1 > MAX_NO_EDITING_QTY;
        },
      },
      typeof index === 'number' ? index : undefined,
    );
  }

  removeCard(productCardView: ProductCardView) {
    removeArrayElement(this._config.products, productCardView._config);
    this.productCardsLayout.removeView(productCardView);
    productCardView.destroy();
    this._node.updateCard(this._card);
    this.renderNode();
  }

  moveCard(_: ProductCardView, startIndex: number, index: number) {
    moveArrayElement(this._config.products, startIndex, index);
    this._node.updateCard(this._card);
  }

  onBeforeRender() {
    this.productCardsLayout.views().forEach((v) => {
      if (this._card.isEditing) {
        galleryCardControl(v, this);
      } else {
        galleryCardControl().unsubscribe(v);
      }
    });
  }

  hasSignificantChangesInConfig() {
    return !!this._config.products?.length;
  }
}
