import { VLayout } from '../../../../components/Elements/Layouts';
import { pluginWidth } from '../../../plugin_consts';
import { inboundLinksEntryPointFragment_config_flows as Flow } from '../../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';
import { Label } from '../../../kit/Label';
import {
  LINKS_TO_BE_SHOWN_PER_BLOCK_LIMIT,
  LINK_ITEM_COMMON_STYLES,
  LINKS_CONTAINER_COMMON_STYLES,
} from '../constants';
import { GrayBoxView } from '../../../kit/GrayBox';
import { InboundLinkView } from './InboundLink';
import { MoreItemsButtonView } from './MoreItemsButton';
import { getFullCounterStats } from '../../../components/BlockStatsView/utils';
import {
  getFlowsBlockHref,
  logInboundLinkEvent,
  logMoreButtonEvent,
} from '../../../../EditorPanel/components/plugins/InboundLinksEntryPoint/helpers';
import { renderByLimit } from './helpers';

interface FlowsInboundLinksViewProps {
  botId: string;
  flowId: string;
  blockId: string;
  flows: Flow[];
  onMoreButtonClick(): void;
}

export class FlowsInboundLinksView extends GrayBoxView {
  constructor({
    botId,
    flowId: currentFlowId,
    blockId: inboundLinksBlockId,
    flows,
    onMoreButtonClick,
  }: FlowsInboundLinksViewProps) {
    super();

    const linksContainer = new VLayout({});

    const logInboundLinkClick = (href: string) => {
      logInboundLinkEvent({
        botId,
        flowId: currentFlowId,
        channel: 'flows',
        source: 'card',
        href,
      });
    };

    renderByLimit(
      LINKS_TO_BE_SHOWN_PER_BLOCK_LIMIT,
      flows,
      [
        {
          getBlocks: (flow) => flow.blocks,
          renderBlock: (
            flowId,
            { id: blockId, card_id: cardId, counter_id, title },
          ) => {
            const href = getFlowsBlockHref({ botId, flowId, blockId, cardId });
            const stats = getFullCounterStats(counter_id, inboundLinksBlockId);
            linksContainer.addToLayout(
              new InboundLinkView({
                title,
                href,
                stats,
                onOpenHref: () => {
                  logInboundLinkClick(href);
                },
              }),
              LINK_ITEM_COMMON_STYLES,
            );
          },
        },
      ],
      (title) => {
        linksContainer.addToLayout(
          new Label({
            text: title,
            width: pluginWidth - 60,
          }),
          LINK_ITEM_COMMON_STYLES,
        );
      },
      (showMoreCount) => {
        linksContainer.addToLayout(
          new MoreItemsButtonView({
            count: showMoreCount,
            onClick: () => {
              logMoreButtonEvent({
                channel: 'flows',
                botId,
                flowId: currentFlowId,
                count: showMoreCount,
              });
              onMoreButtonClick();
            },
          }),
          LINK_ITEM_COMMON_STYLES,
        );
      },
    );

    this.addToLayout(linksContainer, LINKS_CONTAINER_COMMON_STYLES);
  }
}
