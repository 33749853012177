import i18next from 'i18next';
import { nTimeNotificationPluginFragment_config } from '@components/Plugins/NTimeNotificationPlugin/@types/nTimeNotificationPluginFragment';
import {
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
} from '@components/ConnectPageDialog';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { SetAttributeView } from '../../components/SetAttributeView';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { pluginNTNSvgTexture } from '../../../assets/textures';
import { createLineMenu } from '../../Menu/createLineMenu';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import { isEmptyString, notEmptyString } from '../../validation';
import { HTMLText } from '../../../components/Elements/Shapes';
import { Node } from '../../../Node';
import { BlockView } from '../../block_view';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { LineStartView } from '../../components/Line/LineStartView';
import { PlusButtonView } from '../../plus_button_view';
import { IMAGE_SIZE, createImageBox } from './utils/createImageBox';
import { getNTimeNotificationPluginTexts } from './utils/getNTimeNotificationPluginTexts';
import { BotStatusName } from '@globals';
import { getHandleConnectClick } from './utils/getHandleConnectClick';
import css from './NTimeNotificationPluginView.css';
import { HEXColors } from '@ui/_common/colors';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import {
  MediaTypes,
  createHTMLMediaTooltipByPlatform,
} from '../../utils/createHTMLMediaTooltip';
import { getFlowPlatform } from '@components/FlowBuilder/utils/getFlowPlatform';

export class NTimeNotificationPluginView
  extends VLayout
  implements StatefulPluginDelegate<nTimeNotificationPluginFragment_config>
{
  TEST_NAME = 'NTimeNotificationPluginView';

  private isImageUploadError: boolean = false;

  private readonly lineStartView: LineStartView;

  private readonly messageView: SetAttributeView;

  constructor(
    public readonly state: StatefulPlugin<nTimeNotificationPluginFragment_config>,
    private readonly _node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this.state.setDelegate(this);

    const texts = getNTimeNotificationPluginTexts(state.data.config.frequency);

    this.layout(
      new PluginTitleLayout(
        false,
        `${texts.frequency} ${i18next.t('NTimeNotificationPlugin.Card.title')}`,
        pluginNTNSvgTexture,
        state.data,
      ),
      {
        margin: { top: 10, left: 10, bottom: 10 },
      },
    );

    const topicLayout = new HLayout({});
    this.layout(topicLayout);

    topicLayout.layout(
      createImageBox({
        getImageUrl: () => state.data.config.image_url ?? '',
        onSuccessUpload: (url) => {
          this.state.set(({ config }) => ({
            config: { ...config, image_url: url },
          }));
          this.isImageUploadError = false;
        },
        onFailedUpload: () => {
          this.isImageUploadError = true;
        },
      }),
    );
    this.messageView = new SetAttributeView(
      this.state.data,
      {
        maxLength: 65,
        height: 0,
        text: this.state.data.config.title ?? '',
        caption: i18next.t('NTimeNotificationPlugin.Card.titlePlaceholder'),
        width: pluginWidth - 32,
        singleLine: false,
        margin: () => ({
          left: 10,
          right: 10,
          top: state.isEditing ? 8 : 0,
          bottom: 8,
        }),
        isValid: () => notEmptyString(this.state.data.config.title),
        isEditing: () => this.state.isEditing,
      },
      undefined,
      () => {
        const newText = this.messageView.text().trim();
        if (newText !== this.state.data.config.title) {
          this.state.set(({ config }) => ({
            config: { ...config, title: newText },
          }));
          _node.updateCard(state.data);
        }
        this.messageView.text(newText);
      },
      () => this.messageView.text(this.state.data.config.title ?? ''),
      {
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
        discount: ControlVisibility.show,
      },
    );
    this.messageView.text(this.state.data.config.title ?? '');
    this.layout(this.messageView, {
      margin: { top: 20, left: 15, bottom: 0 },
    });

    this.layout(
      new HTMLText({
        fontSize: 15,
        fill: HEXColors.greyDark,
        text: texts.description,
        width: pluginWidth - 80,
        verticalAlign: 'center',
      }),
      {
        margin: () => ({
          top: this.state.isEditing ? 0 : -7,
          left: 26,
          bottom: 22,
        }),
      },
    );

    const notifyButton = new PlusButtonView(
      i18next.t('NTimeNotificationPlugin.Card.getMessages', {
        frequency: texts.frequency.toLowerCase(),
      }),
      pluginWidth - 32,
      40,
      HEXColors.greyDark,
    );

    this.layout(notifyButton, { margin: { bottom: 10, left: 16 } });

    this.lineStartView = new LineStartView(
      {
        from: this,
        node: _node,
        offset: 40,
        mandatory: false,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: (blockView: BlockView) => {
          this.state.set(({ config }) => ({
            config: { ...config, block_id: blockView.id() },
          }));
          this.renderNode();
        },
        onRemoved: () => {
          this.state.set(({ config }) => ({
            config: { ...config, block_id: null },
          }));
          this.renderNode();
        },
      },
      state.data.id,
    );

    notifyButton.layout(this.lineStartView, {
      align: 'end',
      verticalAlign: 'center',
      margin: { left: -4 },
    });

    this.on('click', (e) => {
      if (e.target.parent === this.messageView) return;
      getPixiFieldStrict().fixBlockPosition(_node.blockView, getPanelWidth());
      editPluginInEditorPanel(_node.id, state.data, state);
    });

    const urlParams = getConnectPageUrlParams();
    if (
      urlParams[ConnectPageField.origin] === ConnectPageOrigin.ntn &&
      urlParams[ConnectPageField.blockId] === _node.id
    ) {
      this.connectFacebookPage();
    }
  }

  onBeforeRender() {
    const { config } = this.state.data;
    if (config.block_id)
      this._node.addOutLink(config.block_id, this.lineStartView._lineView);

    this.messageView.text(config.title || '');

    this.messageView._textNode.fontStyle(
      this.state.isEditing ? 'normal' : 'semibold',
    );
  }

  validationError() {
    const { flow } = this._node.controller;
    const { title, image_url, topic_id, frequency } = this.state.data.config;

    if (flow.botStatus?.status === BotStatusName.connected) {
      if (this.isImageUploadError) {
        return createHTMLMediaTooltipByPlatform(
          getFlowPlatform()!,
          MediaTypes.image,
          IMAGE_SIZE,
        );
      }

      const hasAnyError =
        isEmptyString(image_url) ||
        isEmptyString(title) ||
        isEmptyString(topic_id);
      if (!hasAnyError) {
        return undefined;
      }

      const { frequency: frequencyText } =
        getNTimeNotificationPluginTexts(frequency);

      let errorText = `${i18next.t('NTimeNotificationPlugin.tooltipHeader', {
        frequencyText,
      })} </br></br>`;

      if (isEmptyString(image_url))
        errorText += `• ${i18next.t(
          'NTimeNotificationPlugin.addImageTooltip',
        )}</br>`;

      if (isEmptyString(title))
        errorText += `• ${i18next.t(
          'NTimeNotificationPlugin.addMessageTitleTooltip',
        )}</br>`;

      if (isEmptyString(topic_id))
        errorText += `• ${i18next.t('NTimeNotificationPlugin.addTopicTooltip')}
				</br></br>
				<span style="text-decoration: underline;">${i18next.t(
          'NTimeNotificationPlugin.addNotificationTopic',
        )}</span>	
				`;

      return {
        message: errorText,
        onClick: () =>
          editPluginInEditorPanel(this._node.id, this.state.data, this.state),
      };
    }

    return {
      message: `
        <div class=${css.facebookConnectTooltipTitle}>
          ${i18next.t('NTimeNotificationPlugin.facebookCoonectTooltip.title')}
        </div>
        <div class=${css.facebookConnectTooltipLink}>
          ${i18next.t('NTimeNotificationPlugin.facebookCoonectTooltip.link')}
        </div>
      `,
      onClick: this.connectFacebookPage.bind(this),
    };
  }

  connectFacebookPage() {
    const { flow } = this._node.controller;
    getHandleConnectClick(flow.botId, this._node.id)();
  }

  hasSignificantChangesInConfig() {
    const { title, block_id, image_url } = this.state.data.config;

    return (
      notEmptyString(image_url) ||
      notEmptyString(title) ||
      notEmptyString(block_id)
    );
  }

  pluginDidSet() {
    if (this.state.data.isEditing) {
      return;
    }
    this.state.save();
    this.renderNode();
  }

  pluginDidSave() {
    this.renderNode();
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
