import { escapeHtml } from 'xss';
import { upperFirst } from '@utils/String/upperFirst';
import { getFullCounterStats } from '../../../../components/BlockStatsView/utils';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import {
  removeTooltip,
  tooltipScaled,
} from '../../../../helpers/TooltipHelpers';
import { HLayout, VLayout } from '../../../../../components/Elements/Layouts';
import { BlockView } from '../../../../block_view';
import { Node } from '../../../../../Node';
import { BubbleInputField } from '../../../../kit/BubbleInputField';
import { resByFunc } from '../../../../utils';
import { pluginWidth } from '../../../../plugin_consts';
import { LineStartView } from '../../../../components/Line/LineStartView';
import { KeywordGroupProps, KeywordGroupConfig } from './types';
import { Dropdown } from '../../../../kit/Dropdown';
import { propEq } from 'ramda';
import { getFilterTypeItems } from '@utils/Ai/getFilterTypeItems';
import { AiIntentFilterType, OnboardingTourEventType } from '@globals';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

export class KeywordGroup extends VLayout {
  TEST_NAME = 'KeywordGroup';
  private _node: Node;
  private bubbleInputView: BubbleInputField;
  private lines: string[] | undefined;

  getData: () => KeywordGroupConfig | undefined;
  lineStartView: LineStartView;
  props: KeywordGroupProps;

  constructor(
    getData: () => KeywordGroupConfig | undefined,
    node: Node,
    props: KeywordGroupProps,
  ) {
    super({});

    this._node = node;
    this.getData = getData;
    this.props = props;
    this.lines = getData()?.lines;

    const filterTypeItems = getFilterTypeItems().map(
      ({ tooltipContent, ...item }) => ({
        ...item,
        tooltip: {
          html: tooltipContent,
        },
      }),
    );

    if (this.props.showFilterTypeSelector) {
      this.addToLayout(
        new Dropdown({
          defaultSelectedItem:
            filterTypeItems.find(propEq('id', getData()?.filter_type)) ||
            filterTypeItems[0],
          items: filterTypeItems,
          onChange: (selectedItem) => {
            this.props.onChangeFilterType?.(
              (selectedItem.id as AiIntentFilterType) ||
                AiIntentFilterType.default,
            );
            logFlowPluginEvent(
              this.props.targetPlugin,
              'Keyword Group filter type change',
              {
                blockId: node.id,
                filter_type: selectedItem?.id,
              },
            );
          },
          isEditing: this.props.editable,
        }),
      );
    }

    this.bubbleInputView = new BubbleInputField({
      name: props.bubbleInputName,
      editable: this.props.editable,
      width: pluginWidth - 32,
      config: {
        value: this.lines ?? [],
      },
      onStartEditing: () => {
        if (!props.bubbleInputName) return;
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          // TODO: change to string
          value: this.lines ?? ([] as any),
          element: props.bubbleInputName,
        });
      },
      onSetSingleLine: () => {
        if (!props.bubbleInputName) return;
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          // TODO: change to string
          value: this.lines ?? ([] as any),
          element: props.bubbleInputName,
        });
      },
      onChange: ({ value }) => {
        this.lines = value;
      },
      onDoneEditing: () => {
        this.props.onChangeLines?.(this.lines ?? []);
        logFlowPluginEvent(this.props.targetPlugin, 'Keyword Group change', {
          blockId: node.id,
          lines: this.lines,
        });
      },
      errorLines: () => this.getCurrentErrorLines(),
      stats: getFullCounterStats(
        this.getData()?.intent_id ?? '',
        node.block.id,
      ),
    });

    const hBox = new HLayout();

    hBox.addToLayout(this.bubbleInputView);

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: true,
        onConnected: (blockView: BlockView) => {
          this.props.onChangeBlock?.(blockView.id());
          this.renderNode();
        },
        onRemoved: () => {
          this.props.onChangeBlock?.(null);
          this.renderNode();
        },
        items: props.lineParams?.items,
        searchable: props.lineParams?.searchable,
        defaultTargetBlockData: props.lineParams?.defaultTargetBlockData,
        menuStyle: props.lineParams?.menuStyle,
        subtype: props.lineParams?.subtype,
      },
      undefined,
    );

    hBox.addToLayout(this.lineStartView, {
      margin: { left: 10 },
      align: 'center',
    });

    this.addToLayout(hBox);
  }

  onBeforeRender() {
    if (this.getData()?.goto_block?.length) {
      this._node.addOutLink(
        this.getData()?.goto_block?.[0],
        this.lineStartView._lineView,
      );
    }
    const currentErrorLines = this.getCurrentErrorLines();

    if (currentErrorLines.length) {
      tooltipScaled({
        view: this,
        text: `${currentErrorLines
          .filter(Boolean)
          .map(upperFirst)
          .map(escapeHtml)
          .join(', ')}${window.i18next.t(
          'KeywordGroup-Template-1433-is-already-being-used-in-this-or-another-keyword-group-in-this-entry-point',
        )}`,
      });
    } else {
      removeTooltip(this);
    }
  }

  startEditing() {
    this.bubbleInputView.startEditing();
  }

  getCurrentErrorLines() {
    const values = this.lines?.map((value) => value.toLowerCase());
    const errorValues = new Set();

    if (values) {
      const uniqueValues = new Set();

      // Check is all values unique
      // if not - add it to error values
      values.forEach((value) => {
        if (uniqueValues.has(value)) {
          errorValues.add(value);
          return;
        }

        uniqueValues.add(value);
      });

      // Check is current values not exists at other keyword group lines
      // if exists - add it to error values
      ((resByFunc(this.props.otherLines) || []) as string[])
        .map((line) => line.toLowerCase())
        .forEach((line) => {
          if (uniqueValues.has(line)) {
            errorValues.add(line);
          }
        });
    }

    return [...errorValues] as string[];
  }

  destroy() {
    removeTooltip(this);
    super.destroy();
  }
}
