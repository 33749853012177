import { FlowBuilderError } from '../common/errors';

export class OptInError extends FlowBuilderError {
  constructor() {
    super({
      messageKey: 'shopify.backInStock.entryPoint.optInError',
      isBlockError: true,
    });
  }
}
