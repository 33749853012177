import { Messages, toaster } from '@services/MessageService';
import gql from 'graphql-tag';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { Level, log } from 'cf-common/src/logger';
import client from '../../../../../../common/services/ApolloService';
import {
  DisconnectCalendlyAccountMutation,
  DisconnectCalendlyAccountMutationVariables,
} from './@types/DisconnectCalendlyAccountMutation';

const DISCONNECT_CALENDLY_ACCOUNT = gql`
  mutation DisconnectCalendlyAccountMutation(
    $botId: String!
    $accountId: String!
  ) {
    disconnectCalendlyAccount(botId: $botId, accountId: $accountId) {
      id
      connectedCalendlyAccounts {
        id
        admin_id
      }
    }
  }
`;

export const disconnectCalendlyAccount = ({
  botId,
  accountId,
}: DisconnectCalendlyAccountMutationVariables) =>
  client
    .mutate<
      DisconnectCalendlyAccountMutation,
      DisconnectCalendlyAccountMutationVariables
    >({
      mutation: DISCONNECT_CALENDLY_ACCOUNT,
      variables: {
        botId,
        accountId,
      },
    })
    .catch((error) => {
      log({
        error,
        level: Level.error,
        msg: `Error disconnecting Calendly account, ${error.toString()}`,
        data: {
          label: 'calendly',
          requestId: getRequestIdFromApolloError(error),
        },
      });
      toaster.error({
        payload: {
          message: Messages.somethingWentWrong,
        },
      });
    });
