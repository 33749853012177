import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
} from '@components/ConnectPageDialog';
import { BLOCK_SUBTYPES } from '../../consts';
import { getFullCounterStats } from '../components/BlockStatsView/utils';
import { logFlowPluginEvent } from '../../utils/Analytics';
import { HLayout, VLayout } from '../../components/Elements/Layouts';
import { SetAttributeView } from '../components/SetAttributeView';
import { PluginTitleLayout } from '../components/PluginTitleLayout';
import { pluginOtnSvgTexture } from '../../assets/textures';

import { tooltips } from '../Menu/menu_view';
import { createLineMenu } from '../Menu/createLineMenu';
import { ControlVisibility } from '../../FlowBuilderOverlay';
import { isEmptyString, notEmptyString } from '../validation';
import { HTMLText } from '../../components/Elements/Shapes';
import { Node } from '../../Node';
import { Card, FlowData } from '../../types';
import { BlockView } from '../block_view';
import { pluginWidth, textCardBackgroundColor } from '../plugin_consts';
import { LineStartView } from '../components/Line/LineStartView';
import { PlusButtonView } from '../plus_button_view';
import { HEXColors } from '@ui/_common/colors';
import i18next from 'i18next';
import { getFlowControllerStrict } from '../../PixiFieldRepository';

const hideTopic = (node: Node) =>
  node.block.subtype === BLOCK_SUBTYPES.back_in_stock_optin_confirmation;

interface OneTimeNotificationPluginConfig {
  title?: string;
  purpose?: string;
  block_id?: string;
  purpose_hidden?: boolean;
}

export class OneTimeNotificationPluginView extends VLayout {
  TEST_NAME = 'OneTimeNotificationPluginView';

  private readonly _node: Node;

  private readonly _card: Card<OneTimeNotificationPluginConfig>;

  private readonly _flow: FlowData;

  private readonly lineStartView: LineStartView;

  private readonly titleLayout: PluginTitleLayout;
  private readonly topicTitle: HTMLText;
  private readonly topicView: SetAttributeView;
  private readonly messageView: SetAttributeView;

  constructor(card: Card<OneTimeNotificationPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._card = card;
    this._node = node;
    this._flow = this._node.controller.flow;

    this.titleLayout = new PluginTitleLayout(
      false,
      i18next.t('OneTimeNotificationPlugin-string-1975-one-time-notification'),
      pluginOtnSvgTexture,
      card,
      tooltips().otn,
    );

    const topicLayout = new HLayout({});
    this.layout(topicLayout, {
      gone: () => hideTopic(this._node),
    });
    this.topicTitle = new HTMLText({
      fontSize: 15,
      fontColor: HEXColors.greyDark,
      text: i18next.t('OneTimeNotificationPlugin-string-8099-topic'),
      height: 35,
      verticalAlign: 'center',
    });
    this.topicView = new SetAttributeView(
      card,
      {
        width: 224,
        fill: HEXColors.black,
        maxLength: 65,
        caption: i18next.t(
          'OneTimeNotificationPlugin-string--147-notification-topic',
        ),
        isValid: () => notEmptyString(this._card.config.purpose),
      },
      undefined,
      () => {
        const newText = this.topicView.text().trim();
        if (newText !== this._card.config.purpose) {
          this._card.config.purpose = newText;
          node.updateCard(card);
          logFlowPluginEvent(card.plugin_id, 'update topic', {
            blockId: node.id,
            cardId: card.id,
            value: newText,
          });
        }
        this.topicView.text(newText);
      },
      undefined,
      {
        attributes: ControlVisibility.hide,
        emoji: ControlVisibility.hide,
      },
    );
    this.topicView.text(this._card.config.purpose);
    topicLayout.layout(this.topicTitle).layout(this.topicView, {
      margin: () => ({
        left: card.isEditing ? 10 : 4,
      }),
    });

    this.messageView = new SetAttributeView(
      card,
      {
        maxLength: 65,
        height: 0,
        text: this._card.config.title,
        caption: i18next.t(
          'OneTimeNotificationPlugin-string--141-enter-message',
        ),
        width: pluginWidth - 30,
        singleLine: false,
        margin: () => ({
          left: card.isEditing ? 10 : 0,
          right: 10,
          top: card.isEditing ? 8 : 0,
          bottom: 8,
        }),
        isValid: () => notEmptyString(this._card.config.title),
      },
      undefined,
      () => {
        const newText = this.messageView.text().trim();
        if (newText !== this._card.config.title) {
          this._card.config.title = newText;
          node.updateCard(card);
          logFlowPluginEvent(card.plugin_id, 'update message', {
            blockId: node.id,
            cardId: card.id,
            value: newText,
          });
        }
        this.messageView.text(newText);
      },
      () => this.messageView.text(this._card.config.title),
      {
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
        discount: ControlVisibility.show,
      },
    );
    this.messageView.text(this._card.config.title);

    this.layout(this.titleLayout, {
      margin: { top: 15, left: 10, bottom: 10 },
    });
    this.layout(topicLayout, {
      margin: () => ({
        left: 15,
        bottom: card.isEditing ? 15 : 8,
      }),
    });
    this.layout(this.messageView, { margin: { left: 15, bottom: 0 } });
    const notifyButton = new PlusButtonView(
      i18next.t('OneTimeNotificationPlugin-string--388-notify-me'),
      pluginWidth - 30,
      40,
      HEXColors.greyDark,
      getFullCounterStats(`${card.id}_button`, node.block.id),
    );

    this.layout(
      new HTMLText({
        fontSize: 15,
        fill: HEXColors.greyDark,
        text: i18next.t('OneTimeNotificationPlugin-string--388-description', {
          pageTitle: getFlowControllerStrict().flow.botStatus?.page_info?.title,
        }),
        width: pluginWidth - 80,
        verticalAlign: 'center',
      }),
      {
        margin: () => ({
          top: this._card.isEditing ? 0 : -7,
          left: 15,
          bottom: 10,
        }),
      },
    );

    this.layout(notifyButton, { margin: { bottom: 10, left: 15 } });

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: false,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: (blockView: BlockView) => {
          this._card.config.block_id = blockView.id();
          node.updateCard(card);
          this.renderNode();
        },
        onRemoved: () => {
          this._card.config.block_id = undefined;
          node.updateCard(card);
          this.renderNode();
        },
      },
      card.id,
    );

    notifyButton.layout(this.lineStartView, {
      align: 'end',
      verticalAlign: 'center',
      margin: { left: -4 },
    });

    const urlParams = getConnectPageUrlParams();
    if (
      urlParams[ConnectPageField.origin] === ConnectPageOrigin.otn &&
      urlParams[ConnectPageField.blockId] === this._card.config.block_id
    ) {
      this.connectFacebookPage();
    }
  }

  onBeforeRender() {
    const { config } = this._card;
    if (config.block_id)
      this._node.addOutLink(config.block_id, this.lineStartView._lineView);

    this.topicTitle.text(
      this._card.isEditing
        ? i18next.t('OneTimeNotificationPlugin-string-8099-topic')
        : i18next.t('OneTimeNotificationPlugin-string--178-topic'),
    );
    this.messageView.text(
      this._card.isEditing
        ? config.title || ''
        : `${i18next.t(
            'OneTimeNotificationPlugin-Template-1312-get-notified',
          )}${config.title ? config.title : ''}`,
    );

    this.messageView._textNode.fontStyle(
      this._card.isEditing ? 'normal' : 'semibold',
    );
  }

  validationError() {
    if (this._flow.botStatus?.status === 'connected') {
      const otnFeature = this._flow.botFeatures.otn_enabled;
      if (!otnFeature && this._flow.botStatus.page_info) {
        const { id, title } = this._flow.botStatus.page_info;
        return {
          message:
            'You can use a One-Time Notification<br/>feature after the Messenger team<br/>approves it.<br/><br/><span style="text-decoration: underline;">Request feature</span>',
          url: `https://www.facebook.com/${title}-${id}/settings/?tab=messenger_platform`,
        };
      }

      const { title, purpose } = this._card.config;
      let contentError = '';
      if (isEmptyString(title)) {
        contentError += `• ${i18next.t(
          'OTNPlugin.addMessageTitleTooltip',
        )}</br>`;
      }
      if (!hideTopic(this._node) && isEmptyString(purpose)) {
        contentError += `• ${i18next.t('OTNPlugin.addTopicTooltip')}</br>`;
      }
      if (contentError) {
        return {
          message: `${i18next.t(
            'OTNPlugin.tooltipHeader',
          )}<br/></br>${contentError}`,
        };
      }
      return undefined;
    }

    return {
      message:
        'Connect your Facebook Page to send<br/>One-Time Notifications opt-in<br/>requests.<br/><br/><span style="text-decoration: underline;">Connect Facebook Page</span>',
      onClick: () => this.connectFacebookPage(),
    };
  }

  connectFacebookPage() {
    connectPage({
      botId: this._flow.botId,
      urlParams: {
        [ConnectPageField.origin]: ConnectPageOrigin.otn,
        [ConnectPageField.blockId]: this._card.config.block_id,
      },
      onPageConnected: ({ status, pageId, verifiedPermissions }) => {
        this._node.controller.updateFlowBuilderPage(
          status,
          pageId,
          verifiedPermissions,
        );
      },
    });
  }

  hasSignificantChangesInConfig() {
    const { title, purpose, block_id } = this._card.config;

    return (
      notEmptyString(purpose) ||
      notEmptyString(title) ||
      notEmptyString(block_id)
    );
  }
}
