import client from '@common/services/ApolloService';
import {
  UpdateCardTemplateMutation,
  UpdateCardTemplateMutationVariables,
} from './@types/UpdateCardTemplateMutation';
import { UPDATE_CARD_TEMPLATE_MUTATION } from './queries';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';

interface UpdateCardTemplate {
  blockId: string;
  cardId: string;
  templateId?: string;
  managedTemplateId?: string;
}

export const updateCardTemplate = async ({
  blockId,
  cardId,
  templateId,
  managedTemplateId,
}: UpdateCardTemplate) => {
  const { botId } = getFlowControllerStrict().flow;
  const result = await client.mutate<
    UpdateCardTemplateMutation,
    UpdateCardTemplateMutationVariables
  >({
    mutation: UPDATE_CARD_TEMPLATE_MUTATION,
    variables: {
      botId,
      blockId,
      cardId,
      templateId,
      managedTemplateId,
    },
  });
  return result.data?.updateCardTemplate;
};
