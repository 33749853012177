import { log } from 'cf-common/src/logger';
import { Loader } from '@googlemaps/js-api-loader';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';
import { getGeolocation } from '@utils/Navigation';
import { MapInitOptions, LocationPickerPlace } from './types';
import { assert } from '@utils/Assert';

export interface GoogleMapsPlacePickerParams {
  location?: LocationPickerPlace;
  onError(error: Error, info?: string | Object): void;
  setPlace(place: LocationPickerPlace): void;
}

export const useGoogleMapsPlacePicker = ({
  location,
  onError,
  setPlace,
}: GoogleMapsPlacePickerParams) => {
  const mapId = 'mapId';
  const inputId = 'inputId';
  const containerInputId = 'containerInputId';

  const loadMaps = () => {
    const getMapInitOptions = async (): Promise<MapInitOptions> => {
      return location
        ? Promise.resolve({ ...location, zoom: 17 })
        : getGeolocation()
            .then((x) => ({
              latitude: x.coords.latitude.toString(),
              longitude: x.coords.longitude.toString(),
              zoom: 17,
            }))
            .catch((error) => {
              log.warn({ msg: 'Failed to get geolocation', error });

              return { latitude: '0', longitude: '0', zoom: 1 };
            });
    };

    const loader = new Loader({
      apiKey: window.CHATFUEL_CONFIG.GOOGLE_MAPS_API_KEY as string,
      version: 'weekly',
    });

    Promise.all([
      getMapInitOptions(),
      loader.importLibrary('maps'),
      loader.importLibrary('places'),
      loader.importLibrary('geocoding'),
      loader.importLibrary('marker'),
    ])
      .then(([mapOptions, mapLib, placesLib, geocodingLib, markerLib]) => {
        let marker: google.maps.Marker | null = null;
        const mapElement = document.getElementById(mapId);

        if (!mapElement) {
          onError(new Error('Could not find element to render map on'));
          return;
        }

        const map = new mapLib.Map(mapElement, {
          center: {
            lat: Number(mapOptions.latitude),
            lng: Number(mapOptions.longitude),
          },
          zoom: mapOptions.zoom,
          mapTypeControl: false,
          streetViewControl: false,
        });
        const service = new placesLib.PlacesService(map);
        const geocoder = new geocodingLib.Geocoder();

        if (location) {
          const loc = {
            lat: Number(mapOptions.latitude),
            lng: Number(mapOptions.longitude),
          };

          marker = new markerLib.Marker({
            position: loc,
            map,
          });
        }

        const input = document.getElementById(
          inputId,
        ) as HTMLInputElement | null;
        if (!input) {
          onError(new Error('Could not find search input element'));
          return;
        }

        const inputContainer = document.getElementById(containerInputId);
        if (!inputContainer) {
          onError(new Error('Could not find search input container element'));
          return;
        }
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputContainer);

        const autocomplete = new placesLib.Autocomplete(input);
        autocomplete.bindTo('bounds', map);

        map.addListener('click', (event: google.maps.IconMouseEvent) => {
          const { placeId } = event;
          const latitude = event.latLng?.lat().toString();
          const longitude = event.latLng?.lng().toString();

          assert(latitude, { msg: 'latitude must be defined' });
          assert(longitude, { msg: 'longitude must be defined' });

          if (!placeId) {
            marker?.setMap(map);
            if (marker) {
              marker.setPosition(event.latLng);
            } else {
              marker = new markerLib.Marker({
                position: event.latLng,
                map,
              });
            }

            geocoder
              .geocode({ location: event.latLng })
              .then(({ results }) => {
                const [address] = results;

                if (!address) {
                  onError(new Error('No address for location'), {
                    lat: event.latLng?.lat(),
                    lng: event.latLng?.lng(),
                  });
                }

                setPlace({
                  latitude,
                  longitude,
                  name: null,
                  address: address.formatted_address,
                });
              })
              .catch(onError);
            return;
          }

          marker?.setMap(null);

          service.getDetails(
            { placeId, fields: ['name', 'formatted_address'] },
            (place, status) => {
              switch (status) {
                case placesLib.PlacesServiceStatus.OK: {
                  const name = place?.name;
                  const address = place?.formatted_address;

                  assert(name, { msg: 'name must be defined' });
                  assert(address, { msg: 'address must be defined' });

                  return setPlace({
                    latitude,
                    longitude,
                    name,
                    address,
                  });
                }
                case placesLib.PlacesServiceStatus.INVALID_REQUEST:
                case placesLib.PlacesServiceStatus.NOT_FOUND:
                case placesLib.PlacesServiceStatus.OVER_QUERY_LIMIT:
                case placesLib.PlacesServiceStatus.REQUEST_DENIED:
                case placesLib.PlacesServiceStatus.UNKNOWN_ERROR:
                case placesLib.PlacesServiceStatus.ZERO_RESULTS:
                  return onError(new Error('Failed to load places'), status);
                default:
                  return exhaustiveCheck(status);
              }
            },
          );
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          const bounds = new google.maps.LatLngBounds();

          if (!place?.geometry || !place?.geometry.location) {
            onError(new Error(`No details available for input: ${place.name}`));
            return;
          }

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }

          map.fitBounds(bounds);
        });
      })
      .catch((error) =>
        onError(new Error('Failed to load google libraries'), error),
      );
  };

  return {
    mapId,
    inputId,
    containerInputId,
    loadMaps,
  };
};
