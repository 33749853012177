import { HLayout } from '../../../components/Elements/Layouts';
import { HEXColors } from '@ui/_common/colors';

export interface DividerViewProps {
  width: number;
  fill: HEXColors;
}

export class DividerView extends HLayout {
  constructor({ width, fill }: DividerViewProps) {
    super({
      width,
      height: 1,
      background: { fill },
    });
  }
}
