import gql from 'graphql-tag';

const AD_TREE_FRAGMENT = gql`
  fragment adTreeFragment on FacebookAd {
    id
    name
    created_time
    preview_image_small
    preview_image_big
    flow_id
    flow_title
    campaign_id
    account_id
    type
    media_type
    customer_action_type
    page_id
    entry_point_bot_id
    call_to_action_type
    adset {
      id
    }
  }
`;

export const TREE_QUERY = gql`
  query TreeQuery(
    $botId: String!
    $accountId: String
    $limit: Int
    $after: String
    $type: AdCampaignType
    $platform: Platform
  ) {
    bot(id: $botId) {
      id
      adsTree(
        accountId: $accountId
        limit: $limit
        after: $after
        type: $type
        platform: $platform
      ) @connection(key: "adsTree", filter: ["accountId", "type"]) {
        after
        tree {
          id
          name
          account_id
          type
          children {
            id
            name
            children {
              ...adTreeFragment
            }
          }
        }
      }
    }
  }

  ${AD_TREE_FRAGMENT}
`;

export const REMOVE_AD_FROM_ENTRY_POINT_MUTATION = gql`
  mutation RemoveAdFromEntryPoint(
    $botId: String!
    $adId: String!
    $ad: AdTreeInput!
  ) {
    removeAdFromEntryPoint(botId: $botId, adId: $adId, ad: $ad) {
      ...adTreeFragment
    }
  }

  ${AD_TREE_FRAGMENT}
`;
