import nanoid from 'nanoid';
import { IPoint } from 'pixi.js-legacy';
import { LineStartView } from './LineStartView';
import {
  ClickHoldCoordinator,
  ClickHoldCoordinatorDelegate,
} from './ClickHoldCoordinator';
import { FlowArrow } from '../../../components/Elements/Shapes';
import { CreateMenuViewOverlay } from '../../menu_view_overlay';
import { BlockView } from '../../block_view';
import { OverlayType } from '../../../FlowBuilderOverlay';
import {
  getFlowController,
  getPixiField,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { Point } from '../../../types';
import { logFlowEvent } from '../../../utils/Analytics';
import { BLOCK_SUBTYPES } from '../../../consts';
import * as textures from '../../../assets/textures';
import { HEXColors } from '@ui/_common/colors';
import { unlockEntryPointErrorTooltip } from '../../../utils/useActivateEntryPoint';
import { IS_DEBUG, IS_PRODUCTION_BUILD } from 'cf-common/src/environment';

const LINE_CONTROLS_CONFIG = () => ({
  goto: {
    id: 'goto',
    title: window.i18next.t('LineView-string--151-see-linked-block'),
    img: textures.RightArrowIcon,
  },
  delete: {
    id: 'delete',
    title: window.i18next.t('LineView-string-4877-delete-path'),
    img: textures.RemoveIcon,
  },
});

let activeLineObj: LineView | undefined;

export function activeLine() {
  return activeLineObj;
}

export type PontWithClickedEvent = Point & { clicked: boolean };

interface LineViewHandlers {
  onDragStarted(point: PontWithClickedEvent): void;
  onDragFinished(point: PontWithClickedEvent): void;
  onRemove(): void;
}

interface LineViewParams {
  cardId?: string;
  startOffset?: number;
}

export class LineView
  extends FlowArrow
  implements ClickHoldCoordinatorDelegate
{
  TEST_NAME = 'LineView';

  public id = nanoid();
  public cardId?: string;

  public gone = false;
  public dragging: boolean | undefined;

  public toView: BlockView | undefined;
  public fromView: LineStartView;

  private onDragStarted: LineViewHandlers['onDragStarted'];
  private onDragFinished: LineViewHandlers['onDragFinished'];

  private dragStartPoint: Point | undefined;
  private screenSpeed = { x: 0, y: 0 };

  private viewportPointUpdateInterval: NodeJS.Timeout | undefined;

  private clickHoldLineCoordinator: ClickHoldCoordinator;

  constructor(
    fromView: LineStartView,
    { onDragStarted, onDragFinished, onRemove }: LineViewHandlers,
    { cardId, startOffset }: LineViewParams = {},
  ) {
    super({
      end: { x: 0, y: 0 },
      pointerLength: 5,
      pointerWidth: 5,
      opacity: 1,
      startOffset: typeof startOffset !== 'undefined' ? startOffset : 40,
      stroke: HEXColors.greyDark,
      strokeWidth: 2.5,
    });
    this.cardId = cardId;
    this.fromView = fromView;
    this.onDragFinished = onDragFinished;
    this.onDragStarted = onDragStarted;

    this.clickHoldLineCoordinator = new ClickHoldCoordinator(this, {
      onClick: (event) => {
        this.showLineActionsMenu(event, onRemove);
      },
      onHold: (event) => {
        this.handlePointerDown(event, this);
      },
    });

    if (!getPixiFieldStrict().isViewOnly()) {
      this.on('pointerup pointerupoutside', (e: any) => {
        this.handlePointerUp(e, this);
        this.clickHoldLineCoordinator.flush();
      });
      getPixiFieldStrict().hoverHandler().subscribe({
        view: this,
        eventId: 'line_hover',
        over: this.overHandler,
        out: this.outHandler,
      });
    }

    this.onBeforeRender();
  }

  public shouldPreventHold() {
    return (
      getPixiFieldStrict().isViewOnly() || this.fromView.showingContextMenu
    );
  }

  public shouldPreventClick() {
    return getPixiFieldStrict().isViewOnly() || Boolean(this.dragging);
  }

  public onBeforeRender(toView?: BlockView) {
    this.toView = toView;
    if (this.toView) {
      getPixiFieldStrict().hoverHandler().subscribe({
        view: this,
        eventId: 'line_hover',
        over: this.overHandler,
        out: this.outHandler,
      });
      this.show();
    } else {
      this.hide();
    }
  }

  public destroy() {
    this.off('pointerup').off('pointerupoutside');
    getPixiFieldStrict().hoverHandler().remove(this);
    getPixiFieldStrict().eventHandler().remove(this);
    this.clickHoldLineCoordinator.destroy();
    super.destroy();
  }

  public renderLine(toView?: BlockView) {
    if (!this.dragging) {
      this.onBeforeRender(toView);
      this.updateEnd();
      this.fromView.renderLine();
    }
  }

  public toId() {
    if (this.toView) {
      return this.toView.id();
    }
    return undefined;
  }

  public updateEnd(pos?: Point, hover?: boolean) {
    if (this.gone && !this.dragging) {
      this.hide();
      return;
    }

    try {
      if (typeof pos === 'undefined' && !this.toView) {
        if (!this.dragging) {
          this.hide();
        }
        return;
      }
      this.show();

      const fromPos = this.shape().toLocal(
        this.fromView.globalPosition() as IPoint,
      );
      const toPos = pos
        ? this.shape().toLocal(pos as IPoint)
        : this.shape().toLocal(this.toView!.globalPosition() as IPoint);

      const { x, y } = this.shape();
      this.end({ x: x + toPos.x, y: y + toPos.y + 20 });
      this.x = x + fromPos.x;
      this.y = y + fromPos.y;
      this.fromView.renderLine(hover);
    } catch (e) {
      console.error(e);
    }
  }

  private showLineActionsMenu(e: any, onRemove: () => void) {
    /**
     * setTimeout is the workaround to fix showing menu on line click.
     * Menu appears only after the first click and then any clicks on any lines don't trigger showing menu again.
     *
     * TODO: refactor
     */
    let options = Object.values(LINE_CONTROLS_CONFIG());
    const { node, _node } = this.fromView.fromView as any;
    const fromBlock = _node?.block ?? node?.block;
    if (fromBlock.subtype === BLOCK_SUBTYPES.flow_root) {
      options = options.filter((v) => v.id !== 'delete');
    }

    setTimeout(() => {
      new CreateMenuViewOverlay({
        onChoose: (item) => {
          const toId = this.toId();
          const fromId = fromBlock?.id;
          switch (item.id) {
            case LINE_CONTROLS_CONFIG().goto.id: {
              if (toId) {
                logFlowEvent(undefined, 'see linked block', {
                  toId,
                  fromId,
                });
                getFlowController()?.focusOnBlock(toId);
              }
              break;
            }
            case LINE_CONTROLS_CONFIG().delete.id:
              logFlowEvent(undefined, 'delete path', {
                toId,
                fromId,
              });
              onRemove?.();
              break;
            default:
              break;
          }
        },
        items: options,
        menuType: OverlayType.menu,
        style: { opacity: 0.96 },
      }).showOn(e.data.global);
    });
  }

  private hideHover() {
    this.fromView.renderLine(false);
  }

  private showHover() {
    this.fromView.renderLine(true);
  }

  private overHandler = () => {
    this.showHover();
  };

  private outHandler = () => {
    if (!this.dragging) this.hideHover();
  };

  public handlePointerDown = (e: any, view: LineStartView | LineView) => {
    if (IS_DEBUG && IS_PRODUCTION_BUILD) {
      // eslint-disable-next-line no-console
      console.log(`[connector-debug] mouse down - cardId#${this.cardId}`);
    }

    e.stopPropagation();
    this._props.createHit = false;
    this.viewportPointUpdateInterval = setInterval(() => {
      const field = getPixiField();
      if (field) {
        const { viewport } = field;
        viewport.x -= this.screenSpeed.x * 0.3 * viewport.scale.x;
        viewport.y -= this.screenSpeed.y * 0.3 * viewport.scale.x;
      }
    }, 3);

    this.showHover();
    this.dragging = true;
    this.dragStartPoint = { x: e.data.global.x, y: e.data.global.y };
    activeLineObj = this;
    this.onDragStarted?.(e);
    this.updateEnd(e.data.global);
    this.fromView.renderLine(true);
    view.on('pointermove', this.handlePointerMove);
    unlockEntryPointErrorTooltip();
  };

  public handlePointerUp = (e: any, view: LineView | LineStartView) => {
    if (IS_DEBUG && IS_PRODUCTION_BUILD) {
      // eslint-disable-next-line no-console
      console.log(
        `[connector-debug] mouse up - cardId#${
          this.cardId || null
        }, to view layoutId#${this.toView?.layoutId || null}`,
      );
    }

    view.off('pointermove');
    if (this.dragging) {
      this.stopLineDragging(e);
    }
  };

  public handlePointerMove = (e: any) => {
    const pos = e.data.global;
    this.updateEnd(pos, true);
    const {
      screen: { width, height },
    } = getPixiFieldStrict();
    const rightEdge = width - pos.x;
    const bottomEdge = height - pos.y;
    this.screenSpeed.x =
      pos.x < 100
        ? 0.1 * Math.min(100, Math.max(pos.x, 0)) - 10
        : rightEdge < 100
        ? 10 - 0.1 * Math.min(100, Math.max(rightEdge, 0))
        : 0;
    this.screenSpeed.y =
      pos.y < 100
        ? 0.1 * Math.min(100, Math.max(pos.y, 0)) - 10
        : bottomEdge < 100
        ? 10 - 0.1 * Math.min(100, Math.max(bottomEdge, 0))
        : 0;
  };

  private stopLineDragging = (e: any) => {
    e.stopPropagation();
    this._props.createHit = true;
    this.hideHover();
    activeLineObj = undefined;
    if (this.viewportPointUpdateInterval) {
      clearInterval(this.viewportPointUpdateInterval);
    }
    this.dragging = false;
    const position = e.data.global;
    const distance =
      this.dragStartPoint &&
      Math.sqrt(
        // eslint-disable-next-line no-restricted-properties
        Math.pow(position.x - this.dragStartPoint.x, 2) +
          // eslint-disable-next-line no-restricted-properties
          Math.pow(position.y - this.dragStartPoint.y, 2),
      );
    this.screenSpeed = { x: 0, y: 0 };
    if (this.onDragFinished) {
      if (distance && distance < 10) {
        const scale = getPixiFieldStrict().getScale();
        this.onDragFinished({
          x: position.x + 200 * scale,
          y: position.y - 50 * scale,
          clicked: true,
        });
      } else {
        this.onDragFinished({
          x: position.x,
          y: position.y,
          clicked: false,
        });
      }
    }
    this.fromView.renderLine(false);
  };
}
