import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { log } from 'cf-common/src/logger';
import { toaster, ServiceMessageType } from '@services/MessageService';
import { Node } from '../../../Node';
import { VLayout } from '../../../components/Elements/Layouts';
import { Image } from '../../../components/Elements/Shapes';
import { createLineMenu } from '../../Menu/createLineMenu';
import { pluginWidth } from '../../plugin_consts';
import storyMentionSvg from '../../../assets/entry_point/instagram_story_mention.svg';
import { validateIsInstagramPageConnected } from '../common/utils/validateIsInstagramPageConnected';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { instagramStoryMentionEntryPointFragment_config as InstagramStoryMentionEntryPointConfig } from '../../../../Plugins/InstagramStoryMentionEntryPoint/@types/instagramStoryMentionEntryPointFragment';
import { LineStartView } from '../../components/Line/LineStartView';
import { BlockView } from '../../block_view';

export class EntryPointInstagramStoryMention
  extends VLayout
  implements StatefulPluginDelegate<InstagramStoryMentionEntryPointConfig>
{
  TEST_NAME = 'EntryPointInstagramStoryMention';

  public readonly state: StatefulPlugin<InstagramStoryMentionEntryPointConfig>;
  private lineStartView: LineStartView;

  private _node: Node;

  constructor(
    state: StatefulPlugin<InstagramStoryMentionEntryPointConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const greyBox = new VLayout({
      width: pluginWidth,
      height: 188,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
      cursor: {
        in: 'default',
      },
    });

    const image = new Image({
      url: storyMentionSvg,
      cornerRadius: 8,
      width: 214,
      height: 154.5,
    });
    greyBox.addToLayout(image, { align: 'center', margin: { top: 17 } });

    const __typename = 'InstagramStoryMentionEntryPointConfig';
    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 20,
        mandatory: true,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: (blockView: BlockView) => {
          this.state.set({
            config: {
              __typename,
              block_id: blockView.id(),
            },
          });
        },
        onRemoved: () => {
          this.state.set({
            config: {
              __typename,
              block_id: null,
            },
          });
        },
      },
      this.state.data.id,
    );
    greyBox.addToLayout(this.lineStartView, {
      align: 'end',
      margin: { left: 3, top: -74 },
    });

    this.addToLayout(greyBox);
  }

  onBeforeRender() {
    const { block_id } = this.state.data.config;
    if (block_id) {
      this._node.addOutLink(block_id, this.lineStartView._lineView);
    }
  }

  pluginDidSet() {
    this.state.save();
    this.renderNode();
  }

  pluginDidSaveError(error: any) {
    const isConflictError = extractGQLErrorData(error)?.status === 409;
    if (isConflictError) return;

    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.InstagramStoryMention.failedToSaveEntryPointMessageText',
        ),
      },
    });
    log.error({
      error,
      msg: 'Error while saving Story Mention Entry Point',
      data: { label: 'flow_instagram_story' },
    });
  }

  validationError() {
    return (
      validateIsInstagramPageConnected(this._node) ||
      this.validateStoryMentionBlockConnect()
    );
  }

  private validateStoryMentionBlockConnect() {
    if (!this.state.data.config.block_id) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.InstagramStoryMention.blockConnectionValidationText',
        ),
        isBlockError: true,
      };
    }
    return undefined;
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
