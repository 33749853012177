import i18next from 'i18next';
import { BotTabs, getTabLink, globalHistory } from '@utils/Routing';
import { HEXColors } from '@ui/_common/colors';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { Node } from '../../../Node';
import { VLayout } from '../../../components/Elements/Layouts';
import entryPointInstagramDirectFaqSvg from '../../../assets/entry_point/faq.svg';
import { sendFlowBuilderEvent } from '../../../utils/Analytics';
import { ButtonView } from '../../button_view';
import { ButtonView as Button } from '../../kit/ButtonView';
import { Loader } from '../../loader';
import { createLineMenu } from '../../Menu/createLineMenu';
import { buttonHeight, pluginWidth } from '../../plugin_consts';
import { FAQView } from '../common/components/FAQ';
import { addValidationProps } from '../common/utils/validationProps';
import {
  infoSvgTexture,
  loaderWhiteSvgTexture,
} from '../../../assets/textures';
import { tooltipScaled } from '../../helpers/TooltipHelpers';
import { validateIsInstagramPageConnected } from '../common/utils/validateIsInstagramPageConnected';
import { ReplyToByKeywordsGroups } from '../common/components/ReplyToByKeywordGroups/ReplyToByKeywordsGroups';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import {
  Plugin,
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { instagramDirectEntryPointFragment_config as InstagramDirectEntryPointConfig } from '../../../../Plugins/InstagramDirectEntryPoint/@types/instagramDirectEntryPointFragment';
import { swapImmutable } from '../../utils';
import { Label } from '../../kit/Label';
import { Platform } from '@globals';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';

const INSTAGRAM_MAX_ICE_BREAKERS = 4;

export class EntryPointInstagramDirect
  extends VLayout
  implements StatefulPluginDelegate<InstagramDirectEntryPointConfig>
{
  TEST_NAME = 'EntryPointInstagramDirect';

  public readonly state: StatefulPlugin<InstagramDirectEntryPointConfig>;

  private _node: Node;

  private faqView: FAQView;
  private replyToView: ReplyToByKeywordsGroups;

  private syncWithMessengerLoading = false;
  private syncWithMessengerLoader: Loader;
  private syncWithMessengerButton: ButtonView;
  private changesSynced = true;

  constructor(
    state: StatefulPlugin<InstagramDirectEntryPointConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    const isShowKeywordsTab =
      !!getFlowControllerStrict().flow.user.features.show_keywords_tab;

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const faqTitleView = new Label({
      text: window.i18next.t(
        'InstagramDirect-string--119-frequently-asked-questions',
      ),
      tooltipText: `<img src="${entryPointInstagramDirectFaqSvg}" style="display:block;margin-bottom:12px;width:214px;height:141px;" />Add a short FAQ that the users will<br/>see before sending you a message.<br/>This will help you to easily address<br/>basic questions. The list of questions<br />is limited to ${INSTAGRAM_MAX_ICE_BREAKERS} items.`,
      tooltipSvgIcon: infoSvgTexture,
    });
    this.addToLayout(faqTitleView, { margin: { bottom: 8 } });

    this.faqView = new FAQView(
      this.state.data,
      () => ({
        ...this.state.data,
        config: {
          ice_breakers: this.state.data.config.ice_breakers,
        },
      }),
      node,
      {
        platform: Platform.instagram,
        maxIceBreakers: INSTAGRAM_MAX_ICE_BREAKERS,
        updateCardViaDeprecatedMethod: false,
        onIceBreakerAdd: (iceBreaker: any) => {
          sendFlowBuilderEvent({
            category: 'entry point',
            action: 'add faq option',
            label: 'instagram direct',
          });
          this.state.set(({ config }) => ({
            config: {
              ...config,
              ice_breakers: config.ice_breakers
                ? config.ice_breakers.concat(iceBreaker)
                : [iceBreaker],
            },
          }));
          this.changesSynced = false;
        },
        onIceBreakerRemove: (iceBreaker) => {
          sendFlowBuilderEvent({
            category: 'entry point',
            action: 'remove faq option',
            label: 'instagram direct',
          });
          this.state.set(({ config }) => ({
            config: {
              ...config,
              ice_breakers: (config.ice_breakers ?? []).filter(
                (v) => v.counter_id !== iceBreaker.counter_id,
              ),
            },
          }));
          this.changesSynced = false;
        },
        onIceBreakerMove: (_, fromIndex, toIndex) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              ice_breakers: swapImmutable(
                config.ice_breakers,
                fromIndex,
                toIndex,
              ),
            },
          }));
          this.changesSynced = false;
        },
        onIceBreakerTitleChange: (iceBreaker, _, title) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              ice_breakers: (config.ice_breakers ?? []).map((v) =>
                v.counter_id === iceBreaker.counter_id ? { ...v, title } : v,
              ),
            },
          }));
          this.changesSynced = false;
        },
        onIceBreakerConnect: (iceBreaker, _, blockView) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              ice_breakers: (config.ice_breakers ?? []).map((v) =>
                v.counter_id === iceBreaker.counter_id
                  ? { ...v, block_id: blockView?._node.id! }
                  : v,
              ),
            },
          }));
          this.changesSynced = false;
        },
        onIceBreakerDisconnect: (iceBreaker) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              ice_breakers: (config.ice_breakers ?? []).map((v) =>
                v.counter_id === iceBreaker.counter_id
                  ? { ...v, block_id: null }
                  : v,
              ),
            },
          }));
          this.changesSynced = false;
        },
        isEditing: () => this.state.isEditing,
      },
    );
    addValidationProps(this.faqView);
    this.addToLayout(this.faqView, { margin: { bottom: 16 } });

    this.syncWithMessengerButton = new ButtonView({
      title: window.i18next.t(
        'InstagramDirect-string-1105-sync-changes-to-instagram',
      ),
      width: pluginWidth,
      bgColor: HEXColors.blue,
      textColor: HEXColors.white,
      onClick: (event) => {
        sendFlowBuilderEvent({
          category: 'entry point',
          action: 'sync changes',
          label: PluginType.instagram_direct_entry_point,
        });
        event.stopPropagation();
        this.state.set((state) => ({
          ...state,
          refresh: true,
        }));
        this.syncWithMessengerLoading = true;
        this.syncWithMessengerLoader.start();
        this.renderNode();
      },
    });

    this.addToLayout(this.syncWithMessengerButton, {
      gone: () => {
        return (
          !this.state.data.enabled ||
          (this.changesSynced && this.state.data.synced) ||
          this.syncWithMessengerLoading
        );
      },
      margin: {
        bottom: 10,
      },
    });

    tooltipScaled({
      view: this.syncWithMessengerButton,
      text: 'Changes not yet live in Instagram;<br/>click to publish them.<br/>Note that you can’t sync changes<br/>more than ten times in ten minutes,<br/>per Instagram’s restrictions.',
    });

    this.syncWithMessengerLoader = new Loader(
      {
        width: pluginWidth,
        height: buttonHeight,
        background: {
          fill: HEXColors.fuchsia,
          cornerRadius: 4,
        },
      },
      loaderWhiteSvgTexture,
    );

    this.addToLayout(this.syncWithMessengerLoader, {
      gone: () => !this.syncWithMessengerLoading,
      margin: {
        bottom: 10,
      },
    });

    const replyToTitleView = new Label({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.keywordsLabel',
      ),
      tooltipText: `IG Direct Entry Point only reacts to<br/>user's very first message.<br/>AI tab processes all further<br/>messages, so make sure to set up<br/>keywords in AI tab. <div style="margin-bottom: 8px;"></div><a style="color: white;" rel="noreferrer noopener" href="${i18next.t(
        'common.helpDocLinks.set_up_keywords',
      )}">Learn more ↗</a><div style="margin-bottom: 8px;"></div><a style="color: white;" rel="noreferrer noopener" href="${getTabLink(
        BotTabs.keywords,
        node.controller.flow.botId,
        { platform: Platform.instagram },
      )}">${i18next.t(
        isShowKeywordsTab
          ? 'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.setUpAiTitle'
          : 'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.setUpAiTitleAi',
      )}</a>`,
      tooltipSvgIcon: infoSvgTexture,
      width: pluginWidth - 20,
      multiline: true,
    });
    this.addToLayout(replyToTitleView, { margin: { bottom: 8 } });

    this.replyToView = new ReplyToByKeywordsGroups(
      () => ({
        ...this.state.data,
        config: {
          keywords_setup: this.state.data.config.keywords_setup,
        },
      }),
      node,
      {
        defaultAnswerLabel: i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.keywordsDefaultAnswer',
        ),
        keywordsGroupLabel: i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.keywordsGroupLabel',
        ),
        targetPlugin: PluginType.instagram_direct_entry_point,
        isEditing: () => this.state.isEditing,
        statKey: `${this.state.data.id}_default_intent_users`,
        lineParams: {
          items: createLineMenu({
            isShowConnectToExistingBlock: true,
          }),
        },
        mandatoryDefaultBlock: true,
        onChange: (keywordsSetup) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              keywords_setup: keywordsSetup,
            },
          }));
        },
        showFilterTypeSelector: true,
      },
    );
    addValidationProps(this.replyToView);
    this.addToLayout(this.replyToView, { margin: { top: 0, bottom: 16 } });

    const setUpAiLabel = new Label({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.setUpAiLabel',
      ),
      multiline: true,
      width: pluginWidth,
    });
    this.addToLayout(setUpAiLabel, { margin: { bottom: 12 } });

    const setUpAIButton = new Button({
      title: i18next.t(
        isShowKeywordsTab
          ? 'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.setUpAiTitle'
          : 'modernComponents.FlowBuilder.views.entryPoints.InstagramDirect.setUpAiTitleAi',
      ),
      width: pluginWidth,
      onClick: () => {
        sendFlowBuilderEvent({
          category: 'entry point',
          action: 'click on set up ai button',
          label: PluginType.instagram_direct_entry_point,
        });
        globalHistory.push(
          getTabLink(BotTabs.keywords, node.controller.flow.botId, {
            platform: Platform.instagram,
          }),
        );
      },
    });
    this.addToLayout(setUpAIButton, { margin: { bottom: 15 } });
  }

  private validateKeywords() {
    if (!this.state.data.config.keywords_setup?.default_block_id) {
      return {
        message: window.i18next.t(
          'InstagramDirect-string-1668-all-other-messages-option-must-lead-to-block-connect-a-block-to-enable-it',
        ),
        isBlockError: true,
      };
    }
    if (
      this.state.data.config.keywords_setup?.intents?.some(
        (item) => !item.goto_block,
      )
    ) {
      return {
        message: window.i18next.t(
          'InstagramDirect-string-1215-keywords-options-must-lead-to-block-connect-a-block-to-enable-them',
        ),
        isBlockError: true,
      };
    }
    return this.replyToView.isValid()
      ? undefined
      : {
          message:
            "Keywords must be unique. You've<br/>already entered this keyword.",
          isBlockError: true,
        };
  }

  private isEmptyTitleFAQ() {
    return Boolean(
      this.state.data.config.ice_breakers?.some((item) => !item.title),
    );
  }

  onBeforeRender() {
    this.syncWithMessengerButton.setDisabled(
      this.isEmptyTitleFAQ() || Boolean(this.validateFAQ()),
    );
  }

  private validateFAQ() {
    if (this.state.data.config.ice_breakers?.some((item) => !item.block_id)) {
      return {
        message: window.i18next.t(
          'InstagramDirect-string-1518-faq-options-must-lead-to-blocks-connect-a-block-to-enable-them',
        ),
        isBlockError: true,
      };
    }
    return undefined;
  }

  validationError() {
    return (
      validateIsInstagramPageConnected(this._node) ||
      this.validateKeywords() ||
      this.validateFAQ() ||
      this.faqView.validateIceBreakersLimit()
    );
  }

  pluginDidSet(prevState: Plugin<InstagramDirectEntryPointConfig>) {
    if (this.state.data.enabled !== prevState.enabled) {
      // we've changed enabled status from block title
      // no need to save one more time
      return;
    }
    if (!this.state.data.refresh && prevState.refresh) {
      // we don't need to save refresh change after syncing icebreakers
      // just reset this field
      return;
    }

    this.state.save();
  }

  pluginDidSave() {
    this.renderNode();
    if (this.state.data.refresh) {
      this.changesSynced = true;
      this.syncWithMessengerLoading = false;
      this.syncWithMessengerLoader.stop();
      this.state.set((state) => ({
        ...state,
        refresh: false,
        synced: true,
      }));
      this.renderNode();
    }
  }

  pluginDidSaveError(error: any) {
    if (this.state.data.refresh) {
      this.syncWithMessengerLoading = false;
      this.syncWithMessengerLoader.stop();
      this.state.set((state) => ({
        ...state,
        refresh: false,
        synced: false,
      }));
      this.renderNode();
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: window.i18next.t(
            'InstagramDirect-string--203-couldnt-sync-changes-to-instagram-try-again-in-a-minute',
          ),
        },
      });

      log.error({
        error,
        msg: 'Error while sync icebreakers data with instagram',
        data: { label: 'flow_instagram_direct' },
      });
      return;
    }
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: window.i18next.t(
          'InstagramDirect-string--885-could-not-save-instagram-direct-entry-point-please-try-again-later',
        ),
      },
    });
    log.error({
      error,
      msg: 'Error while saving Instagram Direct Entry Point',
      data: { label: 'flow_instagram_direct' },
    });
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
