import {
  whatsappTemplatePluginFragment_config as WhatsappTemplatePluginConfig,
  whatsappTemplatePluginFragment_config_managedTemplateActions_buttonRedirects,
} from '@components/Plugins/WhatsappTemplatePlugin/@types/whatsappTemplatePluginFragment';
import { WhatsappTemplateParameterType } from '@globals';

export const updateBlockIdByIndex =
  (index: number, blockId: string | null, isManagedTemplate: boolean) =>
  (config: WhatsappTemplatePluginConfig) => {
    const updatedConfig = {
      ...config,
      replyButtons:
        config.replyButtons?.map((replyButton) => {
          return replyButton.index === index
            ? {
                ...replyButton,
                button: {
                  ...replyButton.button,
                  blockId,
                },
              }
            : replyButton;
        }) ?? null,
    };
    if (isManagedTemplate) {
      updatedConfig.managedTemplateActions = {
        __typename: 'ManagedWhatsappTemplateActions' as const,
        buttonRedirects:
          (updatedConfig.replyButtons
            ?.map(
              ({ button: { counterId, blockId } }) =>
                blockId && {
                  __typename: 'ManagedWhatsappTemplateButtonRedirect' as const,
                  counterId,
                  blockId: blockId || '',
                },
            )
            .filter(
              Boolean,
            ) as whatsappTemplatePluginFragment_config_managedTemplateActions_buttonRedirects[]) ||
          [],
      };
    }
    return updatedConfig;
  };

export const hasUnmappedBodyParameters = (
  bodyParameters: WhatsappTemplatePluginConfig['bodyParameters'],
) => {
  return bodyParameters?.some(({ text }) => !text);
};

export const hasUnmappedHeaderParameter = (
  headerParameter: WhatsappTemplatePluginConfig['headerParameter'],
) => {
  if (!headerParameter) {
    return false;
  }

  switch (headerParameter.type) {
    case WhatsappTemplateParameterType.video:
      return !headerParameter.video?.link && !headerParameter.video?.id;
    case WhatsappTemplateParameterType.image:
      return !headerParameter.image?.link && !headerParameter.image?.id;
    case WhatsappTemplateParameterType.document:
      return !headerParameter.document?.link && !headerParameter.document?.id;
    default:
      return !headerParameter.text;
  }
};

export const hasUnmappedUrlButtonParameter = (
  urlButtonParameter: WhatsappTemplatePluginConfig['urlButtonParameter'],
) => {
  return urlButtonParameter ? !urlButtonParameter.parameter.text : false;
};
