import React from 'react';
import { Modal } from '@services/index';
import {
  ZapierTemplatesDialogProps,
  ZapierZapsDialog,
} from './ZapierZapsDialog';
import * as css from './ZapierZapsDialog.css';

export const showZapierDialog = (props: ZapierTemplatesDialogProps) => {
  Modal.show(
    ({ close }) => (
      <ZapierZapsDialog
        {...props}
        onDismiss={() => {
          close();
        }}
      />
    ),
    {
      overlayClassName: css.modalOverlayZ,
    },
  )?.onClose(() => {
    props.onDismiss?.();
  });
};
