import React, { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { propEq } from 'ramda';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { NetworkStatus } from 'apollo-client';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Tooltip2 } from '@ui/Tooltip2';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ButtonUnstyled } from '@ui/Button';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { SomethingWentWrong } from './SomethingWentWrong';
import { ADS_ACCOUNT_QUERY } from '../../../queries';
import {
  AdsAccountQuery,
  AdsAccountQuery_facebookAdAccounts,
} from '../../../@types/AdsAccountQuery';
import { QueryTuple } from '@apollo/react-hooks';
import {
  useSaveAccountIdToServerStorage,
  UseSaveAccountIdToServerStorageProps,
} from '../hooks/useSaveAccountIdToServerStorage';
import { onFetchError } from '../ChooseAdsPopup';
import { NoAccountsView } from './NoAccountsView';

export interface AdAccountsDropdownChildrenProps {
  adsQuery: QueryTuple<AdsAccountQuery, {}>[1];
  selectedItem: AdsAccountQuery_facebookAdAccounts | undefined;
  savedAdAccountId: string | undefined;
  setSavedAdAccountId: (value: string) => void;
}

export interface AdAccountsDropdownProps
  extends Omit<UseSaveAccountIdToServerStorageProps, 'adsItems'> {
  pluginId: PluginType;
  selectedAdAccountId: string;
  isTooltipDisabled: boolean;
  isDropdownDisabled: boolean;
  tooltipContent: React.ReactNode;
  onChange: (item: AdsAccountQuery_facebookAdAccounts | null) => void;
  children: (props: AdAccountsDropdownChildrenProps) => React.ReactElement;
}

export const AdAccountsDropdown: React.FC<AdAccountsDropdownProps> = ({
  pageId,
  pluginId,
  selectedAdAccountId,
  setSelectedAdAccountId,
  accountId,
  isTooltipDisabled,
  isDropdownDisabled,
  tooltipContent,
  onChange,
  children,
}) => {
  const { t } = useSafeTranslation();
  const [fetchAds, adsQuery] = useLazyQuery<AdsAccountQuery>(
    ADS_ACCOUNT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onError: onFetchError(
        t('ChooseAdsPopup-string-1959-fetch-ad-accounts-error'),
      ),
    },
  );

  const adsItems = adsQuery.data?.facebookAdAccounts || [];
  const selectedItem = adsItems.find(propEq('id', selectedAdAccountId));

  const [savedAdAccountId, setSavedAdAccountId] =
    useSaveAccountIdToServerStorage({
      pageId,
      selectedAdAccountId,
      setSelectedAdAccountId,
      adsItems,
      accountId,
    });

  useEffect(fetchAds, [fetchAds]);

  if (adsQuery.error) {
    return (
      <SomethingWentWrong
        onRefresh={fetchAds}
        style={{ height: 500 }}
        pluginId={pluginId}
      />
    );
  }

  /**
   * By default we suppose that user has campaigns/adaccounts, also its true by default because
   * NoCampaignsView/NoAccountsView sends an event when it renders, so we should render it
   * only if we 100% sure that user doesn't have campaigns/adaccounts
   */
  const hasAdAccounts =
    adsQuery.networkStatus !== NetworkStatus.ready || !adsQuery.called
      ? true
      : Boolean(adsQuery.data?.facebookAdAccounts?.length);

  if (!hasAdAccounts) {
    return <NoAccountsView onRefresh={fetchAds} pluginId={pluginId} />;
  }

  return (
    <>
      <Tooltip2
        placement="right"
        hoverable
        type="small"
        content={tooltipContent}
        disabled={isTooltipDisabled}
      >
        {(ref, bind) => (
          <div style={{ marginBottom: 16 }} ref={ref} {...bind}>
            <SimpleCombobox
              menuboxStyle={{ width: 620, right: 0 }}
              scrollboxStyle={{ overflowX: 'hidden' }}
              items={adsItems}
              itemToString={(item) => (item ? item.name : '')}
              selectedItem={selectedItem || null}
              onChange={onChange}
              renderInput={({ getToggleButtonProps, getInputProps }) => {
                const { onClick } = getToggleButtonProps();

                return (
                  <Input
                    {...getInputProps({
                      placeholder: window.i18next.t(
                        'ChooseAdsPopup-string--144-choose-ads-account',
                      ),
                    })}
                    onClick={onClick}
                    renderIconEnd={() => (
                      <ButtonUnstyled onClick={onClick}>
                        <Icon icon="triangle" />
                      </ButtonUnstyled>
                    )}
                    disabled={!adsItems.length || isDropdownDisabled}
                  />
                );
              }}
            />
          </div>
        )}
      </Tooltip2>

      {children({
        adsQuery,
        selectedItem,
        savedAdAccountId,
        setSavedAdAccountId,
      })}
    </>
  );
};
