import { getIntegrationZapierAccountIdObservable } from '@utils/Integrations/Zapier/getIntegrationZapierAccountStateObservable';
import { Subscription } from 'rxjs';
import { MainLayout } from '../../../../components/Elements/Layouts';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../../PixiFieldRepository';
import { StaticCommentCard } from '../../../kit/StaticCommentCard';

export class ZapierCommentCardView extends MainLayout {
  private readonly commentView: StaticCommentCard;
  private zapierAccountId: string | undefined;
  private zapierAccountSubscription: Subscription | undefined;

  constructor() {
    super();
    const { botId, user } = getFlowControllerStrict().flow;

    if (!getPixiFieldStrict().isViewOnly()) {
      this.zapierAccountSubscription = getIntegrationZapierAccountIdObservable(
        botId,
        user.id,
      ).subscribe((id) => {
        this.zapierAccountId = id;
        this.renderNode();
      });
    }

    this.commentView = new StaticCommentCard();
    this.addToLayout(this.commentView, {
      margin: { top: 8 },
      gone: () => !this.zapierAccountId,
    });
  }

  public updateText(text: string) {
    this.commentView.updateText(text);
  }

  destroy() {
    super.destroy();
    this.zapierAccountSubscription?.unsubscribe();
  }
}
