import { HEXColors } from '@ui/_common/colors';
import { QUANT_ITEMS_WITH_IMMEDIATELY } from '@utils/DateTime/quant';
import i18next from 'i18next';
import { ShopifyEventType } from '@globals';
import { MainLayout } from '../../../../../../components/Elements/Layouts';
import { Node } from '../../../../../../Node';
import { PluginType, PropFunc } from '../../../../../../types';
import { logFlowPluginEvent } from '../../../../../../utils/Analytics';
import { StatFieldsIds } from '../../../../../components/BlockStatsView';
import { getFullCounterStats } from '../../../../../components/BlockStatsView/utils';
import { LineStartView } from '../../../../../components/Line/LineStartView';
import { DelayInput } from '../../../../../kit/DelayInput';
import { ItemStatView } from '../../../../../kit/ItemStatView/ItemStatView';
import { createLineMenu } from '../../../../../Menu/createLineMenu';
import { pluginWidth } from '../../../../../plugin_consts';
import { resByFunc } from '../../../../../utils';

interface BaseDelayConfig {
  next_block_id: string | null;
  counter_id: string | null;
  delay_in_minutes: number | null;
  delay_in_seconds: number | null;
}

interface LineMenuProps {
  isShowReminders?: boolean;
  isShowReceipt?: boolean;
  isShowProductVisitReminders?: boolean;
  isShowPostPurchase?: boolean;
}

interface ShopifyEventDelayViewProps<T> {
  config: T;
  onChange: (value: T) => void;
  isEditing: PropFunc<boolean>;
  node: Node;
  lineMenuProps: LineMenuProps;
  min: number;
  eventType: ShopifyEventType;
  title: string;
  titleImmediately: string;
}

const SECONDS_MULTIPLIER = 1000;
const MINUTES_MULTIPLIER = 60 * SECONDS_MULTIPLIER;

export class ShopifyEventDelayView<
  T extends Partial<BaseDelayConfig>,
> extends MainLayout {
  private lineStartView: LineStartView;
  private config: T;
  private _node: Node;
  public eventType: ShopifyEventType;

  constructor({
    onChange,
    config,
    isEditing,
    node,
    lineMenuProps,
    min,
    eventType,
    titleImmediately,
    title,
  }: ShopifyEventDelayViewProps<T>) {
    super({
      height: 36,
      width: pluginWidth - 40,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    this.config = config;
    this._node = node;
    this.eventType = eventType;
    const isUseSeconds = typeof config.delay_in_seconds === 'number';

    this.addToLayout(
      new DelayInput({
        quantItems: QUANT_ITEMS_WITH_IMMEDIATELY(),
        allowFraction: false,
        max: Math.round(23 * 60 * MINUTES_MULTIPLIER),
        min: Math.round(
          min * (isUseSeconds ? SECONDS_MULTIPLIER : MINUTES_MULTIPLIER),
        ),
        initialValue:
          (config.delay_in_seconds || 0) * SECONDS_MULTIPLIER ||
          (config.delay_in_minutes || 0) * MINUTES_MULTIPLIER,
        label: i18next.t('shopify.remindIn'),
        getPlaceholderTitle: (_, quantId) =>
          quantId === DelayInput.QUANT_IDS.instantly ? titleImmediately : title,
        onChange: (value) => {
          this.config = {
            ...this.config,
            delay_in_minutes: isUseSeconds
              ? null
              : Math.round(value / MINUTES_MULTIPLIER),
            delay_in_seconds: isUseSeconds
              ? Math.round(value / SECONDS_MULTIPLIER)
              : null,
          };
          onChange(this.config);
          logFlowPluginEvent(
            PluginType.shopify_event_entry_point,
            `${eventType} delay change`,
            {
              delayInMinutes: isUseSeconds
                ? undefined
                : Math.round(value / MINUTES_MULTIPLIER),
              delayInSeconds: isUseSeconds
                ? Math.round(value / SECONDS_MULTIPLIER)
                : undefined,
              shopifyEventType: eventType,
            },
          );
        },
        editable: () => resByFunc(isEditing),
      }),
      {
        margin: {
          left: 0,
        },
      },
    );

    this.lineStartView = new LineStartView({
      node,
      from: this,
      offset: 40,
      mandatory: true,
      onConnected: (blockView) => {
        this.config = {
          ...this.config,
          next_block_id: blockView.id(),
        };
        onChange(this.config);
        this.renderNode();
      },
      onRemoved: () => {
        this.config = {
          ...this.config,
          next_block_id: null,
        };
        onChange(this.config);
        this.renderNode();
      },
      items: createLineMenu(lineMenuProps),
    });

    this.addToLayout(this.lineStartView, {
      align: 'end',
      verticalAlign: 'center',
      margin: {
        left: 28,
      },
    });

    const stats = getFullCounterStats(
      this.config.counter_id || '',
      node.block.id,
    );

    if (stats) {
      this.addToLayout(
        new ItemStatView({
          stats,
          showPercentValue: true,
          fieldId: StatFieldsIds.sent,
        }),
        {
          gone: () => resByFunc(isEditing),
          verticalAlign: 'center',
          align: 'end',
        },
      );
    }
  }

  onBeforeRender() {
    if (this.config.next_block_id) {
      this._node.addOutLink(
        this.config.next_block_id,
        this.lineStartView._lineView,
      );
    }
  }
}
