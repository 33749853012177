import { ProductCardViewConfig } from '../../components/ProductCardView/ProductCardView';
import imageSrc from './images/prepend_entry_point_product.svg';

export const PREPEND_ENTRY_POINT_PRODUCT_ID = 'entry_point_product';

export const PREPEND_ENTRY_POINT_PRODUCT: ProductCardViewConfig = {
  id: PREPEND_ENTRY_POINT_PRODUCT_ID,
  title:
    '<div style="white-space:normal;">The product that the user came from in Messenger</div>',
  description: null,
  image_url: imageSrc,
};
