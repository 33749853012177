import { PermissionGroup } from '@utils/Permissions';
import { FacebookAdsEntryPointConfig } from '@components/Plugins/FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import { Platform } from '@globals';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { LayoutProps, VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import {
  instagramAdsEntryPointFragment,
  instagramAdsEntryPointFragment_config as InstagramAdsEntryPointConfig,
} from '../../../../Plugins/InstagramAdsEntryPoint/@types/instagramAdsEntryPointFragment';
import { ButtonView } from '../../button_view';
import { validateIsGivenPermissions } from '../common/utils/validateIsGivenPermission';
import { pluginWidth } from '../../plugin_consts';
import { AdsSelector } from '../common/components/AdsSelector';
import { GreetingAdsTypeSelector } from '../common/components/GreetingAdsTypeSelector';
import { validateIsInstagramPageConnected } from '../common/utils/validateIsInstagramPageConnected';
import { HEXColors } from '@ui/_common/colors';

export interface EntryPointInstagramAdsCard
  extends instagramAdsEntryPointFragment {
  isEditing?: boolean;
}

export class EntryPointInstagramAds
  extends VLayout
  implements StatefulPluginDelegate<InstagramAdsEntryPointConfig>
{
  private _node: Node;
  public state: StatefulPlugin<
    InstagramAdsEntryPointConfig | FacebookAdsEntryPointConfig
  >;
  adsSelector: AdsSelector;
  typeSelector: GreetingAdsTypeSelector;
  syncResult: any;

  constructor(
    state: StatefulPlugin<
      InstagramAdsEntryPointConfig | FacebookAdsEntryPointConfig
    >,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.syncResult = {};
    this.adsSelector = new AdsSelector(state, this._node, Platform.instagram);
    this.addToLayout(this.adsSelector);

    this.typeSelector = new GreetingAdsTypeSelector(
      state,
      this._node,
      Platform.instagram,
    );
    this.addToLayout(this.typeSelector);
  }

  // TODO: remove it after fix typescript error with stateful
  pluginDidSet() {}

  addToAdsSelectorLayout(...args: [ButtonView, LayoutProps]) {
    this.adsSelector.addToLayout(...args);
  }

  hasSignificantChangesInConfig() {
    return this.state.data.config.campaigns.length !== 0;
  }

  validationError() {
    const entryPointError = this.state.data.validation_details?.fields?.find(
      ({ field }: { field: string }) => !field,
    );

    const logGrandPermissionClick = () => {
      logFlowPluginEvent(
        this.state.data.plugin_id,
        'Grant Permission tooltip click',
        {
          blockId: this._node.id,
          cardId: this.state.data?.id,
        },
      );
    };

    return (
      validateIsInstagramPageConnected(this._node) ||
      validateIsGivenPermissions(
        PermissionGroup.ads,
        window.i18next.t(
          'EntryPointInstagramAds-string--112-grant-chatfuel-permission-to-manage-your-instagram-ads',
        ),
        logGrandPermissionClick,
      ) ||
      (entryPointError
        ? {
            message: entryPointError.error ?? undefined,
            isBlockError: true,
          }
        : undefined)
    );
  }

  onDestroy() {
    this.state.destroy();
    super.destroy();
  }
}
