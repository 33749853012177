import React, { useState } from 'react';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { Modal } from '@services/index';
import { DefaultDialog } from '@ui/Dialog';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Divider } from '@ui/Menu';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ChoosenPlaceView } from './ChoosenPlaceView';
import { LocationPickerPlace } from './types';
import { logFlowPluginEvent } from '@components/FlowBuilder/utils/Analytics';
import { PluginType } from '@components/FlowBuilder/types';
import { GoogleMapsPlacePicker } from './GoogleMapsPlacePicker';
import { PlacePickerMock } from './PlacePickerMock';
import * as css from './LocationPickerDialog.css';

interface LocationPickerDialogProps {
  location?: LocationPickerPlace;
  onSubmit(location: LocationPickerPlace): void;
  onDismiss(): void;
}

export const LocationPickerDialog: React.FC<LocationPickerDialogProps> = ({
  location,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  const [isGoogleMapsMocked] = useLocalStorage(LSKey.mockGoogleMaps);
  const [place, setPlace] = useState<LocationPickerPlace | null>(
    location || null,
  );

  const setPlaceName = (name: string) => setPlace({ ...place!, name });

  return (
    <DefaultDialog
      small
      header={
        <Type as="h1" weight="extrabold" size="18px">
          {t(
            'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.headerTitle',
          )}
        </Type>
      }
      onDismiss={onDismiss}
      className={css.dialog}
    >
      {isGoogleMapsMocked ? (
        <PlacePickerMock place={place} setPlace={setPlace} />
      ) : (
        <GoogleMapsPlacePicker location={location} setPlace={setPlace} />
      )}

      <Spacer factor={4} />

      <Divider />

      <Spacer factor={4} />

      <Flex justifyContent="space-between" alignItems="center">
        {place ? (
          <ChoosenPlaceView place={place} setPlaceName={setPlaceName} />
        ) : (
          <div />
        )}

        <Button
          data-testid="location-picker-dialog__submit"
          disabled={!place}
          onClick={() => {
            logFlowPluginEvent(
              PluginType.whatsapp_location,
              'confirm location click',
              { place },
            );

            onSubmit(place!);
          }}
        >
          {t(
            'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.submitButton',
          )}
        </Button>
      </Flex>
    </DefaultDialog>
  );
};

export const showLocationPickerDialog = (location?: LocationPickerPlace) =>
  Modal.show<LocationPickerPlace>(({ resolve, close }) => (
    <LocationPickerDialog
      location={location}
      onSubmit={resolve}
      onDismiss={close}
    />
  ));
