import { TextPluginView } from '../text_plugin';
import { GalleryPluginView } from '../gallery_view';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { View } from '../../components/Elements/Layouts';

export const setHidePaymentButtonProps = (
  view: View | TextPluginView | GalleryPluginView,
) => {
  if (view instanceof TextPluginView || view instanceof GalleryPluginView) {
    // eslint-disable-next-line no-param-reassign
    view.props.hidePaymentOption = view._card?.plugin_id !== PluginType.gallery;
  }
};
