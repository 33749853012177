import { HEXColors } from '@ui/_common/colors';
import nanoid from 'nanoid';
import {
  MainLayout,
  VLayout,
} from '../../../../../components/Elements/Layouts';
import { HTMLText } from '../../../../../components/Elements/Shapes';
import { Node } from '../../../../../Node';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import { BlockView } from '../../../../block_view';
import { getFullCounterStats } from '../../../../components/BlockStatsView/utils';
import { LineStartView } from '../../../../components/Line/LineStartView';
import { buttonControl } from '../../../../helpers/ControlsHelpers';
import {
  ItemStatView,
  STAT_VIEW_WIDTH,
} from '../../../../kit/ItemStatView/ItemStatView';
import { pluginWidth } from '../../../../plugin_consts';
import { PlusButtonView } from '../../../../plus_button_view';
import { swapImmutable } from '../../../../utils';
import { KeywordGroup } from './KeywordGroup';
import {
  KeywordGroupConfig,
  KeywordGroupsData,
  ReplyToKeywordGroupsProps,
} from './types';
import { AiIntentFilterType, OnboardingTourShape } from '@globals';

export class ReplyToByKeywordsGroups extends VLayout {
  TEST_NAME = 'ReplyTo';

  private _node: Node;

  private getData: () => KeywordGroupsData;

  private props: ReplyToKeywordGroupsProps;

  readonly lineStartView: LineStartView;
  readonly keywordGroupsBox: VLayout;

  private isCardEditing(): boolean {
    return Boolean(this.getData().isEditing ?? this.props.isEditing?.());
  }

  constructor(
    // getData: () => KeywordGroupsData,
    getData: () => any, // TODO
    node: Node,
    props: ReplyToKeywordGroupsProps,
  ) {
    super({});

    this._node = node;
    this.props = props;
    this.getData = getData;

    const box = new VLayout({
      width: pluginWidth,
    });

    const greyBox = new VLayout({
      name: OnboardingTourShape.CommentsWithKeywordsContainer,
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
      cursor: {
        in: 'default',
      },
    });

    const otherCommentsVariantBox = new MainLayout({
      width: pluginWidth,
      height: 50,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
      cursor: {
        in: 'default',
      },
    });

    const addKeywordGroupButton = new PlusButtonView(
      window.i18next.t(
        'ReplyToByKeywordsGroups-string-6697-＋-add-keyword-group',
      ),
      pluginWidth - 32,
    );

    addKeywordGroupButton.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });
    addKeywordGroupButton.on('click', (event: Event) => {
      if (this.getData().config.keywords_setup?.intents?.length) {
        event.stopPropagation();
      }
      const newKeywordGroupConfig: KeywordGroupConfig = {
        __typename: 'AiIntent',
        id: nanoid(),
        intent_id: nanoid(),
        lines: [],
        goto_block: null,
        filter_type: AiIntentFilterType.default,
      };

      const keywordsSetup = this.getData().config.keywords_setup;
      if (keywordsSetup?.intents) {
        props.onChange({
          ...keywordsSetup,
          intents: keywordsSetup.intents.concat(newKeywordGroupConfig),
        });
      }

      const createdKeywordGroup = this.createKeywordGroup(
        newKeywordGroupConfig.id,
      );
      this.lineStartView.renderLine();
      logFlowPluginEvent(props.targetPlugin, 'Add keyword group click', {
        blockId: node.id,
        cardId: this.getData().id,
      });
      this.renderNode();
      createdKeywordGroup?.startEditing();
    });

    greyBox.addToLayout(
      new HTMLText({
        text: props.keywordsGroupLabel,
        fontSize: 15,
        width: pluginWidth - 20,
      }),
      {
        margin: { left: 16, top: 16, bottom: 8 },
      },
    );

    this.keywordGroupsBox = new VLayout({});

    greyBox.addToLayout(this.keywordGroupsBox, {
      gone: () => !this.getData().config.keywords_setup?.intents?.length,
      margin: { left: 16 },
    });

    greyBox.addToLayout(addKeywordGroupButton, {
      margin: { left: 16, bottom: 16 },
    });

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: () =>
          this.props.mandatoryDefaultBlock ||
          !this.getData().config.keywords_setup?.intents?.length,
        onConnected: (blockView: BlockView) => {
          const keywordsSetup = this.getData().config.keywords_setup;
          if (keywordsSetup) {
            props.onChange({
              ...keywordsSetup,
              default_block_id: blockView.id(),
            });
          }
          this.renderNode();
        },
        onRemoved: () => {
          const keywordsSetup = this.getData().config.keywords_setup;
          if (keywordsSetup) {
            props.onChange({
              ...keywordsSetup,
              default_block_id: null,
            });
          }
          this.renderNode();
        },
        items: props.lineParams?.items,
        searchable: props.lineParams?.searchable,
        defaultTargetBlockData: props.lineParams?.defaultTargetBlockData,
        menuStyle: props.lineParams?.menuStyle,
        subtype: props.lineParams?.subtype,
      },
      this.getData().id,
    );

    otherCommentsVariantBox.addToLayout(
      new HTMLText({
        text: props.defaultAnswerLabel,
        fontSize: 15,
        width: pluginWidth - 40,
      }),
      {
        margin: { left: 16 },
        verticalAlign: 'center',
      },
    );

    const stats = getFullCounterStats(props.statKey ?? '', node.block.id);

    if (stats) {
      otherCommentsVariantBox.addToLayout(
        new ItemStatView({
          stats,
          showPercentValue: true,
        }),
        {
          margin: {
            top: 0,
            left: pluginWidth - 24 - STAT_VIEW_WIDTH,
          },
          verticalAlign: 'center',
        },
      );
    }

    otherCommentsVariantBox.addToLayout(this.lineStartView, {
      margin: { left: pluginWidth - 7 },
      verticalAlign: 'center',
    });

    box.addToLayout(otherCommentsVariantBox);

    box.addToLayout(greyBox, {
      margin: { top: 8 },
    });

    this.addToLayout(box);

    this.createKeywordGroupsBoxItems();
  }

  onBeforeRender() {
    if (this.getData().config.keywords_setup?.default_block_id) {
      this._node.addOutLink(
        this.getData().config.keywords_setup?.default_block_id ?? undefined,
        this.lineStartView._lineView,
      );
    }
  }

  createKeywordGroupsBoxItems() {
    this.getData().config.keywords_setup?.intents?.forEach(
      // keyword groups don't have id in share flow mode, just intent_id
      // cause we write id for them only in api-gateway
      ({ id, intent_id }) => {
        this.createKeywordGroup(id ?? intent_id);
      },
    );
  }

  createKeywordGroup(id: string) {
    const getIndex = () =>
      this.getData().config.keywords_setup?.intents?.findIndex((item) =>
        item.id ? item.id === id : item.intent_id === id,
      );
    const getData = () => {
      const index = getIndex();
      return typeof index === 'number'
        ? this.getData().config.keywords_setup?.intents?.[index]
        : undefined;
    };
    const currentIntentData = getData();
    if (!currentIntentData) {
      return undefined;
    }
    const index = getIndex();
    const newKeywordGroup = new KeywordGroup(getData, this._node, {
      bubbleInputName:
        typeof index === 'number'
          ? `${OnboardingTourShape.CommentsAutoreplyKeywordsGroup}${index}`
          : undefined,
      onChangeLines: (lines) => {
        const currentIntentData = getData();
        if (currentIntentData) {
          const keywordsSetup = this.getData().config.keywords_setup;
          if (keywordsSetup?.intents) {
            this.props.onChange({
              ...keywordsSetup,
              intents: keywordsSetup.intents.map((item) =>
                item.id === currentIntentData.id
                  ? { ...currentIntentData, lines }
                  : item,
              ),
            });
          }
        }
      },
      onChangeBlock: (blockId) => {
        const currentIntentData = getData();
        if (currentIntentData) {
          const keywordsSetup = this.getData().config.keywords_setup;
          if (keywordsSetup?.intents) {
            this.props.onChange({
              ...keywordsSetup,
              intents: keywordsSetup.intents.map((item) =>
                item.id === currentIntentData.id
                  ? {
                      ...currentIntentData,
                      goto_block: blockId ? [blockId] : null,
                    }
                  : item,
              ),
            });
          }
        }
      },
      onChangeFilterType: (filterType) => {
        const currentIntentData = getData();
        if (currentIntentData) {
          const keywordsSetup = this.getData().config.keywords_setup;
          if (keywordsSetup?.intents) {
            this.props.onChange({
              ...keywordsSetup,
              intents: keywordsSetup.intents.map((item) =>
                item.id === currentIntentData.id
                  ? {
                      ...currentIntentData,
                      filter_type: filterType,
                    }
                  : item,
              ),
            });
          }
        }
      },
      editable: () => this.isCardEditing(),
      otherLines: () => {
        const lines = this.getData()
          .config.keywords_setup?.intents?.filter((item) =>
            item.id ? item.id !== id : item.intent_id !== id,
          )
          .map(({ lines }) => lines);
        return (lines ?? []).flat(1);
      },
      lineParams: this.props.lineParams,
      showFilterTypeSelector: this.props.showFilterTypeSelector,
      targetPlugin: this.props.targetPlugin,
    });
    this.addButtonControl(newKeywordGroup, currentIntentData);
    this.keywordGroupsBox.addToLayout(newKeywordGroup, {
      margin: { bottom: 8 },
    });
    return newKeywordGroup;
  }

  addButtonControl(
    keywordGroupView: KeywordGroup,
    keywordGroupConfig: KeywordGroupConfig,
  ) {
    buttonControl(
      keywordGroupView,
      this.keywordGroupsBox,
      () => {
        this._node.updateLines();
      },
      () => {
        const keywordsSetup = this.getData().config.keywords_setup;
        if (keywordsSetup?.intents) {
          this.props.onChange({
            ...keywordsSetup,
            intents: keywordsSetup.intents.filter(
              (item) => item.id !== keywordGroupConfig.id,
            ),
          });
        }
        this.keywordGroupsBox.removeView(keywordGroupView);
        keywordGroupView.destroy();
        this._node.updateLines();
        this.renderNode();
        logFlowPluginEvent(this.props.targetPlugin, 'remove keyword group', {
          blockId: this._node.id,
          cardId: this.getData().id,
        });
      },
      (_: any, startIdx: number, idx: number) => {
        const keywordsSetup = this.getData().config.keywords_setup;
        if (keywordsSetup) {
          this.props.onChange({
            ...keywordsSetup,
            intents: swapImmutable(keywordsSetup.intents, startIdx, idx),
          });
        }

        logFlowPluginEvent(this.props.targetPlugin, 'drag keyword group', {
          start: startIdx,
          end: idx,
          blockId: this._node.id,
          cardId: this.getData().id,
        });
        this.renderNode();
      },
    );
  }

  isValid() {
    return !this.keywordGroupsBox
      .views()
      .some((view) => (view as KeywordGroup).getCurrentErrorLines?.().length);
  }
}
