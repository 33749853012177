import { mergeRight } from 'ramda';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { SendToMessengerColor, SendToMessengerSize } from '@globals';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { shopifyBackInStockEntryPointFragment_config as ShopifyBackInStockEntryPointConfig } from '../../../../Plugins/ShopifyBackInStockEntryPoint/@types/shopifyBackInStockEntryPointFragment';
import { BLOCK_SUBTYPES } from '../../../consts';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { getEntryPointCard } from '../common/utils';

interface DefaultConfig {
  __typename: 'ShopifyBackInStockEntryPointConfig';
  title: string;
  description: string;
  button_text: string;
  button_size: SendToMessengerSize;
  button_color: SendToMessengerColor;
  on_subscribe_block_id: null;
  on_back_in_stock_block_id: null;
  on_back_in_stock_counter_id: null;
  notify_unreachable: boolean;
  on_back_in_stock_unreachable_block_id: null;
  on_back_in_stock_unreachable_counter_id: null;
}

export const withDefaultConfig = (
  config: ShopifyBackInStockEntryPointConfig,
): DefaultConfig =>
  mergeRight(
    {
      ...PLUGIN_DEFAULT_CONFIG,
      title: '',
      description: '',
    },
    config,
  ) as any;

export const isFlowHasOptInBlock = () => {
  const { _nodesMap } = getFlowControllerStrict();

  return Object.values(_nodesMap).some(
    ({ block }) =>
      block.subtype === BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
  );
};

export const isFlowHasBackInStock = () => {
  const { _nodesMap } = getFlowControllerStrict();

  return Object.values(_nodesMap).some(
    ({ block }) =>
      getEntryPointCard(block.cards)?.plugin_id ===
      PluginType.shopify_back_in_stock,
  );
};
