import i18next from 'i18next';
import { logFlowPluginEvent } from '../utils/Analytics';
import { MainLayout } from '../components/Elements/Layouts';
import { HTMLText, Image } from '../components/Elements/Shapes';
import { cropImage } from '../components';
import { isEmptyString, notEmptyString } from './validation';
import { Loader } from './loader';
import { pluginWidth } from './plugin_consts';
import { getFlowPlatform } from '../utils/getFlowPlatform';
import { Card } from '../types';
import { Node } from '../Node';
import { HEXColors } from '@ui/_common/colors';
import {
  createHTMLMediaTooltipByPlatform,
  MediaTypes,
} from './utils/createHTMLMediaTooltip';
import { UploadFileResult } from '@utils/UploadService/UploadService';
import { ATTACHMENT_PLUGIN_SIZE } from './components/FileAttachmentView/const';
import { uploadFileService } from '@utils/UploadService/uploadFileService';
import { uploadFile } from '../api/network';
import { getSupportedImageTypes } from '@utils/PlatformSupportedFiles/getSupportedTypes';
import { getImageFileSizeLimit } from '@utils/PlatformSupportedFiles/getFileSizeLimit';
import { FileAttachmentType } from '@utils/UploadService/types';

export interface ImagePluginConfig {
  url: string;
  filename: string;
}
const IMG_HEIGHT = 200;

export class ImagePluginView extends MainLayout {
  TEST_NAME = 'ImagePluginView';

  private readonly _card: Card<ImagePluginConfig>;
  private readonly _node: Node;
  private readonly _img: Image;
  private readonly _loader: Loader;
  private readonly _media: HTMLText;
  private _uploadedAndValid = true;
  private _loading = false;

  constructor(card: Card<ImagePluginConfig>, node: Node) {
    super({
      x: 20,
      y: 0,
      width: pluginWidth,
      // @ts-expect-error
      height: (ins) =>
        (card.config.url && card.is_valid) || ins._loading
          ? IMG_HEIGHT
          : ATTACHMENT_PLUGIN_SIZE.height,
      clipOutside: true,
      background: {
        fill: HEXColors.baseExtraLight,
        cornerRadius: 4,
      },
      cursor: () =>
        card.isEditing ? { in: 'pointer', out: 'grab' } : undefined,
    });
    this._node = node;
    this._card = card;

    const mainViewProps = {
      margin: { left: 2, top: 2 },
    };

    this._img = new Image({
      url: card.config.url,
      cornerRadius: 4,
      width: pluginWidth - 2,
      height: IMG_HEIGHT - 2,
      onReady: () => {
        this._loading = false;
        this._loader.stop();
        this.renderNode();
      },
    });
    this.layout(this._img, {
      ...mainViewProps,
      gone: () => !card.config.url,
    });

    this._loader = new Loader({ height: IMG_HEIGHT - 2, cornerRadius: 4 });
    this.layout(this._loader, {
      ...mainViewProps,
      gone: () => !this._loading,
    });

    this._media = new HTMLText({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.ImagePlugin.addMedia',
        {
          size: getImageFileSizeLimit(getFlowPlatform()!),
        },
      ),
      ...ATTACHMENT_PLUGIN_SIZE,
      align: 'center',
      verticalAlign: 'center',
      fontSize: 15,
      trustedHtml: true,
    });

    this.layout(this._media, {
      ...mainViewProps,
      gone: () => this._loading || Boolean(card.config.url && card.is_valid),
    });

    this.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.on('click', (e) => {
      e.stopPropagation();
      logFlowPluginEvent('image', 'update image', {
        blockId: node.id,
        cardId: card.id,
      });
      this.chooseImage();
    });
  }

  private startLoading = () => {
    this._loading = true;
    this._loader.start();
    this.height(IMG_HEIGHT);
    this.renderNode();
  };

  private chooseImage() {
    uploadFileService
      .chooseFile({
        beforeUpload: this.startLoading,
        accept: getSupportedImageTypes(getFlowPlatform()!),
        dataTestId: 'image__upload-file',
        platform: getFlowPlatform()!,
        filetype: FileAttachmentType.image,
      })
      .then(this.onSuccessfulUpload)
      .catch(this.onFailedUpload);
  }

  private onSuccessfulUpload = ({ url, filename }: UploadFileResult) => {
    this._card.config.url = url;
    this._card.config.filename = filename;
    this._node.updateCard(this._card, (_, rawData) => {
      const isUrlError = rawData.fields?.find(
        (error: any) => error.field === 'url',
      );
      if (isUrlError) {
        this.onFailedUpload();
      }
    });
    this._card.is_valid = true;
    this._uploadedAndValid = true;
    this.renderNode();
  };

  private onFailedUpload = () => {
    this._loader.stop();
    this._uploadedAndValid = false;
    this._card.is_valid = false;
    this._card.config.url = '';
    this._card.config.filename = '';
    this._loading = false;
    this.renderNode();
  };

  public onBeforeRender() {
    this._img.url(this._card.config.url);
  }

  public crop() {
    cropImage({
      image: this._card.config.url,
      onDismiss: () => null,
      onSave: (blob) => {
        const formData = new FormData();
        const file = blob;
        // @ts-expect-error не понятно с чего бы это блобу иметь поле name
        formData.append('file', file, file.name);
        this._loading = true;
        this._uploadedAndValid = true;
        this.renderNode();
        this._loader.start();
        uploadFile(
          formData,
          (url) => {
            this.onSuccessfulUpload({
              url,
              filename: this._card.config.filename,
            });
          },
          this.onFailedUpload,
          {
            filename: this._card.config.filename,
            platform: getFlowPlatform()!,
            filetype: FileAttachmentType.image,
          },
        );
      },
    });
  }

  public hasSignificantChangesInConfig() {
    return notEmptyString(this._card.config.url);
  }

  public validationError() {
    if (this._loading) {
      return false;
    }
    if (!this._uploadedAndValid || !this._card.is_valid) {
      return createHTMLMediaTooltipByPlatform(
        getFlowPlatform()!,
        MediaTypes.image,
      );
    }
    return isEmptyString(this._card.config.url);
  }
}
