import 'cropperjs/dist/cropper.css';
import { EntryPointPopup } from '../../EntryPointPopup';
import { EntryPointShopifyCustomerChat } from '../../EntryPointShopifyCustomerChat';
import { EntryPointShopifyEvent } from '../../EntryPointShopifyEvent/EntryPointShopifyEvent';
import { ExternalIntegrationEntryPoint } from '../../ExternalIntegrationEntryPoint';
import { getEntryPointCardView } from '../utils';
import { EntryPointFacebookShops } from '../../EntryPointFacebookShops';
import { Toggle } from '../../../kit/toggle';
import {
  unsubscribeTooltipFromView,
  tooltipScaled,
} from '../../../helpers/TooltipHelpers';
import { ALERT_VIEW_SIZE, ALERT_VIEW_MARGIN } from '../../../constants';
import { MainLayout } from '../../../../components/Elements/Layouts';
import { Point, TextureShape } from '../../../../components/Elements/Shapes';
import { ValidationError, EntryPointView } from '../../../../types';
import { alertSvgTexture } from '../../../../assets/textures';
import { AudioPluginView } from '../../../plugins/AudioPlugin/AudioPlugin';
import { GoToBlockPluginView } from '../../../plugins/GoToBlock';
import { PluginTitleLayout } from '../../../components/PluginTitleLayout';
import { FacebookAnalyticsView } from '../../../facebook_analytics';
import { OneTimeNotificationPluginView } from '../../../plugins/OneTimeNotificationPlugin';
import { GalleryCardView } from '../../../gallery_view';
import { GoogleSheetPluginView } from '../../../plugins/GoogleSheet';
import { JsonPluginView } from '../../../json_plugin';
import { CalendlyIntegrationPluginView } from '../../../plugins/CalendlyIntegration';
import { EntryPointBotLink } from '../../EntryPointBotLink';
import { EntryPointSendToMessenger } from '../../../entry_point_send_to_messenger';
import { EntryPointFacebookPage } from '../../EntryPointFacebookPage';
import { EntryPointCustomerChat } from '../../EntryPointCustomerChat';
import { FAQView } from '../components/FAQ';
import {
  EntryPointCommentsAutoreply,
  EntryPointInstagramCommentsAutoreply,
} from '../../EntryPointCommentsAutoreply';
import { EntryPointFacebookAds } from '../../EntryPointFacebookAds';
import { EntryPointFacebookAdsJson } from '../../EntryPointFacebookAds/EntryPointFacebookAdsJson';
import { EntryPointInstagramDirect } from '../../EntryPointInstagramDirect';
import { EntryPointInstagramStoryMention } from '../../EntryPointInstagramStoryMention';
import { EntryPointInstagramAds } from '../../EntryPointInstagramAds';
import { EntryPointInstagramStoryReply } from '../../EntryPointInstagramStoryReply';
import { EntryPointInstagramLink } from '../../EntryPointInstagramLink';
import { AddAttributePluginView } from '../../../plugins/AddAttrubute';
import { RemoveAttributePluginView } from '../../../plugins/RemoveAttribute';
import {
  invalidStrokeColor,
  mainStrokeColor,
  pluginWidth,
} from '../../../plugin_consts';
import { BLOCK_SUBTYPES } from '../../../../consts';
import { EntryPointShopifyBackInStock } from '../../EntryPointShopifyBackInStock';
import { EntryPointWhatsappDirect } from '../../EntryPointWhatsappDirect';
import { EntryPointFacebookPersistentMenu } from '../../EntryPointPersistentMenu/EntryPointFacebookPersistentMenu';
import { EntryPointInstagramPersistentMenu } from '../../EntryPointPersistentMenu/EntryPointInstagramPersistentMenu';
import { EntryPointWhatsappBotLink } from '../../EntryPointWhatsappBotLink';
import { EntryPointAdCommentsAutoreply } from '../../EntryPointCommentsAutoreply/AdCommentsAutoreply/EntryPointAdCommentsAutoreply';
import { WhatsappTemplatePluginView } from '@components/FlowBuilder/views/plugins/WhatsappTemplatePlugin/WhatsappTemplatePlugin';
import { SendEmailPlugin } from '../../../plugins/SendEmailPlugin';
import { EntryPointWhatsappWidget } from '../../EntryPointWhatsappWidget';
import { EntryPointWhatsappPopup } from '../../EntryPointWhatsappPopup';

const getAlertViewPosition = (view: any) => {
  const x = pluginWidth - 41;
  switch (view.constructor) {
    case AudioPluginView:
      return { x, y: 4 };
    case GoToBlockPluginView:
      return { x, y: 12 };
    case SendEmailPlugin:
    case FacebookAnalyticsView:
    case OneTimeNotificationPluginView:
    case GoogleSheetPluginView:
    case AddAttributePluginView:
    case RemoveAttributePluginView:
    case JsonPluginView:
      return { x, y: 18 };
    case CalendlyIntegrationPluginView:
      return { x: x - 16, y: 6 };
    case EntryPointFacebookPersistentMenu:
    case EntryPointInstagramPersistentMenu:
    case EntryPointBotLink:
    case EntryPointSendToMessenger:
    case EntryPointCustomerChat:
    case EntryPointFacebookPage:
    case EntryPointCommentsAutoreply:
    case EntryPointAdCommentsAutoreply:
    case EntryPointInstagramCommentsAutoreply:
    case EntryPointFacebookShops:
    case EntryPointFacebookAds:
    case EntryPointFacebookAdsJson:
    case ExternalIntegrationEntryPoint:
    case EntryPointShopifyEvent:
    case EntryPointPopup:
    case EntryPointInstagramDirect:
    case EntryPointInstagramStoryMention:
    case EntryPointInstagramAds:
    case EntryPointInstagramStoryReply:
    case EntryPointInstagramLink:
    case EntryPointShopifyCustomerChat:
    case EntryPointShopifyBackInStock:
    case EntryPointWhatsappDirect:
    case EntryPointWhatsappBotLink:
    case EntryPointWhatsappWidget:
    case EntryPointWhatsappPopup:
      return { x: pluginWidth - ALERT_VIEW_SIZE - ALERT_VIEW_MARGIN.x, y: 6 };
    case FAQView:
      return { x: pluginWidth - 24, y: -41 };
    case WhatsappTemplatePluginView:
      return { x: pluginWidth - ALERT_VIEW_SIZE - 6, y: 12 };
    default: {
      const hasTitle = view.children.some(
        (v: any) => v instanceof PluginTitleLayout,
      );
      if (hasTitle) {
        return { x, y: 13 };
      }
      return { x, y: 16 };
    }
  }
};

export class AlertView extends MainLayout {
  TEST_NAME = 'AlertView';

  constructor({ position }: { position?: Point } = {}) {
    super({
      ...position,
      cursor: {
        in: 'pointer',
      },
    });

    this.layout(
      new TextureShape({
        texture: alertSvgTexture,
        width: ALERT_VIEW_SIZE,
        height: ALERT_VIEW_SIZE,
      }),
    );
  }
}

// If you want use it, you need to set `background` parameter into view
// otherwise you will not see the alert
export const addValidationProps = (
  view: any,
  placementLayout?: MainLayout,
  position?: Point,
  onClick?: (e: MouseEvent) => void,
) => {
  const alertView = new AlertView({
    position: position ?? getAlertViewPosition(view),
  });
  alertView.visible = false;
  alertView.on('click', (event: MouseEvent) => {
    onClick?.(event);
  });
  alertView.on('pointerdown', (event: MouseEvent) => {
    if (onClick) {
      event.stopPropagation();
    }
  });
  const layout = placementLayout || view;
  // TODO: refactor as with .addToLayout to simplify usage in testing API
  layout.addChild(alertView);
  /* eslint-disable no-param-reassign */
  view._layoutProps.cursor = { in: 'pointer' };
  view._layoutProps.background.onhover = {
    stroke: mainStrokeColor,
    strokeWidth: 2,
  };

  // eslint-disable-next-line consistent-return
  view._layoutProps.background.stroke = () => {
    const error: ValidationError = view.validationError?.();
    const isFirstInteraction = view._card?.isFirstInteraction ?? false;
    if (isFirstInteraction) {
      return undefined;
    }
    if (error) {
      return invalidStrokeColor;
    }
    if (view instanceof GalleryCardView) {
      return mainStrokeColor;
    }
  };

  // eslint-disable-next-line consistent-return
  view._layoutProps.background.strokeWidth = () => {
    const cardError: ValidationError = view.validationError?.();
    const isFirstInteraction = view._card?.isFirstInteraction ?? false;
    const { blockView } = view._node || {};
    const entryPointError = (
      getEntryPointCardView(blockView) as EntryPointView | undefined
    )?.validationError();
    const error = cardError || entryPointError;
    const toggle: Toggle | undefined = blockView?._toggle;

    const isEntryPoint =
      view?._node.block.subtype === BLOCK_SUBTYPES.entrypoint;
    const header = view?._node.blockView?.header;
    const tooltipView = isEntryPoint ? header : alertView;

    if (!error && blockView?._error) {
      blockView._error = false;
      toggle?.setDisabled(false);
    }

    const pluginId = isEntryPoint ? view._card.plugin_id : undefined;

    if (!isFirstInteraction) {
      if (error) {
        if (typeof error === 'string') {
          tooltipScaled({
            view: alertView,
            text: error,
            overTimeout: 500,
          });
          alertView.visible = !!cardError;
          alertView.renderElement();
        } else if (typeof error === 'object' && error !== null) {
          tooltipScaled({
            view: tooltipView,
            text: error.message,
            onClick: error.onClick,
            url: error.url,
            overTimeout: 500,
            pluginId,
          });
          alertView.visible = !!cardError;
          alertView.renderElement();

          if (!blockView?._error) {
            blockView._error = true;
            toggle?.setDisabled(!toggle._value);
          }
        } else {
          alertView.visible = false;
        }
        alertView.renderElement();
        if (
          typeof error === 'object' &&
          error !== null &&
          error.isBlockError &&
          blockView
        ) {
          blockView._error = true;
          return 0;
        }
        return 2;
      }

      unsubscribeTooltipFromView(tooltipView);
      unsubscribeTooltipFromView(alertView);

      if (view instanceof GalleryCardView) {
        alertView.visible = false;
        alertView.renderElement();
        return 1;
      }
      alertView.visible = false;
      alertView.renderElement();
      if (blockView) {
        blockView._error = false;
      }
      return 0;
    }
    /* eslint-enable no-param-reassign */
  };
};
