import { HEXColors } from '@ui/_common/colors';
import i18next from 'i18next';
import { OrderReceiptPluginConfig } from '../../../../Plugins/OrderReceiptPlugin/OrderReceiptPluginConst';
import { receiptProductSvgTexture } from '../../../assets/textures';
import {
  HLayout,
  MainLayout,
  VLayout,
} from '../../../components/Elements/Layouts';
import { HTMLText, TextureShape } from '../../../components/Elements/Shapes';
import { Node } from '../../../Node';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { pluginWidth } from '../../plugin_consts';

export class OrderReceiptPlugin
  extends MainLayout
  implements StatefulPluginDelegate<OrderReceiptPluginConfig>
{
  public _node: Node;
  private state: StatefulPlugin<OrderReceiptPluginConfig>;

  constructor(state: StatefulPlugin<OrderReceiptPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: HEXColors.greyLight20,
      },
    });

    const box = new VLayout();

    this.addToLayout(box, {
      margin: {
        top: 16,
        left: 16,
        right: 16,
        bottom: 16,
      },
    });

    box.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.Order'),
        fontSize: 15,
        trustedHtml: true,
      }),
      {
        margin: {
          bottom: 16,
        },
      },
    );

    const productBox = new HLayout();
    box.addToLayout(productBox, {
      margin: {
        bottom: 16,
      },
    });

    productBox.addToLayout(
      new TextureShape({
        texture: receiptProductSvgTexture,
        width: 70,
        height: 70,
      }),
      {
        margin: {
          right: 8,
        },
      },
    );

    const productTextBox = new VLayout();

    productBox.addToLayout(productTextBox, {
      margin: {
        top: 15,
      },
    });

    productTextBox.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.ProductExample'),
        trustedHtml: true,
        fontSize: 15,
        fontStyle: 'semibold',
      }),
    );

    productTextBox.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.Description'),
        trustedHtml: true,
        fill: HEXColors.greyDark,
        fontSize: 15,
      }),
    );

    box.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.PaidWith'),
        trustedHtml: true,
        fill: HEXColors.greyDark,
        fontSize: 12,
      }),
    );

    box.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.CardType'),
        trustedHtml: true,
        fontSize: 15,
      }),
      {
        margin: {
          bottom: 8,
        },
      },
    );

    box.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.ShipTo'),
        trustedHtml: true,
        fill: HEXColors.greyDark,
        fontSize: 12,
      }),
    );

    box.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.AddressCityCountryPostalCode'),
        trustedHtml: true,
        fontSize: 15,
        width: 240,
      }),
      {
        margin: {
          bottom: 16,
        },
      },
    );

    const totalBox = new HLayout();
    box.addToLayout(totalBox);

    totalBox.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.orderReceipt.Total'),
        trustedHtml: true,
        fontSize: 15,
        width: 226,
      }),
    );

    totalBox.addToLayout(
      new HTMLText({
        text: '$0.00',
        trustedHtml: true,
        fontStyle: 'semibold',
        fontSize: 15,
        align: 'right',
        width: 50,
      }),
    );

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
  }

  pluginDidSet() {}

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
