import {
  AggregatedFlowQuery_bot as Bot,
  AggregatedFlowQuery_me as Admin,
  AggregatedFlowQuery_bot_flow as Flow,
  AggregatedFlowQuery_bot_flowBlocks as FlowBlock,
  AggregatedFlowQuery_flowStats as FlowStats,
  AggregatedFlowQuery_bot_verifiedPermissions as VerifiedPermissions,
  AggregatedFlowQuery_customAiTemplates,
} from '@utils/Data/Flow/Aggregated/@types/AggregatedFlowQuery';
import { Card } from '@components/Plugins/common/types';
import { FlowTypes } from '@utils/Data/Flow/Aggregated';
import { Layout } from './components/Elements/Layouts/Layout';
import { MainLayout } from './components/Elements/Layouts';
import { ValidationResult } from './views/entry-points/common/utils/types';

export { FlowTypes, FlowBlock };
export { PluginType } from '@components/Plugins/common/PluginTypes';
export { VerifiedPermissions };
export { Block } from './baseTypes';

export interface FlowData {
  user: Admin;
  bot: Bot;
  uniqStats: FlowStats | null;
  id: Flow['id'];
  sourceFlowId?: Flow['source_flow_id'];
  title: Flow['title'];
  botId: Bot['id'];
  botStatus: Bot['status'];
  botFeatures: Bot['features'];
  blocks: FlowBlock[];
  blocksTitles: Bot['archiveBlocks'];
  groups: Bot['blocksGroups'];
  root: FlowBlock;
  segments: Bot['segments'];
  verifiedPermissions: Bot['verifiedPermissions'];
  sharingParams: Flow['sharing_params'];
  collapsed: Flow['collapsed'];
  platform: Flow['platform'];
  abtFlags?: Record<string, any>;
  customAiTemplates: AggregatedFlowQuery_customAiTemplates[] | null;
}

export type Point = {
  x: number;
  y: number;
};

export interface IPluginValidationError {
  validationError(): string | undefined;
}

export interface IEntryPointValidationError {
  validationError(): ValidationResult | undefined;
}

// TODO: make return type more strict
export type ValidationError =
  | {
      message?: string;
      isBlockError?: boolean;
      onClick?(): void;
      url?: string;
    }
  | string
  | boolean
  | undefined;

export type PropFunc<T> = ((v?: Layout) => T) | T;

export interface EntryPointView extends MainLayout {
  validationError(): ValidationError;
}

export interface ISignificantChangesInConfig {
  hasSignificantChangesInConfig(): boolean;
}

export interface IEntryPointView
  extends ISignificantChangesInConfig,
    IEntryPointValidationError {}

export interface IPluginView
  extends ISignificantChangesInConfig,
    IPluginValidationError {}

export { Card };

export interface UpdateError extends Error {
  status: number;
  json(): Promise<any>;
}
