import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { FROM_PERMISSION_GRANT } from '@components/Plugins/FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { goToGetPermissions } from '@utils/FacebookPages/goToGetPermissions';
import { Modal } from '@services/index';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { sendEvent } from '@utils/Analytics';
import { PermissionGroup } from '@utils/Permissions';

interface FacebookShopsRequestPermissionsDialogProps {
  permissionGroup: PermissionGroup;
  onDismiss?: () => void;
}

export const FacebookShopsRequestPermissionsDialog: React.FC<FacebookShopsRequestPermissionsDialogProps> =
  ({ onDismiss, permissionGroup }) => (
    <DefaultDialog
      dialogStyle={{
        width: 598,
      }}
      header={window.i18next.t(
        'FacebookShopsRequestPermissionsDialog-string-1705-choose-products-in-a-catalog',
      )}
      onDismiss={() => {
        onDismiss?.();
      }}
      footer={
        <Button
          onClick={() => {
            sendEvent({
              category: 'flows',
              action: 'Give permissions to Facebook Shop click (from dialog)',
              label: PluginType.facebook_shops_entry_point,
            });
            goToGetPermissions({
              permissionGroup,
              interruptedHref: `${window.location.href}?${FROM_PERMISSION_GRANT}`,
            });
          }}
        >
          {window.i18next.t(
            'FacebookShopsRequestPermissionsDialog-JSXText-5413-grant-permissions',
          )}
        </Button>
      }
    >
      <Flex>
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'FacebookShopsRequestPermissionsDialog-JSXText--102-to-show-your-products-in-this-bot-chatfuel-needs-permission',
          )}
          <br />
          {window.i18next.t(
            'FacebookShopsRequestPermissionsDialog-JSXText--312-to-access-your-facebook-shop',
          )}
        </Type>
      </Flex>
    </DefaultDialog>
  );

export const showFacebookShopsRequestPermissionsDialog = (
  props: FacebookShopsRequestPermissionsDialogProps,
) => {
  Modal.show(({ close }) => (
    <FacebookShopsRequestPermissionsDialog
      {...props}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
    />
  ));
};
