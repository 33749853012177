import { ShopifyConnectedAccountStateQuery_bot_connectedShop } from '@utils/Integrations/Shopify/@types/ShopifyConnectedAccountStateQuery';
import { showConnectShopifyAccountDialog } from '@utils/Integrations/Shopify/ConnectShopifyAccountDialog';
import { getShopifyConnectedAccountObservable } from '@utils/Integrations/Shopify/getShopifyConnectedAccountObservable';
import { BotTabs, getTabLink } from '@utils/Routing';
import { Subscription } from 'apollo-client/util/Observable';
import i18next from 'i18next';
import { goDisconnectShopifyAccount } from '@utils/Integrations/Shopify/utils/goDisconnectShopifyAccount';
import { shopifySvgTexture } from '../../../assets/textures';
import { HLayout } from '../../../components/Elements/Layouts';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { ExternalAccount } from '../../kit/ExternalAccount';
import { pluginWidth } from '../../plugin_consts';
import accountSrc from './images/account.svg';

interface ShopifyAccountProps {
  blockId: string;
  eventPropertyBag?: object;
  onPageConnectRequest: () => void;
  onConnectedAccountStatusChange?: (
    connectedAccount: null | ShopifyConnectedAccountStateQuery_bot_connectedShop,
  ) => void;
}

export class ShopifyAccount extends HLayout {
  private shopifyAccountDataSubscription: Subscription | undefined;
  public connectedAccount:
    | ShopifyConnectedAccountStateQuery_bot_connectedShop
    | null
    | undefined;

  constructor({
    onConnectedAccountStatusChange,
    onPageConnectRequest,
    eventPropertyBag,
    blockId,
  }: ShopifyAccountProps) {
    super();

    const {
      flow: { botId, id: flowId },
    } = getFlowControllerStrict();

    if (!getPixiFieldStrict().isViewOnly()) {
      this.shopifyAccountDataSubscription =
        getShopifyConnectedAccountObservable(botId).subscribe((account) => {
          this.connectedAccount = account;
          onConnectedAccountStatusChange?.(account);
        });
    }

    this.addToLayout(
      new ExternalAccount({
        eventPropertyBag,
        width: pluginWidth,
        getData: () => {
          const domain = this.connectedAccount?.myshopify_domain;
          return {
            currentAccountId: domain || null,
            accounts: domain
              ? [
                  {
                    id: domain,
                    name: domain,
                    iconSrc: accountSrc,
                  },
                ]
              : [],
          };
        },
        button: {
          title: i18next.t('shopify.connectShopifyStore'),
          icon: shopifySvgTexture,
        },
        onConnectRequest: () => {
          if (!getFlowControllerStrict().flow.botStatus?.page_info) {
            onPageConnectRequest?.();
            return;
          }

          showConnectShopifyAccountDialog({
            interruptedHref: getTabLink(BotTabs.flows, botId, {
              flowId,
              blockId,
            }),
          });
        },
        onDisconnectRequest: () => {
          const domain = this.connectedAccount?.myshopify_domain;
          if (domain) {
            goDisconnectShopifyAccount(botId);
          }
        },
      }),
    );
  }

  destroy() {
    this.shopifyAccountDataSubscription?.unsubscribe();
    super.destroy();
  }
}
