import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { log } from 'cf-common/src/logger';
import { toaster, ServiceMessageType } from '@services/MessageService';
import { Node } from '../../../Node';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { Image, TextureShape } from '../../../components/Elements/Shapes';
import { createLineMenu } from '../../Menu/createLineMenu';
import { pluginWidth } from '../../plugin_consts';
import storyReplySvg from '../../../assets/entry_point/instagram_story_reply.svg';
import { validateIsInstagramPageConnected } from '../common/utils/validateIsInstagramPageConnected';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { ReplyToByKeywordsGroups } from '../common/components/ReplyToByKeywordGroups/ReplyToByKeywordsGroups';
import {
  instagramStoryReplyEntryPointFragment_config as InstagramStoryReplyEntryPointConfig,
  instagramStoryReplyEntryPointFragment_config_keywords_setup as KeywordsSetup,
} from '../../../../Plugins/InstagramStoryReplyEntryPoint/@types/instagramStoryReplyEntryPointFragment';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { infoSvgTexture } from '../../../assets/textures';
import { Checkbox } from '../../kit/Checkbox';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { Label } from '../../kit/Label';
import { LinkedLayout } from '../../../components/Elements/Layouts/types';
import { getI18nCommentsAutoreplyKey } from '../EntryPointCommentsAutoreply/utils/getI18nCommentsAutoreplyKey';
import { tooltipScaled } from '../../helpers/TooltipHelpers';

export class EntryPointInstagramStoryReply
  extends VLayout
  implements StatefulPluginDelegate<InstagramStoryReplyEntryPointConfig>
{
  TEST_NAME = 'EntryPointInstagramStoryReply';

  public readonly state: StatefulPlugin<InstagramStoryReplyEntryPointConfig>;

  private replyToView: ReplyToByKeywordsGroups;
  private replyToForCurrentSubscribersView: ReplyToByKeywordsGroups;

  private icon!: VLayout;

  private _node: Node;

  constructor(
    state: StatefulPlugin<InstagramStoryReplyEntryPointConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const storyRepliesTitleView = new Label({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.InstagramStoryReply.storyRepliesTitle',
      ),
      tooltipText: i18next.t(
        'modernComponents.FlowBuilder.views.components.InstagramStoryReply.storyRepliesTitleTooltip',
      ),
      tooltipSvgIcon: infoSvgTexture,
    });
    this.addToLayout(storyRepliesTitleView, { margin: { bottom: 8 } });

    const greyBox = new VLayout({
      width: pluginWidth,
      height: 188,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
      cursor: {
        in: 'default',
      },
    });

    const image = new Image({
      url: storyReplySvg,
      cornerRadius: 8,
      width: 215,
      height: 155.5,
    });
    greyBox.addToLayout(image, { align: 'center', margin: { top: 17 } });

    this.addToLayout(greyBox, { margin: { bottom: 16 } });

    const replyToTitleView = new Label({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.InstagramStoryReply.reactToTitle',
      ),
    });
    this.addToLayout(replyToTitleView, { margin: { bottom: 8 } });

    const { id: cardId, config } = this.state.data;
    this.replyToView = new ReplyToByKeywordsGroups(
      () => {
        const { data } = this.state;
        return {
          ...data,
          config: {
            keywords_setup: data.config.keywords_setup,
          },
        };
      },
      this._node,
      {
        defaultAnswerLabel: window.i18next.t(
          'InstagramStoryReply-string-3340-all-story-replies',
        ),
        keywordsGroupLabel: window.i18next.t(
          'InstagramStoryReply-string--803-story-replies-with-keywords',
        ),
        targetPlugin: PluginType.instagram_story_reply_entry_point,
        statKey: `${cardId}_default_intent_new_users`,
        lineParams: {
          items: createLineMenu({
            isShowConnectToExistingBlock: true,
          }),
        },
        isEditing: () => !!this.state.isEditing,
        onChange: (keywordsSetup) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              keywords_setup: keywordsSetup,
            },
          }));
        },
        showFilterTypeSelector: true,
      },
    );

    this.replyToForCurrentSubscribersView = new ReplyToByKeywordsGroups(
      () => {
        const { data } = this.state;
        return {
          ...data,
          config: {
            keywords_setup: data.config.already_subscribed_keywords_setup,
          },
        };
      },
      this._node,
      {
        defaultAnswerLabel: window.i18next.t(
          'InstagramStoryReply-string-3340-all-story-replies',
        ),
        keywordsGroupLabel: window.i18next.t(
          'InstagramStoryReply-string--803-story-replies-with-keywords',
        ),
        targetPlugin: PluginType.instagram_story_reply_entry_point,
        statKey: `${cardId}_default_intent_existing_users`,
        lineParams: {
          items: createLineMenu({
            isShowConnectToExistingBlock: true,
          }),
        },
        isEditing: () => !!this.state.isEditing,
        onChange: (keywordsSetup) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              already_subscribed_keywords_setup: keywordsSetup,
            },
          }));
        },
        showFilterTypeSelector: true,
      },
    );

    this.addToLayout(this.replyToView);

    const separateReplies = new HLayout({
      cursor: {
        in: 'default',
      },
    });
    separateReplies.addToLayout(
      new Checkbox({
        text: i18next.t(
          'modernComponents.FlowBuilder.views.components.InstagramStoryReply.oldUsersFlowCheckboxTitle',
        ),
        value: config.already_subscribed_setup_enabled,
        onChange: (value: boolean) => {
          this.state.set(({ config }) => ({
            config: {
              ...config,
              already_subscribed_setup_enabled: value,
            },
          }));
          logFlowPluginEvent(
            PluginType.instagram_story_reply_entry_point,
            'Another reply for current subscribers change',
            {
              already_subscribed_setup_enabled: value,
              blockId: this._node.id,
              cardId,
            },
          );
        },
        textFalse: undefined,
        textTrue: undefined,
        width: pluginWidth - 20,
        multiline: true,
      }),
      {},
    );

    this.icon = new VLayout({}).addToLayout(
      new TextureShape({
        texture: infoSvgTexture,
        width: 16,
        height: 16,
      }),
    );

    tooltipScaled({
      view: this.icon,
      text: i18next.t(
        getI18nCommentsAutoreplyKey('oldUsersFlowCheckboxTooltip'),
      ),
    });

    separateReplies.addToLayout(this.icon, { margin: { left: 4 } });

    this.addToLayout(separateReplies, { margin: { top: 16 } });

    this.addToLayout(this.replyToForCurrentSubscribersView, {
      margin: { top: 16 },
      gone: () => !this.state.data.config.already_subscribed_setup_enabled,
    });
  }

  onBeforeRender() {
    const { config } = this.state.data;
    (
      this.replyToForCurrentSubscribersView.keywordGroupsBox.views() as LinkedLayout[]
    ).forEach(({ lineStartView }) => {
      // eslint-disable-next-line no-param-reassign
      lineStartView._lineView.gone = !config.already_subscribed_setup_enabled;
    });
    this.replyToForCurrentSubscribersView.lineStartView._lineView.gone =
      !config.already_subscribed_setup_enabled;
  }

  pluginDidSet() {
    this.state.save();
    this.renderNode();
  }

  pluginDidSaveError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.InstagramStoryReply.failedToSaveEntryPointMessageText',
        ),
      },
    });
    log.error({ error, msg: 'Error while saving Story Reply Entry Point' });
  }

  validationError() {
    const {
      already_subscribed_setup_enabled,
      already_subscribed_keywords_setup,
      keywords_setup,
    } = this.state.data.config;
    return (
      validateIsInstagramPageConnected(this._node) ||
      this.validateKeywords(this.replyToView, keywords_setup) ||
      (already_subscribed_setup_enabled &&
        this.validateKeywords(
          this.replyToForCurrentSubscribersView,
          already_subscribed_keywords_setup,
        ))
    );
  }

  private validateKeywords(
    replyView: ReplyToByKeywordsGroups,
    keywordsSetup: KeywordsSetup | null,
  ) {
    if (!keywordsSetup?.default_block_id) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.InstagramStoryReply.defaultBlockKeywordsValidationText',
        ),
        isBlockError: true,
      };
    }
    if (keywordsSetup?.intents?.some((item) => !item.goto_block)) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.InstagramStoryReply.intentKeywordsValidationText',
        ),
        isBlockError: true,
      };
    }
    return replyView.isValid()
      ? undefined
      : {
          message: i18next.t(
            'modernComponents.FlowBuilder.views.components.InstagramStoryReply.uniqueKeywordsValidationText',
          ),
          isBlockError: true,
        };
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
