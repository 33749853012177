import { PluginType } from '@components/Plugins/common/PluginTypes';
import { BlockContextType } from '@globals';
import { LineStartSubtype } from '../components/Line/types';
import { BLOCK_SUBTYPES } from '../../consts';
import { Block } from '../../baseTypes';
import i18next from 'i18next';

interface ValidateCommentsContextLines {
  toBlock: Block;
  fromBlock: Block;
  lineSubtype?: LineStartSubtype;
  entryPointId?: PluginType;
}

export const validateCommentsContextLines = ({
  toBlock,
  fromBlock,
  lineSubtype,
  entryPointId,
}: ValidateCommentsContextLines) => {
  const isToBlockContextTypeComments =
    toBlock.context_type === BlockContextType.comments;
  const isFromBlockContextTypeComments =
    fromBlock.context_type === BlockContextType.comments;

  const isFromAutoreplyForOldUser =
    lineSubtype === LineStartSubtype.commentsAutoreplyOldUsers;
  const isFromAutoreplyForAllUser =
    lineSubtype === LineStartSubtype.commentsAutoreplyAllUsers;
  const isMessengerCommentsAutoreply =
    entryPointId &&
    [
      PluginType.comments_autoreply_entry_point,
      PluginType.ad_comments_autoreply_entry_point,
    ].includes(entryPointId);

  if (
    isMessengerCommentsAutoreply &&
    isFromAutoreplyForOldUser &&
    toBlock.subtype !== BLOCK_SUBTYPES.comments_autoreply_content &&
    !isToBlockContextTypeComments
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.commentsAutoreplyUnsupportedTargetBlockMessageForOld',
      ),
    };
  }

  if (
    isMessengerCommentsAutoreply &&
    isFromAutoreplyForAllUser &&
    !isToBlockContextTypeComments &&
    toBlock.subtype !== BLOCK_SUBTYPES.comments_autoreply_content
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.commentsAutoreplyUnsupportedTargetBlockMessageForAll',
      ),
    };
  }

  if (
    toBlock.subtype !== BLOCK_SUBTYPES.comments_autoreply_content &&
    toBlock.subtype !== BLOCK_SUBTYPES.instagram_comments_autoreply_content &&
    !isToBlockContextTypeComments &&
    isFromBlockContextTypeComments &&
    fromBlock.subtype === BLOCK_SUBTYPES.condition
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.fromAutoreplyCondition',
      ),
    };
  }

  if (
    toBlock.subtype !== BLOCK_SUBTYPES.comments_autoreply_content &&
    toBlock.subtype !== BLOCK_SUBTYPES.instagram_comments_autoreply_content &&
    !isToBlockContextTypeComments &&
    isFromBlockContextTypeComments &&
    fromBlock.subtype !== BLOCK_SUBTYPES.condition
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.fromAutoreplyOpenAI',
      ),
    };
  }

  if (
    entryPointId === PluginType.instagram_comments_autoreply_entry_point &&
    isFromAutoreplyForOldUser &&
    toBlock.subtype !== BLOCK_SUBTYPES.instagram_comments_autoreply_content &&
    !isToBlockContextTypeComments
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.commentsAutoreplyUnsupportedTargetBlockMessageForOld',
      ),
    };
  }

  const isToAutoreplyOpenAi =
    toBlock.subtype === BLOCK_SUBTYPES.open_ai && isToBlockContextTypeComments;

  if (
    entryPointId === PluginType.instagram_comments_autoreply_entry_point &&
    isFromAutoreplyForAllUser &&
    toBlock.subtype !== BLOCK_SUBTYPES.instagram_comments_autoreply_content &&
    !isToAutoreplyOpenAi
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.commentsAutoreplyUnsupportedTargetBlockMessageForAll',
      ),
    };
  }

  if (
    (toBlock.subtype === BLOCK_SUBTYPES.comments_autoreply_content ||
      (isToBlockContextTypeComments &&
        toBlock.subtype !== BLOCK_SUBTYPES.condition)) &&
    !(
      entryPointId === PluginType.instagram_comments_autoreply_entry_point &&
      isToAutoreplyOpenAi
    ) &&
    !isMessengerCommentsAutoreply &&
    !isFromBlockContextTypeComments
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.commentsAutoreplySupportedTargetBlockMessage',
      ),
    };
  }

  if (
    toBlock.subtype === BLOCK_SUBTYPES.instagram_comments_autoreply_content &&
    entryPointId !== PluginType.instagram_comments_autoreply_entry_point &&
    !isFromBlockContextTypeComments
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.commentsAutoreplySupportedTargetBlockMessage',
      ),
    };
  }

  if (
    isToBlockContextTypeComments &&
    !isFromAutoreplyForOldUser &&
    !isFromBlockContextTypeComments &&
    toBlock.subtype === BLOCK_SUBTYPES.condition
  ) {
    return {
      fits: false,
      tooltip: i18next.t(
        'modernComponents.FlowBuilder.views.blockView.entryPoint.validation.messenger.commentsAutoreplySupportedTargetBlockMessageForOld',
      ),
    };
  }

  return undefined;
};
