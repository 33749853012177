import isEqual from 'lodash-es/isEqual';
import { Messages, toaster } from '@services/MessageService';
import { HEXColors } from '@ui/_common/colors';
import { waitFoTheReturnToTab } from '@utils/Event/waitFoTheReturnToTab';
import { ValidationErrors } from '@components/Plugins/common';
import { QUANT_ITEMS_WITH_IMMEDIATELY } from '@utils/DateTime/quant';
import i18n from 'i18next';
import { find, lensPath, propEq, remove, set, view, Lens } from 'ramda';
import { Subscription } from 'rxjs';
import { log, Level } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { InteractionEvent } from 'pixi.js-legacy';
import unknownUserSvg from '../../../../../ui/avatar/images/unknown-user.svg';
import {
  calendlyIntegrationPluginFragment_config as CalendlyIntegrationConfig,
  calendlyIntegrationPluginFragment_config_reminders as CalendlyReminder,
} from '../../../../Plugins/CalendlyIntegration/@types/calendlyIntegrationPluginFragment';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../Plugins/CalendlyIntegration/CalendlyIntegrationConst';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import {
  infoSvgTexture,
  pluginCalendlyConnectAccountSvgTexture,
} from '../../../assets/textures';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import {
  HTMLText,
  HTMLTextProps,
  Rect,
  TextureShape,
} from '../../../components/Elements/Shapes';
import { removeEntity } from '../../../components/Modals';
import { Node } from '../../../Node';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { FlowData, IPluginView } from '../../../types';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { opacityAnimationFunc } from '../../../utils/opacityAnimationFunc';
import { BlockView } from '../../block_view';
import { ButtonView } from '../../button_view';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import { LineStartView } from '../../components/Line/LineStartView';
import { SetAttributeView } from '../../components/SetAttributeView';
import { DropdownFieldView } from '../../dropdown_field_view';
import { buttonControl } from '../../helpers/ControlsHelpers';
import { removeTooltip, tooltipScaled } from '../../helpers/TooltipHelpers';
import { DelayInput, LabelPosition } from '../../kit/DelayInput';
import { ExternalAccount } from '../../kit/ExternalAccount/ExternalAccount';
import {
  ItemStatView,
  STAT_VIEW_HEIGHT,
  STAT_VIEW_WIDTH,
} from '../../kit/ItemStatView/ItemStatView';
import { createLineMenu } from '../../Menu/createLineMenu';
import { CreateMenuViewOverlay } from '../../menu_view_overlay';
import { pluginWidth } from '../../plugin_consts';
import { Margin } from '../../utility_classes';
import { cropHTMLText, resByFunc, swapImmutable } from '../../utils';
import { IntegrationCalendlyAccountStateQuery_bot_connectedCalendlyAccounts as ConnectedCalendlyAccount } from './utils/@types/IntegrationCalendlyAccountStateQuery';
import { disconnectCalendlyAccount } from './utils/disconnectCalendlyAccount';
import { getCalendlyAccountsObservable } from './utils/getIntegrationCalendlyAccountIdObservable';
import { startIntegrationCalendlyOAuth } from './utils/startIntegrationCalendlyOAuth';
import { getErrorMessage, hasError, mapErrorToType } from './utils/error';
import { ValidationErrorTypes } from './types';

const WAIT_RETURN_TIMEOUT = 3000;

const wrapperMargin = new Margin({ x: 20, y: 20 });
const lineStartViewMargin = new Margin({ left: 10 + 4, top: 5 });

const bookedBlockLens = lensPath(['booked_block_id']);
const rescheduledBlockLens = lensPath(['rescheduled_block_id']);
const cancelledBlockLens = lensPath(['cancelled_block_id']);
const nameAttributeLens = lensPath(['export_attributes', 'name']);
const emailAttributeLens = lensPath(['export_attributes', 'email']);
const reminderMSLens = (index: number) =>
  lensPath(['reminders', index, 'before_ms']);
const reminderBlockLens = (index: number) =>
  lensPath(['reminders', index, 'block_id']);

export class CalendlyIntegrationPluginView
  extends VLayout
  implements StatefulPluginDelegate<CalendlyIntegrationConfig>, IPluginView
{
  public readonly TEST_NAME = 'CalendlyIntegrationPluginView';
  private readonly state: StatefulPlugin<CalendlyIntegrationConfig>;
  private _node: Node;
  private flow: FlowData;

  private eventTitle!: HTMLText;
  private eventTypeTitle!: HTMLText;
  private eventTypeDropdown!: DropdownFieldView;
  private remindersListView!: VLayout;
  private userScheduledEventLine!: LineStartView;
  private userRescheduledEventLine!: LineStartView;
  private userCancelledEventLine!: LineStartView;
  private nameAttribute!: SetAttributeView;
  private emailAttribute!: SetAttributeView;
  private eventTypeLoaderView: HLayout | undefined;
  private reminderSection: VLayout;

  private isEventTypeLoading: boolean = false;
  private tooltips: Array<HLayout> = [];
  private connectedAccounts: Array<ConnectedCalendlyAccount> = [];
  private connectedAccountsSubscription: Subscription | null = null;
  private calendlyIntegrationSubscription: Subscription | null = null;
  private remindersLines: Array<LineStartView> = [];

  constructor(state: StatefulPlugin<CalendlyIntegrationConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
    });

    this.state = state;
    this.state.setDelegate(this);

    this._node = node;

    const { flow } = getFlowControllerStrict();
    this.flow = flow;

    const wrapper = new VLayout();

    wrapper.addToLayout(
      this.createNote(
        i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.validation.proAccountErrorNote',
        ),
      ),
      {
        margin: new Margin({ bottom: 16 }),
        gone: () => {
          const { validation_details } = this.state.data;

          if (!validation_details) {
            return true;
          }

          return !hasError(
            ValidationErrorTypes.proAccount,
            validation_details.fields,
          );
        },
      },
    );

    wrapper.addToLayout(
      new ExternalAccount({
        width: pluginWidth - wrapperMargin.x,
        getData: () => ({
          currentAccountId: this.adminAccount?.id || null,
          accounts: this.connectedAccounts.map(({ id, name }) => ({
            id,
            name,
            iconSrc: unknownUserSvg,
          })),
        }),
        button: {
          title: i18n.t(
            'modernComponents.FlowBuilder.views.components.Calendly.connectCalendlyAccount',
          ),
          icon: pluginCalendlyConnectAccountSvgTexture,
        },
        onConnectRequest: () => {
          const { flow } = getFlowControllerStrict();
          startIntegrationCalendlyOAuth({
            botId: flow.botId,
            flowId: flow.id,
            blockId: this._node.block.id,
          });
          logFlowPluginEvent(PluginType.calendly, 'connect calendly account');
        },
        onDisconnectRequest: () => {
          const { flow } = getFlowControllerStrict();

          if (!this.adminAccount?.id) {
            log({
              msg: 'Cannot disconnect account without account id',
              level: Level.info,
            });
          }
          logFlowPluginEvent(
            PluginType.calendly,
            'disconnect calendly account click',
          );
          removeEntity({
            onSubmit: () => {
              if (!this.adminAccount?.id) {
                return;
              }
              logFlowPluginEvent(
                PluginType.calendly,
                'disconnect calendly account confirm',
              );
              disconnectCalendlyAccount({
                botId: flow.botId,
                accountId: this.adminAccount.id,
              });
            },
            renderHeading: () =>
              i18n.t(
                'modernComponents.FlowBuilder.views.components.Calendly.disconnectCalendly',
              ),
            renderActionText: () =>
              i18n.t(
                'modernComponents.FlowBuilder.views.components.Calendly.yesDisconnect',
              ),
            renderNoteText: () =>
              i18n.t(
                'modernComponents.FlowBuilder.views.components.Calendly.areYouSureYouWantToDisconnect',
              ),
          });
        },
      }),
      { margin: new Margin({ bottom: 16 }) },
    );

    this.eventTypeLoaderView = this.createEventTypeLoaderView();
    wrapper.addToLayout(this.eventTypeLoaderView, {
      margin: new Margin({ bottom: 16 }),
      gone: () =>
        !this.state.data.config.account_id || !this.isEventTypeLoading,
    });

    wrapper.addToLayout(this.eventTypeTitleView(), {
      gone: () =>
        this.state.isEditing ||
        !this.state.data.config.account_id ||
        this.isEventTypeLoading,
      margin: new Margin({ bottom: 16 }),
    });

    wrapper.addToLayout(this.eventTypeSectionView(), {
      gone: () =>
        !this.state.isEditing ||
        !this.state.data.config.account_id ||
        this.isEventTypeLoading,
      margin: new Margin({ bottom: 16 }),
    });

    wrapper.addToLayout(this.createAutofillSectionView());

    wrapper.addToLayout(this.createActionsSectionView());

    this.reminderSection = this.createReminderSectionView();
    wrapper.addToLayout(this.reminderSection);

    this.addToLayout(wrapper, { margin: wrapperMargin });

    if (!getPixiFieldStrict().isViewOnly()) {
      this.connectedAccountsSubscription = getCalendlyAccountsObservable(
        this.flow.botId,
      ).observable.subscribe((connectedAccounts) => {
        this.connectedAccounts = connectedAccounts;
        const firstConnectedAccountId = this.connectedAccounts[0]?.id;
        if (this.state.data.config.account_id !== firstConnectedAccountId) {
          this.state.set(({ config }) => ({
            config: { ...config, account_id: firstConnectedAccountId || null },
          }));
          this.state.save().finally(() => this.renderNode());
          this.renderNode();
        }
      });
    }
  }

  private setEventTypeLoaderState(state: boolean) {
    if (state) {
      this.eventTypeLoaderView?.startAnimation();
    } else {
      this.eventTypeLoaderView?.renderNode();
    }

    this.isEventTypeLoading = state;
    this.eventTypeLoaderView?.renderNode();
  }

  private createEventTypeLoaderView() {
    const wrapperWidth = pluginWidth - wrapperMargin.x;
    const labelWidth = 140;

    const wrapper = new HLayout({
      height: 35,
      width: wrapperWidth,
      background: {
        fill: HEXColors.white,
        cornerRadius: 5,
        stroke: HEXColors.grey,
        strokeWidth: 1,
      },
    });
    wrapper.TEST_NAME = 'EventTypeLoaderView';

    const label = new Rect({
      width: labelWidth,
      height: 6,
      cornerRadius: 4,
      fill: HEXColors.grey,
    });

    wrapper.addToLayout(label, {
      align: 'center',
      margin: new Margin({ left: (wrapperWidth - labelWidth) / 2 }),
    });

    wrapper.animationFunction = () => opacityAnimationFunc(wrapper, [label]);

    return wrapper;
  }

  private createSectionHeaderView({
    header,
    tooltipText,
  }: {
    header: string;
    tooltipText: string;
  }) {
    const wrapper = new VLayout();

    const icon = new HLayout({}).addToLayout(
      new TextureShape({
        texture: infoSvgTexture,
        width: 16,
        height: 16,
      }),
    );

    this.tooltips.push(icon);

    tooltipScaled({
      view: icon,
      text: tooltipText,
      overTimeout: 500,
    });

    const label = new HLayout({
      height: 20,
    })
      .addToLayout(
        new HTMLText({
          fontSize: 15,
          text: header,
          fontStyle: 'bold',
          trustedHtml: true,
        }),
        {
          margin: new Margin({ right: 8 }),
        },
      )
      .addToLayout(icon, { margin: new Margin({ top: 2 }) });

    wrapper.addToLayout(label, {
      margin: new Margin({ bottom: 8 }),
    });

    return wrapper;
  }

  private createAutofillSectionView() {
    const wrapper = new VLayout();
    wrapper.TEST_NAME = 'AutofillSectionView';

    wrapper.addToLayout(
      this.createSectionHeaderView({
        header: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.autofill',
        ),
        tooltipText: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.autofillTooltip',
        ),
      }),
    );

    const textStyles: HTMLTextProps = {
      fontSize: 15,
      align: 'left',
      singleLine: true,
      width: 42,
      height: 36,
      verticalAlign: 'center',
      fill: HEXColors.greyDark,
      trustedHtml: true,
    };

    this.nameAttribute = new SetAttributeView(
      this.state,
      {
        width: 208,
        height: 36,
        caption: window.i18next.t('CalendlyIntegration-string-8242-value'),
        maxLength: 65,
        text: this.state.data.config.export_attributes?.name ?? '',
      },
      undefined,
      () => {
        const newAttribute = this.nameAttribute.text().trim();

        if (this.state.data.config.export_attributes?.name !== newAttribute) {
          this.state.set(({ config }) => ({
            config: set(nameAttributeLens, newAttribute, config),
          }));
          this.state.save();

          logFlowPluginEvent(PluginType.calendly, 'update name attribute', {
            blockId: this._node.id,
            cardId: this.state.data.id,
            value: newAttribute,
          });
        }
      },
    );

    const attrubuteName = new HLayout()
      .addToLayout(
        new HTMLText({
          ...textStyles,
          text: i18n.t(
            'modernComponents.FlowBuilder.views.components.Calendly.name',
          ),
        }),
        {
          margin: new Margin({ right: 10 }),
        },
      )
      .addToLayout(this.nameAttribute);

    this.emailAttribute = new SetAttributeView(
      this.state,
      {
        width: 208,
        height: 36,
        caption: window.i18next.t('CalendlyIntegration-string-8242-value'),
        maxLength: 65,
        text: this.state.data.config.export_attributes?.email ?? '',
      },
      undefined,
      () => {
        const newAttribute = this.emailAttribute.text().trim();

        if (this.state.data.config.export_attributes?.email !== newAttribute) {
          this.state.set(({ config }) => ({
            config: set(emailAttributeLens, newAttribute, config),
          }));
          this.state.save();

          logFlowPluginEvent(PluginType.calendly, 'update email attribute', {
            blockId: this._node.id,
            cardId: this.state.data.id,
            value: newAttribute,
          });
        }
      },
    );

    const attrubuteEmail = new HLayout()
      .addToLayout(
        new HTMLText({
          ...textStyles,
          text: i18n.t(
            'modernComponents.FlowBuilder.views.components.Calendly.email',
          ),
        }),
        {
          margin: new Margin({ right: 10 }),
        },
      )
      .addToLayout(this.emailAttribute);

    wrapper.addToLayout(attrubuteName, {
      margin: new Margin({ bottom: 8 }),
    });
    wrapper.addToLayout(attrubuteEmail, {
      margin: new Margin({ bottom: 16 }),
    });

    return wrapper;
  }

  private createActionsSectionView() {
    const wrapper = new VLayout();
    const { config } = this.state.data;
    wrapper.TEST_NAME = 'ActionsSectionView';

    wrapper.addToLayout(
      this.createSectionHeaderView({
        header: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.actions',
        ),
        tooltipText: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.actionsTooltip',
        ),
      }),
    );

    const textWidth = pluginWidth - wrapperMargin.x - STAT_VIEW_WIDTH;

    this.userScheduledEventLine = new LineStartView(
      {
        from: this,
        node: this._node,
        items: createLineMenu({
          isShowCalendlySpecificItems: true,
        }),
        onConnected: this.onLineConnected(bookedBlockLens),
        onRemoved: this.onLineRemoved(bookedBlockLens),
      },
      config.booked_block_id,
    );

    const userScheduledEvent = new HLayout().addToLayout(
      new HTMLText({
        fontSize: 15,
        text: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.userScheduledEvent',
        ),
        width: textWidth,
        trustedHtml: true,
      }),
    );
    userScheduledEvent.TEST_NAME = 'UserScheduledEventView';

    const userScheduledEventStats = getFullCounterStats(
      config.booked_counter_id || '',
      this._node.block.id,
    );

    if (userScheduledEventStats) {
      userScheduledEvent.addToLayout(
        new ItemStatView({
          stats: userScheduledEventStats,
          showPercentValue: true,
        }),
      );
    } else {
      userScheduledEvent.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));
    }

    userScheduledEvent.addToLayout(this.userScheduledEventLine, {
      margin: lineStartViewMargin,
    });

    this.userRescheduledEventLine = new LineStartView(
      {
        from: this,
        node: this._node,
        items: createLineMenu({
          isShowCalendlySpecificItems: true,
        }),
        onConnected: this.onLineConnected(rescheduledBlockLens),
        onRemoved: this.onLineRemoved(rescheduledBlockLens),
      },
      config.rescheduled_block_id,
    );

    const userRescheduledEvent = new HLayout().addToLayout(
      new HTMLText({
        fontSize: 15,
        text: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.userRescheduledEvent',
        ),
        width: textWidth,
        trustedHtml: true,
      }),
    );
    userRescheduledEvent.TEST_NAME = 'UserRescheduledEventView';

    const userRescheduledEventStats = getFullCounterStats(
      config.rescheduled_counter_id || '',
      this._node.block.id,
    );

    if (userRescheduledEventStats) {
      userRescheduledEvent.addToLayout(
        new ItemStatView({
          stats: userRescheduledEventStats,
          showPercentValue: true,
        }),
      );
    } else {
      userRescheduledEvent.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));
    }

    userRescheduledEvent.addToLayout(this.userRescheduledEventLine, {
      margin: lineStartViewMargin,
    });

    this.userCancelledEventLine = new LineStartView(
      {
        from: this,
        node: this._node,
        items: createLineMenu({
          isShowCalendlySpecificItems: true,
        }),
        onConnected: this.onLineConnected(cancelledBlockLens),
        onRemoved: this.onLineRemoved(cancelledBlockLens),
      },
      this.state.data.config.cancelled_block_id,
    );

    const userCancelledEvent = new HLayout({ height: 20 }).addToLayout(
      new HTMLText({
        fontSize: 15,
        text: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.userCanceledEvent',
        ),
        width: textWidth,
        trustedHtml: true,
      }),
    );
    userCancelledEvent.TEST_NAME = 'UserCancelledEventView';

    const userCancelledEventStats = getFullCounterStats(
      config.cancelled_counter_id || '',
      this._node.block.id,
    );

    if (userCancelledEventStats) {
      userCancelledEvent.addToLayout(
        new ItemStatView({
          stats: userCancelledEventStats,
          showPercentValue: true,
        }),
      );
    } else {
      userCancelledEvent.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));
    }

    userCancelledEvent.addToLayout(this.userCancelledEventLine, {
      margin: lineStartViewMargin,
    });

    wrapper.addToLayout(userScheduledEvent, {
      margin: new Margin({ bottom: 16 }),
    });
    wrapper.addToLayout(userRescheduledEvent, {
      margin: new Margin({ bottom: 16 }),
    });
    wrapper.addToLayout(userCancelledEvent, {
      margin: new Margin({ bottom: 16 }),
    });

    return wrapper;
  }

  private addButtonControl(index: number) {
    const delayInput = this.createReminderView(index);

    buttonControl(
      delayInput,
      this.remindersListView,
      undefined,
      () => {
        logFlowPluginEvent(PluginType.calendly, 'remove reminder');

        const { reminders } = this.state.data.config;
        if (!reminders?.length) {
          log({
            msg: 'Reminders are not defined',
            level: Level.error,
          });
        }

        const index = this.remindersListView.findViewIndex(delayInput);
        if (index === -1) {
          log({
            msg: 'View was not found',
            level: Level.error,
          });
        }

        const newReminders = remove(
          index,
          1,
          this.state.data.config.reminders!,
        );

        this.state.set(({ config }) => ({
          config: { ...config, reminders: newReminders },
        }));

        this.remindersListView.removeView(delayInput);
        this.remindersLines.splice(index, 1);
        delayInput.destroy();
        this.remindersListView.renderNode();

        this.state.save().finally(() => this.renderNode());
      },
      (_, startIdx, idx) => {
        if (startIdx === idx) {
          this.renderElement();
          return;
        }

        logFlowPluginEvent(PluginType.calendly, 'change reminder position', {
          from: startIdx,
          to: idx,
        });

        const reminders = swapImmutable(
          this.state.data.config.reminders,
          startIdx,
          idx,
        );

        this.remindersLines = swapImmutable(this.remindersLines, startIdx, idx);

        this.state.set(({ config }) => ({
          config: { ...config, reminders },
        }));
        this.state.save().finally(() => this.renderNode());
      },
    );

    this.remindersListView.addToLayout(delayInput);
  }

  private createReminderSectionView() {
    const wrapper = new VLayout();
    wrapper.TEST_NAME = 'ReminderSectionView';

    wrapper.addToLayout(
      this.createSectionHeaderView({
        header: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.reminder',
        ),
        tooltipText: i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.reminderTooltip',
        ),
      }),
    );

    this.remindersListView = new VLayout();
    this.remindersListView.TEST_NAME = 'RemindersListView';

    this.state.data.config.reminders?.forEach((_, index) => {
      this.addButtonControl(index);
    });

    wrapper.addToLayout(this.remindersListView, {
      margin: new Margin({ bottom: 16 }),
    });

    const addReminderButton = new ButtonView({
      title: `+ ${i18n.t(
        'modernComponents.FlowBuilder.views.components.Calendly.addReminder',
      )}`,
      width: 260,
      bgColor: HEXColors.white,
      textColor: HEXColors.black,
      onClick: async (event) => {
        event.stopPropagation();

        logFlowPluginEvent(PluginType.calendly, 'add reminder click');

        const reminder: CalendlyReminder = {
          __typename: 'CalendlyReminder',
          before_ms: 0,
          block_id: null,
          counter_id: null,
        } as const;

        this.state.set(({ config }) => ({
          config: {
            ...config,
            reminders: [...(config.reminders || []), reminder],
          },
        }));
        this.addButtonControl(
          this.state.data.config.reminders!.length - 1 || 0,
        );
        // Рендерим ноду когда ремайндер создался, тк нам надо отобразить ошибку
        this.state.save().finally(() => this.renderNode());
        // Рендерим ноду когда создается ремайндер
        this.renderNode();
      },
    });

    wrapper.addToLayout(addReminderButton);

    return wrapper;
  }

  private eventTypeTitleView() {
    const wrapper = new VLayout({
      height: 36,
      width: pluginWidth - wrapperMargin.x,
    });

    this.eventTypeTitle = new HTMLText({
      width: pluginWidth - wrapperMargin.x,
      text: i18n.t(
        'modernComponents.FlowBuilder.views.components.Calendly.selectEventType',
      ),
      fontSize: 15,
      trustedHtml: true,
    });

    wrapper.addToLayout(this.eventTypeTitle, {
      margin: new Margin({ y: 7 }),
    });

    return wrapper;
  }

  private eventTypeSectionView() {
    const wrapper = new VLayout();
    wrapper.TEST_NAME = 'EventTypeSectionView';

    this.eventTypeDropdown = new DropdownFieldView({
      caption: i18n.t(
        'modernComponents.FlowBuilder.views.components.Calendly.selectEventType',
      ),
      isValid: () => {
        const { validation_details } = this.state.data;

        if (!validation_details) {
          return true;
        }

        return !hasError(
          ValidationErrorTypes.emptyEvent,
          validation_details.fields,
        );
      },
      width: pluginWidth - wrapperMargin.x,
      singleLine: true,
      isEditing: (() => this.state.isEditing) as any,
    });

    this.eventTypeDropdown.on('click', (e: InteractionEvent) => {
      e.stopPropagation();

      logFlowPluginEvent(PluginType.calendly, 'event type dropdown click');

      const addNewEventType = {
        id: 'addNewEventType',
        title: `+ ${i18n.t(
          'modernComponents.FlowBuilder.views.components.Calendly.newEventType',
        )}`,
        type: 'button',
      };

      const menu = this.adminAccount?.event_types.map(({ name, uri }) => ({
        id: uri,
        title: name,
      }));

      new CreateMenuViewOverlay({
        onChoose: (item) => {
          if (item.id === addNewEventType.id) {
            this.goToCreateEventType();
            logFlowPluginEvent(
              PluginType.calendly,
              'add new event type click',
              {
                type: item,
              },
            );
            return;
          }

          logFlowPluginEvent(PluginType.calendly, 'change event type', {
            type: item,
          });

          this.state.set(({ config }) => ({
            config: { ...config, event_type_uri: item.id },
          }));
          this.state.save().finally(() => {
            this.renderNode();
            // Из-за особенностей работы validationProps приходится дергать эту функцию для перерасчета состояния алерта
            resByFunc(this._layoutProps.background?.strokeWidth);
          });
        },
        items: [addNewEventType, ...(menu || [])],
      }).showOn(e.data.global);
    });

    wrapper.addToLayout(this.eventTypeDropdown);

    return wrapper;
  }

  private async goToCreateEventType() {
    window.open(
      'https://calendly.com/event_types/new',
      '_blank',
      'noopener noreferrer',
    );
    await waitFoTheReturnToTab(WAIT_RETURN_TIMEOUT);
    this.setEventTypeLoaderState(true);
    await getCalendlyAccountsObservable(this.flow.botId).refetch();
    this.setEventTypeLoaderState(false);
  }

  private createReminderView(index: number) {
    const wrapper = new HLayout();
    wrapper.TEST_NAME = 'ReminderView';

    const delayInput = new DelayInput({
      label: i18n.t(
        'modernComponents.FlowBuilder.views.components.Calendly.resumeAfter',
      ),
      quantItems: QUANT_ITEMS_WITH_IMMEDIATELY(),
      initialValue: this.state.data.config.reminders?.[index]?.before_ms || 0,
      labelPosition: LabelPosition.end,
      getPlaceholderTitle: (_, quantId) =>
        quantId === DelayInput.QUANT_IDS.instantly
          ? ''
          : window.i18next.t('CalendlyIntegration-string-1237-before'),
      onChange: (value) => {
        logFlowPluginEvent(PluginType.calendly, 'change delay input', {
          value,
        });

        this.state.set(({ config }) => ({
          config: set(reminderMSLens(index), value, config),
        }));
        this.state.save();
      },
      editable: () => this.state.isEditing,
    });

    wrapper.addToLayout(
      new HLayout({ width: pluginWidth - STAT_VIEW_WIDTH - 40 }).addToLayout(
        delayInput,
      ),
      { margin: new Margin({ bottom: 8 }) },
    );

    const lineStartView = new LineStartView(
      {
        from: this,
        node: this._node,
        mandatory: true,
        items: createLineMenu({
          isShowCalendlySpecificItems: true,
        }),
        onConnected: this.onLineConnected(reminderBlockLens(index)),
        onRemoved: this.onLineRemoved(reminderBlockLens(index)),
      },
      view(reminderBlockLens(index), this.state.data.config),
    );

    this.remindersLines.push(lineStartView);

    const block = new HLayout();

    const reminderStats = getFullCounterStats(
      this.state.data.config.reminders?.[index]?.counter_id || '',
      this._node.block.id,
    );

    if (reminderStats) {
      block.addToLayout(
        new ItemStatView({
          stats: reminderStats,
          showPercentValue: true,
        }),
      );
    } else {
      block.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));
    }

    block.addToLayout(lineStartView, { margin: lineStartViewMargin });

    wrapper.addToLayout(block, {
      margin: new Margin({ top: 36 / 2 - STAT_VIEW_HEIGHT / 2 }),
    });

    return wrapper;
  }

  private createNote(text: string) {
    const wrapper = new HLayout({
      width: pluginWidth - wrapperMargin.x,
      background: {
        cornerRadius: 4,
        fill: HEXColors.mustardDark10,
      },
    });
    wrapper.TEST_NAME = 'Note';

    const textMargin = new Margin({ y: 16, x: 12 });
    wrapper.addToLayout(
      new HTMLText({
        width: pluginWidth - wrapperMargin.x - wrapperMargin.x,
        text,
        singleLine: false,
        align: 'left',
        fontSize: 15,
        fill: HEXColors.mustardDark40,
        trustedHtml: true,
      }),
      {
        margin: textMargin,
      },
    );

    return wrapper;
  }

  hasSignificantChangesInConfig() {
    return !isEqual(PLUGIN_DEFAULT_CONFIG, this.state.data.config);
  }

  onBeforeRender() {
    const { config, validation_details } = this.state.data;

    const text = this.adminAccount?.event_types.find(
      ({ uri }) => uri === config.event_type_uri,
    )?.name;
    if (text) {
      this.eventTypeTitle.text(cropHTMLText(text, 260));
    }

    const hasEventTypeError = validation_details?.fields.find(
      (error: ValidationErrors) =>
        mapErrorToType(error) === ValidationErrorTypes.emptyEvent,
    );
    this.eventTypeTitle.fill(
      hasEventTypeError ? HEXColors.red : HEXColors.black,
    );

    if (config.booked_block_id) {
      this._node.addOutLink(
        config.booked_block_id,
        this.userScheduledEventLine._lineView,
      );
    }
    if (config.rescheduled_block_id) {
      this._node.addOutLink(
        config.rescheduled_block_id,
        this.userRescheduledEventLine._lineView,
      );
    }
    if (config.cancelled_block_id) {
      this._node.addOutLink(
        config.cancelled_block_id,
        this.userCancelledEventLine._lineView,
      );
    }
    config.reminders?.forEach((reminder, index) => {
      if (reminder?.block_id) {
        this._node.addOutLink(
          reminder?.block_id,
          this.remindersLines[index]._lineView,
        );
      }
    });

    if (this.eventType) {
      this.eventTypeDropdown?.text(this.eventType.name);
      this.eventTitle?.text(this.eventType.name);
    }
  }

  private get adminAccount() {
    return this.connectedAccounts.find(
      ({ id }) => this.state.data.config.account_id === id,
    );
  }

  private get eventType() {
    const { event_type_uri } = this.state.data.config;

    if (!event_type_uri || !this.adminAccount?.event_types.length) {
      return null;
    }

    const eventType = find(
      propEq('uri', event_type_uri),
      this.adminAccount?.event_types,
    );

    return eventType;
  }

  private onLineConnected(lens: Lens) {
    return (blockView: BlockView) => {
      this.state.set(({ config }) => {
        const result = {
          config: set(lens, blockView.id(), config),
        };
        return result;
      });
      this.state.save().finally(() => this.renderNode());
    };
  }

  private onLineRemoved(lens: Lens) {
    return () => {
      this.state.set(({ config }) => ({
        config: set(lens, null, config),
      }));
      this.state.save().finally(() => this.renderNode());
    };
  }

  pluginDidSaveError(error: any) {
    log({
      error,
      msg: 'Could not save plugin',
      level: Level.error,
      data: {
        label: 'calendly',
        requestId: getRequestIdFromApolloError(error),
      },
    });

    toaster.error({
      payload: {
        message: Messages.somethingWentWrong,
      },
    });
  }

  validationError() {
    const { validation_details } = this.state.data;

    if (!validation_details) {
      return undefined;
    }

    const message = getErrorMessage(validation_details.fields);

    if (!message) {
      log({
        msg: 'Error message was not found',
        level: Level.error,
      });

      return undefined;
    }

    return i18n.t(message);
  }

  destroy() {
    this.connectedAccountsSubscription?.unsubscribe();
    this.calendlyIntegrationSubscription?.unsubscribe();

    this.tooltips.forEach(removeTooltip);
    this.tooltips = [];

    this.state.destroy();

    super.destroy();
  }
}
