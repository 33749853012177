import noop from 'lodash-es/noop';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  instagramAdsEntryPointFragment_config_campaigns,
  instagramAdsEntryPointFragment_config_campaigns_ads,
} from '@components/Plugins/InstagramAdsEntryPoint/@types/instagramAdsEntryPointFragment';
import { remove } from 'ramda';
import { Platform } from '@globals';
import {
  facebookAdsEntryPointFragment_config as FacebookAdsEntryPointConfig,
  facebookAdsEntryPointFragment_config_campaigns,
  facebookAdsEntryPointFragment_config_campaigns_ads,
} from '../../../../../../Plugins/FacebookAdsEntryPoint/@types/facebookAdsEntryPointFragment';
import { StatefulPlugin } from '../../../../../StatefulPlugin';
import {
  showChooseAdsPopup,
  showPermissionPopup,
} from '../../../../../components';
import { ValidationError } from '../../utils/types';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import {
  getPixiFieldStrict,
  getFlowControllerStrict,
} from '../../../../../PixiFieldRepository';
import { getPageInfoFromNode } from '../../../../utils';
import { BLOCK_SUBTYPES } from '../../../../../consts';
import { VLayout } from '../../../../../components/Elements/Layouts';
import { Node } from '../../../../../Node';
import { CampaignItem } from '../CampaignItem';
import { getEntryPointCard, getEntryPointCardView } from '../../utils';
import { showConnectPageDialog } from '../../utils/showConnectPageDialog';
import { invalidStrokeColor, pluginWidth } from '../../../../plugin_consts';
import { PlusButtonView } from '../../../../plus_button_view';
import { EntryPointFacebookAds } from '../../../EntryPointFacebookAds';
import { InstagramAdsEntryPointConfig } from '../../../../../../Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { EntryPointInstagramAds } from '../../../EntryPointInstagramAds';
import { HEXColors } from '@ui/_common/colors';

export class AdsSelector extends VLayout {
  private editAdsButton: PlusButtonView | undefined;
  private chooseAdsButton: PlusButtonView;

  private campaignsBox: VLayout;

  private state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;

  private node: Node;

  platform: Platform.instagram | Platform.facebook;

  constructor(
    state: StatefulPlugin<
      FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
    >,
    node: Node,
    platform: Platform.facebook | Platform.instagram,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
        stroke: () =>
          state.data.config.campaigns.length > 0
            ? HEXColors.white
            : invalidStrokeColor,
        strokeWidth: () => (state.data.config.campaigns.length > 0 ? 1 : 2),
      },
      cursor: {
        in: 'default',
      },
    });
    this.node = node;
    this.state = state;
    this.platform = platform;

    this.campaignsBox = new VLayout({
      width: pluginWidth - 32,
    });

    this.addToLayout(this.campaignsBox, {
      margin: { top: 16, left: 16 },
    });

    const isViewOnly = getPixiFieldStrict().isViewOnly();

    if (!isViewOnly) {
      this.editAdsButton = new PlusButtonView(
        window.i18next.t('AdsSelector-string-1665-edit-ads'),
        pluginWidth - 32,
      );
      this.editAdsButton?.on('pointerdown', (event: Event) => {
        event.stopPropagation();
      });
      this.editAdsButton?.on('click', (event: Event) => {
        event.stopPropagation();
        this.showDialog();
        logFlowPluginEvent(state.data.plugin_id, 'Edit ads', {
          blockId: node.id,
          cardId: state?.data.id,
        });
      });
      this.addToLayout(this.editAdsButton, {
        margin: {
          left: 16,
          right: 16,
          bottom: 8,
          top: 8,
        },
        gone: () => state.data.config.campaigns.length === 0,
      });
    }

    this.chooseAdsButton = new PlusButtonView(
      window.i18next.t('AdsSelector-string--279-choose-ads'),
      pluginWidth - 32,
    );

    if (!isViewOnly) {
      this.chooseAdsButton.on('pointerdown', (event: Event) => {
        event.stopPropagation();
      });
      this.chooseAdsButton.on('click', (event: Event) => {
        event.stopPropagation();
        this.showDialog();
        logFlowPluginEvent(state.data.plugin_id, 'Choose ads', {
          blockId: node.id,
          cardId: state.data?.id,
        });
      });
    }

    this.chooseAdsButton.setDisabled(isViewOnly);

    this.addToLayout(this.chooseAdsButton, {
      margin: {
        left: 16,
        right: 16,
        bottom: 16,
      },
      gone: () => state.data.config.campaigns.length !== 0,
    });

    this.createCampaignList();
  }

  showDialog() {
    const shouldShowPermissionDialog = Boolean(
      // Currently node.blockView have incorrect type "BlockView" but it should have type
      // "BlockView | CommentsAutoreplyContentBlockView | FacebookAdsEntryPointBlockView"
      // because of that blockView doesn't have entryPointCardView. Fix when block_view.js
      // rewrite to typescript
      //  @ts-ignore
      this.node.blockView.entryPointCardView.validationError()?.type ===
        ValidationError.permission,
    );

    const pageExists = getPageInfoFromNode(this.node);
    const entryPointCard = getEntryPointCard(this.node.block.cards);

    if (shouldShowPermissionDialog) {
      showPermissionPopup({
        pluginId: this.state.data.plugin_id as PluginType,
        onDismiss: noop,
      });
    } else if (!pageExists && entryPointCard) {
      showConnectPageDialog(this.node, entryPointCard);
    } else {
      this.showChooseAdsDialog();
    }
  }

  createCampaignList() {
    this.campaignsBox._views.forEach(({ view }) => view.destroy());
    this.campaignsBox._views.length = 0;
    this.state.data.config.campaigns.forEach(
      (
        campaign:
          | facebookAdsEntryPointFragment_config_campaigns
          | instagramAdsEntryPointFragment_config_campaigns,
        index: number,
      ) => {
        this.createCampaignView(campaign, index);
      },
    );
  }

  createCampaignView(config: any, index: number) {
    const campaignItem = new CampaignItem({
      config,
      node: this.node,
      state: this.state,
      campaignParams: {
        index,
        updateParentLayout: () => {
          this.createCampaignList();
          this.renderNode();
        },
      },
    });
    this.campaignsBox.addToLayout(campaignItem);
  }

  public showChooseAdsDialog() {
    showChooseAdsPopup({
      pluginId: this.state.data.plugin_id as PluginType,
      onSubmit: (checkedAds) => {
        const previousAds = (this.state.data.config.campaigns as any).reduce(
          (
            acc: any,
            campaign:
              | facebookAdsEntryPointFragment_config_campaigns
              | instagramAdsEntryPointFragment_config_campaigns,
          ) => {
            campaign.ads!.forEach(
              (
                ad:
                  | facebookAdsEntryPointFragment_config_campaigns_ads
                  | instagramAdsEntryPointFragment_config_campaigns_ads,
              ) => {
                acc.ads[ad.id] = { ...ad };
                acc.campaigns[campaign.id] = campaign.id;
                return acc;
              },
            );
            return acc;
          },
          { ads: {}, campaigns: {} },
        );

        const updatedCampaigns = (checkedAds || []).reduce(
          (acc: any, campaign, i: number) => {
            const newCampaign = {
              __typename:
                this.platform === Platform.facebook
                  ? 'EntryPointFacebookAdCampaign'
                  : 'EntryPointInstagramAdCampaign',
              id: campaign.id,
              name: campaign.name,
              ads: campaign.ads.map((ad) => {
                if (previousAds.ads[ad.id]) {
                  return {
                    __typename:
                      this.platform === Platform.facebook
                        ? 'EntryPointFacebookAd'
                        : 'EntryPointInstagramAd',
                    id: ad.id,
                    preview_image_small: ad.preview_image_small,
                    type: ad.type,
                    name: ad.name,
                    sync: false,
                    last_synced_date: previousAds.ads[ad.id].last_synced_date,
                    campaign_id: ad.campaign_id,
                    account_id: ad.account_id,
                    call_to_action_type: ad.call_to_action_type,
                  };
                }

                return {
                  __typename:
                    this.platform === Platform.facebook
                      ? 'EntryPointFacebookAd'
                      : 'EntryPointInstagramAd',
                  id: ad.id,
                  preview_image_small: ad.preview_image_small,
                  type: ad.type,
                  name: ad.name,
                  sync: false,
                  campaign_id: ad.campaign_id,
                  account_id: ad.account_id,
                  last_synced_date: null,
                  call_to_action_type: ad.call_to_action_type,
                };
              }),
            };
            acc[i] = newCampaign;
            return acc;
          },
          [],
        );
        this.state.set(({ config }) => ({
          config: { ...config, campaigns: updatedCampaigns },
        }));
        this.state.save();
        this.createCampaignList();
        this.renderNode();
      },
      platform: this.platform,
      onDismiss: noop,
      checkedAds: this.state.data.config.campaigns as any,
      onErrorSubmit: (deletedAd) => {
        getFlowControllerStrict()
          .allNodes()
          .forEach(({ blockView, block }) => {
            if (!block || block.subtype !== BLOCK_SUBTYPES.entrypoint) {
              return;
            }

            const entryPointCardView = getEntryPointCardView(blockView) as
              | EntryPointFacebookAds
              | EntryPointInstagramAds;

            let deletedAdIndex: number | undefined;
            let deletedCampaignIndex: number | undefined;
            entryPointCardView.state.data?.config.campaigns?.forEach(
              (
                campaign:
                  | facebookAdsEntryPointFragment_config_campaigns
                  | instagramAdsEntryPointFragment_config_campaigns,
                campaignIndex: number,
              ) => {
                if (campaign.ads) {
                  campaign.ads.forEach(
                    (
                      ad:
                        | facebookAdsEntryPointFragment_config_campaigns_ads
                        | instagramAdsEntryPointFragment_config_campaigns_ads,
                      adIndex: number,
                    ) => {
                      if (ad.id === deletedAd.id) {
                        deletedAdIndex = adIndex;
                        deletedCampaignIndex = campaignIndex;
                      }
                    },
                  );
                }
              },
            );

            if (
              deletedAdIndex !== undefined &&
              deletedCampaignIndex !== undefined
            ) {
              entryPointCardView.state.set(({ config }: any) => {
                // eslint-disable-next-line no-param-reassign
                config.campaigns[deletedCampaignIndex!].ads = remove(
                  deletedAdIndex!,
                  1,
                  config.campaigns[deletedCampaignIndex!].ads,
                );

                if (config.campaigns[deletedCampaignIndex!].ads!.length === 0) {
                  // eslint-disable-next-line no-param-reassign
                  config.campaigns = remove(
                    deletedCampaignIndex!,
                    1,
                    config.campaigns,
                  );
                }

                return { config };
              });
              entryPointCardView.state.save();

              entryPointCardView.adsSelector.createCampaignList();
              blockView.renderNode();
            }
          });
      },
    });
  }
}
