import { sendEvent } from '@utils/Analytics';
import { ShopifyEventConfigInput, ShopifyEventType } from '@globals';
import { isShortcut, PluginType } from '../../Plugins/common/PluginTypes';
import { Card } from '../types';
import { getFlowController, getPixiField } from '../PixiFieldRepository';
import { ShopifyEventDelayView } from '../views/entry-points/EntryPointShopifyEvent/components/eventViews/components/ShopifyEventDelayView';
import { getShortcutPluginDefaultData } from '../../Plugins/common/getShortcutPluginDefaultData';

export function sendFlowBuilderEvent({
  category,
  action,
  label,
  propertyBag = {},
}: PureAnalyticsEvent) {
  const pixiFieldInstance = getPixiField();
  if (!pixiFieldInstance || pixiFieldInstance.isViewOnly()) {
    return;
  }

  const propertyBagCopy = Object.assign({}, propertyBag);
  const controllerInstance = getFlowController();
  if (controllerInstance) {
    propertyBagCopy.flowId = controllerInstance.flow.id;
  }
  sendEvent({ category, action, label, propertyBag: propertyBagCopy });
}

export function logFlowEvent(
  label: string | undefined,
  action: string,
  propertyBag?: Object,
) {
  sendFlowBuilderEvent({
    category: 'flows',
    action,
    label,
    propertyBag,
  });
}

export function logFlowPluginEvent(
  pluginId: string,
  action: string,
  propertyBag?: Object,
) {
  sendFlowBuilderEvent({
    category: 'flows plugin',
    action,
    label: pluginId,
    propertyBag,
  });
}

export class PluginLogger {
  constructor(
    private readonly pluginId: PluginType,
    private readonly blockId: string,
    private readonly cardId: string,
  ) {}

  public log(msg: string, propertyBag?: Object) {
    logFlowPluginEvent(this.pluginId, msg, {
      blockId: this.blockId,
      cardId: this.cardId,
      ...(propertyBag || {}),
    });
  }
}

export const getAdditionalPropertyBagFieldByEntryPointId = (
  entryPointId: string | undefined,
) => {
  switch (entryPointId) {
    case PluginType.shopify_event_entry_point:
      return {
        shopifyEventType: ShopifyEventType.abandoned_cart,
      };
    case PluginType.order_confirmation_shortcut:
      return {
        entryPointType: PluginType.shopify_event_entry_point,
        shopifyEventType: ShopifyEventType.order_confirmation,
      };
    case PluginType.product_visit_shortcut:
      return {
        entryPointType: PluginType.shopify_event_entry_point,
        shopifyEventType: ShopifyEventType.product_visit,
      };
    case PluginType.order_status_update_shortcut:
      return {
        entryPointType: PluginType.shopify_event_entry_point,
        shopifyEventType: ShopifyEventType.order_status_update,
      };
    default:
      return {};
  }
};

export const getAdditionalPropertyBagField = (
  card: Card | undefined,
  fromView?: unknown,
) => {
  if (!card) {
    return {};
  }
  switch (card.plugin_id) {
    case PluginType.shopify_event_entry_point:
      return {
        shopifyEventType:
          (fromView as ShopifyEventDelayView<{}>)?.eventType ||
          card.config.events
            ?.map(({ type }: ShopifyEventConfigInput) => type)
            .join(','),
      };
    case PluginType.order_confirmation_shortcut:
      return {
        entryPointType: PluginType.shopify_event_entry_point,
        shopifyEventType: ShopifyEventType.order_confirmation,
      };
    case PluginType.product_visit_shortcut:
      return {
        entryPointType: PluginType.shopify_event_entry_point,
        shopifyEventType: ShopifyEventType.product_visit,
      };
    default:
      return {};
  }
};

export const getRealPluginId = (pluginType: string) =>
  isShortcut(pluginType as PluginType)
    ? getShortcutPluginDefaultData(pluginType)?.pluginType
    : pluginType;
