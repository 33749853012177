import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { BLOCK_SUBTYPES } from '../../../consts';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { getEntryPointCard } from '../common/utils';

export const isFlowHasShopifyEventEntryPoint = () => {
  const { _nodesMap } = getFlowControllerStrict();
  return Object.values(_nodesMap).some(
    ({ block }) =>
      block.subtype === BLOCK_SUBTYPES.entrypoint &&
      [
        PluginType.shopify_customer_chat_entry_point,
        PluginType.popup_entry_point,
        PluginType.shopify_event_entry_point,
      ].includes(getEntryPointCard(block.cards)?.plugin_id as PluginType),
  );
};
