import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import React, { useEffect, useState } from 'react';
import { CenteredLoader } from '@ui/Loader';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginType } from '../../../../../../../Plugins/common/PluginTypes';
import { getI18ZapierKey } from '../../../utils/getI18ZapierKey';
import * as css from './ZapEditor.css';

interface ZapEditorProps {
  zapUrl: string;
  onZapStateChange: () => void;
  cardId?: string;
  pluginId?: string;
  zapTitle?: string;
  blockId?: string;
}

enum ZapierEvents {
  zapUnpauseDone = 'zap:unpause:done',
  zapPauseDone = 'zap:pause:done',
}

const INSTRUCTIONS: Record<string, string> = {
  [PluginType.external_integration_entry_point]: 'entryPointDialogInstructions',
  [PluginType.event_trigger]: 'sendDataDialogInstructions',
  [PluginType.integration_zapier_plugin]: 'importContentDialogInstructions',
};

export const ZapEditor: React.FC<ZapEditorProps> = ({
  zapUrl,
  onZapStateChange,
  cardId = '',
  pluginId = '',
  zapTitle,
  blockId,
}) => {
  const [editorLoading, setEditorLoading] = useState<boolean>(false);
  const { t } = useSafeTranslation();

  useEffect(() => {
    const postMessageHandler = ({ data }: MessageEvent) => {
      if (
        [ZapierEvents.zapUnpauseDone, ZapierEvents.zapPauseDone].includes(
          data?.event,
        )
      ) {
        onZapStateChange?.();
        sendEvent({
          category: 'zapier integration dialog',
          action: 'Zap state change',
          label: pluginId,
          propertyBag: {
            blockId,
            cardId,
            enabled: ZapierEvents.zapUnpauseDone === data?.event,
            templateName: zapTitle,
          },
        });
      }
    };
    window.addEventListener('message', postMessageHandler);
    return () => window.removeEventListener('message', postMessageHandler);
  }, [onZapStateChange, pluginId, zapTitle, cardId, blockId]);

  useEffect(() => {
    setEditorLoading(true);
  }, [zapUrl]);

  return (
    <div className={css.box}>
      {editorLoading && <CenteredLoader className={css.loader} />}
      <Type size="15px_DEPRECATED" as="div">
        {t(getI18ZapierKey(INSTRUCTIONS[pluginId]), { cardId, blockId })}
      </Type>
      <Spacer factor={7} />
      <iframe
        className={css.zapierIframe}
        style={{ height: 516 }}
        src={zapUrl}
        title="zap"
        onLoad={() => {
          setEditorLoading(false);
        }}
      />
    </div>
  );
};
