import { Card } from '../../../types';
import { IceBreaker } from '../common/components/FAQ';

export enum TypeIds {
  ice_breakers = 'ice_breakers',
  get_started = 'get_started',
}
export interface FacebookPageConfig {
  block_id?: string; // for get started button
  ice_breakers?: IceBreaker[];
  fill_ib_with_fb_data?: boolean;
  type: TypeIds;
}

export type EntryPointFacebookPageCard = Card<FacebookPageConfig>;
