import { Position } from '@ui/Tooltip2';
import { stripTags } from '@utils/stripTags';
import { UnsubscribeFn } from '@utils/EventEmitter';
import { View } from '../../components/Elements/Layouts/types';
import { logFlowEvent, logFlowPluginEvent } from '../../utils/Analytics';
import {
  SingleView,
  SingleViewCallbackParams,
  SingleViewSubscriber,
} from '../SingleView';
import { getPixiField, getPixiFieldStrict } from '../../PixiFieldRepository';
import { findParentByCallback } from '../utils';
import {
  FlowBuilderOverlayEvent,
  OverlayComponentEvent,
  overlayEventEmitter,
  OverlayType,
} from '../../FlowBuilderOverlay';
import { prepareTextForEvent } from '../../../../utils/Analytics';

const isNeedPreventTooltip = (currentOverlayType?: OverlayType) =>
  currentOverlayType && currentOverlayType !== OverlayType.tooltip;

export function tooltip(
  view?: View,
  text?: string | (() => string),
  url?: string,
  scaleFunc?: () => number,
  onClick?: () => void,
  overTimeout?: number,
  children?: React.ReactNode,
  pluginId?: string,
  position?: Position,
): SingleView | undefined {
  if (view) {
    const unsubscribers: UnsubscribeFn[] = [];
    const getTextToShow = () =>
      typeof text === 'function' ? text() : text ?? '';
    const getOverlayOptions = () => ({
      get text() {
        return getTextToShow();
      },
      children,
      url,
      height: view.height(),
      width: view.width(),
      onClick,
      position,
    });
    const remove = ({ currentOverlayType }: SingleViewCallbackParams) => {
      if (unsubscribers.length) {
        unsubscribers.forEach((unsubscriber) => unsubscriber());
        if (!currentOverlayType || !isNeedPreventTooltip(currentOverlayType)) {
          overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
        }
      }
    };
    const subscriber: SingleViewSubscriber = {
      view,
      onShow: ({ currentOverlayType }: SingleViewCallbackParams): undefined => {
        const textToShow = getTextToShow();
        if (!currentOverlayType && pluginId) {
          logFlowEvent('entry_point', 'show error tooltip', {
            extraPluginId: pluginId,
            tooltipText: stripTags(textToShow),
          });
        }

        if (isNeedPreventTooltip(currentOverlayType)) {
          return;
        }
        overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
        overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
          overlayType: OverlayType.tooltip,
          getOverlayOptions,
        });
        const { x, y } = view.globalPosition();
        const scale = scaleFunc ? scaleFunc() : 1;
        overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
          position: {
            x,
            y,
            scale,
          },
        });

        if (unsubscribers.length) {
          unsubscribers.forEach((unsubscriber) => unsubscriber());
        }

        unsubscribers.push(
          ...[
            overlayEventEmitter.on(OverlayComponentEvent.mouseEnter, () => {
              getPixiField()?.tooltipView._overFunc(subscriber, overTimeout)();
            }),
            overlayEventEmitter.on(OverlayComponentEvent.mouseLeave, () => {
              getPixiField()?.tooltipView.onOut()();
            }),
            overlayEventEmitter.on(
              OverlayComponentEvent.onClick,
              (params?: { linkText: string; href: string }) => {
                const parentView = findParentByCallback(
                  view,
                  (view: View) => view && '_card' in view,
                );

                if (!parentView) {
                  console.error(
                    'Could not log an event. The card was not found',
                  );
                  return;
                }

                const pluginId = parentView?._card?.plugin_id;
                const sendEventFunc = pluginId
                  ? logFlowPluginEvent
                  : logFlowEvent;

                sendEventFunc(pluginId, 'tooltip click', {
                  text: prepareTextForEvent(textToShow),
                  url: params?.href ?? url,
                  linkText:
                    params?.linkText && prepareTextForEvent(params?.linkText),
                });
              },
            ),
          ],
        );
      },
      onHide: remove,
      onMove: remove,
    };
    getPixiField()?.tooltipView.subscribe(subscriber, overTimeout);
  }
  return getPixiField()?.tooltipView;
}

export interface TooltipConfig {
  view?: View;
  text?: string | (() => string);
  url?: string;
  onClick?: () => void;
  overTimeout?: number;
  children?: React.ReactNode;
  pluginId?: string;
  position?: Position;
}

export function tooltipScaled(params: TooltipConfig): SingleView | undefined {
  return tooltip(
    params.view,
    params.text,
    params.url,
    () => getPixiFieldStrict().viewport.scale.x,
    params.onClick,
    params.overTimeout,
    params.children,
    params.pluginId,
    params.position,
  );
}

export function hideTooltip(): void {
  const tooltipView = getPixiField()?.tooltipView;
  if (!tooltipView) {
    return;
  }
  tooltipView._currentSubscriber?.onHide?.({
    currentOverlayType: tooltipView._currentOverlayType,
  });
}

export function unsubscribeTooltipFromView(view: View): void {
  getPixiField()?.tooltipView.unsubscribe(view);
}

export function removeTooltip(view: View): void {
  if (getPixiFieldStrict().tooltipView.lock) {
    return;
  }
  hideTooltip();
  unsubscribeTooltipFromView(view);
}

export const showTooltipForView = (view: View) => {
  getPixiFieldStrict()
    .hoverHandler()
    .getEventsForView(view)
    .get('tooltip_view')
    .over();
};

export const getLockTooltipState = () => getPixiFieldStrict().tooltipView.lock;

export const setLockTooltipState = (lock: boolean) => {
  getPixiFieldStrict().tooltipView.lock = lock;
};
