import { HEXColors } from '@ui/_common/colors';
import { MainLayout } from '../components/Elements/Layouts';
import { HTMLText } from '../components/Elements/Shapes';
import { Node } from '../Node';
import { Card } from '../types';
import { pluginWidth, textCardBackgroundColor } from './plugin_consts';

export class UnknownPluginView extends MainLayout {
  TEST_NAME = 'UnknownPluginView';

  _card: Card;
  _node: Node;

  constructor(card: Card, node: Node) {
    super({
      width: pluginWidth,
      height: 50,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this.layout(
      new HTMLText({
        text: card.plugin_id,
        fontSize: 18,
        fill: HEXColors.black,
        align: 'center',
        verticalAlign: 'center',
        width: pluginWidth,
        height: 50,
      }),
    );
    this._card = card;
    this._node = node;
  }
}
