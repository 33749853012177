import { PluginType } from '@components/Plugins/common/PluginTypes';
import { removeTypename } from '@utils/GQL/utils';
import { Card } from '../../../../types';
import { PLUGIN_DEFAULT_CONFIG as PRIVATE_REPLY_DEFAULT_CONFIG } from '../../../../../Plugins/PrivateReplyEntryPoint/PrivateReplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as PUBLIC_REPLY_DEFAULT_CONFIG } from '../../../../../Plugins/PublicReplyEntryPoint/PublicReplyEntryPointConst';
import { PLUGIN_DEFAULT_CONFIG as QUICK_REPLY_DEFAULT_CONFIG } from '../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import { PLUGIN_DEFAULT_CONFIG as COMMENTS_LIKE_DEFAULT_CONFIG } from '../../../../../Plugins/CommentsLikePlugin/CommentsLikePluginConst';

export const DEFAULT_CONTENT_CARDS: Partial<Card>[] = [
  {
    plugin_id: PluginType.comments_like,
    config: removeTypename(COMMENTS_LIKE_DEFAULT_CONFIG),
  },
  {
    plugin_id: PluginType.public_reply_entry_point,
    config: removeTypename(PUBLIC_REPLY_DEFAULT_CONFIG),
  },
  {
    plugin_id: PluginType.private_reply_entry_point,
    config: removeTypename(PRIVATE_REPLY_DEFAULT_CONFIG),
  },
  {
    plugin_id: PluginType.text,
    config: {
      text: '',
    },
  },
  {
    plugin_id: PluginType.quick_reply,
    config: removeTypename(QUICK_REPLY_DEFAULT_CONFIG),
  },
];
