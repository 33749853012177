import audioTextureSvg from './images/audio.svg';
import videoTextureSvg from './images/video.svg';
import fileTextureSvg from './images/file.svg';
import { FileAttachmentType } from '@utils/UploadService/types';

export const FILE_ICONS: Record<FileAttachmentType, string> = {
  [FileAttachmentType.audio]: audioTextureSvg,
  [FileAttachmentType.video]: videoTextureSvg,
  [FileAttachmentType.file]: fileTextureSvg,
  [FileAttachmentType.image]: fileTextureSvg,
  [FileAttachmentType.pdf]: fileTextureSvg,
  [FileAttachmentType.mp4]: videoTextureSvg,
};

export const ATTACHMENT_PLUGIN_SIZE = {
  width: 300,
  height: 52,
};

export const ICON_SIZE = {
  width: 32,
  height: 32,
};
