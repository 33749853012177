import gql from 'graphql-tag';

export { zapFragment as ZapierZap } from './@types/zapFragment';

export const ZAP_FRAGMENT = gql`
  fragment zapFragment on ZapierZap {
    id
    title
    url
    state
    steps {
      type_of
      app {
        id
        title
        description
        url
        image
        hex_color
        slug
      }
    }
    owner {
      id
      picture
      name
    }
  }
`;
