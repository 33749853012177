import gql from 'graphql-tag';

const FACEBOOK_SHOP_PRODUCT_FRAGMENT = gql`
  fragment facebookShopProductFragment on FacebookShopProduct {
    id
    name
    description
    category
    fb_product_category
    image_url
    color
    material
    size
    price
    currency
    retailer_product_group_id
    url
    flow_id
    flow_title
    entry_point_id
  }
`;

const FACEBOOK_SHOP_PRODUCT_GROUP_CONFLICT_FRAGMENT = gql`
  fragment facebookShopProductGroupConflictFragment on FacebookShopProductGroupConflict {
    id
    entry_point_id
    flow_id
    flow_title
  }
`;

export const FACEBOOK_SHOP_PRODUCTS_QUERY = gql`
  query FacebookShopProductsQuery(
    $pageId: String!
    $catalogueId: String!
    $after: String
    $query: String
  ) {
    page(pageId: $pageId) {
      id
      facebookShopCatalogueProducts(
        catalogueId: $catalogueId
        after: $after
        query: $query
      ) @connection(key: "facebookShopCatalogueProducts") {
        after
        products {
          ...facebookShopProductFragment
        }
        group_conflicts {
          ...facebookShopProductGroupConflictFragment
        }
      }
    }
  }
  ${FACEBOOK_SHOP_PRODUCT_FRAGMENT}
  ${FACEBOOK_SHOP_PRODUCT_GROUP_CONFLICT_FRAGMENT}
`;

export const FACEBOOK_SHOP_CATALOGUES_QUERY = gql`
  query FacebookShopCataloguesQuery($pageId: String!) {
    page(pageId: $pageId) {
      id
      facebookShopCatalogues {
        catalogues {
          id
          name
          product_count
          default_image_url
          fallback_image_url
        }
      }
    }
  }
`;

export const REMOVE_PRODUCT_FROM_ENTRY_POINTS_MUTATION = gql`
  mutation RemoveProductFromEntryPointsMutation(
    $pageId: String!
    $productId: String
    $productGroupId: String
  ) {
    removeProductFromEntryPoints(
      pageId: $pageId
      productId: $productId
      productGroupId: $productGroupId
    ) {
      product {
        id
        flow_id
        flow_title
        entry_point_id
      }
      group_conflict {
        ...facebookShopProductGroupConflictFragment
      }
    }
  }
  ${FACEBOOK_SHOP_PRODUCT_GROUP_CONFLICT_FRAGMENT}
`;
