import { HEXColors } from '@ui/_common/colors';
import { mainStrokeColor } from './plugin_consts';
import { VLayout } from '../components/Elements/Layouts';
import { HTMLText } from '../components/Elements/Shapes';
import { getPixiFieldStrict } from '../PixiFieldRepository';

export interface QRPlusButtonViewProps {
  title?: string;
  disabled?: boolean;
}

export class QRPlusButtonView extends VLayout {
  TEST_NAME = 'QRPlusButtonView';

  public textView;
  props: QRPlusButtonViewProps;

  constructor(props: QRPlusButtonViewProps) {
    super({
      width: 200,
      background: {
        cornerRadius: 17,
        fill: HEXColors.white,
        strokeWidth: 0,
        onhover: !props.disabled
          ? {
              stroke: mainStrokeColor,
              strokeWidth: 2,
            }
          : undefined,
      },
    });
    this.props = props;

    this.textView = new HTMLText({
      text:
        props.title ??
        window.i18next.t('qr_plus_button_view-string-1861-add-quick-reply'),
      fontSize: 15,
      height: 35,
      fill: props.disabled ? HEXColors.greyDark : HEXColors.black,
      align: 'center',
      verticalAlign: 'center',
      width: 200,
    });

    super.layout(this.textView);

    getPixiFieldStrict()
      .cursorHandler()
      .createCursor(this, 'pointer', 'grab');
  }

  update(newProps: QRPlusButtonViewProps) {
    this.props = newProps;

    if (this.props.title) {
      this.textView.text(this.props.title);
    }
  }
}
