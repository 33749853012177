import { whatsappWidgetEntryPointFragment_config as WhatsappWidgetEntryPointConfig } from '../../../../Plugins/WhatsappWidgetEntryPoint/@types/whatsappWidgetEntryPointFragment';
import { MainLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Node } from '../../../Node';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { CARD_WIDTH } from '../../components/ProductCardView/ProductCardView';
/* eslint-disable import/no-webpack-loader-syntax,import/no-unresolved */
import cfLogo from '!!raw-loader!./images/cf-logo.svg';
import whatsappLogo from '!!raw-loader!./images/whatsapp-logo.svg';
import close from '!!raw-loader!./images/close.svg';
/* eslint-enable import/no-webpack-loader-syntax,import/no-unresolved */
import i18next from 'i18next';
import { WhatsappValidator } from '../common/WhatsappValidator';
import { getWhatsappSettingsObservable } from '@utils/Data/Whatsapp/getWhatsappSettingsObservable';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { Subscription } from 'rxjs';
import { equals } from 'ramda';
import { removeTypename } from '@utils/GQL/utils';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../Plugins/WhatsappWidgetEntryPoint/WhatsappWidgetEntryPointConst';
import { logFlowEvent } from '@components/FlowBuilder/utils/Analytics';
import { editPluginInEditorPanel } from '@components/FlowBuilder/EditorPanel/events';
import { getPanelWidth } from '@components/FlowBuilder/EditorPanel/utils/panelDimensions';
import { handleConflictError } from '../common/handleConflictError';
import { ApolloError } from 'apollo-client';
import { escape } from '@utils/escape';

export class EntryPointWhatsappWidget
  extends MainLayout
  implements StatefulPluginDelegate<WhatsappWidgetEntryPointConfig>
{
  TEST_NAME = 'EntryPointWhatsappWidget';

  public readonly state: StatefulPlugin<WhatsappWidgetEntryPointConfig>;
  public readonly _node: Node;
  private readonly htmlBox: HTMLText;
  private readonly whatsappSettingsSubscription: Subscription;
  public isWhatsappConnected: boolean | undefined;
  private readonly whatsappValidator: WhatsappValidator;
  private prevText: string | undefined;

  constructor(
    state: StatefulPlugin<WhatsappWidgetEntryPointConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.whatsappValidator = new WhatsappValidator(this);

    this.htmlBox = new HTMLText({
      fontSize: 15,
      width: CARD_WIDTH,
      trustedHtml: true,
    });
    this.updateHtml();

    this.addToLayout(this.htmlBox);

    this.whatsappSettingsSubscription = getWhatsappSettingsObservable(
      getFlowControllerStrict().flow.botId,
    ).subscribe(({ isWhatsappConnected }) => {
      this.isWhatsappConnected = isWhatsappConnected;
      this.renderNode();
    });

    this.on('click', () => {
      logFlowEvent('entry_point', 'open whatsapp widget settings');
      getPixiFieldStrict().fixBlockPosition(node.blockView, getPanelWidth());
      editPluginInEditorPanel(node.id, this.state.data, state);
    });
  }

  onBeforeRender() {
    this.updateHtml();
  }

  updateHtml() {
    const text = this.state.data.config.welcomeMessage;
    if (this.prevText === text) {
      return;
    }
    this.prevText = text || '';
    /* eslint-disable chatfuel/no-use-hex-colors */
    this.htmlBox.text(`
    <div style="position: relative;display: flex; align-items: center;justify-content: center;width: ${CARD_WIDTH}px;margin: -20px 0;">
        <div style="display: flex;align-items: flex-end;gap: 10px;">
            ${
              text
                ? `<div style="display: flex;flex-direction: column;padding: 8px 12px 12px;width: 180px;background-color: #D0FFF0;border: 1px solid #7BF6CF;box-shadow: 0 4px 10px rgba(21, 36, 66, 0.12);border-radius: 4px;">
                    <div style="font-weight: 400;font-size: 15px;line-height: 20px;color: #152442;margin-bottom: 12px;word-break: break-word;">${escape(
                      text,
                    )}</div>
                    <div style="display: flex;align-items: center;gap: 4px;">
                        ${cfLogo}
                        <div style="font-weight: 400;font-size: 12px;color: #0EBC83;white-space: nowrap;">
                           ${i18next.t(
                             'modernComponents.FlowBuilder.views.entryPoints.WhatsappWidgetEntryPoint.label',
                           )}
                        </div>
                    </div>
                </div>`
                : ''
            }
            <div style="display: flex;">
                ${whatsappLogo}
            </div>
        </div>
        ${
          text
            ? `<div style="position: absolute;top: -12px;left: 4px;">${close}</div>`
            : ''
        }
    </div>
    `);
    /* eslint-enable chatfuel/no-use-hex-colors */
  }

  hasSignificantChangesInConfig() {
    return !equals(
      removeTypename(this.state.data.config),
      removeTypename(PLUGIN_DEFAULT_CONFIG),
    );
  }

  validationError() {
    return (
      this.whatsappValidator.validate() ||
      (!this.state.data.config.prefilledMessage
        ? {
            message: i18next.t(
              'modernComponents.FlowBuilder.views.entryPoints.WhatsappWidgetEntryPoint.error',
            ),
            isBlockError: true,
            onClick: () => {
              this.emit('click');
            },
          }
        : undefined)
    );
  }

  pluginDidSet() {
    if (!this.state.data.config.prefilledMessage) {
      this._node.blockView.blockTitleView.setHeaderActive(false);
    }
    this.renderNode();
  }

  pluginDidSave() {
    this.renderNode();
  }

  pluginDidSaveError(error: ApolloError): void {
    handleConflictError(error, this);
  }

  destroy() {
    this.whatsappSettingsSubscription.unsubscribe();
    this.state.destroy();
    super.destroy();
  }
}
