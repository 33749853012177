import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { sendEvent } from '@utils/Analytics';
import { Anchor } from '@ui/Links';
import { ValidationReason } from './types';
import { PluginType } from '@components/FlowBuilder/types';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface Data {
  id: string;
  flow_id: string | null;
  entry_point_bot_id: string | null;
  flow_title: string | null;
}

export interface TooltipContentProps {
  data: Data;
  reason: Maybe<ValidationReason>;
  pluginId: PluginType;
  onResolveConflictClick: (entryPointBotId?: string | null) => void;
}

export const TooltipContent: React.FC<TooltipContentProps> = ({
  data,
  reason,
  pluginId,
  onResolveConflictClick,
}) => {
  const { t } = useSafeTranslation();

  if (reason?.adBelongsToAnotherPage) {
    if (reason?.adFromAnotherEP || reason?.flowIdDoesNotExists) {
      return (
        <>
          {t(
            'TreeItem-JSXText-1233-this-ad-is-already-connected-to-a-different-page-change-a-page-in-ads-manager',
          )}
        </>
      );
    }

    return (
      <>
        {t(
          'TreeItem-JSXText-1393-this-ad-is-already-connected-to-a-different-page',
        )}
        <br />
        <br />
        <Anchor
          intent="tooltip"
          onClick={() => {
            onResolveConflictClick(data.entry_point_bot_id);

            sendEvent({
              category: 'flows',
              action: 'Remove ad from page click (tooltip)',
              label: pluginId,
              propertyBag: {
                postId: data.id,
                flowId: data.flow_id,
              },
            });
          }}
        >
          {t('TreeItem-JSXText--881-remove-ad-from-this-entry-point')}
        </Anchor>
      </>
    );
  }

  if (reason?.adFromAnotherEP) {
    return (
      <>
        {t(
          'TreeItem-JSXText-1897-youve-already-used-this-ad-in-one-of-your-flows-to-use-it-here-remove-it-from-conflicting-ep',
        )}
        <br />
        <br />
        <Anchor
          intent="tooltip"
          onClick={() => {
            onResolveConflictClick();

            sendEvent({
              category: 'flows',
              action: 'Remove ad from entry point click (tooltip)',
              label: pluginId,
              propertyBag: {
                postId: data.id,
                flowId: data.flow_id,
              },
            });
          }}
        >
          {t('TreeItem-JSXText--201-remove-ad-from')}
          {data.flow_title}"
        </Anchor>
      </>
    );
  }

  return null;
};
