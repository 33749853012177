import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { humanizeNumber } from '@utils/humanizeNumber';
import { ButtonUnstyled } from '@ui/Button';
import { globalHistory } from '@utils/Routing';
import { FieldNameType, prepareBlockFilterConfig } from '@utils/PeopleTabUtils';
import { sendEvent } from '@utils/Analytics';
import { StatFieldsIds } from '../BlockStatsView';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';

interface StatTooltipProps {
  uniqValue?: number | null;
  notUniqValue?: number | null;
  blockId?: string;
  fieldId?: StatFieldsIds;
  showPeopleTabLink?: boolean;
  blockTitle?: string;
}

const FIELD_ID_REWRITE: Record<string, string> = {
  [StatFieldsIds.delivered]: 'sent',
  [StatFieldsIds.sent]: 'attempted',
};

export const StatTooltip: React.FC<StatTooltipProps> = ({
  uniqValue,
  notUniqValue,
  showPeopleTabLink,
  blockTitle,
  blockId = '',
  fieldId = '',
}) => {
  const { t } = useSafeTranslation();
  const uniqueStatName = t(
    'modernComponents.FlowBuilder.views.components.StatTooltip.uniqueStatName',
  );
  const ptFieldId = FIELD_ID_REWRITE[fieldId] || fieldId;
  return (
    <>
      {typeof notUniqValue === 'number' && (
        <>
          {window.i18next.t('StatTooltip-JSXText--430-total')}
          {humanizeNumber(notUniqValue)}
          <br />
        </>
      )}
      {typeof uniqValue === 'number' &&
        (showPeopleTabLink ? (
          <ButtonUnstyled
            onClick={() => {
              globalHistory.push(
                `/bot/${getFlowControllerStrict().flow.botId}/users`,
                {
                  filter: prepareBlockFilterConfig(
                    ptFieldId as FieldNameType,
                    blockId,
                  ),
                  block: {
                    id: blockId,
                    title: blockTitle,
                  },
                },
              );
              sendEvent({
                category: 'flow',
                action: 'click on block stat (go to people tab)',
                label: fieldId,
                propertyBag: {
                  blockId,
                  blockTitle,
                },
              });
            }}
          >
            <u>
              {uniqueStatName} {humanizeNumber(uniqValue)}
            </u>
          </ButtonUnstyled>
        ) : (
          <>
            {uniqueStatName} {humanizeNumber(uniqValue)}
          </>
        ))}
    </>
  );
};

export const createStatTooltip = (props: StatTooltipProps) => (
  <StatTooltip {...props} />
);
