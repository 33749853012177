import noop from 'lodash-es/noop';
import { InteractionManager } from 'pixi.js-legacy';
import { defaultObj, View } from '../../../../../components/Elements/Layouts';
import { Shape } from '../../../../../components/Elements/Shapes';
import type { PixiField } from '../../../../../PixiField';

export class HoverH {
  private views = new Map();
  skip = false;
  interaction: InteractionManager;
  pixiField: PixiField;

  constructor(pixiField: PixiField, interaction: InteractionManager) {
    this.pixiField = pixiField;
    this.interaction = interaction;
  }

  mouseEvt = (drop?: boolean) => {
    if (this.skip) return;
    let intersection: any; // view
    let startView: any; // view
    if (drop !== true) {
      startView = this.viewByPos(this.interaction.mouse.global);
      intersection = startView;
    }
    const triggeredEventIds = new Map();
    const triggeredViews = new Map();
    if (typeof intersection !== 'undefined' && intersection !== null) {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const viewOnHover = this.views.get(intersection);
        if (viewOnHover !== undefined && viewOnHover !== null) {
          // eslint-disable-next-line no-loop-func
          viewOnHover.forEach((_: any, key: string) => {
            let existing = triggeredViews.get(intersection);
            if (typeof existing === 'undefined') {
              existing = new Map();
            }
            existing.set(key, {
              eventId: key,
              view: intersection,
              asParent:
                triggeredEventIds.get(key) === undefined
                  ? false
                  : triggeredEventIds.get(key),
            });
            triggeredViews.set(intersection, existing);
            triggeredEventIds.set(key, true);
          });
        }
        intersection = intersection.parent;
        if (intersection === null || typeof intersection === 'undefined') {
          break;
        }
      }
    }
    const outTrigger: Array<Function> = [];
    const overTrigger: Array<Function> = [];
    /* eslint-disable no-param-reassign */
    /* eslint-disable no-lonely-if */
    this.views.forEach((evtDescription, view) => {
      evtDescription.forEach((evt: any, evtId: string) => {
        let triggered = triggeredViews.get(view);
        if (typeof triggered !== 'undefined') {
          triggered = triggered.get(evtId);
        }
        if (typeof triggered !== 'undefined') {
          if (!evt.children) {
            if (startView !== view) {
              if (evt.isOver) {
                evt.isOver = false;
                outTrigger.push(evt.out);
              }
            } else {
              if (!evt.isOver) {
                evt.isOver = true;
                overTrigger.push(evt.over);
              }
            }
          } else if (evt.propagate) {
            if (!evt.isOver) {
              evt.isOver = true;
              overTrigger.push(evt.over);
            }
          } else {
            if (triggered.asParent) {
              if (evt.isOver) {
                evt.isOver = false;
                outTrigger.push(evt.out);
              }
            } else {
              if (!evt.isOver) {
                evt.isOver = true;
                overTrigger.push(evt.over);
              }
            }
          }
        } else {
          if (evt.isOver) {
            evt.isOver = false;
            outTrigger.push(evt.out);
          }
        }
      });
    });
    /* eslint-enable no-param-reassign no-lonely-if */
    /* eslint-enable no-lonely-if */
    outTrigger.forEach((f) => f?.());
    overTrigger.forEach((f) => f?.());
  };

  private outFunc(view: any): () => void {
    return () => {
      if (this.skip) return;
      const evtDescription = this.views.get(view);
      evtDescription.forEach((evt: any) => {
        if (evt.isOver) {
          evt.isOver = false;
          evt.out();
        }
      });
    };
  }

  subscribe(obj: any): void {
    const subscribeObj = defaultObj(
      {
        over: noop,
        out: noop,
        propagate: true,
        children: true,
        view: undefined,
        eventId: undefined,
      },
      obj,
    );
    let { view } = subscribeObj;
    if (view instanceof Shape) {
      view = view.shape();
    }
    const { eventId } = subscribeObj;
    let events = this.views.get(view);
    if (typeof events === 'undefined') {
      events = new Map();
    }
    let event = events.get(eventId);
    let shouldSubscribe = false;
    if (typeof event === 'undefined') {
      shouldSubscribe = true;
    } else if (
      event.over !== subscribeObj.over ||
      event.out !== subscribeObj.out ||
      event.propagate !== subscribeObj.propagate ||
      event.children !== subscribeObj.children
    ) {
      this.unsubscribe(view, eventId);
      shouldSubscribe = true;
    }
    if (shouldSubscribe) {
      event = {
        over: subscribeObj.over,
        out: subscribeObj.out,
        isOver: false,
        propagate: subscribeObj.propagate,
        children: subscribeObj.children,
        func: this.mouseEvt,
        outFunc: this.outFunc(view),
      };
      events.set(eventId, event);
      this.pixiField
        .eventHandler()
        .on(view, `${eventId}over`, 'mouseover', event.func);
      this.pixiField
        .eventHandler()
        .on(view, `${eventId}out`, 'mouseout', event.func);
      this.pixiField
        .eventHandler()
        .on(view, `${eventId}click`, 'click', event.func);
    }
    this.views.set(view, events);
  }

  unsubscribe(viewToRemove: any, eventId: string): void {
    let view = viewToRemove;
    if (view instanceof Shape) {
      view = view.shape();
    }
    const existing = this.views.get(view);
    if (typeof existing !== 'undefined') {
      const evt = existing.get(eventId);
      if (typeof evt !== 'undefined') {
        this.pixiField.eventHandler().off(view, `${eventId}over`);
        this.pixiField.eventHandler().off(view, `${eventId}out`);
        this.pixiField.eventHandler().off(view, `${eventId}click`);
      }
      existing.delete(eventId);
      if (existing.size === 0) {
        this.views.delete(view);
      }
    }
  }

  remove(viewToRemove: any): void {
    let view = viewToRemove;
    if (view instanceof Shape) {
      view = view.shape();
    }
    let existing = this.views.get(view);
    if (typeof existing !== 'undefined') {
      existing.forEach((_: any, k: string) => {
        this.unsubscribe(view, k);
      });
      existing = this.views.get(view);
      // eslint-disable-next-line no-console
      if (existing) console.log(existing.size);
    }
  }

  viewByPos(pos: { x: number; y: number } | any): any {
    // returns view
    return this.interaction.hitTest(pos);
  }

  getEventsForView(view: View) {
    return this.views.get(view);
  }

  destroy(): void {
    this.views.clear();
  }
}
