import { whatsappPopupEntryPointFragment_config as WhatsappPopupEntryPointFragmentConfig } from '../../../../Plugins/WhatsappPopupEntryPoint/@types/whatsappPopupEntryPointFragment';
import { MainLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Node } from '../../../Node';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { CARD_WIDTH } from '../../components/ProductCardView/ProductCardView';
import i18next from 'i18next';
import { WhatsappValidator } from '../common/WhatsappValidator';
import { getWhatsappSettingsObservable } from '@utils/Data/Whatsapp/getWhatsappSettingsObservable';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { Subscription } from 'rxjs';
import { equals } from 'ramda';
import { removeTypename } from '@utils/GQL/utils';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../Plugins/WhatsappPopupEntryPoint/WhatsappPopupEntryPointConst';
import { logFlowEvent } from '@components/FlowBuilder/utils/Analytics';
import { editPluginInEditorPanel } from '@components/FlowBuilder/EditorPanel/events';
import { getPanelWidth } from '@components/FlowBuilder/EditorPanel/utils/panelDimensions';
import { ApolloError } from 'apollo-client';
import { escape } from '@utils/escape';
import { getBase64FromImageUrl } from '@utils/Image';
import { log } from 'cf-common/src/logger';
import { memoize } from 'lodash-es';
import { handleConflictError } from '../common/handleConflictError';

const IMAGE_SIZE = {
  width: 576,
  height: 324,
};

const fetchImage = memoize(
  (image: string | null) => {
    if (!image) {
      return Promise.resolve(null);
    }

    return getBase64FromImageUrl(
      image,
      IMAGE_SIZE.width,
      IMAGE_SIZE.height,
    ).catch(() => {
      log.error({
        msg: 'Error while fetching image in WA Sign Up form preview',
      });
      return null;
    });
  },
  (url) => url,
);

export class EntryPointWhatsappPopup
  extends MainLayout
  implements StatefulPluginDelegate<WhatsappPopupEntryPointFragmentConfig>
{
  TEST_NAME = 'EntryPointWhatsappPopup';

  public readonly state: StatefulPlugin<WhatsappPopupEntryPointFragmentConfig>;
  public readonly _node: Node;
  private readonly htmlBox: HTMLText;
  private readonly whatsappSettingsSubscription: Subscription | null = null;
  public isWhatsappConnected: boolean | undefined;
  private readonly whatsappValidator: WhatsappValidator;
  private prevRender: Record<
    'image' | 'header' | 'text' | 'buttonText',
    string | null
  > = {
    buttonText: null,
    header: null,
    image: null,
    text: null,
  };

  constructor(
    state: StatefulPlugin<WhatsappPopupEntryPointFragmentConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.whatsappValidator = new WhatsappValidator(this);

    this.htmlBox = new HTMLText({
      fontSize: 15,
      width: CARD_WIDTH,
      trustedHtml: true,
    });

    this.renderHtml(this.state.data.config);
    this.addToLayout(this.htmlBox);

    if (!getPixiFieldStrict().isViewOnly()) {
      this.whatsappSettingsSubscription = getWhatsappSettingsObservable(
        getFlowControllerStrict().flow.botId,
      ).subscribe(({ isWhatsappConnected }) => {
        this.isWhatsappConnected = isWhatsappConnected;
        this.renderNode();
      });
    }

    this.on('click', () => {
      logFlowEvent('entry_point', 'open whatsapp popup settings');
      getPixiFieldStrict().fixBlockPosition(node.blockView, getPanelWidth());
      editPluginInEditorPanel(node.id, this.state.data, state);
    });
  }

  async onBeforeRender() {
    const { buttonText, header, text, image } = this.state.data.config;

    const propsLike = { buttonText, header, text, image };
    if (equals(this.prevRender, propsLike)) {
      return;
    }

    this.prevRender = propsLike;
    const imageData = await fetchImage(image);

    this.renderHtml({ buttonText, header, text, image, imageData });
    this.renderNode();
  }

  renderHtml({
    buttonText,
    header,
    text,
    image,
    imageData,
  }: typeof this.prevRender & { imageData?: string | null }) {
    /* eslint-disable chatfuel/no-use-hex-colors */
    this.htmlBox.text(`
    <div style="position: relative; display: flex; align-items: center; justify-content: center; width: ${CARD_WIDTH}px;">
      <div style="box-shadow: 0px 2px 12px 0px #2C3A5533; width: ${
        CARD_WIDTH - 64
      }px; overflow: hidden; color: #152442; background: #fff; border-radius: 8px; padding: 16px; line-height: 0;">
        ${
          image
            ? `<div style="${
                imageData
                  ? `background: url('${imageData}') center center no-repeat;`
                  : ''
              } background-size: cover; width: ${CARD_WIDTH - 32}px; height: ${
                ((CARD_WIDTH - 32) / IMAGE_SIZE.width) * IMAGE_SIZE.height
              }px; margin: -16px -16px 16px;"></div>`
            : ''
        }
        ${
          header
            ? `<div style="width: 75%; margin: 0 auto 16px; font-weight: 700; text-align: center; font-size: 12px; line-height: 1.2;">${escape(
                header,
              )}</div>`
            : ''
        }
        ${
          text
            ? `<div style="width: 75%; text-align: center; font-size: 8px; line-height: 1.4; margin: 0 auto;">${escape(
                text,
              )}</div>`
            : ''
        }
        ${
          buttonText
            ? `<div style="background: #0EBC83; border-radius: 0; color: white; width: 65%; text-align:center; padding: 8px; margin: ${
                text || header ? '18px' : '0'
              } auto 0; line-height: 1.4; font-size: 10px;">${escape(
                buttonText,
              )}</div>`
            : ''
        }
      </div>
    </div>
    `);
    /* eslint-enable chatfuel/no-use-hex-colors */
  }

  hasSignificantChangesInConfig() {
    return !equals(
      removeTypename(this.state.data.config),
      removeTypename(PLUGIN_DEFAULT_CONFIG),
    );
  }

  validationError() {
    return (
      this.whatsappValidator.validate() ||
      (!this.state.data.config.prefilledMessage ||
      this.state.data.config.delay_s === null ||
      !this.state.data.config.buttonText
        ? {
            message: i18next.t(
              'modernComponents.FlowBuilder.views.entryPoints.WhatsappPopup.error',
            ),
            isBlockError: true,
            onClick: () => {
              this.emit('click');
            },
          }
        : undefined)
    );
  }

  pluginDidSet() {
    if (
      !this.state.data.config.prefilledMessage ||
      this.state.data.config.delay_s === null ||
      !this.state.data.config.buttonText
    ) {
      this._node.blockView.blockTitleView.setHeaderActive(false);
    }
    this.renderNode();
  }

  pluginDidSave() {
    this.renderNode();
  }

  pluginDidSaveError(error: ApolloError): void {
    handleConflictError(error, this);
  }

  destroy() {
    this.whatsappSettingsSubscription?.unsubscribe();
    this.state.destroy();
    super.destroy();
  }
}
