import { propEq } from 'ramda';
import { PropFunc } from '../../types';
import {
  HTMLText,
  HTMLTextProps,
  DownArrow,
} from '../../components/Elements/Shapes';
import { resByFunc } from '../utils';
import { CreateMenuViewOverlay } from '../menu_view_overlay';
import { HLayout } from '../../components/Elements/Layouts';
import { textCardBackgroundColor } from '../plugin_consts';
import { HEXColors } from '@ui/_common/colors';

export interface Item {
  id: string;
  title: string;
}

interface Padding {
  left?: number;
  right?: number;
}

interface DropdownProps<T extends { title: string } = Item>
  extends HTMLTextProps {
  items: PropFunc<T[]>;
  defaultSelectedItem: T;
  isEditing?: boolean | PropFunc<boolean>;
  label?: string;
  padding?: Padding;

  onChange(selectedItem: T): void;
}

export class Dropdown<T extends { title: string } = Item> extends HLayout {
  TEST_NAME = 'Dropdown';
  private readonly items: PropFunc<T[]>;
  private _selectedItem: T;
  private readonly textField: HTMLText;
  private padding: Padding | undefined;
  overlayShown: boolean = false;
  private readonly onChange: (selectedItem: T) => void;

  constructor({
    items,
    defaultSelectedItem,
    onChange,
    isEditing,
    label,
    padding,
    ...htmlTextProps
  }: DropdownProps<T>) {
    super({
      background: {
        fill: textCardBackgroundColor,
        ...(htmlTextProps.background || {}),
      },
      cursor: () =>
        isEditing === undefined || resByFunc(isEditing)
          ? { in: 'pointer', out: 'grab' }
          : undefined,
    });

    this.padding = padding;
    this.items = items;
    this._selectedItem = defaultSelectedItem;
    this.onChange = onChange;

    this.textField = new HTMLText({
      fontSize: 15,
      singleLine: true,
      ...htmlTextProps,
      width:
        htmlTextProps.width && htmlTextProps.width - 17 - (padding?.right || 0),
    });

    if (label) {
      this.addToLayout(
        new HTMLText({
          text: label,
          fontSize: 15,
          ...(htmlTextProps.fontStyle
            ? { fontStyle: htmlTextProps.fontStyle }
            : {}),
        }),
        {
          margin: { right: 4 },
        },
      );
    }

    this.addToLayout(this.textField, { margin: { left: padding?.left || 0 } });

    this.addToLayout(
      new DownArrow({ width: 9, height: 7, fill: HEXColors.black }),
      {
        margin: {
          top: htmlTextProps.height ? (htmlTextProps.height - 7) * 0.5 : 9,
          left: 6,
          right: padding?.right || 0,
        },
        gone: () =>
          (isEditing !== undefined ? !resByFunc(isEditing) : false) ||
          resByFunc(items)?.length < 2,
      },
    );

    this.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });

    this.on('click', (event: any) => {
      if (resByFunc(items)?.length < 2) {
        return;
      }
      if (isEditing !== undefined && !resByFunc(isEditing)) {
        return;
      }
      event.stopPropagation();

      new CreateMenuViewOverlay<T>({
        onChoose: (item) => {
          this._selectedItem = item;
          onChange(item);
          this.overlayShown = false;
          this.renderNode();
        },
        items: resByFunc(this.items),
        onClose: () => {
          this.overlayShown = false;
          this.renderNode();
        },
      }).showOn(event.data.global);
      this.overlayShown = true;
      this.renderNode();
    });
  }

  set selectedItem(item: T) {
    this._selectedItem = item;
    this.renderNode();
  }

  get selectedItem() {
    return this._selectedItem;
  }

  setWidth(value: number) {
    this.textField.width(value - 17 - (this.padding?.right || 0));
    this.textField.renderElement();
  }

  onBeforeRender() {
    const items = resByFunc(this.items);
    if (!items.find(propEq('title', this.selectedItem.title))) {
      this.onChange(this._selectedItem);
    }
    this.textField.text(this._selectedItem.title);
  }
}
