import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { getIntegrationZapierAccountIdObservable } from '@utils/Integrations/Zapier/getIntegrationZapierAccountStateObservable';
import { HEXColors } from '@ui/_common/colors';
import { startIntegrationZapierOAuth } from '@utils/Integrations/Zapier/startIntegrationZapierOAuth';
import i18next from 'i18next';
import { disconnectIntegrationZapierAccount } from '@utils/Integrations/Zapier/disconnectIntegrationZapierAccount';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { Subscription } from 'rxjs';
import { refetchFlowBlock } from '../../../../api/refetchFlowBlock';
import { removeEntity } from '../../../../components/Modals';
import { Node } from '../../../../Node';
import { showZapierDialog } from '../ZapierZapsDialog/showZapierDialog';
import { MainLayout, VLayout } from '../../../../components/Elements/Layouts';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../../PixiFieldRepository';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import { ButtonView } from '../../../button_view';
import { zapierSvgTexture } from '../../../../assets/textures';
import { validateIsPageConnecting } from '../../../entry-points/common/utils/validateIsPageConnecting';
import { Loader } from '../../../loader';
import { buttonHeight, pluginWidth } from '../../../plugin_consts';
import { ZapierZap } from '../query';
import { getI18ZapierKey } from '../utils/getI18ZapierKey';
import { ZapAdminView } from './components/ZapAdminView/ZapAdminView';
import { ZapAttributesView } from './components/ZapAttributesView';
import { ZapsListView } from './components/ZapListView/ZapsListView';

interface ZapierIntegrationConfig {
  connected_zaps: ZapierZap[] | null;
  attributes: string[] | null;
}

interface ZapierIntegrationViewProps {
  node: Node;
  pluginId: string;
  cardId: string;
  showBackground?: boolean;
}

type SendIntegrationEventFunc = (action: string, propertyBag?: Object) => void;

const createSendIntegrationEvent =
  (pluginId: string, blockId: string): SendIntegrationEventFunc =>
  (action, propertyBag) =>
    logFlowPluginEvent(pluginId, action, {
      blockId,
      ...(propertyBag || {}),
    });

export class ZapierIntegrationView extends VLayout {
  private readonly loader: Loader;
  private isZapierChangeStatusInProgress: boolean = false;
  private zapierAccountId: string | undefined;
  private zapierAccountSubscription: Subscription | undefined;
  private readonly zapsListView: ZapsListView;
  private readonly zapAttributesView: ZapAttributesView;
  private config: ZapierIntegrationConfig;
  private node: Node;
  private sendIntegrationEvent: SendIntegrationEventFunc;

  constructor({
    node,
    pluginId,
    cardId,
    showBackground = true,
  }: ZapierIntegrationViewProps) {
    super({
      width: pluginWidth,
      background: showBackground
        ? {
            fill: HEXColors.greyLight20,
            cornerRadius: 12,
          }
        : undefined,
    });

    this.node = node;

    this.sendIntegrationEvent = createSendIntegrationEvent(
      pluginId,
      node.block.id,
    );

    this.config = {
      attributes: [],
      connected_zaps: [],
    };

    const { flow } = getFlowControllerStrict();
    const { botId, id: flowId, bot, user } = flow;

    if (!getPixiFieldStrict().isViewOnly()) {
      this.zapierAccountSubscription = getIntegrationZapierAccountIdObservable(
        botId,
        user.id,
      ).subscribe((id) => {
        this.zapierAccountId = id;
        this.renderNode();
      });
    }

    this.loader = new Loader({
      width: pluginWidth - 32,
      height: buttonHeight,
      background: {
        fill: HEXColors.white,
        cornerRadius: 4,
      },
    });

    this.addToLayout(new ZapAdminView({ user }), {
      margin: {
        top: 20,
        left: 16,
        bottom: 0,
      },
      gone: () => !this.zapierAccountId,
    });

    this.addToLayout(
      new ButtonView({
        width: pluginWidth - 32,
        height: buttonHeight,
        title: i18next.t(getI18ZapierKey('connectZapierAccount')),
        img: zapierSvgTexture as any,
        iconWidth: 16,
        iconHeight: 16,
        onClick: (event) => {
          if (getPixiFieldStrict().isViewOnly()) {
            return;
          }
          this.sendIntegrationEvent(
            window.i18next.t(
              'ZapierIntegrationView-string-1118-connect-zapier-account-click',
            ),
          );
          event.stopPropagation();
          this.isZapierChangeStatusInProgress = true;
          this.loader.start();
          startIntegrationZapierOAuth({
            botId,
            flowId,
            blockId: node.block.id,
          });
          this.renderNode();
        },
      }),
      {
        margin: {
          top: 16,
          left: 16,
          bottom: 0,
          right: 0,
        },
        gone: () =>
          this.isZapierChangeStatusInProgress || !!this.zapierAccountId,
      },
    );

    this.addToLayout(this.loader, {
      margin: () => ({
        top: this.zapierAccountId ? 8 : 16,
        left: 16,
        bottom: this.zapierAccountId ? 8 : 0,
        right: 0,
      }),
      gone: () => !this.isZapierChangeStatusInProgress,
    });

    this.addToLayout(
      new ButtonView({
        width: pluginWidth - 32,
        height: buttonHeight,
        title: i18next.t(getI18ZapierKey('disconnectAccount')),
        onClick: (event) => {
          if (!this.zapierAccountId || getPixiFieldStrict().isViewOnly()) {
            return;
          }
          this.sendIntegrationEvent(
            window.i18next.t(
              'ZapierIntegrationView-string-1725-disconnect-account-click',
            ),
          );
          event.stopPropagation();
          removeEntity({
            renderHeading: () => i18next.t(getI18ZapierKey('areYouSure')),
            renderActionText: () => i18next.t(getI18ZapierKey('yesDisconnect')),
            renderNoteText: () =>
              i18next.t(getI18ZapierKey('noteThatZapsWillContinueWorking')),
            onSubmit: async () => {
              if (!this.zapierAccountId) {
                return;
              }
              this.isZapierChangeStatusInProgress = true;
              this.loader.start();
              this.renderNode();
              try {
                await disconnectIntegrationZapierAccount(
                  bot.id,
                  this.zapierAccountId,
                );
                bot.connectedZapierAccounts.length = 0;
                toaster.show({
                  type: ServiceMessageType.default,
                  payload: {
                    message: i18next.t(
                      getI18ZapierKey('accountDisconnectedSuccess'),
                    ),
                  },
                });
              } catch (error) {
                toaster.show({
                  type: ServiceMessageType.error,
                  payload: {
                    message: i18next.t(
                      'common.ToasterMessages.somethingWentWrong',
                    ),
                  },
                });
                log.error({
                  error,
                  msg: 'Error connect zapier account',
                  data: {
                    label: 'zapier',
                    requestId: getRequestIdFromApolloError(error as any),
                  },
                });
              } finally {
                this.isZapierChangeStatusInProgress = false;
                this.loader.stop();
                this.renderNode();
              }
            },
          });
        },
      }),
      {
        margin: {
          top: 8,
          left: 16,
          bottom: 8,
          right: 0,
        },
        gone: () =>
          this.isZapierChangeStatusInProgress || !this.zapierAccountId,
      },
    );

    this.zapsListView = new ZapsListView({
      pluginId,
      cardId,
      blockId: node.block.id,
      onUpdateZapsListRequest: () => {
        refetchFlowBlock(node.block.id, getFlowControllerStrict().flow);
      },
    });

    this.addToLayout(this.zapsListView, {
      margin: {
        top: 8,
        left: 16,
        bottom: 8,
        right: 0,
      },
      gone: () => !this.config.connected_zaps?.length,
    });

    this.zapAttributesView = new ZapAttributesView();

    this.addToLayout(this.zapAttributesView, {
      margin: () => ({
        top: this.zapierAccountId ? 0 : 16,
        left: 16,
        bottom: 8,
        right: 0,
      }),
      gone: () => !this.config.attributes?.length,
    });

    this.addToLayout(
      new ButtonView({
        width: pluginWidth - 32,
        height: buttonHeight,
        title: i18next.t(getI18ZapierKey('addNewZap')),
        onClick: () => {
          this.sendIntegrationEvent(
            window.i18next.t(
              'ZapierIntegrationView-string-2646-add-new-zap-click',
            ),
          );
          showZapierDialog({
            pluginId,
            blockId: node.block.id,
            header: i18next.t(getI18ZapierKey('addNewZap')),
            cardId,
            onZapStateChange: () => {
              refetchFlowBlock(node.block.id, getFlowControllerStrict().flow);
            },
            onDismiss: () => {
              refetchFlowBlock(node.block.id, getFlowControllerStrict().flow);
            },
          });
        },
      }),
      {
        margin: {
          top: 8,
          left: 16,
          bottom: 0,
          right: 0,
        },
        gone: () => !this.zapierAccountId,
      },
    );

    this.addToLayout(
      new MainLayout({
        height: 16,
        background: {
          fill: HEXColors.white,
          opacity: 0,
        },
      }),
    );
  }

  public updateConfigData(config: ZapierIntegrationConfig) {
    this.config = config;
    this.zapsListView.updateZapsData(config.connected_zaps || []);
    this.zapAttributesView.updateAttributesData(config.attributes || []);
  }

  // private isZapsListEmpty() {
  //   return !this.config.connected_zaps?.length
  //     ? {
  //         message: i18next.t(getI18ZapierKey('addAtLeastOneZap')),
  //       }
  //     : undefined;
  // }

  public validationError() {
    return validateIsPageConnecting(this.node);
  }

  destroy() {
    this.zapierAccountSubscription?.unsubscribe();
    super.destroy();
  }
}
