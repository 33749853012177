import { ServiceMessageType, toaster } from '@services/MessageService';
import { HEXColors } from '@ui/_common/colors';
import clipboardCopy from 'clipboard-copy';
import i18next from 'i18next';
import { ZapierEventTriggerPluginConfig } from '../../../../Plugins/ZapierEventTriggerPlugin/ZapierEventTriggerPluginConst';
import { zapeirPluginIconSvgTexture } from '../../../assets/textures';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { BLOCK_SUBTYPES } from '../../../consts';
import { Node } from '../../../Node';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { CircleIcon } from '../../components/CircleIcon';
import { ZapierIntegrationView } from '../../components/Zapier';
import { getI18ZapierKey } from '../../components/Zapier/utils/getI18ZapierKey';
import { ZapierCommentCardView } from '../../components/Zapier/ZapierCommentCardView/ZapierCommentCardView';
import { pluginWidth } from '../../plugin_consts';

export class ZapierEventTriggerPlugin
  extends VLayout
  implements StatefulPluginDelegate<ZapierEventTriggerPluginConfig>
{
  public _node: Node;
  private state: StatefulPlugin<ZapierEventTriggerPluginConfig>;
  private readonly integrationView: ZapierIntegrationView;

  constructor(
    state: StatefulPlugin<ZapierEventTriggerPluginConfig>,
    node: Node,
  ) {
    super();

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    const box = new VLayout({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
    });

    const headerBox = new HLayout({});

    headerBox
      .addToLayout(
        new CircleIcon({
          icon: zapeirPluginIconSvgTexture,
        }),
        {
          margin: {
            right: 8,
          },
        },
      )
      .addToLayout(
        new HTMLText({
          text:
            node.block.subtype === BLOCK_SUBTYPES.send_data
              ? window.i18next.t('ZapierEventTriggerPlugin-string--162-zapier')
              : window.i18next.t(
                  'ZapierEventTriggerPlugin-string--212-send-data-to-zapier',
                ),
          fontSize: 15,
          fill: HEXColors.black,
          fontStyle: 'bold',
        }),
        {
          margin: {
            top: 2,
          },
        },
      );

    box.addToLayout(headerBox, {
      margin: {
        left: 16,
        top: 16,
      },
    });

    this.integrationView = new ZapierIntegrationView({
      node,
      pluginId: state.data.plugin_id,
      showBackground: false,
      cardId: state.data.id,
    });

    this.integrationView.updateConfigData(this.state.data.config);

    box.addToLayout(this.integrationView, {
      margin: {
        top: -8,
      },
    });

    const comment = new ZapierCommentCardView();

    comment.updateText(
      i18next.t(getI18ZapierKey('sendDataCardInstructions'), {
        cardId: this.state.data.id,
      }),
    );

    comment.on('click', (event: Event) => {
      event.stopPropagation();
      clipboardCopy(state.data.id);
      toaster.show({
        type: ServiceMessageType.default,
        payload: {
          message: i18next.t(getI18ZapierKey('cardIdCopied')),
        },
      });
    });

    this.addToLayout(box);

    this.addToLayout(comment, {
      gone: () => this.hasConnectedZaps(),
    });
  }

  private hasConnectedZaps() {
    return !!this.state.data.config.connected_zaps?.length;
  }

  pluginDidSet() {
    this.integrationView.updateConfigData(this.state.data.config);
    this.renderNode();
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
