import { isValidUrl } from '@utils/UrlUtils';
import { MainLayout } from '../../../../components/Elements/Layouts';
import { AddMedia } from '../../../add_media';
import addImageSvg from '../../../../assets/controller/gallery_card_add_image.svg';
import { Image } from '../../../../components/Elements/Shapes';
import { Loader } from '../../../loader';
import { pluginWidth, textCardBackgroundColor } from '../../../plugin_consts';
import {
  uploadFileService,
} from '@utils/UploadService';
import { getFlowPlatform } from '@components/FlowBuilder/utils/getFlowPlatform';
import { getSupportedImageTypes } from '@utils/PlatformSupportedFiles/getSupportedTypes';
import { FileAttachmentType } from '@utils/UploadService/types';

interface CreateImageBoxPayload {
  getImageUrl(): string;
  onSuccessUpload(url: string): void;
  onFailedUpload: VoidFunction;
  height?: number;
  width?: number;
}

export const IMAGE_SIZE = 8;

const CORNER_RADIUS = 12;

const OFFSET = 16;

const MARGIN = {
  left: OFFSET,
  right: OFFSET,
};

export function createImageBox({
  getImageUrl,
  height = 200,
  width = pluginWidth - OFFSET * 2,
  onSuccessUpload,
  onFailedUpload,
}: CreateImageBoxPayload) {
  let imageLoading = false;
  const imageBox = new MainLayout({
    width,
    height,
  });

  const loader = new Loader({
    width,
    height,
    background: { cornerRadius: CORNER_RADIUS },
  });

  const image = new Image({
    url: getImageUrl(),
    width,
    height,
    cornerRadius: CORNER_RADIUS,
    background: { fill: textCardBackgroundColor, cornerRadius: CORNER_RADIUS },
    onReady: () => {
      imageLoading = false;
      loader.stop();
      imageBox.renderNode();
    },
  });

  imageBox.layout(image, {
    margin: MARGIN,
    gone: () => !getImageUrl() || !isValidUrl(getImageUrl()),
  });

  imageBox.layout(loader, {
    margin: MARGIN,
    gone: () => !imageLoading,
  });

  imageBox.layout(
    new AddMedia({
      height,
      width,
      label: `<b>Image</b> (max <b>${IMAGE_SIZE}MB</b>)`,
      url: addImageSvg,
    }),
    {
      cornerRadius: CORNER_RADIUS,
      margin: MARGIN,
      gone: () => imageLoading || Boolean(getImageUrl()),
    },
  );

  const handleFailedUpload = () => {
    onFailedUpload();
    imageLoading = false;
    loader.stop();
    imageBox.renderNode();
  };

  const handleSuccessUpload = ({ url }: { url: unknown }) => {
    if (typeof url !== 'string') {
      handleFailedUpload();
      return;
    }
    onSuccessUpload(url);
    image.url(url);
    imageBox.renderNode();
  };

  imageBox.on('click', (e) => {
    e?.stopPropagation?.();

    uploadFileService
      .chooseFile({
        beforeUpload: () => {
          imageLoading = true;
          loader.start();
          imageBox.renderNode();
        },
        accept: getSupportedImageTypes(getFlowPlatform()!),
        dataTestId: 'ntime-notification__upload-file',
        platform: getFlowPlatform()!,
        filetype: FileAttachmentType.image,
      })
      .then(handleSuccessUpload)
      .catch(handleFailedUpload);
  });

  return imageBox;
}
