import i18next from 'i18next';
import { VLayout } from '@components/FlowBuilder/components/Elements/Layouts';
import { HEXColors } from '@ui/_common/colors';
import { pluginWidth } from '../../plugin_consts';
import { ButtonView, ButtonProps } from '../../button_view';

export interface WhatsappTemplatePluginNoTemplateViewProps {
  onButtonClick: ButtonProps['onClick'];
}

export class WhatsappTemplatePluginNoTemplateView extends VLayout {
  public readonly button: ButtonView;

  constructor({ onButtonClick }: WhatsappTemplatePluginNoTemplateViewProps) {
    const width = pluginWidth - 24;

    super({ width });

    this.button = new ButtonView({
      textColor: HEXColors.white,
      bgColor: HEXColors.accent1Normal,
      width,
      title: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.chooseTemplate',
      ),
      onClick: onButtonClick,
    });

    this.addToLayout(this.button);
  }
}
