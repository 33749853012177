import { flowBuilderFPS, CalculationList } from 'cf-common/src/metrics';
import { log } from 'cf-common/src/logger';
import { localMonitoring } from 'cf-common/src/localMonitoring';

const fpsCalculations = new CalculationList();

export const trackFPS = (fps: number) => {
  fpsCalculations.add(fps);
};

export const sendFPSMetrics = ({
  flowId,
  blocksCount,
  cardsCount,
}: {
  flowId: string;
  blocksCount: number;
  cardsCount: number;
}) => {
  const fpsMetrics = fpsCalculations.metrics();
  if (fpsMetrics) {
    Object.entries(fpsMetrics).forEach(([type, calculation]) => {
      flowBuilderFPS.observe(calculation, { type });
    });
    const monitoringData = {
      fpsPerBlock: Math.round((fpsMetrics.avg / blocksCount) * 100) / 100,
      avgFps: fpsMetrics.avg,
      blocksCount,
      cardsCount,
      flowId,
    };

    log.info({
      msg: 'Flow Builder avg FPS per block',
      data: monitoringData,
    });
    localMonitoring(monitoringData);
  }
  fpsCalculations.clean();
};
