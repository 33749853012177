import { whatsappDirectEntryPointFragment_config as EntryPointWhatsappDirectConfig } from '@components/Plugins/WhatsappDirectEntryPoint/@types/whatsappDirectEntryPointFragment';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Node } from '../../../Node';
import { MainLayout } from '../../../components/Elements/Layouts';
import { pluginWidth } from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { HTMLText } from '../../../components/Elements/Shapes';
import i18next from 'i18next';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { Subscription } from 'rxjs';
import { getWhatsappSettingsObservable } from '@utils/Data/Whatsapp/getWhatsappSettingsObservable';
import { WhatsappValidator } from '../common/WhatsappValidator';

export class EntryPointWhatsappDirect
  extends MainLayout
  implements StatefulPluginDelegate<EntryPointWhatsappDirectConfig>
{
  TEST_NAME = 'EntryPointWhatsappDirect';

  public readonly _node: Node;
  private whatsappSettingsSubscription: Subscription;
  public isWhatsappConnected: boolean | undefined;
  private whatsappValidator: WhatsappValidator;

  public readonly state: StatefulPlugin<EntryPointWhatsappDirectConfig>;

  constructor(
    state: StatefulPlugin<EntryPointWhatsappDirectConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        opacity: 0,
        fill: HEXColors.white,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.whatsappValidator = new WhatsappValidator(this);

    const box = new MainLayout({
      background: {
        fill: HEXColors.calloutsAliceBlue,
        cornerRadius: 4,
      },
      width: pluginWidth,
    });

    box.addToLayout(
      new HTMLText({
        trustedHtml: true,
        fontSize: 15,
        text: i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.WhatsappDirect.text',
        ),
      }),
      {
        margin: {
          top: 12,
          left: 16,
          bottom: 12,
        },
      },
    );

    this.addToLayout(box);

    this.whatsappSettingsSubscription = getWhatsappSettingsObservable(
      getFlowControllerStrict().flow.botId,
    ).subscribe(({ isWhatsappConnected }) => {
      this.isWhatsappConnected = isWhatsappConnected;
      this.renderNode();
    });
  }

  pluginDidSet() {
    this.renderNode();
  }

  validationError() {
    return this.whatsappValidator.validate();
  }

  destroy() {
    this.whatsappSettingsSubscription.unsubscribe();
    super.destroy();
    this.state.destroy();
  }
}
