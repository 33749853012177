import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clone, identity, last, prop, propEq, uniqBy, difference } from 'ramda';
import { DefaultDialog } from '@ui/Dialog';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { sendEvent } from '@utils/Analytics';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { Icon } from '@ui/Icon';
import { NetworkStatus } from 'apollo-client';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import {
  facebookShopsEntryPointFragment_config_products,
  facebookShopsEntryPointFragment_config_products_preview,
} from '@components/Plugins/FacebookShopsEntryPoint/@types/facebookShopsEntryPointFragment';
import { Spacer } from '@ui/Spacer';
import { Input } from '@ui/Input';
import { CheckboxTree } from '@ui/Tree/CheckboxTree';
import { FlatTreeItemTypes } from '@ui/Tree/types';
import { Type } from '@ui/Type';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { LSKey, useLocalStorageForId } from '@utils/LocalStorage';
import { ItemLoadingPlaceholder } from '@components/dialogs/ChooseItemFromListDialog';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';

import {
  FACEBOOK_SHOP_CATALOGUES_QUERY,
  FACEBOOK_SHOP_PRODUCTS_QUERY,
  REMOVE_PRODUCT_FROM_ENTRY_POINTS_MUTATION,
} from './queries';
import {
  FacebookShopCataloguesQuery,
  FacebookShopCataloguesQuery_page_facebookShopCatalogues_catalogues,
  FacebookShopCataloguesQueryVariables,
} from './@types/FacebookShopCataloguesQuery';
import {
  FacebookShopProductsQuery,
  FacebookShopProductsQuery_page_facebookShopCatalogueProducts_products as Product,
  FacebookShopProductsQueryVariables,
} from './@types/FacebookShopProductsQuery';
import { facebookShopProductGroupConflictFragment } from './@types/facebookShopProductGroupConflictFragment';
import {
  CONTENT_HEIGHT,
  DIALOG_STYLE,
  emptyFacebookShopProductGroupConflict,
} from './consts';
import {
  RemoveProductFromEntryPointsMutation,
  RemoveProductFromEntryPointsMutationVariables,
} from './@types/RemoveProductFromEntryPointsMutation';
import { FacebookShopProductItem } from './components/FacebookShopProductItem';
import { PREPEND_ENTRY_POINT_PRODUCT_ID } from '../../../../plugins/FacebookShopsProductListPlugin/consts';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '../../../../../../../services/MessageService';
import { ChooseFacebookShopProductsLoadingStateDialog } from './components/ChooseFacebookShopProductsLoadingStateDialog';
import { ChooseFacebookShopProductsEmptyStateDialog } from './components/ChooseFacebookShopProductsEmptyStateDialog';
import { GoToFacebookButton } from './components/GoToFacebookButton';
import * as css from './ChooseFacebookShopProductsDialog.css';

export interface ChooseFacebookShopProductsDialogProps {
  target: string;
  checkedProductsIds: string[];
  onSubmit: (
    checkedProducts: facebookShopsEntryPointFragment_config_products[],
    notLoadedCheckedProductsIds: string[],
  ) => void;
  onDismiss?: () => void;
  onRemovedProduct?: (id: string) => void;
  productsOnly?: boolean;
  limit?: number;
  showPrependEntryPointProduct?: boolean;
  blockId?: string;
}

export interface ProductTreeItem extends Product {
  children?: Product[];
  product_id?: string;
}

export interface RemoveProductFromEntryPointsProps {
  product?: Product;
  productGroupId?: string;
}

export const getIdFromProductItem = ({
  product_id,
  retailer_product_group_id,
}: facebookShopsEntryPointFragment_config_products) =>
  product_id || retailer_product_group_id || '';

const prepareSearchString = (searchString: string) =>
  searchString.trim().toLowerCase();

const createFilterFunc = (searchString: string) => {
  const clearSearchString = prepareSearchString(searchString);
  return clearSearchString.length
    ? ({ name }: Product) =>
        name.toLowerCase().indexOf(clearSearchString) !== -1
    : identity;
};

const uniqById = uniqBy(prop('id'));

const getProductList = (productsData: FacebookShopProductsQuery | undefined) =>
  productsData?.page?.facebookShopCatalogueProducts.products;
const getAfter = (productsData: FacebookShopProductsQuery | undefined) =>
  productsData?.page?.facebookShopCatalogueProducts.after;
const getGroupConflicts = (
  productsData: FacebookShopProductsQuery | undefined,
) => productsData?.page?.facebookShopCatalogueProducts.group_conflicts;

const createProductTree = (
  flatProductsList: Product[],
  groupConflicts: facebookShopProductGroupConflictFragment[],
) =>
  flatProductsList.reduce((productTree, productItem) => {
    const product = clone(productItem);
    const lastTreeItem = last(productTree);
    if (
      lastTreeItem?.retailer_product_group_id ===
      product.retailer_product_group_id
    ) {
      lastTreeItem.children = lastTreeItem.children || [clone(lastTreeItem)];
      lastTreeItem.children.push(product);
      lastTreeItem.product_id = lastTreeItem.id;
      lastTreeItem.id =
        lastTreeItem.retailer_product_group_id || lastTreeItem.id;
      lastTreeItem.price = null;
      Object.assign(
        lastTreeItem,
        groupConflicts.find(propEq('id', lastTreeItem.id)) ||
          emptyFacebookShopProductGroupConflict,
      );
    } else {
      productTree.push(product as ProductTreeItem);
    }
    return productTree;
  }, [] as ProductTreeItem[]);

export const getCheckedProductsAndGroupsByIds = (
  products: Product[] | undefined,
  groupConflicts: facebookShopProductGroupConflictFragment[],
  ids: string[],
  productsOnly?: boolean,
) =>
  products
    ? createProductTree(products, groupConflicts).reduce((out, treeItem) => {
        const { children, ...itemData } = treeItem;
        const isGroup = !!children;
        if ((!productsOnly || !isGroup) && ids.includes(itemData.id)) {
          out.push({
            __typename: 'FacebookShopProductMatchingRule',
            retailer_product_group_id: itemData.retailer_product_group_id || '',
            product_id: isGroup ? null : itemData.id,
            group_size: children?.length || null,
            preview: {
              ...itemData,
              id: isGroup ? children?.[0]?.id || '' : itemData.id,
            },
          });
          return out;
        }
        children?.forEach((product) => {
          if (ids.includes(product.id)) {
            out.push({
              __typename: 'FacebookShopProductMatchingRule',
              retailer_product_group_id:
                product.retailer_product_group_id || '',
              product_id: product.id,
              group_size: null,
              preview: product,
            });
          }
        });
        return out;
      }, [] as facebookShopsEntryPointFragment_config_products[])
    : [];

const getCheckedQty = (
  checked: string[],
  products: Product[],
  groupConflicts: facebookShopProductGroupConflictFragment[],
  productsOnly?: boolean,
) =>
  createProductTree(products, groupConflicts).reduce((count, treeItem) => {
    if (
      (!productsOnly || !treeItem.children) &&
      checked.includes(treeItem.id)
    ) {
      return count + 1;
    }
    treeItem.children?.forEach(({ id }) => {
      if (checked.includes(id)) {
        // eslint-disable-next-line no-param-reassign
        count++;
      }
    });
    return count;
  }, 0) + (checked.includes(PREPEND_ENTRY_POINT_PRODUCT_ID) ? 1 : 0);

export const ChooseFacebookShopProductsDialog: React.FC<ChooseFacebookShopProductsDialogProps> =
  ({
    onDismiss,
    onSubmit,
    target,
    checkedProductsIds,
    productsOnly,
    limit,
    showPrependEntryPointProduct,
    onRemovedProduct,
    blockId,
  }) => {
    const botId = useCurrentBotId();
    const { pageId, loading: pageLoading } = usePageConnected(botId);
    const [currentCatalogId, setCurrentCatalogId] =
      useLocalStorageForId<string>(
        LSKey.lastUsedFacebookShopCatalogue,
        pageId || undefined,
      );

    const [expanded, setExpanded] = useState<string[]>([]);
    const [checked, setChecked] = useState<string[]>(checkedProductsIds);
    const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
    const [searchString, setSearchString] = useState<string>('');
    const [currentRequestAfter, setCurrentRequestAfter] = useState<
      string | null | undefined
    >(null);
    const [hasError, setHasError] = useState<boolean>(false);

    const lastRequestAfter = useRef<string | undefined | null>();
    const loadingMoreItems = useRef<boolean>(false);

    const {
      data: cataloguesData,
      loading: cataloguesLoading,
      refetch: cataloguesRefetch,
    } = useQuery<
      FacebookShopCataloguesQuery,
      FacebookShopCataloguesQueryVariables
    >(FACEBOOK_SHOP_CATALOGUES_QUERY, {
      variables: {
        pageId: pageId || '',
      },
      skip: !pageId,
    });

    const {
      data: productsData,
      loading: productsLoading,
      fetchMore,
      refetch: productsRefetch,
      networkStatus,
    } = useQuery<FacebookShopProductsQuery, FacebookShopProductsQueryVariables>(
      FACEBOOK_SHOP_PRODUCTS_QUERY,
      {
        variables: {
          catalogueId: currentCatalogId || '',
          pageId: pageId || '',
        },
        notifyOnNetworkStatusChange: true,
        skip: !pageId || !currentCatalogId,
        errorPolicy: 'all',
      },
    );

    const updateCatalogues = useCallback(() => {
      cataloguesRefetch();
    }, [cataloguesRefetch]);

    const updateProducts = useCallback(() => {
      if (currentCatalogId) {
        setCurrentRequestAfter(null);
        productsRefetch();
      }
    }, [productsRefetch, currentCatalogId]);

    const onDismissCallback = useCallback(() => {
      onDismiss?.();
    }, [onDismiss]);

    const [removeProductFromEntryPointsMutation] = useMutation<
      RemoveProductFromEntryPointsMutation,
      RemoveProductFromEntryPointsMutationVariables
    >(REMOVE_PRODUCT_FROM_ENTRY_POINTS_MUTATION, {
      onError: (error) => {
        log.error({
          error,
          msg: 'Remove product from other Entry Point error',
          data: {
            label: 'fb_shops',
            requestId: getRequestIdFromApolloError(error),
          },
        });

        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: Messages.somethingWentWrong,
          },
        });
      },
    });

    const removeProductFromEntryPoints = useCallback(
      async ({
        product,
        productGroupId,
      }: RemoveProductFromEntryPointsProps) => {
        if (!pageId) {
          return;
        }
        await removeProductFromEntryPointsMutation({
          variables: {
            pageId,
            productId: product?.id,
            productGroupId,
          },
          optimisticResponse: {
            removeProductFromEntryPoints: {
              __typename: 'RemoveProductFromEntryPointsResponce',
              product: product || null,
              group_conflict: productGroupId
                ? {
                    ...emptyFacebookShopProductGroupConflict,
                    __typename: 'FacebookShopProductGroupConflict' as const,
                    id: productGroupId,
                  }
                : null,
            },
          },
        });
        onRemovedProduct?.(product?.id || productGroupId || '');
      },
      [onRemovedProduct, pageId, removeProductFromEntryPointsMutation],
    );

    const catalogues = cataloguesData?.page?.facebookShopCatalogues?.catalogues;

    useEffect(() => {
      const id = catalogues?.[0]?.id;
      if (!currentCatalogId && id) {
        setCurrentCatalogId(id);
      }
    }, [currentCatalogId, catalogues, setCurrentCatalogId]);

    const flatProductsList = getProductList(productsData);
    const after = getAfter(productsData);
    const groupConflicts = getGroupConflicts(productsData);

    useLayoutEffect(() => {
      if (isDataUpdated) {
        return;
      }
      setIsDataUpdated(true);
      updateProducts();
    }, [isDataUpdated, flatProductsList, updateProducts]);

    useEffect(() => {
      if (!productsLoading && after !== undefined) {
        setCurrentRequestAfter(after);
      }
    }, [after, productsLoading]);

    useEffect(() => {
      setHasError(false);
      if (currentCatalogId && isDataUpdated) {
        updateProducts();
      }
    }, [currentCatalogId, updateProducts, isDataUpdated]);

    const productsTree = useMemo(() => {
      if (
        networkStatus === NetworkStatus.refetch ||
        !flatProductsList ||
        !groupConflicts
      ) {
        return [];
      }
      const tree = createProductTree(
        flatProductsList.filter(createFilterFunc(searchString)),
        groupConflicts,
      );
      if (showPrependEntryPointProduct) {
        tree.unshift({
          id: PREPEND_ENTRY_POINT_PRODUCT_ID,
        } as ProductTreeItem);
      }
      return tree;
    }, [
      showPrependEntryPointProduct,
      networkStatus,
      flatProductsList,
      searchString,
      groupConflicts,
    ]);

    useEffect(() => {
      setChecked((prevChecked) => {
        setExpanded((prevExpanded) => [
          ...prevExpanded,
          ...productsTree
            .filter(
              ({ id, children }) =>
                children?.length &&
                (prevChecked.includes(id) ||
                  children.some(({ id }) => prevChecked.includes(id))),
            )
            .map(prop('id')),
        ]);
        return [
          ...prevChecked,
          ...productsTree
            .filter(
              ({ id, children }) =>
                prevChecked.includes(id) && children?.length,
            )
            .flatMap(({ children }) => children)
            .map((child) => child?.id || '')
            .filter(Boolean),
        ];
      });
    }, [productsTree]);

    const loadMoreItems = useCallback(() => {
      if (
        !loadingMoreItems.current &&
        pageId &&
        currentCatalogId &&
        networkStatus === NetworkStatus.ready &&
        currentRequestAfter !== null &&
        (currentRequestAfter === undefined ||
          currentRequestAfter !== lastRequestAfter.current) &&
        !hasError
      ) {
        lastRequestAfter.current = currentRequestAfter || null;
        loadingMoreItems.current = true;
        return fetchMore({
          query: FACEBOOK_SHOP_PRODUCTS_QUERY,
          variables: {
            catalogueId: currentCatalogId,
            pageId,
            after: currentRequestAfter || undefined,
            query: prepareSearchString(searchString) || undefined,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const previousProducts =
              clone(getProductList(previousResult)) || [];
            const nextProducts = getProductList(fetchMoreResult) || [];
            const nextAfter = getAfter(fetchMoreResult) || null;
            const nextGroupConflicts = getGroupConflicts(fetchMoreResult) || [];
            const mergedProducts = nextProducts.reduce((products, product) => {
              const oldProductsIndex = products.findIndex(
                propEq('id', product.id),
              );
              if (oldProductsIndex !== -1) {
                // eslint-disable-next-line no-param-reassign
                products[oldProductsIndex] = product;
              } else {
                products.push(product);
              }
              return products;
            }, previousProducts);

            return {
              page: {
                id: previousResult?.page?.id || '',
                facebookShopCatalogueProducts: {
                  products: uniqById(mergedProducts) as Product[],
                  group_conflicts: uniqById([
                    ...(groupConflicts || []),
                    ...nextGroupConflicts,
                  ]) as facebookShopProductGroupConflictFragment[],
                  after: nextAfter,
                  __typename: 'FacebookShopProductPayload',
                },
                __typename: 'Page',
              },
            };
          },
        })
          .then(() => {
            loadingMoreItems.current = false;
          })
          .catch(() => {
            setHasError(true);
          });
      }
      return Promise.resolve([]);
    }, [
      searchString,
      fetchMore,
      pageId,
      currentCatalogId,
      currentRequestAfter,
      networkStatus,
      hasError,
      groupConflicts,
    ]);

    useEffect(() => {
      if (searchString?.length) {
        loadMoreItems();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    if (pageLoading || cataloguesLoading) {
      return (
        <ChooseFacebookShopProductsLoadingStateDialog
          onDismiss={onDismissCallback}
        />
      );
    }

    if (!catalogues?.length) {
      return (
        <ChooseFacebookShopProductsEmptyStateDialog
          onDismiss={onDismissCallback}
          onUpdateShopsRequest={updateCatalogues}
        />
      );
    }

    const checkedQty =
      flatProductsList &&
      getCheckedQty(
        checked,
        flatProductsList,
        groupConflicts || [],
        productsOnly,
      );
    const isProductsListIsEmpty = !flatProductsList?.length && !productsLoading;
    const isProductsNotFound =
      !(
        (productsTree?.length || 0) - (showPrependEntryPointProduct ? 1 : 0) >
        0
      ) && !productsLoading;

    return (
      <DefaultDialog
        header={window.i18next.t(
          'ChooseFacebookShopProductsDialog-string-1705-choose-products-in-a-catalog',
        )}
        onDismiss={onDismissCallback}
        dialogStyle={DIALOG_STYLE}
      >
        <SimpleCombobox<FacebookShopCataloguesQuery_page_facebookShopCatalogues_catalogues>
          items={catalogues}
          selectedItem={
            catalogues.find(propEq('id', currentCatalogId)) || catalogues[0]
          }
          itemToString={(item) => item?.name || ''}
          onSelect={(selectedItem) => {
            if (selectedItem) {
              setCurrentCatalogId(selectedItem.id);
              sendEvent({
                category: 'flows',
                action: 'Catalogue change',
                label: PluginType.facebook_shops_entry_point,
                propertyBag: {
                  catalogueId: selectedItem.id,
                },
              });
            }
          }}
          menuboxStyle={{ minWidth: 480 }}
          renderInput={({ selectedItem, getToggleButtonProps }) => (
            <Button
              intent="secondary"
              iconRight={<Icon icon="triangle" />}
              className={css.catalogSelector}
              {...getToggleButtonProps()}
            >
              {selectedItem?.name || ''}
            </Button>
          )}
        />
        {!isProductsListIsEmpty && (
          <>
            <Spacer factor={4} />
            <Input
              placeholder="search for product"
              renderIcon={() => <Icon icon="search" color="grey" />}
              value={searchString}
              onChange={({ currentTarget: { value } }) => {
                setCurrentRequestAfter(undefined);
                setHasError(false);
                setSearchString(value);
              }}
            />
          </>
        )}
        <Spacer factor={6} />
        {isProductsListIsEmpty && (
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ height: CONTENT_HEIGHT }}
            flexDirection="column"
          >
            <div style={{ textAlign: 'center' }}>
              <Type size="18px" as="div" weight="medium">
                {window.i18next.t(
                  'ChooseFacebookShopProductsDialog-JSXText--144-no-products-in-this-catalog',
                )}
              </Type>
              <Spacer factor={2} />
              <Type as="div" size="15px_DEPRECATED">
                {window.i18next.t(
                  'ChooseFacebookShopProductsDialog-JSXText-2089-choose-another-catalog-or-add-products-in-your-facebook-shop',
                )}
              </Type>
            </div>
            <Spacer factor={6} />
            <GoToFacebookButton eventTarget="choose product dialog" />
          </Flex>
        )}

        {!isProductsListIsEmpty && isProductsNotFound && (
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ height: CONTENT_HEIGHT - 96 }}
          >
            <div style={{ textAlign: 'center' }}>
              <Type size="18px" as="div" weight="medium">
                {window.i18next.t(
                  'ChooseFacebookShopProductsDialog-JSXText--195-no-results-found',
                )}
              </Type>
              <Spacer factor={2} />
              <Type as="div" size="15px_DEPRECATED">
                {window.i18next.t(
                  'ChooseFacebookShopProductsDialog-JSXText--570-try-broadening-your-search',
                )}
              </Type>
            </div>
          </Flex>
        )}

        {!isProductsListIsEmpty && !isProductsNotFound && (
          <CheckboxTree<ProductTreeItem>
            expanded={expanded}
            onExpandedChange={setExpanded}
            checked={checked}
            onCheckedChange={setChecked}
            height={CONTENT_HEIGHT}
            treeData={productsTree}
            itemSize={({ type }) =>
              type === FlatTreeItemTypes.loader ? 50 * 5 : 50
            }
            threshold={30}
            isItemLoaded={(index, treeLength) => {
              return index < treeLength;
            }}
            loadMoreItems={loadMoreItems}
            renderItem={(props) => (
              <FacebookShopProductItem
                onRemoveProductRequest={removeProductFromEntryPoints}
                disableForCheck={!!(limit && checkedQty && checkedQty >= limit)}
                blockId={blockId}
                {...props}
              />
            )}
            renderLoader={() =>
              productsLoading ? <ItemLoadingPlaceholder /> : null
            }
            autoCheckParent={false}
          />
        )}

        {!isProductsListIsEmpty && (
          <Flex
            className={css.bottom}
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              {limit && typeof checkedQty === 'number' && (
                <>
                  {window.i18next.t(
                    'ChooseFacebookShopProductsDialog-JSXText--323-selected-products',
                  )}
                  {productsOnly
                    ? ''
                    : window.i18next.t(
                        'ChooseFacebookShopProductsDialog-string-1747-or-groups',
                      )}
                  &nbsp;
                  <span
                    style={{ color: checkedQty > limit ? 'var(--red)' : '' }}
                  >
                    {checkedQty}
                  </span>
                  {window.i18next.t(
                    'ChooseFacebookShopProductsDialog-JSXText--110-out-of',
                  )}
                  {limit}
                </>
              )}
            </div>
            <Button
              className={css.bottomButton}
              onClick={() => {
                const updatedChecked = getCheckedProductsAndGroupsByIds(
                  flatProductsList,
                  groupConflicts || [],
                  checked,
                  productsOnly,
                );
                if (checked.includes(PREPEND_ENTRY_POINT_PRODUCT_ID)) {
                  updatedChecked.unshift({
                    __typename: 'FacebookShopProductMatchingRule',
                    retailer_product_group_id: PREPEND_ENTRY_POINT_PRODUCT_ID,
                    product_id: null,
                    group_size: null,
                    preview: {
                      id: PREPEND_ENTRY_POINT_PRODUCT_ID,
                    } as facebookShopsEntryPointFragment_config_products_preview,
                  });
                }
                const notLoadedCheckedProductsIds = difference(
                  checked,
                  updatedChecked
                    .map(getIdFromProductItem)
                    .filter(Boolean) as string[],
                );

                onSubmit(
                  updatedChecked,
                  notLoadedCheckedProductsIds.filter(
                    (id) =>
                      productsOnly ||
                      !checked.includes(
                        flatProductsList?.find(propEq('id', id))
                          ?.retailer_product_group_id || '',
                      ),
                  ),
                );
                sendEvent({
                  category: 'flows',
                  action: 'Save products click',
                  label: target,
                  propertyBag: {
                    postIds: checked,
                  },
                });
              }}
            >
              {window.i18next.t(
                'ChooseFacebookShopProductsDialog-JSXText-2449-save-products',
              )}
            </Button>
          </Flex>
        )}
      </DefaultDialog>
    );
  };
