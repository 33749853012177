import { HEXColors } from '@ui/_common/colors';
import i18next from 'i18next';
import { logFlowPluginEvent } from '../../../../../../../utils/Analytics';
import { pluginWidth } from '../../../../../../plugin_consts';
import { showZapierDialog } from '../../../../index';
import { HLayout } from '../../../../../../../components/Elements/Layouts';
import { HTMLText } from '../../../../../../../components/Elements/Shapes';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../../../../../PixiFieldRepository';
import {
  removeTooltip,
  tooltipScaled,
} from '../../../../../../helpers/TooltipHelpers';
import { ZapierZap } from '../../../../query';
import { getI18ZapierKey } from '../../../../utils/getI18ZapierKey';
import { ZapierTemplatesQuery_zapierTemplates_steps } from '../../../../ZapierZapsDialog/@types/ZapierTemplatesQuery';
import { createZapierTemplateItemTooltipContent } from '../../../../ZapierZapsDialog/components/ZapierTemplateItemTooltipContent';
import { ZapItemState } from './ZapItemState';
import { ZapSteps } from './ZapSteps';

interface ZapListItemViewProps {
  zap: ZapierZap;
  pluginId: string;
  cardId: string;
  blockId?: string;
  onUpdateZapsListRequest: () => void;
}

export class ZapListItemView extends HLayout {
  private tooltipHitBox: HLayout;

  constructor({
    zap,
    onUpdateZapsListRequest,
    pluginId,
    cardId,
    blockId,
  }: ZapListItemViewProps) {
    super({
      width: pluginWidth - 32,
      height: 32,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    const { title, state, url, steps } = zap;

    const isAnotherAdminBox =
      zap.owner?.id !== getFlowControllerStrict().flow.user.id;

    const isViewOnly = getPixiFieldStrict().isViewOnly();

    if (isAnotherAdminBox && !isViewOnly) {
      this.alpha = 0.3;
      tooltipScaled({
        view: this,
        text: i18next.t(getI18ZapierKey('thisZapIsSetUpBy'), {
          adminName:
            zap.owner?.name || i18next.t(getI18ZapierKey('defaultAdmin')),
        }),
        overTimeout: 300,
      });
    }

    this.tooltipHitBox = new HLayout();

    const zapStepsView = new ZapSteps(zap);

    this.tooltipHitBox.addToLayout(zapStepsView, {
      margin: {
        top: 0,
        right: 8,
        bottom: 0,
        left: 0,
      },
    });

    this.tooltipHitBox.addToLayout(
      new HTMLText({
        text: `<u>${title || i18next.t(getI18ZapierKey('defaultZapName'))}</u>`,
        singleLine: true,
        width: 148,
        fontSize: 15,
      }),
      {
        margin: {
          top: 4,
          right: 8,
          bottom: 0,
          left: 0,
        },
      },
    );

    this.addToLayout(this.tooltipHitBox);

    tooltipScaled({
      view: this.tooltipHitBox,
      children: createZapierTemplateItemTooltipContent({
        template: {
          steps: steps
            .map(({ app }) => app)
            .filter(Boolean) as ZapierTemplatesQuery_zapierTemplates_steps[],
          description: zap.title || undefined,
        },
      }),
      overTimeout: 300,
    });

    const showDialog = () => {
      logFlowPluginEvent(pluginId, 'Edit Zap click');
      showZapierDialog({
        pluginId,
        cardId,
        blockId,
        header: i18next.t(getI18ZapierKey('editZap')),
        initialUrl: url,
        onZapStateChange: onUpdateZapsListRequest,
        onDismiss: onUpdateZapsListRequest,
      });
    };

    const zapStateView = new ZapItemState({
      zapState: state,
      onTooltipClick: showDialog,
    });

    this.addToLayout(zapStateView, {
      margin: {
        top: 7,
        right: 8,
        bottom: 0,
        left: 0,
      },
    });

    if (isAnotherAdminBox || isViewOnly) {
      zapStepsView.interactive = false;
      zapStateView.interactive = false;
      this.tooltipHitBox.interactive = false;
    } else {
      this.on('click', (event: Event) => {
        event.stopPropagation();
        showDialog();
      });
    }
  }

  destroy() {
    removeTooltip(this);
    removeTooltip(this.tooltipHitBox);
    super.destroy();
  }
}
