import i18next from 'i18next';
import { createLinkToConflictTarget } from './createLinkToConflictTarget';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { showResolveEntryPointConflictDialog } from './showResolveEntryPointConflictDialog';
import { Node } from '../../../Node';
import { Card } from '../../../types';
import { HEXColors } from '@ui/_common/colors';

export interface ConflictingData {
  block_id?: string;
  flow_id?: string;
  block_title?: string;
  flow_title?: string;
  entry_point_id?: string;
  with_configure_tab?: boolean;
  conflicts_with_welcome_message?: boolean;
}

export const resolveEntryPointConflict = (
  node: Node,
  conflictingData: ConflictingData,
  card: Card,
) => {
  const { botId, id } = node.controller.flow;
  const link =
    id === conflictingData.flow_id
      ? i18next.t('resolveEntryPointConflict-string--618-this-flow')
      : createLinkToConflictTarget(botId, conflictingData);

  const epLink =
    id === conflictingData.flow_id
      ? `<a href="/bot/${botId}/flows/${id}?blockId=${
          conflictingData.entry_point_id
        }" style="color: ${HEXColors.blue};" target="_blank">${i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.common.another',
        )}</a>`
      : i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.common.another',
        );

  if (
    [
      PluginType.persistent_menu_entry_point,
      PluginType.instagram_persistent_menu_entry_point,
    ].includes(card.plugin_id)
  ) {
    showResolveEntryPointConflictDialog(
      node,
      conflictingData.entry_point_id,
      conflictingData.with_configure_tab
        ? {
            header: i18next.t(
              'resolveEntryPointConflict-string--724-enable-this-persistent-menu-entry-point',
            ),
            text: `Enabling this Entry Point will disable the persistent menu that you set up in the <a href="/bot/${botId}/settings" style="color: ${HEXColors.blue};" target="_blank">Configure tab</a>. (To revert to that persistent menu, switch this one off at any time.)`,
          }
        : {
            header: i18next.t(
              'resolveEntryPointConflict-string-8118-youve-already-set-up-a-persistent-menu',
            ),
            text: `${i18next.t(
              'resolveEntryPointConflict-Template-5629-you-have-already-enabled-a-persistent-menu-entry-point-in',
            )}${link}${i18next.t(
              'resolveEntryPointConflict-Template--144-itll-be-disabled-if-you-decide-to-enable-this-one-instead',
            )}`,
          },
    );
  }
  if (
    [
      PluginType.instagram_bot_link_entry_point,
      PluginType.ref_link_entry_point,
      PluginType.whatsapp_bot_link,
    ].includes(card.plugin_id)
  ) {
    showResolveEntryPointConflictDialog(
      node,
      conflictingData.entry_point_id,
      conflictingData.flow_id
        ? {
            header: i18next.t(
              'resolveEntryPointConflict-string-4491-youve-already-used-this-link-in-another-entry-point',
            ),
            text: `${i18next.t(
              'resolveEntryPointConflict-Template--509-you-have-already-used-this-link-in-another-entry-point-in',
            )}${link}${i18next.t(
              'resolveEntryPointConflict-Template-1399-itll-be-disabled-there-if-you-decide-to-use-it-here-instead',
            )}`,
          }
        : {
            header: i18next.t(
              'resolveEntryPointConflict-string--171-youve-already-used-this-link-in-a-block',
            ),
            text: `${i18next.t(
              'resolveEntryPointConflict-Template-1226-you-have-already-used-this-link-in',
            )}${link}${i18next.t(
              'resolveEntryPointConflict-Template-2123-itll-be-disabled-there-if-you-decide-to-use-it-here-instead',
            )}`,
          },
    );
  }
  if (card.plugin_id === PluginType.fb_page_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'resolveEntryPointConflict-string--891-youve-already-set-up-a-facebook-page-entry-point',
      ),
      text: `${i18next.t(
        'resolveEntryPointConflict-Template-7997-you-have-already-enabled-a-facebook-page-entry-point-in',
      )}${link}${i18next.t(
        'resolveEntryPointConflict-Template--172-itll-be-disabled-if-you-decide-to-enable-this-one-instead',
      )}`,
    });
  }
  if (card.plugin_id === PluginType.instagram_direct_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'resolveEntryPointConflict-string--204-youve-already-set-up-a-instagram-direct-entry-point',
      ),
      text: `${i18next.t(
        'resolveEntryPointConflict-Template--681-you-have-already-enabled-a-instagram-direct-entry-point-in',
      )}${link}${i18next.t(
        'resolveEntryPointConflict-Template--172-itll-be-disabled-if-you-decide-to-enable-this-one-instead',
      )}`,
    });
  }
  if (card.plugin_id === PluginType.instagram_comments_autoreply_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'resolveEntryPointConflict-string-9394-youve-already-set-up-a-instagram-comments-autoreply-entry-point',
      ),
      text: `${i18next.t(
        'resolveEntryPointConflict-Template-2013-you-have-already-enabled-a-instagram-comments-autoreply-entry-point-in',
      )}${link}${i18next.t(
        'resolveEntryPointConflict-Template--172-itll-be-disabled-if-you-decide-to-enable-this-one-instead',
      )}`,
    });
  }
  if (card.plugin_id === PluginType.instagram_story_mention_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'resolveEntryPointConflict-string--319-youve-already-set-up-a-story-mention-entry-point',
      ),
      text: `${i18next.t(
        'resolveEntryPointConflict-Template--613-you-have-already-enabled-a-story-mention-entry-point-in',
      )}${link}${i18next.t(
        'resolveEntryPointConflict-Template--172-itll-be-disabled-if-you-decide-to-enable-this-one-instead',
      )}`,
    });
  }
  if (card.plugin_id === PluginType.instagram_story_reply_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'resolveEntryPointConflict-string--208-youve-already-set-up-a-story-reply-entry-point',
      ),
      text: `${i18next.t(
        'resolveEntryPointConflict-Template-4413-you-have-already-enabled-a-story-reply-entry-point-in',
      )}${link}${i18next.t(
        'resolveEntryPointConflict-Template--172-itll-be-disabled-if-you-decide-to-enable-this-one-instead',
      )}`,
    });
  }
  if (card.plugin_id === PluginType.comments_autoreply_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'resolveEntryPointConflict-string--841-youve-already-set-up-a-comments-autoreply-entry-point',
      ),
      text: `${i18next.t(
        'resolveEntryPointConflict-Template--164-you-have-already-enabled-a-comments-autoreply-entry-point-in',
      )}${link}${i18next.t(
        'resolveEntryPointConflict-Template--138-itll-be-automatically-disabled-if-you-decide-to-activate-this-one-instead',
      )}`,
    });
  }
  if (card.plugin_id === PluginType.facebook_shops_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'resolveEntryPointConflict-string--634-youve-already-set-up-a-facebook-shop-entry-point',
      ),
      text: `${i18next.t(
        'resolveEntryPointConflict-Template-5717-you-have-already-enabled-a-facebook-shop-entry-point-in',
      )}${link}${i18next.t(
        'resolveEntryPointConflict-Template--138-itll-be-automatically-disabled-if-you-decide-to-activate-this-one-instead',
      )}`,
    });
  }
  if (card.plugin_id === PluginType.popup_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t('shopify.conflict.header'),
      text: i18next.t('shopify.conflict.text', { link }),
    });
  }
  if (card.plugin_id === PluginType.shopify_customer_chat_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t('shopify.customerChat.conflict.header'),
      text: i18next.t('shopify.customerChat.conflict.text', { link }),
    });
  }
  if (card.plugin_id === PluginType.shopify_back_in_stock) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t('shopify.backInStock.conflict.header'),
      text: i18next.t('shopify.backInStock.conflict.text', { link }),
    });
  }
  if (card.plugin_id === PluginType.whatsapp_direct_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappDirect.conflict.header',
      ),
      text: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappDirect.conflict.text',
        { link },
      ),
    });
  }
  if (card.plugin_id === PluginType.whatsapp_widget_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappWidgetEntryPoint.conflict.header',
      ),
      text: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappWidgetEntryPoint.conflict.text',
        { link, epLink },
      ),
    });
  }
  if (card.plugin_id === PluginType.whatsapp_popup_entry_point) {
    showResolveEntryPointConflictDialog(node, conflictingData.entry_point_id, {
      header: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappPopup.conflict.header',
      ),
      text: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappPopup.conflict.text',
        { link, epLink },
      ),
    });
  }
};
