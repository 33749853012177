import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { ContentTypeItem } from './types';

export const MESSENGER_CONTENT_TYPES_ITEMS: ContentTypeItem[] = [
  {
    id: PluginType.text,
    title: 'Text and buttons',
  },
  {
    id: PluginType.image,
    title: 'Image',
  },
  {
    id: PluginType.gallery,
    title: 'Gallery',
  },
  {
    id: PluginType.video,
    title: 'Video',
  },
  {
    id: PluginType.audio,
    title: 'Audio',
  },
  {
    id: PluginType.facebook_shops_product_list,
    title: 'Product Gallery',
  },
];

export const MESSENGER_CONTENT_TYPES_IDS = [
  ...MESSENGER_CONTENT_TYPES_ITEMS.map(({ id }) => id),
  PluginType.quick_reply,
];

export const INSTAGRAM_CONTENT_TYPES_ITEMS: ContentTypeItem[] = [
  {
    id: PluginType.text,
    title: 'Text message',
  },
  {
    id: PluginType.image,
    title: 'Image',
  },
  {
    id: PluginType.video,
    title: 'Video',
  },
  {
    id: PluginType.audio,
    title: 'Audio',
  },
];

export const INSTAGRAM_CONTENT_TYPES_IDS = [
  ...INSTAGRAM_CONTENT_TYPES_ITEMS.map(({ id }) => id),
  PluginType.quick_reply,
];
