import React from 'react';
import { Button } from '@ui/Button';
import { Type } from '@ui/Type';
import { sendEvent } from 'cf-common/src/analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import * as css from './SomethingWentWrong.css';

interface SomethingWentWrongProps {
  onRefresh: () => void;
  pluginId: PluginType;
  style?: React.CSSProperties;
}

export const SomethingWentWrong: React.FC<SomethingWentWrongProps> = ({
  onRefresh,
  style,
  pluginId,
}) => (
  <div className={css.wrapper} style={style}>
    <div className={css.messageBlock}>
      <div className={css.header}>
        <Type as="h4" weight="medium" size="18px">
          {window.i18next.t(
            'SomethingWentWrong-JSXText-1214-oops-something-went-wrong',
          )}
        </Type>
      </div>
      <Button
        intent="secondary"
        onClick={() => {
          sendEvent({
            category: 'flows',
            action: 'refetch ads (error)',
            label: pluginId,
          });
          onRefresh();
        }}
      >
        {window.i18next.t('SomethingWentWrong-JSXText--113-try-again')}
      </Button>
    </div>
  </div>
);
