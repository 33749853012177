import { FullCounterStats } from '../../../utils/Statistics/types';
import { buttonWidth } from '../../plugin_consts';
import { resByFunc } from '../../utils';
import { ItemStatView } from '../ItemStatView/ItemStatView';
import {
  TextCardSimpleButton,
  TextCardSimpleButtonProps,
} from './TextCardSimpleButton';

interface TextCardSimpleButtonWithStatProps extends TextCardSimpleButtonProps {
  stats?: FullCounterStats;
}

const RIGHT_STAT_MARGIN = 4;

export class TextCardSimpleButtonWithStat extends TextCardSimpleButton {
  private readonly statsView: ItemStatView | undefined;

  constructor({ stats, margin, ...props }: TextCardSimpleButtonWithStatProps) {
    super({
      ...props,
      margin: () => {
        const statWidth = this.statsView?.textWidth ?? 0;
        const currentMargin = statWidth ? statWidth + RIGHT_STAT_MARGIN : 0;
        return {
          ...resByFunc(margin),
          left: currentMargin,
          right: currentMargin,
        };
      },
      overlayWidth: buttonWidth,
      overlayOffset: () => ({
        x: -((this.statsView?.textWidth ?? 0) + RIGHT_STAT_MARGIN),
        y: 0,
      }),
    });

    if (stats) {
      this.statsView = new ItemStatView({
        stats,
        showPercentValue: true,
      });

      this.addToLayout(this.statsView, {
        align: 'end',
        verticalAlign: 'center',
        margin: {
          left: -RIGHT_STAT_MARGIN,
        },
        gone: () => this.isEditing(),
      });
    }
  }
}
