import { HLayout } from '@components/FlowBuilder/components/Elements/Layouts';
import {
  HTMLText,
  TextureShape,
} from '@components/FlowBuilder/components/Elements/Shapes';
import { HEXColors } from '@ui/_common/colors';
import { Margin } from '../../utility_classes';
import { infoSvgTexture } from '../../../assets/textures';
import { removeTooltip, tooltipScaled } from '../../helpers/TooltipHelpers';

export interface WhatsappListSectionHeaderParams {
  tooltip: string;
  text: string;
}

export class WhatsappListSectionHeaderView extends HLayout {
  private readonly icon: HLayout;
  private readonly htmlText: HTMLText;

  constructor({ text, tooltip }: WhatsappListSectionHeaderParams) {
    super();

    const title = new HLayout();

    this.htmlText = new HTMLText({
      text,
      fontSize: 12,
      fontStyle: 'semibold',
      fontColor: HEXColors.cobalt,
      trustedHtml: true,
    });

    this.icon = new HLayout({
      cursor: { in: 'pointer' },
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    }).addToLayout(
      new TextureShape({
        texture: infoSvgTexture,
        width: 12,
        height: 12,
      }),
    );

    tooltipScaled({
      view: this.icon,
      text: tooltip,
    });

    title
      .addToLayout(this.htmlText, { margin: new Margin({ right: 4 }) })
      .addToLayout(this.icon, { margin: new Margin({ x: 2, y: 3 }) });

    this.addToLayout(title);
  }

  destroy(): void {
    super.destroy();

    removeTooltip(this.icon);
  }
}
