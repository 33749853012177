import i18next from 'i18next';
import { getI18nCommentsAutoreplyKey } from '../../EntryPointCommentsAutoreply/utils/getI18nCommentsAutoreplyKey';
import { ReplyToByKeywordsGroups } from '../components/ReplyToByKeywordGroups/ReplyToByKeywordsGroups';

export interface CommentsConfig {
  already_subscribed_setup_enabled: boolean;
}

export const validateCommentsReplies = (
  replyTo: ReplyToByKeywordsGroups,
  replyToForCurrentSubscribers: ReplyToByKeywordsGroups,
  config: CommentsConfig,
) =>
  replyTo.isValid() &&
  (replyToForCurrentSubscribers.isValid() ||
    !config.already_subscribed_setup_enabled)
    ? undefined
    : {
        message: i18next.t(
          getI18nCommentsAutoreplyKey('keywordsValidationText'),
        ),
        isBlockError: true,
      };
