import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { Level, log } from 'cf-common/src/logger';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { toaster, ServiceMessageType } from '@services/MessageService';
import { VLayout } from '../../../components/Elements/Layouts';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { pluginAddAttributeSvgTexture } from '../../../assets/textures';
import { Node } from '../../../Node';
import { tooltips } from '../../Menu/menu_view';
import { notEmptyString } from '../../validation';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { HTMLText } from '../../../components/Elements/Shapes';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { addAttributePluginFragment_config as AddAttributePluginConfig } from '../../../../Plugins/AddAttributePlugin/@types/addAttributePluginFragment';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { SYSTEM_ATTRIBUTE_ERROR } from './const';

export class AddAttributePluginView
  extends VLayout
  implements StatefulPluginDelegate<AddAttributePluginConfig>
{
  TEST_NAME = 'AddAttributePluginView';

  public readonly state: StatefulPlugin<AddAttributePluginConfig>;

  private descriptionView: HTMLText;
  private get descriptionText() {
    const { title, value } = this.state.data.config;
    if (!title && !value) {
      return `<span style="color: ${HEXColors.grey};">${i18next.t(
        'modernComponents.FlowBuilder.views.components.AddAttributePlugin.emptyConfigDescription',
        { interpolation: { prefix: '_' } }, // ignore {{
      )}</span>`;
    }
    return `${
      title
        ? `<b style="color: ${HEXColors.blue};">{{${title}}}</b>`
        : `<span style="color: ${HEXColors.grey};">{{attribute}}</span>`
    }${window.i18next.t('AddAttribute-Template-1057-is')}${
      value
        ? `<b>${this.getTextWithDecoratedAttributes(value)}</b>`
        : `<span style="color: ${HEXColors.grey};">value</span>`
    }`;
  }

  private getTextWithDecoratedAttributes(text: string) {
    return text
      .replace(/{{/g, `<span style="color: ${HEXColors.blue};">{{`)
      .replace(/}}/g, '}}</span>');
  }

  constructor(state: StatefulPlugin<AddAttributePluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this.state = state;
    this.state.setDelegate(this);

    const titleLayout = new PluginTitleLayout(
      false,
      window.i18next.t('AddAttribute-string-2074-set-user-attribute'),
      pluginAddAttributeSvgTexture,
      this.state.data,
      tooltips().add_attribute,
    );
    this.layout(titleLayout, { margin: { top: 15, left: 10 } });

    this.descriptionView = new HTMLText({
      text: this.descriptionText,
      fontSize: 15,
      trustedHtml: true,
      width: pluginWidth - 30,
    });

    this.layout(this.descriptionView, {
      margin: { top: 5, left: 20, bottom: 15 },
    });

    this.on('click', () => {
      getPixiFieldStrict().fixBlockPosition(
        node.blockView,
        getPanelWidth(PluginType.go_to_block_plugin),
      );
      editPluginInEditorPanel(node.id, this.state.data);
    });
  }

  onBeforeRender() {
    this.descriptionView.text(this.descriptionText);
  }

  pluginDidSet() {
    this.renderNode();
  }

  pluginDidSetError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.AddAttributePlugin.failedToSavePluginMessageText',
        ),
      },
    });
    log({
      msg: 'Error while saving Add Attribute Plugin',
      level: Level.error,
      data: {
        error: error.toString(),
      },
    });
  }

  hasSignificantChangesInConfig() {
    const { title, value } = this.state.data.config;
    return notEmptyString(title) || notEmptyString(value);
  }

  validationError() {
    const validationError =
      this.state.data.validation_details?.fields?.[0]?.error;
    if (validationError === SYSTEM_ATTRIBUTE_ERROR) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.AddAttributePlugin.systemAttributeError',
      );
    }

    const { title, value } = this.state.data.config;
    if (!title && !value) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.AddAttributePlugin.attributeAndValueValidationText',
      );
    }
    return false;
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
