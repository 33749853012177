import gql from 'graphql-tag';

export const ADS_ACCOUNT_QUERY = gql`
  query AdsAccountQuery {
    facebookAdAccounts {
      id
      name
    }
  }
`;
