import { IS_PRODUCTION_BUILD } from 'cf-common/src/environment';
import { ReadonlyState } from '../StatefulPlugin/ReadonlyState';
import { ApolloState } from '../StatefulPlugin/ApolloState';
import { getPixiFieldStrict } from '../PixiFieldRepository';
import { Card } from '../types';
import { Node } from '../Node';
import { Layout } from '../components/Elements/Layouts/Layout';
import { isApolloBasedPlugin } from './plugins.configuration';

// TODO specify Card generic
export const createStatefulPlugin = (
  View: new (state: ReadonlyState<any>, node: Node) => Layout,
  card: Card,
  node: Node,
) => {
  if (!isApolloBasedPlugin(card.plugin_id) && !IS_PRODUCTION_BUILD) {
    throw new Error(
      'Trying to invoke `createStatefulPlugin` for non Apollo based plugin',
    );
  }

  const StateClass = getPixiFieldStrict().isViewOnly()
    ? ReadonlyState
    : ApolloState;

  const state = new StateClass(card, node.block.id);
  const view = new View(state, node);
  // TODO: for compatibility. Remove after refactoring!
  // @ts-expect-error
  view._card = card;
  // @ts-expect-error
  view._node = node;
  return view;
};
