import noop from 'lodash-es/noop';
import i18next from 'i18next';
import {
  CommentsAutoreplyPostsSelector,
  CommentsAutoreplyPostsSelectorPluginConfig,
} from '../components/CommentsAutoreplyPostsSelector';
import {
  adCommentsAutoreplyEntryPointFragment_config as EntryPointAdCommentsAutoreplyConfig,
  adCommentsAutoreplyEntryPointFragment_config_posts as AdCommentsAutoreplyPost,
} from '../../../../../Plugins/AdCommentsAutoreplyEntryPoint/@types/adCommentsAutoreplyEntryPointFragment';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import {
  IEntryPointValidationError,
  PluginType,
} from '@components/FlowBuilder/types';
import { PostViewModel } from '../components/PostItem';
import {
  showChooseAdPostsDialog,
  showPermissionPopup,
} from '@components/FlowBuilder/components';
import { ValidationError } from '../../common/utils/types';

export interface MessengerAdCommentsAutoreplyPostsSelectorPluginConfig
  extends CommentsAutoreplyPostsSelectorPluginConfig<EntryPointAdCommentsAutoreplyConfig>,
    IEntryPointValidationError {}

export interface MessengerAdCommentsAutoreplyPostsSelectorProps {
  plugin: MessengerAdCommentsAutoreplyPostsSelectorPluginConfig;
  logger: PluginLogger;
}

export class MessengerAdCommentsAutoreplyPostsSelector extends CommentsAutoreplyPostsSelector<
  // @ts-expect-error Придумать как правильно затипизировать
  AdCommentsAutoreplyPost,
  EntryPointAdCommentsAutoreplyConfig
> {
  getPostData({ ad }: AdCommentsAutoreplyPost): PostViewModel {
    return {
      __typename: ad.__typename,
      id: ad.id,
      link: null,
      text: ad.name,
      picture: ad.thumbnailUrl,
    };
  }

  onShowChoosePostsDialog(updatePostList: () => void): void {
    const entryPointId = this.params.plugin._node.id;

    showChooseAdPostsDialog({
      entryPointId,
      accountId: this.params.plugin.state.data.config.adAccount?.id,
      selectedPosts: this.params.plugin.state.data.config.posts || [],
      pluginId: this.params.plugin.state.data.plugin_id,
      onSubmit: ({ posts, adAccount }) => {
        this.params.logger.log('Add posts click', {
          postIds: posts.map((post) => post.ad.id),
          adAccount,
        });

        if (adAccount?.name && adAccount.name !== this.headerText.text()) {
          this.headerText.text(adAccount.name);
        }

        this.params.plugin.state.set(({ config }) => ({
          config: {
            ...config,
            adAccount,
            posts: posts ?? [],
          },
        }));

        this.removePostsFromOtherPlugin(
          posts.map((v) => v.ad.id),
          false,
        );
        updatePostList();
      },
      onPostRemove: (post) => {
        this.removePostsFromOtherPlugin([post.ad.id], true);
      },
    });
  }

  handleButtonClick() {
    /**
     * TODO Подумать как красиво переиспользовать логику подключения постов.
     * Условие скопипащено из AdsSelector.ts:149
     */
    if (
      this.params.plugin.validationError()?.type === ValidationError.permission
    ) {
      showPermissionPopup({
        pluginId: this.params.plugin.state.data.plugin_id as PluginType,
        onDismiss: noop,
      });
    } else {
      super.handleButtonClick();
    }
  }

  private readonly params: MessengerAdCommentsAutoreplyPostsSelectorProps;

  constructor(params: MessengerAdCommentsAutoreplyPostsSelectorProps) {
    super(params.plugin, params.logger, {
      updateCardViaDeprecatedMethod: true,
      pluginType: PluginType.ad_comments_autoreply_entry_point,
      hideDrowdown: true,
      showHeader: true,
      title: params.plugin.state.data.config.adAccount?.name || '',
      editPostsButtonTitle: i18next.t(
        'modernComponents.FlowBuilder.views.components.AdCommentsAutoreplyEntryPoint.editAds',
      ),
      choosePostButtonTitle: i18next.t(
        'modernComponents.FlowBuilder.views.components.AdCommentsAutoreplyEntryPoint.chooseAds',
      ),
    });

    this.params = params;
  }
}
