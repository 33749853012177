import i18next from 'i18next';
import { Subscription } from '@utils/Data/types';
import { getLiveChatSettingsObserver } from '@utils/Data/LiveChat/Settings/observers';
import { Platform } from '@globals';
import {
  TIME_INTERVALS,
  TimeIntervalsIds,
  TimeInterval,
  timeoutIntervalIsNotEnumerated,
} from '../../../../../modern-ui/TimeoutInput';
import { pluginLiveChatSvgTexture } from '../../../assets/textures';
import { VLayout } from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { updatePluginData } from '../../../StatefulPlugin/ApolloState/utils';
import { flowBuilderLiveChatPluginFragment_config as LiveChatPlugin } from '../../../../Plugins/FlowBuilderLiveChatPlugin/@types/flowBuilderLiveChatPluginFragment';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { Node } from '../../../Node';
import { PluginType } from '../../../types';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { tooltips } from '../../Menu/menu_view';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';

export class LiveChatPluginView
  extends VLayout
  implements StatefulPluginDelegate<LiveChatPlugin>
{
  TEST_NAME = 'LiveChatPluginView';

  private _node: Node;
  public readonly state: StatefulPlugin<LiveChatPlugin>;

  private titleView!: PluginTitleLayout;
  private persistentMenuTitleView!: HTMLText;
  private chatTimeoutView!: HTMLText;

  private liveChatSettingsSubscription: Subscription | null = null;

  constructor(state: StatefulPlugin<LiveChatPlugin>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.drawLayout();

    this.watchGlobalLiveChatSettings();

    this.on('click', () => {
      this.openLeftPanel();
    });
  }

  onBeforeRender() {
    const { dynamic_menu_title, timeout_config } = this.state.data.config;
    this.persistentMenuTitleView.text(
      `${i18next.t('LiveChat-Template--113-persistent-menu')}${
        dynamic_menu_title || ''
      }`,
    );
    const interval =
      TIME_INTERVALS.find((item) => item.id === timeout_config?.time_period) ||
      ({} as TimeInterval);
    this.chatTimeoutView.text(
      `${i18next.t('LiveChat-Template--260-chat-timeout')}${
        timeout_config?.time_value ? `${timeout_config?.time_value} ` : ''
      }${
        interval.titleKey
          ? i18next.t(interval.titleKey, {
              count: timeout_config?.time_value ?? 0,
            })
          : ''
      }`,
    );
  }

  pluginDidSet() {
    this.renderNode();
  }

  validationError() {
    const {
      config: { timeout_config },
      is_valid,
    } = this.state.data;

    if (
      timeout_config?.time_period &&
      timeoutIntervalIsNotEnumerated(
        timeout_config.time_period as TimeIntervalsIds,
      ) &&
      timeout_config?.time_value
    ) {
      return true;
    }
    return !is_valid;
  }

  destroy() {
    this.liveChatSettingsSubscription?.unsubscribe();
    this.state.destroy();
    super.destroy();
  }

  private watchGlobalLiveChatSettings() {
    const { botId } = this._node.controller.flow;
    const { id } = this.state.data;
    this.liveChatSettingsSubscription = getLiveChatSettingsObserver(
      botId,
    ).subscribe(({ data: queryData }) => {
      // do not believe types here queryData could be empty or without some fields
      const updatedConfig = queryData?.bot?.livechatSettings?.config;
      if (!updatedConfig) return;
      updatePluginData<LiveChatPlugin>(id, (pluginData) => {
        // eslint-disable-next-line no-param-reassign
        pluginData.card.config = {
          ...pluginData.card.config,
          ...updatedConfig,
        };
      });
    });
  }

  private drawLayout() {
    this.titleView = new PluginTitleLayout(
      false,
      i18next.t('LiveChat-string--134-live-chat'),
      pluginLiveChatSvgTexture,
      this.state.data,
      tooltips().liveChat,
    );
    this.layout(this.titleView, {
      margin: { top: 15, bottom: 5, left: 10 },
    });

    this.persistentMenuTitleView = new HTMLText({
      width: pluginWidth - 24,
      fontSize: 15,
      singleLine: true,
    });

    this.chatTimeoutView = new HTMLText({
      width: pluginWidth - 24,
      fontSize: 15,
      singleLine: true,
    });

    this.addToLayout(this.persistentMenuTitleView, {
      margin: { left: 15, right: 15 },
      gone: () => getFlowPlatform() !== Platform.facebook,
    }).addToLayout(this.chatTimeoutView, {
      margin: { left: 15, right: 15, bottom: 15 },
    });
  }

  private openLeftPanel() {
    const node = this._node;
    getPixiFieldStrict().fixBlockPosition(
      node.blockView,
      getPanelWidth(PluginType.flowbuilder_livechat),
    );
    editPluginInEditorPanel(node.id, this.state.data);
  }
}
