import { FROM_PERMISSION_GRANT } from '@components/Plugins/FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { goToGetPermissions } from '@utils/FacebookPages/goToGetPermissions';
import { PermissionGroup } from '@utils/Permissions';
import { propEq } from 'ramda';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';
import { ValidationError, ValidationResult } from './types';

export const validateIsGivenPermissions = (
  group: PermissionGroup,
  message: string,
  cb?: () => void,
): ValidationResult | void => {
  const permission = getFlowControllerStrict().flow.verifiedPermissions?.find(
    propEq('id', group),
  );

  if (!permission) {
    throw new Error(
      `Verified permissions doen't have an item with id ${group}. You should fetch it before using`,
    );
  }

  if (permission.enabled) {
    return undefined;
  }

  return {
    type: ValidationError.permission,
    message: `${message} <br/><br/> <span style="text-decoration: underline;">Grant Permission</span>`,
    onClick: () => {
      cb?.();
      goToGetPermissions({
        permissionGroup: PermissionGroup.comments,
        interruptedHref: `${window.location.href}?${FROM_PERMISSION_GRANT}`,
      });
    },
    isBlockError: true,
  };
};
