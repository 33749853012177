/* eslint-disable import/no-webpack-loader-syntax */
import { HEXColors } from '@ui/_common/colors';
import { escapeHtml } from 'xss';
import { createStatTooltip } from '../../../../components/BlockStatsView/components/StatTooltip';
import { tooltipScaled } from '../../../../helpers/TooltipHelpers';
import { STAT_VIEW_WIDTH } from '../../../../kit/ItemStatView/ItemStatView';
import { blockWidth, pluginWidth } from '../../../../plugin_consts';
import { HTMLText } from '../../../../../components/Elements/Shapes';
import { MainLayout } from '../../../../../components/Elements/Layouts';
import { removeExtraSpaces } from '../../../../../../../utils/String/removeExraSpaces';
import {
  getBase64FromImageUrl,
  GRAY_ONE_PIXEL_PNG,
} from '../../../../../../../utils/Image';
import closeCustomerChatIcon from '!!raw-loader!../../../../../assets/entry_point/close_customer_chat.svg';
import messengerIcon from '!!raw-loader!../../../../../assets/entry_point/messenger.svg';
import messengerIconBig from '!!raw-loader!../../../../../assets/entry_point/messanger_big.svg';
import botDefaultPicture from '../../../../../../../assets2/images/av_def_bot.png';
import {
  DEFAULT_GREETING,
  DEFAULT_THEME_COLOR,
} from '../../../../../../Plugins/CustomerChatEntryPoint/CustomerChatEntryPointConst';
import { Node } from '../../../../../Node';
import { LineStartView } from '../../../../components/Line/LineStartView';
import { isFlowStatsEnabled } from '../../../../components/BlockStatsView/utils';
import { FullCounterStats } from '../../../../../utils/Statistics/types';
import {
  CustomerChatConfig,
  CustomerChatData,
  CustomerChatStats,
  CustomerChatViewProps,
} from './types';
import { createLineMenu } from '../../../../Menu/createLineMenu';

const GREETING_PLACE = '%GREETING_PLACE%';

const calcPercent = (
  stats: FullCounterStats | undefined,
  percentBase: number | null | undefined,
) =>
  percentBase && stats?.counter?.uniqStats
    ? Math.round((stats.counter.uniqStats.clicked! / percentBase) * 100)
    : 0;

export class CustomerChatView extends MainLayout {
  public customerChatView: HTMLText;
  private pagePictureString: string | undefined;
  private statsInited: boolean = false;
  private stats: CustomerChatStats | undefined;
  private readonly lineStartView: LineStartView;
  private readonly lineStartGuestView: LineStartView;
  private readonly lineStartUpgradeView: LineStartView;
  private readonly _node: Node;
  private readonly getData: () => CustomerChatData;
  private readonly onChange: (config: CustomerChatConfig) => void;

  constructor({ node, getData, onChange }: CustomerChatViewProps) {
    super();
    this._node = node;
    this.getData = getData;
    this.onChange = onChange;

    this.customerChatView = new HTMLText({
      width: pluginWidth,
      trustedHtml: true,
    });

    this.addToLayout(this.customerChatView);

    this.lineStartView = this.createLineView('block_id');
    this.lineStartGuestView = this.createLineView('guest_block_id', false);
    this.lineStartUpgradeView = this.createLineView('upgrade_block_id', false);

    this.addToLayout(this.lineStartView, {
      margin: { top: -202, left: -8 },
      align: 'end',
      verticalAlign: 'bottom',
    });

    this.addToLayout(this.lineStartGuestView, {
      margin: { top: -156, left: -8 },
      align: 'end',
      verticalAlign: 'bottom',
    });

    this.addToLayout(this.lineStartUpgradeView, {
      margin: { top: -80, left: -8 },
      align: 'end',
      verticalAlign: 'bottom',
    });

    this.loadPagePicture();
  }

  async loadPagePicture() {
    const { botStatus } = this._node.controller.flow;
    this.pagePictureString = (await getBase64FromImageUrl(
      botStatus?.page_info?.picture || botDefaultPicture,
      56,
      56,
    )) as string;
    this.updateCustomerChatView(this.pagePictureString);
  }

  onBeforeRender() {
    this.updateCustomerChatView(this.pagePictureString);
    const { config, stats } = this.getData();

    if (config.block_id) {
      this._node.addOutLink(config.block_id, this.lineStartView._lineView);
    }
    if (config.guest_block_id) {
      this._node.addOutLink(
        config.guest_block_id,
        this.lineStartGuestView._lineView,
      );
    }
    if (config.upgrade_block_id) {
      this._node.addOutLink(
        config.upgrade_block_id,
        this.lineStartUpgradeView._lineView,
      );
    }

    if (stats && !this.statsInited) {
      this.statsInited = true;
      this.stats = stats;
      const statsHoverPlaceProps = {
        background: {
          fill: HEXColors.white,
          opacity: 0,
        },
        width: STAT_VIEW_WIDTH,
        height: 21,
      };
      const statsHoverPlaces = {
        notGuest: new MainLayout(statsHoverPlaceProps),
        guest: new MainLayout(statsHoverPlaceProps),
        upgrade: new MainLayout(statsHoverPlaceProps),
      };
      const left = blockWidth - STAT_VIEW_WIDTH - 70;
      this.addToLayout(statsHoverPlaces.notGuest, {
        margin: { top: -200, left },
        verticalAlign: 'bottom',
      });
      this.addToLayout(statsHoverPlaces.guest, {
        margin: { top: -155, left },
        verticalAlign: 'bottom',
      });
      this.addToLayout(statsHoverPlaces.upgrade, {
        margin: { top: -80, left },
        verticalAlign: 'bottom',
      });

      tooltipScaled({
        view: statsHoverPlaces.guest,
        overTimeout: 500,
        children: createStatTooltip({
          uniqValue: stats.statGuest?.counter.uniqStats?.clicked,
          notUniqValue: stats.statGuest?.counter.notUniqStats?.clicked,
          showPeopleTabLink: false,
        }),
      });

      tooltipScaled({
        view: statsHoverPlaces.notGuest,
        overTimeout: 500,
        children: createStatTooltip({
          uniqValue: stats.statNonGuest?.counter.uniqStats?.clicked,
          notUniqValue: stats.statNonGuest?.counter.notUniqStats?.clicked,
          showPeopleTabLink: false,
        }),
      });

      tooltipScaled({
        view: statsHoverPlaces.upgrade,
        overTimeout: 500,
        children: createStatTooltip({
          uniqValue: stats.statUpgrade?.counter.uniqStats?.clicked,
          notUniqValue: stats.statUpgrade?.counter.notUniqStats?.clicked,
          showPeopleTabLink: false,
        }),
      });
    }
  }

  updateCustomerChatView(pagePictureString = GRAY_ONE_PIXEL_PNG) {
    const { config } = this.getData();
    const { user, bot, botStatus } = this._node.controller.flow;
    const textOverflowStyle =
      'display: inline-block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;vertical-align:bottom;';
    const color = config.theme_color || DEFAULT_THEME_COLOR;
    const { statGuest, statNonGuest, statUpgrade } = this.stats || {};
    const isShowStats = isFlowStatsEnabled();
    const percentBase = statNonGuest?.uniqStats?.clicked;
    const guestClicked = calcPercent(statGuest, percentBase);
    const nonGuestClicked = calcPercent(statNonGuest, percentBase);
    const upgradeClicked = calcPercent(statUpgrade, percentBase);

    this.customerChatView.text(
      removeExtraSpaces(`
     <div style="background-color: #fff;margin: 0 16px 0;border-radius: 12px;box-shadow: 0 2px 20px rgba(0, 0, 0, .15);display: flex; flex-direction: column;width: ${
       pluginWidth - 32
     }px; font-size: 11px;">
        <div style="border-bottom: rgba(0, 0, 0, .03);border-radius: 12px 12px 0 0;box-shadow: 0 -5px 20px 1px rgba(0, 0, 0, .03) inset;display: inline-block;padding: 12px 0;position: relative;">
            <div style="bottom: 18px;height: 28px;padding-left: 14px;padding-right: 10px;position: absolute;width: 28px;">
                <div style="background: url('${pagePictureString}') center center no-repeat;background-size: cover;width: 28px;height: 28px;border-radius: 14px;"></div>
            </div>
            <div style="position:absolute;right:12px;top:12px;width: 18px; height: 18px;">${closeCustomerChatIcon}</div>
            <div style="float: left;margin-left: 52px;margin-right: 36px;">
                <div style="color: rgba(0, 0, 0, .50);font-size: 11px;margin-bottom: 4px;margin-left: 0;max-width: 260px; white-space: nowrap">
                  <strong style="max-width: 60px;${textOverflowStyle}">${
        botStatus?.page_info?.title || bot.title
      }</strong> ・Typically replies fast
                </div>
                <div style="font-size: 15px;line-height: 19px;background-color: #f1f0f0; border-radius: 14px; color: rgba(0, 0, 0, 1); display: inline-block; max-width: 260px; padding: 8px 12px; text-align: left; width: fit-content;white-space: pre-wrap;">
                    ${GREETING_PLACE}
                </div>
            </div>
        </div>
        <div style="align-items: center; display: flex; flex-direction: column; overflow: auto; padding: 12px; justify-content: center;">
            <div style="position:relative;white-space: nowrap;font-size:15px;background-color:${
              color === DEFAULT_THEME_COLOR ? 'rgb(0, 132, 255)' : color
            };border-radius: 10px; color: #fff; display: flex; height: 20px; justify-content: center; max-width: 240px; min-width: 240px;  min-height: 20px; outline: none; padding: 8px 0;margin-bottom: 10px;">
              <div style="display:inline-flex; width: 16px; height: 16px;margin-right: 6px;margin-top:-1px;">${messengerIcon}</div>
              Continue as&nbsp;<span style="max-width: 40px;${textOverflowStyle}">${
        (user.name || '').split(' ')[0]
      }</span>
              ${
                isShowStats
                  ? `<div style="position:absolute;right:8px;top:8px;font-weight:600;">${nonGuestClicked.toFixed(
                      0,
                    )}%</div>`
                  : ''
              }
            </div>
            <div style="position:relative;white-space:nowrap;font-size:15px;background-color:#f3f3f3;border-radius: 10px; color: #000000; display: flex; height: 20px; justify-content: center; max-width: 240px; min-width: 240px; min-height: 20px; outline: none; padding: 8px 0;">
              Continue as Guest
                          ${
                            isShowStats
                              ? `<div style="position:absolute;right:8px;top:8px;font-weight:600;">${guestClicked.toFixed(
                                  0,
                                )}%</div>`
                              : ''
                          }
            </div>
            <a href="#" style="color: rgba(0, 0, 0, .40);line-height:20px;padding-top:4px;text-decoration:underline;">Not you? Log into Messenger</a>
        </div>
        <div style="position: relative;font-size:14px;font-weight:500;color:#000000;padding:12px 24px 16px;">
          Logged in from guest mode  ${
            isShowStats
              ? `<div style="position:absolute;right:22px;top:12px;font-weight:600;">${upgradeClicked.toFixed(
                  0,
                )}%</div>`
              : ''
          }
        </div>
     </div>
     <div style="display: flex; justify-content: flex-end; padding: 16px">
          <div style="width: 44px; height: 44px; background-color: ${color};border-radius: 50%;box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);display: flex;justify-content: center;align-items: center;margin-bottom: 16px;">
            ${messengerIconBig.replace(
              '%currentColor%',
              color === DEFAULT_THEME_COLOR
                ? 'url(#paint0_linear)'
                : DEFAULT_THEME_COLOR,
            )}
          </div>
     </div>
    `).replace(
        GREETING_PLACE,
        (
          escapeHtml(config.logged_in_greeting || '') || DEFAULT_GREETING
        ).trim(),
      ),
    );
  }

  createLineView(targetField: string, mandatory = true) {
    return new LineStartView({
      mandatory,
      from: this,
      node: this._node,
      offset: 40,
      items: createLineMenu({
        isShowConnectToExistingBlock: true,
      }),
      sourceBlock: targetField,
      onConnected: (blockView) => {
        const { config } = this.getData();
        this.onChange({ ...config, [targetField]: blockView.id() });
        this.renderNode();
      },
      onRemoved: () => {
        const { config } = this.getData();
        this.onChange({ ...config, [targetField]: '' });
        this.renderNode();
      },
    });
  }
}
