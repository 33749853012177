import { HEXColors } from '@ui/_common/colors';
import { ConflictingData } from './resolveEntryPointConflict';
import i18next from 'i18next';

export const createLinkToConflictTarget = (
  botId: string,
  {
    block_id,
    flow_id,
    block_title,
    flow_title,
    entry_point_id,
  }: ConflictingData,
) => {
  const url = flow_id
    ? `/bot/${botId}/flows/${flow_id}?blockId=${entry_point_id}`
    : `/bot/${botId}/structure/${block_id}`;
  return `${i18next.t('createLinkToConflictTarget-Template-3558-the')}${
    flow_id
      ? i18next.t('createLinkToConflictTarget-string-3146-flow')
      : i18next.t('createLinkToConflictTarget-string-9383-block')
  } <a href="${url}" style="color: ${HEXColors.blue};" target="_blank">${
    flow_title || block_title
  }</a>`;
};
