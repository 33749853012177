import { HEXColors } from '@ui/_common/colors';
import i18next from 'i18next';
import { MainLayout } from '../../../../../../../components/Elements/Layouts';
import { HTMLText } from '../../../../../../../components/Elements/Shapes';
import { ZapierZapState } from '@globals';
import {
  removeTooltip,
  tooltipScaled,
} from '../../../../../../helpers/TooltipHelpers';
import { getI18ZapierKey } from '../../../../utils/getI18ZapierKey';

const COLOR_BY_STATUS = {
  [ZapierZapState.on]: HEXColors.green,
  [ZapierZapState.off]: HEXColors.grey,
  [ZapierZapState.draft]: HEXColors.grey,
};

const TEXT_BY_STATUS = {
  [ZapierZapState.on]: 'On',
  [ZapierZapState.off]: 'Off',
  [ZapierZapState.draft]: 'Off',
};

interface ZapItemStateProps {
  zapState: ZapierZapState;
  onTooltipClick: () => void;
}

export class ZapItemState extends MainLayout {
  constructor({ zapState, onTooltipClick }: ZapItemStateProps) {
    super({
      width: 32,
      height: 18,
      background: {
        fill: COLOR_BY_STATUS[zapState],
        cornerRadius: 9,
      },
    });

    tooltipScaled({
      view: this,
      onClick: onTooltipClick,
      overTimeout: 300,
      text: i18next.t(getI18ZapierKey('youCanTurnZap'), {
        state: i18next.t(
          zapState === ZapierZapState.on
            ? getI18ZapierKey('youCanTurnZapOff')
            : getI18ZapierKey('youCanTurnZapOn'),
        ),
        hostName: '<u>Zapier.com</u>',
      }),
    });

    this.addToLayout(
      new HTMLText({
        text: TEXT_BY_STATUS[zapState],
        width: 32,
        height: 18,
        singleLine: true,
        trustedHtml: true,
        align: 'center',
        fontSize: 11,
        fontStyle: 'bold',
        verticalAlign: 'center',
        fill: HEXColors.white,
        lineHeight: 18 / 11,
      }),
    );
  }

  destroy() {
    removeTooltip(this);
    super.destroy();
  }
}
