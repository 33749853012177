import { equals } from 'ramda';
import { HEXColors } from '@ui/_common/colors';
import { ATTR_SIGN } from '@ui/TextWithAttributesEditor';
import i18next from 'i18next';
import {
  MainLayout,
  VLayout,
} from '../../../../../components/Elements/Layouts';
import { HTMLText } from '../../../../../components/Elements/Shapes';
import { logFlowEvent } from '../../../../../utils/Analytics';
import { getI18ZapierKey } from '../../utils/getI18ZapierKey';

const ATTRIBUTES_ITEMS_LIMIT = 5;

export class ZapAttributesView extends VLayout {
  private attributes: string[];
  private attributesBox: VLayout;
  private showAllAttributesLink: HTMLText;
  private currentAttributesLimit: number | undefined = ATTRIBUTES_ITEMS_LIMIT;

  constructor() {
    super({});
    this.attributes = [];
    this.attributesBox = new VLayout({});
    this.addToLayout(this.attributesBox, {
      margin: {
        left: 88,
      },
    });

    this.showAllAttributesLink = new HTMLText({
      text: '',
      trustedHtml: true,
      fontSize: 15,
      fill: HEXColors.greyDark,
    });

    this.showAllAttributesLink.on('click', (event) => {
      event.stopPropagation();
      this.currentAttributesLimit = this.currentAttributesLimit
        ? undefined
        : ATTRIBUTES_ITEMS_LIMIT;
      this.updateAttributesViews();
      this.renderNode();
      logFlowEvent(
        'zapier integration',
        this.currentAttributesLimit
          ? 'show less attributes click'
          : 'show more attributes click',
      );
    });

    this.addToLayout(this.showAllAttributesLink, {
      margin: {
        top: 4,
        bottom: 8,
      },
      gone: () => this.attributes?.length <= ATTRIBUTES_ITEMS_LIMIT,
      align: 'end',
    });
  }

  private updateAttributesViews() {
    this.attributesBox._views.forEach(({ view }) => view.destroy());
    this.attributesBox._views.length = 0;
    this.attributes
      .slice(0, this.currentAttributesLimit)
      .forEach((attributeName) => {
        this.attributesBox.addToLayout(
          new MainLayout({
            width: 180,
            height: 36,
            background: {
              fill: HEXColors.white,
              cornerRadius: 18,
            },
          }).addToLayout(
            new HTMLText({
              text: `${ATTR_SIGN.start}${attributeName}${ATTR_SIGN.end}`,
              fontSize: 15,
              width: 164,
              height: 35,
              singleLine: true,
              verticalAlign: 'center',
              align: 'center',
              fill: HEXColors.blue,
            }),
            {
              margin: {
                left: 8,
              },
            },
          ),
          {
            margin: {
              bottom: 8,
            },
          },
        );
      });

    this.showAllAttributesLink.text(
      `<u>${
        this.currentAttributesLimit
          ? i18next.t(getI18ZapierKey('showAllAttributes'), {
              qty: this.attributes.length,
            })
          : i18next.t(getI18ZapierKey('showOnlyNAttributes'), {
              limit: ATTRIBUTES_ITEMS_LIMIT,
            })
      }</u>`,
    );
  }

  public updateAttributesData(attributes: string[]) {
    if (equals(this.attributes, attributes)) {
      return;
    }
    this.attributes = attributes;
    this.updateAttributesViews();
  }
}
