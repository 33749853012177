import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { PluginType } from '@components/Plugins/common/PluginTypes';

export const instagramAdsPredefinedAttributes: Record<
  string,
  AttributesQuery_bot_variableSuggest[]
> = {
  default: [],
  [PluginType.text]: [],
};
