import { CSSProperties } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { HEXColors } from '@ui/_common/colors';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { IPoint } from 'pixi.js-legacy';
import { getPluginData } from '../../../StatefulPlugin/ApolloState/utils';
import { DefaultTargetBlockData } from '../../entry-points/common/components/ReplyToByKeywordGroups/types';
import { isApolloBasedPlugin } from '../../plugins.configuration';
import {
  ClickHoldCoordinator,
  ClickHoldCoordinatorDelegate,
} from './ClickHoldCoordinator';
import { Circle } from '../../../components/Elements/Shapes';
import { AddToLayoutProps, View } from '../../../components/Elements/Layouts';
import { LineView, PontWithClickedEvent } from './LineView';
import { Node } from '../../../Node';
import { BlockView } from '../../block_view';
import { BLOCK_SUBTYPES } from '../../../consts';
import {
  getFlowController,
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { findParent, resByFunc } from '../../utils';
import {
  getAdditionalPropertyBagField,
  logFlowEvent,
} from '../../../utils/Analytics';
import { CreateMenuViewOverlay, DefaultItem } from '../../menu_view_overlay';
import { FlowBuilderEvent, flowBuilderEventEmitter } from '../../events';
import { CircleProps } from '../../../components/Elements/Shapes/types';
import { invalidStrokeColor } from '../../plugin_consts';
import { showChooseFlowBlockDialog } from '../../../components';
import { Card, PluginType, Point, PropFunc } from '../../../types';
import {
  lineStartViewEventEmitter,
  LineStartViewEvents,
} from './lineStartViewEventEmitter';
import { IMenuItem, MenuButtonId } from '../../Menu/types';
import { MENU_MIN_WIDTH } from '../../../FlowBuilderOverlay/overlays/Menu/consts';
import { BlockContextType } from '@globals';
import { LineStartSubtype } from './types';

const findParentBlockView = (globalPosition: PontWithClickedEvent) =>
  findParent(
    getPixiFieldStrict().hoverHandler().viewByPos(globalPosition),
    BlockView,
  );

interface LineStartViewCoreProps {
  onConnected(block: BlockView): void;
  onRemoved(): void;
  items?: () => DefaultItem[];
  searchable?: boolean;
  mandatory?: boolean | null | (() => boolean);
}

export interface ButtonLineProps
  extends LineStartViewCoreProps,
    AddToLayoutProps {}

interface OnDragFinishedParams {
  showAddBlockMenu: () => void;
  createBlock: () => void;
}

export interface LineStartViewProps extends ButtonLineProps {
  /**
   * Айдишник требуется для идентификации конкретного инстанса линии, чтобы была возможность понимать,
   * что линия тянется не из конкретного блока, а из конкретного LineStartView.
   */
  id?: string;
  node: Node;
  from: View;
  offset?: number;
  subtype?: LineStartSubtype;
  menuStyle?: CSSProperties;
  defaultTargetBlockData?: PropFunc<DefaultTargetBlockData>;
  sourceBlock?: string;
  onDragFinished?: (params: OnDragFinishedParams) => void;
}

export class LineStartView
  extends Circle
  implements ClickHoldCoordinatorDelegate
{
  TEST_NAME = 'LineStartView';

  public readonly id: string | undefined;
  public fromView: View;
  public _lineView: LineView;
  public showingContextMenu = false;

  private props: LineStartViewProps;
  private _node: Node;
  private onRemovedLink: () => void;
  private mandatory: (() => boolean) | Maybe<boolean>;

  private clickHoldLineStartCoordinator: ClickHoldCoordinator;
  private wasDestroyed?: boolean;

  constructor(props: LineStartViewProps, cardId?: string | null) {
    super({
      fill: HEXColors.white,
      stroke: HEXColors.greyDark,
      strokeWidth: 2,
      radius: 4,
      hitRadius: 20,
    });
    const {
      node,
      from,
      onRemoved,
      offset,
      defaultTargetBlockData,
      mandatory,
      id,
    } = props;

    this.id = id;
    this._node = node;
    this.fromView = from;
    this.onRemovedLink = onRemoved;
    this.mandatory = mandatory;
    this.props = props;

    getPixiFieldStrict().cursorHandler().createCursor(this, 'pointer', 'grab');
    getPixiFieldStrict()
      .hoverHandler()
      .subscribe({
        view: this,
        eventId: 'line_start_hover',
        over: () => {
          this.x = (this._props.x || 0) - 1;
          this.y = (this._props.y || 0) - 1;
          this.renderLine(true);
        },
        out: () => {
          if (!this._lineView.dragging) {
            this.x = (this._props.x || 0) + 1;
            this.y = (this._props.y || 0) + 1;
          }
          this.renderLine(this._lineView.dragging);
        },
      });

    this._lineView = new LineView(
      this,
      {
        onDragStarted: () => {
          node.controller.allNodes().forEach((n) => {
            n.blockView.onLineStarted(this);
          });
        },
        onDragFinished: (point) => {
          this.handleDragFinished(point, {
            defaultTargetBlockData,
          });
        },
        onRemove: () => {
          this.removeLink();
        },
      },
      { cardId: cardId || undefined, startOffset: offset },
    );
    getPixiFieldStrict().viewport.addChild(this._lineView.shape());
    this._lineView.moveToBottom();
    this.renderLine(false);

    this.clickHoldLineStartCoordinator = new ClickHoldCoordinator(this, {
      onClick: (event) => {
        this.onShowAddBlockMenu(event.data.global, 'line start click');
      },
      onHold: (event) => {
        this._lineView.handlePointerDown(event, this);
      },
    });

    this.on('pointerup pointerupoutside', (e: any) => {
      this._lineView.handlePointerUp(e, this);
      this.clickHoldLineStartCoordinator.flush();
    });
  }

  public removeLink() {
    this._node.removeOutLink(this._lineView.toId(), this._lineView);
    this.onRemovedLink();
  }

  public setItems(items: () => DefaultItem[]) {
    this.props.items = items;
  }

  public renderLine(hover: boolean = false) {
    const props: Partial<CircleProps> = {
      stroke: hover
        ? HEXColors.blueDark10
        : resByFunc(this.mandatory, this) &&
          this._lineView &&
          !this._lineView.toView
        ? invalidStrokeColor
        : HEXColors.greyDark,
      strokeWidth: hover ? 4 : 3,
      radius: hover ? 6 : 5,
    };
    if (this._lineView) {
      props.fill = !this._lineView.toView
        ? HEXColors.white
        : hover
        ? HEXColors.blueDark10
        : HEXColors.greyDark;
      this._lineView.stroke(hover ? HEXColors.blueDark10 : HEXColors.greyDark);
      if (!this._lineView.gone) {
        this._lineView.renderElement();
      }
    }
    this.updateProperties(props);
  }

  public shouldPreventHold() {
    return getPixiFieldStrict().isViewOnly() || this.showingContextMenu;
  }

  public shouldPreventClick() {
    return (
      getPixiFieldStrict().isViewOnly() || Boolean(this._lineView.dragging)
    );
  }

  public destroy() {
    if (this.wasDestroyed) {
      return;
    }
    this.wasDestroyed = true;
    this.clickHoldLineStartCoordinator.destroy();
    this._node.removeOutLink(this._lineView.toId(), this._lineView, true);
    this._lineView.destroy();
    (this.fromView as any) = undefined;
    (this._node as any) = undefined;
    this.off('pointerup').off('pointerupoutside');
    super.destroy();
  }

  private handleDragFinished(
    globalPosition: PontWithClickedEvent,
    {
      defaultTargetBlockData,
    }: { defaultTargetBlockData: PropFunc<DefaultTargetBlockData> | undefined },
  ) {
    const {
      sourcePluginType,
      sourcePlugin,
      sourceBlockType,
      sourceBlockId: blockId,
    } = this.getSourceData();
    const targetBlockData = resByFunc(defaultTargetBlockData);

    this._node.controller.allNodes().forEach((n) => {
      n.blockView.onLineFinished(this);
    });
    let blockView = findParentBlockView(globalPosition);

    lineStartViewEventEmitter.emit(LineStartViewEvents.lineConnected, {
      createdBlockType: blockView?._blockType || targetBlockData?.subtype,
      sourceBlockType,
      view: this.fromView,
    });

    if (typeof blockView !== 'undefined' && !globalPosition.clicked) {
      blockView = blockView.onLineFinished(this);
      if (typeof blockView !== 'undefined') {
        logFlowEvent('block', 'connect', {
          sourceBlock: this.props.sourceBlock,
          sourceBlockType,
          sourcePlugin: sourcePluginType,
          blockId,
          to: blockView._node.id,
          ...getAdditionalPropertyBagField(sourcePlugin, this.fromView),
        });
        this.onConnected(blockView);
      } else {
        this._lineView.updateEnd();
      }
      return;
    }

    const source = globalPosition.clicked ? 'arrow click' : 'arrow drag';
    const location = getPixiFieldStrict().viewport.toLocal(
      globalPosition as unknown as IPoint,
    );

    if (this.props.onDragFinished) {
      this.props.onDragFinished({
        showAddBlockMenu: () => {
          this.showAddBlockMenu(
            source,
            location,
            sourcePluginType,
            sourceBlockType,
            blockId,
            globalPosition,
            sourcePlugin,
          );
          this._lineView.updateEnd(globalPosition);
        },
        createBlock: () => {
          this.createBlockWithDefaultData(targetBlockData, location, blockId, {
            source,
            sourcePlugin,
            sourceBlockType,
          });
        },
      });
    } else {
      if (this.props.items?.()) {
        this.showAddBlockMenu(
          source,
          location,
          sourcePluginType,
          sourceBlockType,
          blockId,
          globalPosition,
          sourcePlugin,
        );
        this._lineView.updateEnd(globalPosition);
        return;
      }

      if (targetBlockData) {
        this.createBlockWithDefaultData(targetBlockData, location, blockId, {
          source,
          sourcePlugin,
          sourceBlockType,
        });
      }
    }
  }

  private createBlockWithDefaultData(
    defaultTargetBlockData: PropFunc<DefaultTargetBlockData> | undefined,
    location: IPoint,
    blockId: string | undefined,
    sources: {
      source: string;
      sourcePlugin: string | undefined;
      sourceBlockType: string;
    },
  ) {
    const { source, sourcePlugin, sourceBlockType } = sources;
    const targetBlockData = resByFunc(defaultTargetBlockData);

    logFlowEvent('flows', 'create predefined block', {
      source,
      ...targetBlockData,
    });
    // TODO: remove as any
    const { subtype, pluginId, cards } = targetBlockData as any;
    this.createBlockOnDragEnd(
      location,
      subtype,
      pluginId,
      { source, sourcePlugin, sourceBlockType, blockId },
      cards,
    );
  }

  private onConnected = (blockView: BlockView) => {
    this._node.removeOutLink(this._lineView.toId(), this._lineView);
    if (blockView._node.block.subtype === BLOCK_SUBTYPES.entrypoint) {
      this._lineView.updateEnd();
      return;
    }
    this._node.addOutLink(blockView._node.id, this._lineView);
    this.props.onConnected(blockView);
  };

  private getSourceData = () => {
    const node: Node =
      (this.fromView as any)?._node || (this.fromView as any)?.node;
    let sourcePlugin =
      (this.fromView as any)?._card ||
      (node.block.subtype === BLOCK_SUBTYPES.entrypoint
        ? node.block.cards[0]
        : undefined) ||
      undefined;
    const sourcePluginType: string | undefined = sourcePlugin?.plugin_id;
    const sourcePluginId: string | undefined = sourcePlugin?.id;

    if (
      sourcePluginType &&
      sourcePluginId &&
      isApolloBasedPlugin(sourcePluginType as PluginType)
    ) {
      sourcePlugin = getPluginData(sourcePluginId)?.card || sourcePlugin;
    }

    // TODO: remove as any
    const sourceBlockType: string =
      (this.fromView as any)?._blockType || node.block.subtype;
    const sourceBlockId = sourceBlockType
      ? // @ts-ignore
        node?.id
      : undefined;

    return {
      sourcePlugin,
      sourcePluginType,
      sourcePluginId,
      sourceBlockType,
      sourceBlockId,
    };
  };

  public onShowAddBlockMenu = (point: Point, source: string) => {
    const location = getPixiFieldStrict().viewport.toLocal(point as IPoint);

    const {
      sourcePlugin,
      sourceBlockType,
      sourcePluginType,
      sourceBlockId: blockId,
    } = this.getSourceData();

    this.showAddBlockMenu(
      source,
      location,
      sourcePluginType,
      sourceBlockType,
      blockId,
      point as PontWithClickedEvent,
      sourcePlugin,
    );
  };

  private showAddBlockMenu = (
    source: string,
    location: IPoint,
    sourcePluginId: string | undefined,
    sourceBlockType: string,
    blockId: any,
    globalPosition: PontWithClickedEvent,
    sourcePlugin: Card,
  ) => {
    const items = this.props.items?.();
    const menuStyle = this.props.menuStyle ?? {};

    if (items) {
      this.showingContextMenu = true;
      logFlowEvent('flows', 'show block add menu', { source });

      const createMenuView = new CreateMenuViewOverlay<DefaultItem, IMenuItem>({
        onChoose: (item) => {
          const { id, subtype, contextType, shortcut, defaultPluginId } = item;
          const pluginId =
            defaultPluginId || (subtype === id || shortcut ? undefined : id);

          if (id === MenuButtonId.connectToExistingBlock) {
            const flowId = getFlowControllerStrict().flow.id;
            const currentBlockId = this._node.block.id;
            logFlowEvent('existing block', 'open dialog', {
              flowId,
              currentBlockId,
            });
            showChooseFlowBlockDialog({
              flowId,
              currentBlockId,
              onChooseBlock: (blockId) => {
                logFlowEvent('existing block', 'choose block', {
                  blockId,
                });
              },
              onConnectBlock: (blockId) => {
                logFlowEvent('existing block', 'connect block', {
                  sourceBlock: this.props.sourceBlock,
                  sourceBlockType,
                  sourcePlugin: sourcePluginId,
                  blockId,
                  ...getAdditionalPropertyBagField(sourcePlugin, this.fromView),
                });
                const node = getFlowControllerStrict().getBlockNode(blockId);
                if (node) {
                  this.onConnected(node.blockView);
                  getFlowController()?.focusOnBlock(blockId);
                } else {
                  this._lineView.updateEnd();
                  toaster.show({
                    type: ServiceMessageType.error,
                    payload: {
                      message: window.i18next.t(
                        'LineStartView-string--155-could-not-connect-block-please-try-again-later',
                      ),
                    },
                  });
                }
              },
              onDismiss: () => {
                this._lineView.updateEnd();
                this.showingContextMenu = false;
              },
            });
          } else {
            this.createBlockOnDragEnd(
              location,
              subtype,
              pluginId,
              {
                source,
                sourceBlock: this.props.sourceBlock,
                sourcePlugin: sourcePluginId,
                sourceBlockType,
                blockId,
                title: item.title,
                ...getAdditionalPropertyBagField(sourcePlugin, this.fromView),
              },
              shortcut?.cards,
              contextType,
            );
            lineStartViewEventEmitter.emit(LineStartViewEvents.lineConnected, {
              createdBlockType: item.subtype,
              sourceBlockType,
              view: this.fromView,
            });
          }
          this.showingContextMenu = false;
        },
        items,
        onClose: () => {
          this._lineView.updateEnd();
          this.showingContextMenu = false;
        },
        style: { minWidth: MENU_MIN_WIDTH, paddingBottom: 0, ...menuStyle },
        searchable: this.props.searchable ?? true,
        isPlanLimitMessage: true,
      });
      createMenuView.showOn(globalPosition);
      flowBuilderEventEmitter.emit(FlowBuilderEvent.lineViewMenuOpened);
    }
  };

  private createBlockOnDragEnd(
    location: Point,
    subtype: string,
    pluginId: string | undefined,
    context: any,
    cards?: Partial<Card>[],
    contextType?: BlockContextType,
  ) {
    const loadingView = getFlowControllerStrict().addLoadingView(location);
    this._node.controller.createBlock({
      position: location,
      subtype,
      contextType,
      extraPluginId: pluginId,
      context,
      onCreated: (newNode) => {
        getFlowControllerStrict().removeLoadingView(loadingView);
        this.onConnected(newNode.blockView);
      },
      onError: () => {
        this._lineView.updateEnd();
        getFlowControllerStrict().removeLoadingView(loadingView);
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: window.i18next.t(
              'LineStartView-string-5309-cant-create-the-block-server-is-not-available',
            ),
          },
        });
      },
      cards,
    });
  }

  get subtype(): LineStartSubtype | undefined {
    return this.props.subtype;
  }
}
