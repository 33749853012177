import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { ShopifyEventType } from '@globals';
import { PluginType } from '../../../../../../../Plugins/common/PluginTypes';
import {
  ShopifyEventAbandonedCart,
  shopifyEventAbandonedTexture,
  ShopifyEventOrderConfirmation,
  shopifyEventOrderConfirmationTexture,
  shopifyEventProductVisitTexture,
  ShopifyEventOrderStatusUpdate,
  shopifyEventOrderStatusUpdateTexture,
  ShopifyEventProductVisit,
} from '../../../../../../assets/textures';
import { tooltips } from '../../../../../Menu/menu_view';
import { MenuItemType } from '../../../../../Menu/types';

export const getShopifyEventsWording = memoize(
  (type: ShopifyEventType) =>
    ({
      [ShopifyEventType.abandoned_cart]: {
        title: i18next.t('shopify.events.abandonedCart.title'),
        description: i18next.t('shopify.events.abandonedCart.description'),
        icon: shopifyEventAbandonedTexture,
      },
      [ShopifyEventType.order_confirmation]: {
        title: i18next.t('shopify.events.OrderConfirmation.title'),
        description: i18next.t('shopify.events.OrderConfirmation.description'),
        icon: shopifyEventOrderConfirmationTexture,
      },
      [ShopifyEventType.order_status_update]: {
        title: i18next.t('shopify.events.OrderStatusUpdate.title'),
        description: i18next.t('shopify.events.OrderStatusUpdate.description'),
        icon: shopifyEventOrderStatusUpdateTexture,
      },
      [ShopifyEventType.product_visit]: {
        title: i18next.t('shopify.events.ProductVisit.title'),
        description: i18next.t('shopify.events.ProductVisit.description'),
        icon: shopifyEventProductVisitTexture,
      },
    }[type]),
);

export const getShopifyEventsItems = memoize(() => [
  {
    id: ShopifyEventType.abandoned_cart,
    title: i18next.t('shopify.events.abandonedCart.title'),
    img: ShopifyEventAbandonedCart,
    tooltip: tooltips().cartReminders,
    type: MenuItemType.block,
  },
  {
    id: ShopifyEventType.order_confirmation,
    title: i18next.t('shopify.events.OrderConfirmation.title'),
    img: ShopifyEventOrderConfirmation,
    tooltip: tooltips().orderConfirmation,
    type: MenuItemType.block,
  },
  {
    id: ShopifyEventType.order_status_update,
    title: i18next.t('shopify.events.OrderStatusUpdate.title'),
    img: ShopifyEventOrderStatusUpdate,
    tooltip: tooltips()[PluginType.order_status_update_shortcut],
    type: MenuItemType.block,
  },
  {
    id: ShopifyEventType.product_visit,
    title: i18next.t('shopify.events.ProductVisit.title'),
    img: ShopifyEventProductVisit,
    tooltip: tooltips().productVisit,
    type: MenuItemType.block,
  },
]);
