import i18next from 'i18next';
import { QUANT_ITEMS_WITH_NONE } from '@utils/DateTime/quant';
import { removeTypename } from '@utils/GQL/utils';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { removeTooltip, tooltipScaled } from '../../helpers/TooltipHelpers';
import {
  HLayout,
  MainLayout,
  VLayout,
} from '../../../components/Elements/Layouts';
import {
  publicReplyEntryPointEntryPointFragment_config,
  publicReplyEntryPointEntryPointFragment_config_replies,
} from '../../../../Plugins/PublicReplyEntryPoint/@types/publicReplyEntryPointEntryPointFragment';
import { Node } from '../../../Node';
import { Card } from '../../../types';
import { Checkbox } from '../../kit/Checkbox';
import { PublicReplyItem } from './PublicReplyItem';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { addButtonControl } from '../../helpers/ControlsHelpers';
import { DelayInput } from '../../kit/DelayInput';
import { HTMLText, TextureShape } from '../../../components/Elements/Shapes';
import { plurals } from '@utils/Plurals';
import { pluginWidth } from '../../plugin_consts';
import { PlusButtonView } from '../../plus_button_view';
import { infoSvgTexture } from '../../../assets/textures';
import { HEXColors } from '@ui/_common/colors';
import { OnboardingTourShape } from '@globals';

export type PublicReplyCard =
  Card<publicReplyEntryPointEntryPointFragment_config>;

export class PublicReplyPlugin extends VLayout {
  TEST_NAME = 'PublicReplyPlugin';
  _card: PublicReplyCard;
  _node: Node;
  private repliesBox: VLayout;
  private addPublicReplyButton: PlusButtonView;
  private enableStateCheckbox: Checkbox;
  private repliesBoxTitleView: HTMLText;

  constructor(card: PublicReplyCard, node: Node) {
    super({
      background: {
        fill: HEXColors.white,
      },
    });

    this._card = card;
    this._node = node;

    // eslint-disable-next-line no-param-reassign
    card.config.replies = card.config.replies || [];

    this.enableStateCheckbox = new Checkbox({
      text: `<b>${i18next.t(
        'modernComponents.FlowBuilder.views.plugins.PublicReplyPlugin.publicReply',
      )}</b>`,
      value: !!card.config.enabled,
      textTrue: undefined,
      textFalse: undefined,
      width: 120,
      onChange: (value) => {
        // eslint-disable-next-line no-param-reassign
        card.config.enabled = value;
        node.updateCard<PublicReplyCard>(card);
        this.renderNode();
        logFlowPluginEvent(
          PluginType.public_reply_entry_point,
          'Enable status change',
          {
            blockId: node.id,
            cardId: card?.id,
            enable: value,
          },
        );
      },
    });

    const titleView = new HLayout({});
    const infoIconContainer = new HLayout({});

    titleView.addToLayout(this.enableStateCheckbox).addToLayout(
      infoIconContainer.addToLayout(
        new TextureShape({
          texture: infoSvgTexture,
          width: 14,
          height: 14,
        }),
      ),
      {
        margin: { top: 1 },
      },
    );

    tooltipScaled({
      view: infoIconContainer,
      text: i18next.t(
        'PublicReplyPlugin-string-3449-your-bot-will-reply-to-the-users-comment-in-the-comment-thread-right-under-the-post',
      ),
    });

    this.addToLayout(titleView, {
      margin: { bottom: 16, top: 10 },
    });

    this.addToLayout(
      new VLayout({
        width: pluginWidth,
        background: {
          fill: HEXColors.greyLight20,
          cornerRadius: 12,
        },
      }).addToLayout(
        new DelayInput({
          quantItems: QUANT_ITEMS_WITH_NONE(),
          initialValue: card.config.delay_ms,
          onChange: (value) => {
            // eslint-disable-next-line no-param-reassign
            card.config.delay_ms = value;
            node.updateCard(card);
            logFlowPluginEvent(
              PluginType.public_reply_entry_point,
              'Delay change',
              {
                blockId: node.id,
                cardId: card?.id,
                delay_ms: value,
              },
            );
          },
          editable: () => !!card.isEditing,
        }),
        { margin: { left: 16, top: 16, bottom: 16 } },
      ),
      {
        margin: { bottom: 16 },
        gone: () => !card.config.enabled,
      },
    );

    const box = new VLayout({
      width: pluginWidth,
    });

    this.repliesBoxTitleView = new HTMLText({
      fontSize: 15,
      fontStyle: 'semibold',
      trustedHtml: true,
    });

    box.addToLayout(this.repliesBoxTitleView, { margin: { bottom: 6 } });

    this.repliesBox = new VLayout({
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    box.addToLayout(this.repliesBox, {
      margin: () => ({
        bottom: card.isEditing || !card.config.replies?.length ? 0 : 8,
      }),
    });

    this.addPublicReplyButton = new PlusButtonView(
      i18next.t('PublicReplyPlugin-string-1764-＋-add-random-reply-option'),
      pluginWidth,
    );

    tooltipScaled({
      view: this.addPublicReplyButton,
      text: i18next.t(
        'PublicReplyPlugin-string-1359-if-you-add-two-or-more-reply-options-your-bot-will-alternate-them-randomly',
      ),
    });

    this.addPublicReplyButton.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });

    this.addPublicReplyButton.on('click', (event: Event) => {
      event.stopPropagation();
      const config = removeTypename({
        message: '',
      });
      const length = this._card.config.replies.push(config);
      this.createReplyItem(config, length - 1).startEditing();
      node.updateCard(card);
      // eslint-disable-next-line no-param-reassign
      card.isEditing = true;
      this.renderNode();
      logFlowPluginEvent(
        PluginType.public_reply_entry_point,
        'Add reply option click',
        {
          blockId: node.id,
          cardId: card?.id,
        },
      );
    });

    box.addToLayout(this.addPublicReplyButton, {
      margin: {
        bottom: 16,
      },
      gone: () => !!(card.config.replies?.length && !card.isEditing),
    });

    this.addToLayout(box, {
      gone: () => !card.config.enabled,
    });

    card.config.replies.forEach((config, i) => {
      this.createReplyItem(config, i);
    });
  }

  createReplyItem(
    config: publicReplyEntryPointEntryPointFragment_config_replies,
    index: number,
  ) {
    const replyView = new PublicReplyItem({
      name: `${OnboardingTourShape.PublicReplyTextArea}${index}`,
      config,
      node: this._node,
      card: this._card,
      editable: () => this._card?.isEditing,
    });
    this.addButtonControl(replyView);
    this.repliesBox.addToLayout(replyView, {
      margin: { bottom: 8 },
    });
    return replyView;
  }

  onBeforeRender() {
    this.repliesBoxTitleView.text(
      plurals(
        this._card.config.replies.length,
        i18next.t('PublicReplyPlugin-string-2603-text'),
        i18next.t('PublicReplyPlugin-string-8070-texts'),
        true,
      ),
    );
  }

  addButtonControl(newItemView: MainLayout) {
    addButtonControl(
      newItemView,
      this.repliesBox,
      this._card.config.replies,
      this._node,
      this._card,
      undefined,
      undefined,
      true,
      () => {
        logFlowPluginEvent(
          PluginType.public_reply_entry_point,
          'remove reply',
          {
            blockId: this._node.id,
            cardId: this._card?.id,
          },
        );
        this.renderNode();
      },
      (_, start, end) => {
        logFlowPluginEvent(PluginType.public_reply_entry_point, 'drag reply', {
          start,
          end,
          blockId: this._node.id,
          cardId: this._card?.id,
        });
      },
    );
  }

  destroy() {
    removeTooltip(this.enableStateCheckbox);
    removeTooltip(this.addPublicReplyButton);
    super.destroy();
  }
}
