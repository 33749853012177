/**
 * TODO убрать ts-expect-error. в типе конфига поле size nullable, хотя в типах
 * бекенда оно дефолтится в SendToMessengerSize.large. Перед удалением ts-expect-error
 * убедиться что можно безопасно поле size сделать non nullable.
 */
/* eslint-disable no-param-reassign */
import { MainLayout } from '../components/Elements/Layouts';
import { createLineMenu } from './Menu/createLineMenu';
import { getPixiFieldStrict } from '../PixiFieldRepository';
import { getPanelWidth } from '../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../EditorPanel/events';
import { subscribeEditPlugin } from '../StatefulPlugin/ApolloState/utils';
import { simpleMultiDomainsValidate } from '../../DomainsWhitelisting';
import { validateIsPageConnecting } from './entry-points/common/utils/validateIsPageConnecting';
import { compareFromDefaultConfig } from './validation';
import { PLUGIN_DEFAULT_CONFIG } from '../../Plugins/SendToMessengerEntryPoint/SendToMessengerEntryPointConst';
import { sendToMessengerEntryPointFragment_config as SendToMessengerEntryPointConfig } from '../../Plugins/SendToMessengerEntryPoint/@types/sendToMessengerEntryPointFragment';
import { validateBlockLeadInEntryPoint } from './entry-points/common/utils/validateBlockLeadInEntryPoint';
import { pluginWidth, textCardBackgroundColor } from './plugin_consts';
import { LineStartView } from './components/Line/LineStartView';
import { logFlowEvent } from '../utils/Analytics';
import { SendToMessengerView } from './entry-points/common/SendToMessenger/SendToMessengerView';
import { BUTTONS_SIZES } from './entry-points/common/SendToMessenger/constants';
import { Node } from '../Node';
import { Card } from '../types';
import { pluginDataQuery } from '@components/Plugins/common/PluginGQL';
import { clone } from 'ramda';

export class EntryPointSendToMessenger extends MainLayout {
  TEST_NAME = 'EntryPointSendToMessenger';

  private _node: Node;
  private _card: Card<SendToMessengerEntryPointConfig>;
  private _unsubscribeEditPlugin: () => void;
  private _button: SendToMessengerView;
  private _lineStartView: LineStartView;

  constructor(card: Card<SendToMessengerEntryPointConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 12,
        strokeWidth: 0,
      },
    });
    this._card = card;
    this._node = node;

    this.on('click', () => {
      logFlowEvent('entry_point', 'open send to messenger settings');
      getPixiFieldStrict().fixBlockPosition(node.blockView, getPanelWidth());
      editPluginInEditorPanel(node.id, card);
    });

    this._button = new SendToMessengerView();
    this.addToLayout(this._button);

    this._lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: true,
        items: createLineMenu({ isShowConnectToExistingBlock: true }),
        onConnected: (blockView) => {
          card.config.block_id = blockView.id();
          node.updateCard(card);
          this.updateBlockIdCache(blockView.id());
          this.renderNode();
        },
        onRemoved: () => {
          card.config.block_id = null;
          node.updateCard(card);
          this.updateBlockIdCache(null);
          this.renderNode();
        },
      },
      card.id,
    );

    this.addToLayout(this._lineStartView, {
      margin: () => {
        return {
          top:
            // @ts-expect-error
            BUTTONS_SIZES[this._card.config.size].lineTop - 5,
          left: 6,
        };
      },
      align: 'end',
    });

    this._unsubscribeEditPlugin =
      subscribeEditPlugin<SendToMessengerEntryPointConfig>(
        card.id,
        (card) => {
          this._card.config = card.config;
          // @ts-expect-error
          this._button.updateConfig(this._card.config);
          this.renderNode();
        },
        () => null,
      );
  }

  /**
   * Временный костыль. Если создать этот ЕП, соеденить этот ЕП стрелкой с любым блоком,
   * затем, открыть левую панель редактирования, в конфиге пропадет block_id, тк левая
   * панель берет данные из графкль кеша. Откуда в кеше эти данные вообще не понятно
   * тк этот ЕП не стейтфул, и нет никакой явной синхронизации с кешом. Возможно такое
   * поведение из-за мутации в каком то месте.
   * Можно удалить когда ЕП будет переписан на стейтфул
   */
  private updateBlockIdCache(blockId: string | null) {
    const cacheCard = clone(pluginDataQuery.read({ id: this._card.id })) as {
      card: Card<SendToMessengerEntryPointConfig>;
    };
    cacheCard.card.config.block_id = blockId;
    pluginDataQuery.write(cacheCard, { id: this._card.id });
  }

  onBeforeRender() {
    if (this._card.config.block_id) {
      this._node.addOutLink(
        this._card.config.block_id,
        this._lineStartView._lineView,
      );
    }
    // @ts-expect-error
    this._button.updateConfig(this._card.config);
  }

  hasSignificantChangesInConfig() {
    return !compareFromDefaultConfig(this._card.config, PLUGIN_DEFAULT_CONFIG);
  }

  destroy() {
    this._unsubscribeEditPlugin();
    super.destroy();
  }

  validationError() {
    return (
      validateIsPageConnecting(this._node) ||
      validateBlockLeadInEntryPoint(this._card.config) ||
      (simpleMultiDomainsValidate(this._card.config.domains)
        ? undefined
        : {
            message:
              'To enable this Entry Point, specify the website domain where you’ll be using it.<br/><br/><span style="text-decoration: underline;">Specify domain</span>',
            onClick: () => {
              this.emit('click');
            },
          })
    );
  }
}
