import { Platform } from '@globals';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';
import { Eq } from '@classes/Eq';

export class PlatformHolder extends Eq<Platform> {
  protected readonly platforms: Platform[];

  constructor(...platforms: Platform[]) {
    super();

    this.platforms = platforms;
  }

  eq(a: Platform): boolean {
    return this.platforms.some((platform) => {
      switch (platform) {
        case Platform.facebook:
        case Platform.instagram:
        case Platform.whatsapp:
          return platform === a;
        default:
          return exhaustiveCheck(platform);
      }
    });
  }
}
