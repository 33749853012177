/**
 * Группы ниже отвечают только за ПОРЯДОК айтемов в меню. Добавление айтема в группу
 * не гарантирует его отображение в меню, за это отвечает САМ меню айтем
 */
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { getPluginMenuItem, getMenuItem, menuButtons } from './menu_view';
import { CustomAiPlugins } from '../plugins/AiCustomTemplates/getAiCustomTemplatesList';
import { BLOCK_SUBTYPES } from '../../consts';
import { CustomAiPluginIcon } from '../../assets/textures';
import { PlatformHolder } from './PlatformHolder';
import { Platform } from '../../../../../@types/globalTypes';
import { GetMenuItemByConfig, MenuItemType } from './types';

export const additionalActionItems = [
  getMenuItem.zapierEventTrigger,
  getMenuItem.googleSheet,
  getMenuItem.kommo,
];

export const notifications = [
  getPluginMenuItem.otn,
  getPluginMenuItem.nTNDaily,
  getPluginMenuItem.nTNWeekly,
  getPluginMenuItem.nTNMonthly,
];

export const collectUserData = [
  getPluginMenuItem[PluginType.collect_answers_shortcut],
  getPluginMenuItem[PluginType.collect_emails_shortcut],
  getPluginMenuItem[PluginType.collect_phones_shortcut],
  getPluginMenuItem[PluginType.collect_numbers_shortcut],
  getPluginMenuItem.datePicker,
  getPluginMenuItem.dateAndTime,
];

export const entryPointsInstagram = [
  getMenuItem.instagramLink,
  getMenuItem.instagramDirect,
  getMenuItem.instagramAdsCtm,
  getMenuItem.instagramCommentsAutoreply,
  getMenuItem.instagramStoryMention,
  getMenuItem.instagramStoryReply,
  getMenuItem.instagramPersistentMenu,
];

export const entryPointsFacebook = [
  getMenuItem.refLink,
  getMenuItem.facebookAdsCtmJson,
  getMenuItem.facebookAdsCtm,
  getMenuItem.commentsAutoreply,
  getMenuItem.adCommentsAutoreply,
  getMenuItem.facebookPage,
  getMenuItem.facebookShops,
  getMenuItem.persistentMenu,
  getMenuItem.facebookAdsSm,
];

export const entryPointsWhatsapp = [
  getMenuItem[PluginType.whatsapp_direct_entry_point],
  getMenuItem[PluginType.whatsapp_bot_link],
  getMenuItem[PluginType.whatsapp_widget_entry_point],
  getMenuItem[PluginType.whatsapp_popup_entry_point],
];

export const entryPointsWebsite = [
  getMenuItem.refLink,
  getMenuItem.customerChat,
  getMenuItem.sendToMessenger,
];

export const integrations = [
  getMenuItem.calendly,
  getMenuItem.zapierEventTrigger,
  getMenuItem.googleSheet,
  getMenuItem.zapierImportContent,
  getMenuItem.zapierEp,
  getMenuItem.openAi,
  getMenuItem.kommo,
];

export const shopifyEvents = [
  getMenuItem.cartReminders,
  getMenuItem.orderConfirmation,
  getMenuItem[PluginType.order_status_update_shortcut],
  getMenuItem.productVisit,
];

export const utilityBlocks = [
  getMenuItem.blockDelay,
  getMenuItem.blockSplit,
  getMenuItem.blockCondition,
  getMenuItem.blockRedirect,
  getMenuItem.flowRedirect,
];

export const buttonsBlock = [menuButtons.connectToExistingBlock];

export const shopifyPluginReminders = [
  getPluginMenuItem.gallery_reminder,
  getPluginMenuItem.text_reminder,
];

export const shopifyBackInStockOptInConfirmationPlugin = [
  getPluginMenuItem[PluginType.product_list_opt_in_confirmation_shortcut],
];

export const shopifyBackInStockMessagePlugin = [
  getPluginMenuItem[PluginType.product_list_back_in_stock_message_shortcut],
];

export const shopifyReminders = [
  getMenuItem.gallery_reminder,
  getMenuItem.text_reminder,
];

export const shopifyOrderReceipt = [
  getPluginMenuItem[PluginType.order_receipt],
];

export const calendlySpecificItems = [
  getMenuItem.calendlyEventConfirmation,
  getMenuItem.calendlyEventReminder,
];

export const shopifyProductVisitReminders = [
  getMenuItem.product_visit_reminder,
];

export const shopifyProductVisitPluginReminders = [
  getPluginMenuItem[PluginType.product_list_product_visit_shortcut],
];

export const orderReceipt = [getMenuItem.orderReceipt];

export const postPurchase = [getMenuItem.postPurchase];

export const shopifyOptInConfirmation = [getMenuItem.optInConfirmation];

export const shopifyBackInStockMessage = [getMenuItem.backInStockMessage];

export const shopifyPluginPostPurchase = [
  getPluginMenuItem[PluginType.product_list_shopify_order_shortcut],
];

export const shopifyDiscount = [getMenuItem.shopifyDiscount];

export const generateCustomAiPluginsGroup = (
  customAiPlugins: CustomAiPlugins,
): GetMenuItemByConfig[] | null =>
  customAiPlugins?.length
    ? customAiPlugins
        .concat()
        .sort((a, b) => a.nestedMenuOrder - b.nestedMenuOrder)
        .map((plugin) => () => ({
          newLabel: true,
          subtype: BLOCK_SUBTYPES.ai_custom,
          id: PluginType.custom_ai,
          img: CustomAiPluginIcon,
          platform: new PlatformHolder(
            Platform.instagram,
            Platform.facebook,
            Platform.whatsapp,
          ),
          title: plugin.title,
          type: MenuItemType.plugin,
          tooltip: {
            text: plugin.description,
          },
          shortcut: {
            subtype: BLOCK_SUBTYPES.ai_custom,
            cards: [
              {
                plugin_id: PluginType.custom_ai,
                config: {
                  templateId: plugin.id,
                },
              },
            ],
          },
        }))
    : null;
