import copyToClipboard from 'clipboard-copy';
import i18next from 'i18next';
import { Subscription } from 'apollo-client/util/Observable';

import { toaster } from '@services/MessageService';
import { BotStatus, getConnectedPageObservable } from '@utils/Bot';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import {
  ConnectPageField,
  ConnectPageOrigin,
  runIfConnectPageUrlParamsSubmatches,
} from '@components/ConnectPageDialog';

import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { botLinkEntryPointFragment_config as BotLinkEntryPointConfig } from '../../../../Plugins/BotLinkEntryPoint/@types/botLinkEntryPointFragment';

import { logFlowPluginEvent } from '../../../utils/Analytics';
import { MainLayout, VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Node } from '../../../Node';
import { ControlVisibility } from '../../../FlowBuilderOverlay';

import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { createLineMenu } from '../../Menu/createLineMenu';
import { ButtonView } from '../../button_view';
import { removeTooltip, tooltipScaled } from '../../helpers/TooltipHelpers';
import { SetAttributeView } from '../../components/SetAttributeView';
import { LineStartView } from '../../components/Line/LineStartView';

import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { validateBlockLeadInEntryPoint } from '../common/utils/validateBlockLeadInEntryPoint';
import { showConnectPageDialog } from '../common/utils/showConnectPageDialog';
import { getEntryPointCard } from '../common/utils';
import { getPixiField } from '../../../PixiFieldRepository';
import { HEXColors } from '@ui/_common/colors';
import { BotLink } from '../common/BotLink';
import Maybe from 'graphql/tsutils/Maybe';
import { handleConflictError } from '../common/handleConflictError';
import { ApolloError } from 'apollo-client';
import { validateBotLink } from '../common/utils/validateBotLink';

export class FacebookBotLink extends BotLink {
  constructor() {
    super({ url: 'https://m.me/', parameter: 'ref' });
  }
}

export class EntryPointBotLink
  extends MainLayout
  implements StatefulPluginDelegate<BotLinkEntryPointConfig>
{
  TEST_NAME = 'EntryPointBotLink';

  public readonly state: StatefulPlugin<BotLinkEntryPointConfig>;
  public readonly botLink: FacebookBotLink;

  public readonly _node: Node;

  private baseUrl: URL;

  private refView: SetAttributeView;

  private lineStartView: LineStartView;

  private buttonView: ButtonView;

  private buttonBox: VLayout;

  private connectedPageObservable: Subscription | undefined;

  constructor(state: StatefulPlugin<BotLinkEntryPointConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.botLink = new FacebookBotLink();

    const { title } = node.controller.flow;

    if (!this.state.data?.config?.referral) {
      this.state.set(({ config }) => ({
        config: { ...config, referral: title },
      }));
    }

    this.baseUrl = this.botLink.getBaseUrl(this.getPageId());

    this.refView = new SetAttributeView(
      this.state.data,
      {
        caption: i18next.t(
          'modernComponents.FlowBuilder.views.components.EntryPointBotLink.setBotLinkUrl',
        ),
        width: pluginWidth - 30,
        height: 0,
        textDecoration: 'underline',
        singleLine: false,
        maxLength: 256,
        maskPrefixLength: this.botLink.getBaseUrl(this.getPageId()).toString()
          .length,
        margin: {
          left: 10,
          top: 8,
          bottom: 8,
          right: 10,
        },
        text: this.botLink.getUrlString(
          this.botLink.getBotUrl(this.referral, this.getPageId()),
        ),
        isValid: () => !!this.state.data?.config?.referral,
        isEditing: () => this.state.isEditing,
      },
      undefined,
      async (newLink) => {
        this.refView.text(newLink);
        const newReferral = new URL(newLink).searchParams.get('ref') ?? '';
        this.updateReferral(newReferral, () => {
          logFlowPluginEvent(
            PluginType.ref_link_entry_point,
            'update referral',
            {
              blockId: this._node.id,
              cardId: this.state.data?.id,
              referral: newLink,
            },
          );
          this.renderNode();
        });

        try {
          await this.state.save();
        } catch (error) {
          handleConflictError(error as ApolloError, this);
        }
      },
      undefined,
      {
        emoji: ControlVisibility.hide,
        attributes: ControlVisibility.hide,
      },
      undefined,
    );

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: true,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: (blockView) => {
          this.state.set(({ config }) => ({
            config: { ...config, block_id: blockView.id() },
          }));
        },
        onRemoved: () => {
          this.state.set(({ config }) => ({
            config: { ...config, block_id: null },
          }));
        },
      },
      this.state.data?.id,
    );

    const configLayout = new VLayout();
    this.layout(configLayout);
    configLayout.layout(this.refView, {
      margin: { left: 15, top: 15, bottom: 0 },
    });

    this.buttonView = new ButtonView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.components.EntryPointBotLink.copyUrl',
      ),
      bgColor: HEXColors.white,
      onClick: (e) => {
        e.stopPropagation();
        copyToClipboard(
          this.botLink.getUrlString(
            this.botLink.getBotUrl(this.referral, this.getPageId(), {
              encode: true,
            }),
          ),
        );
        toaster.show({
          type: ServiceMessageType.default,
          timeout: 1000,
          payload: {
            message: i18next.t(
              'modernComponents.FlowBuilder.views.components.EntryPointBotLink.urlCopied',
            ),
          },
        });
        logFlowPluginEvent(PluginType.ref_link_entry_point, 'copy link', {
          blockId: this._node.id,
          cardId: this.state.data?.id,
          referral: this.state.data?.config?.referral,
        });
      },
    });
    this.buttonBox = new VLayout({
      width: pluginWidth - 16,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });
    this.buttonBox.layout(this.buttonView, {
      margin: { left: 15, top: 10, bottom: 15 },
    });

    if (!getPixiField()?.isViewOnly()) {
      this.connectedPageObservable = getConnectedPageObservable(
        this._node.controller.flow.botId,
      ).subscribe(({ pageId, status }) => {
        if (status) {
          this.handlePageConnected(status, pageId);
        }
      });
    }

    runIfConnectPageUrlParamsSubmatches(
      {
        [ConnectPageField.origin]: ConnectPageOrigin.botLinkEntryPoint,
        [ConnectPageField.blockId]: node.id,
      },
      () => {
        const entryPointCard = getEntryPointCard(node.block.cards);
        if (entryPointCard) {
          showConnectPageDialog(
            node,
            entryPointCard,
            undefined,
            ConnectPageOrigin.botLinkEntryPoint,
          );
        }
      },
    );

    const connectPageError = validateIsPageConnecting(
      node,
      undefined,
      ConnectPageOrigin.botLinkEntryPoint,
    );
    if (connectPageError) {
      this.buttonView.setDisabled(true);
      tooltipScaled({
        view: this.buttonBox,
        text: connectPageError.message,
        onClick: connectPageError.onClick,
      });
    }

    configLayout.layout(this.buttonBox);
    this.layout(this.lineStartView, {
      margin: { left: -9, top: -25 },
      align: 'end',
      verticalAlign: 'center',
    });
  }

  private get referral() {
    return (
      this.state.data?.config?.referral || this._node.controller.flow.title
    );
  }

  private getPageId(pageId?: Maybe<string>) {
    const { botStatus } = this._node.controller.flow;

    return (
      botStatus?.page_info?.username || botStatus?.page_info?.id || pageId || ''
    );
  }

  updateReferral(newReferral: string, onChange?: () => void) {
    if (this.state.data?.config?.referral !== newReferral) {
      this.state.set(({ config }) => ({
        config: {
          ...config,
          referral: newReferral,
        },
      }));
      onChange?.();
    }
  }

  handlePageConnected(status: BotStatus, pageId: Maybe<string>) {
    if (status !== BotStatus.noPage) {
      const newUrl = this.botLink.getBotUrl(
        this.referral,
        this.getPageId(pageId),
        { encode: false },
      );
      this.updateReferral(newUrl.searchParams.get('ref') ?? '');
      this.refView.text(this.botLink.getUrlString(newUrl));

      this.baseUrl = this.botLink.getBaseUrl(this.getPageId(pageId));
      this.refView._props.maskPrefixLength = this.baseUrl.toString().length;

      this.buttonView.setDisabled(false);
      removeTooltip(this.buttonBox);

      this.renderNode();
    }
  }

  onBeforeRender() {
    if (this.state.data?.config?.block_id) {
      this._node.addOutLink(
        this.state.data?.config?.block_id,
        this.lineStartView._lineView,
      );
    }
    this.refView._textNode.textDecoration(
      this.state.isEditing || !this.state.data?.config?.referral
        ? 'none'
        : 'underline',
    );
  }

  hasSignificantChangesInConfig() {
    const { title } = this._node.controller.flow;
    return (
      !!this.state.data?.config?.referral.trim() &&
      this.state.data?.config?.referral.trim() !== title
    );
  }

  validationError() {
    return (
      validateIsPageConnecting(
        this._node,
        undefined,
        ConnectPageOrigin.botLinkEntryPoint,
      ) ||
      validateBlockLeadInEntryPoint({
        block_id: this.state.data?.config?.block_id ?? undefined,
      }) ||
      validateBotLink(this.state.data?.config?.referral)
    );
  }

  pluginDidSet() {
    if (this.state.data.isEditing) {
      return;
    }
    this.state.save();
  }

  pluginDidSave() {
    this.renderNode();
  }

  destroy() {
    this.connectedPageObservable?.unsubscribe();
    this.state.destroy();
    super.destroy();
  }
}
