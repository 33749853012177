import i18next from 'i18next';
import { compose, Lens, set, view } from 'ramda';
import { HEXColors } from '@ui/_common/colors';
import { Messages, toaster } from '@services/MessageService';
import {
  OnboardingTourEventType,
  OnboardingTourShape,
  Platform,
  QuickReplyType,
} from '@globals';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import datepickerPreview from '../../../assets/plugin/datepicker_preview.svg';
import { openDatepickerTexture } from '../../../assets/textures';
import { HLayout, View, VLayout } from '../../../components/Elements/Layouts';
import { HTMLText, TextureShape } from '../../../components/Elements/Shapes';
import { BLOCK_SUBTYPES, fontFamily } from '../../../consts';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { Card, PropFunc } from '../../../types';
import { Node } from '../../../Node';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { logFlowEvent, PluginLogger } from '../../../utils/Analytics';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import { ButtonView } from '../../button_view';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { SetAttributeView } from '../../components/SetAttributeView';
import { buttonControl } from '../../helpers/ControlsHelpers';
import { tooltipScaled } from '../../helpers/TooltipHelpers';
import { CardButton } from '../../kit/CardButton';
import {
  ItemStatView,
  STAT_VIEW_WIDTH,
} from '../../kit/ItemStatView/ItemStatView';
import { blockWidth, buttonWidth, pluginWidth } from '../../plugin_consts';
import { QRButtonView, QRButtonViewProps } from '../../qr_button_view';
import { QRPlusButtonView } from '../../qr_plus_button_view';
import { TextEditView } from '../../text_edit_view';
import {
  isFacebookAds,
  isInsideJsonAdsEp,
  moveArrayElement,
  resByFunc,
} from '../../utils';
import { Banner } from '../../kit/Banner';
import { hasNonEmptyButtons, isQRButtonValid } from '../../validation';
import {
  quickReplyPluginFragment_config as QuickReplyPluginConfig,
  quickReplyPluginFragment_config_buttons as QuickReplyPluginButton,
  quickReplyPluginFragment_config_email as QuickReplyEmail,
  quickReplyPluginFragment_config_phone as QuickReplyPhone,
  quickReplyPluginFragment_config_skip_button as QuickReplySkipButton,
} from '../../../../Plugins/QuickReplyPlugin/@types/quickReplyPluginFragment';
import {
  datePickerPluginFragment_config as DatePickerPluginConfig,
  datePickerPluginFragment_config_date_button as DatePickerDateButton,
} from '../../../../Plugins/DatePickerPlugin/@types/datePickerPluginFragment';
import { getQuickReplyConfig } from '../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';
import {
  Plugin,
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import * as Lenses from './lenses';
import {
  EMAIL_PLACEHOLDER,
  MAX_NUMBER_OF_QUICK_REPLIES,
  PHONE_PLACEHOLDER,
  QUICK_REPLY_TYPES_WITH_ERROR_MESSAGES,
} from './constants';
import {
  getPluginError,
  getPluginIcon,
  getPluginTitle,
  isContentPluginBeforePosition,
  isNeedShowQr,
} from './utils';
import { Margin } from '../../utility_classes';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import { mongoObjectId } from 'cf-common/src/utils/mongo/mongoObjectId';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { InteractionEvent } from 'pixi.js-legacy';

enum DatepickerStatsCounterPostfixes {
  choose = '_choose_date',
  skip = '_skip',
}

export interface QuickReplyPluginViewProps {
  includeReplyTypes?: QuickReplyType[];
  isAtLeastOneButtonIsRequired?: PropFunc<boolean>;
  isEmptyQuickReplyAvailable?: PropFunc<boolean>;
  maxLength?: PropFunc<number>;
  plusButtonText?: string;
}

type Config = QuickReplyPluginConfig | DatePickerPluginConfig;

export class QuickReplyPluginView
  extends VLayout
  implements StatefulPluginDelegate<Config>
{
  public readonly TEST_NAME = 'QRPluginView';

  private readonly state: StatefulPlugin<Config>;
  private _node: Node;
  private readonly logger: PluginLogger;

  private dateButtonTitle!: string;
  private skipButtonTitle: string;

  private titleView!: PluginTitleLayout;
  private saveAttributeView!: HLayout;
  private saveAttribute!: SetAttributeView;
  private quickReplyView!: VLayout;
  private plusButtonView!: QRPlusButtonView;
  private datepickerIconView!: TextureShape;
  private props: QuickReplyPluginViewProps;

  protected addFollowUpButton?: ButtonView;
  protected unsubscribesFromFollowUps?: () => void;

  constructor(
    state: StatefulPlugin<Config>,
    node: Node,
    props: QuickReplyPluginViewProps = {},
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: HEXColors.greyLight20,
      },
      margin: new Margin({ bottom: 15 }),
    });

    this.state = state;
    this.state.setDelegate(this);

    this.skipButtonTitle = view(
      Lenses.skipButtonTitleLens,
      this.state.data.config,
    );

    this._node = node;
    this.logger = new PluginLogger(
      this.state.data.plugin_id,
      this._node.id,
      this.state.data.id,
    );

    this.props = {
      isAtLeastOneButtonIsRequired: false,
      isEmptyQuickReplyAvailable: false,
      maxLength: 20,
      plusButtonText: undefined,
      ...props,
    };

    this.createTitleView();
    this.createSetAttributeView();

    if (isNeedShowQr()) {
      this.createQuickReplyView();
      this.updateButtons();
      this.createSkipButtonView();
    }

    this.on('click', () => {
      this.openLeftPanel();
    });
  }

  private openLeftPanel() {
    const node = this._node;
    getPixiFieldStrict().fixBlockPosition(
      node.blockView,
      getPanelWidth(PluginType.quick_reply),
    );
    editPluginInEditorPanel(
      node.id,
      this.state.data,
      this.state,
      this._node.block.subtype,
    );
    const label = getPluginTitle(this.state.data.config.type!).toLowerCase();
    logFlowEvent(label, 'open settings');
  }

  private createTitleView() {
    this.titleView = new PluginTitleLayout(
      false,
      getPluginTitle(this.state.data.config.type!),
      getPluginIcon(this.state.data.config.type!),
      this.state.data,
    );

    this.addToLayout(this.titleView, { margin: { top: 10, left: 10 } });

    const infoBannerView = new Banner({
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.QuickReplyPlugin.bannerText',
      ),
    });
    this.addToLayout(infoBannerView, {
      margin: { top: 10, left: 16 },
      gone: () =>
        getFlowPlatform() !== Platform.instagram || !this.state.isEditing,
    });
  }

  private createSetAttributeView() {
    this.saveAttributeView = new HLayout({ height: 35 });
    this.saveAttribute = new SetAttributeView(
      this.state,
      {
        width: 170,
        caption: 'attribute',
        isValid: () => this.isAttributeFieldValid(),
        attributeOnly: true,
        customAttributesOnly: true,
        editable: false,
        isEditing: () => false,
      },
      undefined,
      () => {
        const { config } = this.state.data;
        const newText = this.saveAttribute?.text().trim();
        if (newText !== config.property) {
          this.state.setAndSave(({ config }) => ({
            config: set(Lenses.propertyLens, newText, config),
          }));

          this.logger.log('update attribute', { value: newText });
        }
        this.saveAttribute?.text(newText);
        this.saveAttribute?.renderElement();
      },
      undefined,
    );
    this.saveAttributeView
      .addToLayout(
        new HTMLText({
          text: i18next.t('QuickReplyPlugin-string--757-save-to'),
          width: 88,
          fill: HEXColors.greyDark,
          height: 35,
          verticalAlign: 'center',
          fontSize: 15,
        }),
        { margin: { left: 15, right: 10 } },
      )
      .addToLayout(this.saveAttribute);

    this.addToLayout(this.saveAttributeView, {
      margin: { top: 15, bottom: isNeedShowQr() ? 0 : 8 },
    });
  }

  /**
   * Quick replies don't work in Instagram after image,
   * but we have to use collect answer plugin for Instagram comments autoreply.
   * That's why we hide quick replies in collect answer block and don't show
   * error message
   */
  private isQrAfterImageInInstagramPrivateReply(): boolean {
    const isInstagramCommentsReplyBlock =
      this._node.block.subtype ===
      BLOCK_SUBTYPES.instagram_comments_autoreply_content;
    const privateReply = this._node.block.cards.find(
      (card) => card.plugin_id === PluginType.private_reply_entry_point,
    );
    const isImagePrivateReply =
      privateReply?.config?.content_type === PluginType.image;
    return isInstagramCommentsReplyBlock && isImagePrivateReply;
  }

  private createQuickReplyView() {
    this.quickReplyView = new VLayout({});
    this.addToLayout(this.quickReplyView, {
      margin: { left: 16, top: 15 },
      gone: () =>
        this.isDatePicker() || this.isQrAfterImageInInstagramPrivateReply(),
    });
    this.updateQuickReply();
    this.plusButtonView = new QRPlusButtonView({
      title: this._node.title,
    });
    this.plusButtonView.on('pointerdown', (e: InteractionEvent) => {
      e.stopPropagation();
    });
    this.plusButtonView.on('click', (e: InteractionEvent) => {
      e.stopPropagation();

      const button: QuickReplyPluginButton = {
        __typename: 'CardButton',
        title: '',
        counter_id: mongoObjectId(),
        block_ids: [],
      };
      this.state.setAndSave(({ config }) => ({
        config: set(
          Lenses.buttonsLens,
          [...((config as QuickReplyPluginConfig).buttons || []), button],
          config,
        ),
      }));
      const buttonView = this.addQuickReply(
        button,
        true,
        Lenses.getButtonsLens,
      );
      buttonView.renderNode();
      buttonView.focus();
      this.logger.log('add button');
    });

    this.addToLayout(this.plusButtonView, {
      margin: { left: blockWidth - 200 - 55, bottom: 15 },
      gone: () => {
        const config = this.state.data.config as QuickReplyPluginConfig;

        const havingUnchangableQuickReplies =
          (config.type === QuickReplyType.email &&
            config.email?.facebook_suggest) ||
          (config.type === QuickReplyType.phone &&
            config.phone?.facebook_suggest) ||
          this.isDatePicker();

        return (
          this.isQrAfterImageInInstagramPrivateReply() ||
          havingUnchangableQuickReplies ||
          !this.state.isEditing ||
          (config.buttons || []).length >= MAX_NUMBER_OF_QUICK_REPLIES
        );
      },
    });
  }

  private addStatView(
    view: CardButton,
    counterPostfix: DatepickerStatsCounterPostfixes,
  ) {
    const stats = getFullCounterStats(
      `${this.state.data.id}${counterPostfix}`,
      this._node.block.id,
    );
    if (stats) {
      const itemStatView = new ItemStatView({
        stats,
        showPercentValue: true,
      });
      Object.assign(view.textEditView._props, {
        overlayWidth: buttonWidth - 60,
        overlayOffset: () => ({ x: -itemStatView.textWidth - 2, y: 0 }),
      });
      Object.assign(view.textEditView._textNode.layoutProperties, {
        margin: { left: itemStatView.textWidth },
        width: buttonWidth - 64 - itemStatView.textWidth * 2,
      });
      view.addToLayout(
        new ItemStatView({
          stats,
          showPercentValue: true,
        }),
        {
          margin: {
            top: 10,
            left: buttonWidth - STAT_VIEW_WIDTH - 32,
          },
          gone: () => view.textEditView._textAreaShown,
        },
      );
    }
    return view;
  }

  private createDatepickerButton() {
    const buttonTextView = new TextEditView(
      {
        fontFamily,
        fontSize: 15,
        fontStyle: 'semibold',
        height: 20,
        fill: HEXColors.black,
        align: 'center',
        maxLength: 20,
        verticalAlign: 'center',
        width: buttonWidth - 60,
        caption: i18next.t('text_card_button_view-string--106-add-button-name'),
        captionFill: HEXColors.red,
        text: (this.state.data.config as DatePickerPluginConfig).date_button
          ?.title,
        singleLine: true,
        attributeOnly: false,
        editable: () => this.state.isEditing,
      },
      undefined,
      (title) => {
        this.dateButtonTitle = title;
      },
      () => {
        this.state.setAndSave(({ config }) => ({
          config: set(Lenses.dateButtonTitleLens, this.dateButtonTitle, config),
        }));

        this.logger.log('rename datepicker button', {
          value: (this.state.data.config as DatePickerPluginConfig).date_button
            ?.title,
        });
      },
    );

    this.datepickerIconView = new TextureShape({
      texture: openDatepickerTexture,
      height: 16,
      width: 16,
    });

    const buttonConfig =
      (this.state.data.config as DatePickerPluginConfig).date_button ||
      getQuickReplyConfig({}).date_button;

    const cardButton = new CardButton({
      buttonConfig,
      card: this.state.data,
      node: this._node,
      text: {
        view: buttonTextView,
        props: {
          margin: {
            top: 10,
            left: 30,
          },
        },
      },
      icon: {
        view: this.datepickerIconView,
        props: {
          margin: {
            top: 12,
            left: 12,
          },
        },
      },
      arrowProps: {
        onConnected: (blockView) => {
          this.state.setAndSave(({ config }) => {
            const date_button: DatePickerDateButton = {
              __typename: 'ButtonConfig',
              title: '',
              ...(config as DatePickerPluginConfig).date_button,
              block_ids: [blockView.id()],
            };

            return {
              config: set(Lenses.dateButtonLens, date_button, config),
            };
          });

          this.logger.log('connect datepicker button', {
            value: (this.state.data.config as DatePickerPluginConfig)
              ?.date_button?.block_ids,
          });
        },
        onRemoved: () => {
          this.state.setAndSave(({ config }) => ({
            config: set(Lenses.dateButtonBlockIdLens, null, config),
          }));

          this.logger.log('disconnect datepicker button', {
            value: (this.state.data.config as DatePickerPluginConfig)
              ?.date_button?.block_ids,
          });
        },
        mandatory: false,
      },
    });

    cardButton.on('click', (e: InteractionEvent) => {
      e.stopPropagation();
      buttonTextView.startEditing();
    });

    return this.addStatView(cardButton, DatepickerStatsCounterPostfixes.choose);
  }

  private createSkipButtonView() {
    const buttonTextView = new TextEditView(
      {
        fontFamily,
        maxLength: 20,
        fontSize: 15,
        fontStyle: 'semibold',
        fontColor: HEXColors.red,
        height: 20,
        fill: HEXColors.black,
        align: 'center',
        verticalAlign: 'center',
        width: buttonWidth - 60,
        text: (this.state.data.config as QuickReplyPluginConfig)?.skip_button
          ?.title,
        caption: i18next.t('text_card_button_view-string--106-add-button-name'),
        captionFill: HEXColors.red,
        singleLine: true,
        attributeOnly: false,
        editable: () => this.state.isEditing,
      },
      undefined,
      (title) => {
        this.skipButtonTitle = title;
      },
      () => {
        this.state.setAndSave(({ config }) => ({
          config: set(Lenses.skipButtonTitleLens, this.skipButtonTitle, config),
        }));

        this.logger.log('rename skip button', {
          value: (this.state.data.config as QuickReplyPluginConfig)?.skip_button
            ?.title,
        });
      },
      {
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
      },
    );

    const buttonConfig =
      (this.state.data.config as QuickReplyPluginConfig).skip_button ||
      getQuickReplyConfig({}).skip_button;

    const skipButtonView = this.addStatView(
      new CardButton({
        buttonConfig,
        card: this.state.data,
        node: this._node,
        text: {
          view: buttonTextView,
          props: {
            margin: {
              top: 10,
              left: 30,
            },
          },
        },
        arrowProps: {
          onConnected: (blockView) => {
            this.state.setAndSave(({ config }) => {
              const skip_button: QuickReplySkipButton = {
                __typename: 'ButtonConfig',
                title: '',
                ...(config as QuickReplyPluginConfig).skip_button,
                block_ids: [blockView.id()],
              };

              return {
                config: set(Lenses.skipButtonLens, skip_button, config),
              };
            });

            this.logger.log('connect skip button', {
              value: (this.state.data.config as QuickReplyPluginConfig)
                ?.skip_button?.block_ids,
            });
          },
          onRemoved: () => {
            this.state.setAndSave(({ config }) => ({
              config: set(Lenses.skipButtonBlockIdLens, null, config),
            }));

            this.logger.log('disconnect skip button', {
              value: (this.state.data.config as QuickReplyPluginConfig)
                ?.skip_button?.block_ids,
            });
          },
        },
      }),
      DatepickerStatsCounterPostfixes.skip,
    );

    skipButtonView.on('click', (e: InteractionEvent) => {
      e.stopPropagation();
      buttonTextView.startEditing();
    });

    skipButtonView.TEST_NAME = 'SkipButton';

    this.addToLayout(skipButtonView, {
      gone: () => !this.state.data.config.allow_skip,
      margin: new Margin({ left: 15, bottom: 15 }),
    });
  }

  private addDatepickerButton() {
    const datePickerButton = this.createDatepickerButton();

    datePickerButton.TEST_NAME = 'DatePickerButton';

    tooltipScaled({
      view: datePickerButton,
      text: `
<img src="${datepickerPreview}" width="215" height="205" />
<br/><br/>
${i18next.t(
  'modernComponents.FlowBuilder.views.plugins.QuickReplyPlugin.datePickerTooltip',
)}
`,
    });

    this.addToLayout(datePickerButton, {
      margin: new Margin({ x: 15, y: 15 }),
      gone: () => !this.isDatePicker(),
    });
  }

  private createQuickReplyButtonView(
    button: QRButtonViewProps['buttonConfig'],
    editable: boolean,
    configLens: (id: number) => Lens,
  ) {
    return new QRButtonView({
      buttonConfig: button,
      updateCardViaDeprecatedMethod: false,
      node: this._node,
      card: this.state.data,
      editable,
      maxLength: () => resByFunc(this.props.maxLength),
      isEditing: () => this.state.isEditing,
      onRemoved: (qrIndex) => {
        const lens = compose(configLens(qrIndex), Lenses.blockIdsLens) as Lens;

        this.state.setAndSave(({ config }) => ({
          config: set(lens, [], config),
        }));
      },
      onConnected: (blockView, qrIndex) => {
        const blockIdsLens = compose(
          configLens(qrIndex),
          Lenses.blockIdsLens,
        ) as Lens;

        this.state.setAndSave(({ config }) => ({
          config: set(blockIdsLens, [blockView.id()], config),
        }));
      },
      onTitleChangeProcess: (newText, qrIndex) =>
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          element: `${OnboardingTourShape.QuickReplyButtonViewTextAreaShape}${qrIndex}`,
          value: newText,
        }),
      onTitleChange: (newText, qrIndex) => {
        const lens = compose(configLens(qrIndex), Lenses.titleLens) as Lens;

        this.state.setAndSave(({ config }) => ({
          config: set(lens, newText, config),
        }));
      },
    });
  }

  private addQuickReply(
    button: QRButtonViewProps['buttonConfig'],
    editable: boolean,
    configLens: (id: number) => Lens,
    hideControls?: boolean,
  ) {
    const qrButtonView = this.createQuickReplyButtonView(
      button,
      editable,
      configLens,
    );
    this.quickReplyView?.addToLayout(qrButtonView, {
      margin: () => {
        return {
          bottom: this.quickReplyView?.isLast(qrButtonView) ? 15 : 10,
        };
      },
    });

    const qrIndex = this.quickReplyView.indexOf(qrButtonView);

    qrButtonView.setButtonShapeName(
      `${OnboardingTourShape.QuickReplyButtonViewButtonShape}${qrIndex}`,
    );
    qrButtonView.setTextAreaShapeName(
      `${OnboardingTourShape.QuickReplyButtonViewTextAreaShape}${qrIndex}`,
    );

    if (editable && !hideControls) {
      buttonControl(
        qrButtonView,
        this.quickReplyView,
        () => {
          this._node.updateLines();
        },
        () => {
          const config = this.state.data.config as QuickReplyPluginConfig;
          const newButtons = [...(config.buttons || [])];
          const qrIndex = this.quickReplyView.indexOf(qrButtonView);
          newButtons.splice(qrIndex, 1);

          this.quickReplyView?.removeView(qrButtonView);
          qrButtonView.destroy();
          this._node.updateLines();
          this.renderNode();

          this.state.setAndSave(({ config }) => ({
            config: set(Lenses.buttonsLens, newButtons, config),
          }));

          this.logger.log('remove button');
        },
        (_: View, startIdx: number, idx: number) => {
          const config = this.state.data.config as QuickReplyPluginConfig;
          const newButtons = [...(config.buttons || [])];
          moveArrayElement(newButtons, startIdx, idx);

          this.state.setAndSave(({ config }) => ({
            config: set(Lenses.buttonsLens, newButtons, config),
          }));

          this.logger.log('drag button');
        },
      );
    }
    return qrButtonView;
  }

  private isDatePicker() {
    return this.state.data.plugin_id === PluginType.date_picker;
  }

  private updateButtons() {
    if (this.isDatePicker()) {
      this.addDatepickerButton();
    }
  }

  private updateQuickReplyPlusButton() {
    this.plusButtonView!.update({
      title: this._node.title ?? this.props.plusButtonText,
    });
  }

  private updateQuickReply() {
    if (this.quickReplyView) {
      const { config } = this.state.data;

      const views = this.quickReplyView.views();
      views.forEach((v) => this.quickReplyView?.removeView(v));
      views.forEach((v) => v.destroy());
      if (
        config.type === QuickReplyType.email &&
        (config as QuickReplyPluginConfig).email?.facebook_suggest
      ) {
        this.state.setAndSave(({ config }) => ({
          config: set(Lenses.emailTitleLens, EMAIL_PLACEHOLDER, config),
        }));

        this.addQuickReply(
          (this.state.data.config as QuickReplyPluginConfig)
            .email as QuickReplyEmail,
          false,
          () => Lenses.emailLens,
        );
      } else if (
        config.type === QuickReplyType.phone &&
        (config as QuickReplyPluginConfig).phone?.facebook_suggest
      ) {
        this.state.setAndSave(({ config }) => ({
          config: set(Lenses.phoneTitleLens, PHONE_PLACEHOLDER, config),
        }));

        this.addQuickReply(
          (this.state.data.config as QuickReplyPluginConfig)
            .phone as QuickReplyPhone,
          false,
          () => Lenses.phoneLens,
        );
      } else if (config.type === QuickReplyType.date) {
        const buttonConfig: DatePickerDateButton = {
          __typename: 'ButtonConfig',
          title: i18next.t('QuickReplyPlugin-string--324-book-date'),
          block_ids: [],
        };

        if (!(config as DatePickerPluginConfig).date_button) {
          this.state.setAndSave(({ config }) => ({
            config: set(Lenses.dateButtonLens, buttonConfig, config),
          }));
        }

        this.addQuickReply(
          buttonConfig,
          true,
          () => Lenses.dateButtonLens,
          true,
        );
      } else if (config.type === QuickReplyType.datetime) {
        const buttonConfig: DatePickerDateButton = {
          __typename: 'ButtonConfig',
          title: i18next.t('QuickReplyPlugin-string-2054-book-date-and-time'),
          block_ids: [],
        };

        if (!(config as DatePickerPluginConfig).date_button) {
          this.state.setAndSave(({ config }) => ({
            config: set(Lenses.dateButtonLens, buttonConfig, config),
          }));
        }

        this.addQuickReply(
          buttonConfig,
          true,
          () => Lenses.dateButtonLens,
          true,
        );
      } else {
        (config as QuickReplyPluginConfig).buttons?.forEach((button) => {
          this.addQuickReply(button, true, Lenses.getButtonsLens);
        });
      }
    }
  }

  public onBeforeRender() {
    const { config } = this.state.data;

    this.saveAttribute.text(config.property || '');

    if (isNeedShowQr()) {
      if (this.isQrAfterImageInInstagramPrivateReply()) {
        this.updateQuickReply();
      }
      this.updateQuickReplyPlusButton();
    }
  }

  private isAttributeFieldValid() {
    const config = this.state.data.config as QuickReplyPluginConfig;

    if (
      (config.type === QuickReplyType.phone &&
        config.phone?.facebook_suggest) ||
      (config.type === QuickReplyType.email && config.email?.facebook_suggest)
    ) {
      return !!config.property;
    }
    return true;
  }

  private isQRAvailableAtCurrentPosition() {
    const plugins = this._node.block.cards || [];
    const position = plugins.findIndex(({ id }) => id === this.state.data.id);
    const isFacebookAdsEntryPointPluginExist =
      plugins.filter((plugin) => isFacebookAds(plugin.plugin_id)).length > 0;

    if (isFacebookAdsEntryPointPluginExist) {
      return true;
    }

    return isContentPluginBeforePosition(
      plugins,
      position,
      !!(this.state.data.config as QuickReplyPluginConfig).buttons?.length,
    );
  }

  private getPreviousCard() {
    const plugins = this._node.block.cards;
    const position = this.state.data.position || 0;

    return (plugins
      .slice(0, position)
      .filter((plugin) => !(plugin.plugin_id === PluginType.comment))
      .pop() || {}) as Card;
  }

  private validateErrorMessage() {
    const { type, error_messages } = this.state.data
      .config as QuickReplyPluginConfig;

    const isErrorMessageEmpty = error_messages?.[type!]?.length === 0;

    if (
      !QUICK_REPLY_TYPES_WITH_ERROR_MESSAGES.includes(type!) ||
      !isErrorMessageEmpty
    ) {
      return undefined;
    }

    if (isErrorMessageEmpty) {
      if (type === QuickReplyType.email) {
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.emails.inputMessageError',
        );
      }

      if (type === QuickReplyType.phone) {
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.phones.inputMessageError',
        );
      }

      if (type === QuickReplyType.number) {
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.numbers.inputMessageError',
        );
      }
    }

    return undefined;
  }

  public validationError() {
    const config = this.state.data.config as QuickReplyPluginConfig;

    const errorMessage = this.validateErrorMessage();
    if (errorMessage) {
      return errorMessage;
    }

    if (
      !this.isQRAvailableAtCurrentPosition() &&
      !this.isQrAfterImageInInstagramPrivateReply()
    ) {
      return getPluginError(config.type!, this.getPreviousCard()?.plugin_id);
    }

    if (
      !this.props.isEmptyQuickReplyAvailable &&
      !config.property &&
      !config.buttons?.length &&
      isNeedShowQr()
    ) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.plugins.QuickReplyPlugin.saveUserInputWarnAddAttrOrQr',
      );
    }

    if (isInsideJsonAdsEp(this._node.block) && !config.buttons?.length) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.plugins.QuickReplyPlugin.saveUserInputQr',
      );
    }

    if (
      !this.props.isEmptyQuickReplyAvailable &&
      !config.property &&
      !isNeedShowQr()
    ) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.plugins.QuickReplyPlugin.saveUserInputWarnAddAttr',
      );
    }

    if (
      this.props.isAtLeastOneButtonIsRequired &&
      config.buttons?.length === 0
    ) {
      return i18next.t(
        'QuickReplyPlugin-string-1239-at-least-one-quick-reply-button-is-required',
      );
    }

    if (this.isDatePicker()) {
      const previousCard = this.getPreviousCard();
      if (
        (previousCard?.config?.buttons?.length || 0) > 0 ||
        previousCard.plugin_id !== 'text'
      ) {
        return i18next.t(
          'modernComponents.FlowBuilder.views.plugins.QuickReplyPlugin.datePickerWarnTextCardBefore',
        );
      }
    }

    const hasChangableQuickReplies =
      config.type === QuickReplyType.number ||
      config.type === QuickReplyType.text ||
      (config.type === QuickReplyType.phone &&
        !config.phone?.facebook_suggest) ||
      (config.type === QuickReplyType.email && !config.email?.facebook_suggest);

    if (hasChangableQuickReplies) {
      return config?.buttons?.some((b) => !isQRButtonValid(b));
    }

    if (this.isDatePicker()) {
      return !!(
        !(config as any).date_button?.title ||
        (config.allow_skip && !this.skipButtonTitle)
      );
    }
    return !this.isAttributeFieldValid();
  }

  public hasSignificantChangesInConfig() {
    const config = this.state.data.config as QuickReplyPluginConfig;

    return (
      (config.property &&
        config.property !== 'email' &&
        config.property !== 'phone') ||
      hasNonEmptyButtons(config.buttons) ||
      config.email?.block_ids ||
      config.phone?.block_ids
    );
  }

  public pluginDidSaveError() {
    toaster.error({
      payload: {
        message: Messages.somethingWentWrong,
      },
    });
  }

  public pluginDidSet(prevState: Plugin<Config>) {
    const { config } = this.state.data;
    const isEmailFacebookSuggestChanged =
      view(Lenses.emailFacebookSuggestLens, prevState.config) !==
      view(Lenses.emailFacebookSuggestLens, config);

    const isPhoneFacebookSuggestChanged =
      view(Lenses.phoneFacebookSuggestLens, prevState.config) !==
      view(Lenses.phoneFacebookSuggestLens, config);

    if (isEmailFacebookSuggestChanged || isPhoneFacebookSuggestChanged) {
      this.updateQuickReply();
    }

    this.renderNode();
  }

  public destroy() {
    this.unsubscribesFromFollowUps?.();
    this.unsubscribesFromFollowUps = undefined;

    this.state.destroy();

    super.destroy();
  }
}
