import i18next from 'i18next';
import { BaseTexture, Texture } from 'pixi.js-legacy';
import { log } from 'cf-common/src/logger';
import memoize from 'lodash-es/memoize';
import { Card } from '@components/Plugins/common/PluginData';
import { Platform, QuickReplyType } from '@globals';
import {
  pluginCollectAnswerSvgTexture,
  pluginCollectEmailSvgTexture,
  pluginCollectNumbersSvgTexture,
  pluginCollectPhoneSvgTexture,
  pluginDatepickerSvgTexture,
} from '../../../assets/textures';
import { PluginType } from '../../../types';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import Maybe from 'graphql/tsutils/Maybe';

const EXCLUDE_FOR_INSTAGRAM_PLUGINS = [PluginType.image, PluginType.gallery];

const getQrContentPlugins = (hasButtons: boolean) => {
  const baseContentPlugins = [
    PluginType.text,
    PluginType.image,
    PluginType.video,
    PluginType.document,
  ];

  if (getFlowPlatform() === Platform.facebook) {
    return [
      ...baseContentPlugins,
      PluginType.audio,
      PluginType.gallery,
      PluginType.list,
      PluginType.facebook_shops_product_list,
    ];
  }

  if (getFlowPlatform() === Platform.instagram) {
    return [
      PluginType.text,
      PluginType.video,
      PluginType.audio,
      ...(hasButtons ? [] : EXCLUDE_FOR_INSTAGRAM_PLUGINS),
    ];
  }

  if (getFlowPlatform() === Platform.whatsapp) {
    return [
      ...baseContentPlugins,
      PluginType.whatsapp_list,
      PluginType.whatsapp_template,
      PluginType.whatsapp_location,
    ];
  }

  return baseContentPlugins;
};

export const isContentPluginBeforePosition = <T>(
  plugins: Array<Card<T>>,
  position: number,
  hasButtons: boolean,
) =>
  position > 0 &&
  (plugins || []).length > 0 &&
  getQrContentPlugins(hasButtons).includes(
    ((
      plugins
        .slice(0, position)
        .filter((plugin) => !(plugin.plugin_id === PluginType.comment))
        .pop() || {}
    ).plugin_id || '') as PluginType,
  );

export const getPluginTitle = memoize((quickReplyType: QuickReplyType) => {
  switch (quickReplyType) {
    case QuickReplyType.number:
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.numbers.title',
      );
    case QuickReplyType.text:
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.answers.title',
      );
    case QuickReplyType.email:
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.emails.title',
      );
    case QuickReplyType.phone:
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.phones.title',
      );
    case QuickReplyType.date:
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.datePicker.title',
      );
    case QuickReplyType.datetime:
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.dateAndTime.title',
      );
    default:
      log.error({ msg: `Unexpected quick reply type ${quickReplyType}` });
      return '';
  }
});

export const getPluginError = memoize(
  (quickReplyType: QuickReplyType, previousCardType: Maybe<PluginType>) => {
    if (
      getFlowPlatform() === Platform.instagram &&
      previousCardType &&
      EXCLUDE_FOR_INSTAGRAM_PLUGINS.includes(previousCardType)
    ) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.CollectUserData.common.prevImageError',
      );
    }

    switch (quickReplyType) {
      case QuickReplyType.number:
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.numbers.error',
        );
      case QuickReplyType.text:
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.answers.error',
        );
      case QuickReplyType.email:
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.emails.error',
        );
      case QuickReplyType.phone:
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.phones.error',
        );
      case QuickReplyType.date:
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.datePicker.error',
        );
      case QuickReplyType.datetime:
        return i18next.t(
          'modernComponents.FlowBuilder.views.components.CollectUserData.dateAndTime.error',
        );
      default:
        log.error({ msg: `Unexpected quick reply type ${quickReplyType}` });
        return '';
    }
  },
  (...args) => args.join('_'),
);

export const getPluginIcon = (quickReplyType: QuickReplyType) => {
  switch (quickReplyType) {
    case QuickReplyType.number:
      return pluginCollectNumbersSvgTexture;
    case QuickReplyType.text:
      return pluginCollectAnswerSvgTexture;
    case QuickReplyType.email:
      return pluginCollectEmailSvgTexture;
    case QuickReplyType.phone:
      return pluginCollectPhoneSvgTexture;
    case QuickReplyType.date:
      return pluginDatepickerSvgTexture;
    case QuickReplyType.datetime:
      return pluginDatepickerSvgTexture;
    default:
      log.error({ msg: `Unexpected quick reply type ${quickReplyType}` });
      return new Texture(new BaseTexture());
  }
};

export const isNeedShowQr = () => getFlowPlatform() !== Platform.whatsapp;
