import { strictFn } from '@utils/strictFn';
import { EntryPointView } from '../../../types';
import { BlockView } from '../../block_view';

export const isEntryPoint = (id: string | undefined) =>
  id?.indexOf('entry_point/') === 0;

export const isInstagramEntryPoint = (id: string) =>
  id.includes('entry_point/instagram');

export const getEntryPointCardView = (blockView: BlockView) =>
  blockView?._cardsLayout?.children?.find((view: any) =>
    isEntryPoint(view._card?.plugin_id),
  ) as EntryPointView | undefined;

interface WithPluginId {
  plugin_id: string;
}

export const getEntryPointCard = <T extends WithPluginId>(
  cards: T[] | null,
): T | undefined => cards?.find(({ plugin_id }) => isEntryPoint(plugin_id));

export const getEntryPointCardStrict = strictFn(getEntryPointCard);
export const getEntryPointCardViewStrict = strictFn(getEntryPointCardView);
