import { HEXColors } from '@ui/_common/colors';
import { equals } from 'ramda';
import { zapierImportContentPluginFragment_config_preview_gallery_cards as PreviewCard } from '../../../../../../Plugins/ZapierImportContentPlugin/@types/zapierImportContentPluginFragment';
import {
  HLayout,
  MainLayout,
} from '../../../../../components/Elements/Layouts';
import { PropFunc } from '../../../../../types';
import { pluginWidth } from '../../../../plugin_consts';
import { resByFunc } from '../../../../utils';
import { ZapierImportContentPreviewCardView } from './components/ZapierImportContentPreviewCardView';

export interface FacebookShopsProductListPluginCard extends PreviewCard {
  isEditing?: boolean;
}

const MAX_NO_EDITING_QTY = 3;
const CARD_PADDING = 8;

interface ZapierImportContentPreviewProps {
  isEditing: PropFunc<boolean>;
}

export class ZapierImportContentPreviewView extends MainLayout {
  private readonly cardsLayout: HLayout;
  private readonly props: ZapierImportContentPreviewProps;
  private cards: PreviewCard[];
  private isFocused: boolean = false;

  constructor(props: ZapierImportContentPreviewProps) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors._ff0000,
        opacity: 0,
      },
    });

    this.props = props;
    this.cards = [];

    this.cardsLayout = new HLayout({
      itemsOffset: () =>
        this.shouldOpen() ? 10 : -(pluginWidth - CARD_PADDING),
      zIndexFunc: (zIndex: number) => 1000 - zIndex + 1,
    });

    this.cardsLayout.sortableChildren = true;
    this.addToLayout(this.cardsLayout, {
      margin: () => ({
        top: Math.min(MAX_NO_EDITING_QTY, this.cards.length) * CARD_PADDING,
      }),
    });

    this.on('click', () => {
      setTimeout(() => {
        this.isFocused = true;
        this.renderNode();
      }, 10); // more next tics, when isEditing state ready
    });
  }

  private updateProductsCardsViews() {
    this.cardsLayout.views().forEach((productCardView) => {
      productCardView.destroy();
      this.cardsLayout.removeView(productCardView);
    });

    this.cards.forEach((card, index) => {
      this.layoutProductCard(card, index);
    });
  }

  private shouldOpen() {
    const isEditing = resByFunc(this.props.isEditing);
    if (!isEditing) {
      this.isFocused = false;
    }
    return this.isFocused;
  }

  private layoutProductCard(card: PreviewCard, index?: number) {
    const productCardView = new ZapierImportContentPreviewCardView(card);
    productCardView._layoutProps.cursor = () => ({
      in: this.shouldOpen() ? 'default' : 'pointer',
    });
    this.cardsLayout.addToLayout(
      productCardView,
      {
        margin: () => {
          const index = this.cardsLayout.findViewIndex(productCardView);
          const top = this.shouldOpen() ? 0 : -CARD_PADDING * index;
          return { top };
        },
        gone: () => {
          const index = this.cardsLayout.findViewIndex(productCardView);
          return !this.shouldOpen() && index + 1 > MAX_NO_EDITING_QTY;
        },
      },
      typeof index === 'number' ? index : undefined,
    );

    productCardView.on('click', (event: Event) => {
      if (this.shouldOpen()) {
        event.stopPropagation();
      }
    });
  }

  public updateCards(cards: PreviewCard[]) {
    if (equals(cards, this.cards)) {
      return;
    }
    this.cards = cards;
    this.updateProductsCardsViews();
  }
}
