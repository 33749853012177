import { ButtonConfig } from './kit/CardButton';

export function buttonBlockId(button: ButtonConfig): string | undefined {
  if (typeof button === 'undefined') {
    return undefined;
  }
  // Seems like its not working logic because block_id doesn't exist in ButtonConfig
  // probably should rename block_id -> block_ids?
  // @ts-ignore
  if (typeof button.block_id !== 'undefined') {
    // @ts-ignore
    if (Array.isArray(button.block_id) && button.block_id.length > 0) {
      // @ts-ignore
      return button.block_id[0];
    }
    // @ts-ignore
    if (typeof button.block_id === 'string') {
      // @ts-ignore
      return button.block_id;
    }
  }
  if (
    typeof button.block_ids !== 'undefined' &&
    Array.isArray(button.block_ids) &&
    button.block_ids.length > 0
  ) {
    return button.block_ids[0];
  }
  return undefined;
}
