import { HEXColors } from '@ui/_common/colors';
import {
  BackgroundProps,
  defaultObj,
  HLayout,
  MainLayout,
} from '../../components/Elements/Layouts';
import { Circle, HTMLText } from '../../components/Elements/Shapes';
import { RADIO_SIZE } from './radio';

interface ToggleProps {
  disabled?: boolean;
  styles?: {
    background: BackgroundProps;
  };
}

interface ExtendedEvent extends MouseEvent {
  preventDefault: () => void;
}

export class Toggle extends HLayout {
  TEST_NAME = 'Toggle';
  _value: boolean;

  _labelView: HTMLText;
  private _control: MainLayout;

  _props: ToggleProps;

  private prevented = false;

  constructor(
    value: boolean,
    onChange: (event: ExtendedEvent, value: boolean) => void,
    label?: string,
    props: ToggleProps = {},
  ) {
    super({
      height: 18,
      cursor: { in: 'pointer' },
    });

    this._props = props;

    let background: BackgroundProps = {
      fill: ({ parent: toggleView }: any) => {
        if (this._props.disabled) {
          return HEXColors.greyLight10;
        }

        return toggleView?._value ? HEXColors.blue : HEXColors.grey;
      },
      cornerRadius: 9,
      opacity: ({ parent: toggleView }: any) =>
        toggleView?._props.disabled ? 0.4 : 1,
    };

    if (this._props?.styles?.background) {
      background = defaultObj(background, this._props.styles.background);
    }

    this._control = new MainLayout({
      width: 32,
      height: 18,
      background,
    });
    this._value = value || false;
    this._control.layout(
      new Circle({
        radius: 6.7,
        fill: HEXColors.white,
      }),
      {
        margin: () => ({
          left: this._value ? 16 : 2.3,
          top: 2.3,
        }),
      },
    );

    this.addToLayout(this._control);

    this._labelView = new HTMLText({
      text: label,
      height: RADIO_SIZE,
      fontSize: 15,
      verticalAlign: 'center',
      fill: props.disabled ? HEXColors._999999 : HEXColors.black,
    });

    if (label) {
      this.addToLayout(this._labelView, { margin: { left: 10 } });
    }

    this.on('pointerdown', (event: MouseEvent) => {
      event.stopPropagation();
      if (props.disabled) {
        return;
      }

      const extendedEvent = {
        ...event,
        preventDefault: () => {
          this.prevented = true;
        },
      };

      onChange(extendedEvent, !this._value);

      if (this.prevented) {
        return;
      }

      this._value = !this._value;
      this.renderNode();
    });
    this.on('click', (event: MouseEvent) => {
      event.stopPropagation();
    });
  }

  setValue(value: boolean) {
    this._value = value;
  }

  setDisabled(value: boolean, silent?: boolean) {
    this._props.disabled = value;
    this._labelView.fill(
      this._props.disabled ? HEXColors._999999 : HEXColors.black,
    );
    this._control.setDisabled(value);
    if (!silent) {
      this.renderNode();
    }
  }
}
