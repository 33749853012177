import React from 'react';
import { CheckBox, CheckBoxProps } from '@ui/CheckBox';
import { Icon } from '@ui/Icon';
import { ListItemWithPicture } from '@ui/ListItemWithPicture';
import { TimeLabel } from '@ui/ListItemWithPicture/ListItemWithPicture';
import { Tooltip2 } from '@ui/Tooltip2';
import { Flex } from '@ui/Flex';
import * as css from './TreeChildren.css';
import {
  Data,
  TooltipContent,
  TooltipContentProps,
} from './ErrorTooltipContent';

export interface TreeItemData extends Data {
  name: string;
  preview_image_big: string | null;
  preview_image_small: string | null;
  created_time: number | null;
}

export interface TreeItemProps extends Omit<TooltipContentProps, 'data'> {
  data: TreeItemData;
  validationError: boolean;
  onCheckedChange: CheckBoxProps['onClick'];
  checked: boolean;
}

export const TreeItem = ({
  data,
  validationError,
  onCheckedChange,
  checked,
  ...tooltipProps
}: TreeItemProps) => {
  return (
    <Flex alignItems="center">
      {validationError ? (
        <Tooltip2
          placement="right"
          hoverable
          type="small"
          content={<TooltipContent data={data} {...tooltipProps} />}
        >
          {(ref, bind) => (
            <div className={css.warning} ref={ref} {...bind}>
              <Icon
                icon="warning"
                size="28px"
                color="red"
                className={css.icon}
              />
            </div>
          )}
        </Tooltip2>
      ) : (
        <CheckBox
          onChange={onCheckedChange}
          checked={checked}
          className={css.checkbox}
        />
      )}
      <Tooltip2
        boundariesElement="viewport"
        type="small"
        disabled={!data.preview_image_big}
        content={
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            className={css.previewImageBox}
          >
            <img
              alt=""
              // @ts-ignore if preview_image_big is null then tooltip would not be printed at all
              src={data.preview_image_big}
              className={css.previewImage}
            />
          </Flex>
        }
      >
        {(ref, bind) => (
          <div ref={ref} {...bind}>
            <ListItemWithPicture
              message={data.name}
              picture={data.preview_image_small}
              messageProps={{ as: 'p', noWrap: true, size: '15px_DEPRECATED' }}
              messageStyles={{ maxWidth: 500 }}
            >
              <TimeLabel time={data.created_time} />
            </ListItemWithPicture>
          </div>
        )}
      </Tooltip2>
    </Flex>
  );
};
