import { showChooseFacebookPostsDialog } from '@components/FlowBuilder/components';
import { PluginType } from '@components/FlowBuilder/types';
import {
  CommentsAutoreplyPostsSelector,
  CommentsAutoreplyPostsSelectorPluginConfig,
} from '../components/CommentsAutoreplyPostsSelector';
import {
  commentsAutoreplyEntryPointFragment_config as EntryPointCommentsAutoreplyConfig,
  commentsAutoreplyEntryPointFragment_config_posts as CommentsAutoreplyPost,
} from '../../../../../Plugins/CommentsAutoreplyEntryPoint/@types/commentsAutoreplyEntryPointFragment';
import { PostViewModel } from '../components/PostItem';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';

export interface MessengerCommentsAutoreplyPostsSelectorProps {
  plugin: CommentsAutoreplyPostsSelectorPluginConfig<EntryPointCommentsAutoreplyConfig>;
  logger: PluginLogger;
}

export class MessengerCommentsAutoreplyPostsSelector extends CommentsAutoreplyPostsSelector<
  CommentsAutoreplyPost,
  EntryPointCommentsAutoreplyConfig
> {
  getPostData(post: CommentsAutoreplyPost): PostViewModel {
    return {
      __typename: post.__typename,
      id: post.id,
      link: post.permalink_url,
      text: post.message,
      picture: post.full_picture,
    };
  }
  onShowChoosePostsDialog(updatePostList: () => void): void {
    showChooseFacebookPostsDialog({
      selectedPosts: this.params.plugin.state.data.config.posts ?? [],
      onSubmit: (posts) => {
        this.params.plugin.state.set(({ config }) => ({
          config: {
            ...config,
            posts: posts ?? [],
          },
        }));

        this.removePostsFromOtherPlugin(
          posts.map((v) => v.id),
          false,
        );
        updatePostList();
      },
      onPostRemove: (post) => {
        this.removePostsFromOtherPlugin([post.id], true);
      },
    });
  }

  private readonly params: MessengerCommentsAutoreplyPostsSelectorProps;

  constructor(params: MessengerCommentsAutoreplyPostsSelectorProps) {
    super(params.plugin, params.logger, {
      updateCardViaDeprecatedMethod: true,
      pluginType: PluginType.comments_autoreply_entry_point,
    });

    this.params = params;
  }
}
