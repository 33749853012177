import { propEq } from 'ramda';
import { Subscription } from 'apollo-client/util/Observable';
import { BotStatus, getConnectedPageObservable } from '@utils/Bot';
import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
} from '@components/ConnectPageDialog';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import { HLayout, VLayout } from '../../../../components/Elements/Layouts';
import { Dropdown, Item } from '../../../kit/Dropdown';
import { Node } from '../../../../Node';
import { Card } from '../../../../types';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { commentsAutoreplyEntryPointFragment_config as EntryPointCommentsAutoreplyConfig } from '../../../../../Plugins/CommentsAutoreplyEntryPoint/@types/commentsAutoreplyEntryPointFragment';
import { addButtonControl } from '../../../helpers/ControlsHelpers';
import { PostItem, PostViewModel } from './PostItem';
import { pluginWidth } from '../../../plugin_consts';
import { PlusButtonView } from '../../../plus_button_view';
import { Margin } from '../../../utility_classes';
import { swapImmutable } from '../../../utils';
import { getPixiField } from '../../../../PixiFieldRepository';
import { HEXColors } from '@ui/_common/colors';
import { HTMLText } from '@components/FlowBuilder/components/Elements/Shapes';

export enum ApplyRuleType {
  all = 'all',
  specific = 'specific',
}

export interface EntryPointCommentsAutoreplyConfigCommon<T>
  extends Omit<EntryPointCommentsAutoreplyConfig, '__typename' | 'posts'> {
  __typename: string;
  posts: T[] | null; // type depends on ep type: messenger, instagram
}

const POSTS_BUTTON_MARGIN = new Margin({ bottom: 8 });

const APPLY_TYPES: () => Item[] = () => [
  {
    id: ApplyRuleType.all,
    title: window.i18next.t('PostsSelector-string-1086-all-posts'),
  },
  {
    id: ApplyRuleType.specific,
    title: window.i18next.t('PostsSelector-string--997-specific-posts'),
  },
];

interface HeaderViewProps {
  showHeader?: boolean;
  title?: string;
}

export interface PostsSelectorProps extends HeaderViewProps {
  pluginType: PluginType;
  updateCardViaDeprecatedMethod: boolean;
  hideDrowdown?: boolean;
  isEditing: () => boolean;
  choosePostButtonTitle?: string;
  editPostsButtonTitle?: string;
}

type PostsSelectorAction = 'Add' | 'Edit';

export abstract class PostsSelector<T> extends VLayout {
  TEST_NAME = 'PostsSelector';
  protected readonly choosePostButton: PlusButtonView;
  protected readonly editPostsButton: PlusButtonView;
  public readonly headerText: HTMLText;

  private postsBox: VLayout;

  private get card(): Card<EntryPointCommentsAutoreplyConfigCommon<T>> {
    return this._getCard();
  }
  private node: Node;

  private readonly props: PostsSelectorProps;
  private _getCard: () => Card<EntryPointCommentsAutoreplyConfigCommon<T>>;

  private connectedPageObservable: Subscription | undefined;
  private isPageConnected: boolean = false;

  constructor(
    getCard: () => Card<EntryPointCommentsAutoreplyConfigCommon<T>>,
    node: Node,
    props: PostsSelectorProps,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
        stroke: HEXColors.red,
        strokeWidth: (v: any) =>
          !this.props.isEditing() && !v.isValid() ? 2 : 0,
      },
      cursor: {
        in: 'default',
      },
    });

    this._getCard = getCard;
    this.node = node;
    this.props = props;

    const wrapper = new VLayout();

    wrapper.addToLayout(
      new Dropdown({
        defaultSelectedItem:
          APPLY_TYPES().find(
            propEq(
              'id',
              this.card.config.apply_for_all_posts
                ? ApplyRuleType.all
                : ApplyRuleType.specific,
            ),
          ) || APPLY_TYPES()[0],
        items: APPLY_TYPES(),
        onChange: (selectedItem) => {
          this.onChange({
            ...this.card.config,
            apply_for_all_posts: selectedItem.id === ApplyRuleType.all,
          });
          this.onApplyRuleTypeChange(selectedItem.id as ApplyRuleType);
        },
        isEditing: this.props.isEditing,
        label: window.i18next.t('PostsSelector-string--732-apply-to'),
      }),
      {
        margin: { bottom: 8 },
        gone: () => this.props.hideDrowdown,
      },
    );

    this.headerText = new HTMLText({
      text: this.props.title,
      singleLine: true,
      width: pluginWidth,
      trustedHtml: true,
      fontSize: 15,
      fontStyle: 'semibold',
      fill: HEXColors.black,
    });

    wrapper.addToLayout(this.headerText, {
      margin: { bottom: 16 },
      gone: () => !this.props.showHeader || !this.card.config.posts?.length,
    });

    this.postsBox = new VLayout({
      width: pluginWidth - 32,
    });

    wrapper.addToLayout(this.postsBox, {
      gone: () => this.card.config.apply_for_all_posts,
    });

    this.editPostsButton = this.createEditPostButton();
    wrapper.addToLayout(this.editPostsButton, {
      margin: POSTS_BUTTON_MARGIN,
      gone: () =>
        !!(
          this.card.config.apply_for_all_posts ||
          this.card.config.posts?.length === 0 ||
          (!this.props.isEditing() && this.card.config.posts?.length)
        ),
    });

    this.choosePostButton = this.createChoosePostButton();
    wrapper.addToLayout(this.choosePostButton, {
      margin: POSTS_BUTTON_MARGIN,
      gone: () =>
        !!(
          this.card.config.apply_for_all_posts ||
          this.card.config.posts?.length !== 0 ||
          (!this.props.isEditing() && this.card.config.posts?.length)
        ),
    });

    this.addToLayout(wrapper, {
      margin: { left: 16, right: 16, top: 16, bottom: 8 },
    });

    this.createPostsList();

    const urlParams = getConnectPageUrlParams();
    if (
      urlParams[ConnectPageField.origin] ===
        ConnectPageOrigin.commentsAddPosts &&
      urlParams[ConnectPageField.blockId] === this.node.id &&
      urlParams[ConnectPageField.cardId] === this.card.id
    ) {
      this.handleConnectPageAndShowChoosePostsDialog(
        urlParams[ConnectPageField.actionId] as PostsSelectorAction,
      );
    }

    this.isPageConnected = !!this.node.controller.flow.bot.status?.page;
    if (!getPixiField()?.isViewOnly()) {
      this.connectedPageObservable = getConnectedPageObservable(
        this.node.controller.flow.bot.id,
      ).subscribe(({ pageId, status }) => {
        this.isPageConnected = !!(pageId && status !== BotStatus.noPage);
      });
    }
  }

  private createChoosePostButton() {
    const choosePostButton = new PlusButtonView(
      this.props.choosePostButtonTitle ||
        window.i18next.t('PostsSelector-string--582-＋-add-posts'),
      pluginWidth - 32,
    );

    choosePostButton.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });

    choosePostButton.on('click', (event: Event) => {
      event.stopPropagation();
      this.handleButtonClick();
    });

    return choosePostButton;
  }

  private createEditPostButton() {
    const editPostsButton = new PlusButtonView(
      this.props.editPostsButtonTitle ||
        window.i18next.t('PostsSelector-string--182-edit-posts'),
      pluginWidth - 32,
    );

    editPostsButton.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });

    editPostsButton.on('click', (event: Event) => {
      event.stopPropagation();
      this.handleButtonClick();
    });

    return editPostsButton;
  }

  protected handleButtonClick() {
    if (this.isPageConnected) {
      this.handleShowChoosePostsDialog(
        window.i18next.t('PostsSelector-string-6566-add'),
      );
    } else {
      this.handleConnectPageAndShowChoosePostsDialog(
        window.i18next.t('PostsSelector-string-2155-edit'),
      );
    }
  }

  private handleConnectPageAndShowChoosePostsDialog(
    action: PostsSelectorAction,
  ) {
    connectPage({
      botId: this.node.controller.flow.bot.id,
      urlParams: {
        [ConnectPageField.origin]: ConnectPageOrigin.commentsAddPosts,
        [ConnectPageField.blockId]: this.node.id,
        [ConnectPageField.cardId]: this.card.id,
        [ConnectPageField.actionId]: action,
      },
      onPageConnected: ({ status }) => {
        if (status !== BotStatus.noPage) {
          this.handleShowChoosePostsDialog(action);
        }
      },
      eventData: {
        actionPostfix: `${window.i18next.t('PostsSelector-Template-3148-for')}${
          this.props.pluginType
        }`,
      },
    });
  }

  private handleShowChoosePostsDialog(action: PostsSelectorAction) {
    this.showChoosePostsDialog();
    logFlowPluginEvent(this.props.pluginType, `${action} posts click`, {
      blockId: this.node.id,
      cardId: this.card.id,
    });
  }

  createPostsList() {
    this.postsBox._views.forEach(({ view }) => view.destroy());
    this.postsBox._views.length = 0;
    this.card.config.posts?.forEach((post) => {
      this.createPostView(post);
    });
  }

  createPostView(post: T) {
    const postView = new PostItem({
      node: this.node,
      cardId: this.card.id,
      post: this.getPostData(post),
    });
    // @ts-ignore for addButtonControl compatibility
    postView.config = post;
    this.addButtonControl(postView);
    this.postsBox.addToLayout(postView, { margin: { bottom: 8 } });
  }

  public removePost(index: number) {
    const postView = this.postsBox.views()[index];
    const { config } = this.card;
    this.onChange({
      ...config,
      posts: config.posts?.filter((_, idx) => idx !== index) ?? [],
    });
    this.postsBox.removeView(postView);
    postView.destroy();
    this.node.updateLines();
  }

  addButtonControl(newItemView: HLayout) {
    addButtonControl(
      newItemView,
      this.postsBox,
      this.card.config.posts ?? [],
      this.node,
      this.card,
      undefined,
      undefined,
      undefined,
      (args) => {
        logFlowPluginEvent(this.props.pluginType, 'remove post', {
          blockId: this.node.id,
          cardId: this.card.id,
        });
        if (!this.props.updateCardViaDeprecatedMethod) {
          const { config } = this.card;
          this.onChange({
            ...config,
            posts:
              config.posts?.filter(
                (v) => this.getPostData(v).id !== args.postId,
              ) ?? [],
          });
        }
        this.renderNode();
      },
      (_, start, end) => {
        const { config, id: cardId } = this.card;
        logFlowPluginEvent(this.props.pluginType, 'drag post', {
          cardId,
          start,
          end,
          blockId: this.node.id,
        });
        if (!this.props.updateCardViaDeprecatedMethod) {
          this.onChange({
            ...config,
            posts: swapImmutable(config.posts, start, end),
          });
        }
      },
      this.props.updateCardViaDeprecatedMethod,
    );
  }

  updatePostsList = () => {
    this.createPostsList();
    this.renderNode();
  };

  public showChoosePostsDialog() {
    this.onShowChoosePostsDialog(this.updatePostsList);
  }

  isValid() {
    return (
      this.card?.config.apply_for_all_posts || !!this.card?.config.posts?.length
    );
  }

  destroy() {
    this.connectedPageObservable?.unsubscribe();
    super.destroy();
  }

  abstract getPostData(post: T): PostViewModel;
  abstract onChange(config: EntryPointCommentsAutoreplyConfigCommon<T>): void;
  abstract onApplyRuleTypeChange(type: ApplyRuleType): void;
  abstract onShowChoosePostsDialog(updatePostList: () => void): void;
}
