import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCurrentBotId } from '@utils/Routing';
import {
  EntryPointsQuery,
  EntryPointsQuery_bot_flows,
  EntryPointsQuery_bot_flows_entry_points,
  EntryPointsQueryVariables,
} from './@types/EntryPointsQuery';

export const ENTRY_POINTS_QUERY = gql`
  query EntryPointsQuery($botId: String!) {
    bot(id: $botId) {
      id
      flows {
        id
        title
        entry_points {
          id
          title
          enabled
          entry_point_type
        }
      }
    }
  }
`;

const getEntryPointsGroupedByFlow = (
  flows: EntryPointsQuery_bot_flows[],
): Array<{
  id: string;
  title: string;
  items: EntryPointsQuery_bot_flows_entry_points[];
}> =>
  flows.map((flow) => ({
    id: flow.id,
    title: flow.title,
    items: (flow.entry_points ?? []).map((entryPoint) => ({
      ...entryPoint,
      flowId: flow.id,
    })),
  }));

export const useEntryPointsByFlowsData = () => {
  const botId = useCurrentBotId() ?? '';

  const { data, ...queryState } = useQuery<
    EntryPointsQuery,
    EntryPointsQueryVariables
  >(ENTRY_POINTS_QUERY, {
    variables: { botId },
    skip: !botId,
    notifyOnNetworkStatusChange: true,
  });

  const flowsWithEntryPoints = useMemo(
    () => getEntryPointsGroupedByFlow(data?.bot?.flows ?? []),
    [data],
  );

  return {
    flowsWithEntryPoints,
    ...queryState,
  };
};
