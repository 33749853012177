import { logFlowPluginEvent } from '../utils/Analytics';
import { VLayout } from '../components/Elements/Layouts';
import { SetAttributeView } from './components/SetAttributeView';
import { PluginTitleLayout } from './components/PluginTitleLayout';
import { connectPage } from '@components/ConnectPageDialog';
import { tooltips } from './Menu/menu_view';
import { DropdownFieldView } from './dropdown_field_view';
import { pluginFbAnalyticsSvgTexture } from '../assets/textures';
import { ControlVisibility } from '../FlowBuilderOverlay';
import { FROM_PERMISSION_GRANT } from '../../Plugins/FacebookAnalyticsPlugin/FacebookAnalyticsPluginConst';
import { goToGetPermissions } from '../../../utils/FacebookPages/goToGetPermissions';
import { CreateMenuViewOverlay } from './menu_view_overlay';
import { notEmptyString } from './validation';
import { EventType } from '../../../../@types/globalTypes';
import { pluginWidth, textCardBackgroundColor } from './plugin_consts';

const standardEvents = [
  'Achieve Level',
  'Activate App',
  'Ad Click',
  'Ad Impression',
  'Add Payment Info',
  'Add to Cart',
  'Add to Wishlist',
  'Complete Registration',
  'Complete Tutorial',
  'Contact',
  'Customize Product',
  'Donate',
  'Find Location',
  'Initiate Checkout',
  'Purchase',
  'Rate',
  'Schedule',
  'Search',
  'Spend Credits',
  'Start Trial',
  'Submit Application',
  'Subscribe',
  'Unlock Achievement',
  'View Content',
];

export class FacebookAnalyticsView extends VLayout {
  TEST_NAME = 'FacebookAnalyticsView';
  _node;
  _card;
  _phoneView;
  _botId;

  constructor(card, node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._card = card;
    this._node = node;
    this._flow = this._node.controller.flow;
    this._botId = this._flow.botId;
    if (!this._card.config.eventType) {
      this._card.config.eventType = EventType.standard;
    }
    this._titleLayout = new PluginTitleLayout(
      true,
      window.i18next.t(
        'facebook_analytics-string-4963-send-event-to-fb-standard',
      ),
      pluginFbAnalyticsSvgTexture,
      card,
      tooltips().fb_analytics,
    );
    this.layout(this._titleLayout, {
      margin: { top: 15, left: 10, bottom: 15 },
    });

    this.renderPlugin();
  }

  renderPlugin() {
    this._customEvent = new SetAttributeView(
      this._card,
      {
        width: pluginWidth - 30,
        caption: window.i18next.t(
          'facebook_analytics-string-1163-name-your-custom-event',
        ),
        isValid: () => !!this._card.config.eventId,
      },
      undefined,
      () => {
        let newText = this._customEvent.text().trim();
        if (this._card.config.eventId !== newText) {
          this._card.config.eventId = newText;
          this.updateCard();
          logFlowPluginEvent(this._card.plugin_id, 'set event', {
            blockId: this._node.id,
            cardId: this._card.id,
            value: newText,
          });
        }
        this._customEvent.text(newText);
      },
      undefined,
      {
        emoji: ControlVisibility.hide,
        attributes: ControlVisibility.hide,
      },
    );
    this._customEvent.text(this._card.config.eventId);
    this.dropdownView = new DropdownFieldView({
      width: pluginWidth - 30,
      caption: window.i18next.t(
        'facebook_analytics-string--401-choose-event-id',
      ),
      card: this._card,
      isValid: () => !!this._card.config.eventId,
    });

    this.layout(this._customEvent, {
      margin: { left: 15, bottom: 15 },
      gone: () => this._card.config.eventType !== EventType.custom,
    });
    this.layout(this.dropdownView, {
      margin: { left: 15, bottom: 15 },
      gone: () => this._card.config.eventType !== EventType.standard,
    });

    this._titleLayout.on('pointerdown', (e) => {
      if (!this._card.isEditing) return;
      e.stopPropagation();
    });
    this._titleLayout.on('click', (e) => {
      if (!this._card.isEditing) return;
      e.stopPropagation();
      new CreateMenuViewOverlay({
        onChoose: (item) => {
          if (this._card.config.eventType !== item.id) {
            this._card.config.eventType = item.id;
            this.renderNode();
            this._node.updateCard(this._card);
            logFlowPluginEvent(this._card.plugin_id, 'update type', {
              blockId: this._node.id,
              cardId: this._card.id,
              value: item.id,
            });
          }
        },
        items: [
          {
            title: window.i18next.t('facebook_analytics-string-1377-standard'),
            id: EventType.standard,
          },
          {
            title: window.i18next.t('facebook_analytics-string-2029-custom'),
            id: EventType.custom,
          },
        ],
      }).showOn(e.data.global);
    });
    this.dropdownView.on('pointerdown', (e) => {
      if (!this._card.isEditing) return;
      e.stopPropagation();
    });
    this.dropdownView.on('click', (e) => {
      if (!this._card.isEditing) return;
      e.stopPropagation();
      const items = [];
      standardEvents.forEach((e) => {
        items.push({ title: e });
      });
      new CreateMenuViewOverlay({
        onChoose: (item) => {
          if (this._card.config.eventId !== item.title) {
            this._card.config.eventId = item.title;
            this.updateCard();
            logFlowPluginEvent(this._card.plugin_id, 'set event', {
              blockId: this._node.id,
              cardId: this._card.id,
              value: item.title,
            });
          }
        },
        items,
      }).showOn(e.data.global);
    });
  }

  onBeforeRender() {
    const error = this.validationError();
    const hasErrorWithDescription = error && typeof error !== 'boolean';
    if (this._card.config.eventType === EventType.custom) {
      if (this._customEvent) this._customEvent.text(this._card.config.eventId);
      if (this._titleLayout)
        this._titleLayout.text(
          hasErrorWithDescription
            ? window.i18next.t(
                'facebook_analytics-string-1517-send-event-to-fb-cust',
              )
            : window.i18next.t(
                'facebook_analytics-string--175-send-event-to-fb-custom',
              ),
        );
    } else if (this._card.config.eventType === EventType.standard) {
      if (this._titleLayout)
        this._titleLayout.text(
          hasErrorWithDescription
            ? window.i18next.t(
                'facebook_analytics-string-4963-send-event-to-fb-stand',
              )
            : window.i18next.t(
                'facebook_analytics-string-4963-send-event-to-fb-standard',
              ),
        );
      if (this.dropdownView) {
        if (standardEvents.includes(this._card.config.eventId)) {
          this.dropdownView.text(this._card.config.eventId);
        } else {
          this.dropdownView.text('');
        }
      }
    }
  }

  hasSignificantChangesInConfig() {
    return notEmptyString(this._card.config.eventId);
  }

  validationError() {
    if (this._flow.botStatus.status !== 'connected') {
      return {
        message:
          'Connect your Facebook Business<br/>page to send events.<br/><span style="text-decoration: underline;">Connect Facebook Page</span>',
        onClick: () => connectPage({ botId: this._botId }),
      };
    }

    const fbAnalyticsPermission = this._flow.verifiedPermissions.find(
      (vp) => vp.id === 'facebook_analytics',
    );
    if (!fbAnalyticsPermission?.enabled) {
      return {
        message:
          'Grant Chatfuel permission to log<br/>and send events to Facebook.<br/><span style="text-decoration: underline;">Grant Permission</span>',
        onClick: () =>
          goToGetPermissions({
            permissionGroup: fbAnalyticsPermission.permissions_group,
            interruptedHref: `${window.location.href}?${FROM_PERMISSION_GRANT}`,
          }),
      };
    }
    if (!this._card.config.eventId) {
      return this._card.config.eventType === EventType.standard
        ? window.i18next.t('facebook_analytics-string-1826-select-event-id')
        : 'Give your custom event a name so you<br/>can easily find it later.';
    }

    return this._card.validation_details?.fields?.[0]?.error;
  }

  updateCard() {
    this._node
      .updateCard(this._card, (_, { error, result, fields }) => {
        this._card.is_valid = !error;
        this._card.config = result.config;
        if (!this._card.validation_details) {
          this._card.validation_details = {};
        }
        this._card.validation_details.fields = fields;
      })
      .finally(() => {
        this.renderNode();
      });
  }
}
