import React from 'react';
import {
  ChooseFacebookShopProductsDialog,
  ChooseFacebookShopProductsDialogProps,
} from './ChooseFacebookShopProductsDialog';
import { Modal } from '../../../../../../../services';

export const showChooseFacebookShopProductsDialog = (
  props: ChooseFacebookShopProductsDialogProps,
) => {
  Modal.show(({ close }) => (
    <ChooseFacebookShopProductsDialog
      {...props}
      onDismiss={() => {
        props.onDismiss?.();
        close();
      }}
      onSubmit={(...args) => {
        close();
        return props.onSubmit(...args);
      }}
      onRemovedProduct={props.onRemovedProduct}
    />
  ));
};
