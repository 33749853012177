import { QUANT_ITEMS_WITH_IMMEDIATELY } from '@utils/DateTime/quant';
import {
  getFullCounterStats,
  isFlowStatsEnabled,
} from '../components/BlockStatsView/utils';
import { logFlowPluginEvent } from '../../utils/Analytics';
import { tooltipScaled } from '../helpers/TooltipHelpers';
import { MainLayout } from '../../components/Elements/Layouts';
import { DelayInput } from '../kit/DelayInput';
import { Node } from '../../Node';
import { Card } from '../../types';
import { createLineMenu } from '../Menu/createLineMenu';
import { BlockView } from '../block_view';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { HTMLText } from '../../components/Elements/Shapes';
import { pluginWidth, textCardBackgroundColor } from '../plugin_consts';
import { LineStartView } from '../components/Line/LineStartView';
import { HEXColors } from '@ui/_common/colors';

export const CONNECT_TO_ITSELF_ERROR_MESSAGE =
  "You can't connect Delay card to itself.";

interface DelayPluginCardConfig {
  delay_ms: number | undefined;
  block_id: string | undefined;
}

interface DelayPluginCard extends Card {
  config: DelayPluginCardConfig;
}

export class DelayPlugin extends MainLayout {
  TEST_NAME = 'DelayPlugin';

  private _node: Node;
  private _card: DelayPluginCard;
  private _config: DelayPluginCardConfig;
  private _lineStartView: LineStartView;
  private itemStatView?: HTMLText;

  constructor(card: DelayPluginCard, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 10,
      },
    });
    this._card = card;
    this._node = node;
    this._config = card.config;

    const stats = getFullCounterStats(`${this._card.id}_delay`, node.block.id);

    this.addToLayout(
      new DelayInput({
        quantItems: QUANT_ITEMS_WITH_IMMEDIATELY(),
        label: window.i18next.t('DelayPlugin-string-1885-resume-after'),
        initialValue: card.config.delay_ms || 0,
        getPlaceholderTitle: (value, quantId) =>
          quantId === DelayInput.QUANT_IDS.instantly
            ? window.i18next.t('DelayPlugin-string--185-resume')
            : value,
        onChange: (value) => {
          // eslint-disable-next-line no-param-reassign
          card.config.delay_ms = value;
          node.updateCard(card);
          logFlowPluginEvent(PluginType.delay, 'Delay change', {
            blockId: node.id,
            cardId: card?.id,
            delay_ms: value,
          });
        },
        editable: () => !!card.isEditing,
      }),
      {
        margin: { top: 15, left: 15, bottom: 15 },
      },
    );

    this._lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 20,
        mandatory: true,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: (blockView: BlockView) => {
          // eslint-disable-next-line no-param-reassign
          card.config.block_id = blockView.id();
          node.updateCard(card);
          this.renderNode();
        },
        onRemoved: () => {
          // eslint-disable-next-line no-param-reassign
          card.config.block_id = undefined;
          node.updateCard(card);
          this.renderNode();
        },
      },
      card.id,
    );
    this.addToLayout(this._lineStartView, {
      align: 'end',
      verticalAlign: 'center',
      margin: { left: 8 },
    });

    if (isFlowStatsEnabled() && stats) {
      const {
        counter: { uniqStats, notUniqStats },
        uniqStats: blockUniqStats,
      } = stats;

      const delivered = uniqStats?.delivered;
      const blockDelivered = blockUniqStats?.delivered;
      const totalDelivered = notUniqStats?.delivered;

      const text = `${
        delivered && blockDelivered
          ? Math.round((delivered / blockDelivered) * 100)
          : 0
      }%`;

      const statsLayout = new MainLayout({
        height: 65,
        width: pluginWidth - 20,
      });

      this.itemStatView = new HTMLText({
        text,
        fontSize: 15,
        fill: HEXColors.black,
        align: 'right',
        singleLine: true,
        trustedHtml: true,
        width: pluginWidth - 15,
      });

      statsLayout.addToLayout(this.itemStatView, {
        gone: () => card.isEditing,
        verticalAlign: 'center',
      });

      tooltipScaled({
        view: statsLayout,
        text: `${window.i18next.t(
          'DelayPlugin-Template--846-total-resumed',
        )}${totalDelivered}<br/>Unique Users: ${delivered}`,
      });

      this.addToLayout(statsLayout);
    }
  }

  onBeforeRender() {
    if (this._config.block_id)
      this._node.addOutLink(
        this._config.block_id,
        this._lineStartView._lineView,
      );
  }

  hasSignificantChangesInConfig() {
    return this._card.config.delay_ms !== undefined;
  }
}
