import { HLayout, MainLayout } from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { CheckboxIcon } from './CheckboxIcon';
import { inactiveStrokeColor, pluginWidth } from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { Cursor } from '../../../components/Elements/Layouts/types';

interface CheckboxProps {
  text?: string;
  textTrue?: string;
  textFalse?: string;
  value?: boolean;
  width?: number | 'auto';
  multiline?: boolean;
  onChange?(value: boolean): void;
  getTitle?(value: boolean): string;
}

export class Checkbox extends HLayout {
  _value: boolean;

  _text?: string;

  _textTrue?: string;

  _textFalse?: string;

  _checkboxTitle: HTMLText;

  get checked(): boolean {
    return this._value;
  }

  private getTitle?(value: boolean): string;

  constructor({
    text,
    textTrue,
    textFalse,
    value,
    width,
    onChange,
    getTitle,
    multiline,
  }: CheckboxProps) {
    const checkboxSize = 16;
    const checkboxFontSize = 15;
    const p = {
      width: width === 'auto' ? undefined : width || pluginWidth,
      height: multiline ? undefined : checkboxSize,
      fontSize: checkboxFontSize,
      cursor: {
        in: 'pointer' as Cursor,
      },
    };
    super(p);

    this.TEST_NAME = 'Checkbox';
    this._value = !!value;
    this._text = text;
    this._textTrue = textTrue;
    this._textFalse = textFalse;
    this.getTitle = getTitle;

    this._checkboxTitle = new HTMLText({
      text: text ?? this.getTitle?.(this._value),
      height: multiline ? undefined : checkboxSize,
      width: width === 'auto' ? undefined : (width || pluginWidth) - 30,
      fontSize: checkboxFontSize,
      verticalAlign: 'top',
    });

    this.layout(
      new MainLayout({
        width: checkboxSize,
        height: multiline ? undefined : checkboxSize,
        background: {
          fill: HEXColors.white,
          cornerRadius: 4,
          strokeWidth: 1,
          stroke: inactiveStrokeColor,
          onhover: {
            fill: HEXColors.greyLight20,
            strokeWidth: 1,
            stroke: inactiveStrokeColor,
          },
        },
      }).layout(
        new CheckboxIcon({
          checked: () => this.checked,
        }),
      ),
    ).layout(this._checkboxTitle, { margin: { left: 10, top: -2 } });

    this.on('pointerdown', (e: any) => {
      e.stopPropagation();
    });
    this.on('click', (e: any) => {
      e.stopPropagation();
      this._value = !this._value;
      this.renderNode();
      if (onChange) {
        onChange(this.checked);
      }
    });
  }

  onBeforeRender() {
    if (this.getTitle) {
      this._checkboxTitle.text(this.getTitle(this._value));
    } else {
      this._checkboxTitle.text(
        this.checked
          ? this._textTrue
            ? this._textTrue
            : this._text
          : this._textFalse
          ? this._textFalse
          : this._text,
      );
    }
  }
}
