import { getFlowControllerStrict } from '@components/FlowBuilder/PixiFieldRepository';
import { DeepLinksMode } from '@pages/DeepLinks/types';
import { BotTabs, getTabLink, globalHistory } from '@utils/Routing';

export const goToHomeTabAndConnectWhatsapp = () => {
  globalHistory.push(
    getTabLink(BotTabs.home, getFlowControllerStrict().flow.botId, {
      dlMode: DeepLinksMode.connectWhatsapp,
    }),
  );
};
