import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { PostsSelector } from '../../EntryPointCommentsAutoreply/components/PostsSelector';

export const validatePostSelector = <T extends { id: string }>(
  postSelector: PostsSelector<T>,
  logger: PluginLogger,
  message: string,
) =>
  postSelector.isValid()
    ? undefined
    : {
        message,
        isBlockError: true,
        onClick: () => {
          postSelector.showChoosePostsDialog();

          logger.log('Add post tooltip click');
        },
      };
