import i18next from 'i18next';
import { VLayout } from '@components/FlowBuilder/components/Elements/Layouts';
import { pluginWidth } from '../../plugin_consts';
import { HTMLText } from '@components/FlowBuilder/components/Elements/Shapes';
import { HEXColors } from '@ui/_common/colors';
import { ButtonView, ButtonProps } from '../../button_view';

export interface WhatsappTemplatePluginDisconnectedViewProps {
  onButtonClick: ButtonProps['onClick'];
}

export class WhatsappTemplatePluginDisconnectedView extends VLayout {
  public readonly TEST_NAME = 'WhatsappTemplatePluginDisconnectedView';
  private readonly _text: HTMLText;
  private readonly _button: ButtonView;

  constructor({ onButtonClick }: WhatsappTemplatePluginDisconnectedViewProps) {
    const width = pluginWidth - 24;

    super({ width });

    this._text = new HTMLText({
      width,
      fontSize: 15,
      fontStyle: 'semibold',
      fontColor: HEXColors.cobalt,
      text: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.wabaDisconnectedNote',
      ),
      trustedHtml: true,
    });

    this._button = new ButtonView({
      textColor: HEXColors.white,
      bgColor: HEXColors.accent1Normal,
      width,
      title: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.connect',
      ),
      onClick: onButtonClick,
    });

    this.addToLayout(this._text, { margin: { bottom: 6 } });
    this.addToLayout(this._button);
  }
}
