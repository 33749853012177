import { HEXColors } from '@ui/_common/colors';
import { humanizeNumber } from '@utils/humanizeNumber';
import { Subscription } from 'apollo-client/util/Observable';
import { propEq } from 'ramda';
import i18next from 'i18next';
import client from '../../../../../../common/services/ApolloService';
import { MainLayout, VLayout } from '../../../../components/Elements/Layouts';
import { HTMLText } from '../../../../components/Elements/Shapes';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../../PixiFieldRepository';
import { BLOCK_SUBTYPES } from '../../../../consts';
import { removeTooltip, tooltipScaled } from '../../../helpers/TooltipHelpers';
import {
  ShopifyRemindersRevenueQuery,
  ShopifyRemindersRevenueQuery_shopifyRemindersRevenue_blocks_revenue,
  ShopifyRemindersRevenueQueryVariables,
} from '../@types/ShopifyRemindersRevenueQuery';
import { ItemWidth } from '../BlockStatsView';
import { SHOPIFY_REMINDERS_REVENUE_QUERY } from '../queries';

interface RevenueStatItemProps {
  blockId: string;
  blockSubtype: string;
}

const prepareAmount = (
  {
    currency,
    amount,
  }: ShopifyRemindersRevenueQuery_shopifyRemindersRevenue_blocks_revenue,
  short?: boolean,
) => {
  const value = parseInt(amount, 10);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })
    .format(0) // placeholder for replace (notation don't support in Safari)
    .replace('0.00', short ? humanizeNumber(value) : value.toFixed(2));
};

const getTooltipDescription = (blockSubtype: string) => {
  if (blockSubtype === BLOCK_SUBTYPES.shopify_discount) {
    return i18next.t('shopify.revenueTooltip.discount');
  }
  if (blockSubtype === BLOCK_SUBTYPES.reminder) {
    return i18next.t('shopify.revenueTooltip.abandonedCart');
  }
  return null;
};

export class RevenueStatItem extends MainLayout {
  private querySubscription: Subscription | undefined;
  private readonly itemBox: VLayout;

  constructor({ blockId, blockSubtype }: RevenueStatItemProps) {
    super({
      width: ItemWidth.withRevenue,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    this.itemBox = new VLayout({
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    this.itemBox.addToLayout(
      new HTMLText({
        text: i18next.t('shopify.revenueTooltip.title'),
        fontSize: 12,
        fill: HEXColors.greyDark,
      }),
    );

    const generalValueView = new HTMLText({
      text: '0',
      fontSize: 15,
      fill: HEXColors.black,
    });

    this.itemBox.addToLayout(generalValueView);

    this.addToLayout(this.itemBox);

    if (!getPixiFieldStrict().isViewOnly()) {
      const tooltipDescription = getTooltipDescription(blockSubtype);
      if (tooltipDescription) {
        tooltipScaled({
          view: this.itemBox,
          overTimeout: 200,
          text: tooltipDescription,
        });
      }
      this.querySubscription = client
        .watchQuery<
          ShopifyRemindersRevenueQuery,
          ShopifyRemindersRevenueQueryVariables
        >({
          query: SHOPIFY_REMINDERS_REVENUE_QUERY,
          variables: {
            flowId: getFlowControllerStrict().flow.id,
          },
        })
        .subscribe(({ data }) => {
          const block = data?.shopifyRemindersRevenue.blocks.find(
            propEq('id', blockId),
          );

          if (!block) {
            return;
          }

          const generalRevenue = block.revenue[0];

          generalValueView.text(
            generalRevenue ? prepareAmount(generalRevenue, true) : '0',
          );

          removeTooltip(this.itemBox);
          if (block.revenue.length) {
            tooltipScaled({
              view: this.itemBox,
              overTimeout: 200,
              text: `${i18next.t(
                'shopify.revenueTooltip.title',
              )}${block.revenue
                .map((revenue) => `<br>${prepareAmount(revenue)}`)
                .join('')}${
                tooltipDescription ? `<br />${tooltipDescription}` : ''
              }`,
            });
          }
        });
    }
  }

  destroy() {
    removeTooltip(this.itemBox);
    this.querySubscription?.unsubscribe();
    super.destroy();
  }
}
