import {
  commentsAutoReplyLineMenu,
  createLineMenu,
} from '../Menu/createLineMenu';
import { BlockContextType } from '@globals';
import { Block, PluginType } from '../../types';
import { LineStartViewProps } from '../components/Line/LineStartView';

export const getLineItemsProps = (
  block: Block,
): Partial<LineStartViewProps> => {
  if (block.context_type === BlockContextType.comments) {
    return {
      items: commentsAutoReplyLineMenu,
      searchable: false,
      menuStyle: { minWidth: 0 },
    };
  }

  if (
    block.cards?.[0]?.plugin_id === PluginType.whatsapp_bot_link ||
    block.cards?.[0]?.plugin_id === PluginType.whatsapp_widget_entry_point ||
    block.cards?.[0]?.plugin_id === PluginType.whatsapp_popup_entry_point ||
    block.cards?.[0]?.plugin_id === PluginType.whatsapp_direct_entry_point
  ) {
    return {
      mandatory: true,
      items: createLineMenu({
        isShowConnectToExistingBlock: true,
      }),
    };
  }

  return {
    items: createLineMenu({
      isShowConnectToExistingBlock: true,
    }),
  };
};
