/* eslint-disable no-param-reassign */
import { equals, complement } from 'ramda';
import {
  BackgroundProps,
  defaultObj,
  MainLayout,
} from '../components/Elements/Layouts';
import { findParent, resByFunc } from './utils';
import { Viewport } from 'pixi-viewport';
import i18next from 'i18next';
import debounce from 'lodash-es/debounce';
import { AggregatedFlowQuery_bot_archiveBlocks as BlockTitle } from '@utils/Data/Flow/Aggregated/@types/AggregatedFlowQuery';
import { getFlowControllerStrict } from '@components/FlowBuilder/PixiFieldRepository';
import { MAX_SCALE } from '../PixiField';
import {
  overlayEventEmitter,
  OverlayComponentEvent,
  FlowBuilderOverlayEvent,
  OverlayType,
} from '../FlowBuilderOverlay';
import { HTMLText } from '../components/Elements/Shapes';
import { inactiveStrokeColor } from './plugin_consts';
import { getFlowPlatform } from '@components/FlowBuilder/utils/getFlowPlatform';
import { isOptimisticBlockId } from '../../Aside/Mutations/BlockMutations';
import { HEXColors } from '@ui/_common/colors';
import { assert } from '@utils/Assert';
import { getBlocksTitlesObservable } from '@utils/Data/Blocks/getBlocksTitlesObservable';
import { Subscription } from 'rxjs';
import { AllBlocksTitlesQuery_bot_archiveBlocks } from '@utils/Data/Blocks/@types/AllBlocksTitlesQuery';
import { calculateAutomateEnabled } from '@utils/Data/Admin/Automate/utils';

const ATTRIBUTE_STYLE =
  'display:inline-block;box-sizing: border-box;margin-right:4px;margin-bottom:4px;border-radius:3px;min-width:16px;' +
  `max-width:196px;height:28px;line-height:27px;padding:0 8px;color:${HEXColors.black};font-weight:normal;` +
  `background-color:${HEXColors.grey};text-overflow:ellipsis;overflow:hidden;white-space:nowrap;text-align:center;`;

const COUNTER_STYLE =
  'display:inline-block;box-sizing: border-box;margin-right:4px;margin-bottom:4px;' +
  `height:28px;line-height:27px;padding:0 8px;color:${HEXColors.black};font-weight:normal;` +
  'overflow:hidden;white-space:nowrap;text-align:right;position:absolute;right:0;';

type OnChange = (ids: Array<string>) => void;
type OnStartEditing = () => void;
type OnDoneEditing = () => void;

interface BlocksSelectorViewProps {
  onBlockAdd?: (block: BlockTitle) => void;
  blockIds: Array<string>;
  width: number;
  handleOutsideClick: boolean;
  background: BackgroundProps;
  editable?: boolean;
  caption?: string;
  captionFill?: HEXColors;
  fill?: HEXColors;
}

export class BlocksSelectorView extends MainLayout {
  TEST_NAME = 'BlocksSelectorView';

  private _props: BlocksSelectorViewProps;
  private _textNode;
  private _onDoneEditing?: OnDoneEditing;
  private _onStartEditing?: OnStartEditing;
  private _onChange?: OnChange;
  private _overlayShown: boolean = false;
  private _captionSet: boolean = false;
  private _viewport?: Viewport;
  private _zoomed: boolean = false;
  private _overlayValue: Array<string> = [];
  private _lastOverlayValue: Array<string> = [];
  private readonly _blocksTitlesMap: Record<
    string,
    AllBlocksTitlesQuery_bot_archiveBlocks
  > = {};
  private _blocksTitles: AllBlocksTitlesQuery_bot_archiveBlocks[] = [];

  private overlayOnChangeUnsubscribe?: () => void;
  private overlayOnAddUnsubscribe?: () => void;
  private overlayOnKeyDownUnsubscribe?: () => void;
  private blocksTitlesSubscription: Subscription;

  constructor(
    props: BlocksSelectorViewProps,
    onChange?: OnChange,
    onStartEditing?: OnStartEditing,
    onDoneEditing?: OnDoneEditing,
  ) {
    const { user, bot } = getFlowControllerStrict().flow;
    const isAutomateEnabled = calculateAutomateEnabled({
      bot,
      adminFeatures: user.features,
    });
    props = defaultObj(
      {
        blockIds: [],
        blocksTitles: [],
        blocksTitlesMap: {},
        editable: true,
        cursor: (v: this) => {
          const isEditable = v._props && resByFunc(v._props.editable, v);
          return isEditable
            ? {
                in: 'text',
              }
            : undefined;
        },
        resolution: window.devicePixelRatio,
        height: 36,
        fontSize: 15,
        caption: isAutomateEnabled
          ? i18next.t(
              'modernComponents.FlowBuilder.views.blocksSelectorView.addBlockOrFlow',
            )
          : i18next.t(
              'modernComponents.FlowBuilder.views.blocksSelectorView.addFlow',
            ),
        captionFill: inactiveStrokeColor,
      },
      props,
    );
    super(props);

    this._props = props;
    this._onStartEditing = onStartEditing;
    this._onDoneEditing = onDoneEditing;
    this._onChange = onChange;
    this._textNode = new HTMLText({
      ...props,
      singleLine: true,
    });
    this._overlayValue = props.blockIds;

    this._textNode.interactive(true);
    this.layout(this._textNode, { width: 0 });
    this.on('pointerdown', (e) => {
      if (resByFunc(this._props.editable)) {
        e.stopPropagation();
      }
    });
    this.on('click', (e) => {
      if (resByFunc(this._props.editable)) {
        e.stopPropagation();
        this.startEditing();
      }
    });
    this.setTextNodeValue();
    this.setCaption();

    this.blocksTitlesSubscription = getBlocksTitlesObservable(
      getFlowControllerStrict().flow.botId,
    ).subscribe(({ blocks }) => {
      this._blocksTitles = blocks;
      blocks.forEach((block) => {
        this._blocksTitlesMap[block.id] = block;
      });
      this.setTextNodeValue();
      this.setCaption();
      this.renderNode();
    });
  }

  private getViewport() {
    this._viewport =
      this._viewport || findParent(this._textNode.shape(), Viewport);

    assert(this._viewport, { msg: 'Viewport was not found' });

    return this._viewport;
  }

  private setCaption() {
    if (!this._overlayShown) {
      if (this._overlayValue.length === 0) {
        this._textNode.text(
          `<div style="padding: 7px 12px 0;">${this._props.caption}</div>`,
        );
        this._textNode.fill(this._props.captionFill);
        this._captionSet = true;
      } else {
        this._textNode.fill(this._props.fill);
        this._captionSet = false;
      }
    } else {
      this._textNode.fill(this._props.fill);
      if (this._captionSet) {
        this._textNode.verticalAlign('top');
        this._textNode.text('');
      }
      this._captionSet = false;
    }
  }

  public start() {
    this.on('pointerdown', () => {
      this.startEditing();
    });
    return this;
  }

  private startEditing() {
    if (!this._overlayShown) {
      setTimeout(() => {
        this.showOverlay();
      });
    }
  }

  private showOverlay() {
    const group = this;
    const textNode = this._textNode;
    this._overlayShown = true;
    const onStartEditing = this._onStartEditing;
    const viewport = this.getViewport();
    if (typeof onStartEditing !== 'undefined') {
      onStartEditing();
    }
    this.setCaption();

    overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
      overlayType: OverlayType.blocksSuggest,
      getOverlayOptions: () => ({
        initialValue: this._overlayValue,
        blocksTitles: this._blocksTitles,
        platform: getFlowPlatform(),
      }),
    });

    this.overlayOnChangeUnsubscribe = overlayEventEmitter.on(
      OverlayComponentEvent.change,
      ({ value }) => {
        const clearValue = value.filter(complement(isOptimisticBlockId));
        if (!equals(clearValue, this._overlayValue)) {
          this._overlayValue = clearValue;
          this.handleChange(this._overlayValue);
        }
      },
    );

    this.overlayOnAddUnsubscribe = overlayEventEmitter.on(
      OverlayComponentEvent.add,
      (block: BlockTitle) => {
        this._props.onBlockAdd?.(block);
      },
    );

    this.overlayOnKeyDownUnsubscribe = overlayEventEmitter.on(
      OverlayComponentEvent.keydown,
      (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          this.setTextNodeValue();
          this.handleChange(this._overlayValue);
          this.removeOverlay();
          return;
        }
        if (event.key === 'Escape') {
          this.removeOverlay();
        }
      },
    );

    viewport?.on('zoomed', this.zoomedHandler);
    viewport?.on('zoomed-end', this.zoomEndHandler);

    setTimeout(() => {
      this.setPosition();
      if (textNode.layoutProperties) {
        textNode.layoutProperties.visible = false;
      }
      group.renderNode();
      this.addClickHandler();
    });
  }

  private addClickHandler() {
    window.addEventListener('mousedown', this.handleOutsideClick);
  }

  private handleChange(newValue: Array<string>) {
    const { _onChange } = this;
    if (typeof _onChange !== 'undefined') {
      _onChange(newValue);
    }
  }

  private handleOutsideClick = () => {
    this.setTextNodeValue();
    this.removeOverlay();
  };

  private setPosition = () => {
    const { x, y } = this._textNode.globalPosition(this._viewport?.scale.x);
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
      position: {
        x,
        y,
        width: this._textNode.width(),
        scale: this._viewport?.scale.x,
      },
    });
  };

  private zoomEndHandler = debounce(() => {
    if (this._zoomed && this._overlayShown) {
      this.setPosition();
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.show);
      if (this._textNode.layoutProperties) {
        this._textNode.layoutProperties.visible = false;
      }
      this.renderNode();
      this._zoomed = false;
    }
  }, 200);

  private zoomedHandler = () => {
    const { scale } = this.getViewport();
    if (!this._zoomed && this._overlayShown && scale.x < MAX_SCALE) {
      const { _textNode, _overlayValue } = this;
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.hide);
      if (_overlayValue.join('') !== this._lastOverlayValue.join('')) {
        this._lastOverlayValue = _overlayValue;
        this.setTextNodeValue();
        this.setCaption();
      }
      if (_textNode.layoutProperties) {
        _textNode.layoutProperties.visible = true;
      }
      this.renderNode();
      this._zoomed = true;
      this.zoomEndHandler();
    }
  };

  private removeOverlay() {
    const { _textNode, _onDoneEditing } = this;
    const viewport = this.getViewport();
    this.overlayOnChangeUnsubscribe?.();
    this.overlayOnKeyDownUnsubscribe?.();
    this.overlayOnAddUnsubscribe?.();
    this.zoomEndHandler.cancel();
    this._zoomed = false;
    window.removeEventListener('mousedown', this.handleOutsideClick);
    viewport.off('zoomed', this.zoomedHandler);
    viewport.off('zoomed-end', this.zoomEndHandler);
    this._overlayShown = false;
    this.setCaption();
    if (_textNode.layoutProperties) {
      _textNode.layoutProperties.visible = true;
    }
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
    this.renderNode();
    if (typeof _onDoneEditing !== 'undefined') {
      _onDoneEditing();
    }
  }

  public isEditing() {
    return this._overlayShown;
  }

  private setTextNodeValue() {
    const chosenBlocks = this._overlayValue
      .map((blockId) => this._blocksTitlesMap[blockId]?.title || '')
      .filter(Boolean);

    if (chosenBlocks.length === 0) {
      this._textNode.text('');
      return;
    }

    if (chosenBlocks.length > 1) {
      this._textNode.text(
        `<div style="padding:4px;position:relative;"><div style="${ATTRIBUTE_STYLE}">${
          chosenBlocks[0]
        }</div><div style="${COUNTER_STYLE}"> +${
          chosenBlocks.length - 1
        }</div></div>`,
      );
      return;
    }

    this._textNode.text(
      `<div style="padding:4px;"><div style="${ATTRIBUTE_STYLE}">${chosenBlocks[0]}</div></div>`,
    );
  }

  destroy() {
    this.blocksTitlesSubscription?.unsubscribe();
    super.destroy();
  }
}
