import { Texture } from 'pixi.js-legacy';
import { HEXColors } from '@ui/_common/colors';
import { TooltipConfig, tooltipScaled } from '../helpers/TooltipHelpers';
import { HLayout, LayoutProps } from '../../components/Elements/Layouts';
import { DownArrow, HTMLText } from '../../components/Elements/Shapes';
import { Card } from '../../types';
import { pluginWidth, textCardBackgroundColor } from '../plugin_consts';
import { CircleIcon } from './CircleIcon';

export class PluginTitleLayout extends HLayout {
  TEST_NAME = 'PluginTitleLayout';
  _titleView: HTMLText;

  constructor(
    withOptions: boolean,
    title: string,
    img: Texture,
    card: Card,
    tooltipDescription?: TooltipConfig,
    layoutProps?: LayoutProps,
  ) {
    super({
      width: pluginWidth - 20,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 4,
        onhover: {
          fill: () => HEXColors.greyLight11,
        },
      },
      ...layoutProps,
    });
    this._titleView = new HTMLText({
      text: title,
      fontSize: 15,
      fontStyle: 'semibold',
      width: pluginWidth - 90,
      verticalAlign: 'center',
    });

    if (img) {
      this.layout(
        new CircleIcon({
          icon: img,
        }),
        {
          margin: { left: 5, top: 3 },
        },
      ).layout(this._titleView, { margin: { left: 10, top: 5, bottom: 5 } });
    }

    if (withOptions) {
      this.layout(
        new DownArrow({ width: 9, height: 7, fill: HEXColors.black }),
        {
          margin: { top: 13, left: 5 },
          gone: () => card != null && !card.isEditing,
        },
      );
    }
    if (tooltipDescription) {
      tooltipScaled({
        view: this,
        text: tooltipDescription.text,
        url: tooltipDescription.url,
      });
    }
  }

  text(t: string): void {
    this._titleView.text(t);
  }
}
