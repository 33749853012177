import { EventEmitter } from '../../../utils/EventEmitter';

export enum FlowBuilderEvent {
  blockMenuOpened = 'blockMenuOpened',
  blockMenuClosed = 'blockMenuClosed',
  pluginAdded = 'pluginAdded',
  pluginRemoved = 'pluginRemoved',
  pluginAddFailed = 'pluginAddFailed',
  textPluginUpdated = 'textPluginUpdated',
  rightClickMenuShown = 'rightClickMenuShown',
  rightClickMenuClosed = 'rightClickMenuClosed',
  rightClickMenuBlockAdded = 'rightClickMenuBlockAdded',
  nodeConnected = 'nodeConnected',
  lineViewMenuOpened = 'lineViewMenuOpened',
  wizardButtonToggled = 'wizardButtonToggled',
  blockAdded = 'pluginAdded',
  blockRemoved = 'pluginRemoved',
}

export const flowBuilderEventEmitter = new EventEmitter();
