import gql from 'graphql-tag';

export const ZAPIER_TEMPLATES_QUERY = gql`
  query ZapierTemplatesQuery($type: ZapierTemplateSetType!) {
    zapierTemplates(type: $type) {
      id
      title
      description
      description_plain
      description_raw
      status
      create_url
      slug
      steps {
        id: title
        description
        url
        image
        hex_color
        slug
      }
    }
  }
`;
