import { HEXColors } from '@ui/_common/colors';
import { Margin } from './utility_classes';
import { BLOCK_SUBTYPES } from '../consts';
import {
  inboundLinksTexture,
  entryPointsIconTextures,
  blockBroadcastFlowRootSvgTexture,
  blockDelaySvgTexture,
  blockActionSvgTexture,
  blockConditionSvgTexture,
  blockSendMsgSvgTexture,
  blockRedirectSvgTexture,
  blockSendDataSvgTexture,
  blockImportContentSvgTexture,
  blockReminderSvgTexture,
  blockOrderReceiptSvgTexture,
  pluginCalendlyEventConfirmationSvgTexture,
  pluginCalendlyEventReminderSvgTexture,
  pluginScheduleCalendlyEventSvgTexture,
  pluginShopifyDiscountSvgTexture,
  blockPostPurchaseSvgTexture,
  shopifyBackInStockOptinConfirmationTexture,
  shopifyBackInStockMessageTexture,
  shopifyBackInStockOTNTexture,
  pluginOpenAiSvgTexture,
  pluginCustomAiSvgTexture,
} from '../assets/textures';
import { PluginType } from '../StatefulPlugin/types';

export const ALERT_VIEW_SIZE = 24;
export const ALERT_VIEW_MARGIN = new Margin({ left: 8, right: 16 });

export const HEADER_HEIGHT = 52;
export const ENTRY_POINT_HEADER_MARGIN = new Margin({
  left: 16,
  top: 8,
  bottom: 8,
  right: 16,
});
export const HEADER_MARGIN = new Margin({
  top: 14,
  right: 16,
  left: 20,
  bottom: 8,
});

export const ICON_SIZE = 24;
export const ENTRY_POINT_ICON_MARGIN = new Margin({ right: 8, top: 6 });
export const ICON_MARGIN = new Margin({ top: 6, right: 10 });

export const ENTRY_POINT_TEXT_VIEW_LAYOUT_MARGIN = new Margin({ right: 8 });
export const TEXT_VIEW_LAYOUT_MARGIN = new Margin({ right: 1 });

export const ENTRY_POINT_TEXT_VIEW_MARGIN = new Margin({ left: 4 });
export const TEXT_VIEW_MARGIN = new Margin({ left: 6 });

export const ACTIVENESS_LABEL_WIDTH = 46;
export const ACTIVENESS_MARGIN = new Margin({ right: 6, top: 11 });

export const TOGGLE_WIDTH = 32;
export const TOGGLE_HEIGHT = 18;
const TOGGLE_MARGIN_SPACE =
  (HEADER_HEIGHT - TOGGLE_HEIGHT - ENTRY_POINT_HEADER_MARGIN.y) / 2;
export const TOGGLE_MARGIN = new Margin({
  top: TOGGLE_MARGIN_SPACE,
  bottom: TOGGLE_MARGIN_SPACE,
});

export const ICON_BY_BLOCK_SUBTYPE = {
  [BLOCK_SUBTYPES.flow_root]: inboundLinksTexture,
  [BLOCK_SUBTYPES.broadcast_flow_root]: blockBroadcastFlowRootSvgTexture,
  [BLOCK_SUBTYPES.action]: blockActionSvgTexture,
  [BLOCK_SUBTYPES.calendly]: pluginScheduleCalendlyEventSvgTexture,
  [BLOCK_SUBTYPES.condition]: blockConditionSvgTexture,
  [BLOCK_SUBTYPES.split_traffic]: blockConditionSvgTexture,
  [BLOCK_SUBTYPES.redirect]: blockRedirectSvgTexture,
  [BLOCK_SUBTYPES.delay]: blockDelaySvgTexture,
  [BLOCK_SUBTYPES.send_message]: blockSendMsgSvgTexture,
  [BLOCK_SUBTYPES.comments_autoreply_content]:
    entryPointsIconTextures.commentsAutoreply.enabled,
  [BLOCK_SUBTYPES.instagram_comments_autoreply_content]:
    entryPointsIconTextures.instagramCommentsAutoreply.enabled,
  [BLOCK_SUBTYPES.send_data]: blockSendDataSvgTexture,
  [BLOCK_SUBTYPES.import_content]: blockImportContentSvgTexture,
  [BLOCK_SUBTYPES.reminder]: blockReminderSvgTexture,
  [BLOCK_SUBTYPES.visitedProductsReminder]: blockReminderSvgTexture,
  [BLOCK_SUBTYPES.receipt]: blockOrderReceiptSvgTexture,
  [BLOCK_SUBTYPES.calendly_confirmation]:
    pluginCalendlyEventConfirmationSvgTexture,
  [BLOCK_SUBTYPES.calendly_reminder]: pluginCalendlyEventReminderSvgTexture,
  [BLOCK_SUBTYPES.shopify_discount]: pluginShopifyDiscountSvgTexture,
  [BLOCK_SUBTYPES.postPurchase]: blockPostPurchaseSvgTexture,
  [BLOCK_SUBTYPES.back_in_stock_optin_confirmation]:
    shopifyBackInStockOptinConfirmationTexture,
  [BLOCK_SUBTYPES.back_in_stock_message]: shopifyBackInStockMessageTexture,
  [BLOCK_SUBTYPES.back_in_stock_otn]: shopifyBackInStockOTNTexture,
  [BLOCK_SUBTYPES.open_ai]: pluginOpenAiSvgTexture,
  [BLOCK_SUBTYPES.ai_custom]: pluginCustomAiSvgTexture,
};

export const ICONS_BY_ENTRY_POINT_TYPE = {
  [PluginType.instagram_persistent_menu_entry_point]:
    entryPointsIconTextures.instagramPersistentMenu,
  [PluginType.persistent_menu_entry_point]:
    entryPointsIconTextures.persistentMenu,
  [PluginType.ref_link_entry_point]: entryPointsIconTextures.refLink,
  [PluginType.instagram_bot_link_entry_point]:
    entryPointsIconTextures.instagramBotLink,
  [PluginType.fb_page_entry_point]: entryPointsIconTextures.facebookPage,
  [PluginType.customer_chat_entry_point]: entryPointsIconTextures.customerChat,
  [PluginType.send_to_messenger_entry_point]:
    entryPointsIconTextures.sendToMessenger,
  [PluginType.comments_autoreply_entry_point]:
    entryPointsIconTextures.commentsAutoreply,
  [PluginType.ad_comments_autoreply_entry_point]:
    entryPointsIconTextures.adCommentsAutoreply,
  [PluginType.ads_manager_ctm_entry_point]:
    entryPointsIconTextures.facebookAdsClickToMessenger,
  [PluginType.ads_manager_ctm_json_entry_point]:
    entryPointsIconTextures.facebookAdsClickToMessengerJson,
  [PluginType.ads_manager_sm_entry_point]:
    entryPointsIconTextures.facebookAdsSponsoredMessage,
  [PluginType.facebook_shops_entry_point]:
    entryPointsIconTextures.facebookShops,
  [PluginType.external_integration_entry_point]: entryPointsIconTextures.zapier,
  [PluginType.popup_entry_point]: entryPointsIconTextures.popup,
  [PluginType.shopify_event_entry_point]: entryPointsIconTextures.shopifyEvent,
  [PluginType.shopify_back_in_stock]:
    entryPointsIconTextures.shopifyBackInStock,
  [PluginType.instagram_direct_entry_point]:
    entryPointsIconTextures.instagramDirect,
  [PluginType.instagram_ads_manager_ctm_entry_point]:
    entryPointsIconTextures.instagramCTM,
  [PluginType.instagram_comments_autoreply_entry_point]:
    entryPointsIconTextures.instagramCommentsAutoreply,
  [PluginType.instagram_story_mention_entry_point]:
    entryPointsIconTextures.instagramStoryMention,
  [PluginType.instagram_story_reply_entry_point]:
    entryPointsIconTextures.instagramStoryReply,
  [PluginType.shopify_customer_chat_entry_point]:
    entryPointsIconTextures.shopifyCustomerChat,
  [PluginType.whatsapp_direct_entry_point]:
    entryPointsIconTextures.whatsappDirect,
  [PluginType.whatsapp_bot_link]: entryPointsIconTextures.whatsappBotlink,
  [PluginType.whatsapp_widget_entry_point]:
    entryPointsIconTextures.whatsappWidget,
  [PluginType.whatsapp_popup_entry_point]:
    entryPointsIconTextures.whatsappPopup,
};

export const COLOR_BY_BLOCK_SUBTYPE = {
  [BLOCK_SUBTYPES.flow_root]: HEXColors.azure,
  [BLOCK_SUBTYPES.broadcast_flow_root]: HEXColors.accent2Normal,
  [BLOCK_SUBTYPES.action]: HEXColors.magenta,
  [BLOCK_SUBTYPES.condition]: HEXColors.magenta,
  [BLOCK_SUBTYPES.split_traffic]: HEXColors.magenta,
  [BLOCK_SUBTYPES.delay]: HEXColors.magenta,
  [BLOCK_SUBTYPES.comments_autoreply_content]: HEXColors._2ABBC5,
  [BLOCK_SUBTYPES.instagram_comments_autoreply_content]: HEXColors.fuchsia,
  [BLOCK_SUBTYPES.redirect]: HEXColors.magenta,
  [BLOCK_SUBTYPES.send_message]: HEXColors.blue,
  [BLOCK_SUBTYPES.send_data]: HEXColors.violet,
  [BLOCK_SUBTYPES.import_content]: HEXColors.violet,
  [BLOCK_SUBTYPES.reminder]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.visitedProductsReminder]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.receipt]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.back_in_stock_message]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.back_in_stock_optin_confirmation]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.back_in_stock_otn]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.calendly]: HEXColors.violet,
  [BLOCK_SUBTYPES.calendly_confirmation]: HEXColors.violet,
  [BLOCK_SUBTYPES.calendly_reminder]: HEXColors.violet,
  [BLOCK_SUBTYPES.shopify_discount]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.postPurchase]: HEXColors.greenShopify,
  [BLOCK_SUBTYPES.open_ai]: HEXColors.violet,
  [BLOCK_SUBTYPES.ai_custom]: HEXColors.violet,
};

export const MAX_BUTTON_LENGTH = 20;

export const BLOCK_STATS_HEIGHT = 54;
