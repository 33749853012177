import { Button } from '@ui/Button';
import {
  BotTabs,
  getTabLink,
  globalHistory,
  useCurrentBotId,
} from '@utils/Routing';
import memoize from 'lodash-es/memoize';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Router, useHistory } from 'react-router-dom';

export const SetUpZapierInFlows: React.FC = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const botId = useCurrentBotId();

  return (
    <Button
      intent="primary"
      onClick={() => {
        history.push(getTabLink(BotTabs.flows, botId));
      }}
    >
      {t('modernComponents.FlowBuilder.views.components.Zapier.SetUpInFlows')}
    </Button>
  );
};

export const createSetUpZapierInFlowsButton = memoize(() => {
  return (
    <Router history={globalHistory}>
      <SetUpZapierInFlows />
    </Router>
  );
});
