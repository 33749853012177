import i18next from 'i18next';
import { Level, log } from 'cf-common/src/logger';
import { toaster, ServiceMessageType } from '@services/MessageService';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { SetAttributeView } from '../../components/SetAttributeView';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { Node } from '../../../Node';
import { pluginRemoveAttributeSvgTexture } from '../../../assets/textures';
import { tooltips } from '../../Menu/menu_view';
import { notEmptyString } from '../../validation';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { removeAttributePluginFragment_config as RemoveAttributePluginConfig } from '../../../../Plugins/RemoveAttributePlugin/@types/removeAttributePluginFragment';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import { Platform } from '@globals';
import { refetchAttributesQueryObservables } from '@utils/AttributesUtils/AttributesUtils';
import { HEXColors } from '@ui/_common/colors';

export class RemoveAttributePluginView
  extends VLayout
  implements StatefulPluginDelegate<RemoveAttributePluginConfig>
{
  TEST_NAME = 'RemoveAttributePluginView';

  public readonly state: StatefulPlugin<RemoveAttributePluginConfig>;

  private attributeView: SetAttributeView;

  constructor(state: StatefulPlugin<RemoveAttributePluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this.state = state;
    this.state.setDelegate(this);

    const titleLayout = new PluginTitleLayout(
      false,
      window.i18next.t('RemoveAttribute-string-4823-remove-user-attribute'),
      pluginRemoveAttributeSvgTexture,
      this.state.data,
      tooltips().remove_attribute,
    );
    this.layout(titleLayout, { margin: { top: 15, left: 10 } });

    const attrWidth = pluginWidth - 30;
    this.attributeView = new SetAttributeView(
      this.state.data,
      {
        width: attrWidth,
        fill: HEXColors.blue,
        caption: 'attribute',
        attributeOnly: true,
        customAttributesOnly: true,
        isEditing: () => this.state.isEditing,
      },
      undefined,
      () => {
        const title = this.attributeView.text().trim();
        const {
          id: cardId,
          plugin_id,
          config: { title: prevTitle },
        } = this.state.data;
        if (title !== prevTitle) {
          logFlowPluginEvent(plugin_id, 'update attribute title', {
            cardId,
            blockId: node.id,
            title,
          });
          this.state.set({
            config: { __typename: 'RemoveAttributePluginConfig', title },
          });
          this.state.save();
          refetchAttributesQueryObservables(
            getFlowControllerStrict().flow.botId,
            getFlowPlatform() || Platform.facebook,
          );
        }
      },
    );

    const hLayout = new HLayout().layout(this.attributeView, {
      margin: { right: 10 },
    });
    this.layout(hLayout, { margin: { left: 15, bottom: 15 } });
  }

  onBeforeRender() {
    this.attributeView.text(this.state.data.config.title ?? '');
  }

  pluginDidSet() {
    this.renderNode();
  }

  pluginDidSaveError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.RemoveAttributePlugin.failedToSavePluginMessageText',
        ),
      },
    });
    log({
      msg: 'Error while saving Remove Attribute Plugin',
      level: Level.error,
      data: {
        error: error.toString(),
      },
    });
  }

  hasSignificantChangesInConfig() {
    return notEmptyString(this.state.data.config.title);
  }

  validationError() {
    if (!this.state.data.config.title) {
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.RemoveAttributePlugin.attributeValidationText',
      );
    }
    return false;
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
