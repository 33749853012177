import { equals } from 'ramda';
import { HEXColors } from '@ui/_common/colors';
import i18next from 'i18next';
import { HTMLText } from '../../../../../../components/Elements/Shapes';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../../../../PixiFieldRepository';
import { logFlowEvent } from '../../../../../../utils/Analytics';
import { ZapierZap } from '../../../query';
import { VLayout } from '../../../../../../components/Elements/Layouts';
import { getI18ZapierKey } from '../../../utils/getI18ZapierKey';
import { ZapAdminView } from '../ZapAdminView/ZapAdminView';
import { ZapListItemView } from './components/ZapListItemView';

interface ZapListProps {
  onUpdateZapsListRequest: () => void;
  pluginId: string;
  cardId: string;
  blockId?: string;
}

const ZAPS_LIMIT = 10;

const orderZaps = (zaps: ZapierZap[], adminId: string) => [
  ...zaps.filter(({ owner }) => owner?.id === adminId),
  ...zaps.filter(({ owner }) => owner && owner.id !== adminId),
  ...zaps.filter(({ owner }) => !owner),
];

export class ZapsListView extends VLayout {
  private readonly zapsBox: VLayout;
  private currentZapsLimit: number | undefined = ZAPS_LIMIT;
  private showAllZapsLink: HTMLText;
  private onUpdateZapsListRequest: () => void;
  private zaps: ZapierZap[];
  private pluginId: string;
  private cardId: string;
  private blockId: string | undefined;

  constructor({
    onUpdateZapsListRequest,
    pluginId,
    cardId,
    blockId,
  }: ZapListProps) {
    super({});
    this.onUpdateZapsListRequest = onUpdateZapsListRequest;
    this.zaps = [];
    this.pluginId = pluginId;
    this.cardId = cardId;
    this.blockId = blockId;
    this.zapsBox = new VLayout({});
    this.addToLayout(this.zapsBox);

    this.showAllZapsLink = new HTMLText({
      text: '',
      trustedHtml: true,
      fontSize: 15,
      fill: HEXColors.greyDark,
    });

    this.showAllZapsLink.on('click', (event) => {
      event.stopPropagation();
      this.currentZapsLimit = this.currentZapsLimit ? undefined : ZAPS_LIMIT;
      this.updateZapListView();
      this.renderNode();
      logFlowEvent(
        'zapier integration',
        this.currentZapsLimit ? 'show less zaps click' : 'show more zaps click',
      );
    });

    this.addToLayout(this.showAllZapsLink, {
      margin: {
        top: 4,
        bottom: 8,
      },
      gone: () => this.zaps?.length <= ZAPS_LIMIT,
    });
  }

  public updateZapsData(zaps: ZapierZap[]) {
    const updatedZaps = orderZaps(zaps, getFlowControllerStrict().flow.user.id);
    if (equals(updatedZaps, this.zaps)) {
      return;
    }
    this.zaps = updatedZaps;
    this.updateZapListView();
  }

  private updateZapListView() {
    const isViewOnly = getPixiFieldStrict().isViewOnly();
    const userId = isViewOnly
      ? undefined
      : getFlowControllerStrict().flow.user.id;
    this.zapsBox._views.forEach(({ view }) => view.destroy());
    this.zapsBox._views.length = 0;
    let currentZapAdminId: string | undefined | null;
    this.zaps.slice(0, this.currentZapsLimit).forEach((zap, index) => {
      const { owner } = zap;
      const ownerId = isViewOnly ? null : owner?.id || null;
      if (currentZapAdminId !== ownerId && owner?.id !== userId) {
        currentZapAdminId = ownerId;
        this.zapsBox.addToLayout(
          new ZapAdminView({
            user: owner,
          }),
          {
            margin: {
              left: 0,
              right: 0,
              top: index > 0 ? 17 : 8,
              bottom: 12,
            },
          },
        );
      }

      this.zapsBox.addToLayout(
        new ZapListItemView({
          zap,
          pluginId: this.pluginId,
          onUpdateZapsListRequest: this.onUpdateZapsListRequest,
          cardId: this.cardId,
          blockId: this.blockId,
        }),
        {
          margin: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 8,
          },
        },
      );
    });

    this.showAllZapsLink.text(
      `<u>${
        this.currentZapsLimit
          ? i18next.t(getI18ZapierKey('showAllZaps'), {
              zapsQty: this.zaps.length,
            })
          : i18next.t(getI18ZapierKey('showOnlyNZaps'), {
              zapsLimit: ZAPS_LIMIT,
            })
      }</u>`,
    );
  }
}
