import { ServiceMessageType, toaster } from '@services/MessageService';
import { HEXColors } from '@ui/_common/colors';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import {
  externalIntegrationEntryPointFragment,
  externalIntegrationEntryPointFragment_config as ExternalIntegrationEntryPointConfig,
} from '@components/Plugins/ExternalIntegrationEntryPoint/@types/externalIntegrationEntryPointFragment';
import clipboardCopy from 'clipboard-copy';
import i18next from 'i18next';
import { Node } from '../../../Node';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { ZapierIntegrationView } from '../../components/Zapier';
import { getI18ZapierKey } from '../../components/Zapier/utils/getI18ZapierKey';
import { ZapierCommentCardView } from '../../components/Zapier/ZapierCommentCardView/ZapierCommentCardView';
import { pluginWidth } from '../../plugin_consts';
import { VLayout } from '../../../components/Elements/Layouts';

export interface EntryPointExternalIntegrationCard
  extends externalIntegrationEntryPointFragment {
  isEditing?: boolean;
  plugin_id: PluginType;
}

export class ExternalIntegrationEntryPoint
  extends VLayout
  implements StatefulPluginDelegate<ExternalIntegrationEntryPointConfig>
{
  public _node: Node;
  private state: StatefulPlugin<ExternalIntegrationEntryPointConfig>;
  private readonly integrationView: ZapierIntegrationView;

  constructor(
    state: StatefulPlugin<ExternalIntegrationEntryPointConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.integrationView = new ZapierIntegrationView({
      node,
      pluginId: state.data.plugin_id,
      cardId: state.data.id,
    });
    this.integrationView.updateConfigData(this.state.data.config);
    this.addToLayout(this.integrationView);

    const comment = new ZapierCommentCardView();

    comment.updateText(
      i18next.t(getI18ZapierKey('entryPointCardInstructions'), {
        blockId: node.block.id,
      }),
    );

    comment.on('click', (event: Event) => {
      event.stopPropagation();
      clipboardCopy(state.data.id);
      toaster.show({
        type: ServiceMessageType.default,
        payload: {
          message: i18next.t(getI18ZapierKey('cardIdCopied')),
        },
      });
    });

    this.addToLayout(comment, {
      gone: () => this.hasConnectedZaps(),
    });
  }

  pluginDidSet() {
    this.integrationView.updateConfigData(this.state.data.config);
    this.renderNode();
  }

  hasSignificantChangesInConfig() {
    return !!this.state.data.config?.connected_zaps?.length;
  }

  private hasConnectedZaps() {
    return !!this.state.data.config.connected_zaps?.length;
  }

  validationError() {
    return this.integrationView.validationError();
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
