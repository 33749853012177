import { propEq } from 'ramda';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { removeTypename } from '@utils/GQL/utils';
import { PermissionGroup } from '@utils/Permissions';
import { HEXColors } from '@ui/_common/colors';
import { facebookShopsEntryPointFragment_config_products } from '@components/Plugins/FacebookShopsEntryPoint/@types/facebookShopsEntryPointFragment';
import { getEntryPointCard, getEntryPointCardView } from '../../common/utils';
import { Dropdown, Item } from '../../../kit/Dropdown';
import {
  getIdFromProductItem,
  showChooseFacebookShopProductsDialog,
} from './ChooseFacebookShopProductsDialog';
import { showFacebookShopsRequestPermissionsDialog } from './FacebookShopsRequestPermissionsDialog';
import { BLOCK_SUBTYPES } from '../../../../consts';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';
import { showConnectPageDialog } from '../../common/utils/showConnectPageDialog';
import { logFlowPluginEvent } from '../../../../utils/Analytics';

import { HLayout, VLayout } from '../../../../components/Elements/Layouts';
import {
  EntryPointFacebookShops,
  EntryPointFacebookShopsCard,
} from '../EntryPointFacebookShops';
import { Node } from '../../../../Node';
import { addButtonControl } from '../../../helpers/ControlsHelpers';
import { ProductItem } from './ProductItem';
import { getPageInfoFromNode } from '../../../utils';
import { pluginWidth } from '../../../plugin_consts';
import { PlusButtonView } from '../../../plus_button_view';

enum ApplyTypesIds {
  all = 'all',
  specific = 'specific',
}

const APPLY_TYPES: () => Item[] = () => [
  {
    id: ApplyTypesIds.all,
    title: window.i18next.t('ProductsSelector-string-5418-all-products'),
  },
  {
    id: ApplyTypesIds.specific,
    title: window.i18next.t('ProductsSelector-string-1588-specific-products'),
  },
];

export class ProductsSelector extends VLayout {
  TEST_NAME = 'ProductsSelector';
  private chooseProductButton: PlusButtonView;

  private productsBox: VLayout;

  private card: EntryPointFacebookShopsCard;

  private node: Node;

  constructor(card: EntryPointFacebookShopsCard, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
        stroke: HEXColors.red,
        strokeWidth: (v: any) => (!card.isEditing && !v.isValid() ? 2 : 0),
      },
      cursor: {
        in: 'default',
      },
    });

    this.card = card;
    this.node = node;

    this.addToLayout(
      new Dropdown({
        defaultSelectedItem:
          APPLY_TYPES().find(
            propEq(
              'id',
              card.config.apply_for_all_products
                ? ApplyTypesIds.all
                : ApplyTypesIds.specific,
            ),
          ) || APPLY_TYPES()[0],
        items: APPLY_TYPES(),
        onChange: (selectedItem) => {
          // eslint-disable-next-line no-param-reassign
          card.config.apply_for_all_products =
            selectedItem.id === ApplyTypesIds.all;
          node.updateCard(card, undefined, ({ status }: any) => {
            // if have conflict (http 409) from other Facebook Shop entry points
            if (status === 409) {
              const titleView = node.blockView.blockTitleView;
              titleView?.setHeaderActive(false);
            }
          });
          this.renderNode();

          logFlowPluginEvent(
            PluginType.facebook_shops_entry_point,
            'Apply rule to change',
            {
              apply_for_all_products: card.config.apply_for_all_products,
              apply_rule_to: selectedItem.id,
              blockId: node.id,
              cardId: card?.id,
            },
          );
        },
        isEditing: () => !!card.isEditing,
        label: window.i18next.t('ProductsSelector-string-2268-apply-rule-to'),
      }),
      {
        margin: () => ({
          left: 16,
          right: 16,
          top: 16,
          bottom: card.config.apply_for_all_products ? 16 : 0,
        }),
      },
    );

    this.productsBox = new VLayout({
      width: pluginWidth - 32,
    });

    this.addToLayout(this.productsBox, {
      margin: { top: 16, bottom: 8, left: 16 },
      gone: () =>
        !card.config.products?.length || card.config.apply_for_all_products,
    });

    this.chooseProductButton = new PlusButtonView(
      window.i18next.t('ProductsSelector-string-1970-＋-choose-products'),
      pluginWidth - 32,
    );

    this.chooseProductButton.on('pointerdown', (event: Event) => {
      event.stopPropagation();
    });

    this.chooseProductButton.on('click', (event: Event) => {
      event.stopPropagation();

      const facebookShopsPermission =
        node.controller.flow.verifiedPermissions?.find(
          ({ id }) => id === PermissionGroup.shops,
        );

      logFlowPluginEvent(
        PluginType.facebook_shops_entry_point,
        'Choose products click',
        {
          blockId: node.id,
          cardId: card?.id,
        },
      );

      const pageExists = getPageInfoFromNode(this.node);
      const entryPointCard = getEntryPointCard(this.node.block.cards);

      if (facebookShopsPermission && !facebookShopsPermission?.enabled) {
        showFacebookShopsRequestPermissionsDialog({
          permissionGroup:
            facebookShopsPermission.permissions_group as PermissionGroup,
        });
      } else if (!pageExists && entryPointCard) {
        showConnectPageDialog(this.node, entryPointCard);
      } else {
        this.showChooseProductsDialog();
      }
    });

    this.addToLayout(this.chooseProductButton, {
      margin: () => ({
        left: 16,
        right: 16,
        bottom: 16,
        top: card.config.products?.length ? 0 : 16,
      }),
      gone: () =>
        (!card.isEditing && !!card.config.products?.length) ||
        card.config.apply_for_all_products,
    });

    this.createProductsList();
  }

  createProductsList() {
    this.productsBox._views.forEach(({ view }) => view.destroy());
    this.productsBox._views.length = 0;
    this.card.config.products?.forEach((product) => {
      this.createProductView(product);
    });
  }

  createProductView(config: facebookShopsEntryPointFragment_config_products) {
    const postView = new ProductItem({
      config,
      node: this.node,
      card: this.card,
    });
    this.addButtonControl(postView);
    this.productsBox.addToLayout(postView, { margin: { bottom: 8 } });
  }

  public removeProduct(index: number) {
    const productView = this.productsBox.views()[index];
    this.card.config.products?.splice(index, 1);
    this.productsBox.removeView(productView);
    productView.destroy();
    this.node.updateLines();
  }

  addButtonControl(newItemView: HLayout) {
    addButtonControl(
      newItemView,
      this.productsBox,
      this.card.config.products || [],
      this.node,
      this.card,
      undefined,
      undefined,
      undefined,
      () => {
        this.renderNode();
        logFlowPluginEvent(
          PluginType.facebook_shops_entry_point,
          'remove product',
          {
            blockId: this.node.id,
            cardId: this.card?.id,
          },
        );
      },
      (_, start, end) => {
        logFlowPluginEvent(
          PluginType.facebook_shops_entry_point,
          'drag product',
          {
            start,
            end,
            blockId: this.node.id,
            cardId: this.card?.id,
          },
        );
      },
    );
  }

  public showChooseProductsDialog() {
    showChooseFacebookShopProductsDialog({
      target: PluginType.facebook_shops_entry_point,
      checkedProductsIds: (this.card.config.products || []).map(
        getIdFromProductItem,
      ),
      onSubmit: (checkedProducts, notLoadedCheckedProductsIds) => {
        checkedProducts.push(
          ...(this.card.config.products?.filter((product) =>
            notLoadedCheckedProductsIds.includes(getIdFromProductItem(product)),
          ) || []),
        );
        this.card.config.products = [];
        this.card.config.products.push(...checkedProducts);
        this.node.updateCard(removeTypename(this.card));
        this.createProductsList();
        this.renderNode();
      },
      onRemovedProduct: (productId) => {
        getFlowControllerStrict()
          .allNodes()
          .forEach(({ blockView, block }) => {
            if (!block || block.subtype !== BLOCK_SUBTYPES.entrypoint) {
              return;
            }
            const entryPointCard = getEntryPointCard(
              block?.cards,
            ) as unknown as EntryPointFacebookShopsCard;
            const entryPointCardView = getEntryPointCardView(blockView) as
              | EntryPointFacebookShops
              | undefined;
            const productsIndex = entryPointCard?.config.products?.findIndex(
              (product) => getIdFromProductItem(product) === productId,
            );
            if (productsIndex !== undefined && productsIndex !== -1) {
              entryPointCardView?.productsSelector.removeProduct(productsIndex);
              blockView.renderNode();
            }
          });
      },
      blockId: this.node.block.id,
    });
  }

  isValid() {
    return (
      this.card?.config.apply_for_all_products ||
      !!this.card?.config.products?.length
    );
  }
}
