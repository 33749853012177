import gql from 'graphql-tag';

export const AI_CUSTOM_TEMPLATES_FRAGMENT = gql`
  fragment aiCustomTemplatesFragment on CustomAiTemplate {
    id
    title
    description
    nestedMenuOrder
  }
`;
