import { MainLayout, VLayout } from '../components/Elements/Layouts';
import { Node } from '../Node';
import { FlowBuilderSnapShotTreeItem } from './types';
import {
  getPixiFieldStrict,
  getFlowControllerStrict,
} from '../PixiFieldRepository';

import { Toggle } from '../views/kit/toggle';
import { Checkbox, CheckboxIcon } from '../views/kit/Checkbox';
import { AlertView } from '../views/entry-points/common/utils/validationProps';
import { LineStartView } from '../views/components/Line/LineStartView';
import { BlockView } from '../views/block_view';
import { BlockLoadingView } from '../views/block_loading_view';
import { Layout } from '../components/Elements/Layouts/Layout';
import { Shape } from '../components/Elements/Shapes';
import isFunction from 'lodash-es/isFunction';
import { ButtonEditView } from '../views/button_edit_view';
import { resByFunc } from '../views/utils';

const isCardView = (view: MainLayout) =>
  (view.parent as unknown as VLayout)?.TEST_NAME === 'CardsLayout';

enum ViewType {
  block = 'block',
  card = 'card',
}

/* eslint-disable no-param-reassign */
const insertSpecificElementData = (
  out: FlowBuilderSnapShotTreeItem,
  el: MainLayout | Shape,
) => {
  const { text } = el as any;
  if (text) {
    out.props.text = isFunction(text) ? text.apply(el) : text;
  }
  switch (el.constructor) {
    case BlockView:
      out.props.id = (el as BlockView)._node?.block?.id;
      out.props.viewType = ViewType.block;
      break;
    case Checkbox:
    case CheckboxIcon:
      out.props.checked = (el as CheckboxIcon).checked;
      break;
    case Toggle:
      out.props.enabled = (el as Toggle)._value;
      break;
    case LineStartView:
      // @ts-ignore
      out.state.fromId = (el as unknown as LineStartView).fromView._node.id;
      out.state.toId = (el as unknown as LineStartView)._lineView.toId();
      out.state.hidden = (el as unknown as LineStartView).shape().visible;
      out.state.color = (el as unknown as LineStartView).stroke();
      break;
    case ButtonEditView:
      out.state.hasError = !(el as ButtonEditView).getButtonErrorGone();
      break;
    default:
      break;
  }

  if (el instanceof MainLayout && isCardView(el)) {
    out.props.viewType = ViewType.card;
    // @ts-ignore
    out.props.id = el._card?.id || el.state?.plugin.id;
    // @ts-ignore
    out.state.isEditing = el.state ? el.state.isEditing : el._card?.isEditing;

    const { strokeWidth, stroke } = el._background?._props || {};
    out.state.strokeColor = strokeWidth ? stroke : 'none';
  }
};
/* eslint-enable no-param-reassign */

const getElementState = (el: MainLayout | Shape) => {
  if (el instanceof MainLayout) {
    return {
      mounted: !!el.parent,
      visible: el.visible,
      disabled: el._disable,
      hovered: el.hovered,
    };
  }
  if (el instanceof Shape) {
    const gone = resByFunc(el.layoutProperties?.gone, el as any);
    return {
      mounted: !!el.rendered,
      visible: !!el.layoutProperties?.visible && !gone,
    };
  }
  return {};
};

const getElementLayoutParams = (el: MainLayout | Shape) => {
  const { x, y } = el.globalPosition();
  const { scale } = getPixiFieldStrict().viewport;
  return {
    x,
    y,
    width: (el.width?.() || 0) * scale.x,
    height: (el.height?.() || 0) * scale.y,
    zIndex: el instanceof MainLayout ? el.zIndex : undefined,
  };
};

const getElementData = (el: MainLayout | Shape) => {
  const out: FlowBuilderSnapShotTreeItem = {
    type: (el as any).TEST_NAME || 'undefined',
    layout: getElementLayoutParams(el),
    props: {},
    state: getElementState(el),
  };
  insertSpecificElementData(out, el);
  return out;
};

const seekElement = (el: MainLayout): FlowBuilderSnapShotTreeItem => {
  // @ts-ignore
  const children = el._views?.map(({ view }) => seekElement(view)) ?? [];

  const alertView = el.children?.find((v) => v instanceof AlertView) as
    | AlertView
    | undefined;
  if (alertView) {
    children.push(getElementData(alertView));
  }

  return {
    ...getElementData(el),
    children,
  };
};

window.getFlowBuilderSnapshot = () => {
  const pixi = getPixiFieldStrict();
  const oneViewLayouts: Record<
    string,
    FlowBuilderSnapShotTreeItem | FlowBuilderSnapShotTreeItem[]
  > = {};

  oneViewLayouts.cardControlView = seekElement(pixi.cardControlView);
  oneViewLayouts.galleryCardControlView = seekElement(
    pixi.galleryCardControlView,
  );
  oneViewLayouts.buttonControlView = seekElement(pixi.buttonControlView);
  oneViewLayouts.blockControlView = seekElement(pixi.blockControlView);
  oneViewLayouts.mainControlView = seekElement(pixi.mainControlView);
  oneViewLayouts.blockLoadingViews = (
    pixi.viewport.children as unknown as Layout[]
  )
    .filter(({ constructor }) => constructor === BlockLoadingView)
    .map(seekElement);

  return {
    baseLayout: getFlowControllerStrict()
      .allNodes()
      .map(({ blockView }: Node) => seekElement(blockView)),
    oneViewLayouts,
    currentAnimations: Object.keys(pixi.activeAnimations),
  };
};
