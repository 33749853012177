import { splitTrafficPluginFragment_config_variants } from '../../../../Plugins/SplitTrafficPlugin/@types/splitTrafficPluginFragment';

export const VARIANT_TITLES = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
];

export const DEFAULT_VARIANT: splitTrafficPluginFragment_config_variants = {
  __typename: 'SplitTrafficPluginVariant',
  percent: 0,
  block_id: null,
};
