import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import { ObservableInput } from 'rxjs/Observable';
import {
  IntegrationCalendlyAccountStateQuery,
  IntegrationCalendlyAccountStateQueryVariables,
} from './@types/IntegrationCalendlyAccountStateQuery';
import client from '../../../../../../common/services/ApolloService';

export const INTEGRATION_CALENDLY_ACCOUNT_STATE_FRAGMENT = gql`
  fragment integrationCalendlyAccountStateFragment on Bot {
    connectedCalendlyAccounts {
      id
      admin_id
      name
      email
      avatar_url
      event_types {
        uri
        name
      }
    }
  }
`;

const INTEGRATION_CALENDLY_ACCOUNT_STATE_QUERY = gql`
  query IntegrationCalendlyAccountStateQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...integrationCalendlyAccountStateFragment
    }
  }
  ${INTEGRATION_CALENDLY_ACCOUNT_STATE_FRAGMENT}
`;

export const getCalendlyAccountsObservable = memoize((botId: string) => {
  const query = client.watchQuery<
    IntegrationCalendlyAccountStateQuery,
    IntegrationCalendlyAccountStateQueryVariables
  >({
    query: INTEGRATION_CALENDLY_ACCOUNT_STATE_QUERY,
    variables: {
      botId,
    },
  });
  return {
    observable: Observable.from(
      query as ObservableInput<
        ApolloQueryResult<IntegrationCalendlyAccountStateQuery>
      >,
    ).map(({ data }) => data?.bot?.connectedCalendlyAccounts),
    refetch: () => query.refetch(),
  };
});
