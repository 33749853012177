import { getShopifyFeatureStatus } from '@utils/Integrations/Shopify/utils/getShopifyFeatureStatus';
import { ShopifyConnectedAccountStateQuery_bot_connectedShop } from '@utils/Integrations/Shopify/@types/ShopifyConnectedAccountStateQuery';
import { BotTabs, getTabLink } from '@utils/Routing';
import { goToConnectShopifyAccount } from '@utils/Integrations/Shopify/utils/goToConnectShopifyAccount';
import { ShopifyFeatureType } from '@globals';
import { DontHaveShopifyPermissionsError } from '../../EntryPointShopifyEvent/errors';
import { Node } from '../../../../Node';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';

export const validateShopifyPermissions = (
  shopifyAccount:
    | ShopifyConnectedAccountStateQuery_bot_connectedShop
    | null
    | undefined,
  featureType: ShopifyFeatureType,
  node: Node,
) =>
  shopifyAccount &&
  (getShopifyFeatureStatus(shopifyAccount, featureType)
    ? undefined
    : new DontHaveShopifyPermissionsError(() => {
        const {
          flow: { botId, id: flowId },
        } = getFlowControllerStrict();
        goToConnectShopifyAccount({
          botId,
          domain: shopifyAccount.myshopify_domain,
          interruptedHref: getTabLink(BotTabs.flows, botId, {
            flowId,
            blockId: node.block.id,
          }),
        });
      }));
