import i18next from 'i18next';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { pluginWidth } from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { Margin } from '../../utility_classes';
import { whatsappTemplatePluginFragment_config_template as Template } from '@components/Plugins/WhatsappTemplatePlugin/@types/whatsappTemplatePluginFragment';
import { getStatusRepresentation } from '@utils/Whatsapp/Templates/utils';

export const WRAPPER_MARGIN = new Margin({ x: 12, y: 12 });

export interface WhatsappTemplateStatusProps {
  template: Template | null;
}

export class WhatsappTemplateStatus extends VLayout {
  constructor({ template }: WhatsappTemplateStatusProps) {
    super({ width: pluginWidth - WRAPPER_MARGIN.x });

    const text = new HTMLText({
      fill: HEXColors.baseSecondary,
      fontSize: 12,
      singleLine: true,
      width: 60,
      text: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.status',
      ),
    });

    const representation = template
      ? getStatusRepresentation(template)
      : { textHEXColor: HEXColors.black, name: '' };

    const statusText = new HTMLText({
      fill: representation.textHEXColor,
      fontSize: 12,
      singleLine: true,
      text: representation.name,
    });
    const badge = new VLayout({
      background: {
        fill: HEXColors.white,
        cornerRadius: 14,
      },
    });

    const header = new HLayout();

    this.addToLayout(
      header
        .addToLayout(text, { margin: new Margin({ y: 4, right: 4 }) })
        .addToLayout(
          badge.addToLayout(statusText, {
            margin: new Margin({ y: 4, x: 8 }),
          }),
        ),
    );
  }
}
