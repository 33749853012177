import { Messages, toaster } from '@services/MessageService';
import gql from 'graphql-tag';
import { Level, log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import client from '../../../../../../common/services/ApolloService';
import {
  IntegrationCalendlyUrlQuery,
  IntegrationCalendlyUrlQueryVariables,
} from './@types/IntegrationCalendlyUrlQuery';

const INTEGRATION_CALENDLY_OAUTH_URL = gql`
  query IntegrationCalendlyUrlQuery($state: String!) {
    getIntegrationCalendlyOAuthUrl(state: $state)
  }
`;

export interface StartIntegrationCalendlyOAuthState {
  botId: string;
  flowId: string;
  blockId: string;
}

export const startIntegrationCalendlyOAuth = async (
  state: StartIntegrationCalendlyOAuthState,
) => {
  try {
    const { data } = await client.query<
      IntegrationCalendlyUrlQuery,
      IntegrationCalendlyUrlQueryVariables
    >({
      query: INTEGRATION_CALENDLY_OAUTH_URL,
      variables: {
        state: JSON.stringify(state),
      },
    });

    if (data) {
      window.location.replace(data.getIntegrationCalendlyOAuthUrl);
    }
  } catch (error) {
    log({
      level: Level.error,
      error,
      msg: `Error getting Calendly OAuth url, ${(error as any).toString()}`,
      data: {
        label: 'calendly',
        requestId: getRequestIdFromApolloError(error as any),
      },
    });
    toaster.error({
      payload: {
        message: Messages.somethingWentWrong,
      },
    });
  }
};
