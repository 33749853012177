import React, { HTMLAttributes } from 'react';
import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';

interface GoToFacebookButtonProps extends HTMLAttributes<HTMLButtonElement> {
  eventTarget: string;
}

export const GoToFacebookButton: React.FC<GoToFacebookButtonProps> = ({
  onClick,
  eventTarget,
  ...props
}) => (
  <Button
    intent="secondary"
    onClick={(event) => {
      sendEvent({
        category: 'flows',
        action: `Go to Facebook button from ${eventTarget} click`,
        label: PluginType.facebook_shops_entry_point,
      });
      window.open(
        'https://business.facebook.com/commerce_manager/onboarding_landing',
        '_blank',
      );
      onClick?.(event);
    }}
    {...props}
  >
    {window.i18next.t('GoToFacebookButton-JSXText-1018-go-to-facebook')}
  </Button>
);
