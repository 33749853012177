import { BotStatus } from '@utils/Bot';
import { ConnectPageOrigin } from '@components/ConnectPageDialog';

import { getPageInfoFromNode } from '../../../utils/getPageInfoFromNode';

import { Node } from '../../../../Node';
import { showConnectPageDialog } from './showConnectPageDialog';
import { getEntryPointCard } from '../utils';
import { ConnectFacebookPageError } from '../errors';

export const validateIsPageConnecting = (
  node: Node,
  onPageConnected?: (params: {
    status: BotStatus;
    pageId: string | null | undefined;
  }) => void,
  connectPageOrigin?: ConnectPageOrigin,
) =>
  getPageInfoFromNode(node)
    ? undefined
    : new ConnectFacebookPageError(() => {
        const entryPointCard = getEntryPointCard(node.block.cards);

        if (entryPointCard) {
          showConnectPageDialog(
            node,
            entryPointCard,
            onPageConnected,
            connectPageOrigin,
          );
        }
      });
