import i18next from 'i18next';
import { PermissionGroup } from '@utils/Permissions';
import { commentsAutoreplyEntryPointFragment_config as EntryPointCommentsAutoreplyConfig } from '../../../../../Plugins/CommentsAutoreplyEntryPoint/@types/commentsAutoreplyEntryPointFragment';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { Node } from '../../../../Node';
import { PostsSelector } from '../components/PostsSelector';
import { getI18nCommentsAutoreplyKey } from '../utils/getI18nCommentsAutoreplyKey';
import { LinkedLayout } from '../../../../components/Elements/Layouts/types';
import {
  commentsAutoReplyAllUsersLineMenu,
  commentsAutoReplyLineMenu,
} from '../../../Menu/createLineMenu';
import { LineStartSubtype } from '../../../components/Line/types';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '@components/FlowBuilder/StatefulPlugin';
import { validateIsPageConnecting } from '../../common/utils/validateIsPageConnecting';
import { CommentsAutoreplyCommon } from '../components/CommentsAutoreplyCommon';
import { MessengerCommentsAutoreplyPostsSelector } from './MessengerCommentsAutoreplyPostsSelector';

export class EntryPointCommentsAutoreply
  extends CommentsAutoreplyCommon<EntryPointCommentsAutoreplyConfig>
  implements StatefulPluginDelegate<EntryPointCommentsAutoreplyConfig>
{
  getPostSelectorView(): PostsSelector<{ id: string }> {
    return new MessengerCommentsAutoreplyPostsSelector({
      plugin: this,
      logger: this.logger,
    });
  }

  TEST_NAME = 'EntryPointCommentsAutoreply';

  constructor(
    state: StatefulPlugin<EntryPointCommentsAutoreplyConfig>,
    node: Node,
  ) {
    super(state, node, {
      validatorOptions: {
        postSelectorErrorMessage: i18next.t(
          getI18nCommentsAutoreplyKey('Messenger.postSelectorValidationText'),
        ),
        commentsPermissionsErrorMessage: i18next.t(
          getI18nCommentsAutoreplyKey('Messenger.permissionsValidationText'),
        ),
        permissionGroup: PermissionGroup.comments,
      },
      replyToOptions: {
        targetPlugin: PluginType.comments_autoreply_entry_point,
        lineParams: {
          items: commentsAutoReplyAllUsersLineMenu,
          searchable: false,
          menuStyle: { minWidth: 0 },
          subtype: LineStartSubtype.commentsAutoreplyAllUsers,
        },
      },
      replyToForCurrentSubscribersOptions: {
        targetPlugin: PluginType.comments_autoreply_entry_point,
        lineParams: {
          items: commentsAutoReplyLineMenu,
          searchable: false,
          menuStyle: { minWidth: 0 },
          subtype: LineStartSubtype.commentsAutoreplyOldUsers,
        },
      },
    });
  }

  onBeforeRender() {
    const { config } = this.state.data;

    (
      this.replyToForCurrentSubscribers.keywordGroupsBox.views() as LinkedLayout[]
    ).forEach(({ lineStartView }) => {
      // eslint-disable-next-line no-param-reassign
      lineStartView._lineView.gone = !config.already_subscribed_setup_enabled;
    });

    this.replyToForCurrentSubscribers.lineStartView._lineView.gone =
      !config.already_subscribed_setup_enabled;
  }

  validationError() {
    return validateIsPageConnecting(this._node) || super.validationError();
  }

  destroy() {
    super.destroy();
  }
}
