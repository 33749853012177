import { InteractionEvent, IPoint } from 'pixi.js-legacy';
import { HEXColors } from '@ui/_common/colors';
import { FLOWS_QUERY } from '@utils/Data/Flow';
import {
  FlowsQuery,
  FlowsQuery_bot_flows_entry_points,
  FlowsQueryVariables,
} from '@utils/Data/Flow/@types/FlowsQuery';
import { BotTabs, getTabLink, globalHistory } from '@utils/Routing';
import { Subscription } from 'apollo-client/util/Observable';
import i18next from 'i18next';
import { clone } from 'ramda';
import { ShopifyConnectedAccountStateQuery_bot_connectedShop } from '@utils/Integrations/Shopify/@types/ShopifyConnectedAccountStateQuery';
import { ShopifyEventType, ShopifyFeatureType } from '@globals';
import client from '../../../../../common/services/ApolloService';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import {
  shopifyEventEntryPointFragment_config as ShopifyEventEntryPointConfig,
  shopifyEventEntryPointFragment_config_events,
} from '../../../../Plugins/ShopifyEventEntryPoint/@types/shopifyEventEntryPointFragment';
import { SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS } from '../../../../Plugins/ShopifyEventEntryPoint/ShopifyEventEntryPointConst';
import { VLayout } from '../../../components/Elements/Layouts';
import { BLOCK_SUBTYPES } from '../../../consts';
import { Node } from '../../../Node';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { ButtonView } from '../../button_view';
import { ShopifyAccount } from '../../components/ShopifyAccount/ShopifyAccount';
import { buttonControl } from '../../helpers/ControlsHelpers';
import { createFlowBlock } from '../../helpers/createFlowBlock';
import { CreateMenuViewOverlay } from '../../menu_view_overlay';
import { pluginWidth } from '../../plugin_consts';
import { swapImmutable } from '../../utils';
import { showConnectPageDialog } from '../common/utils/showConnectPageDialog';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { validateIsShopifyConnected } from '../common/utils/validateIsShopifyConnected';
import { SHOPIFY_EVENTS_COMPONENTS } from './components/eventViews';
import { getShopifyEventsItems } from './components/eventViews/common/constants';
import { ShopifyEventViewBase } from './components/eventViews/common/ShopifyEventViewBase';
import {
  DontHaveEnabledPopupEntryPointError,
  DontHavePopupEntryPointError,
  EmptyEventsListError,
} from './errors';
import { validateShopifyPermissions } from '../common/utils/validateShopifyPermissions';

interface SomePopupEntryPoint extends FlowsQuery_bot_flows_entry_points {
  flow_id: string;
}

const MAX_EVENTS_QTY = 10;

export class EntryPointShopifyEvent
  extends VLayout
  implements StatefulPluginDelegate<ShopifyEventEntryPointConfig>
{
  public _node: Node;
  private state: StatefulPlugin<ShopifyEventEntryPointConfig>;
  private readonly eventsBox: VLayout;
  private connectedShopifyAccount:
    | ShopifyConnectedAccountStateQuery_bot_connectedShop
    | null
    | undefined;
  private readonly entryPointsSubscription: Subscription | undefined;
  private somePopupEntryPoint: SomePopupEntryPoint | null | undefined;

  constructor(state: StatefulPlugin<ShopifyEventEntryPointConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.addToLayout(
      new ShopifyAccount({
        eventPropertyBag: { pluginId: PluginType.shopify_event_entry_point },
        onConnectedAccountStatusChange: (account) => {
          this.connectedShopifyAccount = account;
          this.renderNode();
        },
        blockId: node.block.id,
        onPageConnectRequest: () => {
          showConnectPageDialog(node, this.state.data);
        },
      }),
      {
        margin: {
          bottom: 16,
        },
      },
    );

    this.eventsBox = new VLayout();

    this.addToLayout(this.eventsBox);

    this.createEventsList();

    this.addToLayout(
      new ButtonView({
        width: pluginWidth,
        height: 40,
        title: i18next.t('shopify.addEvent'),
        strokeWidth: 1,
        onClick: (event: InteractionEvent) => {
          event.stopPropagation();
          logFlowPluginEvent(
            PluginType.shopify_event_entry_point,
            'Add event click',
          );
          new CreateMenuViewOverlay({
            onChoose: ({ id }) => {
              logFlowPluginEvent(
                PluginType.shopify_event_entry_point,
                'Choose event for add',
                {
                  eventType: id,
                },
              );
              this.addEventItem(id);
            },
            items: getShopifyEventsItems(),
          }).showOn(event.data.global);
        },
      }),
      {
        margin: {
          top: 17,
        },
        gone: () =>
          !this.state.isEditing ||
          (this.state.data.config.events.length || 0) > MAX_EVENTS_QTY - 1,
      },
    );

    if (!getPixiFieldStrict().isViewOnly()) {
      this.entryPointsSubscription = client
        .watchQuery<FlowsQuery, FlowsQueryVariables>({
          query: FLOWS_QUERY,
          variables: {
            botId: getFlowControllerStrict().flow.botId,
          },
        })
        .subscribe(({ data }) => {
          const entryPoints = data?.bot.flows?.flatMap(({ entry_points, id }) =>
            (entry_points || []).map((entryPoint) => ({
              ...entryPoint,
              flow_id: id,
            })),
          );
          this.somePopupEntryPoint =
            entryPoints?.find(
              (entryPoint) =>
                entryPoint?.enabled &&
                entryPoint.entry_point_type === PluginType.popup_entry_point,
            ) ||
            entryPoints?.find(
              (entryPoint) =>
                entryPoint?.entry_point_type === PluginType.popup_entry_point,
            );
          this.renderNode();
        });
    }
  }

  private addEventItem(type: ShopifyEventType) {
    const newEventConfig = clone(
      SHOPIFY_EVENT_ENTRY_POINT_EVENT_DEFAULT_CONFIGS[type],
    );
    this.state.set(({ config }) => {
      const events = clone(config.events);
      events.push(newEventConfig);
      return {
        config: {
          ...config,
          events,
        },
      };
    });
    this.state.save();
    this.createEventView(newEventConfig);
    this.renderNode();
  }

  private createEventView(
    shopifyEventConfig: shopifyEventEntryPointFragment_config_events,
  ) {
    const eventView = new SHOPIFY_EVENTS_COMPONENTS[shopifyEventConfig.type]({
      node: this._node,
      isEditing: () => this.state.isEditing,
      config: shopifyEventConfig,
      onChange: (updatedEventConfig) => {
        const currentIndex = this.eventsBox.indexOf(eventView);
        this.state.set(({ config }) => {
          const events = clone(config.events);
          events[currentIndex] = updatedEventConfig;
          return {
            config: {
              ...config,
              events,
            },
          };
        });
        this.state.save();
      },
    });
    this.eventsBox.addToLayout(eventView, {
      margin: () => ({
        bottom:
          this.eventsBox.indexOf(eventView) < this.eventsBox.views().length - 1
            ? 16
            : 0,
      }),
    });

    buttonControl(
      eventView,
      this.eventsBox,
      undefined,
      () => {
        const currentIndex = this.eventsBox.indexOf(eventView);
        this.state.set(({ config }) => {
          const events = clone(config.events);
          logFlowPluginEvent(
            PluginType.shopify_event_entry_point,
            'shopify event remove',
            {
              shopifyEventType: events[currentIndex].type,
            },
          );
          events.splice(currentIndex, 1);
          return {
            config: {
              ...config,
              events,
            },
          };
        });
        this.state.save();
        this.eventsBox.removeView(eventView);
        eventView.destroy();
        this.renderNode();
      },
      async (_: any, oldIndex: number, newIndex: number) => {
        this.state.set(({ config }) => ({
          config: {
            ...config,
            events: swapImmutable(config.events, oldIndex, newIndex),
          },
        }));
        logFlowPluginEvent(
          PluginType.shopify_event_entry_point,
          'shopify event change position',
          {
            oldIndex,
            newIndex,
          },
        );
        this.state.save();
        this.renderNode();
      },
      undefined,
      undefined,
      true,
    );
  }

  private createEventsList() {
    const { events } = this.state.data.config;
    events.forEach((shopifyEventConfig) => {
      this.createEventView(shopifyEventConfig);
    });
  }

  pluginDidSet() {
    this.renderNode();
  }

  private validateHasPopupEntryPoint() {
    return this.somePopupEntryPoint
      ? undefined
      : new DontHavePopupEntryPointError(() => {
          const { x, y } = getPixiFieldStrict().viewport.toLocal(
            this._node.blockView.globalPosition() as IPoint,
          );
          createFlowBlock({
            localLocation: {
              x: x - 400,
              y,
            },
            source: `${PluginType.shopify_event_entry_point}${window.i18next.t(
              'EntryPointShopifyEvent-Template--115-error-tooltip',
            )}`,
            subtype: BLOCK_SUBTYPES.entrypoint,
            pluginId: PluginType.popup_entry_point,
          });
        });
  }

  private validateHasEnabledPopupEntryPoint() {
    return !this.somePopupEntryPoint || this.somePopupEntryPoint?.enabled
      ? undefined
      : new DontHaveEnabledPopupEntryPointError(() => {
          const { botId } = getFlowControllerStrict().flow;
          globalHistory.push(
            getTabLink(BotTabs.flows, botId, {
              flowId: this.somePopupEntryPoint!.flow_id || '',
              blockId: this.somePopupEntryPoint!.id,
            }),
          );
        });
  }

  private validateEventsItems() {
    return this.state.data.config.events.length === 0
      ? new EmptyEventsListError()
      : this.eventsBox
          .views()
          .map((eventView) =>
            (eventView as ShopifyEventViewBase).validationError?.(),
          )
          .filter(Boolean)[0];
  }

  private validatePermissions() {
    return !this.state.data.config.events.some(
      ({ type }) => type === ShopifyEventType.order_status_update,
    )
      ? undefined
      : validateShopifyPermissions(
          this.connectedShopifyAccount,
          ShopifyFeatureType.shipment_tracking,
          this._node,
        );
  }

  public validationError() {
    return (
      validateIsPageConnecting(this._node) ||
      validateIsShopifyConnected(!!this.connectedShopifyAccount, this._node) ||
      this.validatePermissions() ||
      this.validateEventsItems() ||
      this.validateHasPopupEntryPoint() ||
      this.validateHasEnabledPopupEntryPoint()
    );
  }

  public hasSignificantChangesInConfig() {
    return this.state.data.config.events.length > 0;
  }

  destroy() {
    this.entryPointsSubscription?.unsubscribe();
    this.state.destroy();
    super.destroy();
  }
}
