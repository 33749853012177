import i18next from 'i18next';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { pluginWidth } from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { Margin } from '../../utility_classes';
import { whatsappTemplatePluginFragment_config_template as Template } from '@components/Plugins/WhatsappTemplatePlugin/@types/whatsappTemplatePluginFragment';

export const WRAPPER_MARGIN = new Margin({ x: 12, y: 12 });

export interface WhatsappTemplateNameProps {
  template: Template | null;
}

export class WhatsappTemplateName extends VLayout {
  constructor({ template }: WhatsappTemplateNameProps) {
    super({ width: pluginWidth - WRAPPER_MARGIN.x });

    const text = new HTMLText({
      fill: HEXColors.baseSecondary,
      fontSize: 12,
      singleLine: false,
      width: 60,
      text: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.templateName',
      ),
    });

    const templateName = new HTMLText({
      fill: HEXColors.baseSecondary,
      width: 180,
      fontSize: 12,
      singleLine: false,
      text: template?.name || '',
    });

    const header = new HLayout();

    this.addToLayout(
      header
        .addToLayout(text, { margin: new Margin({ y: 4, right: 4 }) })
        .addToLayout(templateName, {
          margin: new Margin({ y: 4, x: 8 }),
        }),
      { margin: new Margin({ bottom: 9 }) },
    );
  }
}
