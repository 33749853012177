import { PluginType } from '@components/Plugins/common/PluginTypes';
import { StatsFieldName } from './types';
import { BLOCK_SUBTYPES } from '../../consts';
import { FlowTypes } from '../../types';

export const DEFAULT_STATS: FlowTypes.AggregatedFlowQuery_flowStats_blocks_stats =
  {
    __typename: 'InlineStats',
    seen: 0,
    sent: 0,
    clicked: 0,
    blocked: 0,
    attempted: 0,
    failed: 0,
  };

export const STATS_FIELDS_BY_BLOCK_SUBTYPE: Record<string, StatsFieldName[]> = {
  [BLOCK_SUBTYPES.calendly]: ['clicked'],
};

export const BLOCK_SUBTYPES_WITH_ENABLED_STATS = [
  BLOCK_SUBTYPES.flow_root,
  BLOCK_SUBTYPES.broadcast_flow_root,
  BLOCK_SUBTYPES.entrypoint,
  BLOCK_SUBTYPES.send_message,
  BLOCK_SUBTYPES.comments_autoreply_content,
  BLOCK_SUBTYPES.instagram_comments_autoreply_content,
  BLOCK_SUBTYPES.import_content,
  BLOCK_SUBTYPES.reminder,
  BLOCK_SUBTYPES.receipt,
  BLOCK_SUBTYPES.calendly,
  BLOCK_SUBTYPES.calendly_confirmation,
  BLOCK_SUBTYPES.calendly_reminder,
  BLOCK_SUBTYPES.postPurchase,
  BLOCK_SUBTYPES.visitedProductsReminder,
  BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
  BLOCK_SUBTYPES.back_in_stock_message,
  BLOCK_SUBTYPES.back_in_stock_otn,
  BLOCK_SUBTYPES.delay,
  BLOCK_SUBTYPES.open_ai,
  BLOCK_SUBTYPES.ai_custom,
];

export const STATS_FIELDS_BY_ENTRY_POINT_PLUGIN_ID: Record<
  string,
  StatsFieldName[] | null
> = {
  [PluginType.fb_page_entry_point]: ['clicked'],
  [PluginType.instagram_direct_entry_point]: ['clicked'],
  [PluginType.instagram_comments_autoreply_entry_point]: ['clicked'],
  [PluginType.instagram_story_mention_entry_point]: ['clicked'],
  [PluginType.instagram_story_reply_entry_point]: ['clicked'],
  [PluginType.ref_link_entry_point]: ['clicked'],
  [PluginType.send_to_messenger_entry_point]: ['clicked', 'seen'],
  [PluginType.customer_chat_entry_point]: ['clicked', 'seen'],
  [PluginType.shopify_customer_chat_entry_point]: ['clicked', 'seen'],
  [PluginType.persistent_menu_entry_point]: ['clicked'],
  [PluginType.instagram_persistent_menu_entry_point]: ['clicked'],
  [PluginType.comments_autoreply_entry_point]: ['clicked'],
  [PluginType.ad_comments_autoreply_entry_point]: ['clicked'],
  [PluginType.facebook_shops_entry_point]: ['clicked'],
  [PluginType.external_integration_entry_point]: ['sent'],
  [PluginType.popup_entry_point]: ['clicked', 'seen'],
  [PluginType.shopify_event_entry_point]: ['sent'],
  [PluginType.shopify_back_in_stock]: ['clicked', 'seen'],
  [PluginType.inbound_links_entry_point]: ['clicked'],
  [PluginType.whatsapp_bot_link]: ['clicked'],
  [PluginType.instagram_bot_link_entry_point]: ['clicked'],
  [PluginType.whatsapp_widget_entry_point]: ['clicked', 'seen'],
};
