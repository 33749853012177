import i18next from 'i18next';
import { logFlowPluginEvent } from '../utils/Analytics';
import { VLayout } from '../components/Elements/Layouts';
import { SetAttributeView } from './components/SetAttributeView';
import { PluginTitleLayout } from './components/PluginTitleLayout';
import {
  pluginRemoveTagSvgTexture,
  pluginAddTagSvgTexture,
} from '../assets/textures';
import { tooltips } from './Menu/menu_view';
import { notEmptyString } from './validation';
import { pluginWidth, textCardBackgroundColor } from './plugin_consts';
import { Node } from '../Node';
import { Card } from '../types';
import { HEXColors } from '@ui/_common/colors';

export interface AddRemoveTagConfig {
  title: string;
}

export class AddRemoveTagPluginView extends VLayout {
  TEST_NAME = 'AddRemoveTagPluginView';

  // TODO проверить что не мутируется нигде
  public _node;
  public _textView?: any;

  private _card: Card<AddRemoveTagConfig>;
  private _titleLayout: PluginTitleLayout;
  private _attributeView: SetAttributeView;

  constructor(card: Card<AddRemoveTagConfig>, node: Node, remove: boolean) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._card = card;
    this._node = node;
    const { config } = card;

    this._titleLayout = new PluginTitleLayout(
      false,
      remove
        ? i18next.t('add_tag_plugin-string-1198-remove-user-tag')
        : i18next.t('add_tag_plugin-string-1100-add-user-tag'),
      remove ? pluginRemoveTagSvgTexture : pluginAddTagSvgTexture,
      card,
      remove ? tooltips().remove_tag : tooltips().add_tag,
    );
    this.layout(this._titleLayout, { margin: { top: 15, left: 10 } });

    this._attributeView = new SetAttributeView(
      card,
      {
        width: pluginWidth - 30,
        fill: HEXColors.blue,
        fontStyle: 'semibold',
        caption: i18next.t('add_tag_plugin-string--203-user-tag'),
        attributeOnly: true,
      },
      undefined,
      () => {
        const text = this._attributeView.text().trim();
        if (config.title !== text) {
          config.title = text;
          node.updateCard(card);
          logFlowPluginEvent(card.plugin_id, 'update tag title', {
            blockId: node.id,
            cardId: card.id,
            title: text,
          });
        }
        this._attributeView.text(text);
        this._attributeView.renderElement();
      },
    );
    this._attributeView.text(config.title);
    this.layout(this._titleLayout, { margin: { top: 15, bottom: 10 } });
    this.layout(this._attributeView, { margin: { left: 15, bottom: 15 } });
  }

  public hasSignificantChangesInConfig() {
    return notEmptyString(this._card.config?.title);
  }
}
