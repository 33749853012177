import i18next from 'i18next';
import React from 'react';
import { clone } from 'ramda';
import {
  ServerStorageItemKeys,
  serverStorageItemSet,
} from '@utils/ServerStorage';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import {
  getPluginData,
  updatePluginData,
} from '../../../StatefulPlugin/ApolloState/utils';
import { isApolloBasedPlugin } from '../../plugins.configuration';
import {
  EntryPointEventTrigger,
  entryPointsEmitter,
  EntryPointsEvents,
} from '../events';
import { logFlowEvent, logFlowPluginEvent } from '../../../utils/Analytics';
import { resolveConflict } from '../../../components/Modals';
import { Node } from '../../../Node';
import { Card } from '../../../types';
import { EntryPointPersistentMenuCard } from '../EntryPointPersistentMenu/types';
import { disableEntryPointById } from './disableEntryPointById';
import { getEntryPointCard } from './utils';
import { emitOnbordingEntryPointToggle } from './emitOnbordingEntryPointToggle';

interface ResolveEntryPointConflictDialogProps {
  header: string;
  text: string;
}

export const showResolveEntryPointConflictDialog = (
  node: Node,
  conflictEntryPointId: string | undefined,
  { header, text }: ResolveEntryPointConflictDialogProps,
) => {
  let card = getEntryPointCard(
    node.block.cards,
  ) as EntryPointPersistentMenuCard;
  // TODO add this logic to getEntryPointCard function carefully
  if (isApolloBasedPlugin(card.plugin_id)) {
    const data = getPluginData(card.id);
    card = {
      ...card,
      ...clone(data?.card ?? {}),
    };
  }

  logFlowEvent('entry_point', 'has conflict', {
    plugin_id: card.plugin_id,
  });

  const { blockTitleView } = node.blockView;
  if (isApolloBasedPlugin(card.plugin_id)) {
    updatePluginData<Card>(card.id, (data) => {
      // eslint-disable-next-line no-param-reassign
      data.card.enabled = false;
    });
  }
  card.enabled = false;
  blockTitleView.setHeaderActive(false);
  resolveConflict({
    onSubmit: () => {
      logFlowEvent('entry_point', 'resolve conflict', {
        plugin_id: card.plugin_id,
        accept: true,
      });

      blockTitleView.setHeaderActive(true);
      if (isApolloBasedPlugin(card.plugin_id)) {
        updatePluginData<Card>(card.id, (data) => {
          // eslint-disable-next-line no-param-reassign
          data.card.enabled = true;
          // eslint-disable-next-line no-param-reassign
          data.card.forced = true;
        });
      }
      card.enabled = true;
      card.forced = true;
      card.config = card.config || {};
      node.updateCard(
        card,
        () => {
          if (isApolloBasedPlugin(card.plugin_id)) {
            updatePluginData<Card>(card.id, (data) => {
              // eslint-disable-next-line no-param-reassign
              data.card.forced = false;
            });
          } else {
            card.forced = false;
          }
          if (Array.isArray(node.block?.cards)) {
            node.block.cards.forEach((blockCard) => {
              if (card.id === blockCard.id) {
                blockCard.enabled = true; // eslint-disable-line no-param-reassign
              }
            });
          }
          emitOnbordingEntryPointToggle(true);
          entryPointsEmitter.emit(EntryPointEventTrigger.flowBuilder, {
            data: {
              event: EntryPointsEvents.updateEnabled,
            },
          });
          if (conflictEntryPointId) {
            disableEntryPointById(conflictEntryPointId);
          }
          logFlowPluginEvent(card.plugin_id, 'entry point enabled', {
            blockId: node.id,
            cardId: card.id,
          });
          serverStorageItemSet(ServerStorageItemKeys.isEntryPointEnabled, true);
        },
        (error: any, requestId) => {
          const { status } = error;
          if (isApolloBasedPlugin(card.plugin_id)) {
            updatePluginData<Card>(card.id, (data) => {
              // eslint-disable-next-line no-param-reassign
              data.card.enabled = false;
              // eslint-disable-next-line no-param-reassign
              data.card.forced = false;
            });
          }
          card.forced = false;
          card.enabled = false;
          blockTitleView.setHeaderActive(false);

          if (status === 429) {
            toaster.show({
              type: ServiceMessageType.error,
              payload: { message: Messages.facebookSyncFailed },
            });
          }

          log.error({
            error,
            data: {
              label: 'flow_fb_page_entry_point',
              requestId,
            },
          });
        },
      );
    },
    onDismiss: () => {
      logFlowEvent('entry_point', 'resolve conflict', {
        plugin_id: card.plugin_id,
        accept: false,
      });
    },
    renderActionText: () => i18next.t('common.entryPoints.conflict.cta'),
    renderHeading: () => header,
    renderNoteText: () =>
      React.createElement('div', {
        dangerouslySetInnerHTML: {
          __html: text,
        },
      }),
  });
};
