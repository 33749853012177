import { HLayout } from '../../../../components/Elements/Layouts';
import { Node } from '../../../../Node';
import { HTMLText } from '../../../../components/Elements/Shapes';
import { pluginWidth } from '../../../plugin_consts';
import { removeTooltip, tooltipScaled } from '../../../helpers/TooltipHelpers';
import { ItemStatView } from '../../../kit/ItemStatView/ItemStatView';
import { FullCounterStats } from '../../../../utils/Statistics/types';
import { StatFieldsIds } from '../../../components/BlockStatsView';
import { questionMarkTexture } from '../../../../assets/textures';
import { IconButtonView } from './IconButton';

interface CustomAiRowProps {
  text: string;
  tooltip: string;
  stats: FullCounterStats | undefined;
}

const itemStatViewWidth = 45;

export class CustomAiRow extends HLayout {
  TEST_NAME = 'CustomAiArrow';
  _node: Node;
  _statView?: ItemStatView;
  _textView: HTMLText;
  _iconView: IconButtonView;

  constructor(props: CustomAiRowProps, node: Node) {
    super({
      verticalAlign: 'center',
      height: 36,
      width: pluginWidth,
    });
    const { text } = props;
    this._node = node;
    this._textView = new HTMLText({
      fontSize: 15,
      verticalAlign: 'center',
      align: 'left',
      text,
      height: 36,
      width: pluginWidth - 24 - 18 - (props.stats ? itemStatViewWidth : 0),
    });

    this._iconView = new IconButtonView({
      texture: questionMarkTexture,
    });

    tooltipScaled({
      view: this._iconView,
      text: props.tooltip,
    });

    this.addToLayout(this._textView, {
      margin: () => ({ left: 24 + 4 }),
    });

    if (props.stats) {
      this._statView = new ItemStatView({
        fieldId: StatFieldsIds.delivered,
        stats: props.stats,
        showPercentValue: true,
        hideUniqStat: true,
      });

      this.addToLayout(this._statView, {
        align: 'center',
      });
    }

    this.addToLayout(this._iconView, {
      align: 'center',
      margin: { left: 6 },
    });
  }

  destroy() {
    super.destroy();
    removeTooltip(this._iconView);
  }
}
