import { BlockType, Platform } from '@globals';
import { getFlowController } from '../PixiFieldRepository';
import { getFlowPlatform } from './getFlowPlatform';

export const isWhatsappBroadcastFlow = () => {
  const platform = getFlowPlatform();
  const isBroadcastFlow = getFlowController()?.flow.blocks.some(
    (block) => block.type === BlockType.broadcast,
  );

  return platform === Platform.whatsapp && isBroadcastFlow;
};
