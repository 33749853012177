import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { Level, log } from 'cf-common/src/logger';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { VLayout } from '../../../../components/Elements/Layouts';
import { Node } from '../../../../Node';
import { pluginWidth } from '../../../plugin_consts';
import { Line } from '../../../../components/Elements/Shapes';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../../StatefulPlugin';
import { CustomAiPromptLayout } from './CustomAiPromptLayout';
import { CustomAiArrow } from './CustomAiArrow';
import { logFlowPluginEvent } from '@components/FlowBuilder/utils/Analytics';
import { getFullCounterStats } from '@components/FlowBuilder/views/components/BlockStatsView/utils';
import {
  customAiPluginFragment_config,
  customAiPluginFragment_config_aiConditions,
} from '../../../../../Plugins/CustomAiPlugin/@types/customAiPluginFragment';
import { getPixiFieldStrict } from '../../../../PixiFieldRepository';
import { getPanelWidth } from '../../../../EditorPanel/utils/panelDimensions';
import { PluginType } from '../../../../types';
import { editPluginInEditorPanel } from '../../../../EditorPanel/events';
import { getPromptPreview } from '../../../../EditorPanel/components/plugins/CustomAiPlugin/utils';
import {
  SupportedModels,
  getTokenizerByModel,
} from '../../../../EditorPanel/components/plugins/CustomAiPlugin/components/TokenCounter/useTokenCount';
import { CustomAiRow } from './CustomAiRow';

const getConditionError = (
  condition: customAiPluginFragment_config_aiConditions,
): string => {
  if (!condition.title?.trim()) {
    return i18next.t(
      'modernComponents.FlowBuilder.views.components.CustomAiPlugin.validation.conditionTitle',
    );
  }

  if (!condition.prompt?.trim()) {
    return i18next.t(
      'modernComponents.FlowBuilder.views.components.CustomAiPlugin.validation.conditionPrompt',
    );
  }

  if (!condition.blockId) {
    return i18next.t(
      'modernComponents.FlowBuilder.views.components.CustomAiPlugin.validation.conditionShouldBeConnected',
    );
  }

  return '';
};

export class CustomAiPluginView
  extends VLayout
  implements StatefulPluginDelegate<customAiPluginFragment_config>
{
  TEST_NAME = 'CustomAiPluginView';

  public readonly state: StatefulPlugin<customAiPluginFragment_config>;
  public readonly _node: Node;
  private arrowsContainer: VLayout;
  private promptLayout: CustomAiPromptLayout;
  private tokens = 0;

  constructor(
    state: StatefulPlugin<customAiPluginFragment_config>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        strokeOpacity: 0,
        opacity: 0,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.promptLayout = new CustomAiPromptLayout(
      state,
      node,
      this.openLeftPanel,
    );
    this.addToLayout(this.promptLayout);

    this.arrowsContainer = new VLayout();
    this.addToLayout(this.arrowsContainer, {
      margin: {
        top: 16,
      },
    });
    this.renderConnectors();
    this.calculateTokens();
  }

  private renderConnectors() {
    const lines = Object.values(this._node.outLinks).flat(1);
    lines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      line.gone = true;
    });

    this.arrowsContainer.removeAllViews();

    const conditionStats =
      this.state.data.config.aiConditions.map((condition) => ({
        condition,
        stat: getFullCounterStats(
          `${this.state.data.id}_${condition.statId}_ai_cnd`,
          this._node.block.id,
        ),
      })) || [];

    this.arrowsContainer.addToLayout(
      new CustomAiRow(
        {
          stats: getFullCounterStats(
            `${this.state.data.id}_reply_from_prompt`,
            this._node.block.id,
          ),
          text: i18next.t(
            'modernComponents.FlowBuilder.views.components.CustomAiPlugin.repliesFromPrompt',
          ),
          tooltip: i18next.t(
            'modernComponents.FlowBuilder.views.components.CustomAiPlugin.repliesFromPromptTooltip',
          ),
        },
        this._node,
      ),
    );

    conditionStats.forEach(({ condition, stat }, index) => {
      this.arrowsContainer.addToLayout(
        new Line({
          strokeWidth: 1,
          stroke: HEXColors.greyLight10,
          width: pluginWidth,
        }),
        {
          margin: {
            top: 4,
            bottom: 4,
          },
        },
      );

      this.arrowsContainer.addToLayout(
        new CustomAiArrow(
          {
            error: getConditionError(condition),
            stats: stat,
            connectedBlock: condition.blockId,
            text: condition.title || '',
            onChangeBlock: (blockId) => {
              this.state.setAndSave({
                config: {
                  ...this.state.data.config,
                  aiConditions: this.state.data.config.aiConditions.map(
                    (c, i) => {
                      if (i !== index) {
                        return c;
                      }
                      return {
                        ...c,
                        blockId,
                      };
                    },
                  ),
                },
              });
            },
          },
          this._node,
        ),
      );
    });

    this.renderNode();
  }

  private openLeftPanel() {
    getPixiFieldStrict().fixBlockPosition(
      this._node.blockView,
      getPanelWidth(PluginType.custom_ai),
    );
    editPluginInEditorPanel(this._node.id, this.state.data);
  }

  pluginDidSet() {
    this.renderConnectors();
    this.calculateTokens();
  }

  pluginDidSave() {
    this.renderConnectors();
    this.calculateTokens();
    logFlowPluginEvent('custom_ai', 'update', {
      blockId: this._node.id,
      cardId: this.state.data.id,
      value: this.state.data.config,
    });
  }

  pluginDidSetError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.CustomAiPlugin.failedToSave',
        ),
      },
    });
    log({
      msg: 'Error while saving CustomAi Plugin',
      level: Level.error,
      data: {
        error: error.toString(),
      },
    });
  }

  validationError() {
    if (this.tokens > this.state.data.config.userInputMaxTokens) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.CustomAiPlugin.validation.promptLength',
          { limit: this.state.data.config.userInputMaxTokens },
        ),
        onClick: () => {
          this.emit('click');
        },
      };
    }

    if (
      !this.state.data.config.aiIntents.some((intent) => intent.prompt?.trim())
    ) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.CustomAiPlugin.validation.invalidIntents',
        ),
        onClick: () => {
          this.emit('click');
        },
      };
    }

    if (
      this.state.data.config.aiConditions.some((condition) =>
        getConditionError(condition),
      )
    ) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.views.components.CustomAiPlugin.validation.invalidConditions',
        ),
        onClick: () => {
          this.emit('click');
        },
      };
    }

    return undefined;
  }

  calculateTokens() {
    const text = getPromptPreview({
      conditions: this.state.data.config.aiConditions,
      intents: this.state.data.config.aiIntents,
    });

    const tokenizer = getTokenizerByModel(
      this.state.data.config.aiModel as SupportedModels,
    );
    this.tokens = tokenizer(text.trim());
    this.promptLayout.updateTokens(this.tokens);
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
