import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { toaster, ServiceMessageType } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { Node } from '../../../Node';
import { VLayout } from '../../../components/Elements/Layouts';
import { pluginWidth } from '../../plugin_consts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { inboundLinksEntryPointFragment_config as InboundLinksEntryPointConfig } from '../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import {
  blocksChannelSvgTexture,
  reengageChannelSvgTexture,
  flowsChannelSvgTexture,
} from '../../../assets/textures';
import {
  hasAutomateBlocksToRender,
  hasFlowsToRender,
  hasBroadcastsToRender,
  getI18nInboundLinksKey,
} from '../../../EditorPanel/components/plugins/InboundLinksEntryPoint/helpers';
import { Label } from '../../kit/Label';
import { ButtonView } from '../../kit/ButtonView';
import { LINKS_TO_BE_SHOWN_PER_BLOCK_LIMIT } from './constants';
import { FlowsInboundLinksView } from './components/FlowsInboundLinks';
import { BlocksInboundLinksView } from './components/BlocksInboundLinks';
import { BroadcastsInboundLinksView } from './components/BroadcastsInboundLinks';
import { logFlowEvent } from '../../../utils/Analytics';

export class InboundLinksEntryPointView
  extends VLayout
  implements StatefulPluginDelegate<InboundLinksEntryPointConfig>
{
  TEST_NAME = 'EntryPointInboundLinks';

  public readonly state: StatefulPlugin<InboundLinksEntryPointConfig>;

  private _node: Node;

  private botId: string;
  private flowId: string;

  private flowsInboundLinksTitleView: Label | null = null;
  private flowsInboundLinksView: FlowsInboundLinksView | null = null;
  private blocksInboundLinksTitleView: Label | null = null;
  private blocksInboundLinksView: BlocksInboundLinksView | null = null;
  private broadcastsInboundLinksTitleView: Label | null = null;
  private broadcastsInboundLinksView: BroadcastsInboundLinksView | null = null;
  private seeAllButtonView: ButtonView | null = null;

  constructor(state: StatefulPlugin<InboundLinksEntryPointConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    this._node = node;
    const {
      flow: { botId, id: flowId },
    } = getFlowControllerStrict();
    this.botId = botId;
    this.flowId = flowId;
    this.state = state;
    this.state.setDelegate(this);
    if (getPixiFieldStrict().isViewOnly()) {
      this.renderInboundLinks();
      this.renderNode();
    }
  }

  static getInboundLinksCounted(config: InboundLinksEntryPointConfig) {
    const { blocks, broadcasts, flows } = config;
    const flowsCount = flows.reduce(
      (acc, { blocks }) => acc + (blocks?.length ?? 0),
      0,
    );
    const blocksCount = blocks.reduce(
      (acc, { buttons, quick_replies, redirects }) =>
        acc +
        (buttons?.length ?? 0) +
        (quick_replies?.length ?? 0) +
        (redirects?.length ?? 0),
      0,
    );
    const broadcastsCount = broadcasts.reduce(
      (acc, { block }) =>
        acc +
        ((block &&
          (block.buttons?.length ?? 0) + (block.quick_replies?.length ?? 0)) ??
          0),
      0,
    );

    return {
      flowsCount,
      blocksCount,
      broadcastsCount,
      allCount: blocksCount + flowsCount + broadcastsCount,
    };
  }

  private openEditorPanel = () => {
    getPixiFieldStrict().fixBlockPosition(
      this._node.blockView,
      getPanelWidth(this.state.data.plugin_id),
    );
    editPluginInEditorPanel(this._node.id, this.state.data);
  };

  private renderFlowsInboundLinks() {
    const { flows } = this.state.data.config;
    const shouldRender = hasFlowsToRender(flows);
    if (shouldRender) {
      if (!this.flowsInboundLinksTitleView) {
        this.flowsInboundLinksTitleView = new Label({
          text: i18next.t(getI18nInboundLinksKey('channel.flows')),
          icon: flowsChannelSvgTexture,
        });
        this.addToLayout(this.flowsInboundLinksTitleView);
      }
      const hasRendered = !!this.flowsInboundLinksView;
      this.flowsInboundLinksView = new FlowsInboundLinksView({
        botId: this.botId,
        flowId: this.flowId,
        blockId: this._node.block.id,
        flows,
        onMoreButtonClick: this.openEditorPanel,
      });
      if (!hasRendered) {
        this.addToLayout(this.flowsInboundLinksView, {
          margin: { top: 8 },
        });
      }
    } else {
      this.flowsInboundLinksTitleView?.destroy();
      this.flowsInboundLinksView?.destroy();
    }
  }

  private renderBlocksInboundLinks() {
    const { blocks } = this.state.data.config;
    const shouldRender = hasAutomateBlocksToRender(blocks);
    if (shouldRender) {
      if (!this.blocksInboundLinksTitleView) {
        this.blocksInboundLinksTitleView = new Label({
          text: i18next.t(getI18nInboundLinksKey('channel.blocks')),
          icon: blocksChannelSvgTexture,
        });
        this.addToLayout(this.blocksInboundLinksTitleView, {
          margin: { top: this.flowsInboundLinksView ? 16 : 0 },
        });
      }
      const hasRendered = !!this.blocksInboundLinksView;
      this.blocksInboundLinksView = new BlocksInboundLinksView({
        botId: this.botId,
        flowId: this.flowId,
        blockId: this._node.block.id,
        blocks,
        onMoreButtonClick: this.openEditorPanel,
      });
      if (!hasRendered) {
        this.addToLayout(this.blocksInboundLinksView, {
          margin: { top: 8 },
        });
      }
    } else {
      this.blocksInboundLinksTitleView?.destroy();
      this.blocksInboundLinksView?.destroy();
    }
  }
  private renderBroadcastsInboundLinks() {
    const { broadcasts } = this.state.data.config;
    const shouldRender = hasBroadcastsToRender(broadcasts);
    if (shouldRender) {
      if (!this.broadcastsInboundLinksTitleView) {
        this.broadcastsInboundLinksTitleView = new Label({
          text: i18next.t(getI18nInboundLinksKey('channel.broadcasts')),
          icon: reengageChannelSvgTexture,
        });
        this.addToLayout(this.broadcastsInboundLinksTitleView, {
          margin: {
            top:
              this.flowsInboundLinksView || this.blocksInboundLinksView
                ? 16
                : 0,
          },
        });
      }
      const hasRendered = !!this.broadcastsInboundLinksView;
      this.broadcastsInboundLinksView = new BroadcastsInboundLinksView({
        botId: this.botId,
        flowId: this.flowId,
        blockId: this._node.block.id,
        broadcasts,
        onMoreButtonClick: this.openEditorPanel,
      });
      if (!hasRendered) {
        this.addToLayout(this.broadcastsInboundLinksView, {
          margin: { top: 8 },
        });
      }
    } else {
      this.broadcastsInboundLinksTitleView?.destroy();
      this.broadcastsInboundLinksView?.destroy();
    }
  }

  private renderSeeAllButton() {
    const { blocksCount, broadcastsCount, flowsCount, allCount } =
      InboundLinksEntryPointView.getInboundLinksCounted(this.state.data.config);
    const linksExceededInAnySection = [
      flowsCount,
      blocksCount,
      broadcastsCount,
    ].some((count) => count > LINKS_TO_BE_SHOWN_PER_BLOCK_LIMIT);
    if (linksExceededInAnySection) {
      const hasRendered = !!this.seeAllButtonView;
      this.seeAllButtonView = new ButtonView({
        title: i18next.t(getI18nInboundLinksKey('seeAllButton'), {
          count: allCount,
        }),
        width: pluginWidth,
        onClick: (event: Event) => {
          event.stopPropagation();
          logFlowEvent('inbound links', 'click see all button', {
            botId: this.botId,
            flowId: this.flowId,
            count: allCount,
          });
          this.openEditorPanel();
        },
      });
      if (!hasRendered) {
        this.addToLayout(this.seeAllButtonView, { margin: { top: 16 } });
      }
    } else {
      this.seeAllButtonView?.destroy();
    }
  }

  private renderInboundLinks() {
    this.renderFlowsInboundLinks();
    this.renderBlocksInboundLinks();
    this.renderBroadcastsInboundLinks();
    this.renderSeeAllButton();
  }

  validationError() {
    return false;
  }

  pluginDidSave() {
    this.renderInboundLinks();
    this.renderNode();
  }

  pluginDidSaveError(error: any) {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(getI18nInboundLinksKey('updateError')),
      },
    });
    log.error({ error, msg: 'Error while saving Inbound Links Entry Point' });
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
