import i18next from 'i18next';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { CustomerActionType, WelcomeMessageMediaType } from '@globals';
import { Item } from '../../../../kit/Dropdown';

export enum FacebookMessageTypeIds {
  TEXT_AND_BUTTONS = 'TEXT_AND_BUTTONS',
  TEXT_AND_QR = 'TEXT_AND_QR',
  TEXT_AND_IMAGE_AND_BUTTONS = 'TEXT_AND_IMAGE_AND_BUTTONS',
  TEXT_AND_IMAGE_AND_QR = 'TEXT_AND_IMAGE_AND_QR',
  TEXT_AND_IMAGE = 'TEXT_AND_IMAGE',
  TEXT_AND_VIDEO = 'TEXT_AND_VIDEO',
}

export enum InstagramMessageTypeIds {
  TEXT = 'TEXT',
  TEXT_AND_ICE_BREAKERS = 'TEXT_AND_ICE_BREAKERS',
}

export const MESSAGE_CONFIG = {
  [FacebookMessageTypeIds.TEXT_AND_BUTTONS]: {
    customer_action_type: CustomerActionType.buttons,
    type: WelcomeMessageMediaType.text,
  },
  [FacebookMessageTypeIds.TEXT_AND_QR]: {
    customer_action_type: CustomerActionType.quick_replies,
    type: WelcomeMessageMediaType.text,
  },
  [FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_BUTTONS]: {
    customer_action_type: CustomerActionType.buttons,
    type: WelcomeMessageMediaType.image,
  },
  [FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_QR]: {
    customer_action_type: CustomerActionType.quick_replies,
    type: WelcomeMessageMediaType.image,
  },
  [FacebookMessageTypeIds.TEXT_AND_IMAGE]: {
    customer_action_type: CustomerActionType.quick_replies,
    type: WelcomeMessageMediaType.image,
  },
  [FacebookMessageTypeIds.TEXT_AND_VIDEO]: {
    customer_action_type: CustomerActionType.quick_replies,
    type: WelcomeMessageMediaType.video,
  },
  [InstagramMessageTypeIds.TEXT]: {
    type: WelcomeMessageMediaType.text,
    customer_action_type: CustomerActionType.none,
  },
  [InstagramMessageTypeIds.TEXT_AND_ICE_BREAKERS]: {
    type: WelcomeMessageMediaType.text,
    customer_action_type: CustomerActionType.ice_breakers,
  },
};

export const MESSAGE_TYPES_FACEBOOK_SM: () => Item[] = () => [
  {
    id: FacebookMessageTypeIds.TEXT_AND_BUTTONS,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textButton',
    ),
  },
  {
    id: FacebookMessageTypeIds.TEXT_AND_QR,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textQr',
    ),
  },
  {
    id: FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_BUTTONS,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textImageButton',
    ),
  },
  {
    id: FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_QR,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textImageQr',
    ),
  },
];

export const MESSAGE_TYPES_FACEBOOK_CTM: () => Item[] = () => [
  {
    id: FacebookMessageTypeIds.TEXT_AND_BUTTONS,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textButton',
    ),
  },
  {
    id: FacebookMessageTypeIds.TEXT_AND_QR,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textQr',
    ),
  },
  {
    id: FacebookMessageTypeIds.TEXT_AND_IMAGE,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textImage',
    ),
  },
  {
    id: FacebookMessageTypeIds.TEXT_AND_VIDEO,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.textVideo',
    ),
  },
];

export const MESSAGE_TYPES_INSTAGRAM_CTM: () => Item[] = () => [
  {
    id: InstagramMessageTypeIds.TEXT_AND_ICE_BREAKERS,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.faq',
    ),
  },
];

export const FACEBOOK_MESSAGE_TYPE_PLUGINS = {
  [FacebookMessageTypeIds.TEXT_AND_BUTTONS]: [PluginType.text],
  [FacebookMessageTypeIds.TEXT_AND_QR]: [
    PluginType.text,
    PluginType.quick_reply,
  ],
  [FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_BUTTONS]: [
    PluginType.text,
    PluginType.gallery,
  ],
  [FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_QR]: [
    PluginType.text,
    PluginType.gallery,
    PluginType.quick_reply,
  ],
  [FacebookMessageTypeIds.TEXT_AND_IMAGE]: [
    PluginType.text,
    PluginType.gallery,
    PluginType.quick_reply,
  ],
  [FacebookMessageTypeIds.TEXT_AND_VIDEO]: [
    PluginType.text,
    PluginType.video,
    PluginType.quick_reply,
  ],
};

export const INSTAGRAM_MESSAGE_TYPE_PLUGINS = {
  [InstagramMessageTypeIds.TEXT_AND_ICE_BREAKERS]: [
    PluginType.text,
    PluginType.quick_reply,
  ],
} as Record<InstagramMessageTypeIds, PluginType[]>;
