type Block = {
  id: string;
  card_id: string | null;
  counter_id: string;
  title: string;
};
type Item<Blocks> = { id: string; title: string } & Blocks;

export const renderByLimit = <Blocks = { blocks: Block[] }>(
  limit: number,
  items: Item<Blocks>[],
  renderers: Array<{
    getBlocks: (item: Item<Blocks>) => Block[];
    renderBlock: (itemId: string, block: Block) => void;
  }>,
  renderLabel: (title: string) => void,
  renderShowMore: (showMoreCount: number) => void,
) => {
  let showMoreCount = 0;
  let innerLimit = limit;
  items.forEach((item) => {
    const { id, title } = item;
    if (innerLimit > 0) {
      renderLabel(title);
    }
    renderers.forEach(({ getBlocks, renderBlock }) => {
      const blocks = getBlocks(item);
      const blocksLength = blocks.length;
      if (blocksLength <= innerLimit) {
        blocks.forEach((block) => renderBlock(id, block));
        innerLimit -= blocksLength;
      } else {
        for (let index = 0; index < innerLimit; index++) {
          renderBlock(id, blocks[index]);
        }
        showMoreCount += blocksLength - innerLimit;
        innerLimit = 0;
      }
    });
  });
  if (showMoreCount > 0) {
    renderShowMore(showMoreCount);
  }
};
