import i18next from 'i18next';
import { VLayout } from '../../../components/Elements/Layouts';
import { pluginWidth } from '../../plugin_consts';
import { HTMLText } from '@components/FlowBuilder/components/Elements/Shapes';
import { MediaHeaderStub } from '../WhatsappTemplatePlugin/MediaHeaderStub';
import { whatsappLocationPluginFragment_config as WhatsappLocationPluginConfig } from '../../../../Plugins/WhatsappLocationPlugin/@types/whatsappLocationPluginFragment';
import { WhatsappTemplateComponentFormat } from '@globals';
import { Margin } from '../../utility_classes';
import { ButtonView } from '../../button_view';
import { HEXColors } from '@ui/_common/colors';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { openGoogleMapsAtLocation } from './utils';

export interface WhatsappLocationViewProps {
  logger: PluginLogger;
  getConfig: () => WhatsappLocationPluginConfig;
  onClick(): void;
}

export class WhatsappLocationView extends VLayout {
  private readonly nameText: HTMLText;
  private readonly addressText: HTMLText;
  private readonly props: WhatsappLocationViewProps;

  constructor(props: WhatsappLocationViewProps) {
    super({
      width: pluginWidth - 24,
    });

    this.props = props;

    const mediaHeader = new MediaHeaderStub(
      WhatsappTemplateComponentFormat.LOCATION,
    );
    mediaHeader.on('click', () => {
      this.props.logger.log('open google maps');
      openGoogleMapsAtLocation(props.getConfig());
    });

    this.addToLayout(mediaHeader, {
      margin: new Margin({ x: 12, top: 12, bottom: 4 }),
    });

    const wrapper = new VLayout();

    this.nameText = new HTMLText({
      width: pluginWidth - 24,
      fontSize: 15,
      singleLine: true,
      text: this.props.getConfig().name || '',
    });

    this.addressText = new HTMLText({
      width: pluginWidth - 24,
      fill: HEXColors.greyDark,
      fontSize: 12,
      singleLine: true,
      text: this.props.getConfig().address || '',
    });

    wrapper
      .addToLayout(this.nameText, {
        gone: () => !this.props.getConfig().name,
        margin: new Margin({ bottom: 4 }),
      })
      .addToLayout(this.addressText, {
        gone: () => !this.props.getConfig().address,
      })
      .addToLayout(
        new ButtonView({
          width: pluginWidth - 24,
          title: i18next.t(
            'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.changeLocation',
          ),
          onClick: this.props.onClick,
        }),
        { margin: new Margin({ top: 16 }) },
      );

    this.addToLayout(wrapper, { margin: new Margin({ x: 12, y: 12 }) });
  }

  onBeforeRender() {
    const { name, address } = this.props.getConfig();

    this.addressText.text(address || '');
    this.nameText.text(name || '');
  }
}
