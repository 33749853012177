import { Node } from '@components/FlowBuilder/Node';
import { StatefulPlugin } from '@components/FlowBuilder/StatefulPlugin';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { PermissionGroup } from '@utils/Permissions';
import { validateCommentsPermissions } from '../common/utils/validateCommentsPermissions';
import {
  validateCommentsBlockConnection,
  CommentsConfig as CommentsBlockConfig,
} from '../common/utils/validateCommentsBlockConnection';
import { validatePostSelector } from '../common/utils/validatePostSelector';
import {
  validateCommentsReplies,
  CommentsConfig,
} from '../common/utils/validateCommentsReplies';
import { PostsSelector } from './components/PostsSelector';
import { ReplyToByKeywordsGroups } from '../common/components/ReplyToByKeywordGroups/ReplyToByKeywordsGroups';
import { IFlowBuilderError } from '../common/errors';
import { Validator } from '../common/utils/types';

export interface PluginConfig extends CommentsBlockConfig, CommentsConfig {}

export interface Plugin<T extends PluginConfig> {
  _node: Node;
  logger: PluginLogger;
  state: StatefulPlugin<T>;
  postSelector: PostsSelector<{ id: string }>;
  replyTo: ReplyToByKeywordsGroups;
  replyToForCurrentSubscribers: ReplyToByKeywordsGroups;
}

export class CommentsAutoreplyValidatorDelegate<T extends PluginConfig>
  implements Validator<IFlowBuilderError | undefined>
{
  private delegate!: Plugin<T>;

  constructor(
    private readonly commentsPermissionsErrorMessage: string,
    private readonly postSelectorErrorMessage: string,
    private readonly permissionGroup: PermissionGroup,
  ) {}

  setDelegate(delegate: Plugin<T>) {
    this.delegate = delegate;
  }

  validate() {
    const commentsPermission =
      this.delegate._node.controller.flow.verifiedPermissions?.find(
        ({ id }) => id === this.permissionGroup,
      );

    return (
      validateCommentsPermissions(
        commentsPermission,
        this.delegate.logger,
        this.commentsPermissionsErrorMessage,
      ) ||
      validateCommentsBlockConnection(this.delegate.state.data.config) ||
      validatePostSelector(
        this.delegate.postSelector,
        this.delegate.logger,
        this.postSelectorErrorMessage,
      ) ||
      validateCommentsReplies(
        this.delegate.replyTo,
        this.delegate.replyToForCurrentSubscribers,
        this.delegate.state.data.config,
      )
    );
  }
}
