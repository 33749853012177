import i18next from 'i18next';
import {
  View,
  VLayout,
} from '@components/FlowBuilder/components/Elements/Layouts';
import { getTooltip } from './WhatsappListMessageView';
import { WhatsappListSectionHeaderView } from './WhatsappListSectionHeaderView';
import listImageSrc from './assets/list-tooltip-en.png';
import { WhatsappListSectionItemView } from './WhatsappListSectionItemView';
import { WhatsappListLayoutProps } from './types';
import { Node } from '@components/FlowBuilder/Node';
import { StatefulPlugin } from '@components/FlowBuilder/StatefulPlugin';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { buttonControl } from '../../helpers/ControlsHelpers';
import { PlusButtonView } from '../../plus_button_view';
import { InteractionEvent } from 'pixi.js-legacy';
import { swapImmutable } from '../../utils';
import {
  whatsappListPluginFragment_config,
  whatsappListPluginFragment_config_listSections,
} from '../../../../Plugins/WhatsappListPlugin/@types/whatsappListPluginFragment';
import { DEFAULT_SECTION } from '../../../../Plugins/WhatsappListPlugin/WhatsappListPluginConst';
import { clone } from 'ramda';
import { getButtonsAmount } from './utils';
import { BUTTONS_LIMIT } from './consts';

export class WhatsappListSectionsListView extends VLayout {
  private readonly state: StatefulPlugin<whatsappListPluginFragment_config>;
  private readonly logger: PluginLogger;
  private readonly props: WhatsappListLayoutProps;
  private readonly node: Node;

  private sectionsLayout!: VLayout;

  constructor(
    state: StatefulPlugin<whatsappListPluginFragment_config>,
    logger: PluginLogger,
    props: WhatsappListLayoutProps,
    node: Node,
  ) {
    super(props);

    this.state = state;
    this.logger = logger;
    this.props = props;
    this.node = node;

    this.addToLayout(this.renderView());
  }

  private renderView() {
    const listBlock = new VLayout();

    listBlock
      .addToLayout(this.renderHeaderText(), { margin: { bottom: 16 } })
      .addToLayout(this.renderSections())
      .addToLayout(this.renderPlusButton(), {
        gone: () =>
          getButtonsAmount(this.state.data.config.listSections) >=
            BUTTONS_LIMIT || !this.state.isEditing,
      });

    return listBlock;
  }

  private renderHeaderText() {
    return new WhatsappListSectionHeaderView({
      text: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.list',
      ),
      tooltip: getTooltip(
        listImageSrc,
        i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.listTooltip',
        ),
      ),
    });
  }

  private addControl(section: VLayout) {
    buttonControl(
      section,
      this.sectionsLayout,
      () => {
        this.node.updateLines();
        this.renderNode();
      },
      () => {
        this.state.setAndSave(({ config }) => {
          const index = this.sectionsLayout.indexOf(section);
          const listSections = clone(config.listSections || []);
          listSections.splice(index, 1);

          return {
            config: {
              ...config,
              listSections,
            },
          };
        });

        this.sectionsLayout.removeView(section);
        section.destroy();
        this.renderNode();

        this.logger.log('remove section');
      },
      (_: View, oldIndex, newIndex) => {
        this.state.setAndSave(({ config }) => ({
          config: {
            ...config,
            listSections: swapImmutable(
              config.listSections,
              oldIndex,
              newIndex,
            ),
          },
        }));
        this.renderNode();
        this.logger.log('change section position');
      },
      () => {
        const index = this.sectionsLayout.indexOf(section);
        return {
          x: -40,
          y: index === 0 ? -23 : -10,
        };
      },
      undefined,
      true,
    );
  }

  private renderSections() {
    this.sectionsLayout = new VLayout();
    this.state.data.config.listSections?.forEach((_, index) => {
      const sectionView = this.renderWhatsappListSectionItemView(index);
      this.sectionsLayout.addToLayout(sectionView);
      this.addControl(sectionView);
    });

    return this.sectionsLayout;
  }

  private renderPlusButton() {
    const button = new PlusButtonView(
      i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.addSection',
      ),
      this.props.width,
      36,
    );

    button.on('pointerdown', (e: MouseEvent) => {
      e.stopPropagation();
    });

    button.on('click', (e: InteractionEvent) => {
      e.stopPropagation();
      const sectionConfig: whatsappListPluginFragment_config_listSections =
        clone(DEFAULT_SECTION);
      this.state.setAndSave(({ config }) => ({
        config: {
          ...config,
          listSections: [...(config.listSections || []), sectionConfig],
        },
      }));
      const index = (this.state.data.config.listSections?.length ?? 1) - 1;
      const sectionView = this.renderWhatsappListSectionItemView(index);
      this.addControl(sectionView);
      this.sectionsLayout.addToLayout(sectionView);
      this.renderNode();

      this.logger.log('add section button clicked');
    });

    return button;
  }

  private renderWhatsappListSectionItemView(index: number) {
    return new WhatsappListSectionItemView(
      this.state,
      this.logger,
      {
        width: this.props.width,
        index: (view) => {
          const currentIndex = (view && this.sectionsLayout.indexOf(view)) || 0;
          return currentIndex === -1 ? index : currentIndex;
        },
      },
      this.node,
    );
  }
}
