import { View } from '../components/Elements/Layouts/types';
import { Point } from '../components/Elements/Shapes/types';

import { getPixiFieldStrict } from '../PixiFieldRepository';
import {
  FlowBuilderOverlayEvent,
  overlayEventEmitter,
  OverlayType,
} from '../FlowBuilderOverlay';

interface ViewPositionAbsolutePoint {
  absolute: Point;
}

export type ViewPosition = Point | ViewPositionAbsolutePoint;

function hasViewPositionAbsolutePoint(
  point: ViewPosition,
): point is ViewPositionAbsolutePoint {
  return (point as ViewPositionAbsolutePoint).absolute !== undefined;
}

export interface SingleViewCallbackParams {
  currentOverlayType?: OverlayType;
}

export interface SingleViewSubscriber {
  view: View;
  onShow(params: SingleViewCallbackParams): View | undefined;
  onHide?(params: SingleViewCallbackParams): void;
  onMove?(params: SingleViewCallbackParams): void;
  positionFunction?(): ViewPosition;
}

export class SingleView {
  _addTimeout: number;
  _removeTimeout: number;
  _id: string;

  _subscriber: SingleViewSubscriber | undefined;
  _currentSubscriber: SingleViewSubscriber | undefined;

  _currentOverlayType: OverlayType | undefined;

  _outTimestamp = Date.now();
  _shownView: View | undefined;
  private _lock: boolean = false;

  constructor(id: string, addTimeout: number, removeTimeout: number) {
    this._addTimeout = addTimeout;
    this._removeTimeout = removeTimeout;
    this._id = id;
    overlayEventEmitter.on(FlowBuilderOverlayEvent.mount, ({ overlayType }) => {
      this._currentOverlayType = overlayType;
    });
    overlayEventEmitter.on(FlowBuilderOverlayEvent.unmount, () => {
      this._currentOverlayType = undefined;
    });
  }

  _overFunc(subscriber: SingleViewSubscriber, overTimeout?: number) {
    return () => {
      if (this._lock) {
        return;
      }
      const timeout =
        Date.now() - this._outTimestamp < 200
          ? 200
          : overTimeout || this._addTimeout;
      this._subscriber = subscriber;
      setTimeout(() => {
        this._setView(subscriber);
      }, timeout);
    };
  }

  onOut() {
    return () => {
      if (this._lock) {
        return;
      }
      this._subscriber = undefined;
      this._outTimestamp = Date.now();
      const current = this._currentSubscriber;
      setTimeout(() => {
        this._removeView(current);
      }, this._removeTimeout);
    };
  }

  subscribe(subscriber: SingleViewSubscriber, overTimeout?: number) {
    const { view } = subscriber;
    getPixiFieldStrict()
      .hoverHandler()
      .subscribe({
        view,
        eventId: this._id,
        propagate: false,
        over: this._overFunc(subscriber, overTimeout),
        out: this.onOut(),
      });
  }

  unsubscribe(view: View) {
    getPixiFieldStrict().hoverHandler().unsubscribe(view, this._id);
    if (this._currentSubscriber && this._currentSubscriber.view === view) {
      this.hide();
      delete this._currentSubscriber;
    }
  }

  _setView(subscriber: SingleViewSubscriber) {
    if (this._subscriber === subscriber) {
      if (this._shownView) {
        this._shownView.hide();
        // @ts-ignore  - x y incompatible
        getPixiFieldStrict().panel.removeChild(this._shownView);
        this._shownView = undefined;
      }
      this._currentSubscriber = subscriber;
      this._shownView = subscriber.onShow({
        currentOverlayType: this._currentOverlayType,
      });
      if (this._shownView) {
        // @ts-ignore  - x y incompatible
        getPixiFieldStrict().panel.addChild(this._shownView);
        getPixiFieldStrict()
          .hoverHandler()
          .subscribe({
            view: this._shownView,
            eventId: this._id,
            propagate: false,
            over: () => {
              this._subscriber = this._currentSubscriber;
            },
            out: this.onOut(),
          });

        this.updatePosition();
        this.show();
      }
    }
  }

  _removeView(subscriber?: SingleViewSubscriber) {
    if (this._lock) {
      return;
    }
    if (
      subscriber &&
      subscriber === this._currentSubscriber &&
      this._subscriber !== this._currentSubscriber
    ) {
      if (this._currentSubscriber && this._currentSubscriber.onHide) {
        this._currentSubscriber.onHide({
          currentOverlayType: this._currentOverlayType,
        });
      }
      if (this._shownView) {
        // @ts-ignore  - x y incompatible
        getPixiFieldStrict().panel.removeChild(this._shownView);
        this.hide();
      }
    }
  }

  updatePosition() {
    if (this._lock) {
      return;
    }
    try {
      if (this._currentSubscriber?.positionFunction && this._shownView) {
        const shownView = this._shownView;
        const offset = this._currentSubscriber.positionFunction();
        if (hasViewPositionAbsolutePoint(offset)) {
          shownView.x = offset.absolute.x;
          shownView.y = offset.absolute.y;
        } else {
          const { x, y } = this._currentSubscriber.view.globalPosition();
          if (typeof x === 'number') {
            shownView.x = x + offset.x;
            shownView.y = y + offset.y;
          }
        }
      }
      if (this._currentSubscriber && this._currentSubscriber.onMove) {
        this._currentSubscriber.onMove({
          currentOverlayType: this._currentOverlayType,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  show() {
    if (this._shownView) {
      this._shownView.moveToTop();
      this._shownView.show();
    }
  }

  hide() {
    if (this._shownView) {
      this._shownView.hide();
    }
  }

  moveToTop() {
    if (this._shownView) {
      this._shownView.moveToTop();
    }
  }

  set lock(value: boolean) {
    this._lock = value;
  }

  get lock() {
    return this._lock;
  }
}
