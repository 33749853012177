import { Node } from '../../Node';

export type AllNodes = Record<string, Node>;

export interface NodePosition {
  id: string;
  position: {
    x: number;
    y: number;
  };
}

export function createNodePosition(
  id: string,
  x: number,
  y: number,
): NodePosition {
  return {
    id,
    position: {
      x,
      y,
    },
  };
}

export function isSameNotEmptyPosition(
  positionA: NodePosition['position'] | null | undefined,
  positionB: NodePosition['position'] | null | undefined,
): boolean {
  return Boolean(
    positionA &&
      positionB &&
      positionA.x === positionB.x &&
      positionA.y === positionB.y,
  );
}

export function getNodesWithNoInLinks(allNodes: AllNodes): Node[] {
  return Object.values(allNodes).filter(
    (node) => Object.values(node.inLinks || {}).flat(1).length === 0,
  );
}
