import { TextEditView, TextEditViewProps } from '../../text_edit_view';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import { Node } from '../../../Node';
import { PublicReplyCard } from './PublicReplyPlugin';
import { publicReplyEntryPointEntryPointFragment_config_replies } from '../../../../Plugins/PublicReplyEntryPoint/@types/publicReplyEntryPointEntryPointFragment';
import { MainLayout } from '../../../components/Elements/Layouts';
import {
  inactiveStrokeColor,
  invalidStrokeColor,
  mainStrokeColor,
  pluginWidth,
  textCardBackgroundColor,
} from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { getPredefinedAttributes } from '../../entry-points/common/utils/getPredefinedAttributes';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType } from '@globals';

interface PublicReplyItemProps extends TextEditViewProps {
  config: publicReplyEntryPointEntryPointFragment_config_replies;
  node: Node;
  card: PublicReplyCard;
}

export class PublicReplyItem extends MainLayout {
  TEST_NAME = 'PublicReplyItem';
  config: publicReplyEntryPointEntryPointFragment_config_replies;
  private textView: TextEditView;

  constructor({ config, node, card, ...textProps }: PublicReplyItemProps) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
        stroke: HEXColors.red,
        strokeWidth: () => (config.message?.trim() || card.isEditing ? 0 : 2),
      },
    });

    this.textView = new TextEditView(
      {
        ...textProps,
        text: config.message ?? '',
        fontSize: 15,
        caption: window.i18next.t('PublicReplyItem-string--122-add-text'),
        fill: HEXColors.black,
        width: pluginWidth - 32,
        captionFill: inactiveStrokeColor,
        margin: { left: 12, right: 12, top: 8, bottom: 8 },
        background: {
          cornerRadius: 4,
          fill: () =>
            card.isEditing ? HEXColors.white : textCardBackgroundColor,
          stroke: (view: any) =>
            view.overlayShown
              ? mainStrokeColor
              : config.message?.trim()
              ? mainStrokeColor
              : invalidStrokeColor,
          strokeWidth: () => (card.isEditing ? 1 : 0),
        },
        maxLength: 640,
      },
      undefined,
      (value) => {
        OnboardingEmitter.emit(OnboardingTourEventType.change, {
          element: this.textView.name,
          value,
        });
      },
      () => {
        // eslint-disable-next-line no-param-reassign
        config.message = this.textView.text().trim();
        this.textView.text(config.message);
        node.updateCard(card);
      },
      {
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
        discount: ControlVisibility.show,
      },
      getPredefinedAttributes(node.block),
    );

    this.addToLayout(this.textView, {
      margin: { left: 16, top: 16, bottom: 16 },
    });

    this.config = config;
  }

  startEditing() {
    this.textView.startEditing();
  }
}
