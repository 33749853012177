import { SendToMessengerColor, SendToMessengerSize } from '@globals';

export const BUTTONS_SIZES = {
  [SendToMessengerSize.standard]: {
    padding: '3px 6px 3px 6px',
    fontSize: 11,
    lineHeight: 1.28,
    iconSize: 12,
    iconMargin: 4,
    iconTopMargin: -4,
    picSize: 12,
    buttonSpace: 6,
    borderRadius: 3,
    subFontSize: 10,
    totalHeight: 39,
    lineTop: 29,
  },
  [SendToMessengerSize.large]: {
    padding: '6px 8px 5px 8px',
    fontSize: 13,
    lineHeight: 1.28,
    iconSize: 15,
    iconMargin: 4,
    iconTopMargin: -3,
    picSize: 16,
    buttonSpace: 8,
    borderRadius: 4,
    subFontSize: 11,
    totalHeight: 52,
    lineTop: 33,
  },
  [SendToMessengerSize.xlarge]: {
    padding: '8px 10px 7px',
    fontSize: 15,
    lineHeight: 1.28,
    iconSize: 19,
    iconTopMargin: -2,
    iconMargin: 5,
    picSize: 20,
    buttonSpace: 10,
    borderRadius: 5,
    subFontSize: 12,
    totalHeight: 67,
    lineTop: 36,
  },
};

export const BUTTONS_COLOR = {
  [SendToMessengerColor.blue]: {
    background: {
      fill: 'rgb(13,126,255)',
      stroke: 'rgb(13,126, 255)',
    },
    text: {
      fill: 'rgb(255,255,255)',
    },
  },
  [SendToMessengerColor.white]: {
    background: {
      fill: 'rgb(255,255,255)',
      stroke: 'rgb(227,227,227)',
    },
    text: {
      fill: 'rgb(13,126,255)',
    },
  },
};
