import gql from 'graphql-tag';

export const SHOPIFY_REMINDERS_REVENUE_FRAGMENT = gql`
  fragment shopifyRemindersRevenueFragment on FlowRevenue {
    blocks {
      id
      revenue {
        amount
        currency
      }
    }
  }
`;

export const SHOPIFY_REMINDERS_REVENUE_QUERY = gql`
  query ShopifyRemindersRevenueQuery($flowId: ID!) {
    shopifyRemindersRevenue(flowId: $flowId) {
      ...shopifyRemindersRevenueFragment
    }
  }
  ${SHOPIFY_REMINDERS_REVENUE_FRAGMENT}
`;
