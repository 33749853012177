import { HEXColors } from '@ui/_common/colors';
import { Texture } from 'pixi.js-legacy';
import {
  HLayout,
  LayoutProps,
} from '../../../../../components/Elements/Layouts';
import {
  HTMLText,
  TextureShape,
} from '../../../../../components/Elements/Shapes';
import { buttonWidth } from '../../../../plugin_consts';

interface ConnectButtonProps extends LayoutProps {
  texture: Texture;
  text: string;
}

export class ConnectButton extends HLayout {
  constructor({ texture, text, ...props }: ConnectButtonProps) {
    super({
      width: buttonWidth,
      height: 36,
      background: {
        fill: HEXColors.blue,
        cornerRadius: 4,
      },
      ...props,
    });

    const accountConnectTexture = new TextureShape({
      texture,
      width: 24,
      height: 24,
    });
    const accountConnectText = new HTMLText({
      fill: HEXColors.white,
      text,
      fontSize: 15,
    });

    this.addToLayout(accountConnectTexture, {
      margin: { left: 9 },
      align: 'center',
    }).addToLayout(accountConnectText, {
      margin: { left: 9 },
      align: 'center',
    });
  }
}
