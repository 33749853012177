import {
  FlowBuilderOverlayEvent,
  OverlayComponentEvent,
  overlayEventEmitter,
  OverlayType,
} from '../../FlowBuilderOverlay';
import { Point } from '../../components/Elements/Shapes';

export const showTestOverlay = (
  blockId: string,
  { x, y }: Point,
  onDoneCallback: () => void,
) => {
  overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
    overlayType: OverlayType.testInFacebook,
    getOverlayOptions: () => ({ blockId }),
  });
  overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
    position: {
      x: x && Math.round(x + 1), // prevent mouseup event on overlay
      y: y && Math.round(y),
    },
  });
  overlayEventEmitter.emit(FlowBuilderOverlayEvent.show);

  const onDone = () => {
    window.removeEventListener('mousedown', onDone);
    overlayEventEmitter.off(OverlayComponentEvent.done, onDone);
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
    onDoneCallback();
  };

  overlayEventEmitter.on(OverlayComponentEvent.done, onDone);

  setTimeout(() => {
    window.addEventListener('mousedown', onDone);
  });
};
