import { Platform } from '@globals';

export const platformToString = (platform: Platform | undefined) => {
  switch (platform) {
    case Platform.instagram:
      return 'Instagram';
    case Platform.facebook:
      return 'Facebook';
    default:
      throw new Error(`Unexpected platform ${platform}`);
  }
};
