import i18next from 'i18next';

import { sendEvent } from '@utils/Analytics';
import { isParamsMatchesUrlParams } from '@utils/queryParams';

import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
} from '@components/ConnectPageDialog';
import {
  ConnectInstagramVariant,
  ConnectInstagramRestorationQueryField,
  getInstagramConnectionVariant,
} from '@components/dialogs/ConnectInstagramDialog';

import { getPageInfoFromNode } from '../../../utils';
import { Node } from '../../../../Node';
import { getFlowController } from '../../../../PixiFieldRepository';
import { connectInstagram } from './connectInstagram';

type FbRestorationParams = {
  [ConnectPageField.origin]: ConnectPageOrigin;
  [ConnectPageField.blockId]: string;
};
interface ConnectFacebookArgs {
  botId: string;
  fbRestorationParams: FbRestorationParams;
  blockId: string;
  connectVariant: ConnectInstagramVariant;
  onInstagramConnected: () => void;
}
const connectFacebook = ({
  botId,
  fbRestorationParams,
  blockId,
  connectVariant,
  onInstagramConnected,
}: ConnectFacebookArgs) => {
  connectPage({
    botId,
    urlParams: fbRestorationParams,
    onPageConnected({ pageId, close }) {
      close?.();
      if (pageId) {
        connectInstagram(connectVariant, blockId, onInstagramConnected);
      }
    },
  });
};

export const validateIsInstagramPageConnected = (node: Node) => {
  const pageInfo = getPageInfoFromNode(node);
  const { flow } = node.controller;
  const { bot, user } = flow;
  const hasInstagramPermissions =
    user.fbPermissions.hasInstagramMessagingPermissions;
  const {
    connected: instagramConnected,
    business_account: instagramBusinessAccount,
  } = bot?.instagram ?? {};
  const blockId = node.block.id;

  const igRestorationParams = {
    [ConnectInstagramRestorationQueryField.name]: 'flowBuilder',
    [ConnectInstagramRestorationQueryField.blockId]: blockId,
  };
  const fbRestorationParams: FbRestorationParams = {
    [ConnectPageField.origin]: ConnectPageOrigin.instagramEpConnect,
    [ConnectPageField.blockId]: blockId,
  };
  const connectVariant = getInstagramConnectionVariant(
    !!instagramConnected,
    !!instagramBusinessAccount,
    !!hasInstagramPermissions,
  );

  const sendTooltipEvent = (action: string) => {
    sendEvent({
      category: 'instagram',
      action,
      label: 'card',
      propertyBag: {
        botId: bot.id,
        flowId: flow.id,
      },
    });
  };

  const reloadFlow = async () => {
    await getFlowController()?.fetchFlow();
    node.renderNode();
  };

  if (isParamsMatchesUrlParams(igRestorationParams)) {
    connectInstagram(connectVariant, blockId, reloadFlow);
    return undefined;
  }
  if (isParamsMatchesUrlParams(fbRestorationParams)) {
    connectFacebook({
      botId: bot.id,
      fbRestorationParams,
      blockId,
      connectVariant,
      onInstagramConnected: reloadFlow,
    });
    return undefined;
  }

  if (!pageInfo) {
    return {
      message: `${i18next.t(
        'common.Instagram.flowBuilder.tooltipFbAndIg',
      )}<br /><br /><span style="text-decoration: underline;">${i18next.t(
        'common.Instagram.flowBuilder.connect',
      )}</a>`,
      onClick: () => {
        sendTooltipEvent('click connect now');
        connectFacebook({
          botId: bot.id,
          fbRestorationParams,
          blockId,
          connectVariant,
          onInstagramConnected: reloadFlow,
        });
      },
      isBlockError: true,
    };
  }

  if (
    connectVariant === ConnectInstagramVariant.full ||
    connectVariant === ConnectInstagramVariant.known
  ) {
    return {
      message: `${i18next.t(
        'common.Instagram.flowBuilder.tooltipIg',
      )}<br /><br /><span style="text-decoration: underline;">${i18next.t(
        'common.Instagram.flowBuilder.connect',
      )}</a>`,
      onClick: () => {
        sendTooltipEvent('click connect now');
        connectInstagram(connectVariant, blockId, reloadFlow);
      },
      isBlockError: true,
    };
  }

  if (connectVariant === ConnectInstagramVariant.refresh) {
    return {
      message: `${i18next.t(
        'common.Instagram.flowBuilder.tooltipUpdatePermissions',
      )}<br /><br /><span style="text-decoration: underline;">${i18next.t(
        'common.Instagram.flowBuilder.updatePermissions',
      )}</a>`,
      onClick: () => {
        sendTooltipEvent('click update permissions');
        connectInstagram(connectVariant, blockId, reloadFlow);
      },
      isBlockError: true,
    };
  }

  return undefined;
};
