import { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { useAddFlowBlock, ENTRY_POINT_SUBTYPE } from '@utils/Data/Flow';
import { useToaster } from '@ui/Toaster';
import { useCurrentBotId } from '@utils/Routing';
import { onRenderedFBRouteCallbackService } from '@services/RouteCallbackService/instances/onRenderedFBRouteCallbackService';
import { getDefaultPlugins } from '../views/utils';

function getPathToEP(botId: string, flowId: string, epId: string): string {
  return `/bot/${botId}/flows/${flowId}?blockId=${epId}`;
}

interface useActivateTileParams {
  pluginType: PluginType;
  forceActivateOnSetUp?: boolean;
}

interface useActivateTileReturnParams {
  showFlowSelector: boolean;
  isAddFlowInProgress: boolean;
  onSelectFlowForSetUp(flowId: string): void;
  onCloseFlowSelector(): void;
  onSetUp(): void;
}

export const useSetupEntryPoint = ({
  pluginType,
  forceActivateOnSetUp,
}: useActivateTileParams): useActivateTileReturnParams => {
  const botId = useCurrentBotId() || '';
  const history = useHistory();

  const [savedFlowId, setSavedFlowId] = useState<string | null>(null);
  const [showFlowSelector, setShowFlowSelector] = useState(false);
  const [isAddFlowInProgress, setIsAddFlowInProgress] = useState(false);

  const { addToaster } = useToaster();
  const { addFlowBlock } = useAddFlowBlock({
    onCompleted: ({ addFlowBlock }) => {
      if (addFlowBlock?.id && savedFlowId) {
        const afterRenderCallback = () => {
          addToaster({
            type: 'info',
            content: window.i18next.t(
              'useSetupEntryPoint-string-1251-entry-point-added-set-it-up-then-turn-it-on-to-activate',
            ),
            timeout: 3000,
          });
        };
        const pathToEP = getPathToEP(botId, savedFlowId, addFlowBlock.id);
        const newPathToEP = onRenderedFBRouteCallbackService.registerHandlerForRoute(
          afterRenderCallback,
          pathToEP,
        );
        history.push(newPathToEP);
      }
    },
    forceActivate: forceActivateOnSetUp,
  });

  const onSetUp = useCallback(() => {
    setShowFlowSelector(true);
  }, [setShowFlowSelector]);

  const onCloseFlowSelector = useCallback(() => {
    setShowFlowSelector(false);
  }, [setShowFlowSelector]);

  const onSelectFlowForSetUp = useCallback(
    (flowId: string) => {
      setIsAddFlowInProgress(true);
      setSavedFlowId(flowId);
      addFlowBlock({
        flowId,
        subtype: ENTRY_POINT_SUBTYPE,
        pluginIds: [pluginType, ...getDefaultPlugins(pluginType)],
      });
    },
    [addFlowBlock, pluginType],
  );

  return {
    showFlowSelector,
    isAddFlowInProgress,
    onSetUp,
    onSelectFlowForSetUp,
    onCloseFlowSelector,
  };
};
