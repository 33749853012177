import { HEXColors } from '@ui/_common/colors';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import throttle from 'lodash-es/throttle';
import { Texture, InteractionEvent } from 'pixi.js-legacy';
import i18next from 'i18next';
import {
  GestureNavigation,
  isTrackpadEnabled,
} from 'cf-common/src/gestureNavigation';
import { logFlowEvent } from '../utils/Analytics';
import { removeTooltip, tooltip } from './helpers/TooltipHelpers';
import { MainLayout, VLayout } from '../components/Elements/Layouts';
import type { CornerRadiusAdv } from '../components/Elements/Layouts';
import { toggleGesturesDialog } from '../components';
import { TextureShape } from '../components/Elements/Shapes';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../PixiFieldRepository';
import { createLineMenu } from './Menu/createLineMenu';
import {
  activeWizardSvgTexture,
  addBlockSvgTexture,
  fitSizeSvgTexture,
  inactiveWizardSvgTexture,
  organizeSvgTexture,
  zoomInSvgTexture,
  zoomOutSvgTexture,
  handBlackSvgTexture,
} from '../assets/textures';
import { CreateMenuViewOverlay } from './menu_view_overlay';
import { FlowBuilderEvent, flowBuilderEventEmitter } from './events';
import { inactiveStrokeColor } from './plugin_consts';
import { Feature, ZOOM_ANIMATION_TIME } from '../consts';
import { createFlowBlock } from './helpers/createFlowBlock';
import { IMenuItem } from './Menu/types';
import { MENU_MIN_WIDTH } from '../FlowBuilderOverlay/overlays/Menu/consts';
import { MAX_SCALE, MIN_SCALE } from '../PixiField';
import { Messages, toaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';

interface buttonLayoutProps {
  active: boolean;
  size: number;
  corners: CornerRadiusAdv;
}

export const DEFAULT_BUTTON_LAYOUT_PROPS: buttonLayoutProps = {
  active: false,
  size: 38,
  corners: {
    bottomLeft: 38 * 0.5,
    bottomRight: 38 * 0.5,
    topLeft: 38 * 0.5,
    topRight: 38 * 0.5,
  },
};

function buttonLayout(
  img: Texture,
  testName = window.i18next.t('main_flow_controls-string--372-unknown-control'),
  props: Partial<buttonLayoutProps> = {},
): MainLayout {
  const { size, active, corners } = {
    ...DEFAULT_BUTTON_LAYOUT_PROPS,
    ...props,
  };
  const view = new MainLayout({
    width: size,
    height: size,
    cursor: { in: 'pointer' },
    background: {
      corners,
      fill: active ? HEXColors.blue : HEXColors.white,
      stroke: inactiveStrokeColor,
      strokeWidth: active ? 0 : 1,
      onhover: {
        fill: active ? HEXColors.blueLight : HEXColors.greyLight20,
      },
    },
  }).layout(
    new TextureShape({
      width: size,
      height: size,
      texture: img,
    }),
  );

  view.TEST_NAME = testName;

  return view;
}

enum WizardStatus {
  hidden = 'hidden',
  active = 'active',
  inactive = 'inactive',
}

export class MainControlView extends VLayout {
  TEST_NAME = 'MainControlView';

  private wizardStatus: WizardStatus = WizardStatus.hidden;
  private _viewportClicked = false;
  private menuOverlay: CreateMenuViewOverlay<any> | null = null;

  constructor() {
    super({
      width: 36,
    });
    const pixiField = getPixiFieldStrict();
    const inactiveWizard = buttonLayout(
      inactiveWizardSvgTexture,
      'InactiveWizardButtonView',
    );
    const activeWizard = buttonLayout(
      activeWizardSvgTexture,
      'ActiveWizardButtonView',
      { active: true },
    );
    const addBlock = buttonLayout(addBlockSvgTexture, 'AddBlockButtonView', {
      active: true,
      size: 46,
      corners: {
        topLeft: 46 * 0.5,
        topRight: 46 * 0.5,
        bottomRight: 46 * 0.5,
        bottomLeft: 46 * 0.5,
      },
    });
    const organizeBlocks = buttonLayout(
      organizeSvgTexture,
      'OrganizeBlocksButtonView',
    );
    const fitSize = buttonLayout(fitSizeSvgTexture, 'FitSizeButtonView');
    const zoomIn = buttonLayout(zoomInSvgTexture, 'ZoomInButtonView', {
      corners: {
        bottomLeft: 0,
        bottomRight: 0,
        topLeft: 4,
        topRight: 4,
      },
    });
    const zoomOut = buttonLayout(zoomOutSvgTexture, 'ZoomOutButtonView', {
      corners: {
        bottomLeft: 4,
        bottomRight: 4,
        topLeft: 0,
        topRight: 0,
      },
    });
    zoomIn.setDisabled(pixiField.getScale() >= MAX_SCALE);
    zoomOut.setDisabled(pixiField.getScale() <= MIN_SCALE);

    pixiField.viewport.on('zoomed-end', () => {
      zoomIn.setDisabled(pixiField.getScale() >= MAX_SCALE);
      zoomOut.setDisabled(pixiField.getScale() <= MIN_SCALE);
    });
    const gestureToggle = buttonLayout(
      handBlackSvgTexture,
      'GestureToggleButtonView',
      {
        corners: {
          bottomLeft: 4,
          bottomRight: 4,
          topLeft: 4,
          topRight: 4,
        },
      },
    );
    this.layout(inactiveWizard, {
      gone: () => this.wizardStatus !== WizardStatus.inactive,
    });
    this.layout(activeWizard, {
      gone: () => this.wizardStatus !== WizardStatus.active,
    });
    const flowIsEditable = !pixiField.isViewOnly();
    this.layout(addBlock, {
      margin: { top: 10, left: -4 },
      visible: flowIsEditable,
    });
    this.layout(organizeBlocks, {
      margin: { top: 10 },
      visible: flowIsEditable,
    });
    this.layout(fitSize, { margin: { top: 10 } });

    this.layout(zoomIn, { margin: { top: 10 } });
    this.layout(zoomOut);
    this.layout(gestureToggle, {
      margin: { top: 8 },
      visible: pixiField.availableFeatures.includes(Feature.gestures),
    });

    if (flowIsEditable) {
      const handleShowBlockMenu = (e: InteractionEvent, name: string) => {
        this.showBlockMenu(
          e.data.global,
          getPixiFieldStrict().viewport.toLocal(e.data.global),
          name,
          () => {
            flowBuilderEventEmitter.emit(FlowBuilderEvent.rightClickMenuClosed);
          },
          () => {
            flowBuilderEventEmitter.emit(
              FlowBuilderEvent.rightClickMenuBlockAdded,
            );
          },
        );
        flowBuilderEventEmitter.emit(FlowBuilderEvent.rightClickMenuShown);
      };
      getPixiFieldStrict().viewport.on('rightclick', (e: InteractionEvent) => {
        handleShowBlockMenu(e, 'right click');
      });
      getPixiFieldStrict().viewport.on('drag-start', () => {
        this._viewportClicked = false;
      });
      getPixiFieldStrict().viewport.on('pointertap', (e: InteractionEvent) => {
        if (this._viewportClicked) {
          this._viewportClicked = false;
          setTimeout(() => {
            handleShowBlockMenu(e, 'double tap');
          });
        } else {
          this._viewportClicked = true;
          const menu = this;
          setTimeout(() => {
            menu._viewportClicked = false;
          }, 200);
        }
      });
    }

    zoomIn.on('pointertap', () => {
      this.zoomIn();
    });
    zoomOut.on('pointertap', () => {
      this.zoomOut();
    });

    addBlock.on('click', () => {
      const globalLocation = {
        x: getPixiFieldStrict().screen.width / 2,
        y: getPixiFieldStrict().screen.height / 2,
      };
      const localLocation = getPixiFieldStrict().viewport.toLocal(
        // @ts-ignore
        globalLocation,
      );
      const menuPosition = {
        x: addBlock.globalPosition().x - 265,
        y: addBlock.globalPosition().y,
      };
      removeTooltip(addBlock);
      tooltip()?.hide();
      // @ts-ignore
      this.showBlockMenu(menuPosition, localLocation, 'right panel');
    });
    tooltip(
      addBlock,
      i18next.t(
        'modernComponents.FlowBuilder.views.main_flow_controls.addBlock',
      ),
    );

    organizeBlocks.on('pointertap', () => {
      this.organize();
    });
    fitSize.on('pointertap', () => {
      this.fitSize();
    });
    inactiveWizard.on('pointertap', () => {
      this.toggleWizard();
    });
    activeWizard.on('pointertap', () => {
      this.toggleWizard();
    });

    gestureToggle.on('pointertap', () => {
      this.onGestureToggleClick();
    });

    tooltip(zoomIn, window.i18next.t('main_flow_controls-string-1554-zoom-in'));
    tooltip(
      zoomOut,
      window.i18next.t('main_flow_controls-string-9473-zoom-out'),
    );
    tooltip(
      fitSize,
      window.i18next.t('main_flow_controls-string-1374-fit-to-screen'),
    );
    tooltip(
      organizeBlocks,
      i18next.t(
        'modernComponents.FlowBuilder.views.main_flow_controls.organizeBlocks',
      ),
    );
    tooltip(
      inactiveWizard,
      i18next.t(
        'modernComponents.FlowBuilder.views.main_flow_controls.showWizard',
      ),
    );
    tooltip(
      gestureToggle,
      i18next.t(
        'modernComponents.FlowBuilder.views.main_flow_controls.gestureToggle',
      ),
    );
  }

  toggleWizard = () => {
    logFlowEvent(undefined, 'toggle wizard', { prevStatus: this.wizardStatus });
    flowBuilderEventEmitter.emit(FlowBuilderEvent.wizardButtonToggled);
  };

  fitSize = throttle(() => {
    getFlowControllerStrict().fitScreen();
    logFlowEvent(undefined, 'fit screen');
  }, 2000);

  organize = throttle(() => {
    getFlowControllerStrict()
      .organizeAllBlocks()
      .catch((error) => {
        toaster.error({ payload: { message: Messages.somethingWentWrong } });
        log.error({ error });
      });
    logFlowEvent(undefined, 'organize blocks');
  }, 1000);

  onGestureToggleClick = throttle(() => {
    toggleGesturesDialog({
      initialGesturesType: isTrackpadEnabled()
        ? GestureNavigation.trackpad
        : GestureNavigation.mouse,
      onSubmit: (chosenType) => {
        getPixiFieldStrict().setGestureNavigationType(chosenType);
        logFlowEvent(undefined, 'toggle gestures modal submit', {
          chosenType,
        });
      },
      onDismiss: () => {
        logFlowEvent(undefined, 'toggle gestures modal dismiss');
      },
    });
    logFlowEvent(undefined, 'toggle gestures modal');
  }, 1000);

  zoomOut = () => {
    const {
      viewport,
      app: { ticker },
    } = getPixiFieldStrict();
    viewport.animate({
      time: ZOOM_ANIMATION_TIME,
      scale: viewport.scale.x * 0.75,
    });
    ticker.update();
    logFlowEvent(undefined, 'zoom out');
  };

  zoomIn = () => {
    const {
      viewport,
      app: { ticker },
    } = getPixiFieldStrict();
    viewport.animate({
      time: ZOOM_ANIMATION_TIME,
      scale: viewport.scale.x * 1.25,
    });
    ticker.update();
    logFlowEvent(undefined, 'zoom in');
  };

  showBlockMenu(
    menuPosition: any,
    localLocation: any,
    source: any,
    onClose: any,
    onCreate: any,
  ) {
    logFlowEvent('flows', 'show block add menu', { source });
    const {
      flow: {
        user: {
          features: {
            entry_points: isShowEntryPoints,
            comments_autoreply_entry_point: isShowCommentsAutoreplyEntryPoint,
          },
        },
        botFeatures: { enable_whatsapp: isWhatsappEnabled },
      },
    } = getFlowControllerStrict();
    this.menuOverlay?.destroy();
    this.menuOverlay = new CreateMenuViewOverlay({
      onChoose: (item) => {
        const { id, subtype, shortcut, defaultPluginId } = item as IMenuItem;
        const pluginId =
          defaultPluginId ||
          ((subtype === id || shortcut ? undefined : id) as PluginType);

        createFlowBlock({
          localLocation,
          subtype,
          pluginId,
          source,
          onCreate,
          cards: shortcut?.cards,
        });
      },
      items: createLineMenu({
        isShowEntryPoints,
        isShowScheduleCalendlyEvent: true,
        isShowCommentsAutoreplyEntryPoint,
        isWhatsappEnabled,
      }),
      onClose,
      style: { minWidth: MENU_MIN_WIDTH, paddingBottom: 0 },
      searchable: true,
      isPlanLimitMessage: true,
    });
    this.menuOverlay.showOn(menuPosition);
  }

  showWizardButton = (active: boolean) => {
    this.wizardStatus = active ? WizardStatus.active : WizardStatus.inactive;
  };

  hideWizardButton = () => {
    this.wizardStatus = WizardStatus.hidden;
  };
}
