import i18next from 'i18next';
import { HLayout, VLayout } from '../components/Elements/Layouts';
import { SetAttributeView } from './components/SetAttributeView';
import { PluginTitleLayout } from './components/PluginTitleLayout';
import { tooltips } from './Menu/menu_view';
import { pluginOpenUrlSvgTexture } from '../assets/textures';

import { DropdownFieldView } from './dropdown_field_view';
import { ControlVisibility } from '../FlowBuilderOverlay';
import { isValidUrl } from '../../../utils/UrlUtils';
import { CreateMenuViewOverlay } from './menu_view_overlay';
import { HTMLText } from '../components/Elements/Shapes';
import { pluginWidth, textCardBackgroundColor } from './plugin_consts';
import { Node } from '../Node';
import { Card } from '../types';

export interface OpenUrlConfig {
  url: string;
  percent: number;
}

export class OpenUrlPluginView extends VLayout {
  TEST_NAME = 'OpenUrlPluginView';

  public _node: Node;
  public _card: Card<OpenUrlConfig>;

  private _config: OpenUrlConfig;
  private _titleLayout: PluginTitleLayout;
  private _urlView: SetAttributeView;
  private _percentView: DropdownFieldView;

  constructor(card: Card<OpenUrlConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._card = card;
    this._node = node;
    const { config } = card;
    this._config = config;
    this._titleLayout = new PluginTitleLayout(
      false,
      i18next.t('open_url-string--441-open-url'),
      pluginOpenUrlSvgTexture,
      card,
      tooltips().open_url,
    );
    this.layout(this._titleLayout, { margin: { top: 15, left: 10 } });

    this._urlView = new SetAttributeView(
      card,
      {
        width: pluginWidth - 30,
        caption: 'URL',
        isValid: () => isValidUrl(config.url),
      },
      (text) => {
        config.url = text;
      },
      () => node.updateCard(card),
      undefined,
      {
        emoji: ControlVisibility.hide,
        discount: ControlVisibility.hide,
      },
    );
    this._urlView.text(config.url);
    this.layout(this._titleLayout, { margin: { top: 15, bottom: 10 } });
    this.layout(this._urlView, { margin: { left: 15, bottom: 10 } });

    this._percentView = new DropdownFieldView({
      width: 80,
      caption: i18next.t('open_url-string-9857-percent'),
      card,
    });

    this._percentView.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._percentView.on('click', (e) => {
      e.stopPropagation();
      new CreateMenuViewOverlay({
        onChoose: (item) => {
          config.percent = item.percent;
          node.updateCard(card);
          this.renderNode();
        },
        items: [
          {
            title: '100%',
            percent: 100,
          },
          {
            title: '70%',
            percent: 70,
          },
          {
            title: '40%',
            percent: 40,
          },
        ],
      }).showOn(e.data.global);
    });

    this.layout(
      new HLayout()
        .layout(
          new HTMLText({
            text: i18next.t('open_url-string-1999-in-app-browser-window'),
            fontSize: 15,
            verticalAlign: 'center',
            height: 35,
          }),
        )
        .layout(this._percentView, { margin: { left: 10 } }),
      { margin: { left: 15, bottom: 15 }, gone: () => !card.isEditing },
    );
  }

  onBeforeRender() {
    const { percent } = this._config;
    this._percentView.text(percent ? `${percent}%` : '100%');
  }
}
