import { HLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { AlertView } from '../../entry-points/common/utils/validationProps';
import { HTMLText } from '../../../components/Elements/Shapes';
import { LineStartView } from '../../components/Line/LineStartView';
import { pluginWidth } from '../../plugin_consts';
import {
  commentsAutoReplyLineMenu,
  createLineMenu,
} from '../../Menu/createLineMenu';
import { tooltipScaled } from '../../helpers/TooltipHelpers';
import { ItemStatView } from '../../kit/ItemStatView/ItemStatView';
import { FullCounterStats } from '../../../utils/Statistics/types';
import { BlockContextType } from '@globals';

interface OpenAiFooterStartArrowProps {
  tooltipText: string;
  text: string;
  stats: FullCounterStats | undefined;
  connectedBlock: string | null;
  onChangeBlock: (blockId: string | null) => void;
  mandatory?: boolean;
}

const itemStatViewWidth = 45;

export class OpenAiFooterStartArrow extends HLayout {
  TEST_NAME = 'OpenAiFooterStartArrow';
  _node: Node;
  _alertView: AlertView;
  _statView?: ItemStatView;
  _textView: HTMLText;
  _lineStartView: LineStartView;

  blockId: string | null;

  constructor(props: OpenAiFooterStartArrowProps, node: Node) {
    super({
      verticalAlign: 'center',
      height: 36,
      width: pluginWidth,
    });
    const { text, connectedBlock, onChangeBlock, mandatory = true } = props;
    this._node = node;
    this.blockId = connectedBlock;
    this._alertView = new AlertView();
    this._textView = new HTMLText({
      fontSize: 15,
      verticalAlign: 'center',
      align: 'left',
      text,
      height: 36,
      width: pluginWidth - 24 - 18 - (props.stats ? itemStatViewWidth : 0),
    });

    const blockIsInCommentsContext =
      node.block.context_type === BlockContextType.comments;

    const isValid = () => (mandatory ? !!this.blockId : true);

    this._lineStartView = new LineStartView({
      from: this,
      node,
      mandatory,
      offset: 20,
      items: blockIsInCommentsContext
        ? commentsAutoReplyLineMenu
        : createLineMenu({ isShowConnectToExistingBlock: true }),
      searchable: !blockIsInCommentsContext,
      onConnected: (blockView) => {
        const blockId = blockView.id();
        this.blockId = blockId;
        onChangeBlock(blockId);
      },
      onRemoved: () => {
        this.blockId = null;
        onChangeBlock(null);
      },
    });

    this.addToLayout(this._alertView, {
      gone: isValid,
      margin: {
        right: 4,
        top: 6,
      },
    });
    tooltipScaled({
      view: this._alertView,
      text: props.tooltipText,
      position: 'left',
    });

    this.addToLayout(this._textView, {
      margin: () => (isValid() ? { left: 24 + 4 } : {}),
    });

    if (props.stats) {
      this._statView = new ItemStatView({
        stats: props.stats,
        showPercentValue: true,
        hideUniqStat: true,
      });

      this.addToLayout(this._statView, {
        align: 'center',
      });
    }

    this.addToLayout(this._lineStartView, {
      align: 'center',
      margin: { left: 8 },
    });
  }

  onBeforeRender() {
    super.onBeforeRender();
    if (this.blockId) {
      this._node.addOutLink(this.blockId, this._lineStartView._lineView);
    }
  }
}
