import { HEXColors } from '@ui/_common/colors';
import i18next from 'i18next';
import { equals } from 'ramda';
import { SendToMessengerColor, SendToMessengerSize } from '@globals';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { popupEntryPointFragment_config as PopupEntryPointConfig } from '../../../../Plugins/PopupEntryPoint/@types/popupEntryPointFragment';
import { PLUGIN_DEFAULT_CONFIG } from '../../../../Plugins/PopupEntryPoint/PopupEntryPointConst';
import { crossSvgTexture } from '../../../assets/textures';
import { MainLayout, VLayout } from '../../../components/Elements/Layouts';
import { HTMLText, TextureShape } from '../../../components/Elements/Shapes';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { Node } from '../../../Node';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { BlockView } from '../../block_view';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import { LineStartView } from '../../components/Line/LineStartView';
import { ShopifyAccount } from '../../components/ShopifyAccount/ShopifyAccount';
import { Checkbox } from '../../kit/Checkbox';
import { createLineMenu } from '../../Menu/createLineMenu';
import { pluginWidth } from '../../plugin_consts';
import { SendToMessengerView } from '../common/SendToMessenger/SendToMessengerView';
import { showConnectPageDialog } from '../common/utils/showConnectPageDialog';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { validateIsShopifyConnected } from '../common/utils/validateIsShopifyConnected';
import { TargetBoxView } from './TargetBoxView';

const LINE_POS_CORRECTOR: Record<SendToMessengerSize, number> = {
  [SendToMessengerSize.standard]: 10,
  [SendToMessengerSize.large]: 0,
  [SendToMessengerSize.xlarge]: -14,
};

export class EntryPointPopup
  extends VLayout
  implements StatefulPluginDelegate<PopupEntryPointConfig>
{
  public _node: Node;
  private state: StatefulPlugin<PopupEntryPointConfig>;
  private connectedToShopify: boolean | undefined;
  private readonly sendToMessengerView: SendToMessengerView;
  private readonly popupTitleView: HTMLText;
  private readonly popupDescriptionView: HTMLText;
  private lineStartView: LineStartView;

  constructor(state: StatefulPlugin<PopupEntryPointConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.on('click', () => {
      logFlowPluginEvent(PluginType.popup_entry_point, 'open popup settings');
      getPixiFieldStrict().fixBlockPosition(node.blockView, getPanelWidth());
      editPluginInEditorPanel(node.id, this.state.data);
    });

    this.addToLayout(
      new ShopifyAccount({
        eventPropertyBag: { pluginId: PluginType.popup_entry_point },
        onConnectedAccountStatusChange: (account) => {
          this.connectedToShopify = !!account;
          this.renderNode();
        },
        blockId: node.block.id,
        onPageConnectRequest: () => {
          showConnectPageDialog(node, this.state.data);
        },
      }),
      {
        margin: {
          bottom: 16,
        },
      },
    );

    const box = new MainLayout({
      background: {
        fill: HEXColors.greyLight30,
        cornerRadius: 12,
      },
      width: pluginWidth,
    });

    const whiteBox = new MainLayout({
      background: {
        fill: HEXColors.white,
        cornerRadius: 8,
      },
      width: pluginWidth - 32,
    });

    this.addToLayout(box, {
      margin: {
        bottom: 16,
      },
    });

    this.sendToMessengerView = new SendToMessengerView();

    box.addToLayout(whiteBox, {
      margin: {
        top: 16,
        right: 16,
        bottom: 16,
        left: 16,
      },
    });

    const vBox = new VLayout();

    this.popupTitleView = new HTMLText({
      fontSize: 18,
      fontStyle: 'semibold',
      text: '',
      width: pluginWidth - 64,
    });

    this.popupDescriptionView = new HTMLText({
      fontSize: 12,
      fill: HEXColors.greyDark,
      text: '',
      width: pluginWidth - 64,
    });

    vBox.addToLayout(this.popupTitleView, {
      margin: {
        top: 32,
        right: 16,
        bottom: 4,
        left: 16,
      },
    });

    vBox.addToLayout(this.popupDescriptionView, {
      margin: {
        right: 16,
        bottom: 0,
        left: 16,
      },
    });

    vBox.addToLayout(this.sendToMessengerView, {
      margin: {
        left: -16,
      },
    });

    whiteBox.addToLayout(vBox);
    whiteBox.addToLayout(
      new TextureShape({
        texture: crossSvgTexture,
        width: 36,
        height: 36,
      }),
      {
        margin: {
          top: 8,
          left: 226,
        },
      },
    );

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: true,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
          shouldShowShopifyMenuItems: false,
          isShowShopifyDiscount: true,
        }),
        onConnected: (blockView: BlockView) => {
          const blockId = blockView.id();
          this.updateConfig({
            block_id: blockId,
          });
        },
        onRemoved: () => {
          this.updateConfig({
            block_id: null,
          });
        },
      },
      undefined,
    );

    whiteBox.addToLayout(this.lineStartView, {
      verticalAlign: 'bottom',
      align: 'end',
      margin: () => ({
        left: 8,
        top:
          -56 +
          LINE_POS_CORRECTOR[
            (
              this.state.data.config.shopify_popup ||
              PLUGIN_DEFAULT_CONFIG.shopify_popup
            )?.size as SendToMessengerSize
          ],
      }),
    });

    const checkbox = new Checkbox({
      text: i18next.t('shopify.DifferentFlowForOldUsers'),
      value: this.state.data.config?.separate_response_for_existing_users,
      onChange: (value) => {
        logFlowPluginEvent(
          PluginType.popup_entry_point,
          'different flow for old users change',
          {
            enable: value,
          },
        );
        this.updateConfig({
          separate_response_for_existing_users: value,
        });
        this._node.blockView.renderElement();
      },
    });

    this.addToLayout(checkbox);

    this.addToLayout(
      new TargetBoxView({
        node,
        title: i18next.t('shopify.FlowForOldUsers'),
        blockId: this.state.data.config?.existing_user_block_id,
        onBlockIdChange: (blockId) => {
          this.updateConfig({
            existing_user_block_id: blockId,
          });
        },
        statKey: `${this.state.data.id}_existing_users`,
      }),
      {
        margin: {
          top: 16,
        },
        gone: () =>
          !this.state.data.config?.separate_response_for_existing_users,
      },
    );

    this.updateViews();
  }

  onBeforeRender() {
    const blockId = this.state.data.config?.block_id;
    if (blockId) {
      this._node.addOutLink(blockId, this.lineStartView._lineView);
    }
  }

  private updateConfig(updatedData: Partial<PopupEntryPointConfig>) {
    this.state.set(({ config }) => ({
      config: {
        ...config,
        ...updatedData,
      },
    }));
    this.state.save();
  }

  private updateViews() {
    const stats = getFullCounterStats(
      `${this.state.data.id}_new_users`,
      this._node.block.id,
    );
    const shopifyPopup =
      this.state.data.config.shopify_popup ||
      PLUGIN_DEFAULT_CONFIG.shopify_popup!;
    this.sendToMessengerView.updateConfig({
      size: shopifyPopup.size || SendToMessengerSize.large,
      color: shopifyPopup.color || SendToMessengerColor.blue,
      cta_text: shopifyPopup.button_text || '',
      stats,
    });
    this.popupTitleView.text(shopifyPopup.title.trim());
    this.popupDescriptionView.text(shopifyPopup.description.trim());
  }

  pluginDidSet() {
    this.updateViews();
    this.renderNode();
  }

  private validateIsMustLeadToBlocks() {
    const {
      block_id,
      existing_user_block_id,
      separate_response_for_existing_users,
    } = this.state.data.config;
    return block_id &&
      (!separate_response_for_existing_users || existing_user_block_id)
      ? undefined
      : {
          message: i18next.t('shopify.thisEntryPointMustLeadToBlocks'),
          isBlockError: true,
        };
  }

  private validateIsHaveTriggers() {
    const { shopify_popup: shopifyPopup } = this.state.data.config;
    return shopifyPopup?.trigger?.type?.length
      ? undefined
      : {
          message: `${i18next.t(
            'shopify.toEnableThisEntryPointSetupShowPopUpRules',
          )}<br/><br/><span style="text-decoration: underline;">${i18next.t(
            'shopify.setupIt',
          )}</span>`,
          onClick: (e: any) => {
            this.emit('click', e);
          },
        };
  }

  validationError() {
    return (
      validateIsPageConnecting(this._node) ||
      validateIsShopifyConnected(this.connectedToShopify, this._node) ||
      this.validateIsHaveTriggers() ||
      this.validateIsMustLeadToBlocks()
    );
  }

  hasSignificantChangesInConfig() {
    const { shopify_popup, block_id, existing_user_block_id } =
      this.state.data.config;
    return (
      !equals(shopify_popup, PLUGIN_DEFAULT_CONFIG.shopify_popup) ||
      !!block_id ||
      !!existing_user_block_id
    );
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
