import { Modal } from '@services/index';
import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Divider } from '@ui/Menu';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface WhatsappTemplateChangeDialogProps {
  onSubmit(): void;
  onDismiss(): void;
}

export const WhatsappTemplateChangeDialog: React.FC<WhatsappTemplateChangeDialogProps> =
  ({ onDismiss, onSubmit }) => {
    const { t } = useSafeTranslation();

    return (
      <DefaultDialog
        small
        header={
          <Type as="h1" size="18px" weight="bold">
            {t(
              'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.changeTemplateModal.header',
            )}
          </Type>
        }
        onDismiss={onDismiss}
      >
        <Type as="p">
          {t(
            'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.changeTemplateModal.message',
          )}
        </Type>
        <Type as="p">
          {t(
            'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.changeTemplateModal.precaution',
          )}
        </Type>
        <Spacer factor={4} />
        <Divider />
        <Spacer factor={4} />
        <Flex>
          <Button
            intent="primary"
            onClick={onSubmit}
            data-testid="whatsapp-template-dialog__change"
          >
            {t(
              'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.changeTemplateModal.change',
            )}
          </Button>
          <Spacer factor={0} horizontalFactor={3} />
          <Button
            intent="secondary"
            onClick={onDismiss}
            data-testid="whatsapp-template-dialog__cancel"
          >
            {t(
              'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.changeTemplateModal.cancel',
            )}
          </Button>
        </Flex>
      </DefaultDialog>
    );
  };

export const showChangeTemplateWarningModal = () =>
  Modal.show(({ resolve, close }) => (
    <WhatsappTemplateChangeDialog onDismiss={close} onSubmit={resolve} />
  ));
