import {
  HLayout,
  MainLayout,
  VLayout,
} from '../../../components/Elements/Layouts';
import { HEXColors } from '@ui/_common/colors';
import { HTMLText, TextureShape } from '../../../components/Elements/Shapes';
import i18next from 'i18next';
import Maybe from 'graphql/tsutils/Maybe';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { Sprite } from 'pixi.js-legacy';
import { PropFunc } from '../../../types';
import { resByFunc } from '../../utils';
import { ATTACHMENT_PLUGIN_SIZE, FILE_ICONS, ICON_SIZE } from './const';
import { uploadFileService } from '@utils/UploadService/uploadFileService';
import {
  ErrorTypes,
  UploadFileResult,
} from '@utils/UploadService/UploadService';
import { Loader } from '../../loader';
import { sendEvent } from '@utils/Analytics';
import { FileNameData } from './types';
import linkSvg from './images/link.svg';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import {
  FileAttachmentType,
  UploadMimeTypes,
} from '@utils/UploadService/types';
import { MB_MULTIPLIER } from '@utils/PlatformSupportedFiles/getFileSizeLimit';
import { getExtStringByMimeTypes } from '@utils/PlatformSupportedFiles/getExtListByMimeTypes';

interface FileAttachmentViewParams {
  title: string;
  type: FileAttachmentType;
  fileData: PropFunc<Maybe<FileNameData>>;
  accept: UploadMimeTypes | Array<UploadMimeTypes>;
  onFileUploaded: (params: UploadFileResult) => void;
  isEditing: () => boolean;
  isShowFileFormatError: PropFunc<boolean>;
  fileSizeLimitMb: number;
}

export class FileAttachmentView extends VLayout {
  private readonly filenameView: HTMLText;
  private readonly box: HLayout;
  private readonly loader: Loader;
  private readonly fileData: PropFunc<Maybe<FileNameData>>;
  private isShowFileFormatErrorLoc: boolean = false;
  private fileSizeError: boolean = false;

  constructor({
    title,
    type,
    fileData,
    accept,
    onFileUploaded,
    fileSizeLimitMb,
    isEditing,
    isShowFileFormatError,
  }: FileAttachmentViewParams) {
    super();

    this.fileData = fileData;

    this.box = new HLayout({
      ...ATTACHMENT_PLUGIN_SIZE,
      background: {
        fill: HEXColors.baseExtraLight,
        stroke: () =>
          (this.fileSizeError ||
            resByFunc(isShowFileFormatError) ||
            (!resByFunc(this.fileData)?.url && !resByFunc(isEditing))) &&
          !this.loader.isLoading
            ? HEXColors.accent4Normal
            : HEXColors.baseExtraLight,
        strokeWidth: 2,
        cornerRadius: 4,
      },
    });

    this.addToLayout(this.box);

    this.box.on('click', (event) => {
      event.stopPropagation();
      this.fileSizeError = false;
      this.isShowFileFormatErrorLoc = false;

      sendEvent({
        category: 'flow builder',
        label: 'file upload dialog',
        action: 'choose file click',
      });

      uploadFileService
        .chooseFile({
          beforeUpload: this.startLoading,
          accept,
          dataTestId: 'file__upload-file',
          maxFileSize: fileSizeLimitMb * MB_MULTIPLIER,
          platform: getFlowPlatform()!,
          filetype: type,
        })
        .then(onFileUploaded)
        .catch((error) => {
          if (error === ErrorTypes.fileSizeLimit) {
            this.fileSizeError = true;
          } else {
            this.isShowFileFormatErrorLoc = true;
          }
          this.stopLoading();
        })
        .finally(() => {
          this.renderElement();
        });
    });

    this.box.addToLayout(
      new HTMLText({
        text: title,
        ...ATTACHMENT_PLUGIN_SIZE,
        align: 'center',
        verticalAlign: 'center',
        fontSize: 15,
        trustedHtml: true,
      }),
      {
        gone: () => !!resByFunc(this.fileData)?.url || this.loader.isLoading,
      },
    );

    this.loader = new Loader({
      ...ATTACHMENT_PLUGIN_SIZE,
      background: { opacity: 0 },
    });

    this.box.addToLayout(this.loader, {
      gone: () => !this.loader.isLoading,
    });

    this.filenameView = new HTMLText({
      width: ATTACHMENT_PLUGIN_SIZE.width - 115,
      height: ATTACHMENT_PLUGIN_SIZE.height,
      align: 'left',
      verticalAlign: 'center',
      fontSize: 15,
      singleLine: true,
    });

    this.prepareIcons(type);

    this.box.addToLayout(
      new MainLayout({
        height: ATTACHMENT_PLUGIN_SIZE.height - 2,
        width: 1,
        background: {
          fill: HEXColors.white,
        },
      }),
      {
        gone: () => !resByFunc(this.fileData)?.url || this.loader.isLoading,
        margin: {
          right: 12,
          top: 2,
        },
      },
    );

    this.box.addToLayout(this.filenameView, {
      gone: () => !resByFunc(this.fileData)?.url || this.loader.isLoading,
      margin: {
        left: 10,
      },
    });

    const link = new TextureShape({
      texture: Sprite.from(linkSvg).texture,
      width: 24,
      height: 24,
    });

    link.on('click', (event) => {
      event.stopPropagation();
      window.open(resByFunc(this.fileData)?.url || '', '_blank');
    });

    this.box.addToLayout(link, {
      gone: () => !resByFunc(this.fileData)?.url || this.loader.isLoading,
      margin: {
        top: 15,
        left: 10,
      },
    });

    this.addToLayout(
      new HTMLText({
        fontSize: 12,
        fill: HEXColors.accent4Normal,
        trustedHtml: true,
        text: i18next.t(
          'modernComponents.FlowBuilder.views.components.FileAttachmentView.fileSizeError',
          { size: fileSizeLimitMb },
        ),
      }),
      {
        margin: {
          top: 4,
        },
        gone: () => !this.fileSizeError || this.loader.isLoading,
      },
    );

    this.addToLayout(
      new HTMLText({
        fontSize: 12,
        fill: HEXColors.accent4Normal,
        trustedHtml: true,
        width: ATTACHMENT_PLUGIN_SIZE.width,
        text: i18next.t(
          'modernComponents.FlowBuilder.views.components.FileAttachmentView.formatError',
          {
            formats: getExtStringByMimeTypes(accept),
          },
        ),
      }),
      {
        margin: {
          top: 4,
        },
        gone: () =>
          (!resByFunc(isShowFileFormatError) &&
            !this.isShowFileFormatErrorLoc) ||
          this.loader.isLoading,
      },
    );
  }

  private prepareIcons(type: FileAttachmentType) {
    this.box.addToLayout(
      new TextureShape({
        texture: Sprite.from(FILE_ICONS[type]).texture,
        ...ICON_SIZE,
      }),
      {
        margin: {
          left: 8,
          right: 8,
          top: 10,
        },
        gone: () => this.loader.isLoading || !resByFunc(this.fileData)?.url,
      },
    );
  }

  private startLoading = () => {
    this.loader.start();
    this.renderNode();
  };

  public stopLoading = () => {
    this.loader.stop();
    this.renderNode();
  };

  onBeforeRender() {
    const fileData = resByFunc(this.fileData);
    const filename =
      fileData?.filename || (fileData?.url || '').split('/').pop();
    if (!filename) {
      return;
    }
    const filenameArr = filename.split('.');
    const ext = filenameArr.pop();
    const name = filenameArr.join('.');
    this.filenameView.text(`${truncateWithEllipses(name, 20)}.${ext}`);
  }
}
