import { VLayout, MainLayout } from '../components/Elements/Layouts';
import { Layout } from '../components/Elements/Layouts/Layout';
import { buttonControl } from './helpers/ControlsHelpers';
import {
  controlLayout,
  controlMargin,
  controlWidth,
} from './card_control_view';
import { dragVerticalSvgTexture, removeSvgTexture } from '../assets/textures';
import { getPixiFieldStrict } from '../PixiFieldRepository';
import { HEXColors } from '@ui/_common/colors';
import { PropFunc } from '../types';

type OnPositionChanged = (
  button: MainLayout,
  startIdx: number,
  idx: number,
) => void;

export class ButtonControlViewVertical extends VLayout {
  TEST_NAME = 'ButtonControlView';

  private _hideRemoveButton?: PropFunc<boolean>;
  private _arrow: MainLayout;
  private removeBtn: MainLayout;
  private _layout?: MainLayout;
  private onPositionChanged?: OnPositionChanged;
  private onRemove?: () => void;

  constructor() {
    super({
      width: controlWidth,
      cursor: { in: 'pointer' },
      background: {
        cornerRadius: 5,
        fill: HEXColors.white,
        stroke: HEXColors.grey,
        strokeWidth: 1,
      },
    });
    this._arrow = controlLayout(dragVerticalSvgTexture);
    this.removeBtn = controlLayout(removeSvgTexture);
    this.layout(this._arrow, {
      margin: {
        left: controlMargin,
        top: controlMargin,
        bottom: controlMargin,
        right: controlMargin,
      },
      gone: () =>
        !this.onPositionChanged || !this._layout || this._layout.size() < 2,
    }).layout(this.removeBtn, {
      margin: () => {
        return {
          top: controlMargin,
          bottom: controlMargin,
          right: controlMargin,
          left: controlMargin,
        };
      },
      gone: this._hideRemoveButton,
    });
    this.renderNode();

    this.removeBtn.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.removeBtn.on('click', (e) => {
      e.stopPropagation();
      if (this.onRemove) {
        this.onRemove();
      }
      this.hide();
    });
  }

  public setButton(
    button: Layout,
    layout?: MainLayout,
    onMove?: () => void,
    onRemove?: () => void,
    onPositionChanged?: OnPositionChanged,
    hideRemoveButton?: PropFunc<boolean>,
  ) {
    if (layout) {
      layout.controlDragging(this, button);
    }
    this._hideRemoveButton = hideRemoveButton;
    this._layout = layout;
    this.onRemove = onRemove;
    this.onPositionChanged = onPositionChanged;
    getPixiFieldStrict()
      .eventHandler()
      .on(this, 'button_control', 'dragmove', () => {
        if (onMove) onMove();
      });
    getPixiFieldStrict()
      .eventHandler()
      .on(this, 'button_control', 'dragend', () => {
        buttonControl().updatePosition();
      });

    this.renderNode();
  }

  public onElementPositionChanged(
    button: MainLayout,
    startIdx: number,
    idx: number,
  ) {
    if (this.onPositionChanged) {
      this.onPositionChanged(button, startIdx, idx);
    }
  }
}
