import penner from 'penner';
import { getPixiFieldStrict } from '../../PixiFieldRepository';
import { Node } from '../../Node';
import { outsideBlockFixedViewsService } from '../../views/services';
import { nonEmptyArray } from '../../views/validation';
import { AllNodes, NodePosition } from './helpers';
import { FlowBuilderAnimations } from '../../consts';

const ANIMATION_DURATION = 500;

interface AnimationOptions {
  type?: penner.PennerFunctionKey;
  duration?: number;
}

const DEFAULT_MOVE_ANIMATION_OPTIONS: Required<AnimationOptions> = {
  type: 'linear',
  duration: ANIMATION_DURATION,
};

/**
 * @param node is mutable
 * @param currentMs
 * @param animationOptions
 */
function updateNodePosition(
  node: Node,
  currentMs: number,
  animationOptions: AnimationOptions = {},
): void {
  const { type, duration } = {
    ...DEFAULT_MOVE_ANIMATION_OPTIONS,
    ...animationOptions,
  };
  const x = penner[type](
    currentMs,
    node.startX!,
    node.newX! - node.startX!,
    duration,
  );
  const y = penner[type](
    currentMs,
    node.startY!,
    node.newY! - node.startY!,
    duration,
  );
  /* eslint-disable no-param-reassign */
  node.blockView.x = x;
  node.blockView.y = y;
  node.x = x;
  node.y = y;
  /* eslint-enable no-param-reassign */
  node.updateLines();
  outsideBlockFixedViewsService.updateOutsideViews(node.blockView);
}

function updateFinalNodePosition(node: Node): void {
  const x = node.newX!;
  const y = node.newY!;
  /* eslint-disable no-param-reassign */
  node.blockView.x = x;
  node.blockView.y = y;
  node.x = x;
  node.y = y;
  /* eslint-enable no-param-reassign */
  node.updateLines();
  outsideBlockFixedViewsService.updateOutsideViews(node.blockView);
}

/**
 * @param nodesToUpdate {Node[]} is mutable
 */
export async function updateNodesPositionWithAnimation(
  nodesToUpdate: Node[],
): Promise<void> {
  return new Promise((resolve) => {
    if (!nonEmptyArray(nodesToUpdate)) {
      resolve();
      return;
    }
    const pixiField = getPixiFieldStrict();
    const { ticker } = pixiField.app;

    let currentMs = 0;
    const tickerFunction = () => {
      nodesToUpdate.forEach((node) => {
        updateNodePosition(node, currentMs, { type: 'easeOutQuad' });
      });
      if (currentMs >= ANIMATION_DURATION) {
        nodesToUpdate.forEach(updateFinalNodePosition);
        pixiField.stopAnimation({
          animationId: FlowBuilderAnimations.blocksOrganize,
        });
        resolve();
      } else {
        currentMs += ticker.elapsedMS;
      }
    };

    pixiField.startAnimation({
      animationId: FlowBuilderAnimations.blocksOrganize,
      animationFunction: tickerFunction,
    });
  });
}

export function setNewPositionsToNodes(
  positions: NodePosition[],
  allNodes: AllNodes,
): Node[] {
  return positions.map((position) => {
    const currentNode = allNodes[position.id];
    currentNode.newPosition(position.position.x, position.position.y);
    return currentNode;
  });
}
