import isEqual from 'lodash-es/isEqual';
import { VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { whatsappLocationPluginFragment_config as WhatsappLocationPluginConfig } from '../../../../Plugins/WhatsappLocationPlugin/@types/whatsappLocationPluginFragment';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { WhatsappLocationView } from './WhatsappLocationView';
import { WhatsappNoLocationView } from './WhatsappNoLocationView';
import { Margin } from '../../utility_classes';
import { showLocationPickerDialog } from './modal/LocationPickerDialog';
import { LocationPickerPlace } from './modal/types';
import { PLUGIN_DEFAULT_CONFIG } from '@components/Plugins/WhatsappLocationPlugin/WhatsappLocationPluginConst';

enum PluginViewVariant {
  noLocationView,
  locationView,
}

export class WhatsappLocationPluginView
  extends VLayout
  implements StatefulPluginDelegate<WhatsappLocationPluginConfig>
{
  public readonly TEST_NAME = 'WhatsappLocationPluginView';
  private readonly _node: Node;
  public readonly state: StatefulPlugin<WhatsappLocationPluginConfig>;
  public readonly logger: PluginLogger;
  private viewVariant: PluginViewVariant | undefined;

  constructor(state: StatefulPlugin<WhatsappLocationPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 10,
      },
    });

    this._node = node;

    this.state = state;
    this.state.setDelegate(this);
    this.viewVariant = this.getPluginViewVariant();

    this.logger = new PluginLogger(
      this.state.data.plugin_id,
      this._node.id,
      this.state.data.id,
    );

    this.addToLayout(
      new WhatsappNoLocationView({
        onButtonClick: () => this.onChooseLocationClick(),
      }),
      {
        margin: new Margin({ x: 12, y: 12 }),
        gone: () => this.viewVariant === PluginViewVariant.locationView,
      },
    );

    this.addToLayout(
      new WhatsappLocationView({
        logger: this.logger,
        getConfig: () => this.state.data.config,
        onClick: () => this.onChangeLocationClick(),
      }),
      {
        gone: () => this.viewVariant === PluginViewVariant.noLocationView,
      },
    );
  }

  private getPluginViewVariant() {
    return isEqual(this.state.data.config, PLUGIN_DEFAULT_CONFIG)
      ? PluginViewVariant.noLocationView
      : PluginViewVariant.locationView;
  }

  private onChangeLocationClick() {
    this.logger.log('Change location click');
    const { config } = this.state.data;
    const location = config.longitude && config.latitude ? config : undefined;

    return this.showLocationPickerDialog(location);
  }

  private onChooseLocationClick() {
    this.logger.log('Choose location click');
    return this.showLocationPickerDialog()?.then(() => {
      this.viewVariant = this.getPluginViewVariant();
      this.renderNode();
    });
  }

  private showLocationPickerDialog(location?: LocationPickerPlace) {
    return showLocationPickerDialog(location)?.then((location) => {
      this.state.set(({ config }) => ({ config: { ...config, ...location } }));
      this.state.save();
    });
  }

  hasSignificantChangesInConfig() {
    return !isEqual(this.state.data.config, PLUGIN_DEFAULT_CONFIG);
  }

  pluginDidSet() {
    this.renderNode();
  }

  validationError() {
    return false;
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
