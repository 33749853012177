import { removeTypename } from '@utils/GQL/utils';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { PLUGIN_DEFAULT_CONFIG as QUICK_REPLY_DEFAULT_CONFIG } from '../../../../../Plugins/QuickReplyPlugin/QuickReplyPluginConst';

export const DEFAULT_CONTENT_CARDS = [
  {
    plugin_id: PluginType.text,
    config: {
      text: '',
    },
  },
  {
    plugin_id: PluginType.quick_reply,
    config: removeTypename(QUICK_REPLY_DEFAULT_CONFIG),
  },
];
