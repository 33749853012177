import i18next from 'i18next';
import Maybe from 'graphql/tsutils/Maybe';
import { isEmptyString } from '../../../validation';

export const validateBotLink = (link: Maybe<string>, message?: string) => {
  return isEmptyString(link)
    ? {
        message:
          message ||
          i18next.t(
            'modernComponents.FlowBuilder.views.components.EntryPointBotLink.addBotLink',
          ),
      }
    : undefined;
};
