import i18next from 'i18next';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { kommoPngPluginSvgTexture } from '../../../assets/textures';
import { Node } from '../../../Node';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { KommoActionType, KommoIntegrationStatus } from '@globals';
import { HEXColors } from '@ui/_common/colors';
import { KommoPluginFragment_config } from '@components/Plugins/KommoPlugin/@types/KommoPluginFragment';
import { ButtonView } from '../../button_view';
import { BotTabs, getTabLink } from '@utils/Routing';
import { Dropdown } from '../../kit/Dropdown';
import { queryKommoIntegrationStatus } from '@utils/Data/Kommo/useKommoIntegrationStatus';
import { Loader } from '../../loader';
import { HTMLText } from '@components/FlowBuilder/components/Elements/Shapes';
import { KOMMO_PLUGIN_DEFAULT_CONFIG } from '@components/Plugins/KommoPlugin/KommoPlugin';
import { BASE_REQUIRED_ATTRIBUTES } from '@components/FlowBuilder/EditorPanel/components/plugins/KommoPlugin/consts';
import { getPixiFieldStrict } from '@components/FlowBuilder/PixiFieldRepository';

export class KommoPluginView
  extends VLayout
  implements StatefulPluginDelegate<KommoPluginFragment_config>
{
  TEST_NAME = 'KommoPluginView';

  kommoIntegrationStatus: {
    accountLink: string | null;
    accountName: string | null;
    status: KommoIntegrationStatus;
  } | null = null;

  loader: Loader;

  loading: boolean = true;

  setAttributesCount: VoidFunction;
  attributeCountText: string = '';
  setAccountName: VoidFunction;

  async getKommoIntegrationStatus(payload: { botId: string }) {
    if (!getPixiFieldStrict().isViewOnly()) {
      try {
        const queryResult = await queryKommoIntegrationStatus(payload);
        this.kommoIntegrationStatus = queryResult.data.kommoIntegrationStatus;
        this.setAccountName();
      } catch {
        // TODO
      }
    }

    this.loading = false;
    this.renderNode();
  }

  constructor(
    public readonly state: StatefulPlugin<KommoPluginFragment_config>,
    private readonly _node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this.state.setDelegate(this);

    this.layout(
      new PluginTitleLayout(
        false,
        i18next.t('KommoPlugin.kommo'),
        kommoPngPluginSvgTexture,
        state.data,
      ),
      {
        margin: { top: 10, left: 10, bottom: 10 },
      },
    );

    this.loader = new Loader({
      width: pluginWidth - 24,
      height: 36,
      background: { cornerRadius: 4 },
    });
    this.loader.start();
    this.layout(this.loader, {
      margin: {
        left: 12,
        bottom: 12,
      },
      gone: () => !this.loading,
    });

    const { botId } = this._node.controller.flow;

    this.getKommoIntegrationStatus({ botId });

    const connectAccountButton = new ButtonView({
      width: pluginWidth - 24,
      height: 36,
      bgColor: HEXColors.accent1Normal,
      textColor: HEXColors.white,
      title: i18next.t('KommoPlugin.connectAccount'),
      onClick: () => {
        window.open(getTabLink(BotTabs.configure, botId), '_self');
      },
    });

    this.layout(connectAccountButton, {
      margin: {
        left: 12,
        bottom: 12,
      },
      gone: () => {
        return (
          this.loading ||
          this.kommoIntegrationStatus?.status !== KommoIntegrationStatus.none
        );
      },
    });

    const connectedItems = new VLayout();

    const actionTypeList = [
      {
        id: KommoActionType.create_lead,
        title: i18next.t('KommoPlugin.create_lead'),
      },
      {
        id: KommoActionType.update_lead,
        title: i18next.t('KommoPlugin.update_lead'),
      },
      {
        id: KommoActionType.create_task,
        title: i18next.t('KommoPlugin.create_task'),
      },
    ];

    const defaultSelectedItem =
      actionTypeList.find(
        (item) => item.id === this.state.data.config.actionType,
      ) || actionTypeList[0];

    const accountName = new HTMLText({
      trustedHtml: true,
      fontSize: 15,
      width: pluginWidth - 40,
    });

    this.setAccountName = () => {
      accountName.text(this.kommoIntegrationStatus?.accountName ?? '');
    };

    this.setAccountName();

    connectedItems.layout(accountName, {
      margin: {
        bottom: 12,
      },
      gone: () => !this.kommoIntegrationStatus?.accountName?.length,
    });

    const actionTypeDropdown = new Dropdown<{
      id: KommoActionType;
      title: string;
    }>({
      isEditing: () => true,
      items: actionTypeList,
      defaultSelectedItem,
      onChange: (value) => {
        this.state.setAndSave({
          config: {
            ...KOMMO_PLUGIN_DEFAULT_CONFIG,
            actionType: value.id,
          },
        });
      },
      height: 36,
      verticalAlign: 'center',
      width: pluginWidth - 40,
      padding: {
        left: 16,
        right: 8,
      },
      background: {
        cornerRadius: 4,
        fill: HEXColors.white,
      },
    });

    connectedItems.layout(actionTypeDropdown, {
      margin: {
        bottom: 12,
      },
    });

    const attributesContainer = new HLayout({
      background: {
        fill: HEXColors.white,
        cornerRadius: 18,
      },
    });

    const attributes = new HTMLText({
      fontColor: HEXColors.cobaltTertiary,
      verticalAlign: 'center',
    });

    this.setAttributesCount = () => {
      this.attributeCountText = `${
        (this.state.data.config?.customFields?.length ?? 0) +
        BASE_REQUIRED_ATTRIBUTES
      } ${i18next.t('KommoPlugin.attributes')}`;
      attributes.text(this.attributeCountText);
    };

    this.setAttributesCount();

    attributesContainer.layout(attributes, {
      gone: () =>
        this.state.data.config.actionType === KommoActionType.create_task,
      margin: {
        left: 8,
        top: 6,
        bottom: 8,
        right: 8,
      },
    });

    connectedItems.layout(attributesContainer, {
      margin: {
        bottom: 12,
      },
      align: 'end',
      gone: () => !this.attributeCountText.length,
    });

    this.layout(connectedItems, {
      margin: {
        left: 12,
        right: 12,
      },
      gone: () => {
        return (
          this.loading ||
          this.kommoIntegrationStatus?.status !==
            KommoIntegrationStatus.connected
        );
      },
    });

    this.on('click', () => {
      if (
        this.loading ||
        !this.kommoIntegrationStatus ||
        this.kommoIntegrationStatus.status === KommoIntegrationStatus.none ||
        !this.state.data.config.actionType
      )
        return;

      editPluginInEditorPanel(_node.id, state.data, state);
    });
  }

  pluginDidSave() {
    this.renderNode();
  }

  onBeforeRender() {
    super.onBeforeRender();
    this.setAttributesCount();
  }

  validationError() {
    const { actionType, assigneeId, pipelineId, statusId, text, deadline_ms } =
      this.state.data.config;

    switch (actionType) {
      case KommoActionType.create_task: {
        if (!deadline_ms || !text)
          return {
            message: i18next.t('KommoPlugin.fieldsNotSet'),
          };
        break;
      }
      case KommoActionType.create_lead:
      case KommoActionType.update_lead: {
        if (!assigneeId || !pipelineId || !statusId)
          return {
            message: i18next.t('KommoPlugin.fieldsNotSet'),
          };
        break;
      }
      default:
        return {
          message: i18next.t('KommoPlugin.fieldsNotSet'),
        };
    }

    if (this.state.data.validation_details) {
      if (
        typeof this.state.data.validation_details === 'object' &&
        'kommo_related_error' in this.state.data.validation_details
      )
        return {
          message: `${i18next.t('KommoPlugin.relatedError')} ${
            this.state.data.validation_details.kommo_related_error
          }`,
        };
      if (
        this.state.data.validation_details.fields?.[0]?.field ===
        'kommo_related_error'
      )
        return {
          message: `${i18next.t('KommoPlugin.relatedError')} ${
            this.state.data.validation_details.fields[0].error ?? ''
          }`,
        };
    }

    return undefined;
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }
}
