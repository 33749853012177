import { VLayout } from '../../../../components/Elements/Layouts';
import { pluginWidth } from '../../../plugin_consts';
import { inboundLinksEntryPointFragment_config_blocks as Block } from '../../../../../Plugins/InboundLinksEntryPoint/@types/inboundLinksEntryPointFragment';
import { Label } from '../../../kit/Label';
import {
  LINKS_TO_BE_SHOWN_PER_BLOCK_LIMIT,
  LINK_ITEM_COMMON_STYLES,
  LINKS_CONTAINER_COMMON_STYLES,
} from '../constants';
import { GrayBoxView } from '../../../kit/GrayBox';
import { InboundLinkView } from './InboundLink';
import { MoreItemsButtonView } from './MoreItemsButton';
import { getFullCounterStats } from '../../../components/BlockStatsView/utils';
import { FullCounterStats } from '../../../../utils/Statistics/types';
import {
  getAutomationBlockHref,
  logInboundLinkEvent,
  logMoreButtonEvent,
} from '../../../../EditorPanel/components/plugins/InboundLinksEntryPoint/helpers';

interface BlocksInboundLinksViewProps {
  botId: string;
  flowId: string;
  blockId: string;
  blocks: Block[];
  onMoreButtonClick(): void;
}

export class BlocksInboundLinksView extends GrayBoxView {
  constructor({
    botId,
    flowId,
    blockId: inboundLinksBlockId,
    blocks,
    onMoreButtonClick,
  }: BlocksInboundLinksViewProps) {
    super();

    const linksContainer = new VLayout({});
    let showMoreCount = 0;
    let availableLinksSlots = LINKS_TO_BE_SHOWN_PER_BLOCK_LIMIT;

    const logInboundLinkClick = (href: string) => {
      logInboundLinkEvent({
        botId,
        flowId,
        channel: 'blocks',
        source: 'card',
        href,
      });
    };

    blocks.forEach(
      ({
        id: blockId,
        title: blockTitle,
        buttons,
        redirects,
        quick_replies,
      }) => {
        const getBlockHref = (cardId: string | null) =>
          getAutomationBlockHref({
            botId,
            blockId,
            cardId,
          });

        if (availableLinksSlots > 0) {
          linksContainer.addToLayout(
            new Label({
              text: blockTitle,
              width: pluginWidth - 60,
            }),
            LINK_ITEM_COMMON_STYLES,
          );
        }

        if (buttons) {
          const buttonsLength = buttons.length;
          if (buttonsLength <= availableLinksSlots) {
            buttons.forEach(({ card_id, counter_id, title }) => {
              const href = getBlockHref(card_id);
              const stats = getFullCounterStats(
                counter_id,
                inboundLinksBlockId,
              );
              this.addInboundLinkToLayout(linksContainer, {
                href,
                title,
                stats,
                log: logInboundLinkClick,
              });
            });
            availableLinksSlots -= buttonsLength;
          } else {
            for (let index = 0; index < availableLinksSlots; index++) {
              const { card_id, counter_id, title } = buttons[index];
              const href = getBlockHref(card_id);
              const stats = getFullCounterStats(
                counter_id,
                inboundLinksBlockId,
              );
              this.addInboundLinkToLayout(linksContainer, {
                href,
                title,
                stats,
                log: logInboundLinkClick,
              });
            }
            showMoreCount += buttonsLength - availableLinksSlots;
            availableLinksSlots = 0;
          }
        }

        if (redirects) {
          const redirectsLength = redirects.length;
          if (redirectsLength <= availableLinksSlots) {
            redirects.forEach(({ card_id, counter_id }) => {
              const href = getBlockHref(card_id);
              const stats = getFullCounterStats(
                counter_id,
                inboundLinksBlockId,
              );
              this.addInboundLinkToLayout(linksContainer, {
                href,
                stats,
                title: 'Redirect',
                log: logInboundLinkClick,
              });
            });
            availableLinksSlots -= redirectsLength;
          } else {
            for (let index = 0; index < availableLinksSlots; index++) {
              const { card_id, counter_id } = redirects[index];
              const href = getBlockHref(card_id);
              const stats = getFullCounterStats(
                counter_id,
                inboundLinksBlockId,
              );
              this.addInboundLinkToLayout(linksContainer, {
                href,
                stats,
                title: 'Redirect',
                log: logInboundLinkClick,
              });
            }
            showMoreCount += redirectsLength - availableLinksSlots;
            availableLinksSlots = 0;
          }
        }

        if (quick_replies) {
          const quickRepliesLength = quick_replies.length;
          if (quickRepliesLength <= availableLinksSlots) {
            quick_replies.forEach(({ card_id, counter_id, title }) => {
              const href = getBlockHref(card_id);
              const stats = getFullCounterStats(
                counter_id,
                inboundLinksBlockId,
              );
              this.addInboundLinkToLayout(linksContainer, {
                href,
                title,
                stats,
                log: logInboundLinkClick,
              });
            });
            availableLinksSlots -= quickRepliesLength;
          } else {
            for (let index = 0; index < availableLinksSlots; index++) {
              const { card_id, counter_id, title } = quick_replies[index];
              const href = getBlockHref(card_id);
              const stats = getFullCounterStats(
                counter_id,
                inboundLinksBlockId,
              );
              this.addInboundLinkToLayout(linksContainer, {
                href,
                title,
                stats,
                log: logInboundLinkClick,
              });
            }
            showMoreCount += quickRepliesLength - availableLinksSlots;
            availableLinksSlots = 0;
          }
        }
      },
    );

    if (showMoreCount > 0) {
      linksContainer.addToLayout(
        new MoreItemsButtonView({
          count: showMoreCount,
          onClick: () => {
            logMoreButtonEvent({
              channel: 'blocks',
              botId,
              flowId,
              count: showMoreCount,
            });
            onMoreButtonClick();
          },
        }),
        LINK_ITEM_COMMON_STYLES,
      );
    }

    this.addToLayout(linksContainer, LINKS_CONTAINER_COMMON_STYLES);
  }

  private addInboundLinkToLayout(
    container: VLayout,
    {
      title,
      href,
      stats,
      log,
    }: {
      title: string;
      href: string;
      stats?: FullCounterStats;
      log: (href: string) => void;
    },
  ) {
    container.addToLayout(
      new InboundLinkView({
        title,
        href,
        stats,
        onOpenHref: () => {
          log(href);
        },
      }),
      LINK_ITEM_COMMON_STYLES,
    );
  }
}
