import {
  Alignment,
  HLayout,
  LayoutProps,
  VLayout,
} from '../../../components/Elements/Layouts';
import { Margin } from '../../utility_classes';
import { HEXColors } from '@ui/_common/colors';
import {
  HTMLText,
  TextureShape,
} from '@components/FlowBuilder/components/Elements/Shapes';
import { TextEditView, TextEditViewProps } from '../../text_edit_view';
import { fontFamily } from '@components/FlowBuilder/consts';
import { WhatsappListSectionHeaderView } from './WhatsappListSectionHeaderView';
import messageImageSrc from './assets/message-tooltip-en.png';
import { StatefulPlugin } from '@components/FlowBuilder/StatefulPlugin';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import {
  whatsappListPluginFragment_config,
  whatsappListPluginFragment_config_listHeader as ListHeader,
  whatsappListPluginFragment_config_listFooter as ListFooter,
} from '../../../../Plugins/WhatsappListPlugin/@types/whatsappListPluginFragment';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import i18next from 'i18next';
import {
  DEFAULT_LIST_BODY,
  DEFAULT_LIST_FOOTER,
  DEFAULT_LIST_HEADER,
} from '@components/Plugins/WhatsappListPlugin/WhatsappListPluginConst';
import {
  listErrorIconSvgTexture,
  listIconSvgTexture,
} from '../../../assets/textures';

export const getTooltip = (src: string, text: string) => `
  <div>
    <img src="${src}" alt="" style="display:block;width:214px;margin-bottom:12px;"/>
    <p style="width:214px">${text}</p>
  </div>
`;

const margin = new Margin({ bottom: 12 });

export interface WhatsappListMessageLayoutProps extends LayoutProps {
  width: number;
}

export class WhatsappListMessageView extends VLayout {
  private headerText: string = '';
  private bodyText: string = '';
  private footerText: string = '';
  private buttonText: string = '';

  private readonly state: StatefulPlugin<whatsappListPluginFragment_config>;
  private readonly logger: PluginLogger;
  private readonly props: WhatsappListMessageLayoutProps;

  constructor(
    state: StatefulPlugin<whatsappListPluginFragment_config>,
    logger: PluginLogger,
    props: WhatsappListMessageLayoutProps,
  ) {
    super(props);

    this.props = props;
    this.logger = logger;
    this.state = state;

    this.addToLayout(this.renderView());
  }

  private renderView() {
    const messageBlock = new VLayout();

    messageBlock
      .addToLayout(this.renderHeaderText(), { margin })
      .addToLayout(this.renderHeaderBlock(), { margin })
      .addToLayout(this.renderBodyInput(), { margin })
      .addToLayout(this.renderFooterInput(), { margin })
      .addToLayout(this.renderButtonTitle(), { margin: { bottom: 8 } })
      .addToLayout(this.renderButtonInput(), { margin });

    return messageBlock;
  }

  private renderHeaderText() {
    return new WhatsappListSectionHeaderView({
      text: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.message',
      ),
      tooltip: getTooltip(
        messageImageSrc,
        i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.messageTooltip',
        ),
      ),
    });
  }

  public renderHeaderBlock() {
    const wrapper = new VLayout();
    const headerRow = new HLayout();

    const header = new TextEditView(
      {
        ...this.getTextEditViewDefaultProps(),
        text: this.state.data.config.listHeader?.text || '',
        margin: { left: 8, top: 8, bottom: 8, right: 8 },
        maxLength: 60,
        caption: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.caption',
        ),
        captionFill: HEXColors.cobaltTertiary,
      },
      undefined,
      (title) => {
        this.headerText = title.trim();
      },
      () => {
        this.state.set(({ config }) => {
          const listHeader: ListHeader | null = this.headerText.length
            ? {
                ...DEFAULT_LIST_HEADER,
                ...config.listHeader,
                text: this.headerText,
              }
            : null;

          return { config: { ...config, listHeader } };
        });

        this.state.save();

        this.logger.log('update header text', {
          value: this.state.data.config.listHeader?.text,
        });
      },
      {
        symbolsLimit: ControlVisibility.show,
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
      },
    );

    // const button = new ButtonView({
    //   width: 36,
    //   height: 36,
    //   title: '',
    //   icon: attachmentSvgTexture,
    //   onClick: () => {
    //   },
    //   background: { stroke: HEXColors.grey },
    //   iconMargin: new Margin({ x: 6, y: 6 }),
    // });

    headerRow.addToLayout(header, { margin: new Margin({ right: 12 }) });
    // .addToLayout(button);

    // const subscription = new HTMLText({
    //   text: i18next.t('modernComponents.FlowBuilder.plugins.WhatsappListPlugin.subscription'),
    //   fontSize: 12,
    //   fontStyle: 'semibold',
    //   fill: HEXColors.cobaltTertiary,
    //   trustedHtml: true,
    // });

    wrapper.addToLayout(headerRow);
    // .addToLayout(subscription);

    return wrapper;
  }

  private renderBodyInput() {
    return new TextEditView(
      {
        ...this.getTextEditViewDefaultProps(),
        text: this.state.data.config.listBody?.text || '',
        maxLength: 1024,
        margin: { left: 8, top: 8, bottom: 8, right: 8 },
        minHeight: 72,
        caption: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.bodyCaption',
        ),
        captionFill: HEXColors.accent4Normal,
        background: {
          fill: HEXColors.white,
          stroke: HEXColors.accent4Secondary,
          strokeWidth: () => (this.state.data.config.listBody?.text ? 0 : 2),
          cornerRadius: 4,
          strokeAlignment: Alignment.outter,
        },
      },
      undefined,
      (title) => {
        this.bodyText = title.trim();
      },
      () => {
        this.state.set(({ config }) => ({
          config: {
            ...config,
            listBody: {
              ...DEFAULT_LIST_BODY,
              ...config.listBody,
              text: this.bodyText,
            },
          },
        }));
        this.state.save();
        this.logger.log('update body text', {
          value: this.state.data.config.listBody?.text,
        });
      },
      {
        symbolsLimit: ControlVisibility.show,
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
      },
    );
  }

  public renderFooterInput() {
    return new TextEditView(
      {
        ...this.getTextEditViewDefaultProps(),
        text: this.state.data.config.listFooter?.text || '',
        margin: { left: 8, top: 8, bottom: 8, right: 8 },
        maxLength: 60,
        caption: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.footerCaption',
        ),
        captionFill: HEXColors.cobaltTertiary,
      },
      undefined,
      (title) => {
        this.footerText = title.trim();
      },
      () => {
        this.state.set(({ config }) => {
          const listFooter: ListFooter | null = this.footerText.length
            ? {
                ...DEFAULT_LIST_FOOTER,
                ...config.listFooter,
                text: this.footerText,
              }
            : null;

          return { config: { ...config, listFooter } };
        });
        this.state.save();
        this.logger.log('update footer text', {
          value: this.state.data.config.listFooter?.text,
        });
      },
      {
        symbolsLimit: ControlVisibility.show,
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
      },
    );
  }

  private renderButtonTitle() {
    return new HTMLText({
      text: i18next.t(
        'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.buttonTitle',
      ),
      fontSize: 12,
      fontStyle: 'semibold',
      fill: HEXColors.cobalt,
      trustedHtml: true,
    });
  }

  private renderButtonInput() {
    return new TextEditView(
      {
        ...this.getTextEditViewDefaultProps(),
        text: this.state.data.config.listButtonTitle || '',
        height: 36,
        maxLength: 20,
        caption: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappListPlugin.buttonCaption',
        ),
        captionFill: HEXColors.accent4Normal,
        align: 'center',
        singleLine: true,
        background: {
          fill: HEXColors.white,
          stroke: HEXColors.accent4Secondary,
          strokeWidth: () => (this.state.data.config.listButtonTitle ? 0 : 2),
          cornerRadius: 4,
          strokeAlignment: Alignment.outter,
        },
        icon: new TextureShape({
          texture: listIconSvgTexture,
          width: 24,
          height: 24,
        }),
        errorIcon: new TextureShape({
          texture: listErrorIconSvgTexture,
          width: 24,
          height: 24,
        }),
      },
      undefined,
      (title) => {
        this.buttonText = title.trim();
      },
      () => {
        this.state.set(({ config }) => ({
          config: { ...config, listButtonTitle: this.buttonText },
        }));
        this.state.save();

        this.logger.log('update button text', {
          value: this.state.data.config.listButtonTitle,
        });
      },
      {
        symbolsLimit: ControlVisibility.show,
        attributes: ControlVisibility.show,
        emoji: ControlVisibility.show,
      },
    );
  }

  private getTextEditViewDefaultProps(): TextEditViewProps {
    return {
      fontFamily,
      fontSize: 15,
      fill: HEXColors.black,
      verticalAlign: 'center',
      width: this.props.width,
      margin: new Margin({ x: 12 }),
      attributeOnly: false,
      background: {
        cornerRadius: 4,
        fill: HEXColors.white,
      },
      editable: () => this.state.isEditing,
    };
  }
}
