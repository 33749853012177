import { Card } from '@components/Plugins/common';
import { ServiceMessageType, toaster } from '@services/MessageService';
import i18next from 'i18next';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import { Point } from '../../components/Elements/Shapes';
import { getFlowControllerStrict } from '../../PixiFieldRepository';
import { getDefaultBlockCards } from '../utils';
import { BlockContextType } from '@globals';

interface CreateFlowBlockProps {
  localLocation: Point;
  subtype: string;
  contextType?: BlockContextType;
  pluginId: PluginType;
  source: string;
  onCreate?: () => void;
  cards?: Partial<Card>[];
}

export const createFlowBlock = ({
  localLocation,
  subtype,
  contextType,
  pluginId,
  source,
  onCreate,
  cards,
}: CreateFlowBlockProps) => {
  const loadingView = getFlowControllerStrict().addLoadingView(localLocation);
  return getFlowControllerStrict().createBlock({
    position: localLocation,
    subtype,
    contextType,
    extraPluginId: pluginId,
    context: { source },
    onCreated: () => {
      getFlowControllerStrict().removeLoadingView(loadingView);
      onCreate?.();
    },
    onError: () => {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: i18next.t(
            'common.ToasterMessages.CanTCreateTheBlockServerIsNotAvailable',
          ),
        },
      });
      getFlowControllerStrict().removeLoadingView(loadingView);
    },
    cards: [...getDefaultBlockCards(pluginId), ...(cards || [])],
  });
};
