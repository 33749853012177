interface BotUrlOptions {
  encode: boolean;
}

interface BotLinkOptions {
  url: string;
  parameter: string;
}

export abstract class BotLink {
  private readonly url: string;
  private readonly parameter: string;

  constructor({ url, parameter }: BotLinkOptions) {
    this.url = url;
    this.parameter = parameter;
  }

  public getBaseUrl(pageId: string) {
    const baseUrl = new URL(`${this.url}${pageId}`);
    baseUrl.searchParams.set(this.parameter, '');
    return baseUrl;
  }

  public getBotUrl(referral: string, pageId: string, options?: BotUrlOptions) {
    const baseUrl = this.getBaseUrl(pageId);
    baseUrl.searchParams.set(
      this.parameter,
      options?.encode ? encodeURIComponent(referral) : referral,
    );
    return baseUrl;
  }

  public getUrlString(url: URL) {
    const param = url.searchParams.get(this.parameter);
    const u = url.toString().replace(url.searchParams.toString(), '');

    return `${u}${this.parameter}=${param}`;
  }
}
