import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { AdCampaignType, Platform } from '@globals';
import { AdsLearnMoreAnchor, AdsManagerAnchor, NoAdsView } from './NoAdsView';

export interface NoAdsViewProps {
  title: string;
  message1?: string;
  message2?: string;
  buttonTitle?: string;
  onRefresh: () => void;
  pluginId: PluginType;
  platform: Platform.facebook | Platform.instagram;
}

const adTypeToMessage = (type?: AdCampaignType) => {
  switch (type) {
    case AdCampaignType.click_to_messenger:
      return window.i18next.t(
        'NoCampaignsView-string-5279-no-click-to-messenger-campaigns-yet',
      );
    case AdCampaignType.sponsored_message:
      return window.i18next.t(
        'NoCampaignsView-string--161-no-sponsored-message-campaigns-yet',
      );
    case undefined:
      return window.i18next.t(
        'NoCampaignsView-string-1756-no-click-to-messenger-and-sponsored-message-campaigns-yet',
      );
    default:
      throw new Error(`Unsupported ad type ${type}`);
  }
};

type SpecifiedProps = 'title' | 'buttonTitle';

export interface NoCampaignsViewProps
  extends Omit<NoAdsViewProps, SpecifiedProps> {
  adsType?: AdCampaignType;
}

export const NoCampaignsView: React.FC<NoCampaignsViewProps> = ({
  adsType,
  ...props
}) => {
  const { t } = useSafeTranslation();

  return (
    <NoAdsView
      {...props}
      title={adTypeToMessage(adsType)}
      buttonTitle={t(
        'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.refreshCampaigns',
      )}
    >
      <Type as="p" size="15px">
        {t(
          'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.description1',
        )}{' '}
        <AdsManagerAnchor />.{' '}
        {t(
          'modernComponents.FlowBuilder.modals.ChooseAdsPopup.NoCampaignsView.description2',
        )}{' '}
        <AdsLearnMoreAnchor />
        <Spacer factor={8} />
      </Type>
    </NoAdsView>
  );
};
