import { HEXColors } from '@ui/_common/colors';
import { TextureShape } from '../../../components/Elements/Shapes';
import { checkboxSvgTexture } from '../../../assets/textures';
import { LayoutProps, MainLayout } from '../../../components/Elements/Layouts';

interface CheckboxIconProps extends LayoutProps {
  checked?: () => boolean;
  text?: string;
  value?: boolean;
  textTrue?: boolean;
  textFalse?: boolean;
  width?: number;
  onChange?: (value: boolean) => void;
}

export class CheckboxIcon extends MainLayout {
  TEST_NAME = 'CheckboxIcon';

  checkTexture: TextureShape;

  private _checked?(): boolean;
  get checked(): boolean {
    return !!this._checked?.();
  }

  constructor({ checked, ...props }: CheckboxIconProps) {
    super(props);

    this._checked = checked;

    this.checkTexture = new TextureShape({
      width: 16,
      height: 16,
      texture: checkboxSvgTexture,
    });

    this.addToLayout(
      new MainLayout({
        width: 16,
        height: 16,
        background: {
          fill: HEXColors.white,
          cornerRadius: 4,
          strokeWidth: 1,
          stroke: HEXColors.grey,
          onhover: {
            fill: HEXColors.greyLight20,
          },
        },
      }).addToLayout(this.checkTexture, {
        gone: () => !this.checked,
      }),
    );
  }
}
