import { propEq, clone } from 'ramda';
import {
  FullStats,
  FullCounterStats,
  DisplayedStats,
  StatsFieldName,
} from './types';
import {
  DEFAULT_STATS,
  BLOCK_SUBTYPES_WITH_ENABLED_STATS,
  STATS_FIELDS_BY_ENTRY_POINT_PLUGIN_ID,
  STATS_FIELDS_BY_BLOCK_SUBTYPE,
} from './constants';
import { getEntryPointCard } from '../../views/entry-points/common/utils';
import { isFacebookAds, isInstagramAds } from '../../views/utils';
import { Card, FlowTypes } from '../../types';
import { BLOCK_SUBTYPES } from '../../consts';

const prepareDisplayedStats = (
  rawStats: FlowTypes.AggregatedFlowQuery_flowStats_blocks_stats,
) => {
  const { sent, seen, clicked, attempted } = {
    ...DEFAULT_STATS,
    ...rawStats,
  };

  return {
    sent: attempted,
    delivered: sent,
    seen,
    clicked,
  };
};

export const filterStatsFieldByBlock = (
  stats: DisplayedStats,
  block: any,
): DisplayedStats | null => {
  if (
    !BLOCK_SUBTYPES_WITH_ENABLED_STATS.includes(
      (block.subtype as string) || BLOCK_SUBTYPES.send_message,
    )
  ) {
    return null;
  }

  const entryPointPluginId = getEntryPointCard(block.cards)?.plugin_id;
  if (!entryPointPluginId && block.subtype !== BLOCK_SUBTYPES.calendly) {
    return stats;
  }
  const fields =
    STATS_FIELDS_BY_ENTRY_POINT_PLUGIN_ID[entryPointPluginId as string] ||
    STATS_FIELDS_BY_BLOCK_SUBTYPE[block.subtype as string];
  if (!fields) {
    return null;
  }

  return Object.keys(stats).reduce((updatedStats, key) => {
    const fieldName = key as StatsFieldName;
    if (fields.includes(fieldName)) {
      // eslint-disable-next-line no-param-reassign
      updatedStats[fieldName] = stats[fieldName];
    }
    return updatedStats;
  }, {} as DisplayedStats);
};

export interface FullStatsConfig {
  blocks: FlowTypes.AggregatedFlowQuery_bot_flowBlocks[];
  uniqStats: FlowTypes.AggregatedFlowQuery_flowStats | undefined | null;
}

export const getFullBlockStatsShared = (
  blockId: string,
  { blocks, uniqStats }: FullStatsConfig,
) => {
  const block = blocks.find(propEq('id', blockId));

  if (!block) {
    return null;
  }

  if (
    isFacebookAds(getEntryPointCard(block.cards as Card[])?.plugin_id) ||
    isInstagramAds(getEntryPointCard(block.cards as Card[])?.plugin_id)
  ) {
    return null;
  }

  const blockUniqStats =
    uniqStats?.blocks?.find(propEq('id', blockId))?.stats || DEFAULT_STATS;

  if (blockUniqStats.attempted < blockUniqStats.sent) {
    return null;
  }

  const fullStats: FullStats = {
    id: block.id,
    uniqStats: filterStatsFieldByBlock(
      prepareDisplayedStats(blockUniqStats),
      block,
    ),
    notUniqStats: filterStatsFieldByBlock(
      prepareDisplayedStats(block.stats || DEFAULT_STATS),
      block,
    ),
  };
  return fullStats;
};

export const getFullCounterStatsShared = (
  counterId: string,
  blockId: string,
  { blocks, uniqStats }: FullStatsConfig,
) => {
  const blockStats = getFullBlockStatsShared(blockId, { uniqStats, blocks });

  // for incorrect stats (old blocks)
  if (blockStats === null) {
    return undefined;
  }

  const fullCounterStats: FullCounterStats = {
    ...blockStats,
    counter: {
      id: counterId,
      uniqStats: clone(
        prepareDisplayedStats(
          uniqStats?.blocks
            ?.find(propEq('id', blockId))
            ?.counters?.find(propEq('counterId', counterId))?.stats ||
            DEFAULT_STATS,
        ),
      ),
      notUniqStats: clone(
        prepareDisplayedStats(
          blocks
            ?.find(propEq('id', blockId))
            ?.non_unique_counter_stats?.find(propEq('counterId', counterId))
            ?.stats || DEFAULT_STATS,
        ),
      ),
    },
  };

  return fullCounterStats;
};
