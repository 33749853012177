import gql from 'graphql-tag';
import { PLUGIN_FRAGMENT } from '../../../../../Plugins/WhatsappTemplatePlugin/WhatsappTemplatePluginQuery';

export const UPDATE_CARD_TEMPLATE_MUTATION = gql`
  mutation UpdateCardTemplateMutation(
    $botId: String!
    $blockId: String!
    $cardId: String!
    $templateId: String
    $managedTemplateId: String
  ) {
    updateCardTemplate(
      botId: $botId
      blockId: $blockId
      cardId: $cardId
      templateId: $templateId
      managedTemplateId: $managedTemplateId
    ) {
      ...whatsappTemplatePluginFragment
    }
  }
  ${PLUGIN_FRAGMENT}
`;
