import { VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { DocumentPluginConfig } from '../../../../Plugins/DocumentPlugin/DocumentPluginConst';
import { Node } from '../../../Node';
import { pluginWidth } from '../../plugin_consts';
import { FileAttachmentView } from '../../components/FileAttachmentView';
import { HEXColors } from '@ui/_common/colors';
import { pollForCardUploadStatus } from '@common/plugins/cardMediaUpload';
import i18next from 'i18next';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import {
  DOCUMENT_FILE_SIZE_LIMIT_MB,
  SUPPORTED_DOCUMENT_MIME_TYPES,
} from '@utils/PlatformSupportedFiles/documentSupportedTypes';
import { FileAttachmentType } from '@utils/UploadService/types';
import { SUPPORTED_AUDIO_MIME_TYPES } from '@utils/PlatformSupportedFiles/audioSupportedTypes';
import { SUPPORTED_VIDEO_MIME_TYPES } from '@utils/PlatformSupportedFiles/videoSupportedTypes';

export class DocumentPluginView
  extends VLayout
  implements StatefulPluginDelegate<DocumentPluginConfig>
{
  _node: Node;
  public readonly state: StatefulPlugin<DocumentPluginConfig>;
  public readonly TEST_NAME = 'DocumentPluginView';
  private readonly fileAttachmentView: FileAttachmentView;
  private cancelCardPolling: Function | undefined;

  constructor(state: StatefulPlugin<DocumentPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        opacity: 0,
        fill: HEXColors.white,
      },
    });

    this.state = state;
    this.state.setDelegate(this);

    this._node = node;

    const platform = getFlowPlatform()!;

    this.fileAttachmentView = new FileAttachmentView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.components.DocumentPlugin.addMedia',
        { size: DOCUMENT_FILE_SIZE_LIMIT_MB[getFlowPlatform()!] },
      ),
      fileSizeLimitMb: DOCUMENT_FILE_SIZE_LIMIT_MB[getFlowPlatform()!],
      type: FileAttachmentType.file,
      fileData: () => this.state.data.config,
      accept: [
        ...SUPPORTED_DOCUMENT_MIME_TYPES[platform],
        ...SUPPORTED_AUDIO_MIME_TYPES[platform],
        ...SUPPORTED_VIDEO_MIME_TYPES[platform],
      ],
      onFileUploaded: ({ url, filename }) => {
        this.state
          .setAndSave(({ config }) => ({
            config: {
              ...config,
              url,
              filename,
            },
          }))
          .then(() => {
            const { promise, cancel } = pollForCardUploadStatus(
              this.state.data.id,
            );
            this.cancelCardPolling = cancel;
            return promise;
          })
          .catch(this.configInvalidate)
          .finally(() => {
            this.fileAttachmentView.stopLoading();
            this.renderNode();
          });
      },
      isEditing: () => this.state.isEditing,
      isShowFileFormatError: () =>
        !!this.state.data.config.url && !this.state.data.is_valid,
    });
    this.addToLayout(this.fileAttachmentView);
  }

  pluginDidSet() {}

  private configInvalidate() {
    this.state.setAndSave(({ config }) => ({
      config,
      is_valid: false,
    }));
  }

  public hasSignificantChangesInConfig() {
    return !!this.state.data.config.url;
  }

  destroy() {
    this.cancelCardPolling?.();
    this.state.destroy();
    super.destroy();
  }
}
