import { EventEmitter } from '@utils/EventEmitter';

export enum BlockControlViewEmitterEvent {
  show = 'show',
  hide = 'hide',
}

export interface BlockControlViewEmitterEventPayload {
  blockId: string;
}

export const blockControlViewEmitter = new EventEmitter();
