import { PermissionGroup } from '@utils/Permissions';
import { InstagramAdsEntryPointConfig } from '@components/Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { Platform, WelcomeMessageMediaType } from '@globals';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { LayoutProps, VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import {
  facebookAdsEntryPointFragment,
  facebookAdsEntryPointFragment_config as FacebookAdsEntryPointConfig,
} from '../../../../Plugins/FacebookAdsEntryPoint/@types/facebookAdsEntryPointFragment';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { validateIsGivenPermissions } from '../common/utils/validateIsGivenPermission';
import { pluginWidth } from '../../plugin_consts';
import { AdsSelector } from '../common/components/AdsSelector';
import { GreetingAdsTypeSelector } from '../common/components/GreetingAdsTypeSelector';
import { Layout } from '../../../components/Elements/Layouts/Layout';
import { HEXColors } from '@ui/_common/colors';
import { ValidationResult } from '../common/utils/types';

export interface EntryPointFacebookAdsCard
  extends facebookAdsEntryPointFragment {
  isEditing?: boolean;
}

export class EntryPointFacebookAds
  extends VLayout
  implements StatefulPluginDelegate<FacebookAdsEntryPointConfig>
{
  TEST_NAME = 'EntryPointFacebookAds';
  private _node: Node;
  public state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;
  adsSelector: AdsSelector;
  typeSelector: GreetingAdsTypeSelector;
  syncResult: any;

  constructor(
    state: StatefulPlugin<
      FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
    >,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.syncResult = {};
    this.adsSelector = new AdsSelector(state, this._node, Platform.facebook);
    this.addToLayout(this.adsSelector);

    this.typeSelector = new GreetingAdsTypeSelector(
      state,
      this._node,
      Platform.facebook,
    );
    this.addToLayout(this.typeSelector);
  }

  // TODO: remove it after fix typescript error with stateful
  pluginDidSet() {}

  pluginWillSave() {
    this.state.set(({ config }) => ({
      config: {
        ...config,
        welcomeMessageType:
          config.welcomeMessageType || WelcomeMessageMediaType.text,
      },
    }));
  }

  addToAdsSelectorLayout(...args: [Layout, LayoutProps]) {
    this.adsSelector.addToLayout(...args);
  }

  hasSignificantChangesInConfig() {
    return this.state.data.config.campaigns.length !== 0;
  }

  validationError() {
    const entryPointError = this.state.data.validation_details?.fields?.find(
      ({ field }: { field: string }) => !field,
    );

    const logGrandPermissionClick = () => {
      logFlowPluginEvent(
        this.state.data.plugin_id,
        'Grant Permission tooltip click',
        {
          blockId: this._node.id,
          cardId: this.state.data?.id,
        },
      );
    };

    return (
      validateIsPageConnecting(this._node) ||
      validateIsGivenPermissions(
        PermissionGroup.ads,
        window.i18next.t(
          'EntryPointFacebookAds-string--156-grant-chatfuel-permission-to-manage-your-facebook-ads',
        ),
        logGrandPermissionClick,
      ) ||
      (entryPointError
        ? ({
            message: entryPointError.error ?? undefined,
            isBlockError: true,
          } as ValidationResult)
        : undefined)
    );
  }

  onDestroy() {
    this.state.destroy();
  }
}
