import { InteractionEvent, Texture } from 'pixi.js-legacy';
import { TextEditView } from './text_edit_view';
import { buttonHeight, buttonWidth, mainStrokeColor } from './plugin_consts';
import { TextureShape } from '../components/Elements/Shapes';
import { HEXColors } from '../../../modern-ui/_common/colors';
import { resByFunc } from './utils';
import { FontWeightKey } from '../components/Elements/Shapes/types';
import { PropFunc } from '../types';

export type OnClick = (e: InteractionEvent) => void;

export interface ButtonProps {
  width?: number;
  height?: number;
  iconWidth?: number;
  iconHeight?: number;
  iconLeftMargin?: number;
  textColor?: PropFunc<HEXColors>;
  fontStyle?: FontWeightKey;
  bgColor?: PropFunc<HEXColors>;
  cornerRadius?: number;
  strokeWidth?: number;
  img?: Texture;
  title: string;
  onClick?: OnClick;
}

export class ButtonView extends TextEditView {
  TEST_NAME = 'TextEditableButtonView';

  private onClick;
  private readonly textColor;

  constructor({
    width = buttonWidth,
    height = buttonHeight,
    iconWidth = 24,
    iconHeight = 24,
    iconLeftMargin = 16,
    textColor = HEXColors.black,
    fontStyle = 'normal',
    bgColor = HEXColors.white,
    cornerRadius = 4,
    strokeWidth = 0,
    img = undefined,
    title,
    onClick,
  }: ButtonProps) {
    super({
      width,
      height,
      fill: resByFunc(textColor),
      background: {
        cornerRadius,
        fill: bgColor,
        strokeWidth,
        stroke: HEXColors.greyLight20,
        onhover: {
          stroke: mainStrokeColor,
          strokeWidth: 2,
        },
      },
      text: title,
      fontSize: 15,
      fontStyle,
      editable: false,
      verticalAlign: 'center',
      align: 'center',
      cursor: {
        in: 'pointer',
      },
    });

    this.textColor = textColor;

    if (img) {
      this.layout(
        new TextureShape({
          texture: img,
          width: iconWidth,
          height: iconHeight,
        }),
        { margin: { left: iconLeftMargin, top: height / 2 - iconHeight / 2 } },
      );
    }

    if (onClick) {
      this.onClick = onClick;
      this.on('click', onClick);
    }
  }

  setBackgroundName(name: string) {
    super.setBackgroundName(name);
  }

  onBeforeRender() {
    this.setTextColor(resByFunc(this.textColor));
  }

  setDisabled(disabled: boolean) {
    this._disable = disabled;
    this.alpha = disabled ? 0.6 : 1;
    this.interactiveChildren = !disabled;
    this.interactive = !disabled;
  }

  destroy() {
    this.off('click', this.onClick);
    super.destroy();
  }
}
