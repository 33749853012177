import { clone, merge } from 'lodash-es';
import { log } from 'cf-common/src/logger';
import { Block } from '../../../types';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { BLOCK_SUBTYPES } from '../../../consts';
import { WebviewHeightRatio } from '@globals';
import client from '../../../../../common/services/ApolloService';
import { FLOW_STATS_ENABLED_QUERY } from '../../../utils/Statistics/queries';
import {
  getFullBlockStatsShared,
  getFullCounterStatsShared,
} from '../../../utils/Statistics/Statistics';
import {
  DEFAULT_STATS,
  STATS_FIELDS_BY_BLOCK_SUBTYPE,
  STATS_FIELDS_BY_ENTRY_POINT_PLUGIN_ID,
} from '../../../utils/Statistics/constants';
import {
  FlowStatsEnabledQuery,
  FlowStatsEnabledQueryVariables,
} from '../../../utils/Statistics/@types/FlowStatsEnabledQuery';
import { getEntryPointCard } from '../../entry-points/common/utils';
import {
  FullCounterStats,
  StatsFieldName,
} from '../../../utils/Statistics/types';

export interface DisplayedStats {
  sent?: number | null;
  delivered?: number | null;
  seen?: number | null;
  clicked?: number | null;
}

const filterStatsFieldByBlock = (
  stats: DisplayedStats,
  block: any,
): DisplayedStats | null => {
  if (
    ![
      BLOCK_SUBTYPES.entrypoint,
      BLOCK_SUBTYPES.send_message,
      BLOCK_SUBTYPES.comments_autoreply_content,
      BLOCK_SUBTYPES.instagram_comments_autoreply_content,
      BLOCK_SUBTYPES.import_content,
      BLOCK_SUBTYPES.reminder,
      BLOCK_SUBTYPES.visitedProductsReminder,
      BLOCK_SUBTYPES.receipt,
      BLOCK_SUBTYPES.calendly,
      BLOCK_SUBTYPES.calendly_confirmation,
      BLOCK_SUBTYPES.calendly_reminder,
      BLOCK_SUBTYPES.shopify_discount,
      BLOCK_SUBTYPES.postPurchase,
      BLOCK_SUBTYPES.back_in_stock_optin_confirmation,
      BLOCK_SUBTYPES.back_in_stock_message,
      BLOCK_SUBTYPES.back_in_stock_otn,
    ].includes((block.subtype as string) || BLOCK_SUBTYPES.send_message)
  ) {
    return null;
  }

  const entryPointPluginId = getEntryPointCard(block.cards)?.plugin_id;
  if (!entryPointPluginId && block.subtype !== BLOCK_SUBTYPES.calendly) {
    return stats;
  }
  const fields =
    STATS_FIELDS_BY_ENTRY_POINT_PLUGIN_ID[entryPointPluginId as string] ||
    STATS_FIELDS_BY_BLOCK_SUBTYPE[block.subtype as string];

  if (!fields) {
    return null;
  }

  return Object.keys(stats).reduce((updatedStats, key) => {
    const fieldName = key as StatsFieldName;
    if (fields.includes(fieldName)) {
      // eslint-disable-next-line no-param-reassign
      updatedStats[fieldName] = stats[fieldName];
    }
    return updatedStats;
  }, {} as DisplayedStats);
};

export const isFlowStatsEnabled = () => {
  // TODO: fixme after flow in flow controller updates on flow change
  // old version
  // return !!getFlowControllerStrict().flow.botFeatures.flow_stats;

  const { botId, bot } = getFlowControllerStrict().flow;
  try {
    const data = client.readQuery<
      FlowStatsEnabledQuery,
      FlowStatsEnabledQueryVariables
    >({
      query: FLOW_STATS_ENABLED_QUERY,
      variables: {
        botId,
      },
    });
    return Boolean(data?.bot?.features?.flow_stats && bot.status?.page);
  } catch (error) {
    log.warn({ error, msg: 'No cache data for flow_stats' });
    return false;
  }
};

export const needShowBlockStats = (block: Block) =>
  isFlowStatsEnabled() &&
  !!filterStatsFieldByBlock(DEFAULT_STATS, block) &&
  ![
    BLOCK_SUBTYPES.comments_autoreply_content,
    BLOCK_SUBTYPES.instagram_comments_autoreply_content,
  ].includes(block.subtype ?? '');

export const getFullBlockStats = (blockId: string) => {
  const { flow } = getFlowControllerStrict();
  return getFullBlockStatsShared(blockId, flow);
};

export const getFullCounterStats = (counterId: string, blockId: string) => {
  if (!isFlowStatsEnabled()) {
    return undefined;
  }
  const { flow } = getFlowControllerStrict();
  return getFullCounterStatsShared(counterId, blockId, flow);
};

const sumNullable = (a?: number | null, b?: number | null) => {
  if (
    typeof a === 'number' &&
    Number.isFinite(a) &&
    typeof b === 'number' &&
    Number.isFinite(b)
  ) {
    return a + b;
  }
  return a;
};

const sumStats = (base: DisplayedStats | null, add: DisplayedStats | null) => {
  if (base && add) {
    return {
      sent: sumNullable(base.sent, add.sent),
      delivered: sumNullable(base.delivered, add.delivered),
      seen: sumNullable(base.seen, add.seen),
      clicked: sumNullable(base.clicked, add.clicked),
    };
  }

  return base;
};

export const sumCounterStats = (
  base?: FullCounterStats,
  add?: FullCounterStats,
) => {
  if (base && add) {
    return merge(clone(base), {
      counter: {
        notUniqStats: sumStats(
          base.counter.notUniqStats,
          add.counter.notUniqStats,
        ),
        uniqStats: sumStats(base.counter.uniqStats, add.counter.uniqStats),
      },
    });
  }

  return base;
};

interface ButtonConfig {
  title: string | null;
  block_id: string[] | null;
  block_ids: string[] | null;
  url: string | null;
  webview_height_ratio: WebviewHeightRatio | null;
  phone_number: string | null;
  attribute_update_list: any[];
}

export const isWebExtensionButton = ({
  url,
  block_ids,
  block_id,
  attribute_update_list,
}: ButtonConfig) => {
  const buttonWhitelist =
    getFlowControllerStrict().flow.bot.button_whitelist ?? [];

  const hasAdditionalActions = !!(
    block_ids?.length ||
    block_id?.length ||
    attribute_update_list?.length
  );
  return url?.length && buttonWhitelist.includes(url) && !hasAdditionalActions;
};
