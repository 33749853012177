import i18next from 'i18next';
import { FlowBuilderAttribute } from '@ui/TextWithAttributesEditor/FlowBuilderAttribute';
import { CardButtonType } from '@globals';
import { MagicButton } from '../../../FlowBuilderOverlay/overlays/ButtonPopupOverlay/MagicButton';

export const CALENDLY_EVENTS_LIST = () => [
  new MagicButton({
    id: CardButtonType.calendly_cancel,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.plugins.calendly.cancel',
    ),
  }),
  new MagicButton({
    id: CardButtonType.calendly_reschedule,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.plugins.calendly.reschedule',
    ),
  }),
  new MagicButton({
    id: CardButtonType.calendly_join_meeting,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.plugins.calendly.join_meeting',
    ),
  }),
];

export const scheduleCalendlyMagicButton = () =>
  new MagicButton({
    id: CardButtonType.calendly_schedule,
    title: i18next.t(
      'modernComponents.FlowBuilder.views.plugins.calendly.schedule',
    ),
  });

enum CalendlyAttrs {
  eventDate = 'calendly event date',
  eventTime = 'calendly event time',
  eventDuration = 'calendly event duration',
  inviteeName = 'calendly invitee name',
  inviteeEmail = 'calendly invitee email',
  inviteeAnswer1 = 'calendly invitee answer 1',
  inviteeAnswer2 = 'calendly invitee answer 2',
  inviteeAnswerN = 'calendly invitee answer n',
}

export const CalendlyAttributes = {
  eventDate: new FlowBuilderAttribute({ name: CalendlyAttrs.eventDate }),
  eventTime: new FlowBuilderAttribute({ name: CalendlyAttrs.eventTime }),
  eventDuration: new FlowBuilderAttribute({
    name: CalendlyAttrs.eventDuration,
  }),
  inviteeName: new FlowBuilderAttribute({ name: CalendlyAttrs.inviteeName }),
  inviteeEmail: new FlowBuilderAttribute({ name: CalendlyAttrs.inviteeEmail }),
  inviteeAnswer1: new FlowBuilderAttribute({
    name: CalendlyAttrs.inviteeAnswer1,
  }),
  inviteeAnswer2: new FlowBuilderAttribute({
    name: CalendlyAttrs.inviteeAnswer2,
  }),
  inviteeAnswerN: new FlowBuilderAttribute({
    name: CalendlyAttrs.inviteeAnswerN,
  }),
};

export const CALENDLY_PREDEFINED_ATTRIBUTES = {
  default: Object.values(CalendlyAttributes),
};
