import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { log } from 'cf-common/src/logger';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { tooltipScaled } from '../../helpers/TooltipHelpers';
import { VLayout } from '../../../components/Elements/Layouts';
import {
  EntryPointPersistentMenuCard,
  PersistentMenuLocaleItem,
} from './types';
import { Node } from '../../../Node';
import { addButtonControl } from '../../helpers/ControlsHelpers';
import { LocaleItem } from './LocaleItem';
import { CreateMenuViewOverlay } from '../../menu_view_overlay';
import { DEFAULT_LANGUAGE, LANGUAGES } from './languages';
import { hasNonEmptyButtons } from '../../validation';
import { ButtonView } from '../../button_view';
import { loaderWhiteSvgTexture } from '../../../assets/textures';
import { Messages, toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { isValidUrl } from '@utils/UrlUtils';
import { Loader } from '../../loader';
import { buttonHeight, pluginWidth } from '../../plugin_consts';
import { PlusButtonView } from '../../plus_button_view';
import { HEXColors } from '@ui/_common/colors';
import { Translation } from '@translations';

interface EntryPointPersistentMenuOptons {
  testName: string;
  updateButtonTextKey: Translation;
}

const stringsToDefaultItems = memoize((strings: string[]) =>
  strings.map((str) => ({ id: str, title: str })),
);

export const updatePersistentMenuCard = (
  node: Node,
  card: EntryPointPersistentMenuCard,
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void,
) => {
  // eslint-disable-next-line no-param-reassign
  delete card.synced;
  node.updateCard(card, onSuccess, onError);
};

export const FB_LIMIT_TT_TEXT = () =>
  i18next.t(
    'modernComponents.FlowBuilder.views.entryPoints.PersistentMenu.warningRateLimit',
  );

export abstract class EntryPointPersistentMenu extends VLayout {
  public TEST_NAME: string;

  public readonly _node: Node;

  public readonly _card: EntryPointPersistentMenuCard;

  private readonly _itemsBox: VLayout;

  private readonly _updateButtonView: ButtonView;

  private readonly _loader: Loader;

  constructor(
    card: EntryPointPersistentMenuCard,
    node: Node,
    { testName, updateButtonTextKey }: EntryPointPersistentMenuOptons,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._card = card;
    this._node = node;
    this.TEST_NAME = testName;

    if (!this._card.config.localized_items) {
      this._card.config.localized_items = [];
    }

    this._itemsBox = new VLayout({});

    const addLocalizationButton = new PlusButtonView(
      i18next.t('EntryPointPersistentMenu-string-4679-＋-add-localization'),
      pluginWidth,
    );

    addLocalizationButton.on('click', (event: any) => {
      event.stopPropagation();
      const usedLanguages = this._card.config.localized_items.map(
        ({ language }) => language,
      );
      new CreateMenuViewOverlay({
        onChoose: (item) => {
          this.addLocalization(item.id);
        },
        items: stringsToDefaultItems(
          LANGUAGES().filter((language) => !usedLanguages.includes(language)),
        ),
      }).showOn(event.data.global);
    });

    addLocalizationButton.on('pointerdown', (event: any) => {
      event.stopPropagation();
    });

    this.addToLayout(this._itemsBox);

    this.createLocalizationViews();

    this.addToLayout(addLocalizationButton, {
      margin: {
        top: 12,
      },
    });

    this._updateButtonView = new ButtonView({
      title: i18next.t(updateButtonTextKey),
      width: pluginWidth,
      bgColor: HEXColors.blue,
      textColor: HEXColors.white,
      onClick: (event) => {
        event.stopPropagation();
        /* eslint-disable no-param-reassign */
        card.refresh = true;
        this._loader.start();
        this.renderNode();
        updatePersistentMenuCard(
          node,
          card,
          () => {
            card.refresh = false;
            card.synced = true;
            this._loader.stop();
            this.renderNode();
          },
          (error) => {
            card.refresh = false;
            this._loader.stop();
            this.renderNode();

            toaster.show({
              type: ServiceMessageType.error,
              payload: { message: Messages.facebookSyncFailed },
            });

            log.error({
              error,
              msg: 'Error Persistent menu sync',
              data: { label: 'flow_persistent_menu' },
            });
          },
        );
        /* eslint-enable no-param-reassign */

        logFlowPluginEvent(this._card.plugin_id, 'update persistent menu', {
          blockId: this._node.id,
          cardId: this._card?.id,
        });
      },
    });

    tooltipScaled({
      view: this._updateButtonView,
      text: FB_LIMIT_TT_TEXT(),
    });

    this.addToLayout(this._updateButtonView, {
      margin: { top: 12 },
      gone: () => {
        const { enabled, isEditing, refresh } = this._card;
        return !!(!enabled || !isEditing || refresh || this.validationError());
      },
    });

    this._loader = new Loader(
      {
        width: pluginWidth,
        height: buttonHeight,
        background: {
          fill: HEXColors.blue,
          cornerRadius: 4,
        },
      },
      loaderWhiteSvgTexture,
    );

    this.addToLayout(this._loader, {
      margin: { top: 12 },
      gone: () => !this._card.refresh,
    });
  }

  createLocalizationView(config: PersistentMenuLocaleItem) {
    const itemView = new LocaleItem(config, this._node, this._card);
    addButtonControl(
      itemView,
      this._itemsBox,
      this._card.config.localized_items,
      this._node,
      this._card,
      () => ({
        x: -40,
        y: 0,
      }),
      config.language === DEFAULT_LANGUAGE,
      true,
      () => {
        this.setPersistentMenuUnsynced();
        logFlowPluginEvent(this._card.plugin_id, 'remove localization', {
          language: config.language,
          blockId: this._node.id,
          cardId: this._card?.id,
        });
      },
      (_, start, end) => {
        this.setPersistentMenuUnsynced();
        logFlowPluginEvent(this._card.plugin_id, 'drag localization', {
          start,
          end,
          language: config.language,
          blockId: this._node.id,
          cardId: this._card?.id,
        });
      },
    );
    this._itemsBox.addToLayout(itemView, { margin: { bottom: 8 } });
  }

  createLocalizationViews() {
    this._card.config.localized_items.forEach((item) => {
      this.createLocalizationView(item);
    });
  }

  addLocalization(language: string) {
    const newItem = {
      language,
      user_input_disabled: false,
      items: [
        {
          title: '',
        },
      ],
    };
    this._card.config.localized_items.push(newItem);
    this.createLocalizationView(newItem);
    updatePersistentMenuCard(this._node, this._card);
    this.renderNode();

    logFlowPluginEvent(this._card.plugin_id, 'add localization', {
      language,
      blockId: this._node.id,
      cardId: this._card?.id,
    });
  }

  validationError() {
    return (
      (this._card.config.localized_items.every(({ items }) =>
        items.every(
          (item) => !!item.block_ids?.[0] || isValidUrl(item.url || ''),
        ),
      )
        ? undefined
        : {
            message: i18next.t(
              'modernComponents.FlowBuilder.views.entryPoints.PersistentMenu.warningMustLeadToBlock',
            ),
            isBlockError: true,
          }) ||
      (this._card.config.localized_items.every(({ items }) =>
        items.every((item) => !!item.title?.trim()),
      )
        ? undefined
        : {
            message: i18next.t(
              'EntryPointPersistentMenu-string-1462-all-menu-buttons-must-have-a-title',
            ),
            isBlockError: true,
          })
    );
  }

  hasSignificantChangesInConfig() {
    return this._card.config.localized_items.some(({ items }) =>
      hasNonEmptyButtons(items),
    );
  }

  setPersistentMenuUnsynced() {
    delete this._card.synced;
    this.renderNode();
  }

  onBeforeRender() {
    this._updateButtonView?.setDisabled(!!this._card.synced);
  }
}
