import i18next from 'i18next';
import { tooltipScaled } from './helpers/TooltipHelpers';
import { HLayout } from '../components/Elements/Layouts';
import { TextureShape, HTMLText } from '../components/Elements/Shapes';
import { blockStartingTexture } from '../assets/textures';
import { inactiveStrokeColor } from './plugin_consts';
import { HEXColors } from '@ui/_common/colors';

export class StartingPointView extends HLayout {
  TEST_NAME = 'StartingPointView';

  constructor() {
    super({
      height: 30,
      background: {
        fill: HEXColors.white,
        cornerRadius: 15,
        stroke: inactiveStrokeColor,
        strokeWidth: 1,
      },
    });
    this.layout(
      new TextureShape({
        texture: blockStartingTexture,
        width: 20,
        height: 20,
      }),
      { margin: { left: 5, top: 5 } },
    );
    this.layout(
      new HTMLText({
        text: i18next.t('starting_point_view-string-8020-start'),
        fill: HEXColors.black,
        height: 30,
        fontSize: 15,
        align: 'center',
        verticalAlign: 'center',
      }),
      { margin: { left: 8, right: 12 } },
    );

    tooltipScaled({
      view: this,
      text: i18next.t(
        'modernComponents.FlowBuilder.views.startingPointView.tooltip',
      ),
    });
  }
}
