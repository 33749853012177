import i18next from 'i18next';
import { propEq } from 'ramda';
import { QUANT_ITEMS_WITH_NONE } from '@utils/DateTime/quant';
import { Platform } from '@globals';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { removeTooltip, tooltipScaled } from '../../helpers/TooltipHelpers';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { Checkbox } from '../../kit/Checkbox';
import { Dropdown } from '../../kit/Dropdown';
import { PluginType } from '../../../../Plugins/common/PluginTypes';
import { DelayInput } from '../../kit/DelayInput';
import { pluginWidth } from '../../plugin_consts';
import { TextureShape } from '../../../components/Elements/Shapes';
import { infoSvgTexture } from '../../../assets/textures';
import { ContentTypeItem, PrivateReplyCard } from './types';
import { HEXColors } from '@ui/_common/colors';

interface PrivateReplyConfig {
  platform: Platform;
  contentTypeItems: ContentTypeItem[];
}

export class PrivateReplyPlugin extends VLayout {
  TEST_NAME = 'PrivateReplyPlugin';
  _card: PrivateReplyCard;
  private enableStateCheckbox: Checkbox;

  constructor(card: PrivateReplyCard, node: Node, config: PrivateReplyConfig) {
    super({
      background: {
        fill: HEXColors.white,
      },
    });

    this._card = card;

    this.enableStateCheckbox = new Checkbox({
      text: `<b>${i18next.t(
        'modernComponents.FlowBuilder.views.plugins.PrivateReplyPlugin.privateReply',
      )}</b>`,
      value: !!card.config.enabled,
      textTrue: undefined,
      textFalse: undefined,
      width: 125,
      onChange: (value: boolean) => {
        // eslint-disable-next-line no-param-reassign
        card.config.enabled = value;
        node.updateCard(card);
        this.renderNode();
        logFlowPluginEvent(
          PluginType.private_reply_entry_point,
          'Enable status change',
          {
            blockId: node.id,
            cardId: card?.id,
            enable: value,
          },
        );
      },
    });

    const titleView = new HLayout({});
    const infoIconContainer = new HLayout({});

    titleView.addToLayout(this.enableStateCheckbox).addToLayout(
      infoIconContainer.addToLayout(
        new TextureShape({
          texture: infoSvgTexture,
          width: 14,
          height: 14,
        }),
      ),
      {
        margin: { top: 1 },
      },
    );

    tooltipScaled({
      view: infoIconContainer,
      text: i18next.t(
        config.platform === Platform.instagram
          ? 'modernComponents.FlowBuilder.views.plugins.PrivateReplyPlugin.instagramPrivateReplyTooltip'
          : 'modernComponents.FlowBuilder.views.plugins.PrivateReplyPlugin.facebookPrivateReplyTooltip',
      ),
    });

    this.addToLayout(titleView, {
      margin: { bottom: 16, top: 8 },
    });

    this.addToLayout(
      new VLayout({
        width: pluginWidth,
        background: {
          fill: HEXColors.greyLight20,
          cornerRadius: 12,
        },
      }).addToLayout(
        new DelayInput({
          quantItems: QUANT_ITEMS_WITH_NONE(),
          initialValue: card.config.delay_ms,
          onChange: (value) => {
            // eslint-disable-next-line no-param-reassign
            card.config.delay_ms = value;
            node.updateCard(card);
            logFlowPluginEvent(
              PluginType.private_reply_entry_point,
              'Delay change',
              {
                blockId: node.id,
                cardId: card?.id,
                delay_ms: value,
              },
            );
          },
          max: 1000 * 60 * 60 * 24 * 7, // 7 days
          editable: () => !!card.isEditing,
        }),
        { margin: { left: 16, top: 16, bottom: 16 } },
      ),
      {
        margin: { bottom: 10 },
        gone: () => !card.config.enabled,
      },
    );

    this.addToLayout(
      new Dropdown<ContentTypeItem>({
        items: config.contentTypeItems,
        defaultSelectedItem:
          config.contentTypeItems.find(
            ({ id }) => card.config.content_type === id,
          ) || config.contentTypeItems[0],
        onChange: ({ id: selectedContentType }) => {
          // eslint-disable-next-line no-param-reassign
          card.config.content_type = selectedContentType;
          if (
            !node.block.cards.some(propEq('plugin_id', selectedContentType))
          ) {
            const index = node.block.cards.findIndex(
              propEq('plugin_id', PluginType.private_reply_entry_point),
            );
            node.blockView.addPlugin(selectedContentType, index + 1, () => {
              node.updateCard(card);
            });
          } else {
            node.updateCard(card);
          }

          logFlowPluginEvent(
            PluginType.private_reply_entry_point,
            'Content type change',
            {
              blockId: node.id,
              cardId: card?.id,
              contentType: selectedContentType,
            },
          );
        },
        background: {
          opacity: 0,
        },
        label: i18next.t(
          'modernComponents.FlowBuilder.views.plugins.PrivateReplyPlugin.type',
        ),
        isEditing: () =>
          node.block.cards
            .filter(
              ({ plugin_id }) =>
                plugin_id !== PluginType.public_reply_entry_point,
            )
            .some(({ isEditing }) => isEditing),
      }),
      {
        gone: () => !card.config.enabled,
      },
    );
  }

  destroy() {
    removeTooltip(this.enableStateCheckbox);
    super.destroy();
  }
}
