import i18next from 'i18next';
import { propEq } from 'ramda';
import { InstagramAdsEntryPointConfig } from '@components/Plugins/InstagramAdsEntryPoint/InstagramAdsEntryPointConst';
import { FacebookAdsEntryPointConfig } from '@components/Plugins/FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import { Platform } from '@globals';
import { infoSvgTexture } from '../../../../../assets/textures';
import {
  removeTooltip,
  tooltipScaled,
} from '../../../../helpers/TooltipHelpers';
import { Node } from '../../../../../Node';
import { logFlowPluginEvent } from '../../../../../utils/Analytics';
import { StatefulPlugin } from '../../../../../StatefulPlugin';
import {
  MainLayout,
  VLayout,
} from '../../../../../components/Elements/Layouts';
import { pluginWidth } from '../../../../plugin_consts';
import { Dropdown } from '../../../../kit/Dropdown';
import { TextureShape } from '../../../../../components/Elements/Shapes';
import entryPointInstagramAdsFaqPng from '../../../../../assets/entry_point/instagram_faq_greeting.png';
import {
  getMessageTypeIdsByPlatform,
  getMessageTypesByPlatform,
  getInstagramMessageTypeId,
} from './utils';
import {
  MESSAGE_CONFIG,
  FacebookMessageTypeIds,
  InstagramMessageTypeIds,
} from './consts';
import { HEXColors } from '@ui/_common/colors';

export class GreetingAdsTypeSelector extends VLayout {
  state: StatefulPlugin<
    FacebookAdsEntryPointConfig | InstagramAdsEntryPointConfig
  >;

  _node: Node;
  textureShape: MainLayout | undefined;

  constructor(state: any, node: Node, platform: Platform) {
    super({
      width: pluginWidth,
      cursor: {
        in: 'default',
      },
    });
    this.state = state;
    this._node = node;

    const messageTypes = getMessageTypesByPlatform(
      platform,
      state.data.plugin_id,
    );
    const dropdown = new Dropdown({
      defaultSelectedItem:
        messageTypes.find(
          propEq('id', getMessageTypeIdsByPlatform(platform, this.state.data)),
        ) || messageTypes[0],
      items: messageTypes,
      onChange: (selectedItem) => {
        const messageConfig =
          MESSAGE_CONFIG[
            selectedItem.id as FacebookMessageTypeIds | InstagramMessageTypeIds
          ];
        this.state.set(({ config }) => ({
          config: {
            ...config,
            welcomeMessageType: messageConfig.type,
            customer_action_type: messageConfig.customer_action_type,
          },
        }));
        this.state.save();
        this.renderNode();

        logFlowPluginEvent(state.data.plugin_id, 'Greeting type changed', {
          greeting_type: messageConfig.type,
          customer_action_type: messageConfig.customer_action_type,
        });
      },
      isEditing: () => state.isEditing,
      label: window.i18next.t('GreetingAdsTypeSelector-string--217-greeting'),
      fontStyle: 'semibold',
      background: {
        fill: HEXColors.white,
      },
    });
    this.addToLayout(dropdown, {
      margin: { top: 16, bottom: 8 },
    });

    if (
      platform === Platform.instagram &&
      getInstagramMessageTypeId(this.state.data) ===
        InstagramMessageTypeIds.TEXT_AND_ICE_BREAKERS
    ) {
      this.textureShape = new MainLayout().addToLayout(
        new TextureShape({
          texture: infoSvgTexture,
          width: 16,
          height: 16,
        }),
      );

      dropdown.addToLayout(this.textureShape, {
        margin: { top: 3, left: 8 },
      });
    }

    this.updateView();
  }

  updateView() {
    if (this.textureShape) {
      removeTooltip(this.textureShape);
      tooltipScaled({
        view: this.textureShape,
        overTimeout: 200,
        text: `<img src="${entryPointInstagramAdsFaqPng}" width="216" /><br/><br/> ${i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.common.GreetingAdsTypeSelector.addFAQ',
        )}`,
      });
    }

    this.renderNode();
  }
}
