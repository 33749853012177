import { logFlowPluginEvent } from '../utils/Analytics';
import { HLayout, MainLayout } from '../components/Elements/Layouts';
import { galleryCardControl } from './helpers/ControlsHelpers';
import {
  controlLayout,
  controlMargin,
  controlWidth,
} from './card_control_view';
import {
  cropSvgTexture,
  dragHorizontalSvgTexture,
  addGalleryImageSvgTexture,
  removeSvgTexture,
} from '../assets/textures';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { getGalleryItems, isGalleryLimitOverflow } from './gallery_view';
import { addGalleryCard } from './utils';

export class GalleryCardControlView extends HLayout {
  TEST_NAME = 'GalleryCardControlView';
  constructor() {
    super({
      height: controlWidth,
      cursor: { in: 'pointer' },
      background: {
        cornerRadius: 5,
        fill: 'white',
        stroke: '#f0f0f0',
        strokeWidth: 1,
      },
    });
    this._arrow = controlLayout(dragHorizontalSvgTexture, { in: 'ew-resize' });
    this.removeBtn = controlLayout(removeSvgTexture);
    this._addImage = controlLayout(addGalleryImageSvgTexture);
    this._crop = controlLayout(cropSvgTexture);

    let margin = { top: controlMargin, right: controlMargin };
    this.layout(new MainLayout({ width: controlMargin }))
      .layout(this._addImage, {
        margin,
        gone: (layout) => {
          if (
            this._card._card?.plugin_id ===
              PluginType.facebook_shops_product_list ||
            !layout._card
          ) {
            return true;
          }
          return isGalleryLimitOverflow(layout._card);
        },
      })
      .layout(this._arrow, {
        margin: margin,
        gone: (layout) =>
          layout._card ? getGalleryItems(layout._card)?.length <= 1 : true,
      })
      .layout(this.removeBtn, {
        margin: margin,
        gone: (layout) =>
          layout._card
            ? getGalleryItems(layout._card)?.length <= 1 &&
              !layout._card?._config?.products
            : true,
      })
      .layout(this._crop, {
        margin: margin,
        gone: () =>
          !this._galleryCard ||
          !this._galleryCard._galleryCard ||
          !this._galleryCard._galleryCard.image_url,
      });

    this._crop.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._crop.on('click', (e) => {
      e.stopPropagation();
      this._galleryCard.crop();
      this.hide();
      logFlowPluginEvent(
        this._card._card?.plugin_id,
        'crop',
        this.getPropertyBag(),
      );
    });

    this.removeBtn.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this.removeBtn.on('click', (e) => {
      e.stopPropagation();
      this._card.removeCard(this._galleryCard);
      this.hide();
      logFlowPluginEvent(
        this._card._card?.plugin_id,
        'remove card',
        this.getPropertyBag(),
      );
    });
    this._addImage.on('pointerdown', (e) => {
      e.stopPropagation();
    });
    this._addImage.on('click', (e) => {
      e.stopPropagation();
      switch (this._card._card?.plugin_id) {
        case PluginType.gallery: {
          const index = addGalleryCard(this._card, this._galleryCard);
          this.hide();
          const propertyBag = this.getPropertyBag();
          propertyBag.idx = index;
          logFlowPluginEvent(
            this._card._card?.plugin_id,
            'add card',
            propertyBag,
          );
          break;
        }
        case PluginType.facebook_shops_product_list: {
          const index =
            this._card.productCardsLayout.indexOf(this._galleryCard) + 1;
          this._card.addCard(index);
          this.hide();
          break;
        }
        default:
          break;
      }
    });
  }

  getPropertyBag() {
    return { blockId: this._card._node.id, source: 'gallery card menu' };
  }

  setGalleryCard(galleryCard, card) {
    (card._cardsLayout || card.productCardsLayout).controlDragging(
      this,
      galleryCard,
    );
    this.on('dragmove', () => card._node.updateLines());
    this.on('dragend', () => {
      galleryCardControl().updatePosition();
      this._card.renderNode();
    });
    this._galleryCard = galleryCard;
    this._card = card;
    this.renderNode();
  }

  onElementPositionChanged(galleryCard, startIdx, idx) {
    this._card.moveCard(galleryCard, startIdx, idx);
    let propertyBag = this.getPropertyBag();
    propertyBag.start = startIdx;
    propertyBag.end = idx;
    logFlowPluginEvent(this._card._card?.plugin_id, 'drag', propertyBag);
  }
}
