import { VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import {
  sendEmailPluginFragment_config as EmailPluginConfig,
  sendEmailPluginFragment_config as SendEmailPluginConfig,
} from '../../../../Plugins/EmailPlugin/@types/sendEmailPluginFragment';
import { Node } from '../../../Node';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { TextEditView } from '../../text_edit_view';
import i18next from 'i18next';
import { pluginEmailSvgTexture } from '../../../assets/textures';
import { tooltips } from '../../Menu/menu_view';
import { HEXColors } from '@ui/_common/colors';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { subscribeEditPlugin } from '../../../StatefulPlugin/ApolloState/utils';
import {
  attributeWrapper,
  wrapAllAttributes,
} from '@ui/TextWithAttributesEditor/attributesBoundariesUtils';
import { notEmptyString } from '../../validation';

export class SendEmailPlugin
  extends VLayout
  implements StatefulPluginDelegate<SendEmailPluginConfig>
{
  TEST_NAME = 'EmailPluginView';
  public readonly state: StatefulPlugin<SendEmailPluginConfig>;
  public readonly _node: Node;
  private readonly unsubscribeEditPlugin: VoidFunction;
  private readonly titleLayout: PluginTitleLayout;
  private readonly emailsView: TextEditView;
  private readonly subjectView: TextEditView;
  private readonly messageView: TextEditView;

  constructor(state: StatefulPlugin<SendEmailPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this.state = state;
    this.state.setDelegate(this);
    this._node = node;

    this.titleLayout = new PluginTitleLayout(
      false,
      i18next.t('email_plugin-string-1888-notify-admin-by-email'),
      pluginEmailSvgTexture,
      this.state.data,
      tooltips().email,
    );
    this.layout(this.titleLayout, {
      margin: { top: 15, bottom: 5, left: 10 },
    });

    this.emailsView = new TextEditView({
      width: pluginWidth,
      fontStyle: 'semibold',
      fontSize: 15,
      editable: false,
      margin: { left: 15, bottom: 5 },
      caption: i18next.t('email_plugin-string-2027-email-addresses'),
    });
    this.layout(this.emailsView);

    this.subjectView = new TextEditView({
      width: pluginWidth,
      fontSize: 15,
      margin: { left: 15, right: 15, bottom: 5 },
      caption: i18next.t('email_plugin-string--203-subject'),
      editable: false,
      singleLine: true,
    });
    this.layout(this.subjectView);

    this.messageView = new TextEditView({
      width: pluginWidth,
      fontSize: 15,
      margin: { left: 15, right: 15, bottom: 17 },
      caption: i18next.t('email_plugin-string--167-message'),
      captionFill: HEXColors.greyDark,
      editable: false,
      singleLine: true,
    });
    this.layout(this.messageView);

    this.on('click', () => {
      getPixiFieldStrict().fixBlockPosition(
        node.blockView,
        getPanelWidth(this.state.data.plugin_id),
      );
      editPluginInEditorPanel(node.id, this.state.data);
    });

    this.unsubscribeEditPlugin = subscribeEditPlugin<EmailPluginConfig>(
      this.state.data.id,
      (card) => {
        this.state.data.config = card.config;
        this.state.data.is_valid = card.is_valid;
        this.renderNode();
      },
      () => {
        this.state.data.is_valid = false;
        this.renderNode();
      },
    );
  }

  pluginDidSet() {
    this.renderNode();
  }

  validationError() {
    const { emails, title, body } = this.state.data.config;

    let errorMessage = '';
    if (!emails?.length) {
      errorMessage += i18next.t('email_plugin-string-6939-add-email-addresses');
    }
    if (!title) {
      errorMessage += `${errorMessage.length ? '<br/>' : ''}${i18next.t(
        'email_plugin-Template--122-add-subject',
      )}`;
    }
    if (!body) {
      errorMessage += `${errorMessage.length ? '<br/>' : ''}${i18next.t(
        'email_plugin-Template-1593-add-message',
      )}`;
    }
    if (errorMessage) {
      return errorMessage;
    }

    return 'is_valid' in this.state.data && !this.state.data.is_valid;
  }

  onBeforeRender() {
    const { emails, title, body } = this.state.data.config;
    this.emailsView.text(emails?.length ? emails.join('\n') : '');
    this.subjectView.text(title ? this.renderWithAttributes(title) : '');
    this.messageView.text(body ? this.renderWithAttributes(body) : '');
  }

  renderWithAttributes(str: string) {
    return wrapAllAttributes(str, attributeWrapper(false, true));
  }

  hasSignificantChangesInConfig() {
    const { emails, title, body } = this.state.data.config;
    return (
      (emails?.length ?? 0) > 0 || notEmptyString(title) || notEmptyString(body)
    );
  }

  destroy() {
    this.unsubscribeEditPlugin();
    this.state.destroy();
    super.destroy();
  }
}
