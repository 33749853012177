import { BLOCK_SUBTYPES } from '../../../../consts';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';

export const isFlowHasCalendlyBlock = () => {
  const { _nodesMap } = getFlowControllerStrict();

  return Object.values(_nodesMap).some(
    ({ block }) => block.subtype === BLOCK_SUBTYPES.calendly,
  );
};
