/* eslint-disable no-param-reassign */
import {
  defaultObj,
  LayoutProps,
  MarginType,
  MainLayout,
} from '../components/Elements/Layouts';
import { InteractionEvent } from 'pixi.js-legacy';
import debounce from 'lodash-es/debounce';
import nanoid from 'nanoid';
import { FLOW_BUILDER_LINK_CLASS, resByFunc, wrapAllLinks } from './utils';
import { HEXColors } from '@ui/_common/colors';
import { assert } from '@utils/Assert';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { MAX_SCALE } from '../PixiField';
import { getPixiFieldStrict } from '../PixiFieldRepository';
import {
  overlayEventEmitter,
  OverlayComponentEvent,
  FlowBuilderOverlayEvent,
  OverlayType,
  ControlVisibility,
  OverlayEventPayload,
} from '../FlowBuilderOverlay';
import {
  FONT_STYLE_TO_WEIGHT,
  HTMLText,
  HTMLTextProps,
  TextureShape,
} from '../components/Elements/Shapes';
import { Point, PropFunc } from '../types';
import { FontWeightKey } from '../components/Elements/Shapes/types';

export interface TextOutsideControlsVisibility {
  attributes?: ControlVisibility;
  emoji?: ControlVisibility;
  links?: ControlVisibility;
  symbolsLimit?: ControlVisibility;
  discount?: ControlVisibility;
}

export const prepareControlsVisibilityFlags = (
  textOutsideControlsVisibility: TextOutsideControlsVisibility,
  props: TextEditViewProps,
  overlayType: OverlayType,
) => {
  const { maxLength, attributeOnly } = props;

  const { emoji, symbolsLimit, attributes } = {
    attributes: ControlVisibility.auto,
    emoji: ControlVisibility.hide,
    symbolsLimit: ControlVisibility.auto,
    ...textOutsideControlsVisibility,
  };

  const isShowEmoji = [ControlVisibility.show, ControlVisibility.auto].includes(
    emoji || ControlVisibility.hide,
  );

  return {
    emoji: isShowEmoji,
    attributes:
      !attributeOnly &&
      (attributes === ControlVisibility.auto
        ? [
            OverlayType.textareaWithAttributes,
            OverlayType.buttonPopup,
          ].includes(overlayType)
        : attributes === ControlVisibility.show),
    symbolsLimit:
      symbolsLimit === ControlVisibility.auto
        ? !!maxLength
        : symbolsLimit === ControlVisibility.show,
  };
};

export interface TextEditViewProps extends LayoutProps, HTMLTextProps {
  maxLength?: number;
  caption?: string;
  captionFill?: PropFunc<HEXColors>;
  enterForNewLine?: boolean;
  editable?: boolean | (() => boolean | undefined) | undefined;
  isEditing?(): boolean;
  customAttributesOnly?: boolean;
  numbersOnly?: boolean;
  numbersFractionAllowed?: boolean;
  min?: number;
  max?: number;
  overlayWidth?: number;
  overlayOffset?: PropFunc<Point>;
  isValid?: PropFunc<boolean>;
  margin?: PropFunc<MarginType>;
  maskPrefixLength?: number;
  icon?: TextureShape;
  errorIcon?: TextureShape;
}

const ICON_PADDING = 16;

export class TextEditView extends MainLayout {
  TEST_NAME = 'TextEditView';

  public _textAreaShown = false;
  public id;
  public _props;
  public _textNode;

  private _onTextChanged;
  private _onStartEditing;
  private _onDoneEditing;
  private _captionSet = false;
  private _overlayType = OverlayType.textarea;
  private _zoomed = false;
  private _overlayValue = '';
  private _textOutsideControlsVisibility;
  private _predefinedAttributes;
  private _shouldJoinAttributes;
  private overlayOnChangeUnsubscribe?: () => void;
  private overlayOnKeyDownUnsubscribe?: () => void;

  constructor(
    props: TextEditViewProps,
    onStartEditing?: () => void,
    onTextChanged?: (text: string) => void,
    onDoneEditing?: (text: string) => void,
    textOutsideControlsVisibility: TextOutsideControlsVisibility = {},
    predefinedAttributes?: AttributesQuery_bot_variableSuggest[],
    shouldJoinAttributes?: boolean,
  ) {
    textOutsideControlsVisibility.attributes =
      !predefinedAttributes || predefinedAttributes.length > 0
        ? textOutsideControlsVisibility.attributes
        : ControlVisibility.hide;
    const shouldShowAttributes = [
      ControlVisibility.show,
      ControlVisibility.auto,
    ].includes(textOutsideControlsVisibility.attributes as ControlVisibility);
    const shouldUnderlineLinks = [
      ControlVisibility.show,
      ControlVisibility.auto,
    ].includes(textOutsideControlsVisibility.links as ControlVisibility);
    props = defaultObj(
      {
        editable: true,
        caption: '',
        captionFill: props.fill,
        cursor: (v: this) => {
          const isEditable = v._props && resByFunc(v._props.editable, v);
          return isEditable
            ? {
                in: 'text',
              }
            : undefined;
        },
        styles: {
          cf_caption: {
            fill: HEXColors.greyDark,
          },
          cf_attr: {
            fill: HEXColors.blue,
          },
        },
        singleLine: false,
        attributeOnly: false,
        customAttributesOnly: false,
        numbersOnly: false,
        resolution: window.devicePixelRatio,
        shouldShowAttributes,
        shouldUnderlineLinks,
        shouldJoinAttributes,
        maskPrefixLength: undefined,
        predefinedAttributes,
      },
      props,
    );
    super(props);
    this.id = nanoid();
    this._textOutsideControlsVisibility = textOutsideControlsVisibility;
    this._predefinedAttributes = predefinedAttributes;
    this._shouldJoinAttributes = shouldJoinAttributes;

    if (props.attributeOnly) {
      this._overlayType = OverlayType.inputAttribute;
      props.singleLine = true;
      props.fill = HEXColors.blue;
    } else {
      this._overlayType = shouldShowAttributes
        ? OverlayType.textareaWithAttributes
        : OverlayType.textarea;
    }

    this._props = props;
    this._onStartEditing = onStartEditing;
    this._onTextChanged = onTextChanged;
    this._onDoneEditing = onDoneEditing;

    if (props.icon) {
      this.addToLayout(props.icon, {
        margin: this.getIconMargin(),
        gone: () => !this.needShowIcon(),
      });
    }

    if (props.errorIcon) {
      this.addToLayout(props.errorIcon, {
        margin: this.getIconMargin(),
        gone: () => !this.needShowErrorIcon(),
      });
    }

    this._textNode = new HTMLText(props);
    this._textNode.interactive(true);
    this.setCaption();
    this.addToLayout(this._textNode, {
      margin: () => {
        const margin = resByFunc(props.margin);

        return {
          ...margin,
          left:
            (margin?.left || 0) +
            ((!this._props.align || this._props.align === 'left') &&
            (this.needShowIcon() || this.needShowErrorIcon())
              ? (this._props.icon?.width() ||
                  this._props.errorIcon?.width() ||
                  0) + ICON_PADDING
              : 0),
        };
      },
      width: 0,
    });
    this.on('pointerdown', (e) => {
      if (resByFunc(this._props.editable)) {
        e.stopPropagation();
      }
    });
    this.on('click', (e: InteractionEvent) => {
      if (resByFunc(this._props.editable)) {
        e.stopPropagation();
        if (shouldUnderlineLinks && !this._textAreaShown) {
          this.detectLinkClick(e);
        } else {
          this.startEditing();
        }
      }
    });
  }

  private needShowIcon() {
    return this._props.icon && !this._textAreaShown && !!this.text().trim();
  }

  private needShowErrorIcon() {
    return this._props.errorIcon && !this._textAreaShown && !this.text().trim();
  }

  private getIconMargin() {
    return () => {
      return {
        top: 6,
        left:
          this._props.align === 'center'
            ? this._props.width
              ? (this._props.width - this._textNode.getTextWidth()) * 0.5 -
                (resByFunc(this._props.margin)?.left || 0) -
                ICON_PADDING
              : 0
            : 0,
      };
    };
  }

  updateProps(newProps: TextEditViewProps) {
    this._props = {
      ...this._props,
      ...newProps,
    };
  }

  setCaption() {
    if (!this._textAreaShown) {
      if (this._textNode.text() === '') {
        this._textNode.text(this._props.caption);
        this._textNode.fill(resByFunc(this._props.captionFill));
        this._captionSet = true;
      } else {
        this._textNode.fill(this._props.fill);
        this._captionSet = false;
      }
    } else {
      this._textNode.fill(this._props.fill);
      if (this._captionSet) {
        this._textNode.text('');
      }
      this._captionSet = false;
    }
  }

  text(text?: string): string {
    if (text !== null && text !== undefined) {
      this._textNode.text(text);
      this.setCaption();
    }
    return this._captionSet ? '' : this._textNode.text();
  }

  setTextColor(color?: HEXColors) {
    this._textNode.fill(color);
  }

  start() {
    this.on('pointerdown', () => {
      this.startEditing();
    });
    return this;
  }

  startEditing() {
    setTimeout(() => {
      this.showTextArea();
    });
  }

  detectLinkClick(e: InteractionEvent) {
    const x = (e.data.originalEvent as MouseEvent).clientX;
    const y = (e.data.originalEvent as MouseEvent).clientY;
    const text = wrapAllLinks(this._textNode.text());

    const textNode = this._textNode;
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
      overlayType: OverlayType.nativeHtml,
      getOverlayOptions: () => ({
        initialValue: text,
        style: {
          lineHeight: textNode.lineHeight(),
          fontFamily: textNode.fontFamily(),
          textAlign: textNode.align(),
          color: textNode.fill(),
          fontWeight:
            FONT_STYLE_TO_WEIGHT[textNode.fontStyle() as FontWeightKey],
          fontSize: textNode.fontSize(),
          opacity: 0.01,
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
        },
      }),
    });
    this.setPosition();

    const element = document.elementFromPoint(x, y) as HTMLAnchorElement;
    assert(element, { msg: 'Element was not found' });

    if (element?.classList.contains(FLOW_BUILDER_LINK_CLASS)) {
      element.click();
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
    } else {
      this.startEditing();
    }
  }

  showTextArea() {
    if (this._textAreaShown) {
      return;
    }
    const textNode = this._textNode;
    this._textAreaShown = true;
    const onStartEditing = this._onStartEditing;
    const isNeedAutoSize = !this._props.height;
    const { viewport } = getPixiFieldStrict();
    const overlayType = this._overlayType;
    if (typeof onStartEditing !== 'undefined') {
      onStartEditing();
    }
    this.setCaption();
    this._overlayValue = textNode.text();

    const {
      maxLength,
      singleLine,
      attributeOnly,
      customAttributesOnly,
      numbersOnly,
      numbersFractionAllowed,
      maskPrefixLength,
      min,
      max,
    } = this._props;

    const shouldShowOutsideControls = prepareControlsVisibilityFlags(
      this._textOutsideControlsVisibility,
      this._props,
      overlayType,
    );

    overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
      overlayType,
      getOverlayOptions: () => ({
        shouldJoinAttributes: this._shouldJoinAttributes,
        initialValue: textNode.text().toString(),
        maxLength: resByFunc(maxLength) || undefined,
        numbersOnly,
        numbersFractionAllowed,
        maskPrefixLength,
        style: {
          lineHeight: textNode.lineHeight(),
          fontFamily: textNode.fontFamily(),
          textAlign: textNode.align(),
          color: textNode.fill(),
          fontWeight:
            FONT_STYLE_TO_WEIGHT[textNode.fontStyle() as FontWeightKey],
          fontSize: textNode.fontSize(),
        },
        shouldShowOutsideControls,
        singleLine,
        customAttributesOnly,
        min,
        max,
        predefinedAttributes: this._predefinedAttributes,
      }),
    });

    let lastHeight = 0;
    this.overlayOnChangeUnsubscribe = overlayEventEmitter.on<
      OverlayEventPayload<string>
    >(
      OverlayComponentEvent.change,
      ({ value, target: { clientHeight: height } }) => {
        if (typeof value !== 'string') {
          return;
        }
        this._overlayValue =
          numbersOnly && value === ''
            ? (min || 0).toFixed(0)
            : attributeOnly
            ? value.replace(/\s+/g, ' ')
            : value;
        this.handleTextChange(value);
        if (isNeedAutoSize && height !== lastHeight) {
          lastHeight = height;
          this._textNode.text(this._overlayValue);
          this.renderNode({ shouldRunOnBeforeRender: false });
        }
      },
    );

    this.overlayOnKeyDownUnsubscribe = overlayEventEmitter.on(
      OverlayComponentEvent.keydown,
      (event) => {
        if (
          event.key === 'Enter' &&
          !event.shiftKey &&
          !this._props.enterForNewLine
        ) {
          textNode.text(this._overlayValue);
          this.handleTextChange(this._overlayValue);
          this.removeTextarea();
          return;
        }
        if (event.key === 'Escape') {
          this.removeTextarea();
        }
      },
    );

    viewport.on('zoomed', this.zoomedHandler);
    viewport.on('zoomed-end', this.zoomEndHandler);
    viewport.on('moved-end', this.setPosition);

    setTimeout(() => {
      this.setPosition();
      if (textNode.layoutProperties) {
        textNode.layoutProperties.visible = false;
      }
      this.renderNode({ shouldRunOnBeforeRender: false });
      this.addClickHandler();
    });
  }

  addClickHandler() {
    window.addEventListener('mousedown', this.handleOutsideClick);
  }

  handleTextChange(newText: string) {
    const { _onTextChanged } = this;
    if (typeof _onTextChanged !== 'undefined') {
      _onTextChanged(newText.trim());
    }
  }

  handleOutsideClick = () => {
    this._textNode.text(this._overlayValue);
    this.handleTextChange(this._overlayValue);
    if (this._textAreaShown) {
      this.removeTextarea();
    }
  };

  setPosition = () => {
    const { _textNode } = this;
    const { overlayWidth, overlayOffset } = this._props;
    const { scale } = getPixiFieldStrict().viewport;
    const { x, y } = _textNode.globalPosition(scale.x);
    const offset = resByFunc(overlayOffset);
    if (offset) {
      offset.x *= scale.x;
      offset.y *= scale.y;
    }
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
      position: {
        x: x + (offset?.x || 0),
        y: y + (offset?.y || 0),
        width: overlayWidth || _textNode.width(),
        scale: scale.x,
      },
    });
  };

  zoomEndHandler = debounce(() => {
    if (this._zoomed && this._textAreaShown) {
      this.setPosition();
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.show);
      if (this._textNode.layoutProperties) {
        this._textNode.layoutProperties.visible = false;
      }
      this.renderNode({ shouldRunOnBeforeRender: false });
      this._zoomed = false;
    }
  }, 200);

  zoomedHandler = () => {
    const { scale } = getPixiFieldStrict().viewport;
    if (!this._zoomed && this._textAreaShown && scale.x < MAX_SCALE) {
      const { _textNode, _overlayValue } = this;
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.hide);
      if (_textNode.text() !== _overlayValue) {
        _textNode.text(_overlayValue);
        _textNode.renderElement();
        this.setCaption();
      }
      if (_textNode.layoutProperties) {
        _textNode.layoutProperties.visible = true;
      }
      this.renderNode({ shouldRunOnBeforeRender: false });
      this._zoomed = true;
      this.zoomEndHandler();
    }
  };

  removeTextarea() {
    const { _textNode, _onDoneEditing } = this;
    const { viewport } = getPixiFieldStrict();
    this.overlayOnChangeUnsubscribe?.();
    this.overlayOnKeyDownUnsubscribe?.();
    this.zoomEndHandler.cancel();
    this._zoomed = false;
    window.removeEventListener('mousedown', this.handleOutsideClick);
    viewport.off('zoomed', this.zoomedHandler);
    viewport.off('zoomed-end', this.zoomEndHandler);
    viewport.off('moved-end', this.setPosition);
    this._textAreaShown = false;
    this.setCaption();
    if (_textNode.layoutProperties) {
      _textNode.layoutProperties.visible = true;
    }
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
    this.renderNode({ shouldRunOnBeforeRender: false });
    if (typeof _onDoneEditing !== 'undefined') {
      _onDoneEditing(this._overlayValue);
    }
  }

  isEditing() {
    return this._textAreaShown;
  }
}
