import { HEXColors } from '@ui/_common/colors';

export const textCardBackgroundColor = HEXColors.greyLight20;
export const blockWidth = 340;
export const pluginWidth = 300;
export const buttonWidth = 270;
export const buttonHeight = 40;
export const qrBackgroundColor = HEXColors.white;
export const inactiveStrokeColor = HEXColors.grey;
export const mainStrokeColor = HEXColors._a6d2f9;
export const cfColor = HEXColors._428fe9;
export const invalidStrokeColor = HEXColors.red;
export const onHoverStrokeWidth = 2;
export const commentBackgroundColor = HEXColors._f7f2d4;
