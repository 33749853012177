import { VLayout } from '../../../components/Elements/Layouts';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { HTMLText } from '../../../components/Elements/Shapes';
import { ButtonView } from '../../button_view';
import { StatefulPlugin } from '../../../StatefulPlugin';
import { OpenAiPluginConfig } from './types';
import {
  buttonHeight,
  pluginWidth,
  textCardBackgroundColor,
} from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import debounce from 'lodash-es/debounce';
import i18next from 'i18next';
import { pluginCollectNumbersSvgTexture } from '../../../assets/textures';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getPanelWidth } from '@components/FlowBuilder/EditorPanel/utils/panelDimensions';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { editPluginInEditorPanel } from '@components/FlowBuilder/EditorPanel/events';
import { Node } from '@components/FlowBuilder/Node';
import { AlertView } from '../../entry-points/common/utils/validationProps';
import { tooltipScaled } from '@components/FlowBuilder/views/helpers/TooltipHelpers';
import { getPromptPreview } from '@components/FlowBuilder/EditorPanel/components/plugins/OpenAiPlugin/PromptTab/getPromptPreview';
import { calculateTokenizedLength } from '@utils/calculateTokenizedLength';
import { MAX_TOKENS } from '@components/FlowBuilder/EditorPanel/components/plugins/OpenAiPlugin/consts';
import { OnboardingTourEventType, OnboardingTourShape } from '@globals';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

export class OpenAiPromptLayout extends VLayout {
  TEST_NAME = 'OpenAiPromptLayout';
  titleLayout: PluginTitleLayout;
  textView: HTMLText;
  buttonView: ButtonView;
  _node: Node;

  alertView: AlertView;

  private tokens = 0;

  state: StatefulPlugin<OpenAiPluginConfig>;
  constructor(state: StatefulPlugin<OpenAiPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
        stroke: HEXColors.red,
        strokeWidth: 2,
        strokeOpacity: () => (this.getErrorTooltip() ? 1 : 0),
      },
    });
    this._node = node;
    this.state = state;
    this.titleLayout = new PluginTitleLayout(
      false,
      i18next.t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.prompt',
      ),
      pluginCollectNumbersSvgTexture,
      state.data,
      undefined,
      {
        background: {
          fill: textCardBackgroundColor,
        },
      },
    );

    this.on('click', () => {
      this.openLeftPanel(state, node);
    });

    this.addToLayout(this.titleLayout, {
      margin: { top: 15, right: 10, left: 10 },
    });
    this.alertView = new AlertView({
      position: { x: pluginWidth - 41, y: 19 },
    });
    tooltipScaled({
      view: this.alertView,
      text: this.getErrorTooltip.bind(this),
    });
    this.addChild(this.alertView);

    this.textView = new HTMLText({
      fontSize: 15,
      align: 'left',
      text: this.promptText(),
      width: pluginWidth - 32,
    });
    this.addToLayout(this.textView, {
      margin: {
        left: 16,
        right: 16,
      },
    });

    this.buttonView = new ButtonView({
      width: pluginWidth - 32,
      height: buttonHeight,
      title: i18next.t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.setUp',
      ),
      onClick: () => {
        if (getPixiFieldStrict().isViewOnly()) {
          return;
        }
        OnboardingEmitter.emit(OnboardingTourEventType.click, {
          element: OnboardingTourShape.OpenAiPromptSetUp,
          value: null,
        });
        this.renderNode();
      },
    });
    this.buttonView.setBackgroundName(OnboardingTourShape.OpenAiPromptSetUp);

    this.addToLayout(this.buttonView, {
      margin: {
        top: 16,
        left: 16,
        bottom: 16,
      },
    });

    this.calculateAndRenderTokens();
  }

  promptText(): string {
    const count = this.tokens;
    return `${count}/${MAX_TOKENS} ${i18next.t(
      'modernComponents.FlowBuilder.views.components.OpenAiPlugin.tokens',
      { count },
    )}`;
  }

  calculateAndRenderTokens = debounce(() => {
    const text = getPromptPreview(this.state.data.config);

    this.tokens = calculateTokenizedLength(text.trim());
    this.textView.text(this.promptText());
    this.alertView.visible = Boolean(this.getErrorTooltip());
    this.alertView.renderElement();

    this.renderElement();
  }, 500);

  private openLeftPanel(state: StatefulPlugin<OpenAiPluginConfig>, node: Node) {
    getPixiFieldStrict().fixBlockPosition(
      node.blockView,
      getPanelWidth(PluginType.open_ai),
    );
    editPluginInEditorPanel(node.id, state.data);
  }

  getErrorTooltip() {
    if (!this.state.data.config.intents.every(({ key, value }) => key && value))
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.promptTooltip',
      );
    if (this.tokens === 0)
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.addPromt',
      );

    if (this.tokens > MAX_TOKENS)
      return i18next.t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.editPromt',
      );

    return '';
  }

  onBeforeRender() {
    super.onBeforeRender();

    this.calculateAndRenderTokens();
  }
}
