// TODO убрать no-param-reassign после рефакторинга block_view на Stateful
/* eslint-disable no-param-reassign */
import i18next from 'i18next';
import copyToClipboard from 'clipboard-copy';
import { Level, log } from 'cf-common/src/logger';
import { HEXColors } from '@ui/_common/colors';
import { strictFn } from '@utils/strictFn';
import { CanvasRenderer, InteractionEvent } from 'pixi.js-legacy';
import {
  CardButtonType,
  Platform,
  QuickReplyType,
  WelcomeMessageMediaType,
} from '@globals';
import {
  getRealPluginId,
  logFlowEvent,
  logFlowPluginEvent,
} from '../utils/Analytics';
import { EntryPointInstagramAds } from './entry-points/EntryPointInstagramAds';
import { EntryPointShopifyCustomerChat } from './entry-points/EntryPointShopifyCustomerChat';
import { removeTooltip, tooltipScaled } from './helpers/TooltipHelpers';
import { ProductListPlugin } from './plugins/ProductListPlugin/ProductListPlugin';
import {
  createPluginListByPluginId,
  getColorByBlockSubtype,
  getEntryPointTheme,
  isFacebookAds,
  isInstagramAds,
  moveArrayElement,
  resByFunc,
} from './utils';
import {
  AddToLayoutProps,
  Alignment,
  HLayout,
  MainLayout,
  UpdateParams,
  View,
  VLayout,
} from '../components/Elements/Layouts';
import { GalleryPluginView } from './gallery_view';
import { UnknownPluginView } from './unknown_plugin';
import { ImagePluginView } from './image_plugin';
import { TextPluginView } from './text_plugin';
import { QuickReplyPluginView } from './plugins/QuickReplyPlugin';
import { GoToBlockPluginView } from './plugins/GoToBlock';
import {
  blockControl,
  cardControl,
  moveControlsToTop,
  removeButtonControl,
  updateControls,
} from './helpers/ControlsHelpers';
import { BLOCK_SUBTYPES, disabledTheme, isRootBlock } from '../consts';
import { createBlockMenu } from './Menu/createBlockMenu';
import { AddRemoveTagPluginView } from './add_tag_plugin';
import { OpenUrlPluginView } from './open_url';
import { PhoneCallPluginView } from './phone_call';
import { FacebookAnalyticsView } from './facebook_analytics';
import { AudioPluginView } from './plugins/AudioPlugin/AudioPlugin';
import { OneTimeNotificationPluginView } from './plugins/OneTimeNotificationPlugin';
import { RedirectToBlock } from './redirect_to_block';
import { PluginLoadingView } from './block_loading_view';
import { JsonPluginView } from './json_plugin';
import { StartingPointView } from './starting_point_view';
import { LiveChatPluginView } from './plugins/LiveChat';
import { loaderWhiteSvgTexture } from '../assets/textures';
import { GoogleSheetPluginView } from './plugins/GoogleSheet';
import { CalendlyIntegrationPluginView } from './plugins/CalendlyIntegration';
import { AddAttributePluginView } from './plugins/AddAttrubute';
import { RemoveAttributePluginView } from './plugins/RemoveAttribute';
import {
  getFlowController,
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../PixiFieldRepository';
import { addValidationProps } from './entry-points/common/utils/validationProps';
import { nonEmptyArray } from './validation';
import { Messages, showInfoToaster, toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { EntryPointFacebookPage } from './entry-points/EntryPointFacebookPage';
import { CommentPluginView } from './plugins/CommentPlugin';
import { EntryPointSendToMessenger } from './entry_point_send_to_messenger';
import { PluginType } from '../../Plugins/common/PluginTypes';
import { EntryPointInstagramDirect } from './entry-points/EntryPointInstagramDirect';
import { EntryPointInstagramStoryMention } from './entry-points/EntryPointInstagramStoryMention';
import {
  getEntryPointCard,
  getEntryPointCardStrict,
  getEntryPointCardView,
  getEntryPointCardViewStrict,
  isEntryPoint,
} from './entry-points/common/utils';
import {
  EntryPointCommentsAutoreply,
  EntryPointInstagramCommentsAutoreply,
} from './entry-points/EntryPointCommentsAutoreply';
import { EntryPointFacebookAds } from './entry-points/EntryPointFacebookAds';
import { EntryPointBotLink } from './entry-points/EntryPointBotLink';
import {
  INSTAGRAM_CONTENT_TYPES_IDS,
  INSTAGRAM_CONTENT_TYPES_ITEMS,
  MESSENGER_CONTENT_TYPES_IDS,
  MESSENGER_CONTENT_TYPES_ITEMS,
  PrivateReplyPlugin,
} from './plugins/PrivateReplyPlugin';
import { PublicReplyPlugin } from './plugins/PublicReplyPlugin';
import {
  CONNECT_TO_ITSELF_ERROR_MESSAGE,
  DelayPlugin,
} from './plugins/DelayPlugin';
import { DEFAULT_PLUGINS as INSTAGRAM_ADS_PLUGINS } from './entry-points/EntryPointInstagramAds/utils/defaultPlugins';
import { ButtonView } from './button_view';
import { FlowBuilderEvent, flowBuilderEventEmitter } from './events';
import { pollForAdsCardUploadStatus } from './entry-points/EntryPointFacebookAds/utils/adsCardUploadStatus';
import { EntryPointFacebookShops } from './entry-points/EntryPointFacebookShops';
import { FacebookShopsProductListPlugin } from './plugins/FacebookShopsProductListPlugin';
import { BlockStatsView } from './components/BlockStatsView';
import { Loader } from './loader';
import { blockWidth, inactiveStrokeColor, pluginWidth } from './plugin_consts';
import { LineStartView } from './components/Line/LineStartView';
import { activeLine } from './components/Line/LineView';
import { PlusButtonView } from './plus_button_view';
import { InboundLinksEntryPointView } from './entry-points/EntryPointInboundLinks';
import { ExternalIntegrationEntryPoint } from './entry-points/ExternalIntegrationEntryPoint';
import { ZapierEventTriggerPlugin } from './plugins/ZapierEventTriggerPlugin';
import { needShowBlockStats } from './components/BlockStatsView/utils';
import { BlockTitleView } from './block_title_view';
import {
  BLOCK_STATS_HEIGHT,
  ENTRY_POINT_HEADER_MARGIN,
  HEADER_MARGIN,
} from './constants';
import { ZapierImportContentPlugin } from './plugins/ZapierImportContentPlugin';
import { isApolloBasedPlugin } from './plugins.configuration';
import {
  getPluginData,
  savePlugin,
  updatePluginData,
} from '../StatefulPlugin/ApolloState/utils';
import { MediaSharePluginView } from './plugins/MediaSharePlugin';
import { Block, Card } from '../types';
import { Node } from '../Node';
import { Toggle } from './kit/toggle';
import { Theme } from './utility_classes';
import { Layout } from '../components/Elements/Layouts/Layout';
import { FacebookAdsEntryPointConfig } from '../../Plugins/FacebookAdsEntryPoint/FacebookAdsEntryPointConst';
import { createStatefulPlugin } from './createStatefulPlugin';
import {
  getFacebookMessageTypeId,
  getInstagramMessageTypeId,
} from './entry-points/common/components/GreetingAdsTypeSelector/utils';
import {
  FACEBOOK_MESSAGE_TYPE_PLUGINS,
  FacebookMessageTypeIds,
  INSTAGRAM_MESSAGE_TYPE_PLUGINS,
  InstagramMessageTypeIds,
} from './entry-points/common/components/GreetingAdsTypeSelector/consts';
import { outsideBlockFixedViewsService } from './services';
import { PrivateReplyCard } from './plugins/PrivateReplyPlugin/types';
import { EntryPointShopifyEvent } from './entry-points/EntryPointShopifyEvent/EntryPointShopifyEvent';
import { EntryPointPopup } from './entry-points/EntryPointPopup';
import { EntryPointShopifyBackInStock } from './entry-points/EntryPointShopifyBackInStock';
import { EntryPointCustomerChat } from './entry-points/EntryPointCustomerChat';
import { EntryPointInstagramStoryReply } from './entry-points/EntryPointInstagramStoryReply';
import { DragmoveEvent } from './entry-points/common/utils/eventHandlers/DragHandler';
import { facebookAdsCardUploadStatus_facebookAdEntryPointCardUploadStatus_ads as FacebookAd } from './entry-points/EntryPointFacebookAds/utils/@types/facebookAdsCardUploadStatus';
import { CardReminderPlugin } from './plugins/CardReminderPlugin/CardReminderPlugin';
import { OrderReceiptPlugin } from './plugins/OrderReceiptPlugin';
import { DEFAULT_PLUGINS as FACEBOOK_ADS_PLUGINS } from './entry-points/EntryPointFacebookAds/utils/defaultPlugins';
import { CreateMenuViewOverlay } from './menu_view_overlay';
import { OTN_LINE_ID } from '../../Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { NTimeNotificationPluginView } from './plugins/NTimeNotificationPluginView/NTimeNotificationPluginView';
import { refetchFlowBlock } from '../api/refetchFlowBlock';
import { getLineItemsProps } from './utils/getLineItemsProps';
import { unlockEntryPointErrorTooltip } from '../utils/useActivateEntryPoint';
import { EntryPointWhatsappDirect } from './entry-points/EntryPointWhatsappDirect';
import { EntryPointFacebookAdsJson } from './entry-points/EntryPointFacebookAds/EntryPointFacebookAdsJson';
import { EntryPointInstagramLink } from './entry-points/EntryPointInstagramLink';
import { Subscription } from 'rxjs';
import { equals } from 'ramda';
import { prettifyJson } from '@utils/String/prettifyJson';
import type { TextEditView } from './text_edit_view';
import { getAdsJsonDataObservable } from '@utils/Data/Marketing/getAdsJsonDataObservable';
import { EntryPointInstagramPersistentMenu } from './entry-points/EntryPointPersistentMenu/EntryPointInstagramPersistentMenu';
import { EntryPointFacebookPersistentMenu } from './entry-points/EntryPointPersistentMenu/EntryPointFacebookPersistentMenu';
import { EntryPointWhatsappBotLink } from './entry-points/EntryPointWhatsappBotLink';
import { setHidePaymentButtonProps } from './utils/setHidePaymentButtonProps';
import { EntryPointAdCommentsAutoreply } from './entry-points/EntryPointCommentsAutoreply/AdCommentsAutoreply/EntryPointAdCommentsAutoreply';
import { CommentsLikePluginView } from './plugins/CommentsLikePlugin/CommentsLikePlugin';
import { OpenAiPluginView } from './plugins/OpenAiPlugin';
import { CustomAiPluginView } from './plugins/AiCustomTemplates/CustomAiPlugin';
import { WhatsappTemplatePluginView } from '@components/FlowBuilder/views/plugins/WhatsappTemplatePlugin/WhatsappTemplatePlugin';
import { validateCommentsContextLines } from './utils/validateCommentsContextLines';
import { SplitTrafficPlugin } from './plugins/SplitTrafficPlugin/SplitTrafficPlugin';
import { SendEmailPlugin } from './plugins/SendEmailPlugin';
import { WhatsappListPluginView } from './plugins/WhatsappListPlugin/WhatsappListPlugin';
import { EntryPointWhatsappWidget } from './entry-points/EntryPointWhatsappWidget';
import { DocumentPluginView } from './plugins/DocumentPlugin/DocumentPlugin';
import { VideoPluginView } from './plugins/VideoPlugin/VideoPlugin';
import { WhatsappLocationPluginView } from './plugins/WhatsappLocationPlugin/WhatsappLocationPlugin';
import { matchPath } from 'react-router-dom';
import { getWhatsappBroadcastObservable } from '@pages/BotPage/WhatsappReEngageTab/hooks/useWhatsappBroadcast';
import { isEditableBroadcast } from '@pages/BotPage/WhatsappReEngageTab/utils';
import { KommoPluginView } from './plugins/KommoPlugin/KommoPluginView';
import { flowBuilderModule } from '../FlowBuilderModule';
import { EntryPointWhatsappPopup } from './entry-points/EntryPointWhatsappPopup';

export function viewByPluginId(card: Card, node: Node, blockView: BlockView) {
  if (typeof card.isEditing === 'undefined') {
    card.isEditing = false;
  }
  if (!card.plugin_id) {
    return undefined;
  }

  let result: Layout | undefined;

  if (flowBuilderModule.hasPlugin(card.plugin_id)) {
    result = flowBuilderModule.getPlugin(card.plugin_id).getView(card, node);
  }

  // ========stateless plugins========
  if (card.plugin_id === PluginType.text) {
    result = new TextPluginView(card, node);
  }
  if (card.plugin_id === PluginType.json_plugin) {
    result = createStatefulPlugin(JsonPluginView, card, node);
  }
  if (card.plugin_id === PluginType.gallery) {
    result = new GalleryPluginView(card, node);
  }
  if (card.plugin_id === PluginType.image) {
    result = new ImagePluginView(card, node);
  }
  if (card.plugin_id === PluginType.audio) {
    result = createStatefulPlugin(AudioPluginView, card, node);
  }
  if (card.plugin_id === PluginType.video) {
    result = createStatefulPlugin(VideoPluginView, card, node);
  }
  if (card.plugin_id === PluginType.facebook_analytics) {
    result = new FacebookAnalyticsView(card, node);
  }
  if (card.plugin_id === PluginType.split_traffic) {
    result = createStatefulPlugin(SplitTrafficPlugin, card, node);
  }
  if (card.plugin_id === PluginType.add_tag) {
    result = new AddRemoveTagPluginView(card, node, false);
  }
  if (card.plugin_id === PluginType.remove_tag) {
    result = new AddRemoveTagPluginView(card, node, true);
  }
  // we have different plugin_ids on backend but common view for them here
  if (
    card.plugin_id === PluginType.quick_reply ||
    card.plugin_id === PluginType.date_picker
  ) {
    result = createStatefulPlugin(QuickReplyPluginView, card, node);
  }
  if (card.plugin_id === PluginType.otn_request) {
    result = new OneTimeNotificationPluginView(card, node);
  }
  if (card.plugin_id === PluginType.kommo_plugin) {
    result = createStatefulPlugin(KommoPluginView, card, node);
  }
  if (card.plugin_id === PluginType.notification_messages_request) {
    result = createStatefulPlugin(NTimeNotificationPluginView, card, node);
  }
  if (card.plugin_id === PluginType.open_url) {
    result = new OpenUrlPluginView(card, node);
  }
  if (card.plugin_id === PluginType.phone_call) {
    result = new PhoneCallPluginView(card, node);
  }
  if (card.plugin_id === PluginType.delay) {
    result = new DelayPlugin(card, node);
  }
  if (card.plugin_id === PluginType.email) {
    result = createStatefulPlugin(SendEmailPlugin, card, node);
  }
  if (card.plugin_id === PluginType.flowbuilder_livechat) {
    result = createStatefulPlugin(LiveChatPluginView, card, node);
  }
  if (card.plugin_id === PluginType.send_to_messenger_entry_point) {
    result = new EntryPointSendToMessenger(card, node);
  }
  if (card.plugin_id === PluginType.fb_page_entry_point) {
    result = new EntryPointFacebookPage(card, node);
  }
  if (card.plugin_id === PluginType.ref_link_entry_point) {
    result = createStatefulPlugin(EntryPointBotLink, card, node);
  }
  if (card.plugin_id === PluginType.whatsapp_widget_entry_point) {
    result = createStatefulPlugin(EntryPointWhatsappWidget, card, node);
  }
  if (card.plugin_id === PluginType.instagram_bot_link_entry_point) {
    result = createStatefulPlugin(EntryPointInstagramLink, card, node);
  }
  if (card.plugin_id === PluginType.persistent_menu_entry_point) {
    result = new EntryPointFacebookPersistentMenu(card, node);
  }
  if (card.plugin_id === PluginType.instagram_persistent_menu_entry_point) {
    result = new EntryPointInstagramPersistentMenu(card, node);
  }
  if (card.plugin_id === PluginType.comments_autoreply_entry_point) {
    result = createStatefulPlugin(EntryPointCommentsAutoreply, card, node);
  }
  if (card.plugin_id === PluginType.ad_comments_autoreply_entry_point) {
    result = createStatefulPlugin(EntryPointAdCommentsAutoreply, card, node);
  }
  if (card.plugin_id === PluginType.comments_like) {
    result = createStatefulPlugin(CommentsLikePluginView, card, node);
  }
  if (card.plugin_id === PluginType.facebook_shops_entry_point) {
    result = new EntryPointFacebookShops(card, node);
  }
  if (card.plugin_id === PluginType.comment) {
    result = new CommentPluginView(card, node);
  }
  if (card.plugin_id === PluginType.private_reply_entry_point) {
    const { platform } = getFlowControllerStrict().flow;

    if (!platform) {
      throw new Error('Platform cannot be null');
    }

    const contentTypeItems =
      platform === Platform.instagram
        ? INSTAGRAM_CONTENT_TYPES_ITEMS
        : MESSENGER_CONTENT_TYPES_ITEMS;
    result = new PrivateReplyPlugin(card, node, { contentTypeItems, platform });
  }
  if (card.plugin_id === PluginType.public_reply_entry_point) {
    result = new PublicReplyPlugin(card, node);
  }
  if (card.plugin_id === PluginType.facebook_shops_product_list) {
    result = new FacebookShopsProductListPlugin(card, node);
  }
  // ========stateful plugins========
  if (card.plugin_id === PluginType.inbound_links_entry_point) {
    result = createStatefulPlugin(InboundLinksEntryPointView, card, node);
  }
  if (card.plugin_id === PluginType.open_ai) {
    result = createStatefulPlugin(OpenAiPluginView, card, node);
  }
  if (card.plugin_id === PluginType.custom_ai) {
    result = createStatefulPlugin(CustomAiPluginView, card, node);
  }
  if (card.plugin_id === PluginType.add_attribute) {
    result = createStatefulPlugin(AddAttributePluginView, card, node);
  }
  if (card.plugin_id === PluginType.remove_attribute) {
    result = createStatefulPlugin(RemoveAttributePluginView, card, node);
  }
  if (card.plugin_id === PluginType.go_to_block_plugin) {
    result = createStatefulPlugin(GoToBlockPluginView, card, node);
  }
  if (card.plugin_id === PluginType.google_sheet) {
    result = createStatefulPlugin(GoogleSheetPluginView, card, node);
  }
  if (card.plugin_id === PluginType.calendly) {
    result = createStatefulPlugin(CalendlyIntegrationPluginView, card, node);
  }
  if (card.plugin_id === PluginType.external_integration_entry_point) {
    result = createStatefulPlugin(ExternalIntegrationEntryPoint, card, node);
  }
  if (card.plugin_id === PluginType.event_trigger) {
    result = createStatefulPlugin(ZapierEventTriggerPlugin, card, node);
  }
  if (card.plugin_id === PluginType.integration_zapier_plugin) {
    result = createStatefulPlugin(ZapierImportContentPlugin, card, node);
  }
  if (card.plugin_id === PluginType.media_share) {
    result = createStatefulPlugin(MediaSharePluginView, card, node);
  }
  if (card.plugin_id === PluginType.instagram_direct_entry_point) {
    result = createStatefulPlugin(EntryPointInstagramDirect, card, node);
  }
  if (card.plugin_id === PluginType.ads_manager_ctm_entry_point) {
    result = createStatefulPlugin(EntryPointFacebookAds, card, node);
  }
  if (card.plugin_id === PluginType.ads_manager_ctm_json_entry_point) {
    result = createStatefulPlugin(EntryPointFacebookAdsJson, card, node);
  }
  if (card.plugin_id === PluginType.ads_manager_sm_entry_point) {
    result = createStatefulPlugin(EntryPointFacebookAds, card, node);
  }
  if (card.plugin_id === PluginType.popup_entry_point) {
    result = createStatefulPlugin(EntryPointPopup, card, node);
  }
  if (card.plugin_id === PluginType.shopify_back_in_stock) {
    result = createStatefulPlugin(EntryPointShopifyBackInStock, card, node);
  }
  if (card.plugin_id === PluginType.shopify_event_entry_point) {
    result = createStatefulPlugin(EntryPointShopifyEvent, card, node);
  }
  if (card.plugin_id === PluginType.abandoned_cart) {
    result = createStatefulPlugin(CardReminderPlugin, card, node);
  }
  if (card.plugin_id === PluginType.instagram_comments_autoreply_entry_point) {
    result = createStatefulPlugin(
      EntryPointInstagramCommentsAutoreply,
      card,
      node,
    );
  }
  if (card.plugin_id === PluginType.instagram_story_mention_entry_point) {
    result = createStatefulPlugin(EntryPointInstagramStoryMention, card, node);
  }
  if (card.plugin_id === PluginType.customer_chat_entry_point) {
    result = createStatefulPlugin(EntryPointCustomerChat, card, node);
  }
  if (card.plugin_id === PluginType.instagram_story_reply_entry_point) {
    result = createStatefulPlugin(EntryPointInstagramStoryReply, card, node);
  }
  if (card.plugin_id === PluginType.instagram_ads_manager_ctm_entry_point) {
    result = createStatefulPlugin(EntryPointInstagramAds, card, node);
  }
  if (card.plugin_id === PluginType.shopify_customer_chat_entry_point) {
    result = createStatefulPlugin(EntryPointShopifyCustomerChat, card, node);
  }
  if (card.plugin_id === PluginType.order_receipt) {
    result = createStatefulPlugin(OrderReceiptPlugin, card, node);
  }
  if (card.plugin_id === PluginType.product_list) {
    result = createStatefulPlugin(ProductListPlugin, card, node);
  }
  if (card.plugin_id === PluginType.whatsapp_direct_entry_point) {
    result = createStatefulPlugin(EntryPointWhatsappDirect, card, node);
  }
  if (card.plugin_id === PluginType.whatsapp_bot_link) {
    result = createStatefulPlugin(EntryPointWhatsappBotLink, card, node);
  }
  if (card.plugin_id === PluginType.whatsapp_popup_entry_point) {
    result = createStatefulPlugin(EntryPointWhatsappPopup, card, node);
  }
  if (card.plugin_id === PluginType.whatsapp_template) {
    result = createStatefulPlugin(WhatsappTemplatePluginView, card, node);
  }
  if (card.plugin_id === PluginType.whatsapp_list) {
    result = createStatefulPlugin(WhatsappListPluginView, card, node);
  }
  if (card.plugin_id === PluginType.document) {
    result = createStatefulPlugin(DocumentPluginView, card, node);
  }
  if (card.plugin_id === PluginType.whatsapp_location) {
    result = createStatefulPlugin(WhatsappLocationPluginView, card, node);
  }

  // ================
  if (typeof result === 'undefined') {
    result = new UnknownPluginView(card, node);
  }
  if (
    !(
      result instanceof SplitTrafficPlugin ||
      (result instanceof WhatsappTemplatePluginView &&
        node.block.subtype === BLOCK_SUBTYPES.broadcast_flow_root) ||
      (([
        BLOCK_SUBTYPES.entrypoint,
        BLOCK_SUBTYPES.calendly,
        BLOCK_SUBTYPES.comments_autoreply_content,
        BLOCK_SUBTYPES.instagram_comments_autoreply_content,
        BLOCK_SUBTYPES.back_in_stock_otn,
        BLOCK_SUBTYPES.open_ai,
        BLOCK_SUBTYPES.ai_custom,
      ].includes(node.block.subtype) ||
        node.block.subtype === BLOCK_SUBTYPES.flow_root) &&
        !(result instanceof CommentPluginView))
    )
  ) {
    cardControl(result, blockView);
  }
  const entryPointCard = getEntryPointCard(node.block.cards);
  const isEntryPointCardExists = !!entryPointCard;
  const isPluginDoesntNeedValidationProps = [
    PluginType.gallery,
    PluginType.public_reply_entry_point,
    PluginType.private_reply_entry_point,
  ].includes(card.plugin_id);
  const isFacebookAdsEntryPointPlugins =
    isFacebookAds(entryPointCard?.plugin_id) &&
    card.plugin_id !== PluginType.ads_manager_ctm_entry_point &&
    card.plugin_id !== PluginType.ads_manager_ctm_json_entry_point &&
    card.plugin_id !== PluginType.ads_manager_sm_entry_point;
  const isInstagramAdsEntryPointPlugins =
    isInstagramAds(entryPointCard?.plugin_id) &&
    card.plugin_id !== PluginType.instagram_ads_manager_ctm_entry_point;
  if (
    (!isPluginDoesntNeedValidationProps && !isEntryPointCardExists) ||
    isFacebookAdsEntryPointPlugins ||
    isInstagramAdsEntryPointPlugins
  ) {
    addValidationProps(result);
  }

  if (!getPixiFieldStrict().isViewOnly()) {
    result.on('pointerdown', (e: InteractionEvent) => {
      e?.stopPropagation();
    });

    result.on('click', (e: InteractionEvent) => {
      e?.stopPropagation();
      const editableViaPanelPlugins = [
        PluginType.json_plugin,
        PluginType.email,
        PluginType.flowbuilder_livechat,
        PluginType.send_to_messenger_entry_point,
        PluginType.customer_chat_entry_point,
        PluginType.shopify_customer_chat_entry_point,
      ];
      if (!editableViaPanelPlugins.includes(card.plugin_id)) {
        if (result instanceof CommentPluginView && !card.isEditing) {
          result.onEditStart(e);
        }
        card.isEditing = !card.isEditing;
        if (card.plugin_id === 'image' && card.isFirstInteraction) {
          card.isFirstInteraction = false;
        }
        result?.renderNode();
      }
      blockView.moveToTop();
      moveControlsToTop();
    });
  }

  return result;
}

export const viewByPluginIdStrict = strictFn(viewByPluginId);

const needShowDefaultLineStart = ({ subtype, cards }: Block) => {
  const excludeBlocks = [
    BLOCK_SUBTYPES.split_traffic,
    BLOCK_SUBTYPES.entrypoint,
    BLOCK_SUBTYPES.redirect,
    BLOCK_SUBTYPES.delay,
    BLOCK_SUBTYPES.back_in_stock_otn,
    BLOCK_SUBTYPES.open_ai,
    BLOCK_SUBTYPES.ai_custom,
  ];

  const includeEntryPoints: string[] = [
    PluginType.ads_manager_ctm_entry_point,
    PluginType.external_integration_entry_point,
    PluginType.whatsapp_direct_entry_point,
    PluginType.whatsapp_bot_link,
    PluginType.whatsapp_popup_entry_point,
    PluginType.whatsapp_widget_entry_point,
  ];

  return (
    !excludeBlocks.includes(subtype as string) ||
    (subtype === BLOCK_SUBTYPES.entrypoint &&
      includeEntryPoints.includes(getEntryPointCard(cards)?.plugin_id || ''))
  );
};

const isFocusedOrActive = (blockId: string) => {
  const controller = getFlowControllerStrict();

  return (
    controller.focusedBlockId === blockId || controller.isBlockActive(blockId)
  );
};

const strokeFunc = (hover: boolean, node: Node) => (block: any) => {
  const { _lineFits, _error, _theme, blockTitleView } = block;
  const isEntryPoint = node.block.subtype === BLOCK_SUBTYPES.entrypoint;
  const blockType = node.block.subtype
    ? node.block.subtype
    : BLOCK_SUBTYPES.send_message;
  let strokeColor;

  if (isEntryPoint) {
    strokeColor = blockTitleView?.isHeaderDisabled
      ? _theme?.strokeColor
      : disabledTheme.strokeColor;
  } else {
    strokeColor = getColorByBlockSubtype(blockType);
  }

  return isFocusedOrActive(node.block.id)
    ? strokeColor
    : _error
    ? HEXColors.red
    : hover && _lineFits !== false
    ? strokeColor
    : inactiveStrokeColor;
};

export class BlockView extends MainLayout {
  TEST_NAME = 'BlockView';

  public _node: Node;
  public _toggle: Toggle;
  public _theme: Theme | undefined;
  public _cardsLayout: VLayout;
  public blockTitleView: BlockTitleView;
  public _error: boolean | undefined;
  public removing = false;
  public header: VLayout;

  private _card: Card | null;
  private _topView: MainLayout;
  private _titleView: TextEditView;
  protected _plusPluginView: PlusButtonView | undefined;
  protected _mainLayout: VLayout;
  protected _lineStartView: LineStartView | undefined;

  // @ts-ignore поле не используется внутри класса, но нужно снаружи
  private _blockType: string; // TODO it is not actually string.
  private _lineFits: boolean | undefined;
  private _disabled: boolean | undefined;
  private _screenMoveIntervalId: NodeJS.Timeout | undefined; // TODO точно NodeJS?
  private _screenSpeed = { x: 0, y: 0 };
  private isRootBlock: boolean;
  private isEntryPoint: boolean;

  constructor(node: Node) {
    const blockType = node.block.subtype
      ? node.block.subtype
      : BLOCK_SUBTYPES.send_message;
    const isEntryPoint = node.block.subtype === BLOCK_SUBTYPES.entrypoint;
    const isCanvasRender =
      getPixiFieldStrict().app.renderer instanceof CanvasRenderer;

    super({
      x: node.x,
      y: node.y,
      width: blockWidth,
      cursor: {
        in: 'grab',
      },
      draggable: true,
      background: {
        cornerRadius: 20,
        fill: HEXColors.white,
        opacity: 0.8,
        strokeAlignment:
          !isEntryPoint && isCanvasRender ? Alignment.middle : Alignment.inner,
        strokeWidth: ({ _error }: any) =>
          isFocusedOrActive(node.block.id) || _error ? 3 : 1,
        stroke: strokeFunc(false, node),
        onhover: {
          stroke: strokeFunc(true, node),
          strokeWidth: ({ _error, _lineFits }: any) =>
            _lineFits !== false || _error ? 3 : 1,
          strokeOpacity: 1,
        },
      },
    });

    if (!node.block.cards) {
      // eslint-disable-next-line
      node.block.cards = [];
    }

    this.isRootBlock = isRootBlock(node.block.subtype);

    this.isEntryPoint = isEntryPoint;
    this._card = this.isEntryPoint
      ? (getEntryPointCardStrict(node.block.cards) as Card)
      : this.isRootBlock
      ? node.block.cards[0]
      : null;
    this._node = node;
    this._blockType = blockType;

    this._mainLayout = new VLayout({
      width: blockWidth,
    });
    this.layout(this._mainLayout, { height: 54 });

    this.blockTitleView = new BlockTitleView(blockType, this._node, this);
    this._titleView = this.blockTitleView.titleView!;
    this._toggle = this.blockTitleView.toggle!;

    const isViewOnly = getPixiFieldStrict().isViewOnly();
    if (!isViewOnly) {
      this.on(
        'pointerup', // for catch click on unfocused canvas
        () => {
          const controller = getFlowController();
          if (
            controller &&
            controller.validateIfBlockCanBeCopied(this._node.block)
          ) {
            controller.setActiveBlocksIds(() => [this._node.id]);
          }
        },
      );
    }

    this.blockTitleView.on('click', () => {
      if (!this._dragHandler?.moved) {
        this._titleView.startEditing();
      }
    });

    this.header = new VLayout({
      width: blockWidth,
      background: {
        corners: {
          topLeft: 20,
          topRight: 20,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    });

    this.header.layout(this.blockTitleView, {
      margin:
        this.isEntryPoint || this.isRootBlock
          ? ENTRY_POINT_HEADER_MARGIN
          : HEADER_MARGIN,
    });

    this.extendMainLayout();

    this.renderBlockStatsPlaceholder();

    this._cardsLayout = new VLayout({
      width: blockWidth,
      onElementPositionChanged: (view: View, startIdx, idx) => {
        moveArrayElement(this._node.block.cards, startIdx, idx);
        this._node.block.cards?.forEach((c, idx) => {
          if (isApolloBasedPlugin(c.plugin_id as PluginType) && !isViewOnly) {
            updatePluginData(c.id, (data) => {
              // eslint-disable-next-line
              data.card.position = idx;
            });
          } else {
            // eslint-disable-next-line
            c.position = idx;
          }
        });
        // @ts-expect-error
        const card = view._card;
        if (isApolloBasedPlugin(card.plugin_id) && !isViewOnly) {
          const data = getPluginData(card.id);
          if (data) {
            const { id: pluginId, plugin_id: pluginType, position } = data.card;
            savePlugin({
              blockId: node.block.id,
              pluginId,
              pluginType,
              position,
            });
          }
        } else {
          this._node.updateCard(card);
        }
        // @ts-expect-error
        view.renderNode();
      },
    });

    this._cardsLayout.TEST_NAME = 'CardsLayout';

    this._mainLayout.layout(this._cardsLayout);

    this.addCardsToLayout();

    this.mountPlusButton(blockType);

    this.handleEvents(node);

    if (blockType === BLOCK_SUBTYPES.redirect) {
      this._mainLayout.layout(new RedirectToBlock(node), {
        margin: { top: 0, bottom: 20, left: 20 },
      });
    }

    this.renderFooter();

    this._topView = new MainLayout({
      width: blockWidth,
      height: this.height(),
      cursor: () => {
        if (getPixiFieldStrict().isViewOnly()) {
          return { in: 'pointer' };
        }
        return this.removing
          ? { in: 'wait' }
          : this._lineFits
          ? undefined
          : { in: 'not-allowed' };
      },
      background: {
        cornerRadius: 20,
        fill: HEXColors.white,
        opacity: () =>
          (this._lineFits !== false && !this._disabled) ||
          getPixiFieldStrict().isViewOnly()
            ? 0
            : 0.4,
      },
    });

    this.renderTopViewAndBlockStats();

    this.layout(new StartingPointView(), {
      gone: () => !node.startingPoint,
      margin: { top: -40, left: 0 },
    });

    if (
      [BLOCK_SUBTYPES.entrypoint, BLOCK_SUBTYPES.flow_root].includes(blockType)
    ) {
      const entryPointCard = getEntryPointCardView(this);
      if (entryPointCard) {
        addValidationProps(
          entryPointCard,
          this._titleView,
          undefined,
          (event) => {
            event.stopPropagation();
            // @ts-expect-error
            if (!entryPointCard.validationError?.()?.isBlockError) {
              entryPointCard.emit('click', event);
            }
          },
        );
      }
    }

    if (blockType === BLOCK_SUBTYPES.calendly) {
      const calendly = this._cardsLayout?.children?.find(
        (view) => view instanceof CalendlyIntegrationPluginView,
      );
      if (calendly) {
        addValidationProps(calendly, this._titleView);
      }
    }

    outsideBlockFixedViewsService.attachBlock(this);
  }

  protected mountPlusButton(blockType: string) {
    // skip plus content button
    if (this.shouldRenderPlusButton()) {
      this.createPlusButton(blockType);
      this._mainLayout.layout(this._plusPluginView!, {
        margin: { bottom: 20, left: 20 },
      });
    }
  }

  public rerenderCards() {
    this._cardsLayout.removeAllViews();
    this.addCardsToLayout();
    this.renderNode();
  }

  protected addCardsToLayout() {
    this._node.block.cards.forEach((card, idx) => {
      if (
        isApolloBasedPlugin(card.plugin_id as PluginType) &&
        !getPixiFieldStrict().isViewOnly()
      ) {
        updatePluginData(card.id, (data) => {
          // eslint-disable-next-line
          data.card.position = idx;
        });
      } else {
        // eslint-disable-next-line
        card.position = idx;
      }
      const cardView = viewByPluginIdStrict(card, this._node, this);
      this.addCardToLayout(
        cardView,
        {
          margin: {
            bottom: [
              BLOCK_SUBTYPES.split_traffic,
              BLOCK_SUBTYPES.entrypoint,
              BLOCK_SUBTYPES.flow_root,
            ].includes(this._node.block.subtype)
              ? 20
              : 10,
            left: 20,
            right: 20,
          },
        },
        idx,
      );
    });
  }

  protected renderDefaultLineStart(): LineStartView {
    this._lineStartView = new LineStartView({
      node: this._node,
      from: this,
      ...getLineItemsProps(this._node.block),
      offset: 0,
      onConnected: (connectedBlockView) => {
        const connectedBlockId = connectedBlockView.id();
        if (this._node.block.subtype === BLOCK_SUBTYPES.flow_root) {
          const controller = getFlowControllerStrict();
          const connectedBlockNode = controller.getBlockNode(connectedBlockId);
          if (connectedBlockNode) {
            controller.markStartingPoint(connectedBlockNode);
          }
        } else {
          this._node.block.next_block_ids = [connectedBlockId];
          this._node.updateBlock(() => {
            flowBuilderEventEmitter.emit(FlowBuilderEvent.nodeConnected);
          });
        }
        this._node.renderNode();
      },
      onRemoved: () => {
        this._node.block.next_block_ids = [];
        this._node.updateBlock();
        this._node.renderNode();
      },
    });

    return this._lineStartView;
  }

  protected renderFooter(): void {
    if (needShowDefaultLineStart(this._node.block)) {
      this._mainLayout.layout(this.renderDefaultLineStart(), {
        margin: { left: blockWidth - 18, bottom: 8 },
        gone: () => this.getLineStartNextBlockGoneStatus(),
      });
    }
  }

  protected shouldRenderPlusButton(): boolean {
    return ![
      BLOCK_SUBTYPES.flow_root,
      BLOCK_SUBTYPES.redirect,
      BLOCK_SUBTYPES.split_traffic,
      BLOCK_SUBTYPES.entrypoint,
      BLOCK_SUBTYPES.calendly,
      BLOCK_SUBTYPES.comments_autoreply_content,
      BLOCK_SUBTYPES.instagram_comments_autoreply_content,
      BLOCK_SUBTYPES.back_in_stock_otn,
      BLOCK_SUBTYPES.open_ai,
      BLOCK_SUBTYPES.ai_custom,
    ].includes(this._node.block.subtype);
  }

  protected extendMainLayout() {
    const headerLayoutProps = { margin: { bottom: 16 } };
    if (this.isEntryPoint) {
      if (!this._card) {
        throw new Error(`Entry point must have a type`);
      }

      const margin = new VLayout({});
      this._theme = getEntryPointTheme(this._card.plugin_id);

      margin.layout(this.header, headerLayoutProps);

      const enabled =
        isFacebookAds(this._card.plugin_id) ||
        isInstagramAds(this._card.plugin_id)
          ? true
          : Boolean(this._card.enabled);

      setTimeout(() => {
        this.blockTitleView.setHeaderActive(enabled);
      });

      this._mainLayout.layout(margin);
    } else if (this._node.block.subtype === BLOCK_SUBTYPES.flow_root) {
      const { background } = this.header._layoutProps;
      if (background) {
        background.fill = HEXColors.azure;
      } else {
        this.header._layoutProps.background = { fill: HEXColors.azure };
      }
      this._mainLayout.layout(this.header, headerLayoutProps);
    } else {
      this._mainLayout.layout(this.header);
    }
  }

  getLineStartNextBlockGoneStatus() {
    return false;
  }

  id() {
    return this._node.id;
  }

  handleEvents(node: Node) {
    if (getPixiFieldStrict().isViewOnly()) {
      return;
    }

    this.on('click', (e: InteractionEvent) => {
      if (typeof this._dragHandler !== 'undefined') {
        if (this._dragHandler.moved) {
          return;
        }
      }
      e?.stopPropagation();
    });
    this.on('mousedown', () => {
      this.moveToTop();
      cardControl().moveToTop();
    });
    blockControl(this);
    this.on('dragstart', () => {
      unlockEntryPointErrorTooltip();
      this._screenMoveIntervalId = setInterval(() => {
        const { viewport } = getPixiFieldStrict();
        viewport.x -= this._screenSpeed.x * 0.3 * viewport.scale.x;
        viewport.y -= this._screenSpeed.y * 0.3 * viewport.scale.x;
      }, 3);
    });
    this.on('dragmove', (e: DragmoveEvent) => {
      const { event } = e;
      node.x = this.x;
      node.y = this.y;
      updateControls();
      this._node.updateLines(false);

      const pos = event.data.global;
      const { screen } = getPixiFieldStrict();
      const { width, height } = screen;
      const rightEdge = width - pos.x;
      const bottomEdge = height - pos.y;
      this._screenSpeed.x =
        pos.x < 100
          ? 0.1 * Math.min(100, Math.max(pos.x, 0)) - 10
          : rightEdge < 100
          ? 10 - 0.1 * Math.min(100, Math.max(rightEdge, 0))
          : 0;
      this._screenSpeed.y =
        pos.y < 100
          ? 0.1 * Math.min(100, Math.max(pos.y, 0)) - 10
          : bottomEdge < 100
          ? 10 - 0.1 * Math.min(100, Math.max(bottomEdge, 0))
          : 0;
    });
    this.on('dragend', () => {
      if (this._screenMoveIntervalId) {
        clearInterval(this._screenMoveIntervalId);
        delete this._screenMoveIntervalId;
      }
      if (this._dragHandler?.moved)
        logFlowEvent('block', 'moved', { blockId: this._node.id });
      node.updateLines(true);
      node.updateBlock();
    });
  }

  // TODO probably BLOCK_SUBTYPES
  createPlusButton(blockType: string) {
    let plusTitle;
    let tooltip;
    if ([BLOCK_SUBTYPES.action, BLOCK_SUBTYPES.send_data].includes(blockType)) {
      plusTitle = `+ ${i18next.t(
        'modernComponents.FlowBuilder.views.blockView.addAction',
      )}`;
    } else if (blockType === 'condition') {
      plusTitle = `+ ${i18next.t(
        'modernComponents.FlowBuilder.views.blockView.addCondition',
      )}`;
    } else if (blockType === 'delay') {
      plusTitle = `+ ${i18next.t(
        'modernComponents.FlowBuilder.views.blockView.addDelay',
      )}`;
      tooltip = i18next.t(
        'modernComponents.FlowBuilder.views.blockView.addDelayTooltip',
      );
    } else if (blockType === BLOCK_SUBTYPES.broadcast_flow_root) {
      plusTitle = `+ ${i18next.t(
        'modernComponents.FlowBuilder.views.blockView.block.add',
      )}`;
      tooltip = i18next.t(
        'modernComponents.FlowBuilder.views.blockView.block.answerTooltip',
      );
    } else {
      plusTitle = `+ ${i18next.t(
        'modernComponents.FlowBuilder.views.blockView.addContent',
      )}`;
    }
    this._plusPluginView = new PlusButtonView(
      plusTitle,
      undefined,
      undefined,
      undefined,
      undefined,
      // tooltip, is not used
    );
    if (tooltip) {
      tooltipScaled({
        view: this._plusPluginView,
        text: tooltip,
      });
    }

    const blockView = this;
    this._plusPluginView.on('pointerdown', (e: InteractionEvent) => {
      e.stopPropagation();
    });
    this._plusPluginView.on('click', (e: InteractionEvent) => {
      e?.stopPropagation();
      if (blockType === BLOCK_SUBTYPES.broadcast_flow_root) {
        this.addPlugin(PluginType.quick_reply);
      } else if (blockType === BLOCK_SUBTYPES.condition) {
        this.addPlugin(PluginType.go_to_block_plugin);
      } else if (blockType === BLOCK_SUBTYPES.delay) {
        this.addPlugin(PluginType.delay);
      } else {
        flowBuilderEventEmitter.emit(FlowBuilderEvent.blockMenuOpened);

        new CreateMenuViewOverlay({
          onChoose: (item) => {
            logFlowEvent('block', 'add card', {
              blockId: this._node.id,
              plugin: getRealPluginId(item.id),
              extraPluginId: item.id,
              source: 'add button',
            });
            blockView.addPlugin(item.id, undefined, () => {
              /**
               * При добавлении карточек путем клика на "add action" карточка добавляется
               * через эндпоинт /cards и не добавляется в gql кэш, поэтому для этих плагинов требуется
               * дополнительная загрузка данных но уже через gql. Если этого не сделатьт, перестанет
               * открываться EditorPanel, тк, она для плагинов читает данные из кеша, не делая
               * никаких запросов
               */
              if (
                [
                  PluginType.add_attribute,
                  PluginType.remove_attribute,
                  PluginType.json_plugin,
                  PluginType.flowbuilder_livechat,
                ].includes(item.id as PluginType)
              ) {
                refetchFlowBlock(this._node.id, this._node.controller.flow);
              }
            });
          },
          items: createBlockMenu(blockType),
          onClose: () => {
            flowBuilderEventEmitter.emit(FlowBuilderEvent.blockMenuClosed);
          },
        }).showOn(e.data.global);
      }
    });
  }

  addPluginLoading(plugin_id: PluginType, pluginIndex: number) {
    const pluginLoadingView = new PluginLoadingView(plugin_id);
    this.addCardToLayout(
      pluginLoadingView,
      { margin: { bottom: 10, left: 20, right: 20 } },
      pluginIndex,
    );
    this.renderNode();
    return pluginLoadingView;
  }

  addPluginAsync(
    plugin_id: PluginType,
    pluginIndex: number,
    pluginLoadingView: PluginLoadingView,
  ) {
    const byIndex = pluginIndex !== undefined;
    const node = this._node;

    return new Promise((resolve, reject) => {
      node.addPlugin(
        plugin_id,
        // TODO fix any
        (card: any) => {
          this._cardsLayout.removeView(pluginLoadingView);
          pluginLoadingView.destroy();
          if (plugin_id !== PluginType.remove_attribute) {
            card.isEditing = true;
          }
          card.isFirstInteraction = true;
          const plugin = viewByPluginId(card, node, this);

          if (!plugin) {
            throw new Error('Plugin is not defined');
          }

          if (byIndex) {
            node.block.cards?.splice(pluginIndex, 0, card);
          } else {
            node.block.cards?.push(card);
          }
          node.block.cards?.forEach((c, idx) => {
            if (isApolloBasedPlugin(c.plugin_id as PluginType)) {
              updatePluginData(c.id, (data) => {
                // eslint-disable-next-line
                data.card.position = idx;
              });
            } else {
              // eslint-disable-next-line
              c.position = idx;
            }
          });
          this.addCardToLayout(
            plugin,
            { margin: { bottom: 10, left: 20, right: 20 } },
            byIndex ? pluginIndex : undefined,
          );
          this.renderNode();
          // @ts-expect-error
          plugin?.focus?.();
          flowBuilderEventEmitter.emit(FlowBuilderEvent.pluginAdded);
          resolve(card);
        },
        pluginIndex,
        (error) => {
          this._cardsLayout.removeView(pluginLoadingView);
          pluginLoadingView.destroy();
          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: i18next.t(
                'block_view-string-5093-cant-create-the-card-server-is-not-available',
              ),
            },
          });
          flowBuilderEventEmitter.emit(FlowBuilderEvent.pluginAddFailed);
          reject(error);
        },
      );
    });
  }

  // we can add several plugins by shortcut id
  // see createPluginListByPluginId function
  async addPlugin(
    plugin_id: string,
    indexForPlugin?: number,
    success?: (result: Card) => void,
  ) {
    const pluginIndex =
      indexForPlugin ?? (this._node.block?.cards?.length || 0);
    const pluginList = createPluginListByPluginId(plugin_id);

    const pluginPlaceholders = pluginList.map((pluginId, index) =>
      this.addPluginLoading(pluginId, pluginIndex + index),
    );

    // eslint-disable-next-line
    for (const [index, pluginId] of pluginList.entries()) {
      // eslint-disable-next-line
      const result = await this.addPluginAsync(
        pluginId,
        pluginIndex + index,
        pluginPlaceholders[index],
      );
      if (result) {
        success?.(result as Card);
      }
    }
  }

  /**
   * broadcast_flow_stats_workaround start
   *
   * В задаче https://chatfuel.atlassian.net/browse/CHAT-9821 требовалось
   * реализовать возможность просмотра статистики когда флоу находится в
   * режиме readonly. Так как в мы блокируем блок отрисовкой {@link _topView | оверлея}
   * поверх всего блока, то невозможно "раздизейблить" какую то конкретную
   * часть, поэтому было притяно решение рисовать статистику блока поверх оверлея
   *
   * Решение с zIndex не сработало, так как в pixi zIndex изолирован внутри
   * контейнера, а статистика и оверлей находятся в разных контейнерах.
   * https://www.html5gamedevs.com/topic/45703-zindex-not-working-pixi/
   */

  /**
   * Рисует заглушку, на месте которой, отрисуется статистика блока
   * {@link renderTopViewAndBlockStats}
   */
  private renderBlockStatsPlaceholder() {
    this._mainLayout.layout(new VLayout({ height: BLOCK_STATS_HEIGHT }), {
      gone: () => !needShowBlockStats(this._node.block),
    });
  }

  /**
   * Рисует поверх {@link renderBlockStatsPlaceholder | заглушки} абсолютно спозиционированную
   * относительно blockView статистику.
   */
  private renderTopViewAndBlockStats() {
    this.layout(this._topView);

    this.layout(new BlockStatsView({ block: this._node.block }), {
      gone: () => !needShowBlockStats(this._node.block),
      margin: { top: 58 + (this.isEntryPoint ? 10 : 0) },
    });
  }

  /**
   * Когда флоу находится в режиме readonly юзер не может нажимать ни на какие кнопки внутри блока,
   * поэтому поверх него рисуется {@link BlockView._topView | _topView}. Но для бродкастного флоу
   * делается исключение, только {@link BroadcastRootBlockView | рутовый} блок может обрабатывать
   * клики, иначе нельзя посмотреть с какими параметрами был запущен бродкаст.
   */
  private shouldDrawTopView(): boolean {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      !(this instanceof BroadcastRootBlockView) &&
      getPixiFieldStrict().isViewOnly()
    );
  }
  // broadcast_flow_stats_workaround end

  renderNode(updateParams?: UpdateParams) {
    const result = super.renderNode(updateParams);
    this._node.updateLines();
    if (this._topView) {
      if (this.shouldDrawTopView()) {
        this._topView.visible = true;
      } else {
        this._topView.visible =
          typeof activeLine() !== 'undefined' ||
          this.removing ||
          Boolean(this._disabled);
      }
      this._topView.height(this.height());
      // @ts-expect-error
      this._topView._updateBackground();
    }
    return result;
  }

  onBeforeRender() {
    const node = this._node;
    const { block } = node;
    const { title } = block;
    this._titleView.text(title);

    if (this._lineStartView && nonEmptyArray(node.block.next_block_ids))
      node.addOutLink(
        node.block.next_block_ids?.[0],
        this._lineStartView._lineView,
      );

    if (this._plusPluginView) {
      // @ts-expect-error
      this._plusPluginView.inactive =
        Number(
          node.block.cards?.findIndex((c) => c.plugin_id === 'phone_call'),
        ) >= 0;
    }

    outsideBlockFixedViewsService.updateOutsideViews(this);
    updateControls();
  }

  onLineStarted(lineStartView: LineStartView) {
    const result = this.validateLine(lineStartView);
    this._lineFits = result.fits;
    const needUpdate = result.fits === false;
    if (result.tooltip) {
      tooltipScaled({
        view: this,
        text: result.tooltip,
      });
    }
    if (needUpdate) {
      this.renderNode({ shouldRunOnBeforeRender: false });
    }
  }

  onLineFinished(lineStartView: LineStartView) {
    this._lineFits = undefined;
    this.renderNode();
    removeTooltip(this);
    if (this.validateLine(lineStartView).fits) {
      return this;
    }

    return undefined;
  }

  validateLine(lineStartView: LineStartView) {
    const { fromView, subtype: lineSubtype } = lineStartView;
    const fromViewAny = fromView as any;
    const fromBlock = fromViewAny.node?.block || fromViewAny._node?.block;

    // Убедиться что нигде node не примутировывается
    // @ts-expect-error
    const toBlock = this._node.block || this.node.block;
    const entryPointCardTo = getEntryPointCard(toBlock.cards);
    const entryPointCardFrom = getEntryPointCard(fromBlock.cards);
    if (entryPointCardTo) {
      return {
        fits: false,
        tooltip: i18next.t(
          'block_view-string--859-entry-point-block-not-allowed-as-target-of-button',
        ),
      };
    }

    if (
      toBlock.subtype === BLOCK_SUBTYPES.back_in_stock_otn &&
      lineStartView.id !== OTN_LINE_ID
    ) {
      return {
        fits: false,
        tooltip: i18next.t('shopify.backInStock.tooltips.OTNLineError'),
      };
    }

    if (
      toBlock.subtype !== BLOCK_SUBTYPES.back_in_stock_otn &&
      lineStartView.id === OTN_LINE_ID
    ) {
      return {
        fits: false,
        tooltip: i18next.t('shopify.backInStock.tooltips.fromOTNLineError'),
      };
    }

    const commentsValidationResult = validateCommentsContextLines({
      toBlock,
      fromBlock,
      lineSubtype,
      entryPointId: entryPointCardFrom?.plugin_id as PluginType | undefined,
    });

    if (commentsValidationResult) {
      return commentsValidationResult;
    }

    if (
      fromViewAny._buttonEditView?._config?.type ===
        CardButtonType.calendly_schedule &&
      toBlock.subtype !== BLOCK_SUBTYPES.calendly
    ) {
      return {
        fits: false,
        tooltip: i18next.t(
          'modernComponents.FlowBuilder.views.components.Calendly.validation.fromCalendlyButtonError',
        ),
      };
    }

    const notFromTextPlugin = fromViewAny._card?.plugin_id !== PluginType.text;
    const notFromGalleryPlugin =
      fromViewAny._card?.plugin_id !== PluginType.gallery;
    const fromFacebookAds = fromViewAny._node?.block?.cards.find(
      ({ plugin_id }: { plugin_id: string }) => isFacebookAds(plugin_id),
    );
    if (
      toBlock.subtype === BLOCK_SUBTYPES.calendly &&
      ((notFromTextPlugin && notFromGalleryPlugin) || fromFacebookAds)
    ) {
      return {
        fits: false,
        tooltip: i18next.t(
          'modernComponents.FlowBuilder.views.components.Calendly.validation.toCalendlyBlockError',
        ),
      };
    }

    if (
      toBlock.subtype === BLOCK_SUBTYPES.back_in_stock_otn &&
      entryPointCardFrom?.plugin_id !== PluginType.shopify_back_in_stock
    ) {
      return {
        fits: false,
        tooltip: i18next.t(
          'shopify.backInStock.tooltips.fromEntryPointToOTNError',
        ),
      };
    }

    if (
      fromBlock.subtype === BLOCK_SUBTYPES.delay &&
      toBlock.subtype === BLOCK_SUBTYPES.delay &&
      fromBlock.id === toBlock.id
    ) {
      return {
        fits: false,
        tooltip: CONNECT_TO_ITSELF_ERROR_MESSAGE,
      };
    }

    return {
      fits: true,
      tooltip: undefined,
    };
  }

  destroy() {
    this._topView?.destroy();
    removeButtonControl(this);
    removeTooltip(this);
    outsideBlockFixedViewsService.unattachBlock(this);
    super.destroy();
  }

  hasSignificantChangesInConfig() {
    return (
      this._cardsLayout.views().findIndex((v) => {
        let hasChanges = true;
        try {
          // @ts-expect-error
          hasChanges = v.hasSignificantChangesInConfig?.();
        } catch (e) {
          console.error(e);
        }
        return hasChanges;
      }) >= 0
    );
  }

  // TODO fix type too
  addCardToLayout(
    view: View | TextPluginView | GalleryPluginView,
    props: AddToLayoutProps,
    idx?: number,
  ) {
    setHidePaymentButtonProps(view);
    this._cardsLayout.layout(view, props, idx);
  }

  // return maximum of all cards widths
  getCardsWidth() {
    return (
      this._cardsLayout.children
        .map((card) => {
          if (card instanceof MainLayout) {
            return card.getOccupiedWidth();
          }
          return 0;
        })
        .reduce((prevMax, current) => Math.max(prevMax, current), 0) ?? 0
    );
  }

  findCardViewBySourceId(cardSourceId: string): Layout {
    // @ts-expect-error
    return this._cardsLayout.children.find(
      // @ts-expect-error
      (child) => child?._card?.source_card_id === cardSourceId,
    );
  }
}

/**
 * Warning!
 * Views below placed here because of circular dependency error
 * TODO: Move them to appropriate folders after validationProps (mistake cause) refactoring
 */

export class CommentsAutoreplyContentBlockView extends BlockView {
  TEST_NAME = 'CommentsAutoreplyContentBlockView';

  privateReplyCard: Card | undefined;

  constructor(...params: ConstructorParameters<typeof BlockView>) {
    super(...params);
    this.privateReplyCard = this._node.block.cards.find(
      (card) => card.plugin_id === PluginType.private_reply_entry_point,
    );
  }

  // TODO fix type
  addCardToLayout(view: any, props: AddToLayoutProps, idx: number) {
    setHidePaymentButtonProps(view);
    if (view._card?.plugin_id === PluginType.quick_reply) {
      // eslint-disable-next-line
      view.props.includeReplyTypes = [QuickReplyType.text];
    }
    this._cardsLayout.layout(
      view,
      {
        ...props,
        gone: () => {
          if (
            !view._card ||
            !MESSENGER_CONTENT_TYPES_IDS.includes(view._card.plugin_id)
          ) {
            return resByFunc(props.gone);
          }
          const gone =
            !this.privateReplyCard?.config.enabled ||
            (view._card.plugin_id !==
              this.privateReplyCard?.config.content_type &&
              view._card.plugin_id !== PluginType.quick_reply);
          const lines = Object.values(this._node.outLinks)
            .flat(1)
            .filter(({ cardId }) => cardId === view._card.id);
          lines.forEach((line) => {
            // eslint-disable-next-line
            line.gone = gone;
          });
          return gone;
        },
        margin: {
          bottom: view._card?.plugin_id === PluginType.quick_reply ? 20 : 8,
          left: 20,
          right: 20,
        },
      },
      idx,
    );
  }

  getLineStartNextBlockGoneStatus() {
    if (
      this._node.block.subtype !== BLOCK_SUBTYPES.comments_autoreply_content
    ) {
      return false;
    }
    const card = this._node.block.cards?.find(
      (card) => card.plugin_id === PluginType.private_reply_entry_point,
    );
    if (!card) {
      return false;
    }
    const gone = !card.config.enabled;
    const lines = Object.values(this._node.outLinks)
      .flat(1)
      .filter(({ cardId }) => !cardId);

    lines.forEach((line) => {
      // eslint-disable-next-line
      line.gone = gone;
    });
    return gone;
  }
}

export class InstagramCommentsAutoreplyContentBlockView extends BlockView {
  TEST_NAME = 'InstagramCommentsAutoreplyContentBlockView';

  private privateReplyCard?: PrivateReplyCard;

  constructor(...params: ConstructorParameters<typeof BlockView>) {
    super(...params);
    this.privateReplyCard = this._node.block.cards.find(
      (card) => card.plugin_id === PluginType.private_reply_entry_point,
    );
  }

  addCardToLayout(view: any, props: AddToLayoutProps, idx?: number) {
    setHidePaymentButtonProps(view);
    if (view._card?.plugin_id === PluginType.quick_reply) {
      // eslint-disable-next-line no-param-reassign
      view.props.includeReplyTypes = [QuickReplyType.text];
    }
    this._cardsLayout.layout(
      view,
      {
        ...props,
        gone: () => {
          if (
            !view._card ||
            !INSTAGRAM_CONTENT_TYPES_IDS.includes(view._card.plugin_id)
          ) {
            return resByFunc(props.gone);
          }
          const gone =
            !this.privateReplyCard?.config.enabled ||
            (view._card.plugin_id !==
              this.privateReplyCard?.config.content_type &&
              view._card.plugin_id !== PluginType.quick_reply);
          const lines = Object.values(this._node.outLinks)
            .flat(1)
            .filter(({ cardId }) => cardId === view._card.id);
          lines.forEach((line) => {
            // eslint-disable-next-line no-param-reassign
            line.gone = gone;
          });
          return gone;
        },
        margin: {
          bottom: view._card?.plugin_id === PluginType.quick_reply ? 20 : 8,
          left: 20,
          right: 20,
        },
      },
      idx,
    );
  }

  getLineStartNextBlockGoneStatus() {
    if (
      this._node.block.subtype !==
      BLOCK_SUBTYPES.instagram_comments_autoreply_content
    ) {
      return false;
    }
    const card = this._node.block.cards.find(
      (card) => card.plugin_id === PluginType.private_reply_entry_point,
    );
    if (!card) {
      return false;
    }
    const gone = !card.config.enabled;
    const lines = Object.values(this._node.outLinks)
      .flat(1)
      .filter(({ cardId }) => !cardId);

    lines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      line.gone = gone;
    });
    return gone;
  }
}

export class FacebookAdsCommonBlockView extends BlockView {
  protected facebookAdsEntryPointCard: Card<FacebookAdsEntryPointConfig>;

  constructor(...params: ConstructorParameters<typeof BlockView>) {
    super(...params);
    this.facebookAdsEntryPointCard = getEntryPointCardStrict(
      this._node.block.cards,
    );
  }

  // TODO fix type
  addCardToLayout(view: any, props: AddToLayoutProps, idx: number) {
    setHidePaymentButtonProps(view);
    const entryPointCard =
      this.facebookAdsEntryPointCard ||
      getEntryPointCard(this._node.block.cards);

    if (view._card?.plugin_id === PluginType.text) {
      view.props.buttonsGone = () => {
        const currentEntryPointCardView = getEntryPointCardViewStrict(
          this._node.blockView,
        ) as EntryPointFacebookAds;

        const messageId = getFacebookMessageTypeId(
          currentEntryPointCardView.state.data,
        );
        const gone = ![FacebookMessageTypeIds.TEXT_AND_BUTTONS].includes(
          messageId,
        );

        const lines = Object.values(this._node.outLinks)
          .flat(1)
          .filter(({ cardId }) => cardId === view._card.id);
        lines.forEach((line) => {
          // eslint-disable-next-line
          line.gone = gone;
        });

        return gone;
      };
      view.props.isButtonsAvailable = () => {
        const currentEntryPointCardView = getEntryPointCardViewStrict(
          this._node.blockView,
        ) as EntryPointFacebookAds;

        const entryPointMessageId = getFacebookMessageTypeId(
          currentEntryPointCardView.state.data,
        );
        return entryPointMessageId === FacebookMessageTypeIds.TEXT_AND_BUTTONS;
      };

      const currentGreetingType = entryPointCard?.config.welcomeMessageType;

      if (currentGreetingType !== WelcomeMessageMediaType.text) {
        view.props.isEmptyStringAvailable = true;
      }

      if (entryPointCard.plugin_id === PluginType.ads_manager_sm_entry_point) {
        view.props.hideCallPhoneOption = true;
      }

      view.props.hideButtonPopupIfTargetBlockSelected = true;
    }

    if (view._card?.plugin_id === PluginType.gallery) {
      view.props.maxImageItems = 1;
      view.props.isImagesNeedToBeUploaded = true;
      view.props.hideButtonPopupIfTargetBlockSelected = true;
      view.props.hideCardControls = () => {
        return view._card.config.gallery_cards.every(
          ({ image_url }: any) => !image_url,
        );
      };
      if (entryPointCard.plugin_id === PluginType.ads_manager_sm_entry_point) {
        view.props.hideCallPhoneOption = true;
      }
    }

    if (view._card?.plugin_id === PluginType.quick_reply) {
      view.props.includeReplyTypes = [QuickReplyType.text];
      view.props.maxLength = 80;
      view.props.isEmptyQuickReplyAvailable = true;
    }

    if (
      entryPointCard.plugin_id === PluginType.ads_manager_sm_entry_point &&
      view._card?.plugin_id === PluginType.quick_reply
    ) {
      view.props.isAtLeastOneButtonIsRequired = true;
    }

    if (
      entryPointCard.plugin_id === PluginType.ads_manager_ctm_entry_point &&
      view._card?.plugin_id === PluginType.quick_reply
    ) {
      view.props.isEmptyQuickReplyAvailable = false;
    }

    if (entryPointCard.plugin_id === PluginType.ads_manager_sm_entry_point) {
      if (view._card?.plugin_id === PluginType.text) {
        view.props.isAtLeastOneButtonIsRequired = () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          ) as EntryPointFacebookAds;
          const entryPointMessageId = getFacebookMessageTypeId(
            currentEntryPointCardView.state.data,
          );

          return (
            entryPointMessageId === FacebookMessageTypeIds.TEXT_AND_BUTTONS
          );
        };
        view.props.isButtonsAvailable = () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          ) as EntryPointFacebookAds;
          const entryPointMessageId = getFacebookMessageTypeId(
            currentEntryPointCardView.state.data,
          );

          return (
            entryPointMessageId === FacebookMessageTypeIds.TEXT_AND_BUTTONS
          );
        };
      }

      if (view._card?.plugin_id === PluginType.gallery) {
        view.props.isAtLeastOneButtonIsRequired = () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          ) as EntryPointFacebookAds;
          const entryPointMessageId = getFacebookMessageTypeId(
            currentEntryPointCardView.state.data,
          );

          return (
            entryPointMessageId ===
            FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_BUTTONS
          );
        };

        view.props.buttonsGone = () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          ) as EntryPointFacebookAds;

          const messageId = getFacebookMessageTypeId(
            currentEntryPointCardView.state.data,
          );
          const gone = [FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_QR].includes(
            messageId,
          );
          const lines = Object.values(this._node.outLinks)
            .flat(1)
            .filter(({ cardId }) => cardId === view._card.id);
          lines.forEach((line) => {
            line.gone = gone;
          });

          return gone;
        };

        view.props.isButtonsAvailable = () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          ) as EntryPointFacebookAds;
          const entryPointMessageId = getFacebookMessageTypeId(
            currentEntryPointCardView.state.data,
          );
          return (
            entryPointMessageId ===
            FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_BUTTONS
          );
        };
      }
    }

    this._cardsLayout.layout(
      view,
      {
        ...props,
        gone: () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          ) as EntryPointFacebookAds;

          const messageId = getFacebookMessageTypeId(
            currentEntryPointCardView.state.data,
          );

          if (
            !view._card ||
            !FACEBOOK_ADS_PLUGINS.includes(view._card.plugin_id)
          ) {
            return resByFunc(props.gone);
          }

          const gone = !FACEBOOK_MESSAGE_TYPE_PLUGINS[messageId].includes(
            view._card.plugin_id,
          );
          const lines = Object.values(this._node.outLinks)
            .flat(1)
            .filter(({ cardId }) => cardId === view._card.id);
          lines.forEach((line) => {
            // eslint-disable-next-line
            line.gone = gone;
          });
          return gone;
        },
        margin: () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          ) as EntryPointFacebookAds;
          const messageId = getFacebookMessageTypeId(
            currentEntryPointCardView.state.data,
          );

          return {
            bottom:
              view._card?.plugin_id === PluginType.quick_reply ||
              (messageId === FacebookMessageTypeIds.TEXT_AND_BUTTONS &&
                view._card?.plugin_id === PluginType.text) ||
              (messageId ===
                FacebookMessageTypeIds.TEXT_AND_IMAGE_AND_BUTTONS &&
                view._card?.plugin_id === PluginType.gallery)
                ? 20
                : 8,
            left: 20,
            right: 20,
          };
        },
      },
      idx,
    );
  }
}

export class FacebookAdsEntryPointBlockView extends FacebookAdsCommonBlockView {
  private entryPointCardView: EntryPointFacebookAds;
  private _updateButtonView: ButtonView;
  private _loader: Loader;

  constructor(...params: ConstructorParameters<typeof BlockView>) {
    super(...params);
    this._updateButtonView = new ButtonView({
      title: i18next.t('block_view-string-2078-publish-changes-on-facebook'),
      width: pluginWidth - 32,
      height: 35,
      bgColor: HEXColors.blue,
      textColor: HEXColors.white,
      onClick: (event: InteractionEvent) => {
        event.stopPropagation();
        this.onSaveChangesOnFacebook();
      },
    });

    this._loader = new Loader(
      {
        width: pluginWidth - 32,
        height: 35,
        background: {
          fill: HEXColors.blue,
          cornerRadius: 4,
          strokeWidth: 0,
        },
      },
      loaderWhiteSvgTexture,
    );
    this._loader.alpha = 0.5;

    const entryPointCardView = getEntryPointCardView(this) as
      | EntryPointFacebookAds
      | undefined;

    if (!entryPointCardView) {
      throw new Error('Entry point card was not found');
    }

    this.entryPointCardView = entryPointCardView;
    this.entryPointCardView.addToAdsSelectorLayout(this._updateButtonView, {
      margin: { bottom: 16, left: 16, right: 16 },
      gone: () =>
        this.entryPointCardView.state.data.refresh ||
        this.entryPointCardView.state.data.config.campaigns.length === 0 ||
        getPixiFieldStrict().isViewOnly(),
    });
    this.entryPointCardView.addToAdsSelectorLayout(this._loader, {
      gone: () => !this.entryPointCardView.state.data.refresh,
      margin: { bottom: 16, left: 16, right: 16 },
    });
  }

  disabledEntryPointInteractive(value: boolean) {
    const entryPointView = getEntryPointCardView(this._node.blockView);
    if (entryPointView) {
      entryPointView.interactive = !value;
      entryPointView.interactiveChildren = !value;
    }
  }

  onSaveChangesOnFacebook() {
    this.entryPointCardView.state.set({
      refresh: true,
    });
    this._loader.start();
    this.disabledEntryPointInteractive(true);

    logFlowPluginEvent(
      this.facebookAdsEntryPointCard.plugin_id,
      'Click publish changes on Facebook',
      {
        blockId: this._node.id,
        cardId: this.facebookAdsEntryPointCard?.id,
      },
    );
    this.entryPointCardView.state
      .save()
      .then(() => {
        pollForAdsCardUploadStatus(this.facebookAdsEntryPointCard.id)
          .promise.then((res) => {
            this.entryPointCardView.syncResult =
              res.ads?.reduce((acc, ad) => {
                acc[ad.id] = ad;
                return acc;
              }, {} as Record<string, FacebookAd>) || {};
            toaster.show({
              type: ServiceMessageType.default,
              payload: {
                message: i18next.t('block_view-string--183-changes-saved'),
              },
            });
          })
          .catch((error) => {
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: Messages.somethingWentWrong,
              },
            });
            log.error({
              error,
              msg: 'Error while saving changes',
              data: { label: 'flow_save' },
            });
          })
          .finally(() => {
            this.disabledEntryPointInteractive(false);
            this.entryPointCardView.state.set({
              refresh: false,
            });
            this._loader.stop();
            this.entryPointCardView.adsSelector.createCampaignList();
            this.renderNode();
          });
        this.renderNode();
      })
      .catch(() => {
        this.entryPointCardView.state.set({
          refresh: false,
        });
        this._loader.stop();
        this.renderNode();
      });

    this.renderNode();
  }

  onBeforeRender() {
    const entryPointCardView = getEntryPointCardViewStrict(
      this._node.blockView,
    );
    // @ts-expect-error
    const messageId = getFacebookMessageTypeId(entryPointCardView.state.data);

    const isEntryPointCardValidationError = Boolean(
      this.entryPointCardView?.validationError(),
    );
    const isAnyPluginHasValidationErrors = this._cardsLayout
      ?.views()
      .find((view: any) => {
        if (!view._card) {
          return false;
        }

        if (
          [...FACEBOOK_MESSAGE_TYPE_PLUGINS[messageId]].includes(
            view._card.plugin_id,
          )
        ) {
          return view.validationError?.();
        }

        if (isFacebookAds(view._card.plugin_id)) {
          return view.state.data.config.campaigns.length === 0;
        }

        return false;
      });

    this._updateButtonView?.setDisabled(
      !!(isAnyPluginHasValidationErrors || isEntryPointCardValidationError),
    );

    super.onBeforeRender();
  }

  destroy() {
    this._loader.stop();
    super.destroy();
  }
}

export class InstagramAdsEntryPointBlockView extends BlockView {
  private instagramAdsEntryPointCard: Card;
  private entryPointCardView;
  private _updateButtonView: ButtonView;
  private _loader: Loader;

  constructor(...params: ConstructorParameters<typeof BlockView>) {
    super(...params);
    this.instagramAdsEntryPointCard = getEntryPointCardStrict(
      this._node.block.cards,
    );
    this._updateButtonView = new ButtonView({
      title: i18next.t('block_view-string-2078-publish-changes-on-facebook'),
      width: pluginWidth - 32,
      height: 35,
      bgColor: HEXColors.blue,
      textColor: HEXColors.white,
      onClick: (event: InteractionEvent) => {
        event.stopPropagation();
        this.onSaveChangesOnFacebook();
      },
    });

    this._loader = new Loader(
      {
        width: pluginWidth - 32,
        height: 35,
        background: {
          fill: HEXColors.blue,
          cornerRadius: 4,
          strokeWidth: 0,
        },
      },
      loaderWhiteSvgTexture,
    );
    this._loader.alpha = 0.5;

    this.entryPointCardView = getEntryPointCardViewStrict(
      this,
    ) as EntryPointFacebookAds;

    this.entryPointCardView.addToAdsSelectorLayout(this._updateButtonView, {
      margin: { bottom: 16, left: 16, right: 16 },
      gone: () =>
        this.entryPointCardView.state.data.refresh ||
        this.entryPointCardView.state.data.config.campaigns.length === 0 ||
        getPixiFieldStrict().isViewOnly(),
    });
    this.entryPointCardView.addToAdsSelectorLayout(this._loader, {
      gone: () => !this.entryPointCardView.state.data.refresh,
      margin: { bottom: 16, left: 16, right: 16 },
    });
  }

  disabledEntryPointInteractive(value: boolean) {
    const entryPointView = getEntryPointCardView(this._node.blockView);
    if (entryPointView) {
      entryPointView.interactive = !value;
      entryPointView.interactiveChildren = !value;
    }
  }

  onSaveChangesOnFacebook() {
    this.entryPointCardView.state.set({
      refresh: true,
    });
    this._loader.start();
    this.disabledEntryPointInteractive(true);

    logFlowPluginEvent(
      this.instagramAdsEntryPointCard.plugin_id,
      'Click publish changes on Facebook',
      {
        blockId: this._node.id,
        cardId: this.instagramAdsEntryPointCard?.id,
      },
    );
    this.entryPointCardView.state
      .save()
      .then(() => {
        pollForAdsCardUploadStatus(this.instagramAdsEntryPointCard.id)
          .promise.then((res) => {
            this.entryPointCardView.syncResult =
              res.ads?.reduce((acc, ad) => {
                acc[ad.id] = ad;
                return acc;
              }, {} as Record<string, FacebookAd>) || {};
            toaster.show({
              type: ServiceMessageType.default,
              payload: {
                message: i18next.t('block_view-string--183-changes-saved'),
              },
            });
          })
          .catch((error) => {
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: Messages.somethingWentWrong,
              },
            });
            log.error({
              error,
              msg: 'Error while saving changes',
              data: { label: 'flow_save' },
            });
          })
          .finally(() => {
            this.disabledEntryPointInteractive(false);
            this.entryPointCardView.state.set({
              refresh: false,
            });
            this._loader.stop();
            this.entryPointCardView.adsSelector.createCampaignList();
            this.renderNode();
          });
        this.renderNode();
      })
      .catch(() => {
        this.entryPointCardView.state.set({
          refresh: false,
        });
        this._loader.stop();
        this.renderNode();
      });

    this.renderNode();
  }

  onBeforeRender() {
    const entryPointCardView = getEntryPointCardView(
      this._node.blockView,
    ) as EntryPointFacebookAds;
    const messageId = getInstagramMessageTypeId(entryPointCardView.state.data);

    const isEntryPointCardValidationError = Boolean(
      this.entryPointCardView?.validationError(),
    );
    const isAnyPluginHasValidationErrors = this._cardsLayout
      ?.views()
      .find((view: any) => {
        if (!view._card) {
          return false;
        }

        if (
          INSTAGRAM_MESSAGE_TYPE_PLUGINS[messageId].includes(
            view._card.plugin_id,
          )
        ) {
          return view.validationError?.();
        }

        if (isInstagramAds(view._card.plugin_id)) {
          return view.state.data.config.campaigns.length === 0;
        }

        return false;
      });

    this._updateButtonView?.setDisabled(
      !!(isAnyPluginHasValidationErrors || isEntryPointCardValidationError),
    );

    super.onBeforeRender();
  }

  addCardToLayout(view: any, props: AddToLayoutProps, idx: number) {
    setHidePaymentButtonProps(view);
    const entryPointCard =
      this.instagramAdsEntryPointCard ||
      getEntryPointCard(this._node.block.cards);

    if (view._card?.plugin_id === PluginType.text) {
      view.props.buttonsGone = () => {
        return true;
      };
      view.props.isButtonsAvailable = () => {
        return false;
      };
      view.props.textMaxLength = 300;

      const currentGreetingType = entryPointCard?.config.welcomeMessageType;

      if (currentGreetingType !== WelcomeMessageMediaType.text) {
        view.props.isEmptyStringAvailable = true;
      }

      view.props.hideButtonPopupIfTargetBlockSelected = true;
    }

    if (view._card?.plugin_id === PluginType.quick_reply) {
      view.props.includeReplyTypes = [QuickReplyType.text];
      view.props.maxLength = 80;
      view.props.plusButtonText = i18next.t(
        'modernComponents.FlowBuilder.views.blockView.addFAQ',
      );
      view.props.isEmptyQuickReplyAvailable = true;
      view.props.isAtLeastOneButtonIsRequired = true;
    }

    this._cardsLayout.layout(
      view,
      {
        ...props,
        gone: () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          );

          const messageId = getInstagramMessageTypeId(
            // @ts-expect-error
            currentEntryPointCardView.state.data,
          );

          if (
            !view._card ||
            !INSTAGRAM_ADS_PLUGINS.includes(view._card.plugin_id)
          ) {
            return resByFunc(props.gone);
          }

          const gone = !INSTAGRAM_MESSAGE_TYPE_PLUGINS[messageId].includes(
            view._card.plugin_id,
          );
          const lines = Object.values(this._node.outLinks)
            .flat(1)
            .filter(({ cardId }) => cardId === view._card.id);
          lines.forEach((line) => {
            line.gone = gone;
          });
          return gone;
        },
        margin: () => {
          const currentEntryPointCardView = getEntryPointCardViewStrict(
            this._node.blockView,
          );
          const messageId = getInstagramMessageTypeId(
            // @ts-expect-error
            currentEntryPointCardView.state.data,
          );

          return {
            bottom:
              view._card?.plugin_id === PluginType.quick_reply ||
              (messageId === InstagramMessageTypeIds.TEXT &&
                view._card?.plugin_id === PluginType.text)
                ? 20
                : 8,
            left: 20,
            right: 20,
          };
        },
      },
      idx,
    );
  }

  destroy() {
    this._loader.stop();
    super.destroy();
  }
}

export class InboundLinksBlockView extends BlockView {
  TEST_NAME = 'InboundLinksContentBlockView';

  constructor(...params: ConstructorParameters<typeof BlockView>) {
    super(...params);

    this.visible = false;

    try {
      const entryPointCardView = getEntryPointCardViewStrict(
        this,
      ) as InboundLinksEntryPointView;
      this.invalidateInboundLinksViewState(entryPointCardView.state);
    } catch (error) {
      // For debug purpose
      // This case is covered by flows migration on backend side
      const { id: flowId, botId } = this._node.controller.flow;
      log({
        msg: 'Inbound links cards is empty',
        level: Level.error,
        data: {
          botId,
          flowId,
        },
      });
    }
  }

  private async invalidateInboundLinksViewState(
    state: InboundLinksEntryPointView['state'],
  ) {
    state.set({ refresh: true });
    await state.save();
    if (
      InboundLinksEntryPointView.getInboundLinksCounted(state.data.config)
        .allCount > 0
    ) {
      this.visible = true;
      this.renderNode();
    }
  }

  getLineStartNextBlockGoneStatus() {
    const gone = !this.visible;
    const lines = Object.values(this._node.outLinks)
      .flat(1)
      .filter(({ cardId }) => !cardId);

    lines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      line.gone = gone;
    });
    return gone;
  }

  destroy() {
    super.destroy();
  }
}

export class FacebookAdsJsonEntryPointBlockView extends FacebookAdsCommonBlockView {
  private jsonDataLoading: boolean = false;
  private needCopyJson: boolean = false;
  private readonly copyJsonButton: ButtonView;
  private readonly loader: Loader;
  private jsonAdsData: string = '';
  private jsonAdsDataSubscription: Subscription;

  constructor(...params: ConstructorParameters<typeof BlockView>) {
    super(...params);

    const { botId } = getFlowControllerStrict().flow;
    const { id: blockId } = this._node.block;

    this.copyJsonButton = new ButtonView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.FacebookAdsJson.CopyJSON',
      ),
      width: pluginWidth,
      height: 35,
      bgColor: () => (this.needCopyJson ? HEXColors.blue : HEXColors.white),
      textColor: () => (this.needCopyJson ? HEXColors.white : HEXColors.black),
      strokeWidth: 1,
      onClick: (event: InteractionEvent) => {
        event.stopPropagation();
        this.needCopyJson = false;
        this.copyJsonButton.renderNode();
        logFlowPluginEvent(
          PluginType.ads_manager_ctm_json_entry_point,
          'copy json',
          {
            blockId: this._node.id,
          },
        );
        const json = prettifyJson(this.jsonAdsData);
        copyToClipboard(json);
        window.adsJSON = json;
        showInfoToaster(
          'modernComponents.FlowBuilder.views.entryPoints.FacebookAdsJson.Copied',
        );
      },
    });

    this.loader = new Loader(
      {
        width: pluginWidth,
        height: 35,
        background: {
          fill: HEXColors.blue,
          cornerRadius: 4,
          strokeWidth: 0,
        },
      },
      loaderWhiteSvgTexture,
    );

    this.loader.alpha = 0.5;

    this.jsonAdsDataSubscription = getAdsJsonDataObservable(
      botId,
      blockId,
    ).observable.subscribe(({ json: jsonAdsData, loading }) => {
      this.jsonDataLoading = loading;
      if (loading) {
        this.loader.start();
      } else {
        this.loader.stop();
      }
      if (!this.jsonAdsData && jsonAdsData) {
        this.jsonAdsData = jsonAdsData;
      }
      if (jsonAdsData && !equals(this.jsonAdsData, jsonAdsData)) {
        this.jsonAdsData = jsonAdsData;
        if (this.isAllActivePluginsValid()) {
          this.needCopyJson = true;
          showInfoToaster(
            'modernComponents.FlowBuilder.views.entryPoints.FacebookAdsJson.DontForget',
            undefined,
            undefined,
            i18next.t(
              'modernComponents.FlowBuilder.views.entryPoints.FacebookAdsJson.Open',
            ),
            () => {
              window.open(
                'https://business.facebook.com/adsmanager/manage/',
                '_blank',
              );
            },
            5000,
          );
        }
      }
      this._node.renderNode();
    });

    this.copyJsonButton.on('pointerdown', (e) => {
      e.stopPropagation();
    });

    this._mainLayout.addToLayout(this.copyJsonButton, {
      margin: {
        left: 20,
        top: -4,
        bottom: 20,
      },
      gone: () => this.jsonDataLoading,
    });

    this._cardsLayout.addToLayout(this.loader, {
      margin: {
        left: 20,
        top: -4,
        bottom: 20,
      },
      gone: () => !this.jsonDataLoading,
    });
  }

  private isAllActivePluginsValid() {
    return !this._cardsLayout?.children.some(
      (child: any) =>
        child.visible &&
        !isEntryPoint(child._card?.plugin_id) &&
        child.validationError?.(),
    );
  }

  private setJsonCopyButtonStatus() {
    this.copyJsonButton.setDisabled(!this.isAllActivePluginsValid());
  }

  onBeforeRender() {
    this.setJsonCopyButtonStatus();
    super.onBeforeRender();
  }

  destroy() {
    this.jsonAdsDataSubscription?.unsubscribe();
    super.destroy();
  }
}

export class BroadcastRootBlockView extends BlockView {
  // TODO uncomment this when back is ready
  // private tooltipView!: MainLayout;

  private whatsappBroadcastSubscription: Subscription | undefined;
  private isReadOnly: boolean = false;

  constructor(node: Node) {
    super(node);
    this.subscribeToWhatsappBroadcastData();
  }

  private subscribeToWhatsappBroadcastData() {
    const params = matchPath(
      window.location.pathname,
      '/bot/:botId/broadcast/:broadcastId/flow/:flowId',
    )?.params as {
      broadcastId: string;
    };

    if (params?.broadcastId) {
      this.whatsappBroadcastSubscription = getWhatsappBroadcastObservable(
        getFlowControllerStrict().flow.botId,
        params.broadcastId,
      ).subscribe(({ whatsappBroadcast }) => {
        this.isReadOnly = !isEditableBroadcast(whatsappBroadcast);

        this.renderNode();
      });
    }
  }

  protected mountPlusButton(blockType: string) {
    this.createPlusButton(blockType);
    if (!this._plusPluginView) {
      return;
    }
    this._mainLayout.layout(this._plusPluginView, {
      margin: { bottom: 20, left: 20 },
      gone: () =>
        this._node.block.cards.findIndex(
          (card) => card.plugin_id === PluginType.quick_reply,
        ) !== -1 || this.isReadOnly,
    });
  }

  validateLine() {
    return {
      fits: false,
      tooltip: undefined,
    };
  }

  protected renderFooter(): void {
    const footer = new VLayout({ width: pluginWidth });
    const box = new HLayout({ width: pluginWidth });

    footer.addToLayout(box, {
      margin: { left: blockWidth - 40, bottom: 8 },
    });
    this._mainLayout.addToLayout(footer);

    this._mainLayout.addToLayout(this.renderDefaultLineStart(), {
      margin: { left: blockWidth - 18, bottom: 8 },
      gone: () => this.getLineStartNextBlockGoneStatus(),
    });
  }

  protected extendMainLayout(): void {
    const headerLayoutProps = { margin: { bottom: 16 } };
    const { background } = this.header._layoutProps;
    if (background) {
      background.fill = HEXColors.accent2Normal;
    } else {
      this.header._layoutProps.background = { fill: HEXColors.accent2Normal };
    }
    this._mainLayout.layout(this.header, headerLayoutProps);
  }

  getLineStartNextBlockGoneStatus() {
    const gone =
      this._node.block.cards.findIndex(
        (card) => card.plugin_id === PluginType.quick_reply,
      ) === -1;
    if (gone && this._node.block.next_block_ids?.length) {
      this._lineStartView?.removeLink();
      // this._node.block.next_block_ids = [];
      // this._node.updateBlock();
      // this._node.renderNode();
    }
    return gone;
  }

  destroy(): void {
    this.whatsappBroadcastSubscription?.unsubscribe();
    // TODO uncomment this when back is ready
    // removeTooltip(this.tooltipView);
    super.destroy();
  }
}
