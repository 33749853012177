import i18next from 'i18next';
import { Lens, lensPath, set } from 'ramda';
import isEqual from 'lodash-es/isEqual';
import { HEXColors } from '@ui/_common/colors';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { HTMLText, TextureShape } from '../../../components/Elements/Shapes';
import { shopifyBackInStockEntryPointFragment_config as ShopifyBackInStockEntryPointConfig } from '../../../../Plugins/ShopifyBackInStockEntryPoint/@types/shopifyBackInStockEntryPointFragment';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { pluginWidth } from '../../plugin_consts';
import { Margin } from '../../utility_classes';
import { Checkbox } from '../../kit/Checkbox';
import { removeTooltip, tooltipScaled } from '../../helpers/TooltipHelpers';
import { STAT_VIEW_WIDTH } from '../../kit/ItemStatView/ItemStatView';
import { createLineMenu } from '../../Menu/createLineMenu';
import {
  LineStartView,
  LineStartViewProps,
} from '../../components/Line/LineStartView';
import { ShopifyAccount } from '../../components/ShopifyAccount/ShopifyAccount';
import { showConnectPageDialog } from '../common/utils/showConnectPageDialog';
import { SendToMessengerView } from '../common/SendToMessenger/SendToMessengerView';
import { infoSvgTexture } from '../../../assets/textures';
import { getPixiFieldStrict } from '../../../PixiFieldRepository';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import { withDefaultConfig, isFlowHasOptInBlock } from './utils';
import { BlockView } from '../../block_view';
import {
  BlockShortcutId,
  getBlockShortcut,
} from '../../helpers/blocksShortcuts';
import { OptInError } from './errors';
import { LeadToBlockError } from '../common/errors';
import { IEntryPointView } from '../../../types';
import {
  OTN_LINE_ID,
  PLUGIN_DEFAULT_CONFIG,
} from '../../../../Plugins/ShopifyBackInStockEntryPoint/ShopifyBackInStockEntryPointConst';
import { tooltips } from '../../Menu/menu_view';
import { resByFunc } from '../../utils';
import { validateIsShopifyConnected } from '../common/utils/validateIsShopifyConnected';
import { validateIsPageConnecting } from '../common/utils/validateIsPageConnecting';
import { Node } from '../../../Node';

interface SectionProps extends LineStartViewProps {
  cardId: string | null;
  children: HLayout;
}

class Section extends HLayout {
  private readonly props: SectionProps;

  public readonly lineView: LineStartView;

  constructor(props: SectionProps) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
    });

    this.props = props;

    const childrenMargin = new Margin({ left: 16, y: 16 });

    this.addToLayout(this.props.children, {
      margin: childrenMargin,
    });

    this.lineView = new LineStartView(this.props, this.props.cardId);

    this.addToLayout(this.lineView, {
      margin: new Margin({
        left: -6,
        top: childrenMargin.top + 5,
      }),
    });
  }

  destroy() {
    super.destroy();
  }
}

const messageWithinLens = lensPath(['notify_unreachable']);
const settingsLens = lensPath(['on_subscribe_block_id']);
const otnLens = lensPath(['on_back_in_stock_unreachable_block_id']);
const messageWithinWindowLens = lensPath(['on_back_in_stock_block_id']);

export class EntryPointShopifyBackInStock
  extends VLayout
  implements
    StatefulPluginDelegate<ShopifyBackInStockEntryPointConfig>,
    IEntryPointView
{
  public _node: Node;

  private readonly state: StatefulPlugin<ShopifyBackInStockEntryPointConfig>;

  private icon!: VLayout;
  private sendToMessengerView!: SendToMessengerView;
  private settingsTitle!: HTMLText;
  private descriptionTitle!: HTMLText;
  private settingsLineStartView!: LineStartView;
  private otnSection!: Section;
  private messageWithinSection!: Section;
  private shopifyAccountView!: ShopifyAccount;

  constructor(
    state: StatefulPlugin<ShopifyBackInStockEntryPointConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.addToLayout(this.createShopifyAccountView());
    this.addToLayout(this.createSettingsView());
    this.addToLayout(this.createOtnView());
    this.addToLayout(this.createMessageWithinWindowCheckboxView());
    this.addToLayout(this.createMessageWithinWindowView(), {
      gone: () => !this.state.data.config.notify_unreachable,
    });

    this.updateViews();
  }

  private createShopifyAccountView() {
    const wrapper = new HLayout();

    this.shopifyAccountView = new ShopifyAccount({
      eventPropertyBag: { pluginId: PluginType.shopify_back_in_stock },
      onConnectedAccountStatusChange: () => {
        this.renderNode();
      },
      blockId: this._node.block.id,
      onPageConnectRequest: () => {
        showConnectPageDialog(this._node, this.state.data);
      },
    });

    wrapper.addToLayout(this.shopifyAccountView, {
      margin: new Margin({
        bottom: 16,
      }),
    });

    return wrapper;
  }

  private createSettingsView() {
    const wrapper = new VLayout();

    const box = new VLayout({
      background: {
        fill: HEXColors.greyLight20,
        cornerRadius: 12,
      },
      width: pluginWidth,
    });

    this.settingsTitle = new HTMLText({
      fontSize: 18,
      fontStyle: 'semibold',
      text: '',
      width: pluginWidth - 64,
    });

    box.addToLayout(this.settingsTitle, {
      margin: new Margin({ x: 24, top: 20 }),
    });

    this.descriptionTitle = new HTMLText({
      fontSize: 12,
      fill: HEXColors.greyDark,
      text: '',
      width: pluginWidth - 64,
    });

    box.addToLayout(this.descriptionTitle, {
      margin: new Margin({ x: 24, top: 8 }),
    });

    const sendToMessengerWrapper = new HLayout();

    this.sendToMessengerView = new SendToMessengerView();
    sendToMessengerWrapper.addToLayout(this.sendToMessengerView);

    this.settingsLineStartView = new LineStartView(
      {
        from: this,
        node: this._node,
        offset: 40,
        mandatory: true,
        items: createLineMenu({
          isShowOptInConfirmation: true,
        }),
        onConnected: this.onLineConnected(settingsLens),
        onRemoved: this.onLineRemoved(settingsLens),
      },
      this.state.data.config.on_subscribe_block_id,
    );

    this.sendToMessengerView.addToLayout(this.settingsLineStartView, {
      margin: new Margin({
        left: pluginWidth - 6,
        top: 32,
      }),
    });
    box.addToLayout(sendToMessengerWrapper);

    wrapper.addToLayout(box, {
      margin: new Margin({ bottom: 16 }),
    });

    wrapper.on('click', () => {
      logFlowPluginEvent(PluginType.shopify_back_in_stock, 'open settings');
      getPixiFieldStrict().fixBlockPosition(
        this._node.blockView,
        getPanelWidth(),
      );
      editPluginInEditorPanel(this._node.id, this.state.data);
    });

    return wrapper;
  }

  private createOtnView() {
    const children = new HLayout({
      width: pluginWidth - 16,
    });

    const textWithIcon = new HLayout({
      width: pluginWidth - 32 - STAT_VIEW_WIDTH,
    });

    const text = new HTMLText({
      text: i18next.t('shopify.backInStock.entryPoint.otn'),
      fontSize: 15,
      lineHeight: 1.4,
      trustedHtml: true,
    });
    textWithIcon.addToLayout(text);

    this.icon = new VLayout({}).addToLayout(
      new TextureShape({
        texture: infoSvgTexture,
        width: 16,
        height: 16,
      }),
    );

    tooltipScaled({
      view: this.icon,
      text: tooltips().optInConfirmation.html,
      overTimeout: 500,
      url: tooltips().optInConfirmation.url,
    });

    textWithIcon.addToLayout(this.icon, {
      margin: new Margin({ left: 6, top: 3 }),
    });

    children.addToLayout(textWithIcon);

    // const stats = getFullCounterStats(
    //   this.state.data.config.on_back_in_stock_unreachable_counter_id || '',
    //   this._node.block.id,
    // );
    //
    // if (stats) {
    //   children.addToLayout(
    //     new ItemStatView({
    //       stats,
    //       showPercentValue: true,
    //     }),
    //   );
    // } else {
    //   children.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));
    // }

    children.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));

    this.otnSection = new Section({
      id: OTN_LINE_ID,
      from: this,
      node: this._node,
      mandatory: true,
      children,
      defaultTargetBlockData: getBlockShortcut(BlockShortcutId.backInStockOTN),
      onConnected: this.onLineConnected(otnLens),
      onRemoved: this.onLineRemoved(otnLens),
      cardId: this.state.data.config.on_back_in_stock_unreachable_block_id,
    });

    return new VLayout().addToLayout(this.otnSection, {
      margin: new Margin({ bottom: 16 }),
    });
  }

  private createMessageWithinWindowCheckboxView() {
    const wrapper = new VLayout({});

    const checkbox = new Checkbox({
      text: i18next.t('shopify.backInStock.entryPoint.messageWithinWindow'),
      value: this.state.data.config.notify_unreachable,
      onChange: (value) => {
        logFlowPluginEvent(
          PluginType.shopify_back_in_stock,
          'Message withing 24-hour window change',
          {
            enable: value,
          },
        );
        this.state.set(({ config }) => ({
          config: set(messageWithinLens, value, config),
        }));
        this.state.save().finally(() => this.renderNode());
      },
    });

    wrapper.addToLayout(checkbox, { margin: new Margin({ bottom: 16 }) });

    return wrapper;
  }

  private createMessageWithinWindowView() {
    const children = new HLayout({
      width: pluginWidth - 16,
    });

    const text = new HTMLText({
      width: pluginWidth - 32 - STAT_VIEW_WIDTH,
      fontSize: 15,
      lineHeight: 1.4,
      trustedHtml: true,
      text: i18next.t('shopify.backInStock.entryPoint.messageWithinWindow'),
    });

    children.addToLayout(text);

    // const stats = getFullCounterStats(
    //   this.state.data.config.on_back_in_stock_counter_id || '',
    //   this._node.block.id,
    // );
    //
    // if (stats) {
    //   children.addToLayout(
    //     new ItemStatView({
    //       stats,
    //       showPercentValue: true,
    //     }),
    //   );
    // } else {
    //   children.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));
    // }

    children.addToLayout(new HLayout({ width: STAT_VIEW_WIDTH }));

    this.messageWithinSection = new Section({
      from: this,
      node: this._node,
      mandatory: true,
      children,
      onConnected: this.onLineConnected(messageWithinWindowLens),
      onRemoved: this.onLineRemoved(messageWithinWindowLens),
      cardId: this.state.data.config.on_back_in_stock_block_id,
      items: createLineMenu({
        isShowBackInStockMessage: true,
      }),
    });

    return this.messageWithinSection;
  }

  private updateViews() {
    const config = withDefaultConfig(this.state.data.config);

    this.sendToMessengerView.updateConfig({
      size: config.button_size,
      color: config.button_color,
      cta_text: config.button_text,
    });
    this.settingsTitle.text(config.title.trim());
    this.descriptionTitle.text(config.description.trim());
  }

  private onLineConnected(lens: Lens) {
    return (blockView: BlockView) => {
      this.state.set(({ config }) => {
        const result = {
          config: set(lens, blockView.id(), config),
        };
        return result;
      });
      this.state.save().finally(() => this.renderNode());
    };
  }

  private onLineRemoved(lens: Lens) {
    return () => {
      this.state.set(({ config }) => ({
        config: set(lens, null, config),
      }));
      this.state.save().finally(() => this.renderNode());
    };
  }

  public validationError() {
    const pageConnectedError = validateIsPageConnecting(this._node);

    if (pageConnectedError) {
      return pageConnectedError;
    }

    const shopifyConnectedError = validateIsShopifyConnected(
      Boolean(this.shopifyAccountView.connectedAccount),
      this._node,
    );

    if (shopifyConnectedError) {
      return shopifyConnectedError;
    }

    const hasOptInBlock = isFlowHasOptInBlock();
    if (!hasOptInBlock) {
      return new OptInError();
    }

    const { config } = this.state.data;
    if (
      !config.on_back_in_stock_unreachable_block_id ||
      (config.notify_unreachable && !config.on_back_in_stock_block_id)
    ) {
      return new LeadToBlockError();
    }

    return undefined;
  }

  onBeforeRender() {
    const { config } = this.state.data;

    if (config?.on_back_in_stock_unreachable_block_id) {
      this._node.addOutLink(
        config.on_back_in_stock_unreachable_block_id,
        this.otnSection.lineView._lineView,
      );
    }

    if (config?.on_back_in_stock_block_id) {
      this._node.addOutLink(
        config?.on_back_in_stock_block_id,
        this.messageWithinSection.lineView._lineView,
      );
    }

    if (config?.on_subscribe_block_id) {
      this._node.addOutLink(
        config?.on_subscribe_block_id,
        this.settingsLineStartView._lineView,
      );
    }

    this.messageWithinSection.lineView._lineView.gone = !!resByFunc(
      this.messageWithinSection.layoutProperties?.gone,
    );
  }

  pluginDidSet() {
    this.updateViews();
    this.renderNode();
  }

  public hasSignificantChangesInConfig() {
    return !isEqual(PLUGIN_DEFAULT_CONFIG, this.state.data.config);
  }

  destroy() {
    this.state.destroy();
    removeTooltip(this.icon);
    super.destroy();
  }
}
