import { clone } from 'ramda';
import debounce from 'lodash-es/debounce';
import { getPixiFieldStrict } from '../../PixiFieldRepository';
import { LayoutProps, MainLayout } from '../../components/Elements/Layouts';
import {
  FlowBuilderOverlayEvent,
  OverlayComponentEvent,
  overlayEventEmitter,
  OverlayType,
} from '../../FlowBuilderOverlay';
import { HTMLText, HTMLTextProps } from '../../components/Elements/Shapes';
import { MAX_SCALE } from '../../PixiField';
import { hideControls } from '../helpers/ControlsHelpers';
import { resByFunc } from '../utils';
import { PropFunc } from '../../types';
import { EventPayload } from '../../../../utils/EventEmitter';

interface Caption {
  text: string;
  fill: string;
}

export interface OverlayFieldCommonProps<T>
  extends Pick<LayoutProps, 'width' | 'height' | 'name'> {
  config: T;
  overlayWidth?: number;
  editable?: PropFunc<boolean>;
  onStartEditing?: () => void;
  onDoneEditing?: (config: T) => void;
  onChange?: (config: T) => void;
  onSetSingleLine?: VoidFunction;
}

interface OverlayFieldProps<T = {}>
  extends HTMLTextProps,
    OverlayFieldCommonProps<T> {
  overlayType: OverlayType;
}

export abstract class OverlayFieldBase<T = {}> extends MainLayout {
  TEST_NAME = 'OverlayFieldBase';
  protected overlayShown: boolean;
  protected config: T;
  private onStartEditing?: () => void;
  private onChange?: (config: T) => void;
  private onDoneEditing?: (config: T) => void;
  private onSetSingleLine?: VoidFunction;
  private backupConfig: T;
  private unsubscribers: (() => void)[];
  private isViewportZoomed: boolean;
  private overlayType: OverlayType;
  private isCaptionSet: boolean;
  private htmlTextProps: HTMLTextProps;
  private overlayWidth: number | undefined;

  protected htmlTextNode: HTMLText;

  constructor({
    onStartEditing,
    onChange,
    onDoneEditing,
    onSetSingleLine,
    config,
    overlayType,
    background,
    editable,
    width,
    height,
    overlayWidth,
    ...htmlTextProps
  }: OverlayFieldProps<T>) {
    super({
      background,
      width,
      height,
    });

    this.config = config;
    this.backupConfig = clone(config);
    this.onSetSingleLine = onSetSingleLine;
    this.onStartEditing = onStartEditing;
    this.onChange = onChange;
    this.onDoneEditing = onDoneEditing;
    this.overlayShown = false;
    this.unsubscribers = [];
    this.isViewportZoomed = false;
    this.isCaptionSet = false;
    this.overlayType = overlayType;
    this.htmlTextProps = htmlTextProps;
    this.overlayWidth = overlayWidth;

    this.htmlTextNode = new HTMLText({
      width: width ? width - 1 : undefined,
      height,
      ...htmlTextProps,
    });

    this.addToLayout(this.htmlTextNode);

    this.on('pointerdown', (event: Event) => {
      if (resByFunc(editable)) {
        event.stopPropagation();
      }
    });

    this.on('click', (event: Event) => {
      if (resByFunc(editable)) {
        event.stopPropagation();
        this.startEditing();
      }
    });
  }

  onBeforeRender() {
    const caption = this.prepareCaption();
    if (caption && !this.isCaptionSet) {
      this.isCaptionSet = true;
      this.htmlTextNode.text(caption.text);
      this.htmlTextNode.fill(caption.fill);
    } else if (!caption) {
      if (this.isCaptionSet) {
        this.htmlTextNode.fill(resByFunc(this.htmlTextProps.fill));
        this.isCaptionSet = false;
      }
      this.updateText();
    }
  }

  updateText() {
    this.htmlTextNode?.text(this.configToHtml());
  }

  startEditing() {
    this.showOverlay();
  }

  showOverlay() {
    if (this.overlayShown) {
      return;
    }
    this.overlayShown = true;
    const { viewport } = getPixiFieldStrict();
    hideControls();
    this.onStartEditing?.();

    overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
      overlayType: this.overlayType,
      getOverlayOptions: () => ({
        config: this.config,
        ...this.getOverlayOptions(),
      }),
    });

    this.unsubscribers.push(
      overlayEventEmitter.on(
        OverlayComponentEvent.change,
        ({ value } = {} as EventPayload<any>) => {
          this.config = value;
          this.onChange?.(this.configPostProcessor(value));
          this.onChangeHandler();
        },
      ),
    );

    this.unsubscribers.push(
      overlayEventEmitter.on(
        OverlayComponentEvent.keydown,
        (event: KeyboardEvent) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            this.acceptChanges();
            this.removeOverlay();
            return;
          }
          if (event.key === 'Escape') {
            Object.assign(this.config, this.backupConfig);
            this.removeOverlay();
          }
        },
      ),
    );

    viewport.on('zoomed', this.zoomedHandler);
    viewport.on('zoomed-end', this.zoomEndHandler);

    setTimeout(() => {
      this.setPosition();
      if (this.htmlTextNode.layoutProperties) {
        this.htmlTextNode.layoutProperties.visible = false;
      }
      this.renderNode();
      this.addClickHandler();
    });
  }

  addClickHandler() {
    window.addEventListener('mousedown', this.handleOutsideClick);
  }

  acceptChanges() {
    this.backupConfig = clone(this.config);
  }

  handleOutsideClick = () => {
    this.acceptChanges();
    this.removeOverlay();
  };

  setPosition = () => {
    const { scale } = getPixiFieldStrict().viewport;
    const { x, y } = this.globalPosition();
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
      position: {
        x,
        y,
        width: this.overlayWidth || this.width,
        height: this.height,
        scale: scale.x,
      },
    });
  };

  zoomEndHandler = debounce(() => {
    if (this.isViewportZoomed && this.overlayShown) {
      this.setPosition();
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.show);
      if (this.htmlTextNode.layoutProperties) {
        this.htmlTextNode.layoutProperties.visible = false;
      }
      this.renderNode();
      this.isViewportZoomed = false;
    }
  }, 350);

  zoomedHandler = () => {
    const { scale } = getPixiFieldStrict().viewport;
    if (!this.isViewportZoomed && this.overlayShown && scale.x < MAX_SCALE) {
      overlayEventEmitter.emit(FlowBuilderOverlayEvent.hide);
      if (this.htmlTextNode.layoutProperties) {
        this.htmlTextNode.layoutProperties.visible = true;
      }
      this.renderNode();
      this.isViewportZoomed = true;
      this.zoomEndHandler();
    }
  };

  removeOverlay() {
    const { viewport } = getPixiFieldStrict();
    this.unsubscribers.forEach((unsubscriber) => unsubscriber());
    this.zoomEndHandler.cancel();
    this.isViewportZoomed = false;
    window.removeEventListener('mousedown', this.handleOutsideClick);
    viewport.off('zoomed', this.zoomedHandler);
    viewport.off('zoomed-end', this.zoomEndHandler);
    this.overlayShown = false;
    if (this.htmlTextNode.layoutProperties) {
      this.htmlTextNode.layoutProperties.visible = true;
    }
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
    this.renderNode();
    this.onDoneEditing?.(this.config);
  }

  isEditing() {
    return this.overlayShown;
  }

  private isSingleLineChange: boolean = false;

  setSingleLine(value: boolean) {
    if (!this.isSingleLineChange) {
      this.isSingleLineChange = this.htmlTextNode._props.singleLine !== value;
    }
    this.htmlTextNode.singleLine(value);
  }

  renderElement() {
    super.renderElement();
    if (this.isSingleLineChange) {
      this.onSetSingleLine?.();
      this.isSingleLineChange = false;
    }
    return this;
  }

  renderNode() {
    super.renderNode();
    if (this.isSingleLineChange) {
      this.onSetSingleLine?.();
      this.isSingleLineChange = false;
    }
    return this;
  }

  onChangeHandler() {}

  configPostProcessor(config: T) {
    return config;
  }

  abstract getOverlayOptions(): any;

  abstract configToHtml(): string;

  abstract prepareCaption(): Caption | undefined;
}
