import { stripTags } from '@utils/stripTags';
import { facebookShopsEntryPointFragment_config_products } from '@components/Plugins/FacebookShopsEntryPoint/@types/facebookShopsEntryPointFragment';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import { plurals } from '@utils/Plurals';
import { HEXColors } from '@ui/_common/colors';
import { getFullCounterStats } from '../../../components/BlockStatsView/utils';
import {
  ItemStatView,
  STAT_VIEW_WIDTH,
} from '../../../kit/ItemStatView/ItemStatView';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import {
  HLayout,
  LayoutProps,
  MainLayout,
  VLayout,
} from '../../../../components/Elements/Layouts';
import { Image, HTMLText } from '../../../../components/Elements/Shapes';
import { EntryPointFacebookShopsCard } from '../EntryPointFacebookShops';
import { Node } from '../../../../Node';
import emptyPictureUrl from '../../common/images/empty-picture.svg';
import { pluginWidth } from '../../../plugin_consts';

interface ProductItemProps extends LayoutProps {
  config: facebookShopsEntryPointFragment_config_products;
  card: EntryPointFacebookShopsCard;
  node: Node;
}

export class ProductItem extends HLayout {
  TEST_NAME = 'ProductItem';
  config: facebookShopsEntryPointFragment_config_products;

  constructor({ config, node, card, ...layoutProps }: ProductItemProps) {
    super({
      height: 32,
      ...layoutProps,
    });
    this.config = config;

    const { preview } = config;

    if (!preview) {
      return;
    }

    const link = new HLayout({
      height: 32,
      cursor: {
        in: 'pointer',
      },
      background: {
        fill: HEXColors.white,
        opacity: 0,
      },
    });

    link.on('click', () => {
      window.open(
        `https://www.facebook.com/commerce/products/${config.preview?.id}/`,
        '_blank',
      );
      logFlowPluginEvent(
        PluginType.facebook_shops_entry_point,
        'Product click (open on FB)',
        {
          blockId: node.id,
          cardId: card?.id,
          productId: config.preview?.id,
        },
      );
    });

    link.addToLayout(
      new Image({
        url: preview.image_url || emptyPictureUrl,
        cornerRadius: 4,
        width: 32,
        height: 32,
      }),
      {
        margin: { right: 12 },
      },
    );

    const stats = getFullCounterStats(
      `${card.id}_${config.retailer_product_group_id}_${
        config.product_id || 'null'
      }`,
      node.block.id,
    );

    link.addToLayout(
      new VLayout({})
        .addToLayout(
          new HTMLText({
            text: `<u style="text-transform: capitalize; max-width: ${
              224 - (stats ? STAT_VIEW_WIDTH : 0)
            }px; display: block; text-overflow: ellipsis; overflow: hidden;text-decoration-color:${
              HEXColors.grey
            };">${removeExtraSpaces(
              stripTags(
                preview.name ||
                  window.i18next.t('ProductItem-string--578-no-text'),
              ),
              ' ',
            )}</u>`,
            singleLine: true,
            fontSize: 15,
            fill: preview.name ? HEXColors.black : HEXColors.greyDark,
          }),
          {
            margin: { top: -4 },
          },
        )
        .addToLayout(
          new HTMLText({
            text:
              preview.price ||
              plurals(
                config.group_size || 0,
                window.i18next.t('ProductItem-string-2367-variant'),
                window.i18next.t('ProductItem-string--124-variants'),
              ),
            singleLine: true,
            fontSize: 12,
            fill: preview.price ? HEXColors.black : HEXColors.greyDark,
          }),
          {
            margin: { top: 0 },
          },
        ),
    );

    const linkBox = new MainLayout({
      width: pluginWidth - 34 - (stats ? STAT_VIEW_WIDTH : 0),
    });
    linkBox.addToLayout(link);
    this.addToLayout(linkBox);

    if (stats) {
      this.addToLayout(
        new ItemStatView({
          stats,
          showPercentValue: true,
        }),
        {
          margin: {
            top: 4.5,
            left: 0,
          },
        },
      );
    }
  }
}
