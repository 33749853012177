import { Node } from '../../Node';
import { Card } from '../../types';
import { MainLayout, ViewProp } from '../../components/Elements/Layouts';
import { TextureShape } from '../../components/Elements/Shapes';
import { TextEditView } from '../text_edit_view';
import { createLineMenu } from '../Menu/createLineMenu';
import { buttonHeight, buttonWidth, mainStrokeColor } from '../plugin_consts';
import {
  ButtonLineProps,
  LineStartView,
} from '../components/Line/LineStartView';
import { buttonBlockId } from '../button_block_id';
import { HEXColors } from '@ui/_common/colors';

export interface ButtonConfig {
  title: string;
  block_ids?: string[] | null;
}

interface CardButtonProps {
  card: Card;
  node: Node;
  icon?: ViewProp<TextureShape>;
  text: ViewProp<TextEditView>;
  arrowProps: ButtonLineProps;
  buttonConfig: ButtonConfig;
}

export class CardButton extends MainLayout {
  _card: Card;
  _node: Node;
  _lineStartView: LineStartView;
  _config: ButtonConfig;
  public textEditView: TextEditView;

  constructor({
    buttonConfig,
    card,
    node,
    icon,
    text,
    arrowProps,
  }: CardButtonProps) {
    super({
      height: buttonHeight,
      width: buttonWidth,
      background: {
        cornerRadius: 5,
        fill: HEXColors.white,
        stroke: mainStrokeColor,
        strokeWidth: 0,
      },
    });
    this._card = card;
    this._node = node;
    this._config = buttonConfig;
    this.textEditView = text.view;

    if (icon) {
      this.addToLayout(icon.view, icon.props, icon.index);
    }
    this.addToLayout(text.view, text.props, text.index);

    this._lineStartView = new LineStartView(
      {
        from: this,
        node: this._node,
        offset: 40,
        mandatory: arrowProps.mandatory,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: arrowProps.onConnected,
        onRemoved: arrowProps.onRemoved,
      },
      card.id,
    );

    this.addToLayout(this._lineStartView, {
      margin: { left: buttonWidth - 20 },
      verticalAlign: 'center',
      gone: arrowProps.gone,
    });
  }

  onBeforeRender() {
    this._node.addOutLink(
      buttonBlockId(this._config),
      this._lineStartView._lineView,
    );
    this._lineStartView?.renderLine();
  }
}
