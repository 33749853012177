import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { VariablesType } from '@globals';

export const facebookAdsPredefinedAttributes: Record<
  string,
  AttributesQuery_bot_variableSuggest[]
> = {
  default: [],
  [PluginType.text]: [
    {
      __typename: 'BotUserVariable',
      id: 'first name_system',
      name: 'first name',
      type: VariablesType.system,
      count: null,
    },
    {
      __typename: 'BotUserVariable',
      id: 'last name_system',
      name: 'last name',
      type: VariablesType.system,
      count: null,
    },
  ],
};
