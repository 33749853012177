import { lensPath, compose, Lens, lensIndex, lensProp } from 'ramda';
import { QuickReplyType } from '@globals';

export const buttonsLens = lensProp('buttons');
export const blockIdsLens = lensProp('block_ids');
export const firstBlockIdLens = compose(blockIdsLens, lensIndex(0)) as Lens;
export const propertyLens = lensProp('property');
export const typeLens = lensProp('type');
export const titleLens = lensProp('title');
export const emailLens = lensProp('email');
export const emailTitleLens = compose(emailLens, titleLens) as Lens;

export const phoneLens = lensProp('phone');
export const phoneTitleLens = compose(phoneLens, titleLens) as Lens;

export const dateButtonLens = lensProp('date_button');
export const dateButtonBlockIdLens = compose(
  dateButtonLens,
  blockIdsLens,
) as Lens;
export const dateButtonTitleLens = compose(dateButtonLens, titleLens) as Lens;

export const skipButtonLens = lensProp('skip_button');
export const skipButtonBlockIdLens = compose(
  skipButtonLens,
  blockIdsLens,
) as Lens;
export const skipButtonTitleLens = compose(skipButtonLens, titleLens) as Lens;

export const getButtonsLens = (id: number) => lensPath(['buttons', id]);
export const emailFacebookSuggestLens = lensPath(['email', 'facebook_suggest']);
export const phoneFacebookSuggestLens = lensPath(['phone', 'facebook_suggest']);
export const allowSkipLens = lensProp('allow_skip');

export const getErrorMessageLens = (type: QuickReplyType) =>
  lensPath(['error_messages', type]);
