import { ButtonUnstyled } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import React, { useEffect, useMemo, useState } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { useQuery } from '@apollo/react-hooks';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { ScrollBoxWithShadow } from '@ui/ScrollBox/ScrollBoxWithShadow';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginType } from '../../../../../Plugins/common/PluginTypes';
import { createZapierTemplatesFilter } from '../utils/createZapierTemplatesFilter';
import { getI18ZapierKey } from '../utils/getI18ZapierKey';
import {
  ZapierTemplatesQuery,
  ZapierTemplatesQueryVariables,
} from './@types/ZapierTemplatesQuery';
import { ZAPIER_TEMPLATES_QUERY } from './query';
import * as css from './ZapierZapsDialog.css';
import { ZapierTemplateItem } from './components/ZapierTemplateItem';
import { ZapierTemplatesLoader } from './components/ZapierTemplatesLoader';
import { ZapierTemplateSetType, ZapierTemplateStatus } from '@globals';
import { ZapEditor } from './components/ZapEditor/ZapEditor';
import { ReactComponent as BlankTemplateSvg } from './images/blank_template.svg';

export interface ZapierTemplatesDialogProps {
  header: string;
  onDismiss?: () => void;
  onZapStateChange?: () => void;
  initialUrl?: string;
  cardId?: string;
  pluginId?: string;
  blockId?: string;
}

const BLANK_TEMPLATE_URL = 'https://zapier.com/app/editor/';

const ZAPIER_TEMPLATE_SET_TYPE: Record<string, ZapierTemplateSetType> = {
  [PluginType.external_integration_entry_point]:
    ZapierTemplateSetType.entry_point,
  [PluginType.event_trigger]: ZapierTemplateSetType.send_data,
  [PluginType.integration_zapier_plugin]: ZapierTemplateSetType.import_content,
};

export const ZapierZapsDialog: React.FC<ZapierTemplatesDialogProps> = ({
  header,
  onDismiss,
  onZapStateChange,
  initialUrl,
  cardId,
  pluginId,
  blockId,
}) => {
  const { t } = useSafeTranslation();

  const { data, loading, error } = useQuery<
    ZapierTemplatesQuery,
    ZapierTemplatesQueryVariables
  >(ZAPIER_TEMPLATES_QUERY, {
    variables: {
      type: ZAPIER_TEMPLATE_SET_TYPE[pluginId || ''],
    },
  });

  const [searchString, setSearchString] = useState<string>('');
  const [selectedZapUrl, setSelectedZapUrl] = useState<string | undefined>(
    initialUrl,
  );
  const [selectedZapTitle, setSelectedZapTitle] = useState<
    string | undefined
  >();

  const templates = data?.zapierTemplates || [];

  useEffect(() => {
    if (!loading && templates?.length === 0 && !selectedZapUrl) {
      setSelectedZapUrl(BLANK_TEMPLATE_URL);
    }
  }, [templates, loading, selectedZapUrl]);

  const filteredTemplates = useMemo(
    () =>
      templates
        .filter(createZapierTemplatesFilter(pluginId || ''))
        .filter(
          ({ title, status }) =>
            title.toLowerCase().includes(searchString.trim().toLowerCase()) &&
            status === ZapierTemplateStatus.published,
        ),
    [templates, searchString, pluginId],
  );

  return (
    <DefaultDialog
      header={header}
      onDismiss={() => {
        onDismiss?.();
      }}
      dialogStyle={{ width: 900, height: 700 }}
    >
      {selectedZapUrl && (
        <ZapEditor
          zapUrl={selectedZapUrl}
          zapTitle={selectedZapTitle}
          cardId={cardId}
          pluginId={pluginId}
          blockId={blockId}
          onZapStateChange={() => {
            onZapStateChange?.();
          }}
        />
      )}
      {!selectedZapUrl && error && (
        <Flex justifyContent="center" alignItems="center" className={css.oops}>
          <Type size="18px" weight="semibold">
            {t(getI18ZapierKey('oops'))}
          </Type>
        </Flex>
      )}
      {!selectedZapUrl && !error && (
        <>
          <Type as="div" size="15px_DEPRECATED">
            {t(getI18ZapierKey('youCanAddANewTemplate'))}
          </Type>
          <Spacer factor={6} />
          <Input
            placeholder={t(getI18ZapierKey('searchByText'))}
            renderIcon={() => <Icon icon="search" color="grey" />}
            onChange={({ currentTarget: { value } }) => {
              setSearchString(value);
            }}
            value={searchString}
          />
          <Spacer factor={6} />
          <Type as="div" size="18px" weight="medium">
            {t(getI18ZapierKey('makeANewChatfuelZap'))}
          </Type>
          <Spacer factor={2} />
          {loading && <ZapierTemplatesLoader />}
          {!loading && !!filteredTemplates?.length && (
            <>
              <div className={css.itemsListBox}>
                <ScrollBoxWithShadow className={css.itemsList} fullHeight>
                  <ButtonUnstyled
                    className={css.blankTemplate}
                    onClick={() => {
                      setSelectedZapUrl(BLANK_TEMPLATE_URL);
                      setSelectedZapTitle(
                        window.i18next.t(
                          'ZapierZapsDialog-string-3274-blank-template',
                        ),
                      );
                      sendEvent({
                        category: 'zapier integration dialog',
                        action: 'zap template click',
                        label: pluginId,
                        propertyBag: {
                          cardId,
                          blockId,
                          templateId: 'blank',
                          templateName: 'blank',
                        },
                      });
                    }}
                  >
                    <Flex alignItems="center" style={{ height: 32 }}>
                      <BlankTemplateSvg />
                      <Spacer factor={1} horizontalFactor={2} />
                      <div>
                        <Type size="15px_DEPRECATED">
                          {t(getI18ZapierKey('createZapFromABlankTemplate'))}
                        </Type>
                      </div>
                    </Flex>
                  </ButtonUnstyled>
                  {filteredTemplates.map((template) => (
                    <ZapierTemplateItem
                      key={template.id}
                      template={template}
                      onTemplateClick={({ create_url, title }) => {
                        setSelectedZapUrl(create_url);
                        setSelectedZapTitle(title);
                        sendEvent({
                          category: 'zapier integration dialog',
                          action: 'zap template click',
                          label: pluginId,
                          propertyBag: {
                            cardId,
                            blockId,
                            templateId: template.id,
                            templateName: template.title,
                          },
                        });
                      }}
                    />
                  ))}
                </ScrollBoxWithShadow>
              </div>
            </>
          )}
          {!loading && !filteredTemplates?.length && (
            <div className={css.noResultsFound}>
              <Spacer factor={26} />
              <Type size="18px" weight="medium" as="div">
                {t(getI18ZapierKey('noResultsFound'))}
              </Type>
              <Spacer factor={2} />
              <Type as="div" size="15px_DEPRECATED">
                {t(getI18ZapierKey('tryBroadeningYourSearch'))}
              </Type>
            </div>
          )}
        </>
      )}
    </DefaultDialog>
  );
};
