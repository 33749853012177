import i18next from 'i18next';
import { logFlowPluginEvent } from '../../../utils/Analytics';
import { HLayout, VLayout } from '../../../components/Elements/Layouts';
import { SetAttributeView } from '../../components/SetAttributeView';
import { PluginTitleLayout } from '../../components/PluginTitleLayout';
import { Node } from '../../../Node';
import { pluginCollectNumbersSvgTexture } from '../../../assets/textures';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { StatefulPlugin } from '../../../StatefulPlugin';
import { getFlowControllerStrict } from '../../../PixiFieldRepository';
import { getFlowPlatform } from '../../../utils/getFlowPlatform';
import { Platform } from '@globals';
import { refetchAttributesQueryObservables } from '@utils/AttributesUtils/AttributesUtils';
import { HEXColors } from '@ui/_common/colors';
import { OpenAiPluginConfig } from './types';
import { AlertView } from '@components/FlowBuilder/views/entry-points/common/utils/validationProps';
import { tooltipScaled } from '@components/FlowBuilder/views/helpers/TooltipHelpers';

export class OpenAiSaveAttributeCardView extends VLayout {
  TEST_NAME = 'OpenAiSaveAttributeCardView';
  public readonly state: StatefulPlugin<OpenAiPluginConfig>;
  alertView: AlertView;
  _node: Node;
  private attributeView: SetAttributeView;
  constructor(state: StatefulPlugin<OpenAiPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
        stroke: HEXColors.red,
        strokeWidth: 2,
        strokeOpacity: () => (this.hasError() ? 1 : 0),
      },
    });
    this._node = node;
    this.state = state;

    const titleLayout = new PluginTitleLayout(
      false,
      window.i18next.t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.saveTo',
      ),
      pluginCollectNumbersSvgTexture,
      this.state.data,
    );
    this.addToLayout(titleLayout, { margin: { top: 15, left: 10 } });

    this.alertView = new AlertView({
      position: { x: pluginWidth - 41, y: 19 },
    });
    tooltipScaled({
      view: this.alertView,
      text: i18next.t(
        'modernComponents.FlowBuilder.views.components.OpenAiPlugin.selectAttributeTooltip',
      ),
      position: 'right',
    });
    this.addChild(this.alertView);

    const attrWidth = pluginWidth - 30;
    this.attributeView = new SetAttributeView(
      this.state.data,
      {
        width: attrWidth,
        fill: HEXColors.blue,
        caption: 'attribute',
        attributeOnly: true,
        customAttributesOnly: true,
        isEditing: () => this.state.isEditing,
      },
      undefined,
      () => {
        const saveToVariable = this.attributeView.text().trim();
        const { id: cardId, plugin_id, config: oldConfig } = this.state.data;
        if (saveToVariable !== oldConfig.saveToVariable) {
          logFlowPluginEvent(plugin_id, 'update saveToVariable', {
            cardId,
            blockId: node.id,
            saveToVariable,
          });
          this.state.set({
            config: {
              ...oldConfig,
              saveToVariable,
            },
          });
          this.state.save();
          refetchAttributesQueryObservables(
            getFlowControllerStrict().flow.botId,
            getFlowPlatform() || Platform.facebook,
          );
        }
      },
    );

    const hLayout = new HLayout().layout(this.attributeView, {
      margin: { right: 10 },
    });
    this.layout(hLayout, { margin: { left: 15, bottom: 15 } });
  }

  hasError(): boolean {
    return !this.state.data.config.saveToVariable;
  }

  onBeforeRender() {
    super.onBeforeRender();
    this.attributeView.text(this.state.data.config.saveToVariable ?? '');
    this.alertView.visible = this.hasError();
    this.alertView.renderElement();
  }
}
