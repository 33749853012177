import { ValidationErrors } from '@components/Plugins/common';
import { log } from 'cf-common/src/logger';
import { always, cond, startsWith, equals, compose, head, sort } from 'ramda';
import { ValidationErrorTypes } from '../types';

/**
 * Календли отдает ошибку прям строкой без статус кодов поэтому нет другого выбора
 * кроме как проверять строки. На данный момент возвращается ошибка вида
 * "Please upgrade your Calendly account to Professional", константа ниже не хранит
 * слово "Professional" тк Календли уже переименовывали Premium в Professional
 * поэтому проверять стоит по вхождению строки
 */
const CALENDLY_PRO_ACCOUNT_ERROR = 'Please upgrade your Calendly account to';

export const mapErrorToType = cond<ValidationErrors, ValidationErrorTypes>([
  [
    ({ field }) => startsWith('event_type_uri', field),
    always(ValidationErrorTypes.emptyEvent),
  ],
  [
    ({ field }) => startsWith('reminders', field),
    always(ValidationErrorTypes.linelessReminder),
  ],
  [
    ({ field, error }) =>
      startsWith('account_id', field) &&
      error.includes(CALENDLY_PRO_ACCOUNT_ERROR),
    always(ValidationErrorTypes.proAccount),
  ],
  [
    ({ field, error }) =>
      startsWith('account_id', field) && error.includes('null'),
    always(ValidationErrorTypes.notConnectedAccount),
  ],
  [
    ({ field }) => startsWith('account_id', field),
    ({ error }) => {
      log.error({ msg: 'Unhandled Calendly account_id error', error });
      return ValidationErrorTypes.notConnectedAccount;
    },
  ],
]);

const mapTypeToMessage = cond([
  [
    equals(ValidationErrorTypes.emptyEvent),
    always(
      'modernComponents.FlowBuilder.views.components.Calendly.validation.emptyEvent',
    ),
  ],
  [
    equals(ValidationErrorTypes.linelessReminder),
    always(
      'modernComponents.FlowBuilder.views.components.Calendly.validation.linelessReminderError',
    ),
  ],
  [
    equals(ValidationErrorTypes.proAccount),
    always(
      'modernComponents.FlowBuilder.views.components.Calendly.validation.proAccountErrorTooltip',
    ),
  ],
  [
    equals(ValidationErrorTypes.notConnectedAccount),
    always(
      'modernComponents.FlowBuilder.views.components.Calendly.validation.notConnectedAccount',
    ),
  ],
]);

type GetErrorMessageType = (error: { field: string }) => string | undefined;
export const getErrorMessage: GetErrorMessageType = compose(
  mapTypeToMessage,
  // @ts-expect-error
  mapErrorToType,
  head,
  sort<ValidationErrors>((a, b) => mapErrorToType(a) - mapErrorToType(b)),
);

export const hasError = (
  error: ValidationErrorTypes,
  fields: ValidationErrors[],
) => fields.some(compose(equals(error), mapErrorToType));
