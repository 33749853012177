import { HEXColors } from '@ui/_common/colors';
import { stripTags } from '@utils/stripTags';
import { zapierImportContentPluginFragment_config_preview_gallery_cards } from '../../../../../../../Plugins/ZapierImportContentPlugin/@types/zapierImportContentPluginFragment';
import {
  MainLayout,
  VLayout,
} from '../../../../../../components/Elements/Layouts';
import { HTMLText, Image } from '../../../../../../components/Elements/Shapes';
import { ButtonEditView } from '../../../../../button_edit_view';

export const CARD_WIDTH = 300;

export class ZapierImportContentPreviewCardView extends VLayout {
  constructor({
    title,
    image_url,
    subtitle,
    item_url: itemUrl,
    buttons,
  }: zapierImportContentPluginFragment_config_preview_gallery_cards) {
    super({
      width: CARD_WIDTH,
      background: {
        fill: HEXColors.greyLight20,
        stroke: HEXColors.grey,
        strokeWidth: 1,
        cornerRadius: 12,
      },
      cursor: { in: 'default' },
    });

    if (image_url) {
      const imageBox = new MainLayout({});
      imageBox.addToLayout(
        new Image({
          url: image_url || undefined,
          width: CARD_WIDTH,
          height: 200,
          corners: {
            topLeft: 12,
            topRight: 12,
          },
          background: {
            fill: HEXColors.grey,
          },
        }),
      );
      this.addToLayout(imageBox, {
        margin: { bottom: 12 },
      });
    }

    this.addToLayout(
      new HTMLText({
        text: stripTags(title || ''),
        fontSize: 15,
        fontStyle: 'semibold',
        width: CARD_WIDTH - 48,
      }),
      {
        margin: () => ({
          top: image_url ? 0 : 16,
          left: 24,
          bottom: 12,
        }),
      },
    );

    if (subtitle) {
      this.addToLayout(
        new HTMLText({
          text: stripTags(subtitle || ''),
          fontSize: 15,
          fontStyle: 'normal',
          width: CARD_WIDTH - 48,
        }),
        {
          margin: {
            left: 24,
            bottom: 12,
          },
        },
      );
    }

    if (itemUrl) {
      const urlView = new HTMLText({
        text: `<u>${stripTags(itemUrl || '')}</u>`,
        fontSize: 15,
        fontStyle: 'normal',
        width: CARD_WIDTH - 48,
        singleLine: true,
      });

      urlView.on('click', (event) => {
        event.stopPropagation();
        window.open(itemUrl, '_blank', 'noopener noreferrer');
      });

      this.addToLayout(
        new MainLayout({
          cursor: { in: 'pointer' },
        }).addToLayout(urlView),
        {
          margin: {
            left: 24,
            bottom: 12,
          },
        },
      );
    }

    if (buttons?.length) {
      const buttonBox = new VLayout({});
      buttons.forEach((config) => {
        buttonBox.addToLayout(
          new MainLayout({
            width: CARD_WIDTH - 32,
            height: 40,
            background: {
              fill: HEXColors.white,
              cornerRadius: 6,
            },
          }).addToLayout(
            new ButtonEditView({
              config,
              editable: () => false,
              caption: '',
              maxLength: 20,
              blockId: '',
              width: CARD_WIDTH - 32,
              height: 40,
            }),
          ),
          {
            margin: {
              bottom: 8,
              left: 16,
            },
          },
        );
      });

      this.addToLayout(buttonBox, {
        margin: {
          top: 8,
          bottom: 4,
        },
      });
    }

    this.addToLayout(
      new MainLayout({
        height: 4,
      }),
    );
  }
}
