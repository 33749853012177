import {
  ConnectInstagramQueryField,
  ConnectInstagramRestorationQueryField,
  ConnectInstagramStep,
  ConnectInstagramVariant,
  showConnectInstagramDialog,
} from '@components/dialogs/ConnectInstagramDialog';
import { cleanupQueryParams, setQueryParams } from '@utils/queryParams';

const cleanupInstagramRestorationParams = () => {
  cleanupQueryParams([
    ConnectInstagramQueryField.step,
    ConnectInstagramRestorationQueryField.name,
    ConnectInstagramRestorationQueryField.blockId,
  ]);
};

export const connectInstagram = (
  connectVariant: ConnectInstagramVariant,
  blockId: string,
  cb?: () => void,
) => {
  const igRestorationParams = {
    [ConnectInstagramRestorationQueryField.name]: 'flowBuilder',
    [ConnectInstagramRestorationQueryField.blockId]: blockId,
  };
  setQueryParams(igRestorationParams);
  const url = new URL(window.location.href);
  const step = url.searchParams.get(ConnectInstagramQueryField.step);
  showConnectInstagramDialog(
    connectVariant,
    step as ConnectInstagramStep,
    cleanupInstagramRestorationParams,
    cb,
  );
};
