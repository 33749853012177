import { PluginType } from '@components/Plugins/common/PluginTypes';
import i18next from 'i18next';
import { VLayout } from '../../../components/Elements/Layouts';
import { HTMLText, Image } from '../../../components/Elements/Shapes';
import {
  getFlowControllerStrict,
  getPixiFieldStrict,
} from '../../../PixiFieldRepository';
import { Node } from '../../../Node';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { whatsappTemplatePluginFragment_config as WhatsappTemplatePluginConfig } from '../../../../Plugins/WhatsappTemplatePlugin/@types/whatsappTemplatePluginFragment';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { getPanelWidth } from '../../../EditorPanel/utils/panelDimensions';
import { editPluginInEditorPanel } from '../../../EditorPanel/events';
import {
  OnboardingTourEventType,
  OnboardingTourShape,
  WhatsappTemplateButtonType,
  WhatsappTemplateComponentFormat,
  WhatsappTemplateComponentType,
  WhatsappTemplateParameterType,
} from '@globals';
import { HEXColors } from '@ui/_common/colors';
import { WhatsappTemplateButtonView } from './WhatsappTemplateButtonView';
import { MediaHeaderStub } from './MediaHeaderStub';
import {
  hasUnmappedBodyParameters,
  hasUnmappedHeaderParameter,
  hasUnmappedUrlButtonParameter,
  updateBlockIdByIndex,
} from './utils';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { WhatsappTemplatePluginDisconnectedView } from './WhatsappTemplatePluginDisconnectedView';
import { WhatsappTemplatePluginNoTemplateView } from './WhatsappTemplatePluginNoTemplateView';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { goToHomeTabAndConnectWhatsapp } from '../../utils/goToHomeTabAndConnectWhatsapp';
import { showChooseTemplateDialog } from '@components/ChooseWhatsappTempalteDialog';
import { getWhatsappSettingsObservable } from '@utils/Data/Whatsapp/getWhatsappSettingsObservable';
import { WhatsappSettingsQuery_whatsappMessagingSettings } from '@utils/Data/Whatsapp/@types/WhatsappSettingsQuery';
import { Subscription } from 'rxjs';
import {
  WhatsappTemplateStatus,
  WRAPPER_MARGIN,
} from './WhatsappTemplateStatus';
import { updateCardTemplate } from './helpers/updateCardTemplate';
import { Card } from '@components/FlowBuilder/types';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { ConnectWhatsappPageError } from '../../entry-points/common/errors';
import { DividerView } from '../WhatsappListPlugin/DividerView';
import { ButtonView } from '../../button_view';
import { Margin } from '../../utility_classes';
import { WhatsappTemplateName } from './WhatsappTemplateName';
import { showChangeTemplateWarningModal } from './WhatsappTemplateChangeDialog';
import { BLOCK_SUBTYPES } from '@components/FlowBuilder/consts';
import { getStatusErrorMessage } from '@utils/Whatsapp/Templates/utils';
import { isCreatedTemplateInChatfuelEnabled } from '@utils/Whatsapp/isCreatedTemplateInChatfuelEnabled';
import { previewFormat } from '@pages/LiveChat/components/Messages/Message/WhatsappTemplate/helpers/previewFormat';

enum PluginViewVariant {
  disconnectedView,
  noTemplateView,
  templateView,
}

const getPluginViewVariant = (
  isWhatsappConnected: boolean,
  template: boolean,
) => {
  if (!isWhatsappConnected && !template) {
    return PluginViewVariant.disconnectedView;
  }

  if (!isWhatsappConnected && template) {
    return PluginViewVariant.templateView;
  }

  if (isWhatsappConnected && template) {
    return PluginViewVariant.templateView;
  }

  if (!template) {
    return PluginViewVariant.noTemplateView;
  }

  return PluginViewVariant.templateView;
};

type HasFilenameTypes =
  | WhatsappTemplateParameterType.document
  | WhatsappTemplateParameterType.video
  | WhatsappTemplateParameterType.image;

export class WhatsappTemplatePluginView
  extends VLayout
  implements StatefulPluginDelegate<WhatsappTemplatePluginConfig>
{
  public readonly TEST_NAME = 'WhatsappTemplatePluginView';
  private readonly _node: Node;
  public readonly logger: PluginLogger;
  public readonly state: StatefulPlugin<WhatsappTemplatePluginConfig>;
  private viewVariant: PluginViewVariant | undefined;
  private isWhatsappConnected: boolean | undefined;
  private whatsappSettings:
    | WhatsappSettingsQuery_whatsappMessagingSettings
    | undefined;
  private whatsappSettingsSubscription: Subscription | undefined;
  private isManagedTemplate: boolean = false;

  constructor(state: StatefulPlugin<WhatsappTemplatePluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        fill: textCardBackgroundColor,
        cornerRadius: 10,
      },
      name: OnboardingTourShape.WhatsappTemplatePlugin,
    });

    this._node = node;

    this.state = state;
    this.state.setDelegate(this);
    this.logger = new PluginLogger(
      this.state.data.plugin_id,
      this._node.id,
      this.state.data.id,
    );
    this.isManagedTemplate = Boolean(state.data.config.managedTemplateId);

    const wrapper = new VLayout({});

    if (!getPixiFieldStrict().isViewOnly()) {
      this.whatsappSettingsSubscription = getWhatsappSettingsObservable(
        getFlowControllerStrict().flow.botId,
      ).subscribe(({ isWhatsappConnected, whatsappSettings }) => {
        this.whatsappSettings = whatsappSettings;
        this.isWhatsappConnected = isWhatsappConnected;
        this.viewVariant = getPluginViewVariant(
          this.isWhatsappConnected,
          Boolean(this.state.data.config.template),
        );
        this.renderNode();
      });
    } else {
      this.isWhatsappConnected = true;
      this.viewVariant = this.state.data.config.template
        ? PluginViewVariant.templateView
        : PluginViewVariant.noTemplateView;
    }

    const disconnectedView = new WhatsappTemplatePluginDisconnectedView({
      onButtonClick: this.onConnectClick,
    });
    wrapper.addToLayout(disconnectedView, {
      gone: () => !(this.viewVariant === PluginViewVariant.disconnectedView),
      margin: WRAPPER_MARGIN,
    });

    const noTemplateView = new WhatsappTemplatePluginNoTemplateView({
      onButtonClick: () => {
        this.logger.log('connect template click');

        this.showChooseTemplateDialog();
      },
    });
    wrapper.addToLayout(noTemplateView, {
      gone: () => !(this.viewVariant === PluginViewVariant.noTemplateView),
      margin: WRAPPER_MARGIN,
    });

    wrapper.addToLayout(this.drawPlugin(), {
      gone: () => !(this.viewVariant === PluginViewVariant.templateView),
    });

    this.addToLayout(wrapper);
  }

  private drawPlugin = () => {
    const wrapper = new VLayout();

    wrapper
      .addToLayout(this.drawHeader(), {
        margin: new Margin({ x: 2, y: 1.5 }),
      })
      .addToLayout(this.drawTemplateHeader())
      .addToLayout(this.drawTemplateBody())
      .addToLayout(this.drawTemplateFooter())
      .addToLayout(
        this.drawFixedButtons([
          WhatsappTemplateButtonType.URL,
          WhatsappTemplateButtonType.PHONE_NUMBER,
        ]),
      )
      .addToLayout(this.drawReplyButtons())
      .addToLayout(
        this.drawFixedButtons([WhatsappTemplateButtonType.MARKETING_OPT_OUT]),
      )
      .addToLayout(this.drawChangeTemplateButton(), {
        margin: new Margin({ x: 12, bottom: 12 }),
        gone: () => getPixiFieldStrict().isViewOnly(),
      });

    /**
     * Почему то если эвент висит на wrapper, то клики срабатывают
     * только по отрисованным во wrapper вьюхах, и появляются места
     * по которым клики не проходят
     */
    this.on('click', () => {
      if (
        this.isManagedTemplate ||
        this.viewVariant !== PluginViewVariant.templateView
      ) {
        return;
      }

      OnboardingEmitter.emit(OnboardingTourEventType.click, {
        element: OnboardingTourShape.WhatsappTemplatePlugin,
        value: null,
      });
      this.openEditorPanel();
    });

    return wrapper;
  };

  private drawHeader() {
    const wrapper = new VLayout({
      width: pluginWidth - 2.5,
      background: {
        fill: HEXColors.greyLight10,
        corners: () => ({
          topLeft: this._layoutProps.background?.cornerRadius,
          topRight: this._layoutProps.background?.cornerRadius,
          bottomLeft: 0,
          bottomRight: 0,
        }),
      },
    });

    const box = new VLayout({});

    box
      .addToLayout(
        new WhatsappTemplateName({
          template: this.state.data.config.template,
        }),
      )
      .addToLayout(
        new WhatsappTemplateStatus({
          template: this.state.data.config.template,
        }),
      );

    wrapper.addToLayout(box, { margin: WRAPPER_MARGIN });

    return wrapper;
  }

  private showChangeTemplateWarningModal = () => {
    const { bodyParameters, headerParameter, urlButtonParameter } =
      this.state.data.config;
    const parameters = ((bodyParameters as Array<unknown>) || [])
      ?.concat(headerParameter, urlButtonParameter)
      .filter(Boolean);

    if (parameters && parameters?.length > 0) {
      return showChangeTemplateWarningModal();
    }

    return Promise.resolve();
  };

  private showChooseTemplateDialog = () => {
    showChooseTemplateDialog({
      onSubmit: (templateId, managedTemplateId) => {
        if (!templateId && !managedTemplateId) {
          return;
        }
        this.logger.log('choose template', { templateId, managedTemplateId });
        updateCardTemplate({
          blockId: this._node.blockView.id(),
          cardId: this.state.data.id,
          templateId,
          managedTemplateId,
        })
          .then((card) => {
            if (!card) {
              throw new Error('No card received');
            }

            this.state.set(() => card as Card);
          })
          .then(() => {
            this.viewVariant = getPluginViewVariant(
              Boolean(this.isWhatsappConnected),
              Boolean(this.state.data.config.template),
            );
            this._node.blockView.rerenderCards();
          })
          .catch((error) => {
            showSomethingWentWrongToaster();
            log.warn({
              error,
              msg: 'Flow Builder: replace whatsapp template error',
            });
          });
      },
    });
  };

  private onConnectClick = () => {
    this.logger.log('on connect whatsapp click');
    goToHomeTabAndConnectWhatsapp();
  };

  pluginDidSet() {
    this.renderNode();
  }

  hasSignificantChangesInConfig() {
    return Boolean(this.state.data.config.template);
  }

  validationError() {
    if (this.viewVariant !== PluginViewVariant.templateView) {
      return undefined;
    }

    if (!this.isWhatsappConnected) {
      return new ConnectWhatsappPageError(goToHomeTabAndConnectWhatsapp, {
        type: i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.plugin',
        ),
      });
    }

    const currentId = this.whatsappSettings?.whatsappBusinessAccount?.id;
    const { config } = this.state.data;
    if (
      !config.managedTemplateId &&
      config.template &&
      currentId &&
      currentId !== config.whatsappBusinessAccountId
    ) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.wrongWaba',
        ),
      };
    }

    const headerParameterError =
      this.state.data.validation_details?.fields?.find(
        // @ts-expect-error
        ({ field }) => field === 'headerParameter',
      );

    const hasUnmappedParameters =
      headerParameterError ||
      hasUnmappedHeaderParameter(this.state.data.config.headerParameter) ||
      hasUnmappedBodyParameters(this.state.data.config.bodyParameters) ||
      hasUnmappedUrlButtonParameter(this.state.data.config.urlButtonParameter);

    if (hasUnmappedParameters) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.needToMapAttributes',
        ),
        onClick: () => this.openEditorPanel(),
      };
    }

    const { status } = this.state.data.config.template || {};
    const errorTooltip =
      status &&
      getStatusErrorMessage(
        status,
        getFlowControllerStrict().flow.botId,
        this.state.data.config.managedTemplateId,
      );
    if (errorTooltip) {
      return errorTooltip;
    }

    const wasTemplateChanged = this.state.data.validation_details?.fields?.find(
      // @ts-expect-error
      ({ field, error }) =>
        field === 'template' &&
        error.includes('The actual template has another number'),
    );
    if (wasTemplateChanged) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.errors.templateWasChanged',
        ),
      };
    }

    return this.state.data.config.replyButtons?.some(
      ({ button }) => !button.blockId,
    );
  }

  destroy() {
    this.state.destroy();
    this.whatsappSettingsSubscription?.unsubscribe();
    super.destroy();
  }

  private drawTemplateHeader() {
    const wrapper = new VLayout();
    const header = this.getComponent(WhatsappTemplateComponentType.HEADER);

    if (!header) {
      return wrapper;
    }

    const { format } = header;

    const imgLink = this.state.data.config.headerParameter?.image?.link;
    if (format === WhatsappTemplateComponentFormat.IMAGE && imgLink) {
      wrapper.addToLayout(
        new Image({
          url: imgLink,
          width: pluginWidth - 2,
          height: 198,
          onReady: () => {
            this.renderNode();
          },
        }),
        {
          margin: {
            left: 2,
          },
        },
      );
      return wrapper;
    }

    if (format && format !== WhatsappTemplateComponentFormat.TEXT) {
      const headerData =
        this.state.data.config.headerParameter?.[
          format.toLowerCase() as HasFilenameTypes
        ];

      wrapper.addToLayout(
        new MediaHeaderStub(
          format,
          headerData?.filename || undefined,
          headerData?.link || undefined,
        ),
        {
          margin: new Margin({ x: 12, y: 12 }),
        },
      );
      return wrapper;
    }

    const headerTextView = new HTMLText({
      width: pluginWidth - 64,
      fontSize: 15,
      fontStyle: 'bold',
      singleLine: true,
      trustedHtml: true,
      text: previewFormat(this.headerText),
    });

    wrapper.addToLayout(headerTextView, {
      margin: { left: 12, right: 52, top: 12 },
      gone: () => !this.headerText,
    });

    return wrapper;
  }

  private drawTemplateBody() {
    const wrapper = new VLayout();

    const bodyTextView = new HTMLText({
      width: pluginWidth - 64,
      fontSize: 15,
      singleLine: !isCreatedTemplateInChatfuelEnabled(),
      trustedHtml: true,
      text: previewFormat(this.bodyText),
    });

    wrapper.addToLayout(bodyTextView, {
      margin: { left: 12, right: 52, top: 12, bottom: 12 },
      gone: () => !this.state.data.config.template,
    });

    return wrapper;
  }

  private drawTemplateFooter() {
    const wrapper = new VLayout();

    const footerTextView = new HTMLText({
      width: pluginWidth - 64,
      fill: HEXColors.greyDark,
      fontSize: 12,
      singleLine: true,
      text: this.footerText,
    });

    wrapper.addToLayout(footerTextView, {
      margin: { left: 12, right: 52, bottom: 12 },
      gone: () => !this.footerText,
    });

    return wrapper;
  }

  private drawFixedButtons(types: WhatsappTemplateButtonType[]) {
    const buttonsLayout = new VLayout({});

    this.getComponent(WhatsappTemplateComponentType.BUTTONS)
      ?.buttons?.filter((button) => button.type && types.includes(button.type))
      .forEach((button) => {
        const buttonView = new WhatsappTemplateButtonView({
          node: this._node,
          title: button.text ?? '',
          type: button.type!,
        });

        buttonsLayout.addToLayout(buttonView, {
          margin: () => ({
            bottom: buttonsLayout.isLast(buttonView) ? 15 : 10,
          }),
        });
      });

    return buttonsLayout;
  }

  private drawChangeTemplateButton() {
    const wrapper = new VLayout();

    wrapper
      .addToLayout(
        new DividerView({
          width: pluginWidth - WRAPPER_MARGIN.x,
          fill: HEXColors.white,
        }),
        { margin: WRAPPER_MARGIN },
      )
      .addToLayout(
        new ButtonView({
          title: i18next.t(
            'modernComponents.FlowBuilder.plugins.WhatsappTemplatePlugin.changeTemplate',
          ),
          bgColor: textCardBackgroundColor,
          textColor: HEXColors.accent1Normal,
          onClick: (event) => {
            event.stopPropagation();
            this.logger.log('Change template click');

            this.showChangeTemplateWarningModal()?.then(() =>
              this.showChooseTemplateDialog(),
            );
          },
        }),
      );
    return wrapper;
  }

  private drawReplyButtons() {
    const buttonsLayout = new VLayout({});

    this.state.data.config.replyButtons?.forEach(({ index, button }) => {
      const buttonView = new WhatsappTemplateButtonView({
        node: this._node,
        title: button.title ?? '',
        blockId: button.blockId,
        hideStats:
          this._node.block.subtype === BLOCK_SUBTYPES.broadcast_flow_root,
        counterId: button.counterId,
        onConnected: (blockId: string) => {
          this.state.setAndSave(({ config }) => ({
            config: updateBlockIdByIndex(
              index,
              blockId,
              this.isManagedTemplate,
            )(config),
          }));
        },
        onDisconnected: () => {
          this.state.setAndSave(({ config }) => ({
            config: updateBlockIdByIndex(
              index,
              null,
              this.isManagedTemplate,
            )(config),
          }));
        },
      });

      buttonsLayout.addToLayout(buttonView, {
        margin: () => ({
          bottom: buttonsLayout.isLast(buttonView) ? 15 : 10,
        }),
      });
    });

    return buttonsLayout;
  }

  private get headerText() {
    return this.getComponent(WhatsappTemplateComponentType.HEADER)?.text ?? '';
  }

  private get bodyText() {
    return this.getComponent(WhatsappTemplateComponentType.BODY)?.text ?? '';
  }

  private get footerText() {
    return this.getComponent(WhatsappTemplateComponentType.FOOTER)?.text ?? '';
  }

  private getComponent(type: WhatsappTemplateComponentType) {
    return this.state.data.config.template?.components.find(
      (component) => type === component.type,
    );
  }

  private openEditorPanel() {
    const node = this._node;
    getPixiFieldStrict().fixBlockPosition(
      node.blockView,
      getPanelWidth(PluginType.whatsapp_template),
    );
    editPluginInEditorPanel(node.id, this.state.data, this.state);
  }
}
