import { HEXColors } from '@ui/_common/colors';
import { getBase64FromImageUrl, GRAY_ONE_PIXEL_PNG } from '@utils/Image';
import { SendToMessengerSize } from '@globals';
import { MainLayout } from '../../../../components/Elements/Layouts';
import { HTMLText } from '../../../../components/Elements/Shapes';
import { getFlowControllerStrict } from '../../../../PixiFieldRepository';
import { StatFieldsIds } from '../../../components/BlockStatsView';
import { createStatTooltip } from '../../../components/BlockStatsView/components/StatTooltip';
import { removeTooltip, tooltipScaled } from '../../../helpers/TooltipHelpers';
import { prepareButtonHtml } from './helpers';
import { SendToMessengerConfig } from './types';

const HOVER_BOX_TOP_MARGINS: Record<SendToMessengerSize, number> = {
  [SendToMessengerSize.standard]: -35,
  [SendToMessengerSize.large]: -45,
  [SendToMessengerSize.xlarge]: -58,
};

export class SendToMessengerView extends MainLayout {
  private readonly box: HTMLText;
  private userPicString: string = GRAY_ONE_PIXEL_PNG;
  private config: SendToMessengerConfig | undefined;
  private readonly statHoverBox: MainLayout;

  constructor() {
    super({});
    this.box = new HTMLText({
      fontSize: 15,
      fill: HEXColors._666666,
      trustedHtml: true,
    });
    this.addToLayout(this.box);
    this.loadUserPicString();

    this.statHoverBox = new MainLayout({
      width: 90,
      height: 30,
      background: {
        fill: HEXColors.red,
        opacity: 0,
      },
    });

    this.addToLayout(this.statHoverBox, {
      align: 'end',
      verticalAlign: 'bottom',
      margin: () => ({
        top: HOVER_BOX_TOP_MARGINS[
          this.config?.size ?? SendToMessengerSize.large
        ],
        left: -40,
      }),
    });
  }

  private async loadUserPicString() {
    const { user } = getFlowControllerStrict().flow;
    this.userPicString = user.picture
      ? await getBase64FromImageUrl(user.picture, 40, 40)
      : GRAY_ONE_PIXEL_PNG;
    if (this.config) {
      this.updateConfig(this.config);
      this.renderNode();
    }
  }

  public async updateConfig(config: SendToMessengerConfig) {
    this.config = config;
    const { user } = getFlowControllerStrict().flow;
    this.box.text(prepareButtonHtml(config, user, this.userPicString));
    removeTooltip(this.statHoverBox);
    if (config.stats) {
      tooltipScaled({
        view: this.statHoverBox,
        overTimeout: 500,
        children: createStatTooltip({
          fieldId: StatFieldsIds.clicked,
          notUniqValue: config.stats.counter.notUniqStats?.clicked || 0,
          uniqValue: config.stats.counter.uniqStats?.clicked || 0,
          blockId: config.stats.id,
        }),
      });
    }
  }

  destroy() {
    super.destroy();
    removeTooltip(this.statHoverBox);
  }
}
