import { HEXColors } from '@ui/_common/colors';
import { clone } from 'ramda';
import { abandonedCartPluginFragment_config_text_plugin as TextCardReminderConfig } from '../../../../../Plugins/AbandonedCartPlugin/@types/abandonedCartPluginFragment';
import { VLayout } from '../../../../components/Elements/Layouts';
import { PluginType, PropFunc } from '../../../../types';
import { logFlowPluginEvent } from '../../../../utils/Analytics';
import { FullCounterStats } from '../../../../utils/Statistics/types';
import { TextCardInputView } from '../../../kit/TextCard/TextCardInputView';
import { TextCardSimpleButtonWithStat } from '../../../kit/TextCard/TextCardSimpleButtonWithStat';
import { pluginWidth } from '../../../plugin_consts';

interface TextCardReminderViewProps {
  config: TextCardReminderConfig;
  onChange: (config: TextCardReminderConfig) => void;
  isEditing: PropFunc<boolean>;
  stats: FullCounterStats | undefined;
}

export class TextCardReminderView extends VLayout {
  config: TextCardReminderConfig;
  constructor({
    config,
    onChange,
    isEditing,
    stats,
  }: TextCardReminderViewProps) {
    super({
      width: pluginWidth,
      background: {
        fill: HEXColors.greyLight30,
        cornerRadius: 12,
      },
    });
    this.config = clone(config);

    const text = new TextCardInputView({
      initValue: config.text || '',
      onChange: (value) => {
        this.config.text = value;
        onChange(this.config);
        logFlowPluginEvent(PluginType.abandoned_cart, 'update text');
      },
      isEditing,
    });

    this.addToLayout(text, {
      margin: {
        top: 16,
        left: 16,
        bottom: 16,
      },
    });

    const button = new TextCardSimpleButtonWithStat({
      isEditing,
      initConfig: { title: config.button_title || '' },
      onChange: (config) => {
        this.config.button_title = config.title;
        onChange(this.config);
        logFlowPluginEvent(PluginType.abandoned_cart, 'update button text');
      },
      stats,
    });

    this.addToLayout(button, {
      margin: {
        left: 16,
        bottom: 16,
      },
    });

    this.on('click', () => {
      text.startEditing();
    });
  }

  validationError() {
    return !this.config.text?.trim() || !this.config.button_title?.trim();
  }
}
