import { HEXColors } from '@ui/_common/colors';
import { ControlVisibility } from '../../../FlowBuilderOverlay';
import { PropFunc } from '../../../types';
import {
  buttonHeight,
  buttonWidth,
  mainStrokeColor,
} from '../../plugin_consts';
import { TextEditView, TextEditViewProps } from '../../text_edit_view';
import { resByFunc } from '../../utils';

export interface TextCardSimpleButtonConfig {
  title: string;
}

export interface TextCardSimpleButtonProps
  extends Omit<TextEditViewProps, 'isEditing'> {
  initConfig: TextCardSimpleButtonConfig;
  isEditing: PropFunc<boolean>;
  onChange: (config: TextCardSimpleButtonConfig) => void;
}

export class TextCardSimpleButton extends TextEditView {
  private readonly config: TextCardSimpleButtonConfig;
  constructor({
    initConfig,
    isEditing,
    onChange,
    ...textEditViewProps
  }: TextCardSimpleButtonProps) {
    super(
      {
        text: initConfig.title || '',
        editable: () => resByFunc(isEditing),
        height: buttonHeight,
        width: buttonWidth,
        caption: window.i18next.t(
          'TextCardSimpleButton-string--106-add-button-name',
        ),
        captionFill: HEXColors.red,
        fill: HEXColors.black,
        maxLength: 20,
        align: 'center',
        verticalAlign: 'center',
        fontSize: 15,
        fontStyle: 'semibold',
        singleLine: true,
        background: {
          cornerRadius: 5,
          fill: HEXColors.white,
          stroke: mainStrokeColor,
          strokeWidth: 0,
        },
        ...textEditViewProps,
      },
      undefined,
      undefined,
      (value) => {
        this.config.title = value.trim();
        this.text(this.config.title);
        onChange(this.config);
        this.renderNode();
      },
      {
        emoji: ControlVisibility.show,
        attributes: ControlVisibility.show,
      },
    );
    this.config = initConfig;
  }
}
