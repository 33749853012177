import { MainLayout } from '../components/Elements/Layouts';
import { Layout } from '../components/Elements/Layouts/Layout';
import { BlocksSelectorView } from './blocks_selector_view';
import {
  inactiveStrokeColor,
  mainStrokeColor,
  pluginWidth,
} from './plugin_consts';
import { Node } from '../Node';
import { Block } from '../types';
import { HEXColors } from '@ui/_common/colors';

export class RedirectToBlock extends MainLayout {
  TEST_NAME = 'RedirectToBlock';

  private _node: Node;
  private _block: Block;
  private readonly _nextBlockField: BlocksSelectorView;

  constructor(node: Node) {
    super({
      width: pluginWidth,
    });
    this._node = node;
    this._block = node.block;
    this._nextBlockField = new BlocksSelectorView(
      {
        onBlockAdd: node.controller.addBlockTitle,
        blockIds: Array.isArray(this._block.next_block_ids)
          ? this._block.next_block_ids
          : [],
        width: pluginWidth,
        handleOutsideClick: false,
        background: {
          cornerRadius: 3,
          fill: () => HEXColors.white,
          stroke: (v: Layout | undefined) =>
            v?._textAreaShown ? mainStrokeColor : inactiveStrokeColor,
          strokeWidth: (v: Layout | undefined) =>
            node.isEditing ? (v?._textAreaShown ? 2 : 1) : 0,
        },
      },
      (blocksIds: string[]) => {
        this._block.next_block_ids = blocksIds;
        this._node.updateBlock();
      },
    );
    this.layout(this._nextBlockField);
  }
}
