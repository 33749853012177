import { HEXColors } from '@ui/_common/colors';
import {
  ItemStatView,
  STAT_VIEW_WIDTH,
} from '../../kit/ItemStatView/ItemStatView';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import { EntryPointFacebookPageCard, FacebookPageConfig } from './types';
import { ButtonView } from '../../button_view';
import { createLineMenu } from '../../Menu/createLineMenu';
import { HLayout } from '../../../components/Elements/Layouts';
import { Node } from '../../../Node';
import { BlockView } from '../../block_view';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { LineStartView } from '../../components/Line/LineStartView';

export class GetStartedButtonView extends HLayout {
  TEST_NAME = 'GetStartedButtonView';
  private _card: EntryPointFacebookPageCard;

  private _node: Node;

  private _config: FacebookPageConfig;

  private lineStartView: LineStartView;

  private getStartedButton: ButtonView;

  constructor(card: EntryPointFacebookPageCard, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });
    this._card = card;
    this._config = card.config;
    this._node = node;

    this.getStartedButton = new ButtonView({
      title: window.i18next.t('GetStartedButton-string--911-get-started'),
      width: pluginWidth - 32,
      bgColor: HEXColors.white,
      cornerRadius: 17,
    } as any);
    this.getStartedButton.on('pointerdown', (e: MouseEvent) => {
      e.stopPropagation();
    });
    this.addToLayout(this.getStartedButton, {
      margin: { left: 16, top: 15, right: 16, bottom: 15 },
    });

    const stats = getFullCounterStats(`${card.id}_button`, node.block.id);

    if (stats) {
      this.getStartedButton.addToLayout(
        new ItemStatView({
          stats,
          showPercentValue: true,
        }),
        {
          margin: {
            top: 9.5,
            left: pluginWidth - 40 - STAT_VIEW_WIDTH,
          },
        },
      );
    }

    this.lineStartView = new LineStartView(
      {
        node,
        from: this,
        offset: 40,
        mandatory: () => !this._card.isEditing,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        /* eslint-disable no-param-reassign */
        onConnected: (blockView: BlockView) => {
          card.config.block_id = blockView.id();
          node.updateCard(card);
          this.renderNode();
        },
        onRemoved: () => {
          card.config.block_id = undefined;
          node.updateCard(card);
          this.renderNode();
        },
        /* eslint-enable no-param-reassign */
      },
      card.id,
    );

    this.addToLayout(this.lineStartView, {
      margin: { left: -7 },
      align: 'center',
    });
  }

  onBeforeRender() {
    if (this._config.block_id)
      this._node.addOutLink(
        this._config.block_id,
        this.lineStartView._lineView,
      );
  }

  validationError() {
    if (this._card.isEditing) {
      return false;
    }
    return !this._card.config.block_id;
  }
}
