import { AggregatedFlowQuery_me } from '@utils/Data/Flow/Aggregated/@types/AggregatedFlowQuery';
import { GRAY_ONE_PIXEL_PNG } from '@utils/Image';
import { removeExtraSpaces } from '@utils/String/removeExraSpaces';
import { filterXSS } from 'xss';
// eslint-disable-next-line import/no-webpack-loader-syntax
import messengerIcon from '!!raw-loader!../../../../assets/entry_point/messenger.svg';
import { getCTATexts } from '../../../../EditorPanel/components/plugins/SendToMessengerEntryPoint/consts';
import { calcPercent } from '../../../kit/ItemStatView/helpers';
import { pluginWidth } from '../../../plugin_consts';
import { BUTTONS_COLOR, BUTTONS_SIZES } from './constants';
import { SendToMessengerConfig } from './types';

export const createFbPluginUserHtml = (
  user: AggregatedFlowQuery_me,
  picSize: number,
  fontSize: number,
  space: number,
  userPictureString = GRAY_ONE_PIXEL_PNG,
) => {
  const userPicStyle = `
    width:${picSize}px;
    height:${picSize}px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0px ${space}px 0 0;
    background: url('${userPictureString}') center center no-repeat;
    display: inline-block;
    background-size: 20px 20px;
    vertical-align:middle;
  `;

  const userNameStyle = `
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    vertical-align: middle;
  `;

  const linkStyle = `
    text-decoration: underline;
    color: rgb(153, 153, 153);
    margin-left: ${space * 2}px;
  `;

  return `
  <div style="font-size: ${fontSize}px;height:${picSize}px;line-height:${picSize}px;">
    <span style="${userPicStyle}"></span>
    <span style="${userNameStyle}">${filterXSS(user.name || '')}</span>
    <span style="${linkStyle}">Not you?</span>
  </div>
`;
};

export const prepareButtonHtml = (
  { size, color, cta_text: ctaText, stats }: SendToMessengerConfig,
  user: AggregatedFlowQuery_me,
  userPictureString: string,
) => {
  const { background, text } = BUTTONS_COLOR[color];
  const {
    padding,
    fontSize,
    lineHeight,
    iconSize,
    iconMargin,
    iconTopMargin,
    picSize,
    buttonSpace,
    borderRadius,
    subFontSize,
    totalHeight,
  } = BUTTONS_SIZES[size];

  const SCALE = 1.2;
  const buttonStyle = `max-width:180px;color:${text.fill};padding:${padding};font-size:${fontSize}px;line-height:${lineHeight};background-color: ${background.fill};border: 1px solid ${background.stroke};margin-bottom:${buttonSpace}px;border-radius: ${borderRadius}px;display:inline-flex;`;
  const messengerIconStyle = `display:inline-flex; width: ${iconSize}px; height: ${iconSize}px;margin-right: ${iconMargin}px;margin-top:${iconTopMargin}px`;

  const statValue =
    stats &&
    `${calcPercent(
      stats.uniqStats?.clicked,
      stats.counter.uniqStats?.clicked || 0,
    )}%`;

  return removeExtraSpaces(`
      <div style="display: flex; align-items: center; justify-content: center; padding: 16px; width: ${pluginWidth}px;box-sizing: border-box; font-family: Helvetica, Arial, sans-serif;">
        <div style="height: ${totalHeight * SCALE}px">
           <div style="transform: scale(${SCALE});transform-origin: top center;">
              <div style="${buttonStyle}">
                 <div style="${messengerIconStyle}">${messengerIcon}</div>
                 <div style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">
                 ${
                   (
                     getCTATexts().find(({ id }) => id === ctaText) ||
                     getCTATexts()[0]
                   ).title
                 }
                 </div>
                   ${
                     statValue
                       ? `<div style="padding-left:4px;font-size:12px;display:inline-flex;align-items:center;">${statValue}</div>`
                       : ''
                   }
                </div>
                  ${createFbPluginUserHtml(
                    user,
                    picSize,
                    subFontSize,
                    iconMargin,
                    userPictureString,
                  )}
            </div>
        </div>
      </div>
  `);
};
