import i18next from 'i18next';
import Maybe from 'graphql/tsutils/Maybe';
import copyToClipboard from 'clipboard-copy';
import { Subscription } from 'apollo-client/util/Observable';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { Messages, toaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { whatsappBotLinkEntryPointFragment_config as EntryPointWhatsappBotLinkConfig } from '@components/Plugins/WhatsappBotLinkEntryPoint/@types/whatsappBotLinkEntryPointFragment';
import {
  StatefulPlugin,
  StatefulPluginDelegate,
} from '../../../StatefulPlugin';
import { Node } from '../../../Node';
import { VLayout } from '../../../components/Elements/Layouts';
import { pluginWidth, textCardBackgroundColor } from '../../plugin_consts';
import { HEXColors } from '@ui/_common/colors';
import { ControlVisibility } from '@components/FlowBuilder/FlowBuilderOverlay';
import { SetAttributeView } from '../../components/SetAttributeView';
import { ButtonView } from '../../button_view';
import { Margin } from '../../utility_classes';
import { QRCodeTexture } from '@components/FlowBuilder/assets/textures';
import { download } from '@utils/download';
import { QRCodeGenerator } from '@classes/QRCodeGenerator';
import {
  QR_CODE_RENDERER_OPTIONS,
  QR_CODE_WHITE_LABEL_RENDERER_OPTIONS,
} from '@utils/QrCodes/consts';
import { PluginType } from '@components/FlowBuilder/types';
import { PluginLogger } from '@components/FlowBuilder/utils/Analytics';
import { BotLink } from '../common/BotLink';
import { getWhatsappSettingsObservable } from '@utils/Data/Whatsapp/getWhatsappSettingsObservable';
import { getFlowControllerStrict } from '@components/FlowBuilder/PixiFieldRepository';
import { WhatsappSettingsQuery_whatsappMessagingSettings } from '@utils/Data/Whatsapp/@types/WhatsappSettingsQuery';
import { InteractionEvent } from 'pixi.js-legacy';
import { WhatsappValidator } from '../common/WhatsappValidator';
import { handleConflictError } from '../common/handleConflictError';
import { ApolloError } from 'apollo-client';
import { validateBotLink } from '../common/utils/validateBotLink';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { removeNonDigits } from '@utils/String/removeNonDigits';

const FILE_NAME = 'WhatsApp bot QR-code';

class WhatsappBotLink extends BotLink {
  constructor() {
    super({ url: 'https://wa.me/', parameter: 'text' });
  }
}

export class EntryPointWhatsappBotLink
  extends VLayout
  implements StatefulPluginDelegate<EntryPointWhatsappBotLinkConfig>
{
  TEST_NAME = 'EntryPointWhatsappBotLink';

  public readonly _node: Node;
  public readonly state: StatefulPlugin<EntryPointWhatsappBotLinkConfig>;
  public readonly qrCodeGenerator: QRCodeGenerator;
  public readonly logger: PluginLogger;
  public readonly botLink: WhatsappBotLink;
  public readonly whatsappValidator: WhatsappValidator;
  public isWhatsappConnected: boolean | undefined;

  private whatsappSettingsSubscription: Subscription;
  private whatsappSettings: Maybe<WhatsappSettingsQuery_whatsappMessagingSettings>;
  private inputRef: SetAttributeView;
  private downloadButton: ButtonView;
  private copyButton: ButtonView;
  private disabledState: boolean | undefined;

  constructor(
    state: StatefulPlugin<EntryPointWhatsappBotLinkConfig>,
    node: Node,
  ) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 12,
        fill: textCardBackgroundColor,
      },
      cursor: {
        in: 'default',
      },
    });
    this._node = node;
    this.state = state;
    this.state.setDelegate(this);
    this.botLink = new WhatsappBotLink();
    this.whatsappValidator = new WhatsappValidator(this);
    this.logger = new PluginLogger(
      PluginType.whatsapp_bot_link,
      this._node.id,
      this.state.data?.id,
    );

    this.qrCodeGenerator = new QRCodeGenerator(
      isWhiteLabelDomain()
        ? QR_CODE_WHITE_LABEL_RENDERER_OPTIONS
        : QR_CODE_RENDERER_OPTIONS,
    );

    const wrapper = new VLayout({});

    this.inputRef = this.createRefInput();
    wrapper.addToLayout(this.inputRef, {
      margin: new Margin({ bottom: 12 }),
    });

    this.copyButton = this.createCopyLinkButton();
    wrapper.addToLayout(this.copyButton, {
      margin: new Margin({ bottom: 12 }),
    });

    this.downloadButton = this.createDownloadQRCodeButton();
    wrapper.addToLayout(this.downloadButton);

    const box = new VLayout({
      width: pluginWidth,
    });

    box.addToLayout(wrapper, {
      margin: new Margin({ x: 15, y: 15 }),
    });

    this.addToLayout(box);

    this.setLoading(true);
    this.whatsappSettingsSubscription = getWhatsappSettingsObservable(
      getFlowControllerStrict().flow.botId,
    ).subscribe(
      ({ whatsappSettings, isWhatsappConnected }) => {
        this.whatsappSettings = whatsappSettings;
        this.isWhatsappConnected = isWhatsappConnected;
        const newLink = this.botLink.getUrlString(this.getWhatsappBotLink());

        this.inputRef.text(newLink);
        this.inputRef._props.maskPrefixLength = this.botLink
          .getBaseUrl(
            removeNonDigits(
              this.whatsappSettings?.phone?.display_phone_number || '',
            ),
          )
          .toString().length;

        if (!this.isWhatsappConnected) {
          this.inputRef.setDisabled(false);
          this.downloadButton.setDisabled(true);
          this.copyButton.setDisabled(true);
        } else {
          this.setLoading(false);
          this.updateDisableState(newLink);
        }

        this.renderNode();
      },
      (error) => {
        log.error({
          error,
          msg: 'Failed to load WhatsApp settings',
        });
        toaster.error({
          payload: { message: Messages.somethingWentWrong },
        });
      },
    );
  }

  private setLoading(loading: boolean) {
    this.inputRef.setDisabled(loading);
    this.downloadButton.setDisabled(loading);
    this.copyButton.setDisabled(loading);
  }

  private createDownloadQRCodeButton() {
    const button = new ButtonView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappBotlink.download',
      ),
      textColor: HEXColors.blue,
      img: QRCodeTexture as any,
      bgColor: HEXColors.white,
      onClick: async () => {
        this.logger.log('download QR-code');

        // BUG start по какой то причине в сафари не рисуется иконка с первого раза. Приходится вызывать дважды
        await this.qrCodeGenerator.getRawData(
          this.getWhatsappBotLink().toString(),
        );
        // BUG end
        this.qrCodeGenerator
          .getRawData(this.getWhatsappBotLink().toString())
          .then((blob) => {
            const message = truncateWithEllipses(
              this.state.data.config.prefilledMessage || '',
              20,
            );
            const ref = removeNonDigits(
              this.whatsappSettings?.phone?.display_phone_number || '',
            );
            const fileName = `${FILE_NAME} ${ref} (${message})`;

            download({ url: URL.createObjectURL(blob), fileName });
          })
          .catch((error) => {
            log.error({
              error,
              msg: 'Failed to create WhatsApp QR-code',
            });
            toaster.error({
              payload: { message: Messages.somethingWentWrong },
            });
          });
      },
    });

    return button;
  }

  private createCopyLinkButton() {
    const button = new ButtonView({
      title: i18next.t(
        'modernComponents.FlowBuilder.views.entryPoints.WhatsappBotlink.copy',
      ),
      textColor: HEXColors.white,
      bgColor: HEXColors.blue,
      onClick: (e: InteractionEvent) => {
        e.stopPropagation();
        copyToClipboard(
          this.botLink.getUrlString(this.getWhatsappBotLink(true)),
        );

        toaster.default({
          timeout: 1000,
          payload: {
            message: i18next.t(
              'modernComponents.FlowBuilder.views.components.EntryPointBotLink.urlCopied',
            ),
          },
        });

        this.logger.log('copy link', {
          prefilledMessage: this.state.data?.config?.prefilledMessage,
        });
      },
    });

    return button;
  }

  private createRefInput() {
    const view = new SetAttributeView(
      this.state.data,
      {
        caption: i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.WhatsappBotlink.placeholder',
        ),
        width: pluginWidth - 30,
        height: 0,
        textDecoration: 'underline',
        singleLine: false,
        maxLength: 256,
        maskPrefixLength: this.getWhatsappBotLink().toString().length,
        margin: {
          left: 10,
          top: 8,
          bottom: 8,
          right: 10,
        },
        text: this.botLink.getUrlString(this.getWhatsappBotLink()),
        isValid: () => !!this.state.data?.config?.prefilledMessage,
        isEditing: () => this.state.isEditing,
      },
      this.updateDisableState,
      async (newLink) => {
        // TODO same logic in bot link
        this.inputRef.text(newLink);
        const prefilledMessage =
          new URL(newLink).searchParams.get('text') ?? '';

        this.updatePrefilledMessage(prefilledMessage, () => {
          this.logger.log('update referral', { referral: newLink });
        });

        try {
          await this.state.save();
        } catch (error) {
          handleConflictError(error as ApolloError, this);
        }
      },
      undefined,
      {
        emoji: ControlVisibility.hide,
        attributes: ControlVisibility.hide,
      },
      undefined,
    );

    return view;
  }

  private updateDisableState = (newLink: string) => {
    if (!this.isWhatsappConnected) {
      return;
    }

    const prefilledMessage = new URL(newLink).searchParams.get('text') ?? '';
    const state = !prefilledMessage.length;

    if (this.disabledState !== state) {
      this.downloadButton.setDisabled(state);
      this.copyButton.setDisabled(state);
      this.disabledState = state;
      this.renderNode();
    }
  };

  // TODO same logic in bot link
  private updatePrefilledMessage(
    prefilledMessage: string,
    onChange?: () => void,
  ) {
    if (this.state.data?.config?.prefilledMessage !== prefilledMessage) {
      this.state.set(({ config }) => ({
        config: { ...config, prefilledMessage },
      }));
      onChange?.();
    }
  }

  private getWhatsappBotLink(encode = false) {
    return this.botLink.getBotUrl(
      this.state.data.config.prefilledMessage || '',
      removeNonDigits(this.whatsappSettings?.phone?.display_phone_number || ''),
      { encode },
    );
  }

  pluginDidSet() {
    this.renderNode();
  }

  validationError() {
    return (
      this.whatsappValidator.validate() ||
      validateBotLink(
        this.state.data?.config?.prefilledMessage,
        i18next.t(
          'modernComponents.FlowBuilder.views.entryPoints.WhatsappBotlink.errorMessage',
        ),
      )
    );
  }

  destroy() {
    this.whatsappSettingsSubscription.unsubscribe();
    super.destroy();
    this.state.destroy();
  }
}
