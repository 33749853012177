import { useEntryPointsByFlowsData } from './useEntryPointsByFlowsData';
import {
  HIDDEN_DEPRECATED_ENTRY_POINTS,
  PluginType,
} from '../../../Plugins/common/PluginTypes';

export const useFlowEntryPointsData = (flowId: string | undefined) => {
  const { flowsWithEntryPoints, ...queryState } = useEntryPointsByFlowsData();
  return {
    entryPoints: flowId
      ? flowsWithEntryPoints
          ?.find(({ id }) => id === flowId)
          ?.items?.filter(
            ({ entry_point_type: type }) =>
              type &&
              !HIDDEN_DEPRECATED_ENTRY_POINTS.includes(type as PluginType),
          )
      : undefined,
    ...queryState,
  };
};
