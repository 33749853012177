import gql from 'graphql-tag';
import { FLOW_STATS_FRAGMENT } from '@utils/Data/Flow/Aggregated/queries';
import { FLOW_BLOCK_FRAGMENT } from '@components/Plugins/common/PluginGQL';

export const FLOW_STATS_ENABLED_QUERY = gql`
  query FlowStatsEnabledQuery($botId: String!) {
    bot(id: $botId) {
      features {
        flow_stats
      }
    }
  }
`;

export const FLOW_INBOUND_LINKS_DATA_QUERY = gql`
  query FlowInboundLinksDataQuery(
    $botId: String!
    $flowBlockId: MongoObjectId!
    $flowId: ID!
  ) {
    bot(id: $botId) {
      flowBlocks(id: $flowBlockId) {
        ...FlowBlockFragment
      }
    }
    flowStats(id: $flowId) {
      ...flowStatsFragment
    }
  }
  ${FLOW_STATS_FRAGMENT}
  ${FLOW_BLOCK_FRAGMENT}
`;
