import { getFlowControllerStrict } from '../PixiFieldRepository';
import {
  FlowBuilderOverlayEvent,
  OverlayComponentEvent,
  overlayEventEmitter,
  OverlayEventPayload,
  OverlayType,
} from '../FlowBuilderOverlay';
import { resByFunc } from './utils';
import { FLOW_BUILDER_ROOT_ID } from '../consts';
import { PropFunc } from '../types';
import { Point } from '../components/Elements/Shapes';
import { IMenuItem, IMenuNode } from './Menu/types';
import { assert } from '@utils/Assert';
import Maybe from 'graphql/tsutils/Maybe';
import { MENU_Z_INDEX } from '../FlowBuilderOverlay/overlays/Menu/consts';

type OnChoose<T, U> = (item: U, self: CreateMenuViewOverlay<T, U>) => void;
type OnClose = () => void;

export type DefaultItem = IMenuNode<IMenuItem>;

export type MessageTagType = DefaultItem & {
  tagType: 'tag' | 'otn';
};

interface CreateMenuViewOverlayParams<T, U> {
  onChoose: OnChoose<T, U>;
  items?: PropFunc<T[]>;
  onClose?: OnClose;
  defaultValue?: string | null;
  menuType?: OverlayType;
  style?: React.CSSProperties;
  searchable?: boolean;
  isPlanLimitMessage?: boolean;
}

export class CreateMenuViewOverlay<T, U = T> {
  private readonly flowBuilderRoot: HTMLElement;
  private _onClose: Maybe<OnClose>;
  private _onChoose: Maybe<OnChoose<T, U>>;

  constructor({
    onChoose,
    items,
    onClose,
    defaultValue,
    menuType = OverlayType.menu,
    style,
    searchable,
    isPlanLimitMessage,
  }: CreateMenuViewOverlayParams<T, U>) {
    this._onClose = onClose;
    this._onChoose = onChoose;
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.mount, {
      overlayType: menuType,
      getOverlayOptions: () => ({
        items: resByFunc(items),
        defaultValue,
        flowId: getFlowControllerStrict().flow.id,
        style,
        searchable,
        isPlanLimitMessage,
        zIndex: MENU_Z_INDEX,
      }),
    });

    const flowBuilderRoot = document.getElementById(FLOW_BUILDER_ROOT_ID);
    assert(flowBuilderRoot, { msg: 'Flow builder root was not found' });
    this.flowBuilderRoot = flowBuilderRoot;
  }

  _handleWheel = () => {
    this.destroy();
  };

  _handleKeyDown = ({ key }: React.KeyboardEvent<HTMLElement>) => {
    if (key === 'Escape') {
      this.destroy();
    }
  };

  _handleClick = () => {
    this.destroy();
  };

  _handleChange = ({ value: item }: OverlayEventPayload<U>) => {
    // @ts-expect-error looks like something legacy
    if (item.inactive) return;
    if (this._onChoose) {
      this._onChoose(item, this);
      this._onClose = null;
      this._onChoose = null;
      this.destroy();
    }
  };

  showOn({ x, y }: Point) {
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.move, {
      position: {
        x: Math.round(x + 1), // prevent mouseup event on overlay
        y: Math.round(y),
      },
    });
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.show);
    overlayEventEmitter.on(OverlayComponentEvent.change, this._handleChange);
    overlayEventEmitter.on(OverlayComponentEvent.keydown, this._handleKeyDown);
    setTimeout(() => {
      this.flowBuilderRoot.addEventListener('mousedown', this._handleClick);
      this.flowBuilderRoot.addEventListener('touchstart', this._handleClick);
      this.flowBuilderRoot.addEventListener('wheel', this._handleWheel);
    });
  }

  destroy() {
    this.flowBuilderRoot.removeEventListener('mousedown', this._handleClick);
    this.flowBuilderRoot.removeEventListener('touchstart', this._handleClick);
    this.flowBuilderRoot.removeEventListener('wheel', this._handleWheel);
    overlayEventEmitter.off(OverlayComponentEvent.change, this._handleChange);
    overlayEventEmitter.off(OverlayComponentEvent.keydown, this._handleKeyDown);
    this._onClose?.();
    overlayEventEmitter.emit(FlowBuilderOverlayEvent.unmount);
  }
}
