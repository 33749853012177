import i18next from 'i18next';
import {
  jsonPluginFragment_config as JsonPluginConfig,
  jsonPluginFragment_validation_details_fields as ValidationField,
} from '@components/Plugins/JsonPlugin/@types/jsonPluginFragment';
import { StatefulPlugin, StatefulPluginDelegate } from '../StatefulPlugin';
import { VLayout } from '../components/Elements/Layouts';
import { PluginTitleLayout } from './components/PluginTitleLayout';
import { pluginJsonSvgTexture } from '../assets/textures';
import { tooltips } from './Menu/menu_view';
import { TextEditView } from './text_edit_view';
import { editPluginInEditorPanel } from '../EditorPanel/events';
import { getPanelWidth } from '../EditorPanel/utils/panelDimensions';
import { PluginType } from '../types';
import { getPixiFieldStrict } from '../PixiFieldRepository';
import { HTMLText } from '../components/Elements/Shapes';
import { pluginWidth, textCardBackgroundColor } from './plugin_consts';
import { Node } from '../Node';
import Maybe from 'graphql/tsutils/Maybe';
import { HEXColors } from '@ui/_common/colors';

export class JsonPluginView
  extends VLayout
  implements StatefulPluginDelegate<JsonPluginConfig>
{
  TEST_NAME = 'JsonPluginView';

  public readonly state: StatefulPlugin<JsonPluginConfig>;

  private readonly _node: Node;
  private readonly titleLayout: PluginTitleLayout;
  private readonly methodView: TextEditView;
  private readonly urlView: HTMLText;

  constructor(state: StatefulPlugin<JsonPluginConfig>, node: Node) {
    super({
      width: pluginWidth,
      background: {
        cornerRadius: 10,
        fill: textCardBackgroundColor,
      },
    });

    this._node = node;
    this.state = state;
    this.state.setDelegate(this);

    this.titleLayout = new PluginTitleLayout(
      false,
      i18next.t('json_plugin-string--134-json-request'),
      pluginJsonSvgTexture,
      this.state.data,
      tooltips().json,
    );

    this.addToLayout(this.titleLayout, {
      margin: { top: 15, bottom: 15, left: 10 },
    });

    this.methodView = new TextEditView({
      width: pluginWidth,
      fontStyle: 'semibold',
      fontSize: 15,
      editable: false,
      margin: { left: 15, bottom: 5 },
    });
    this.urlView = new HTMLText({
      width: pluginWidth - 24,
      fontSize: 15,
    });
    this.layout(this.methodView).layout(this.urlView, {
      margin: { left: 15, right: 15, bottom: 15 },
    });

    this.on('click', () => {
      this.openLeftPanel();
    });
  }

  validationError() {
    const { validation_details } = this.state.data;
    const validationFields: Maybe<ValidationField[]> =
      validation_details?.fields;

    const hasUrlInvalid = validationFields?.some(
      ({ field }) => field === 'url',
    );

    const hasCustomResponseInvalid = validationFields?.some(
      ({ field }) => field === 'custom_response_config',
    );

    if (hasUrlInvalid) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.JSONPlugin.errors.setupURL',
        ),
        onClick: () => this.openLeftPanel(),
      };
    }

    if (hasCustomResponseInvalid) {
      return {
        message: i18next.t(
          'modernComponents.FlowBuilder.plugins.JSONPlugin.errors.setupMapping',
        ),
        onClick: () => this.openLeftPanel(),
      };
    }

    return !!validationFields;
  }

  pluginDidSet() {
    this.renderNode();
  }

  onBeforeRender() {
    const { config } = this.state.data;
    this.methodView.text(config.method ? config.method : 'GET');
    this.urlView.text(
      config.url
        ? JsonPluginView.renderWithAttributes(config.url)
        : 'Undefined',
    );
  }

  destroy() {
    this.state.destroy();
    super.destroy();
  }

  private static renderWithAttributes(string: string) {
    return string
      .replace('{{', `<span style="color: ${HEXColors.blue};">{{`)
      .replace('}}', '}}</span>');
  }

  private openLeftPanel() {
    const node = this._node;
    getPixiFieldStrict().fixBlockPosition(
      node.blockView,
      getPanelWidth(PluginType.json_plugin),
    );
    editPluginInEditorPanel(node.id, this.state.data);
  }
}
