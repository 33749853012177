import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { getI18nInboundLinksKey } from '../../../../EditorPanel/components/plugins/InboundLinksEntryPoint/helpers';
import { VLayout } from '../../../../components/Elements/Layouts';
import { HTMLText } from '../../../../components/Elements/Shapes';

interface MoreItemsButtonViewProps {
  count: number;
  onClick(): void;
}

export class MoreItemsButtonView extends VLayout {
  private props: MoreItemsButtonViewProps;

  constructor(props: MoreItemsButtonViewProps) {
    super({
      cursor: {
        in: 'pointer',
      },
    });

    this.props = props;
    const { count, onClick } = this.props;

    this.addToLayout(
      new HTMLText({
        text: i18next.t(getI18nInboundLinksKey('moreButton'), { count }),
        fontSize: 15,
        fill: HEXColors.greyDark,
        singleLine: true,
        textDecoration: 'underline',
      }),
    );

    this.on('click', onClick);
  }

  destroy() {
    this.off('click', this.props.onClick);
  }
}
