import { HEXColors } from '@ui/_common/colors';
import { MainLayout } from '../../../components/Elements/Layouts';
import { HTMLText } from '../../../components/Elements/Shapes';
import { Node } from '../../../Node';
import { BlockView } from '../../block_view';
import { getFullCounterStats } from '../../components/BlockStatsView/utils';
import { LineStartView } from '../../components/Line/LineStartView';
import { ItemStatView } from '../../kit/ItemStatView/ItemStatView';
import { createLineMenu } from '../../Menu/createLineMenu';
import { pluginWidth } from '../../plugin_consts';
import { resByFunc } from '../../utils';

interface TargetBoxViewProps {
  node: Node;
  title: string;
  blockId: string | null;
  statKey: string;
  onBlockIdChange: (blockId: string | null) => void;
}

export class TargetBoxView extends MainLayout {
  private readonly lineStartView: LineStartView;
  private readonly textView: HTMLText;
  private blockId: string | null = null;
  public _node: Node;

  constructor({
    title,
    node,
    onBlockIdChange,
    blockId,
    statKey,
  }: TargetBoxViewProps) {
    super({
      background: {
        fill: HEXColors.greyLight30,
        cornerRadius: 12,
      },
      width: pluginWidth,
    });

    this._node = node;
    this.blockId = blockId;

    this.textView = new HTMLText({
      fontSize: 15,
      text: title,
    });

    this.addToLayout(this.textView, {
      margin: {
        top: 16,
        right: 16,
        bottom: 16,
        left: 16,
      },
    });

    this.lineStartView = new LineStartView(
      {
        from: this,
        node,
        offset: 40,
        mandatory: true,
        items: createLineMenu({
          isShowConnectToExistingBlock: true,
        }),
        onConnected: (blockView: BlockView) => {
          const blockId = blockView.id();
          this.updateBlockId(blockId);
          onBlockIdChange(blockId);
        },
        onRemoved: () => {
          this.updateBlockId(null);
          onBlockIdChange(null);
        },
      },
      undefined,
    );

    this.addToLayout(this.lineStartView, {
      verticalAlign: 'center',
      align: 'end',
      margin: {
        left: 8,
      },
    });

    const stats = getFullCounterStats(statKey, node.block.id);

    if (stats) {
      this.addToLayout(
        new ItemStatView({
          stats,
          showPercentValue: true,
        }),
        {
          verticalAlign: 'center',
          align: 'end',
          margin: {
            left: -8,
          },
        },
      );
    }
  }

  private updateBlockId(blockId: string | null) {
    this.blockId = blockId;
    this.renderNode();
  }

  public set text(value: string) {
    this.textView.text(value);
  }

  onBeforeRender() {
    if (this.blockId) {
      this._node.addOutLink(this.blockId, this.lineStartView._lineView);
    }
    this.lineStartView._lineView.gone = !!resByFunc(
      this.layoutProperties?.gone,
    );
  }
}
